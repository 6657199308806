import React from "react";

const style = {
  width: 10,
  display: "inline-block",
};

const PageToolbarSpace = () => <div style={style} />;

export default PageToolbarSpace;
