import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  TextField,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import useI18n from "../../../hooks/useI18n";
import { deepCompare } from "../../../utils/common";
import { useAnyAppDispatch, useAppSelector } from "../../../hooks/store";
import { setTags as setTagsAction } from "../../../actions/actions";
import { Alert } from '@mui/material';
import { Tag } from "../../../types/common";
import { colors } from "../../../utils/tags";

const Line = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeleteButton = styled(IconButton)`
  margin-top: -5px;
  margin-left: 2px;
  margin-right: -16px;
`;

const Color = styled.button<{
  color: string;
  selected?: boolean;
  $radius?: boolean;
}>`
  background-color: ${(props) => props.color};
  width: 25px;
  height: 25px;
  display: inline-block;
  box-sizing: border-box;
  border: ${(props) => (props.selected ? "solid 2px black" : "none")};
  border-radius: ${(props) => (props.$radius ? 4 : 0)}px;
  cursor: pointer;
`;

const ColorWrap = styled.div`
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  overflow: hidden;
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

const TagsManager = (props: Props) => {
  const [_] = useI18n();
  const { onClose } = props;
  const [selectedColorTag, setSelectedColorTag] = useState<null | Tag>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const dispatch = useAnyAppDispatch();

  const { items } = useAppSelector((state) => ({
    items: state.database.tags || [],
  }));

  const [tags, setTags] = useState(items);

  const handleAdd = useCallback(() => {
    setTags((tags) => [
      ...tags,
      { id: Date.now(), label: "Tag", color: colors[5][0] },
    ]);
  }, [setTags]);

  const handleRemove = useCallback(
    (id: number) => {
      setTags((tags) => tags.filter((i) => i.id !== id));
    },
    [setTags]
  );

  const handleRename = useCallback(
    (id: number, label: string) => {
      setTags((tags) => tags.map((i) => (i.id === id ? { ...i, label } : i)));
    },
    [setTags]
  );
  const handleChangeColor = useCallback(
    (color: string) => {
      if (selectedColorTag) {
        setSelectedColorTag(null);
        setAnchorEl(null);
        setTags((tags) =>
          tags.map((i) => (i.id === selectedColorTag.id ? { ...i, color } : i))
        );
      }
    },
    [setTags, selectedColorTag]
  );

  const handleSave = useCallback(() => {
    dispatch(setTagsAction(tags));
    onClose();
  }, [dispatch, tags, onClose]);

  const isChanged = useMemo(() => !deepCompare(tags, items), [tags, items]);

  const isUniq = useMemo(
    () => new Set(tags.map((i) => i.label)).size === tags.length,
    [tags]
  );

  const handleClose = useCallback(() => {
    onClose();
    setTags(items);
  }, [items, onClose]);

  return (
    <Dialog open={props.open}>
      <DialogTitle>{_("tags")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          {tags.map((tag) => (
            <Grid item key={tag.id}>
              <Line>
                <TextField
                  variant={"outlined"}
                  size="small"
                  fullWidth
                  value={tag.label}
                  onChange={(e) => handleRename(tag.id, e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Color
                        $radius
                        color={tag.color || "blue"}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setSelectedColorTag(tag);
                        }}
                      />
                    ),
                  }}
                />

                <DeleteButton
                  aria-label="delete"
                  onClick={() => handleRemove(tag.id)}
                >
                  <DeleteIcon fontSize="inherit" />
                </DeleteButton>
              </Line>
            </Grid>
          ))}
          <Grid item>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              onClick={handleAdd}
            >
              {_("add_tag")}
            </Button>
          </Grid>
          {!isUniq && (
            <Grid item>
              <Alert severity="error">{_("names_must_be_unique")}</Alert>
            </Grid>
          )}
        </Grid>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ColorWrap>
            {colors.map((i) => (
              <Color
                color={i[0]}
                key={i[0]}
                selected={i[0] === selectedColorTag?.color}
                onClick={() => handleChangeColor(i[0])}
              />
            ))}
          </ColorWrap>
        </Popover>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {_("close")}
        </Button>
        <Button
          disabled={!isChanged || !isUniq}
          color="primary"
          onClick={handleSave}
        >
          {_("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagsManager;
