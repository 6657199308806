import React, { Component } from "react";
import { camera } from "../actions/actions";
import Rectangles from "./Rectangles";
import { ImageSize, Line, Rectangle } from "../types/common";

const BORDER = 2;

const styles = {
  canvas: {
    position: "relative",
  },
  wrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#eee",
  },
  rectangles: {
    position: "absolute",
  },
} as const;

const getImageStyle = (wrapSize: ImageSize, imageSize: ImageSize) => {
  const scaleWidth = Math.min(wrapSize.width / imageSize.width);
  const scaleHeight = Math.min(wrapSize.height / imageSize.height);
  const scale = Math.min(scaleWidth, scaleHeight);
  return {
    width: imageSize.width * scale,
    height: imageSize.height * scale,
    offsetLeft: (wrapSize.width - scale * imageSize.width) / 2,
    scale,
  };
};

type Props = {
  id: string;
  showRectangles: boolean;
};

type State = {
  rectangles: Rectangle[] | null;
  lines: Line[] | null;
  status: null | string;
  imageSize?: ImageSize;
  image?: HTMLImageElement;
  scale?: number;
};

class Camera extends Component<Props, State> {
  state: State = {
    rectangles: null,
    lines: null,
    status: null,
  };

  componentDidMount() {
    camera.registerListener(
      (image, rectangles, lines, imageSize, status, scale) => {
        this.setState({
          rectangles,
          imageSize,
          image,
          lines,
          status,
          scale,
        });
        this.renderImage(image);
      }
    );
    setTimeout(() => {
      camera.runningCam();
    }, 200);
    window.addEventListener("resize", this.resize);
    document.addEventListener("fullscreenchange", this.resize);
    document.addEventListener("webkitfullscreenchange", this.resize);
    document.addEventListener("mozfullscreenchange", this.resize);
    document.addEventListener("MSFullscreenChange", this.resize);
  }

  componentWillUnmount() {
    camera.unregisterListener();
    window.removeEventListener("resize", this.resize);
    document.removeEventListener("fullscreenchange", this.resize);
    document.removeEventListener("webkitfullscreenchange", this.resize);
    document.removeEventListener("mozfullscreenchange", this.resize);
    document.removeEventListener("MSFullscreenChange", this.resize);
    // eslint-disable-next-line no-underscore-dangle
  }

  resize = () => {
    if (this.state.image) {
      this.renderImage(this.state.image);
    }
  };

  renderImage(image: HTMLImageElement) {
    const canvasMain = document.getElementById(
      `canvas_camera_${this.props.id}`
    ) as HTMLCanvasElement;
    const wrapElement = document.getElementById(
      `wrap_camera_${this.props.id}`
    ) as HTMLDivElement;

    if (!wrapElement || !canvasMain) {
      return;
    }

    const s = getImageStyle(
      { width: wrapElement.offsetWidth, height: wrapElement.offsetHeight },
      image
    );
    canvasMain.width = s.width;
    canvasMain.height = s.height;

    const ctxMain = canvasMain.getContext("2d") as CanvasRenderingContext2D;

    if (this.state.status) {
      ctxMain.lineWidth = BORDER;
      ctxMain.strokeStyle = this.state.status === "ok" ? "green" : "red";
      ctxMain.strokeRect(
        BORDER / 2,
        BORDER / 2,
        canvasMain.width - BORDER,
        canvasMain.height - BORDER
      );
      ctxMain.drawImage(
        image,
        BORDER,
        BORDER,
        canvasMain.width - BORDER * 2,
        canvasMain.height - BORDER * 2
      );
    } else {
      ctxMain.drawImage(image, 0, 0, canvasMain.width, canvasMain.height);
    }
  }

  renderRectangles = () => {
    const wrapElement = document.getElementById(
      `wrap_camera_${this.props.id}`
    ) as HTMLDivElement;

    if (!wrapElement || !this.state.imageSize) {
      return null;
    }

    const s = getImageStyle(
      { width: wrapElement.offsetWidth, height: wrapElement.offsetHeight },
      this.state.imageSize
    );

    if (
      !this.state.rectangles ||
      !this.state.lines ||
      !this.state.imageSize ||
      !this.state.scale
    ) {
      return null;
    }

    return (
      <div style={{ ...styles.rectangles, ...s }}>
        <Rectangles
          imageSize={{
            width: this.state.imageSize.width / this.state.scale,
            height: this.state.imageSize.height / this.state.scale,
          }}
          items={this.state.rectangles}
          lines={this.state.lines}
          wrapSize={{ width: s.width, height: s.height }}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <div style={styles.wrap} id={`wrap_camera_${this.props.id}`}>
          <canvas id={`canvas_camera_${this.props.id}`} style={styles.canvas} />
          {this.props.showRectangles &&
            this.state.image &&
            this.renderRectangles()}
        </div>
      </>
    );
  }
}

export default Camera;
