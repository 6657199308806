import { Modules } from "./modules";

export const MAIN_DOC = "main";
export const MODULES_DOC = "modules";
export const CAMERA_DOC = "camera";
export const OUTPUT_DOC = "output";
export const IMAGES_DOC = "images";
export const INSPECTION_DOC = "inspection";
export const STATISTICS_DOC = "statistics";
export const DETECTOR_DOC = "detector";
export const CLASSIFIER_DOC = "classifier";
export const SUPERVISED_DOC = "supervised";
export const UNSUPERVISED_DOC = "unsupervised";
export const OCR_DOC = "ocr";
export const CODE_DOC = "code";
export const MASK_DOC = "mask";
export const MEASURE_DOC = "measure";
export const UNIFIER_DOC = "unifier";
export const PREPROCESS_DOC = "preprocess";

export const moduleTypeToDoc = (type: Modules) => {
  switch (type) {
    case Modules.CODE:
      return CODE_DOC;
    case Modules.DETECTOR:
      return DETECTOR_DOC;
    case Modules.CLASSIFIER:
      return CLASSIFIER_DOC;
    case Modules.SUPERVISED:
      return SUPERVISED_DOC;
    case Modules.UNSUPERVISED:
      return UNSUPERVISED_DOC;
    case Modules.MASK:
      return MASK_DOC;
    case Modules.OCR:
      return OCR_DOC;
    case Modules.PREPROCESS:
      return PREPROCESS_DOC;
    case Modules.UNIFIER:
      return UNIFIER_DOC;
    case Modules.MEASURE:
      return MEASURE_DOC;
  }
};

const getDoc = (id: string = MAIN_DOC) =>
  `https://pekatvision.com/documentation/${(window as any).version.replace(
    /\./g,
    "_"
  )}/${id}`;
export default getDoc;
