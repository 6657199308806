import React from "react";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { TextField } from "formik-mui";
import MenuItem from "@mui/material/MenuItem";
import useI18n from "../../../../hooks/useI18n";

const TcpForm = () => {
  const [_] = useI18n();
  return (
    <>
      <Grid item>
        <Field
          disabled={false}
          name="plcMessage"
          label={_("message_in_utf8")}
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
          validate={(value: string) => !value && _("required")}
        />
      </Grid>
      <Grid item>
        <Field
          select
          disabled={false}
          name="plcValue"
          label={_("value")}
          margin="dense"
          variant="outlined"
          component={TextField}
          validate={(value: string) => !value && _("required")}
        >
          <MenuItem value="0">Logical 0</MenuItem>
          <MenuItem value="1">Logical 1</MenuItem>
        </Field>
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="plcIp"
          label="IP"
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
          validate={(value: string) => !value && _("required")}
          url
        />
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="plcPort"
          label="port"
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
          inputProps={{
            type: "number",
            max: 65535,
            min: 0,
          }}
          validate={(value: string) => {
            if (!value) return _("required");
            if (!RegExp("^d+$")) return _("number_error_not_number");
            if (parseInt(value, 10) > 65535)
              return `${_("error")} (${_("must_be")} <= 65535)`;
            if (parseInt(value, 10) < 0) return _("number_error_negative");
          }}
          url
        />
      </Grid>
    </>
  );
};

export default TcpForm;
