import React, { FC, useState } from "react";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import EvaluationDialog from "../../../components/Evaluation/EvaluationDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import EvaluationButton from "../../../components/EvaluationButton";
import { getNumberDictionary } from "../../../store/utils";
import { PropsFromRedux } from "../ModelDetailContainer";
import ChangeModelPanel from "../../../components/ChangeModelPanel";
import ModelListContainer from "../ModelListContainer";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import useI18n from "../../../hooks/useI18n";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";

const ModelDetailView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const [showEvaluationDialog, setShowEvaluationDialog] = useState(false);

  const getImages = () => {
    const trainingImagesIds = getNumberDictionary(props.model.trainingImages);
    const validateImagesIds = getNumberDictionary(props.model.validateImages);
    return props.images.map((i) => {
      if (trainingImagesIds[i.id]) {
        return { ...i, label2: "T", isTraining: true };
      }
      if (validateImagesIds[i.id]) {
        return { ...i, label2: "V", isValidate: true };
      }
      return i;
    });
  };

  const getInfo = ({ filtered, all }: { filtered: number; all: number }) => {
    const { trainingImages, validateImages } = props.model;
    return {
      title: _("image_list_info_help_rectangles"),
      text: `(${
        trainingImages.length + validateImages.length
      }) / ${filtered} / ${all}`,
    };
  };

  if (!props.model) {
    return null;
  }

  const moduleId = props.module.id;

  const evaluateValueTypes = [
    { id: "COUNT", label: _("count") },
    { id: "EDGE_LENGTH", label: _("edge_length") },
  ];

  return (
    <div>
      <ImageListWithMarkers
        detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
        listHeight={CON_MIN_HEIGHT_HEADER}
        moduleId={moduleId}
        ImageDetailProps={{
          defaultScale: 0.9,
        }}
        ImageListProps={{
          showSelectedFilter: true,
          images: getImages(),
        }}
        readOnly
        getInfo={getInfo}
        includeLast
      >
        <ModuleRightToolPanel>
          <ChangeModelPanel model={props.model}>
            <ModelListContainer moduleId={props.module.id} />
          </ChangeModelPanel>

          <ModuleSettingsWrap>
            <EvaluationButton onClick={() => setShowEvaluationDialog(true)} />
          </ModuleSettingsWrap>
        </ModuleRightToolPanel>
      </ImageListWithMarkers>
      <EvaluationDialog
        classNameList={props.model.trainingParams.classNames}
        valueTypes={evaluateValueTypes}
        open={showEvaluationDialog}
        onClose={() => setShowEvaluationDialog(false)}
        onSave={(evaluation) =>
          props.updateEvaluation({
            modelId: props.model.modelId,
            evaluation,
          })
        }
        values={props.model.evaluation || {}}
      />
    </div>
  );
};

export default ModelDetailView;
