import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { makeStyles } from 'tss-react/mui';
import Alert from '@mui/material/Alert';
import MiniLoader from "../../../components/Loader/MiniLoader";
import ModelInfo from "../../../components/ModelInfo";
import {
  DetectorModel,
  ClassifierModel,
  UnsupervisedModel,
  SupervisedModel,
  OcrModel,
} from "../../../types/modules";
import useI18n from "../../../hooks/useI18n";

const useStyles = makeStyles()({
  paper: {
    minWidth: 400,
  },
  loader: {
    position: "absolute",
    top: 15,
    right: 24,
  },
});

type Props = {
  detectorTrainingModel?: DetectorModel;
  classifierTrainingModel?: ClassifierModel;
  supervisedTrainingModels?: SupervisedModel;
  unsupervisedTrainingModels?: UnsupervisedModel;
  ocrTrainingModels?: OcrModel;
  detectorStopTraining: (modelId: number) => void;
  supervisedStopTraining: (modelId: number) => void;
  unsupervisedStopTraining: (modelId: number) => void;
  classifierStopTraining: (modelId: number) => void;
  ocrStopTraining: (modelId: number) => void;
  diffTime: number;
};

const Training = ({
  detectorTrainingModel,
  classifierTrainingModel,
  supervisedTrainingModels,
  unsupervisedTrainingModels,
  detectorStopTraining,
  supervisedStopTraining,
  unsupervisedStopTraining,
  classifierStopTraining,
  ocrStopTraining,
  ocrTrainingModels,
  diffTime,
}: Props) => {
  const [_] = useI18n();
  const { classes } = useStyles();
  const model =
    detectorTrainingModel ||
    classifierTrainingModel ||
    supervisedTrainingModels ||
    ocrTrainingModels ||
    unsupervisedTrainingModels;

  if (!model) {
    return null;
  }

  const preciseAnomaly =
    unsupervisedTrainingModels?.trainingParams.TYPE === "new";

  const isPercent = model.firstStagePercent !== undefined;

  return (
    <Dialog open classes={{ paper: classes.paper }}>
      <DialogTitle>{_("training_is_going")}</DialogTitle>
      <DialogContent>
        <MiniLoader className={classes.loader} white />
        {model.firstStagePercent === undefined &&
          model.progress === undefined && (
            <p>
              <small>{_("training_info")}</small>
            </p>
          )}
        <ModelInfo
          indeterminateLoader={preciseAnomaly}
          model={model}
          diffTime={diffTime}
        />
        {model.cancelling && model.progress === undefined && (
          <p>
            <Alert severity="info">{_("wait_until_the_finish_training")}</Alert>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        {!preciseAnomaly && (
          <>
            <Button
              color="primary"
              disabled={model.cancelling}
              onClick={(e) => {
                e.stopPropagation();
                if (detectorTrainingModel) {
                  detectorStopTraining(detectorTrainingModel.modelId);
                } else if (supervisedTrainingModels) {
                  supervisedStopTraining(supervisedTrainingModels.modelId);
                } else if (unsupervisedTrainingModels) {
                  unsupervisedStopTraining(unsupervisedTrainingModels.modelId);
                } else if (classifierTrainingModel) {
                  classifierStopTraining(classifierTrainingModel.modelId);
                } else if (ocrTrainingModels) {
                  ocrStopTraining(ocrTrainingModels.modelId);
                }
              }}
            >
              {model.cancelling === true
                ? _("stopping")
                : _(isPercent ? "cancel_training" : "close_training")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Training;
