import React, { Component } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  maskSetImage,
  maskSetToHeatmap,
  maskUpdateName,
} from "../../../actions/actions";
import Editor from "../../../components/templates/Editor";
import PaintCanvas from "../../../Containers/PaintCanvas/PaintCanvasContainer";
import NavigationPrompt from "../../../components/NavigationPromp";
import { PropsFromRedux } from "../DetailContainer";
import withI18n from "../../../utils/withI18n";
import { Image } from "../../../types/common";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const styles = {
  canvasWrap: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    height: "100%",
  },
};

type Props = PropsFromRedux & {
  t: (key: string) => string;
};

type State = {
  currentImage: null | Image;
  isChange: boolean;
  base64image?: string;
};

class MaskView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;
  state: State = {
    currentImage: null,
    isChange: false,
  };

  clearCanvas: (() => void) | undefined = undefined;

  handleClickSave = () => {
    if (this.state.base64image) {
      maskSetImage(
        this.props.maskItem.itemId,
        this.props.moduleId,
        this.state.base64image
      );
      this.setState({
        isChange: false,
      });
    }
  };

  render() {
    const _ = this.props.t;
    const { name, imageId, heatmap } = this.props.maskItem;
    const { currentImage } = this.state;
    const imgSrc =
      currentImage &&
      this.context.helper.getImagePath({
        imageId: currentImage.id,
        moduleId: this.props.moduleId,
        includeLast: false,
        withMask: true,
      });

    return (
      <>
        <NavigationPrompt
          when={this.state.isChange}
          message={_("changes_not_save")}
        />
        <Editor
          key={heatmap ? 0 : 1}
          onClickBack={() =>
            this.props.history.push(`/modules/mask/${this.props.moduleId}/`)
          }
          onChangeName={(name) =>
            maskUpdateName({ name, itemId: this.props.maskItem.itemId })
          }
          menuItems={
            <FormControlLabel
              style={{ marginLeft: 10 }}
              control={
                <Checkbox
                  checked={heatmap}
                  onChange={(e) =>
                    maskSetToHeatmap({
                      itemId: this.props.maskItem.itemId,
                      heatmap: e.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={_("apply_to_heatmap")}
            />
          }
          onSave={this.handleClickSave}
          moduleId={this.props.moduleId}
          name={name}
          isChange={this.state.isChange}
          onSelectImage={(currentImage) => this.setState({ currentImage })}
          activeId={
            this.state.currentImage ? this.state.currentImage.id : undefined
          }
          getImageSrc2={(image) => ({
            imageId: image.id,
            moduleId: this.props.moduleId,
            otherModule: {
              id: this.props.moduleId,
              type: "MASK",
              imageId,
              heatmap,
            },
            withMask: true,
          })}
        >
          <div style={styles.canvasWrap}>
            <PaintCanvas
              enableUploadImage
              onGetClear={(clearCanvas) => {
                this.clearCanvas = clearCanvas;
              }}
              label={currentImage ? currentImage.label : ""}
              key={imgSrc}
              imgSrc={imgSrc || ""}
              maskSrc={
                // eslint-disable-next-line no-nested-ternary
                this.state.base64image
                  ? this.state.base64image
                  : this.props.maskItem.imageId
                  ? this.context.helper.getMaskPath(imageId)
                  : undefined
              }
              color="black"
              radius={this.props.module.values.paintRadius || 10}
              onChangeRadius={(paintRadius) =>
                this.props.changePaintRadius({
                  moduleId: this.props.moduleId,
                  paintRadius,
                })
              }
              onChange={(canvas) => {
                this.setState({
                  isChange: true,
                  base64image: canvas,
                });
              }}
              onClear={() => {
                const blank = document.createElement("canvas");
                blank.width = 10;
                blank.height = 10;

                this.setState({
                  isChange: true,
                  base64image: blank.toDataURL(),
                });
              }}
            />
          </div>
        </Editor>
      </>
    );
  }
}

export default withI18n(MaskView);
