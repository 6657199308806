import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextFieldWithDebounce from "../../../components/TextFieldWithDebounce";
import { Line } from "../../../types/common";
import useI18n from "../../../hooks/useI18n";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles()((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  accordion: {
    display: "block",
  },
  select: {
    marginTop: 13,
  },
}));

const styles = {
  addBtn: {
    marginTop: 5,
  },
  icon: {},
  input: {
    width: 200,
  },
} as const;

type Props = {
  items: Line[];
  onChangeItems: (values: Line[]) => void;
};

const Lines = (props: Props) => {
  const [_] = useI18n();
  const [activeLine, setActiveLine] = useState<number | null>(null);

  const { classes } = useStyles();

  const handleAdd = () => {
    props.onChangeItems([
      ...props.items,
      {
        id: Date.now(),
        label: "Line",
        method: "max",
        width: 3,
        percent: true,
        start: {
          x: 0.1,
          y: 0.2,
        },
        end: {
          x: 0.5,
          y: 0.8,
        },
      },
    ]);
  };

  return (
    <>
      {props.items.map((item, index) => (
        <Accordion
          disableGutters
          key={item.id}
          expanded={activeLine === item.id}
          onChange={() =>
            setActiveLine(activeLine === item.id ? null : item.id)
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordion}>
            <TextFieldWithDebounce
              variant="outlined"
              label={_("name")}
              margin="dense"
              fullWidth
              value={item.label}
              onChange={(value) =>
                props.onChangeItems(
                  props.items.map((i, index2) =>
                    index === index2 ? { ...i, label: value } : i
                  )
                )
              }
            />

            {/* <div>
            {" "}
            <SliderWithField
              marginTop
              value={20}
              size="small"
              label={_("width")}
              onChange={(value) => {
                props.onChangeItems(
                  props.items.map((i, index2) =>
                    index === index2 ? { ...i, width: value } : i
                  )
                );
              }}
              min={1}
              step={1}
              maxSlider={200}
            />
          </div>
          <TextField
            className={classes.select}
            select
            label={_("type")}
            value={item.method}
            fullWidth
            variant="outlined"
            margin="dense"
            onChange={(e) =>
              props.onChangeItems(
                props.items.map((i, index2) =>
                  index === index2
                    ? {
                        ...i,
                        method: e.target.value as "min" | "max" | "average",
                      }
                    : i
                )
              )
            }
          >
            <MenuItem value="min">{_("min")}</MenuItem>
            <MenuItem value="max">{_("max")}</MenuItem>
            <MenuItem value="average">{_("average")}</MenuItem>
          </TextField> */}
            <IconButton
              onClick={() =>
                props.onChangeItems(
                  props.items.filter((i, index2) => index !== index2)
                )
              }
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button
        variant="contained"
        color="primary"
        style={styles.addBtn}
        onClick={handleAdd}
      >
        <AddIcon /> {_("add_line")}
      </Button>
    </>
  );
};

export default Lines;
