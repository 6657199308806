import React, { Component } from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import withI18n from "../../utils/withI18n";
import PeakChart from "./PeakChart";

const styles = {
  slider: {
    marginTop: 8,
    marginBottom: 8,
  },
  sliderInner: {
    margin: 0,
  },
  thresholdLabel: {
    marginLeft: 30,
  },
  checkbox: {
    whiteSpace: "nowrap",
    marginRight: 30,
    width: "auto",
  },
  checkboxOnlySupervised: {
    marginLeft: 30,
  },
  moreThresholdWrap: {
    position: "absolute",
    backgroundColor: "rgb(232, 232, 232)",
    zIndex: 3,
    padding: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
  },
  textThreshold: {
    textAlign: "right",
    marginBottom: 5,
    marginTop: 10,
  },
  evaluationButton: {
    marginRight: 30,
  },
} as const;

type Props = {
  onCalculateSensitivity: () => void;
  onChangeEvaluation: (active: boolean) => void;
  onChangeThreshold: (value: number) => void;
  evaluationEnable: boolean;
  showSensitivity: boolean;
  autoSensitivityData: { okPercents: number; nokPercents: number }[];
  threshold: number;
  scores: {
    ok: number[];
    nok: number[];
  } | null;
  t: (key: string) => string;
};

type State = {
  threshold: number | string;
  prevPropsThreshold: number | string;
};

class HeatmapChart extends Component<Props, State> {
  state: State = {
    threshold: this.props.threshold.toFixed(4),
    prevPropsThreshold: this.props.threshold,
  };

  saveThreshold?: (value: number) => void;

  componentDidMount() {
    this.setState({
      threshold: this.props.threshold.toFixed(4),
    });
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.threshold !== state.prevPropsThreshold) {
      return {
        threshold: props.threshold.toFixed(4),
        prevPropsThreshold: props.threshold,
      };
    }
    return null;
  }

  render() {
    const _ = this.props.t;
    return (
      <>
        {this.props.showSensitivity && (
          // eslint-disable-next-line
          <div
            onKeyDown={(e) => e.stopPropagation()}
            onKeyPress={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
          >
            <div>
              {this.props.autoSensitivityData && (
                <>
                  <div style={{ display: "grid" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.props.evaluationEnable}
                          onChange={(e) =>
                            this.props.onChangeEvaluation(e.target.checked)
                          }
                        />
                      }
                      label={_("evaluation")}
                    />
                    <TextField
                      fullWidth
                      label={_("threshold")}
                      style={styles.textThreshold}
                      value={this.state.threshold}
                      size="small"
                      onChange={(e) =>
                        this.setState({ threshold: e.target.value })
                      }
                      variant="outlined"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const floatValue = parseFloat(
                            (e.target as any).value
                          );
                          if (!isNaN(floatValue)) {
                            const fixedValue = Math.max(floatValue, 0);
                            this.props.onChangeThreshold(floatValue);
                            this.setState({
                              threshold: fixedValue.toFixed(4),
                            });
                          } else {
                            this.setState({
                              threshold: this.props.threshold,
                            });
                          }
                        }
                      }}
                      onBlur={(e) => {
                        const floatValue = parseFloat(e.target.value);
                        if (!isNaN(floatValue)) {
                          const fixedValue = Math.max(floatValue, 0);
                          this.props.onChangeThreshold(floatValue);
                          this.setState({
                            threshold: fixedValue.toFixed(4),
                          });
                        } else {
                          this.setState({
                            threshold: this.props.threshold,
                          });
                        }
                      }}
                    />
                  </div>
                  {this.props.scores && (
                    <PeakChart
                      onChangeThreshold={(value) => {
                        this.props.onChangeThreshold(value);
                        this.setState({
                          threshold: value.toFixed(4),
                        });
                      }}
                      data={{
                        ok: this.props.scores.ok,
                        nok: this.props.scores.nok,
                        threshold: this.props.threshold,
                      }}
                    />
                  )}
                  <Button
                    onClick={this.props.onCalculateSensitivity}
                    variant="outlined"
                    size="small"
                    fullWidth
                    color="secondary"
                    style={{ marginTop: 10 }}
                  >
                    {_("recalculate_threshold")}
                  </Button>
                </>
              )}
              {!this.props.autoSensitivityData && (
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  color="secondary"
                  onClick={this.props.onCalculateSensitivity}
                >
                  {_("calculate_sensitivity")}
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withI18n(HeatmapChart);
