import React from "react";
import { makeStyles } from "tss-react/mui";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Form from "./Form";
import utilsTypes, { Values } from "./utilsTypes";
import useI18n from "../../../../hooks/useI18n";
import { Histrogram, ImageData } from "../../../../actions/ImageService";
import * as Flow from "../../../../types/flow";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
  },
  panel: {
    padding: `0 ${theme.spacing(2)}`,
    cursor: "move",
  },
  expansionPanel: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  switch: {
    position: "absolute",
    right: 58,
    top: 4,
  },
  checkbox: {
    marginTop: -19,
    marginBottom: -19,
  },
  detail: {
    width: "100%",
  },
  deleteIcon: {
    marginLeft: -7,
    marginTop: 9,
  },
}));

type Props = {
  index: number;
  onChange: (values: Values) => void;
  values: Values;
  type: string;
  active: boolean;
  onChangeActive: (active: boolean) => void;
  onDelete: () => void;
  expanded: boolean;
  onChangeExpanded: (expanded: boolean) => void;
  imageKey: string;
  moduleId: number;
  getImageData: (numberOfItems: number) => Promise<{ data: ImageData }>;
  getImageHistogram: (numberOfItems: number) => Promise<{ data: Histrogram }>;
  getImageFlow: (numberOfItems: number) => Flow.Flow;
  isDragging: boolean;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
};

const Card = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      index,
      onChange,
      values,
      type,
      active,
      onChangeActive,
      onDelete,
      getImageData,
      getImageHistogram,
      expanded,
      onChangeExpanded,
      imageKey,
      getImageFlow,
      moduleId,
      isDragging,
      draggableProps,
      dragHandleProps,
    },
    ref
  ) => {
    const { classes } = useStyles();
    const [_] = useI18n();
    const opacity = 1; //isDragging ? 0 : 1;
    const currentStructure = utilsTypes.find((i) => i.id === type);

    const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onChangeActive(e.target.checked);
    };

    if (!currentStructure) {
      return null;
    }

    return (
      <Accordion
        ref={ref}
        disableGutters
        expanded={expanded}
        onChange={(_, r) => onChangeExpanded(r)}
        className={classes.root}
        style={{ opacity }}
        {...draggableProps}
      >
        <AccordionSummary
          className={classes.panel}
          expandIcon={<ExpandMoreIcon />}
          {...dragHandleProps}
        >
          <Grid container justifyContent="space-between">
            <Grid item>{_(currentStructure.label)}</Grid>
            <Grid>
              <Checkbox
                className={classes.checkbox}
                checked={active}
                onChange={handleChangeActive}
                onClick={(e) => e.stopPropagation()}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanel}>
          {expanded && (
            <div className={classes.detail} draggable={false}>
              <Form
                onChange={onChange}
                values={values}
                imageKey={imageKey}
                getImageData={getImageData}
                getImageFlow={getImageFlow}
                getImageHistogram={getImageHistogram}
                itemIndex={index}
                items={currentStructure.formItems}
                type={type}
                moduleId={moduleId}
              />
              <IconButton
                className={classes.deleteIcon}
                onClick={onDelete}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default Card;
