import { connect, ConnectedProps } from "react-redux";
import ListView from "./components/ListView";
import { setOutputItems } from "../../actions/actions";
import { GlobalState } from "../../store/createStore";

const mapStateToProps = (state: GlobalState) => {
  return {
    items: state.database.outputItems,
    cloudSave: state.system.disableCodeModule,
    enableGPIO: state.system.enableGPIO,
    enableMXIO: state.system.enableMXIO,
    language: state.system.lang, // refresh language
    disabled: state.system.restricted,
  };
};

const connector = connect(mapStateToProps, { setOutputItems });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ListView);
