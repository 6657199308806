import React, { FC } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { SERVER_IMAGE_URL } from "../../config";
import useI18n from "../../hooks/useI18n";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "absolute",
  },
  empty: {
    background: "#666",
    border: "1px solid #444",
    color: "white",
    overflow: "hidden",
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {
  position: {
    x: number;
    y: number;
  };
  width: number;
  style?: any;
  src: string;
  disableAbsolutePosition?: boolean;
};

const Image: FC<Props> = ({
  position,
  style,
  width,
  src,
  disableAbsolutePosition,
}) => {
  const { classes } = useStyles();
  const [t] = useI18n();

  const elementStyle = {
    position: "relative",
    left: position.x,
    top: position.y,
    width,
    ...style,
  };

  return (
    <div
      className={!disableAbsolutePosition ? classes.root : undefined}
      style={elementStyle}
    >
      {src ? (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img src={`${SERVER_IMAGE_URL}/assets/${src}`} style={{ width }} />
      ) : (
        <div
          className={classes.empty}
          style={{ width, height: (width / 3) * 2 }}
        >
          {t("image")}
        </div>
      )}
    </div>
  );
};

export default Image;
