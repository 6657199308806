import React, { PureComponent } from "react";

const styles = {
  wrap: {
    width: 190,
    margin: "0 20px",
    whiteSpace: "nowrap",
    position: "relative",
    padding: 13,
  },
} as const;

export default class EmptyModule extends PureComponent {
  render() {
    return <div style={styles.wrap} />;
  }
}
