import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles<void, "root">()((theme, _params, classes) => ({
  root: {
    overflow: "hidden",
    //background: "#f5f5f5",
    marginRight: -16,
    marginLeft: -16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    "&:not(:first-of-type)": {
      marginTop: 0,
      borderTop: "1px solid #e0e0e0",
    },
    paddingBottom: 16,
  },
  title: {
    fontSize: 14,
    marginBottom: 7,
    fontWeight: 500,
  },
  extraMargin: {
    marginBottom: 18,
  },
}));

type Props = {
  title?: string;
  className?: string;
  children: React.ReactNode;
  extraTopMargin?: boolean;
};

const ModuleSettingsWrap: FC<Props> = (props) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root)}>
      {props.title && (
        <Typography
          className={cx(classes.title, {
            [classes.extraMargin]: props.extraTopMargin,
          })}
          color={grey["700"]}
        >
          {props.title}
        </Typography>
      )}
      {props.children}
    </div>
  );
};

export default ModuleSettingsWrap;
