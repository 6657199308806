/* eslint no-param-reassign: 0 */
import moment from "moment";
import { Image } from "../types/common";
import { fileNameSortAsc, fileNameSortDesc } from "./common";

export const SORT_NEW = "SORT_NEW";
export const SORT_OLD = "SORT_OLD";
export const SORT_AZ = "SORT_AZ";
export const SORT_ZA = "SORT_ZA";

export const FILTER_ALL = "FILTER_ALL";
export const FILTER_MARKED = "FILTER_MARKED";
export const FILTER_NONE_MARKED = "FILTER_NONE_MARKED";
export const FILTER_VALIDATE = "FILTER_VALIDATE";
export const FILTER_TRAINING = "FILTER_TRAINING";
export const FILTER_OK_NOK = "FILTER_OK_NOK";
export const FILTER_OK = "FILTER_OK";
export const FILTER_NOK = "FILTER_NOK";
export const FILTER_SUCCESS = "FILTER_SUCCESS";
export const FILTER_WRONG = "FILTER_WRONG";

export type Sort = "SORT_NEW" | "SORT_OLD" | "SORT_AZ" | "SORT_ZA";
export type TrainingType =
  | "FILTER_ALL"
  | "FILTER_VALIDATE"
  | "FILTER_TRAINING"
  | "FILTER_NONE_MARKED";
export interface FilterSettings {
  id: string;
  sort: Sort;
  idFrom: number | null;
  idTo: number | null;
  text: string;
  from: string | null;
  to: string | null;
  mark: "FILTER_ALL" | "FILTER_MARKED" | "FILTER_NONE_MARKED" | null;
  trainingType: TrainingType | null;
  okNokType: "FILTER_ALL" | "FILTER_OK" | "FILTER_NOK" | "FILTER_OK_NOK" | null;
  statsType: "FILTER_ALL" | "FILTER_SUCCESS" | "FILTER_WRONG" | null;
  imageIds: number[] | null;
  processed: boolean;
  tags: number[];
}

export interface FilterImage extends Image {
  isMarked?: boolean;
  warning?: boolean;
  isOk?: boolean;
  isNok?: boolean;
  isValidate?: boolean;
  isTraining?: boolean;
  stats?: boolean;
  label2?: string;
  disabled?: boolean;
}

export const sort = <T extends Image>(imgs: T[], type: Sort): T[] => {
  let images = [...imgs];
  if (type === SORT_NEW) {
    images = images.sort((a, b) => {
      if (a.creationDate === b.creationDate) {
        return fileNameSortAsc(a.label || "", b.label || "");
      }
      return b.creationDate - a.creationDate;
    });
  } else if (type === SORT_OLD) {
    images = images.sort((a, b) => {
      if (a.creationDate === b.creationDate) {
        return fileNameSortDesc(a.label || "", b.label || "");
      }
      return a.creationDate - b.creationDate;
    });
  } else if (type === SORT_AZ) {
    images = images.sort((a, b) =>
      fileNameSortAsc(a.label || "", b.label || "")
    );
  } else if (type === SORT_ZA) {
    images = images.sort((a, b) =>
      fileNameSortDesc(a.label || "", b.label || "")
    );
  }
  return images;
};

export const filter = (
  images: FilterImage[],
  {
    idFrom = null,
    idTo = null,
    text = "",
    from = null,
    to = null,
    mark = null,
    trainingType = null,
    okNokType = null,
    statsType = null,
    imageIds = null,
    tags = [],
  }: Partial<FilterSettings>
) => {
  images = [...images];

  if (tags && tags.length > 0) {
    images = images.filter((i) => tags?.every((k) => i.tags?.includes(k)));
  }

  if (idFrom !== null) {
    images = images.filter((i) => i.id >= idFrom);
  }

  if (idTo !== null) {
    images = images.filter((i) => i.id <= idTo);
  }

  if (imageIds !== null) {
    images = images.filter((i) => imageIds.includes(i.id));
  }

  if (text !== "") {
    const textLowerCase = text.toLowerCase();
    images = images.filter(
      ({ label }) => label.toLowerCase().indexOf(textLowerCase) !== -1
    );
  }

  if (from || to) {
    const fromUnixTime = from && moment(from).toDate().getTime();
    const toUnixTime = to && moment(to).add("day", 1).toDate().getTime();
    images = images.filter(
      (i) =>
        (!fromUnixTime || fromUnixTime <= i.creationDate) &&
        (!toUnixTime || toUnixTime >= i.creationDate)
    );
  }

  if (mark === FILTER_MARKED) {
    images = images.filter((i) => i.isMarked || i.isValidate || i.isTraining);
  } else if (mark === FILTER_NONE_MARKED) {
    images = images.filter(
      (i) => !i.isMarked && !i.isValidate && !i.isTraining
    );
  }

  if (okNokType === FILTER_OK) {
    images = images.filter((i) => i.isOk);
  } else if (okNokType === FILTER_NOK) {
    images = images.filter((i) => i.isNok);
  } else if (okNokType === FILTER_OK_NOK) {
    images = images.filter((i) => i.isOk || i.isNok);
  }

  if (trainingType === FILTER_VALIDATE) {
    images = images.filter((i) => i.isValidate);
  } else if (trainingType === FILTER_TRAINING) {
    images = images.filter((i) => i.isTraining);
  } else if (trainingType === FILTER_NONE_MARKED) {
    images = images.filter((i) => !i.isTraining && !i.isValidate);
  }

  if (statsType === FILTER_SUCCESS) {
    images = images.filter((i) => i.stats === true);
  } else if (statsType === FILTER_WRONG) {
    images = images.filter((i) => i.stats === false);
  }

  return images;
};

export const defaultValues: FilterSettings = {
  id: "",
  from: null,
  to: null,
  idFrom: null,
  idTo: null,
  sort: SORT_NEW,
  mark: FILTER_ALL,
  okNokType: FILTER_ALL,
  trainingType: FILTER_ALL,
  statsType: FILTER_ALL,
  text: "",
  imageIds: null,
  processed: true,
  tags: [],
};

export const getFilter = (items: FilterSettings[], id: string) =>
  items.find((i) => i.id === id) || defaultValues;
