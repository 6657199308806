import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import RuntimeStatistics from "../../../Containers/RuntimeStatistics/RuntimeStatisticsContainer";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
}));

type Props = {
  open: boolean,
  onClose: () => void,
}

const StatisticsDialog = (props: Props) => {
  const { classes } = useStyles();
  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Statistics
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        {props.open && <RuntimeStatistics />}
      </div>
    </Dialog>
  );
};

export default StatisticsDialog;
