import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface WithRequiredModelProps<T> {
  model?: T;
}

interface WithModelNotFoundProps {
  model: undefined;
}

type HocProps<P> = P extends WithRequiredModelProps<infer T>
  ? WithRequiredModelProps<T>
  : WithModelNotFoundProps;

export function withRequiredModel<P extends WithRequiredModelProps<unknown>>(
  WrappedComponent: React.ComponentType<P>
): React.FC<HocProps<P>> {
  const WithRequiredModel: React.FC<HocProps<P>> = ({ model, ...props }) => {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      if (!model) {
        history.push(
          location.pathname.substring(
            0,
            location.pathname.lastIndexOf("/detail") + 1
          )
        );
      }
    }, [model, history, location.pathname]);
    if (!model) {
      return null;
    }
    return <WrappedComponent {...(props as any)} model={model} />;
  };
  WithRequiredModel.displayName = `WithRequiredModel(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithRequiredModel;
}
