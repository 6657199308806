import { makeStyles } from 'tss-react/mui';
import React, { FC } from "react";
import { useAppSelector } from "../hooks/store";
import { Tag } from "../types/common";
import { getContrastColor } from "../utils/tags";

const getTagsKeys = (tags: Tag[]) => {
  const response: {
    [key: number]: {
      label: string;
      backgroundColor: string;
      textColor: string;
    };
  } = {};
  tags.forEach((i) => {
    response[i.id] = {
      label: i.label,
      backgroundColor: i.color,
      textColor: getContrastColor(i.color),
    };
  });
  return response;
};

const useStyles = makeStyles<void, 'item'>()((_theme, _params, classes) => ({
  item: {
    borderRadius: 1,
    padding: "2px 3px",
    backgroundColor: "#06baec",
    color: "white",
    whiteSpace: "nowrap",
    fontSize: 11,
    lineHeight: "14px",
    [`&+.${classes.item}`]: {
      marginLeft: 2,
    },
  },
}));

type Props = {
  tagsIds: number[];
};

const Tags: FC<Props> = ({ tagsIds }) => {
  const { classes } = useStyles();

  const { tagsKeys } = useAppSelector((state) => ({
    tagsKeys: getTagsKeys(state.database.tags || []),
  }));

  return (
    <>
      {tagsIds.map((i) => (
        <span
          key={i}
          className={classes.item}
          style={{
            color: tagsKeys[i]?.textColor,
            backgroundColor: tagsKeys[i]?.backgroundColor,
          }}
        >
          {tagsKeys[i]?.label || "not-found"}
        </span>
      ))}
    </>
  );
};

export default Tags;
