import { connect, ConnectedProps } from "react-redux";
import CodeListView from "./components/CodeListView";
import { codeSetSourceCode } from "../../actions/actions";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { CodeModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const module = getModule(
    state,
    +ownProps.match.params.moduleId
  ) as CodeModule;
  return {
    moduleId: +ownProps.match.params.moduleId,
    values: module.values,
    module,
    items: state.database.codeItems,
    activeFileId: module.fileId,
  };
};

const connector = connect(mapStateToProps, { codeSetSourceCode });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodeListView);
