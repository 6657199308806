import React, { FC, useContext, useState } from "react";
import { unifierCreate, unifierDelete } from "../../../actions/actions";
import ModelList from "../../../components/templates/ModelList";
import SelectImageDialog from "./SelectImageDialog";
import { getImageData } from "../../../actions/ImageService";
import { PropsFromRedux } from "../ListContainer";
import useI18n from "../../../hooks/useI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const UnifierListView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const context = useContext(HelperContext);
  const [templateName, setTemplateName] = useState<null | string>(null);

  const handleSelectImage = (imageIds: number[]) => {
    if (imageIds.length !== 1) {
      return;
    }
    const imageId = imageIds[0];
    const flow = context.helper.getSettings({
      moduleId: props.moduleId,
    });
    getImageData(imageId, flow, props.token)
      .then(({ data }) => {
        const idClassnames = data.rectangles
          .map((i) =>
            i.classNames && i.classNames.length > 0 ? i.classNames[0].id : null
          )
          .filter((i) => i !== null);
        if (
          Array.from(new Set(idClassnames)).length > 1 &&
          templateName !== null
        ) {
          unifierCreate({
            moduleId: props.moduleId,
            imageId: imageId,
            name: templateName,
            flow: context.helper.getSettings({
              moduleId: props.moduleId,
            }),
          });
          setTemplateName(null);
        } else {
          // eslint-disable-next-line no-alert
          alert(_("unifier_select_image_error"));
        }
      })
      // eslint-disable-next-line no-alert
      .catch(() => alert(_("fatal_error_message")));
  };

  return (
    <>
      <ModelList
        t={_}
        moduleId={props.moduleId}
        items={props.items}
        onCreate={({ name }) => setTemplateName(name)}
        isItemActive={(item) => {
          if (item) {
            if (props.activeUnifierId) {
              return props.activeUnifierId === item.itemId;
            }
            return false;
          }
          return !props.activeUnifierId;
        }}
        onSetItem={(item) => {
          props.unifierSetActiveUnifierId({
            moduleId: props.moduleId,
            itemId: item ? item.itemId : null,
          });
        }}
        onClickItem={({ itemId }) => {
          props.history.push(`/modules/unifier/${props.moduleId}/${itemId}/`);
        }}
        onClickDelete={({ itemId }) => {
          unifierDelete(itemId);
        }}
        createTitle={_("unifier_new_placeholder")}
      />
      <SelectImageDialog
        open={templateName !== null}
        onClose={() => setTemplateName(null)}
        moduleId={props.moduleId}
        onSelect={handleSelectImage}
        images={props.images}
      />
    </>
  );
};

export default UnifierListView;
