import React, { Component } from "react";
import ObjectMarker, {
  Props as ObjectMarkerProps,
} from "./ObjectMarker/ObjectMarker";
import LoaderImg from "./Rolling.svg";
import { ImageSize, Line, Rectangle } from "../../types/common";
import withI18n from "../../utils/withI18n";
import { Tool } from "./Dashboard/Objects/Dashboard";
import { CONTENT_INNER_PADDING } from "../../theme";

const styles = {
  wrap: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  imageLabel: {
    position: "absolute",
    left: CONTENT_INNER_PADDING,
    top: 8,
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 79px)",
    textOverflow: "ellipsis",
    height: 36,
    lineHeight: "36px",
    padding: `0 ${CONTENT_INNER_PADDING}px`,
  },
  error: {
    position: "absolute",
    fontSize: 24,
    color: "white",
    backgroundColor: "rgba(255,0,0,0.8)",
    padding: 10,
    bottom: 0,
    left: 0,
    zIndex: 3,
  },
  loading: {
    position: "absolute",
    fontSize: 24,
    color: "white",
    backgroundColor: "rgba(0,0,0,0.8)",
    padding: 10,
    bottom: 0,
    left: 0,
    zIndex: 3,
  },
  loaderImg: {
    width: 26,
    height: 26,
    top: 3,
    position: "relative",
  },
  imageLabelSize: {
    color: "rgb(101, 101, 101)",
    marginLeft: 20,
  },
  patchSize: {
    position: "absolute",
    top: -3,
    left: -14,
  },
} as const;

export type Props = Omit<
  ObjectMarkerProps,
  | "onChangeRectangles"
  | "imgWidth"
  | "imgHeight"
  | "width"
  | "height"
  | "imgSrc"
> & {
  imageId: number;
  defaultScale?: number;
  showError?: boolean;
  showLoader?: boolean;
  moduleId: number | null;
  getImageSrc: (imageId: number) => any;
  imageLabel: string;
  onChangeRectangles: (imageId: number, rectangles: Rectangle[]) => void;
  t: (key: string) => string;
  renderPanel?: () => any;
  tool?: Tool;
  onChangeLines?: (lines: Line[]) => void;
  imgSize: ImageSize;
};

class ImageDetail extends Component<Props> {
  handleChangeRectangles = (rectangles: Rectangle[]) => {
    if (!this.props.imgSize) {
      return;
    }
    const currentImageId = this.props.imageId;
    this.props.onChangeRectangles(currentImageId, rectangles);
  };

  render() {
    const _ = this.props.t;
    return (
      <div style={styles.wrap}>
        <ObjectMarker
          onChangeLines={this.props.onChangeLines}
          tool={this.props.tool}
          minRectangleSize={this.props.minRectangleSize}
          disabledKeyListener={this.props.disabledKeyListener}
          imgSrc={this.props.getImageSrc(this.props.imageId)}
          rectangles={this.props.rectangles}
          allowOverflow={this.props.allowOverflow}
          paddingBorder={this.props.paddingBorder}
          maxRatio={this.props.maxRatio}
          square={this.props.square}
          onChangeRectangles={this.handleChangeRectangles}
          fixSize={this.props.fixSize}
          readOnly={this.props.readOnly}
          onSelectRectangle={this.props.onSelectRectangle}
          selectedRectangle={this.props.selectedRectangle}
          classifierMode={this.props.classifierMode}
          showCircle={this.props.showCircle}
          onOpenHelper={this.props.onOpenHelper}
          defaultClassName={this.props.defaultClassName}
          enableRotate={this.props.enableRotate}
          patchSize={this.props.patchSize}
          onChangePatchSize={this.props.onChangePatchSize}
          lastRectangleSize={this.props.lastRectangleSize}
          onChangeLastRectangle={this.props.onChangeLastRectangle}
          lineMode={this.props.lineMode}
          lines={this.props.lines}
          patchSizeProps={this.props.patchSizeProps}
          imgWidth={this.props.imgSize.width}
          imgHeight={this.props.imgSize.height}
        />
        <span style={styles.imageLabel}>
          {this.props.renderPanel?.()}
          {this.props.imageLabel}
          <span style={styles.imageLabelSize}>
            {this.props.imgSize &&
              `${this.props.imgSize.width}x${this.props.imgSize.height}`}
          </span>
        </span>
        {this.props.showError && (
          <span style={styles.error}>{_("executor_error")}</span>
        )}
        {this.props.showLoader && (
          <span style={styles.loading} className="fade-in">
            {" "}
            <img style={styles.loaderImg} src={LoaderImg} alt="loading" />{" "}
            {_("loading")}
          </span>
        )}
      </div>
    );
  }
}

export default withI18n(ImageDetail);
