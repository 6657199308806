import React, { FC } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from 'tss-react/mui';
import useI18n from "../../hooks/useI18n";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: `0 ${theme.spacing(1)}`,
  },
}));

type Props = {
  label: string,
  active?: boolean,
  checked?: boolean,
  onChangeActive: (checked: boolean) => void
}

const PageToolbarCheckbox:FC<Props> = (props) => {
  const [_] = useI18n()
  const { classes } = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox
          checked={props.active || props.checked}
          onChange={(e) => props.onChangeActive(e.target.checked)}
        />
      }
      label={_(props.label)}
    />
  );
};

export default PageToolbarCheckbox;
