import React, { FC } from "react";
import Button from "@mui/material/Button";
import useI18n from "../hooks/useI18n";

type Props = {
  onClick: () => void;
  style?: any;
};

const EvaluationButton: FC<Props> = (props) => {
  const [_] = useI18n();
  return (
    <Button
      style={{ ...(props.style || {}) }}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      onClick={props.onClick}
    >
      {_("evaluation")}
    </Button>
  );
};

export default EvaluationButton;
