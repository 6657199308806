import React, { Component } from "react";
import intersection from "lodash/intersection";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import ImageListWithMarkers from "./ImageListWithMarkers/ImageListWithMarkers";
import ClassNameList from "./ClassNameList";
import ModuleRightToolPanel from "./ModuleRightToolPanel";
import ModuleSettingsWrap from "./ModuleSettingsWrap";
import AutoClassifierDialog from "./AutoClassifierDialog";
import { imagesAutoClassification } from "../utils/common";
import { Image, Rectangle } from "../types/common";
import withI18n from "../utils/withI18n";
import { connect, ConnectedProps } from "react-redux";
import { addErrorMessage } from "../store/system/reducer";

const styles = {
  dialog: {
    width: "calc(100% - 100px)",
    maxWidth: "none",
  },
  wrap: {
    height: "calc(100vh - 260px)",
  },
  dialogBody: {
    paddingBottom: 0,
  },
};

const mapDispatchToProps = {
  addErrorMessage,
};

const connector = connect(null, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export type AutoThreholdAnnotations = {
  [key: number]: { [key: number]: number };
};

type Props = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
  onStart: (data: AutoThreholdAnnotations) => void;
  onChangeSensitivityClassification: (data: {
    ok: number[];
    nok: number[];
  }) => void;
  images: Image[];
  values: {
    ok: number[];
    nok: number[];
  };
  moduleId: number;
  t: (key: string) => string;
  getImageItem: (image: Image) => any;
};

type State = {
  annotationOk: number[];
  annotationNok: number[];
  showErrorDialog: boolean;
  showAutoClassifierDialog: boolean;
  selectedIds: number[];
  currentImage: null | Image;
  rectangle: null | Rectangle;
  values: AutoThreholdAnnotations;
};

class AutoThresholdDialog extends Component<Props, State> {
  state: State = {
    currentImage: null,
    annotationOk: [],
    annotationNok: [],
    showErrorDialog: false,
    showAutoClassifierDialog: false,
    selectedIds: [],
    rectangle: null,
    values: {},
  };

  componentDidMount() {
    if (this.props.open) {
      this.initData(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      this.initData(this.props);
    }
  }

  initData = (props: Props) => {
    const { images, values } = props;
    const imagesIds = images.map((i) => i.id);
    this.setState({
      annotationNok: intersection(values.nok, imagesIds),
      annotationOk: intersection(values.ok, imagesIds),
    });
  };

  handleClickStart = () => {
    if (
      true
      // this.state.annotationOk.length > 0 //&&
      // this.state.annotationNok.length > 0
    ) {
      this.props.onStart(this.state.values);
      this.props.onClose();
    } else {
      this.setState({
        showErrorDialog: true,
      });
    }
  };

  handleClickClose = () => {
    this.props.onClose();
  };

  getImageLabel = (i: Image, isValidate: boolean) => {
    const _ = this.props.t;
    const ok = this.state.annotationOk.indexOf(i.id) !== -1;
    const nok = this.state.annotationNok.indexOf(i.id) !== -1;
    // eslint-disable-next-line no-nested-ternary
    const label = `${isValidate ? "V " : isValidate === false ? "T " : ""}${
      ok ? _("ok") : ""
    }${nok ? _("ng") : ""}`;
    return { label, isOk: ok, isNok: nok };
  };

  handleClickAutoClassification = () => {
    this.setState({
      showAutoClassifierDialog: true,
    });
  };

  handleClickResetModuleValues = () => {
    this.setState({
      annotationNok: [],
      annotationOk: [],
    });

    this.props.onChangeSensitivityClassification({
      ok: [],
      nok: [],
    });
  };

  getCurrentRectangle = (image: Image) => {
    const rec0: Rectangle = {
      id: 10,
      x: 0,
      y: 0,
      width: 0.4,
      height: 0.4,
      detected: true,
      percent: true,
    } as Rectangle;
    const rec1: Rectangle = {
      id: 11,
      x: 0.5,
      y: 0,
      width: 0.4,
      height: 0.4,
      detected: true,
      percent: true,
    } as Rectangle;
    return [rec0, rec1];
  };

  render() {
    const { currentImage } = this.state;
    const _ = this.props.t;

    const actionsMain = [
      <Button color="primary" onClick={this.handleClickClose}>
        {_("close")}
      </Button>,
      <Button color="primary" onClick={this.handleClickStart}>
        {_("submit")}
      </Button>,
    ];
    const actionsError = [
      <Button
        color="primary"
        onClick={() => this.setState({ showErrorDialog: false })}
      >
        {_("close")}
      </Button>,
    ];

    /*const trainingOk = intersection(
      this.props.model.classification ? this.props.model.classification.ok : [],
      this.props.model.trainingImages
    );*/

    const ok = this.state.annotationOk;
    const nok = this.state.annotationNok;

    const classNames = [
      { id: 1, label: _("ok"), count: ok.length },
      { id: 2, label: _("ng"), count: nok.length },
      { id: 3, label: "Cancelled", count: 0 },
    ];

    const currentRectangle = this.state.rectangle;
    return (
      <>
        <Dialog
          actions={actionsMain}
          open={this.props.open}
          onRequestClose={this.props.onClose}
          contentStyle={styles.dialog}
          title={_("auto_sensitivity")}
        >
          <div style={styles.wrap}>
            <ImageListWithMarkers
              onSelectRectangle={(rectangle) => this.setState({ rectangle })}
              formatRectangles={(str) => {
                return {
                  ...str,
                  detected: true,
                  disabled: false,
                };
              }}
              ImageDetailProps={{
                classifierMode: true,
                selectedRectangle: this.state.rectangle,
              }}
              ImageListProps={{
                showSelectedFilter: true,
                showOkNokFilter: true,
                onSelectMore: (ids) =>
                  this.setState({
                    selectedIds: ids,
                  }),
                getImageItem: (i: Image) => {
                  const item = this.props.getImageItem(i);
                  const disabled = false; //trainingOk.indexOf(i.id) !== -1;
                  const { label, isOk, isNok } = this.getImageLabel(
                    i,
                    item.isValidate
                  );
                  return {
                    ...item,
                    isOk,
                    isNok,
                    disabled,
                    label2: disabled ? item.label2 : label,
                  };
                },
              }}
              withMask
              moduleId={this.props.moduleId}
              includeLast
              detailHeight="calc((100vh - 286px))"
              listHeight="calc((100vh - 266px))"
              disabledKeyListener={this.state.showAutoClassifierDialog}
              onSelectImage={(image) => {
                this.setState({
                  currentImage: image,
                });
              }}
            >
              <ModuleRightToolPanel>
                <ModuleSettingsWrap title={_("annotations")}>
                  <ClassNameList
                    t={_}
                    onClickAutoClassifier={this.handleClickAutoClassification}
                    onClickRemove={this.handleClickResetModuleValues}
                    isActive={!!(currentImage && currentRectangle)}
                    onChange={(id) => {
                      if (!currentImage || !currentRectangle) {
                        return;
                      }
                      const rectangleRecord = {
                        [currentRectangle.id as number]: id as number,
                      };
                      this.setState(({ values }) => ({
                        values: {
                          ...values,
                          [currentImage.id]: values[currentImage.id]
                            ? { ...values[currentImage.id], ...rectangleRecord }
                            : rectangleRecord,
                        },
                      }));
                    }}
                    value={
                      currentImage && currentRectangle
                        ? this.state.values[currentImage.id]?.[
                            currentRectangle.id as number
                          ] ?? 0
                        : null
                    }
                    items={classNames}
                  />
                </ModuleSettingsWrap>
              </ModuleRightToolPanel>
            </ImageListWithMarkers>
          </div>
        </Dialog>
        <Dialog
          actions={actionsError}
          open={this.state.showErrorDialog}
          onRequestClose={() =>
            this.setState({
              showErrorDialog: false,
            })
          }
        >
          {_("unsupervised_calc_error")}
        </Dialog>
        <AutoClassifierDialog
          open={this.state.showAutoClassifierDialog}
          onClose={() => this.setState({ showAutoClassifierDialog: false })}
          classNames={classNames}
          onSet={(patternItems, isRegex) => {
            const { results } = imagesAutoClassification(
              this.props.images,
              patternItems,
              isRegex
            );
            // if (error) {
            //   this.props.addErrorMessage(error)
            // }
            this.setState({
              annotationOk: results[0],
              annotationNok: results[1],
              showAutoClassifierDialog: false,
            });

            this.props.onChangeSensitivityClassification({
              ok: results[0],
              nok: results[1],
            });
          }}
        />
      </>
    );
  }
}

export default connector(withI18n(AutoThresholdDialog));
