import React, { FC, useEffect, useState } from "react";
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import SliderMUI from '@mui/material/Slider';
import { Grid, TextField, Typography } from "@mui/material";


const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'absolute',
  }
}));


type Props = {
  value?: number,
  onChange: (value: number) => void,
  position: {
    x: number,
    y: number,
  },
  width: number,
  min: number,
  max: number,
  label?: string,
  style?: any,
  showTextField: boolean,
  disableAbsolutePosition?: boolean,
}

const Slider: FC<Props> = ({ value, onChange, position, style, width, disableAbsolutePosition, min, max, label, showTextField }) => {
  const { classes } = useStyles()
  const [localValue, setLocalValue] = useState<string>(value !== undefined ? `${value}` : '')
  const elementStyle = {
    position: 'relative',
    left: position.x, top: position.y, width, ...style
  }

  useEffect(() => {
    setLocalValue(value !== undefined ? `${value}` : '')
  }, [value])

  return (
    <div className={!disableAbsolutePosition ? classes.root : undefined} style={elementStyle}>
      {label && (
        <Typography id="input-slider" gutterBottom>
          {label}
        </Typography>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <SliderMUI
            style={{ width: '100%' }}
            value={typeof value === 'number' ? value : 0}
            onChange={(e, nextValue) => onChange(nextValue as number)}
            aria-labelledby="input-slider"
            min={min}
            max={max}
          />
        </Grid>
        {showTextField && (
          <Grid item>
            <TextField
              variant="outlined"
              value={localValue}
              size="small"
              onChange={(e) => {
                setLocalValue(e.target.value)
                const number = Number(e.target.value)
                if (e.target.value === '' || isNaN(number)) {
                  return
                }
                if (number <= max && number >= min) {
                  onChange(number)
                }
              }}
              onBlur={() => {
                const number = Number(localValue)
                if (localValue === '' || isNaN(number) || number > max || number < min) {
                  setLocalValue(value ? `${value}` : '')
                }
              }}
              inputProps={{
                min,
                max,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
};

export default Slider;
