import React, { useEffect, useState } from "react";

import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { formatDatepicker, formatDateTime } from "../../../utils/format";
import { getStatistics, StatisticDay } from "../../../actions/StatisticsService";
import useI18n from "../../../hooks/useI18n";

const styles = {
  header: {
    marginTop: 10,
  },
  charts: {
    marginTop: 20,
  },
};


type Props = {
  token: string
}

const RuntimeStatistics = (props: Props) => {
  const [state, setState] = useState<{ dateFrom: number, dateTo: number }>({
    dateFrom: Date.now(),
    dateTo: Date.now(),
  })
  const [data, setData] = useState<StatisticDay[] | undefined>()
  const [_] = useI18n()
  const getStats = () => {
    getStatistics(
      Math.round(state.dateFrom / 1000),
      Math.round(state.dateTo / 1000),
      props.token
    ).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getStats()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {index === 0 ? _("ok") : _("ng")} {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const pieData =
    data &&
    data.reduce(
      (a, b) => {
        // eslint-disable-next-line no-param-reassign
        a[0].value += b.ok;
        // eslint-disable-next-line no-param-reassign
        a[1].value += b.nok;
        return a;
      },
      [
        { label: "ok", value: 0 },
        { label: "ng", value: 0 },
      ]
    );

  return (
    <>
      {/* <Grid container spacing={2}>
         <Grid item>
           <FormControlLabel
             control={<Switch />}
             label="Save image"
           />
         </Grid>
          <Grid item>
            <Button color="primary" variant="contained">Settings</Button>
          </Grid>
        </Grid> */}
      <div style={styles.header}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              type="date"
              value={formatDatepicker(state.dateFrom / 1000)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  dateFrom: moment(e.target.value).valueOf(),
                }));
              }}
              inputProps={{
                max: formatDatepicker(state.dateTo / 1000),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              type="date"
              value={formatDatepicker(state.dateTo / 1000)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  dateTo: moment(e.target.value).valueOf(),
                }));
              }}
              inputProps={{
                min: formatDatepicker(state.dateFrom / 1000),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={getStats}
              color="primary"
              variant="contained"
            >
              {_("show_statistics")}
            </Button>
          </Grid>
        </Grid>
      </div>

      <div style={styles.charts}>
        {data && (
          <>
            <LineChart
              width={800}
              height={300}
              data={data.map((i) => ({
                name: formatDateTime(i.date),
                [_("success_rate")]:
                  i.ok + i.nok === 0
                    ? null
                    : Math.round((i.ok / (i.ok + i.nok)) * 100),
              }))}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={_("success_rate")}
                stroke="#82ca9d"
              />
            </LineChart>

            <LineChart
              width={800}
              height={300}
              data={data.map((i) => ({
                name: formatDateTime(i.date),
                [_('images')]: i.ok + i.nok,
              }))}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey={_("images")} stroke="#82ca9d" />
            </LineChart>

            {pieData && pieData[0].value + pieData[1].value > 0 && (
              <PieChart width={400} height={400}>
                <Pie
                  data={pieData}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  outerRadius={80}
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                  dataKey="value"
                >
                  <Cell fill="#86c186" />
                  <Cell fill="#ff8c8c" />
                </Pie>
              </PieChart>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default RuntimeStatistics