import React, { FC } from "react";
import Button from "@mui/material/Button";
import useI18n from "../hooks/useI18n";
import { useAnyAppDispatch, useAppSelector } from "../hooks/store";
import { addErrorMessage } from "../store/system/reducer";

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const TrainingButton: FC<Props> = (props) => {
  const [_] = useI18n();
  const dispatch = useAnyAppDispatch();
  const { enable } = useAppSelector((state) => ({
    enable: state.system.license.withTraining,
  }));
  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      onClick={
        enable
          ? props.onClick
          : () => dispatch(addErrorMessage("license_without_training_info"))
      }
    >
      {_("start_train")}
    </Button>
  );
};

export default TrainingButton;
