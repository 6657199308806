import React from "react";
import { Label as LabelKonva, Tag, Text } from "react-konva";

type Props = {
  x: number;
  y: number;
  scale: number;
  text?: string;
};

const Label = (props: Props) => {
  const { scale, text } = props;
  if (!text) {
    return null;
  }

  return (
    <LabelKonva draggable={false} listening={false} y={props.y} x={props.x}>
      <Tag fill="rgba(0,0,0,0.5)"></Tag>
      <Text
        padding={2 / scale}
        fill="white"
        text={text}
        fontFamily="Roboto"
        fontSize={15 / scale}
      />
    </LabelKonva>
  );
};

export default Label;
