import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { formatDateTime } from "../../utils/format";
import withI18n from "../../utils/withI18n";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const styles = {
  textField: {
    marginTop: 10,
    width: 240,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (name: string) => void;
  title: string;
  t: (key: string) => string;
};

type State = {
  value: string;
  prevOpen: boolean;
};

class CreateDialog extends Component<Props, State> {
  state: State = {
    value: "",
    prevOpen: this.props.open,
  };

  private textRef = React.createRef<HTMLDivElement>();

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.open === state.prevOpen) {
      return null;
    }
    if (props.open) {
      return {
        prevOpen: true,
        value: formatDateTime(),
      };
    } else {
      return {
        prevOpen: false,
      };
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    this.setState({
      value: e.target.value,
    });
  };

  handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClose();
  };

  handleClickCreate = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onCreate(this.state.value);
  };

  render() {
    const { open, onClose } = this.props;
    const _ = this.props.t;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <TextField
            style={styles.textField}
            ref={this.textRef}
            fullWidth
            autoFocus
            label={_("name")}
            value={this.state.value}
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClickClose}>
            {_("close")}
          </Button>
          <Button
            color="primary"
            disabled={this.state.value === ""}
            onClick={this.handleClickCreate}
          >
            {_("create")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withI18n(CreateDialog);
