import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ImagesState = {
  que: string[],
  canLoad: string[],
  loading: null | string,
  deleteImages: null | number[],
  renameImage: null | number,
  tagsImages: number[],
  showTagsManager: boolean
}

// Define the initial state using that type
const initialState: ImagesState = {
  que: [],
  canLoad: [],
  loading: null,
  deleteImages: null,
  renameImage: null,
  tagsImages: [],
  showTagsManager: false
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    mount: (state, action: PayloadAction<string>) => {
      state.que = state.loading ? [...state.que, action.payload] : state.que
      if (!state.loading) {
        state.canLoad.push(action.payload)
      }
      state.loading = !state.loading ? action.payload : state.loading
    },
    loaded: (state) => {
      state.canLoad = state.que.length > 0 ? [...state.canLoad, state.que[0]] : state.canLoad
      state.que = state.que.filter((_, i) => i !== 0)
      state.loading = state.que.length > 0 ? state.que[0] : null
    },
    unmount: (state, action: PayloadAction<string>) => {
      state.que = state.que.filter((i) => i !== action.payload)
      state.canLoad = state.canLoad.filter((i) => i !== action.payload)
      state.loading = state.loading === action.payload ? null : state.loading
    },
    deleteImage: (state, action: PayloadAction<number[] | null>) => {
      state.deleteImages = action.payload
    },
    setRename: (state, action: PayloadAction<number | null>) => {
      state.renameImage = action.payload
    },
    setTagsImages: (state, action: PayloadAction<{ images: number[] }>) => {
      state.tagsImages = action.payload.images
    },
    setShowTagsManager: (state, action: PayloadAction<boolean>) => {
      state.showTagsManager = action.payload
    }
  }
})

export const { mount, unmount, loaded, deleteImage, setRename, setTagsImages, setShowTagsManager } = imagesSlice.actions

export default imagesSlice.reducer
