import React, { Component } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AutoSensitivityDialog from "../../../components/AutoSensitivityDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import HeatmapChart from "../../../components/templates/HeatmapChart";
import { getNumberDictionary } from "../../../store/utils";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import ChangeModelPanel from "../../../components/ChangeModelPanel";
import ModelListContainerUnsupervised from "../ListContainer";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import { PropsFromRedux } from "../ModelDetailContainer";

type Props = PropsFromRedux & { t: (key: string) => string };

export class ModelDetailView extends Component<Props> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  closeAutosensitivityDialog = () => {
    this.props.history.replace(
      `/modules/unsupervised/${this.props.moduleId}/validate/detail/`
    );
  };

  showAutoSensitivityDialog = () => {
    this.props.history.replace(
      `/modules/unsupervised/${this.props.moduleId}/validate/detail/evaluation`
    );
  };

  getImages = (props: Props) => {
    const _ = this.props.t;
    const trainingImagesDict = getNumberDictionary(props.model.trainingImages);
    const validateImagesDict = getNumberDictionary(props.model.validateImages);
    const classificationOkDict = getNumberDictionary(
      props.model.classification?.ok ?? []
    );

    const classificationNokDict = getNumberDictionary(
      props.model.classification?.nok ?? []
    );

    return props.images.map((i) => {
      const ok = this.isOk(i.id, classificationOkDict);
      const nok = this.isNok(i.id, classificationNokDict);

      if (trainingImagesDict[i.id]) {
        return {
          ...i,
          label2: `T${ok ? ` ${_("ok")}` : ""}${nok ? ` ${_("ng")}` : ""}`,
          isValidate: false,
          isTraining: true,
          isOk: ok,
          isNok: nok,
        };
      }
      if (validateImagesDict[i.id]) {
        return {
          ...i,
          label2: `V${ok ? ` ${_("ok")}` : ""}${nok ? ` ${_("ng")}` : ""}`,
          isValidate: true,
          isTraining: false,
          isOk: ok,
          isNok: nok,
        };
      }
      return i;
    });
  };

  isOk(imageId: number, classificationOkDict: { [n: number]: true }) {
    if (this.props.model.classification) {
      return !!classificationOkDict[imageId];
    }
    return null;
  }

  isNok(imageId: number, classificationNokDict: { [n: number]: true }) {
    if (this.props.model.classification) {
      return !!classificationNokDict[imageId];
    }
    return null;
  }

  render() {
    const _ = this.props.t;
    const images = this.getImages(this.props);

    return (
      <>
        <ImageListWithMarkers
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          disabledKeyListener={!!this.props.params.showAutoSensitivity}
          withMask
          moduleId={this.props.module.id}
          readOnly
          includeLast
          ImageListProps={{
            showSelectedFilter: true,
            images: images,
          }}
        >
          <ModuleRightToolPanel>
            {!this.props.simpleTutorialOnly && (
              <ChangeModelPanel model={this.props.model}>
                <ModelListContainerUnsupervised
                  moduleId={this.props.module.id}
                />
              </ChangeModelPanel>
            )}
            <ModuleSettingsWrap title={_("settings")}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.module.showHeatmap}
                      onChange={(e) => {
                        this.props.moduleChangeShowHeatmap({
                          moduleId: this.props.module.id,
                          show: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={_("show_heatmap")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.module.showRectangles}
                      onChange={(e) => {
                        this.props.moduleChangeShowRectangles({
                          moduleId: this.props.module.id,
                          show: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={_("show_rectangles")}
                />
              </FormGroup>
            </ModuleSettingsWrap>
            {!this.props.simpleTutorialOnly && (
              <ModuleSettingsWrap title={_("evaluation")}>
                <HeatmapChart
                  scores={
                    this.props.model.scores
                      ? {
                          ok: this.props.model.scores.okScores,
                          nok: this.props.model.scores.nokScores,
                        }
                      : null
                  }
                  showSensitivity={true}
                  threshold={
                    this.props.model.threshold === undefined
                      ? 50
                      : this.props.model.threshold
                  }
                  onChangeThreshold={(value) => {
                    this.props.setThreshold?.({
                      modelId: this.props.model.modelId,
                      value,
                    });
                  }}
                  onChangeEvaluation={(enable) => {
                    this.props.setEvaluation?.({
                      enable,
                      modelId: this.props.module.modelId as number,
                    });
                  }}
                  autoSensitivityData={this.props.model.sensitivityAnalyze}
                  onCalculateSensitivity={() => {
                    this.showAutoSensitivityDialog();
                  }}
                  evaluationEnable={this.props.model.evaluation || false}
                />
              </ModuleSettingsWrap>
            )}
          </ModuleRightToolPanel>
        </ImageListWithMarkers>
        <AutoSensitivityDialog
          open={!!this.props.params.showAutoSensitivity}
          getImageItem={(item) => images.find((i) => i.id === item.id)}
          images={this.props.images}
          model={this.props.model}
          module={this.props.module}
          onClose={() => this.closeAutosensitivityDialog()}
          onChangeSensitivityClassification={(classification) =>
            this.props.seSensitivityClassification?.({
              modelId: this.props.model.modelId,
              classification,
            })
          }
          onStart={(classification) => {
            this.closeAutosensitivityDialog();
            this.props.startSensitivityAnalyze?.({
              moduleId: this.props.moduleId,
              flow: this.context.helper.getSettings({
                moduleId: this.props.moduleId,
              }),
              classification,
            });
          }}
        />
      </>
    );
  }
}

export default withI18n(ModelDetailView);
