import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PageLayout from "./layouts/PageLayout/PageLayout";

import PageNotFound from "./components/PageNotFound";
import ModulesContainer from "./routes/Modules/ModulesContainer";
import DebuggerContainer from "./routes/Debugger/DebuggerContainer";
import CameraWrapContainer from "./routes/Camera/WrapContainer";
import CameraInputContainer from "./routes/Camera/InputContainer";
import CameraEditorContainer from "./routes/Camera/EditorContainer";
import CodeDetailContainer from "./routes/Code/DetailContainer";
import CodeWrapContainer from "./routes/Code/WrapContainer";
import CodeListContainer from "./routes/Code/ListContainer";
import CodeImageListContainer from "./routes/Code/ImageListContainer";
import DetectorWrapContainer from "./routes/Detector/WrapContainer";
import DetectorTrainingContainer from "./routes/Detector/TrainingContainer";
import DetectorModelListContainer from "./routes/Detector/SelectModelContainer";
import DetectorModelDetailContainer from "./routes/Detector/ModelDetailContainer";
import ClassifierWrapContainer from "./routes/Classifier/WrapContainer";
import ClassifierTrainingContainer from "./routes/Classifier/TrainingContainer";
import ClassifierModelListContainer from "./routes/Classifier/SelectModelContainer";
import ClassifierModelDetailContainer from "./routes/Classifier/ModelDetailContainer";
import MaskWrapContainer from "./routes/Mask/WrapContainer";
import MaskListContainer from "./routes/Mask/ListContainer";
import MaskDetailContainer from "./routes/Mask/DetailContainer";
import FlowContainer from "./routes/Flow/FlowContainer";
import ImagesContainer from "./routes/Images/ListContainer";
import SupervisedWrapContainer from "./routes/Supervised/WrapContainer";
import SupervisedTrainingContainer from "./routes/Supervised/TrainingContainer";
import SupervisedListContainer from "./routes/Supervised/SelectModelContainer";
import SupervisedModelDetailContainer from "./routes/Supervised/ModelDetailContainer";
import UnsupervisedWrapContainer from "./routes/Unsupervised/WrapContainer";
import UnsupervisedTrainingContainer from "./routes/Unsupervised/TrainingContainer";
import UnsupervisedListContainer from "./routes/Unsupervised/SelectModelContainer";
import UnsupervisedModelDetailContainer from "./routes/Unsupervised/ModelDetailContainer";
import UnifierTrainingContainer from "./routes/Unifier/TraningContainer";
import UnifierWrapContainer from "./routes/Unifier/WrapContainer";
import UnifierListContainer from "./routes/Unifier/ListContainer";
import UnifierDetailContainer from "./routes/Unifier/DetailContainer";
import PreprocessWrapContainer from "./routes/Preprocess/WrapContainer";
import MeasuresWrapContainer from "./routes/Measure/WrapContainer";
import OcrWrapContainer from "./routes/Ocr/WrapContainer";
import OcrTrainingContainer from "./routes/Ocr/TrainingContainer";
import OcrListContainer from "./routes/Ocr/SelectModelContainer";
import OcrModelDetailContainer from "./routes/Ocr/ModelDetailContainer";
import StatisticsContainer from "./routes/Statistics/StatisticsContainer";
import RuntimeContainer from "./routes/Runtime/RuntimeContainer";
import OutputWrapContainer from "./routes/Output/WrapContainer";
import OutputListContainer from "./routes/Output/ListContainer";
import CoreLayout from "./layouts/CoreLayout/CoreLayout";
import CameraContainer from "./routes/Operator/CameraContainer";
import ApiView from "./routes/API/ApiView";

const routes = (
  <CoreLayout>
    <Switch>
      <Route path="/api" component={ApiView} />
      <Route path="/operator" component={CameraContainer} />
      <Route
        path="*"
        component={(props: RouteComponentProps<{ moduleId: string }>) => (
          <PageLayout {...props}>
            <Switch>
              <Redirect exact from="/" to="/images/" />
              <Route path="/images" component={ImagesContainer} />
              <Route path="/debugger" component={() => <DebuggerContainer />} />
              <Route path="/modules">
                <Switch>
                  <Redirect exact from="/modules" to="/modules/list" />
                  <Route path="/modules/edit" component={FlowContainer} />
                  <Route path="/modules/list" component={ModulesContainer} />
                  {/* SUPERVISED */}
                  <Route path="/modules/supervised/:moduleId/">
                    <SupervisedWrapContainer>
                      <Route
                        exact
                        path="/modules/supervised/:moduleId/"
                        render={(props) => (
                          <Redirect
                            to={`/modules/supervised/${props.match.params.moduleId}/validate/`}
                          />
                        )}
                      />
                      <Switch>
                        <Route
                          path="/modules/supervised/:moduleId/training/"
                          component={SupervisedTrainingContainer}
                        />
                        <Route
                          exact
                          path="/modules/supervised/:moduleId/validate/"
                          component={SupervisedListContainer}
                        />
                        <Route
                          path="/modules/supervised/:moduleId/validate/detail/"
                          component={SupervisedModelDetailContainer}
                        />
                      </Switch>
                    </SupervisedWrapContainer>
                  </Route>
                  {/* UNSUPERVISED */}
                  <Route path="/modules/unsupervised/:moduleId/">
                    <UnsupervisedWrapContainer>
                      <Route
                        exact
                        path="/modules/unsupervised/:moduleId/"
                        render={(props) => (
                          <Redirect
                            to={`/modules/unsupervised/${props.match.params.moduleId}/validate/`}
                          />
                        )}
                      />
                      <Switch>
                        <Route
                          path="/modules/unsupervised/:moduleId/training/:showTrainingDialog?"
                          component={UnsupervisedTrainingContainer}
                        />
                        <Route
                          exact
                          path="/modules/unsupervised/:moduleId/validate/"
                          component={UnsupervisedListContainer}
                        />
                        <Route
                          path="/modules/unsupervised/:moduleId/validate/detail/:showAutoSensitivity?"
                          component={UnsupervisedModelDetailContainer}
                        />
                      </Switch>
                    </UnsupervisedWrapContainer>
                  </Route>
                  {/* CODE */}
                  <Route
                    path="/modules/code/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <CodeWrapContainer {...props}>
                        <Switch>
                          <Route
                            exact
                            path="/modules/code/:moduleId/"
                            component={CodeListContainer}
                          />
                          <Route
                            path="/modules/code/:moduleId/:itemId/images/"
                            component={CodeImageListContainer}
                          />
                          <Route
                            exact
                            path="/modules/code/:moduleId/:itemId/"
                            component={CodeDetailContainer}
                          />
                        </Switch>
                      </CodeWrapContainer>
                    )}
                  />
                  {/* MASK */}
                  <Route
                    path="/modules/mask/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <MaskWrapContainer {...props}>
                        <Switch>
                          <Route
                            exact
                            path="/modules/mask/:moduleId"
                            component={MaskListContainer}
                          />
                          <Route
                            path="/modules/mask/:moduleId/:itemId/"
                            component={MaskDetailContainer}
                          />
                        </Switch>
                      </MaskWrapContainer>
                    )}
                  />
                  {/* DETECTOR */}
                  <Route
                    path="/modules/detector/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <DetectorWrapContainer {...props}>
                        {props.location.pathname ===
                          `/modules/detector/${props.match.params.moduleId}/` && (
                          <Redirect
                            to={`/modules/detector/${props.match.params.moduleId}/validate/`}
                          />
                        )}
                        <Switch>
                          <Route
                            path="/modules/detector/:moduleId/training/"
                            component={DetectorTrainingContainer}
                          />
                          <Route
                            exact
                            path="/modules/detector/:moduleId/validate/"
                            component={DetectorModelListContainer}
                          />
                          <Route
                            path="/modules/detector/:moduleId/validate/detail/"
                            component={DetectorModelDetailContainer}
                          />
                        </Switch>
                      </DetectorWrapContainer>
                    )}
                  />
                  {/* UNIFIER */}
                  <Route
                    path="/modules/unifier/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <UnifierWrapContainer {...props}>
                        <Switch>
                          <Route
                            exact
                            path="/modules/unifier/:moduleId/"
                            component={UnifierListContainer}
                          />
                          <Route
                            exact
                            path="/modules/unifier/:moduleId/:itemId/"
                            component={UnifierTrainingContainer}
                          />
                          <Route
                            path="/modules/unifier/:moduleId/:itemId/images/"
                            component={UnifierDetailContainer}
                          />
                        </Switch>
                      </UnifierWrapContainer>
                    )}
                  />
                  {/* CLASSIFIER */}
                  <Route
                    path="/modules/classifier/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <ClassifierWrapContainer>
                        {props.location.pathname ===
                          `/modules/classifier/${props.match.params.moduleId}/` && (
                          <Redirect
                            to={`/modules/classifier/${props.match.params.moduleId}/validate/`}
                          />
                        )}
                        <Switch>
                          <Route
                            path="/modules/classifier/:moduleId/training/"
                            component={ClassifierTrainingContainer}
                          />
                          <Route
                            exact
                            path="/modules/classifier/:moduleId/validate/"
                            component={ClassifierModelListContainer}
                          />
                          <Route
                            path="/modules/classifier/:moduleId/validate/detail/"
                            component={ClassifierModelDetailContainer}
                          />
                        </Switch>
                      </ClassifierWrapContainer>
                    )}
                  />
                  {/* PRE-PROCESS */}
                  <Route
                    path="/modules/preprocess/:moduleId/"
                    component={PreprocessWrapContainer}
                  />
                  {/* OCR */}
                  <Route
                    path="/modules/ocr/:moduleId/"
                    component={(
                      props: RouteComponentProps<{ moduleId: string }>
                    ) => (
                      <OcrWrapContainer {...props}>
                        {props.location.pathname ===
                          `/modules/ocr/${props.match.params.moduleId}/` && (
                          <Redirect
                            to={`/modules/ocr/${props.match.params.moduleId}/validate/`}
                          />
                        )}
                        <Switch>
                          <Route
                            path="/modules/ocr/:moduleId/training/"
                            component={OcrTrainingContainer}
                          />
                          <Route
                            exact
                            path="/modules/ocr/:moduleId/validate/"
                            component={OcrListContainer}
                          />
                          <Route
                            path="/modules/ocr/:moduleId/validate/detail/"
                            component={OcrModelDetailContainer}
                          />
                        </Switch>
                      </OcrWrapContainer>
                    )}
                  />
                  {/* MEASURE */}
                  <Route
                    path="/modules/measure/:moduleId/"
                    component={MeasuresWrapContainer}
                  />
                </Switch>
              </Route>
              {/* CAMERA */}
              <Route path="/camera">
                <CameraWrapContainer>
                  <Route
                    exact
                    path="/camera"
                    component={CameraInputContainer}
                  />
                  <Route
                    path="/camera/editor"
                    component={CameraEditorContainer}
                  />
                </CameraWrapContainer>
              </Route>
              {/* OUTPUT */}
              <Route path="/output">
                <OutputWrapContainer>
                  <Route exact path="/output" component={OutputListContainer} />
                </OutputWrapContainer>
              </Route>
              {/* STATISTICS */}
              <Route path="/report" component={StatisticsContainer} />
              {/* STATISTICS */}
              <Route path="/runtime/" component={RuntimeContainer} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </PageLayout>
        )}
      />
    </Switch>
  </CoreLayout>
);

export default routes;
