// eslint-disable-next-line import/extensions
import messagesEN from "./en-US.json";
import messagesCZ from "./cs-CZ.json";
import messagesDE from "./de-DE.json";
import messagesJP from "./ja-JP.json";
import messagesZh from "./zh-Hans.json";

const message = (id: string, ...params: (string | number)[]): string => {
  let messages;
  // eslint-disable-next-line no-underscore-dangle
  switch ((window as any).__lang) {
    case "cs-CZ":
      messages = messagesCZ;
      break;
    case "de-DE":
      messages = messagesDE;
      break;
    case "ja-JP":
      messages = messagesJP;
      break;
    case "zh-Hans":
      messages = messagesZh;
      break;
    default:
      messages = messagesEN;
  }

  let m = (messages as any)[id];
  if (!m) {
    m = (messagesEN as any)[id]; // lets try english
    if (m == null) {
      // eslint-disable-next-line no-underscore-dangle
      return (window as any).__debug ? `-${id}-` : id;
    }
  }
  if (params && params.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < params.length; i++) {
      const r = `{${i}}`;
      m = m.replace(r, params[i]);
    }
  }
  return m;
};

export default message;
