import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Values = {
  radius: number,
  rubber: boolean,
  polygon: boolean,
}

export type PaintState = {
  settings: { [key: string]: Values}
}

// Define the initial state using that type
const initialState: PaintState = {
  settings: {}
};

export const paintSlice = createSlice({
  name: 'paint',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<{key: string, settings: Values}>) => {
      state.settings[action.payload.key] = action.payload.settings
    }
  }
})

export const { set } = paintSlice.actions

export default paintSlice.reducer
