import React, { useEffect } from "react";
import { FC } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ModelListContainer from "./ModelListContainer";
import { useAppSelector } from "../../hooks/store";
import { getModule } from "../../utils/common";
import { AnyLearningModule } from "../../types/modules";

const SelectModelView: FC = () => {
  const history = useHistory();
  const location = useLocation();
  let { moduleId } = useParams<{ moduleId: string }>();

  const { module } = useAppSelector((state) => ({
    module: getModule(state, parseInt(moduleId)) as AnyLearningModule,
  }));

  useEffect(() => {
    if (module?.modelId !== null) {
      history.push(location.pathname + "detail/");
    }
  }, [module.modelId, history, location.pathname]);

  return <ModelListContainer maxHeight moduleId={+moduleId} />;
};

export default SelectModelView;
