import React from "react";
import Button from "@mui/material/Button";
import { codeCreate, codeDelete } from "../../../actions/actions";
import ModelList from "../../../components/templates/ModelList";
import { PropsFromRedux } from "../ListContainer";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";
import { CodeModuleItemUsed } from "../../../types/modules";

const CodeListView = (props: PropsFromRedux) => {
  const [_] = useI18n();
  const history = useHistory();
  const renderButtons = (item: CodeModuleItemUsed) => {
    return (
      <Button
        style={{
          marginTop: -19,
          marginBottom: -19,
        }}
        color="secondary"
        disabled={props.activeFileId !== item.fileId}
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            `/modules/code/${props.moduleId}/${item.itemId}/images/`
          );
        }}
      >
        {_("preview")}
      </Button>
    );
  };

  return (
    <ModelList
      t={_}
      items={props.items.filter((i) => !i.deleted)}
      onCreate={codeCreate}
      isItemActive={(item) => {
        if (item) {
          if (props.activeFileId) {
            return props.activeFileId === item.fileId;
          }
          return false;
        }
        return !props.activeFileId;
      }}
      onSetItem={(item) => {
        props.codeSetSourceCode({
          moduleId: props.moduleId,
          fileId: item ? item.fileId : null,
        });
      }}
      onClickItem={({ itemId }) => {
        history.push(`/modules/code/${props.moduleId}/${itemId}/`);
      }}
      onClickDelete={({ itemId }) => {
        codeDelete(itemId);
      }}
      createTitle={_("code_new_placeholder")}
      moduleId={props.moduleId}
      renderButtons={renderButtons}
    />
  );
};

export default CodeListView;
