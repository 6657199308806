import React, { Component } from "react";
import { ImageSize, Line, Rectangle } from "../types/common";
import { formatConfidence } from "../utils/format";

const styles = {
  svg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  rectangle: {
    border: "1px solid rgba(120,255,0,0.8)",
    position: "absolute",
    boxSizing: "content-box",
  },
  classNames: {
    color: "white",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: 0,
    //marginBottom: -23,
  },
  distance: {
    color: "white",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: 0,
    fontSize: 11,
    zIndex: 2,
  },
} as const;

type Props = {
  items: Rectangle[];
  lines: Line[];
  wrapSize: ImageSize;
  imageSize: ImageSize;
};

export default class Rectangles extends Component<Props> {
  getScale() {
    const { imageSize, wrapSize } = this.props;
    return Math.min(
      wrapSize.width / imageSize.width,
      wrapSize.height / imageSize.height
    );
  }

  isSameSize(imageSize: ImageSize, rectangles: Rectangle) {
    return (
      imageSize.width === rectangles.width &&
      imageSize.height === rectangles.height
    );
  }

  renderRectangles() {
    const scale = this.getScale();
    return this.props.items
      .filter((i) => !("points" in i))
      .map((rectangle, index) => (
        <div
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          style={{
            backgroundColor: !this.isSameSize(this.props.imageSize, rectangle)
              ? "rgba(120,255,0,0.1)"
              : undefined,
            width: scale * rectangle.width + 2,
            height: scale * rectangle.height + 2,
            transform: `rotate(${rectangle.rotate || 0}deg)`,
            top:
              scale * rectangle.y -
              2 +
              (this.props.wrapSize.height -
                scale * this.props.imageSize.height) /
                2,
            left:
              scale * rectangle.x -
              2 +
              (this.props.wrapSize.width - scale * this.props.imageSize.width) /
                2,
            ...styles.rectangle,
          }}
        >
          {this.renderClassNames(
            rectangle,
            !this.isSameSize(this.props.imageSize, rectangle)
              ? { top: 0 }
              : { bottom: 0 }
          )}
        </div>
      ));
  }

  renderPolygons() {
    const scale = this.getScale();
    const offsetX =
      (this.props.wrapSize.width - scale * this.props.imageSize.width) / 2;
    const offsetY =
      (this.props.wrapSize.height - scale * this.props.imageSize.height) / 2;
    const polygons = this.props.items
      .filter((i) => "points" in i)
      .map((item) => ({
        ...item,
        points:
          item.points?.map(([a, b]): [number, number] => [
            offsetX + a * scale,
            offsetY + b * scale,
          ]) || [],
        textPos: {
          left:
            Math.min(...(item.points || [])?.map((i) => i[0])) * scale +
            offsetX,
          top:
            Math.max(...(item.points || [])?.map((i) => i[1])) * scale +
            offsetY,
        },
      }));

    return (
      <>
        <svg style={styles.svg}>
          {polygons.map((item, index) => (
            <polygon
              key={index}
              points={item.points.map((i) => i.join(",")).join(" ")}
              style={{ fill: "transparent", stroke: "yellow", strokeWidth: 1 }}
            />
          ))}
        </svg>
        {polygons.map((item, index) => (
          <div key={index} style={{ position: "absolute", ...item.textPos }}>
            {this.renderClassNames(item, {})}
          </div>
        ))}
      </>
    );
  }

  renderLines() {
    const scale = this.getScale();
    const lines = this.props.lines
      .filter((l) => l.length && l.length > 0)
      .map((item) => ({
        ...item,
        start: {
          x:
            scale * item.start.x +
            (this.props.wrapSize.width - scale * this.props.imageSize.width) /
              2,
          y:
            scale * item.start.y +
            (this.props.wrapSize.height - scale * this.props.imageSize.height) /
              2,
        },
        end: {
          x:
            scale * item.end.x +
            (this.props.wrapSize.width - scale * this.props.imageSize.width) /
              2,
          y:
            scale * item.end.y +
            (this.props.wrapSize.height - scale * this.props.imageSize.height) /
              2,
        },
      }));

    return (
      <>
        <svg style={styles.svg}>
          {lines.map((item, index) => (
            <line
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              x1={item.start.x}
              y1={item.start.y}
              x2={item.end.x}
              y2={item.end.y}
              style={{ stroke: "rgb(0,32,255)", strokeWidth: 3 }}
            />
          ))}
        </svg>
        {lines.map((item, index) => (
          <div
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            style={{
              top: (item.start.y + item.end.y) / 2,
              left: (item.start.x + item.end.x) / 2,
              ...styles.distance,
            }}
          >
            {Math.round(item.length || 0)}
          </div>
        ))}
      </>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderClassNames(item: Rectangle, style: any) {
    const className =
      item.classNames && item.classNames.length > 0 ? item.classNames[0] : null;
    if (className) {
      return (
        <div style={{ ...styles.classNames, ...style }}>
          {className.accuracy || className.confidence || item.confidence
            ? `${className.label} ${formatConfidence(
                className.accuracy ||
                  className.confidence ||
                  (item.confidence as number)
              )}%`
            : className.label}
        </div>
      );
    }
    if (item.text) {
      return <div style={{ ...styles.classNames, ...style }}>{item.text}</div>;
    }
    if (item.confidence) {
      return (
        <div style={{ ...styles.classNames, ...style }}>
          {Math.round(item.confidence * 100)}%
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        {this.renderRectangles()}
        {this.renderLines()}
        {this.renderPolygons()}
      </>
    );
  }
}
