import { Path } from "./reducer";

// eslint-disable-next-line import/prefer-default-export
export const updateObject = (data: any, path: Path, nextValue: any): void => {
  if (path.length === 0) {
    return;
  }
  const key = path[0];

  if (typeof key === "object") {
    const keyList = Object.keys(key);
    const keyName = keyList[0];
    const index = data.findIndex((i: any) => i[keyName] === key[keyName]);
    if (path.length === 1) {
      if (index === -1) {
        data.push(nextValue);
      } else {
        // eslint-disable-next-line no-param-reassign
        data[index] = nextValue;
      }
    } else {
      updateObject(data[index], path.splice(1), nextValue);
    }
  } else if (path.length === 1) {
    // eslint-disable-next-line no-param-reassign
    data[key] = nextValue;
  } else {
    updateObject(data[key], path.slice(1), nextValue);
  }
};

export const removeObject = (data: any, path: Path, id: string | number): void => {
  if (path.length === 0) {
    return;
  }
  const key = path[0];

  if (typeof key === "object") {
    const keyList = Object.keys(key);
    const keyName = keyList[0];
    const index = data.findIndex((i: any) => i[keyName] === key[keyName]);
    removeObject(data[index], path.splice(1), id);
  } else if (path.length === 1) {
    // eslint-disable-next-line no-param-reassign
    data[key] = data[key].filter((i: any) => i.id !== id);
  } else {
    removeObject(data[key], path.slice(1), id);
  }
};
