import React, { FC } from "react";
import ModelLearningList from "../../../components/templates/ModelLearningList";
import { supervisedDeleteModel } from "../../../actions/actions";
import { PropsFromRedux } from "../ListContainer";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";

const ModelListView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const history = useHistory();
  return (
    <ModelLearningList
      t={_}
      enableGpuSettings
      onlyOneGpu
      gpuDevices={props.gpuDevices}
      maxHeight={props.maxHeight}
      onChangeGpuValue={(values) =>
        props.setGpuSettings(props.module.id, values)
      }
      showTrt={(model) =>
        model.trainingParams.config?.TYPE === "objects" &&
        model.trainingParams.classNames.length === 1
      }
      gpuValue={props.module.gpuSettings || [0]}
      activeModelId={props.activeModelId}
      models={props.models}
      onActive={(model) => {
        props.supervisedSetActiveModelId({
          moduleId: props.module.id,
          modelId: model ? model.modelId : null,
        });
      }}
      onChangeTrt={(model, active) => {
        props.setModelValue(
          "supervisedModels",
          model.modelId,
          "tensorrt_auto_optimise",
          active
        );
      }}
      getParams={(model) => {
        return (
          <span>
            {" "}
            {_(
              model.trainingParams.config.TYPE === "universal"
                ? "standart"
                : "lightweight"
            )}
          </span>
        );
      }}
      onEditName={props.supervisedSetName}
      onDelete={(model) => supervisedDeleteModel(model.modelId)}
      onOpen={() => {
        history.push(`/modules/supervised/${props.module.id}/validate/detail/`);
      }}
    />
  );
};

export default ModelListView;
