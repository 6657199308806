import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type {
  GlobalState,
  AppDispatch,
  AnyAppDispatch,
} from "../store/createStore";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAnyAppDispatch = () => useDispatch<AnyAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector;
