import { connect, ConnectedProps } from "react-redux";
import MainView from "./componentes/MainView";
import { setModuleValue } from "../../actions/actions";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import PageNotFound from "../../components/PageNotFound";
import { MeasureModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => ({
  module: getModule(state, +ownProps.match.params.moduleId),
  noImage: state.database.images.length === 0,
});

const mapDispatchToProps = {
  setModuleValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { module: MeasureModule };

const Wrap = (props: PropsFromRedux) => {
  if (props.module) {
    return <MainView {...(props as Props)} />;
  } else {
    return <PageNotFound />;
  }
};

export default connector(Wrap);
