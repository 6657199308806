import { Chip } from "@mui/material";
import React, { useCallback } from "react";
import { FC } from "react";
import { Tag } from "../types/common";
import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../hooks/store";

type Props = {
  tags?: Tag[];
  onChange: (ids: number[]) => void;
  values: number[];
  disabledIds?: number[];
};

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -3px;
  margin-right: -3px;
  margin-bottom: 16px;
  & > * {
    margin: 3px;
  }
`;

const TagsPicker: FC<Props> = ({ values, onChange, tags, disabledIds }) => {
  const { _tags } = useAppSelector((state) => ({
    _tags: state.database.tags || [],
  }));

  const handleRemove = useCallback(
    (id: number) => {
      onChange(values.filter((i) => i !== id));
    },
    [values, onChange]
  );

  const handleAdd = useCallback(
    (id: number) => {
      onChange([...values, id]);
    },
    [values, onChange]
  );

  return (
    <Wrap>
      {(tags || _tags).map((tag) => {
        const active = values.includes(tag.id);
        return (
          <Chip
            key={tag.id}
            disabled={disabledIds?.includes(tag.id) && !active}
            deleteIcon={!active ? <DoneIcon /> : undefined}
            color={active ? "primary" : "default"}
            label={tag.label}
            onClick={!active ? () => handleAdd(tag.id) : undefined}
            onDelete={() => (active ? handleRemove(tag.id) : handleAdd(tag.id))}
          />
        );
      })}
    </Wrap>
  );
};

export default TagsPicker;
