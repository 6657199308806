import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { setShowTagsManager, setTagsImages } from "../../store/images/reducer";
import lodash from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import TagsPicker from "../../components/TagsPicker";
import { updateTags } from "../../actions/actions";
import { Tag, Image } from "../../types/common";
import useI18n from "../../hooks/useI18n";
import { deepCompare } from "../../utils/common";
import styled from "styled-components";

const Content = styled(DialogContent)`
  min-width: 300px;
  max-width: 700px;
`;

const getTagsIdsFromImages = (images: Image[]) => {
  return images.reduce((prev: number[], image) => {
    if (!image.tags) {
      return prev;
    }
    return Array.from(new Set([...prev, ...image.tags]));
  }, []);
};

const getUnselectedTagsFromImages = (images: Image[], tags: Tag[]) => {
  const currentTagsIds = getTagsIdsFromImages(images);
  return tags.filter((t) => !currentTagsIds.includes(t.id)).map((i) => i.id);
};

const TagsImagesDialogContainer = () => {
  const [t] = useI18n();

  const { tags, images } = useAppSelector(
    (state) => ({
      tags: state.database.tags || [],
      images: state.database.images.filter((i) =>
        state.images.tagsImages.includes(i.id)
      ),
    }),
    lodash.isEqual
  );

  const dispatch = useAnyAppDispatch();
  const [currentTags, setCurrentTags] = useState<number[]>(
    getTagsIdsFromImages(images)
  );
  const [removedTags, setRemovedTags] = useState<number[]>(
    getUnselectedTagsFromImages(images, tags)
  );

  useEffect(() => {
    setCurrentTags(getTagsIdsFromImages(images));
    setRemovedTags(getUnselectedTagsFromImages(images, tags));
  }, [tags, images]);

  const isChanged = useMemo(
    () =>
      !deepCompare(getTagsIdsFromImages(images), currentTags) ||
      !deepCompare(removedTags, getUnselectedTagsFromImages(images, tags)),
    [images, currentTags, removedTags, tags]
  );

  const handleSave = useCallback(() => {
    const defaultTags = getTagsIdsFromImages(images);
    const removeIds = tags
      .filter(
        (tag) =>
          !currentTags.includes(tag.id) &&
          removedTags.includes(tag.id) &&
          defaultTags.includes(tag.id)
      )
      .map((i) => i.id);

    const addIds = tags
      .filter(
        (tag) => currentTags.includes(tag.id) && removedTags.includes(tag.id)
      )
      .map((i) => i.id);

    dispatch(updateTags(addIds, removeIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, currentTags, tags]);

  const handleSelectTags = useCallback(
    (ids: number[]) => {
      const newRemoves = currentTags.filter((i) => !ids.includes(i));
      setRemovedTags((removedIds) =>
        Array.from(new Set([...removedIds, ...newRemoves]))
      );
      setCurrentTags(ids);
    },
    [setCurrentTags, setRemovedTags, currentTags]
  );

  const tagsWithCount = useMemo(() => {
    const getLabel = (tag: Tag) => {
      if (images.length === 1) {
        return tag.label;
      } else {
        if (currentTags.includes(tag.id)) {
          if (removedTags.includes(tag.id)) {
            return `${images.length}: ${tag.label}`;
          } else {
            return `${images.filter((i) => i.tags?.includes(tag.id)).length}: ${
              tag.label
            }`;
          }
        } else {
          return `0: ${tag.label}`;
        }
      }
    };
    return tags.map((tag) => ({ ...tag, label: getLabel(tag) }));
  }, [tags, images, currentTags, removedTags]);

  return (
    <Dialog open>
      <DialogTitle>{t("update_tags")}</DialogTitle>
      <Content>
        {images.length > 1 && (
          <Typography gutterBottom>
            {t("images_count")} {images.length}
          </Typography>
        )}
        <TagsPicker
          tags={tagsWithCount}
          values={currentTags}
          onChange={handleSelectTags}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={() => dispatch(setShowTagsManager(true))}
        >
          {t("open_tags")}
        </Button>
      </Content>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => dispatch(setTagsImages({ images: [] }))}
        >
          {t("close")}
        </Button>
        <Button color="primary" disabled={!isChanged} onClick={handleSave}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagsImagesDialogContainer;
