import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Dialog from "./SimpleDialog";
import { deepCompare } from "../utils/common";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import withI18n from "../utils/withI18n";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { Alert } from "@mui/material";

const Line = styled.div`
  display: flex;
  flex-direction: row;
`;
const DeleteButton = styled(IconButton)`
  margin-top: -5px;
  margin-left: 2px;
  margin-right: -16px;
`;

const styles = {
  deleteAnnotationsCheckbox: {
    width: "100%",
  },
} as const;

type Item = { id: number; label: string };

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: (
    items: Item[],
    deleteAnnotations: boolean,
    deletedIds: (number | string)[]
  ) => void;
  enableDeleteAnnotations?: boolean;
  items: Item[];
  max?: number;
  min?: number;
  t: (key: string) => string;
};

type State = {
  deleteAnnotations: boolean;
  items: Item[];
  prevProps: Props;
};

class ClassNamesManagerDialog extends Component<Props, State> {
  state: State = {
    items: this.props.items,
    deleteAnnotations: false,
    prevProps: this.props,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (
      (!state.prevProps.open && props.open) ||
      !deepCompare(props.items, state.prevProps.items)
    ) {
      return {
        items: props.items,
        prevProps: props,
      };
    }
    return {
      prevProps: props,
    };
  }

  handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClose();
  };

  handleClickSave = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (this.state.deleteAnnotations) {
      const deletedIds = this.props.items
        .filter((item) => !this.state.items.find((i) => i.id === item.id))
        .map(({ id }) => id);
      this.props.onUpdate(this.state.items, true, deletedIds);
    } else {
      this.props.onUpdate(this.state.items, false, []);
    }
    this.props.onClose();
  };

  handleClickRefresh = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      items: this.props.items,
    });
  };

  handleChangeLabel = (
    e: React.ChangeEvent,
    value: string,
    id: string | number
  ) => {
    e.stopPropagation();
    this.setState({
      items: this.state.items.map((i) =>
        i.id === id ? { ...i, label: value } : i
      ),
    });
  };

  handleClickDelete = (e: React.MouseEvent, id: string | number) => {
    e.stopPropagation();
    this.setState({
      items: this.state.items.filter((i) => i.id !== id),
    });
  };

  getFreeId = () => {
    let i = 1;
    const ids = this.state.items.map((i) => i.id);
    while (true) {
      if (!ids.includes(i)) {
        return i;
      }
      i++;
    }
  };

  handleClickAdd = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      items: [
        ...this.state.items,
        { id: this.getFreeId(), label: "Classname" },
      ],
    });
  };

  isUniq = () =>
    new Set(this.state.items.map((i) => i.label)).size ===
    this.state.items.length;

  render() {
    const _ = this.props.t;
    const isUniq = this.isUniq();
    const actions = [
      <Button key="close" color="primary" onClick={this.handleClickClose}>
        {_("close")}
      </Button>,
    ];

    if (JSON.stringify(this.state.items) !== JSON.stringify(this.props.items)) {
      actions.push(
        <Button key="refresh" color="primary" onClick={this.handleClickRefresh}>
          {_("refresh")}
        </Button>
      );
      actions.push(
        <Button
          key="save"
          disabled={!isUniq}
          color="primary"
          onClick={this.handleClickSave}
        >
          {_("save_change")}
        </Button>
      );
    }

    const { max, min, enableDeleteAnnotations } = this.props;
    const showDelete = !min || min < this.state.items.length;
    const showAdd = !max || max > this.state.items.length;
    const showDeleteAnnotations = this.props.items.some(
      (item) => !this.state.items.find((i) => i.id === item.id)
    );

    return (
      <Dialog
        actions={actions}
        open={this.props.open}
        onRequestClose={this.props.onClose}
        contentStyle={{
          width: 372,
        }}
        title={_("classifier_class_name_manager")}
      >
        <div
          onKeyUp={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Grid container direction="column" spacing={1}>
            {/* eslint-disable-next-line  */}

            {this.state.items.map((item) => (
              <Grid item key={item.id}>
                <Line>
                  <TextField
                    size="small"
                    name={`item_${item.id}`}
                    value={item.label}
                    fullWidth
                    variant={"outlined"}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.handleChangeLabel(e, e.target.value, item.id);
                    }}
                    onKeyPress={(e) => e.stopPropagation()}
                  />
                  {showDelete && (
                    <DeleteButton
                      aria-label="delete"
                      onClick={(e) => this.handleClickDelete(e, item.id)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </DeleteButton>
                  )}
                </Line>
              </Grid>
            ))}
            {!isUniq && (
              <Grid item>
                <Alert severity="error">{_("names_must_be_unique")}</Alert>
              </Grid>
            )}
            {enableDeleteAnnotations && showDeleteAnnotations && (
              <Grid item>
                <FormControlLabel
                  style={styles.deleteAnnotationsCheckbox}
                  control={
                    <Checkbox
                      checked={this.state.deleteAnnotations}
                      onChange={(e) =>
                        this.setState({ deleteAnnotations: e.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label={_("delete_annotations")}
                />
              </Grid>
            )}

            {showAdd && (
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={this.handleClickAdd}
                  color="primary"
                >
                  {_("classifier_add_class_name")}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Dialog>
    );
  }
}

export default withI18n(ClassNamesManagerDialog);
