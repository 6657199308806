import React, { FC } from "react";
import { withResizeDetector } from "react-resize-detector";
import { UnifierItem } from "../../../types/modules";

const styles = {
  wrap: {
    background: "#f3f3f3",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  image: {
    position: "absolute",
  },
  pointers: {
    position: "relative",
  },
  pointer: {
    position: "absolute",
    width: 30,
    height: 30,
    borderRadius: "50%",
    marginTop: -15,
    marginLeft: -15,
    border: "4px solid rgb(233, 30, 99)",
    backgroundColor: "rgba(233, 30, 99, .5)",
  },
  point: {
    width: 3,
    height: 3,
    borderRadius: "50%",
    backgroundColor: "rgb(233, 30, 99)",
    top: "calc(50% - 1.5px)",
    left: "calc(50% - 2px)",
    position: "relative",
  },
  cutBorder: {
    position: "absolute",
    border: "solid 2px rgb(233, 30, 99)",
  },
} as const;

type Props = {
  imageSize: {
    width: number;
    height: number;
  };
  width: number;
  height: number;
  imgSrc: string;
  values: UnifierItem["values"];
  pointers: { x: number; y: number }[];
  targetRef: any;
};

const ImagePreview: FC<Props> = (props) => {
  const imageDiagonal = Math.sqrt(
    // eslint-disable-next-line no-restricted-properties
    Math.pow(props.imageSize.width, 2) + Math.pow(props.imageSize.height, 2)
  );
  const scale = Math.min(props.width || 1, props.height || 1) / imageDiagonal;
  const nextSize = {
    width: props.imageSize.width * scale,
    height: props.imageSize.height * scale,
  };

  const offset = {
    top: ((props.height || 0) - nextSize.height) / 2,
    left: ((props.width || 0) - nextSize.width) / 2,
  };
  const cutStyle = {
    width: props.values.width * scale,
    height: props.values.height * scale,
  };

  return (
    <div style={styles.wrap} ref={props.targetRef}>
      <img
        src={props.imgSrc}
        style={{
          transform: `rotate(${props.values.angle}deg)`,
          ...styles.image,
          ...nextSize,
          ...offset,
        }}
        alt=""
      />
      <div
        style={{
          transform: `rotate(${props.values.angle}deg)`,
          ...styles.pointers,
          ...offset,
          ...nextSize,
        }}
      >
        {props.pointers.map((point, pointIndex) => (
          <div
            key={pointIndex}
            style={{
              top: point.y * scale,
              left: point.x * scale,
              ...styles.pointer,
            }}
          >
            <div style={styles.point} />
          </div>
        ))}
      </div>
      <div
        style={{
          marginLeft:
            (nextSize.width - cutStyle.width) / 2 +
            props.values.offsetX * scale,
          marginTop:
            (nextSize.height - cutStyle.height) / 2 +
            props.values.offsetY * scale,
          ...styles.cutBorder,
          ...offset,
          ...cutStyle,
        }}
      />
    </div>
  );
};

export default withResizeDetector(ImagePreview);
