import { FC, useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { addLabelsToFlow, findAverageTimes } from "../../../utils/common";
import Builder from "../../../components/Builder/Builder";
import { CON_MIN_HEIGHT_HEADER, CONTENT_INNER_PADDING } from "../../../theme";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import { MODULES_DOC } from "../../../docLinks";
import { PropsFromRedux } from "../ModulesContainer";
import useI18n from "../../../hooks/useI18n";
import { Modules } from "../../../modules";
import { averageTimes } from "../../../actions/actions";
import { useDebouncedCallback } from "use-debounce";
import { Flow } from "../../../types/flow";

const styles = {
  wrap: {
    height: CON_MIN_HEIGHT_HEADER,
    overflow: "auto",
  },
  builder: {
    // display: 'flex',
    width: "100%",
    justifyContent: "center",
    overflow: "auto",
    height: "calc(100% - 58px)",
    textAlign: "center",
  },
  editButtonWrap: {
    textAlign: "center",
    marginBottom: 20,
  },
} as const;

const getModuleUrl = (moduleId: number, type: Modules) =>
  `/modules/${type.toLowerCase()}/${moduleId}/`;

const ModulesView: FC<PropsFromRedux> = (props) => {
  const history = useHistory();
  const [_] = useI18n();
  const location = useLocation();

  const [debouncedCallback, cancel] = useDebouncedCallback(
    // function
    (params: { settings: Flow; imagesLastId: number }) => {
      if (props.noImagesQue) {
        averageTimes(params);
      }
    },
    // delay in ms
    props.left ? 4000 : 10
  );

  const handleSelect = ({ id, type }: { id: number; type: Modules }) => {
    const modelUrl = getModuleUrl(id, type);
    const currentModule = props.moduleItems.find((i) => i.id === id);
    if (!currentModule) {
      return;
    }
    if ("modelId" in currentModule) {
      const models: any[] = (props.allModels as any)[
        currentModule.type.toLocaleLowerCase() + "Models"
      ];
      // ai modules
      if (currentModule.modelId !== null) {
        history.push(modelUrl + "validate/detail/");
      } else if (models.length === 0) {
        history.push(modelUrl + "training/");
      } else {
        history.push(modelUrl);
      }
    } else {
      history.push(modelUrl);
    }
  };

  const handleClickEdit = () => {
    history.push("/modules/edit/");
  };

  useEffect(() => {
    if (props.noImagesQue && !props.disabledTimes) {
      const item = findAverageTimes({
        items: props.averageTimes,
        imagesLastId: props.imagesLastId,
        settings: props.settings,
      });
      if (!item) {
        debouncedCallback({
          settings: props.settings,
          imagesLastId: props.imagesLastId,
        });
      }
    }
  }, [
    props.averageTimes,
    props.settings,
    props.noImagesQue,
    props.imagesLastId,
    debouncedCallback,
    props.disabledTimes,
  ]);

  useCallback(() => {
    if (!props.noImagesQue) {
      // images has higher priority
      cancel();
    }
  }, [props.noImagesQue, cancel]);

  const debuggerItem = useMemo(() => {
    if (props.disabledTimes) {
      return [];
    }
    return findAverageTimes({
      items: props.averageTimes,
      settings: props.settings,
      imagesLastId: props.imagesLastId,
    });
  }, [props.settings, props.averageTimes, props.imagesLastId, props.left]);
  return (
    <ModuleWrap
      front
      title={_("drawer_modules")}
      left={props.left}
      docId={MODULES_DOC}
      onClose={props.left ? () => props.setModulesPreview(false) : undefined}
      extraCheckbox={
        !props.left && !props.hasParallelism
          ? {
              label: "Show preview",
              disabled: props.hasParallelism,
              active: !!props.showPreview,
              onChange: props.setModulesPreview,
            }
          : undefined
      }
    >
      <div
        style={{
          ...styles.wrap,
          padding: props.left
            ? `${CONTENT_INNER_PADDING}px 0`
            : CONTENT_INNER_PADDING,
        }}
      >
        <div style={styles.editButtonWrap}>
          <Button variant="contained" onClick={handleClickEdit} color="primary">
            {_("edit")}
          </Button>
        </div>
        <div style={styles.builder}>
          <Builder
            isModuleActive={(moduleId, type) =>
              location.pathname.startsWith(getModuleUrl(moduleId, type))
            }
            path={[]}
            isFirst
            hideImages
            onSelect={handleSelect}
            items={addLabelsToFlow(
              props.settings,
              props.allModels,
              props.moduleItems,
              !props.disabledTimes,
              !props.disabledTimes ? debuggerItem?.times : undefined
            )}
          />
        </div>
      </div>
    </ModuleWrap>
  );
};

export default ModulesView;
