import React, { FC, useMemo, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Camera from "../../../components/Camera";
import { themeConfig } from "../../../theme";
import Logo from "../../../components/pekat_vision_logo_left_inverse_RGB.svg";
import StatisticsDialog from "./StatisticsDialog";
import useLiveContext from "./useLiveContext";
import {
  ElementLabel,
  ElementRoot,
} from "../../Camera/components/Editor/types";
import ElementsPreview from "../../Camera/components/Editor/ElementsPreview";
import { useLocation } from "react-router-dom";
import { PropsFromRedux } from "../CameraContainer";

const updatedTheme = createTheme({
  ...themeConfig,
  mixins: {
    toolbar: {
      minHeight: 45,
    },
  },
});

const useStyles = makeStyles()({
  root: {
    backgroundColor: "#263238",
    width: "100wh",
    height: "100vh",
    overflowY: "hidden",
  },
  content: {
    position: "absolute",
    height: `calc(100vh - 45px)`,
    width: "100%",
  },
  defaultCamera: {
    width: "100%",
    height: "100%",
    right: 0,
    top: 0,
  },
  cameraWrap: {
    height: `calc(100vh - 45px)`,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: 25,
  },
  offset: {
    flex: 1,
  },
});

const CameraView: FC<PropsFromRedux> = ({
  customOperatorItems,
  enableCustomOperator,
  operatorViewCounter,
  operatorInput = {},
  changeOperatorInput,
}) => {
  const { classes, cx } = useStyles();
  const context = useLiveContext();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [showStatistics, setShowStatistics] = useState(false);

  const getAdminUrl = () => {
    const sessionAdminUrl = sessionStorage.getItem("adminUrl");
    if (sessionAdminUrl) {
      return sessionAdminUrl;
    }
    return search.get("adminUrl");
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adminUrl = useMemo(() => getAdminUrl(), [search.get("adminUrl")]);
  const rootItem = customOperatorItems.find(
    (i) => i.type === "root"
  ) as ElementRoot;
  const isKiosk = !!adminUrl;

  const getLabelValue = (item: ElementLabel) =>
    item.sourceType === "dynamic" ? context[item.sourceKey] : item.sourceValue;

  const filteredItems = customOperatorItems.filter((item) => {
    if (
      (item.type === "label" || item.type === "input") &&
      item.visibilityByKey !== ""
    ) {
      return context[item.visibilityByKey];
    } else {
      return true;
    }
  });

  const showRectangles =
    localStorage.getItem("camera_showRectangles") !== undefined
      ? localStorage.getItem("camera_showRectangles") === "true"
      : true;

  return (
    <div className={classes.root}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={updatedTheme}>
          <AppBar position="static" color="secondary">
            <Toolbar>
              <img src={Logo} alt="logo" className={classes.logo} />
              <div className={classes.offset} />
              {isKiosk && (
                <Button
                  href={`${adminUrl}?clientUrl=${
                    window.location.origin + window.location.pathname
                  }`}
                  color="inherit"
                >
                  Admin
                </Button>
              )}
              <Button onClick={() => setShowStatistics(true)} color="inherit">
                Statistics
              </Button>
            </Toolbar>
          </AppBar>
          <div
            className={classes.content}
            style={{
              backgroundColor: enableCustomOperator
                ? rootItem.backgroundColor
                : undefined,
            }}
          >
            {enableCustomOperator ? (
              <ElementsPreview
                counters={operatorViewCounter}
                getLabelValue={getLabelValue}
                items={filteredItems}
                inputs={operatorInput}
                onChangeInputs={changeOperatorInput}
              />
            ) : (
              <div className={cx(classes.cameraWrap, classes.defaultCamera)}>
                <Camera id="main" showRectangles={showRectangles} />
              </div>
            )}
          </div>
          <StatisticsDialog
            open={showStatistics}
            onClose={() => setShowStatistics(false)}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default CameraView;
