import { createTheme } from "@mui/material/styles";

export const CONTENT_HEADER_COLOR = "#354045";
export const MIN_CONTENT_WIDTH = 900;
export const HELP_PANEL_WIDTH_MIN = 400;
export const DRAWER_WIDTH = 205;
export const DRAWER_SLIM_WIDTH = 56;
export const CONTENT_HEADER_HEIGHT = 48;
export const TOOLPANEL_HEIGHT = 48;
export const CONTENT_INNER_PADDING = 10;
export const IMAGE_LIST_LEFT_PADDING = 16;
export const PANEL_WIDTH = 236;
export const CON_MIN_HEIGHT_HEADER = `calc(100vh - ${CONTENT_HEADER_HEIGHT}px)`;
export const CON_MIN_HEIGHT_HEADER_TOOLPANEL = `calc(100vh - ${
  CONTENT_HEADER_HEIGHT + TOOLPANEL_HEIGHT
}px)`;
export const CON_MIN_HEIGHT_HEADER_TOOLPANEL_PADDING = `calc(100vh - ${
  CONTENT_HEADER_HEIGHT + TOOLPANEL_HEIGHT + 2 * CONTENT_INNER_PADDING
}px)`;
export const CON_MIN_HEIGHT_HEADER_PADDING = `calc(100vh - ${CONTENT_HEADER_HEIGHT}px)`;

export const PRIMARY_COLOR = "#039be5";
// export const PRIMARY_COLOR = '#1976d2'

const defaultTheme = {
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: "#393939",
    },
  },
};

export const themeConfig = {
  ...defaultTheme,
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: CONTENT_HEADER_COLOR,
          marginBottom: 8,
        },
      },
    },
  },
};

export const themeV4 = createTheme(themeConfig);

export const themeV4dark = createTheme({
  ...themeConfig,
  palette: {
    ...themeConfig.palette,
  },
});

export const themeV4rightPanel = (HELP_PANEL_WIDTH: number) =>
  createTheme({
    /* theme for v1.x */
    ...defaultTheme,
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            top: `0px!important`,
            right: `${HELP_PANEL_WIDTH}px!important`,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: "white",
            backgroundColor: CONTENT_HEADER_COLOR,
            marginBottom: 8,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            top: `0px!important`,
            right: `${HELP_PANEL_WIDTH}px!important`,
          },
        },
      },
    },
  });
