import axios from "axios";
import { SERVER_URL } from "../config";

// eslint-disable-next-line import/prefer-default-export

export type StatisticDay = {
  date: number;
  ok: number;
  nok: number;
};

export const getStatistics = (
  dateFrom: number,
  dateTo: number,
  token: string
) =>
  new Promise<{ data: StatisticDay[] }>((resolve) => {
    resolve(
      axios.get(
        `${SERVER_URL}/get_stats?from=${dateFrom}&to=${dateTo}&token=${token}`
      )
    );
  });
