import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Field, Form, Formik } from "formik";
import { Checkbox, TextField } from "formik-mui";
import MenuItem from "@mui/material/MenuItem";
import useI18n from "../../../hooks/useI18n";

const useStyles = makeStyles()({
  input: {
    width: 400,
  },
  maxImages: {
    width: 166,
  },
});

type Values = {
  name: string;
  testingOnly: boolean;
  showRecallPrecision: boolean;
  showProcessingTime: boolean;
  showConfusionMatrix: boolean;
  showModules: boolean;
  maxImages: number;
  maxImageSize: number;
  language: string;
};

type Props = {
  projectName: string;
  open: boolean;
  evaluationType: boolean;
  onClose: () => void;
  onSubmit: (values: Values) => void;
  lang: string;
};

const DownloadReportDialog = (props: Props) => {
  const [_] = useI18n();
  const { classes } = useStyles();
  const { evaluationType } = props;
  const initialValues: Values = {
    name: props.projectName,
    testingOnly: false,
    showRecallPrecision: evaluationType,
    showProcessingTime: true,
    showConfusionMatrix: evaluationType,
    showModules: true,
    maxImages: 100,
    maxImageSize: 400,
    language: props.lang,
  };
  return (
    <Formik onSubmit={props.onSubmit} initialValues={initialValues}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{_("download_report")}</DialogTitle>
            <DialogContent>
              <FormGroup>
                <Field
                  fullWidth
                  variant="outlined"
                  autoFocus
                  disabled={false}
                  className={classes.input}
                  component={TextField}
                  margin="dense"
                  name="name"
                  label={_("project_name")}
                />
                <Field
                  margin="dense"
                  variant="outlined"
                  disabled={false}
                  component={TextField}
                  label={_("default_language")}
                  name="language"
                  select
                >
                  <MenuItem value="en-US">English en-US</MenuItem>
                  <MenuItem value="de-DE">Deutsch de-DE</MenuItem>
                  <MenuItem value="cs-CZ">Čeština cs-CZ</MenuItem>
                  <MenuItem value="ja-JP">日本語 ja-JP</MenuItem>
                  <MenuItem value="zh-Hans">简体中文 zh-Hans</MenuItem>
                </Field>
                <Field
                  margin="dense"
                  variant="outlined"
                  disabled={false}
                  className={classes.maxImages}
                  component={TextField}
                  label={_("max_images")}
                  name="maxImages"
                  inputProps={{
                    type: "number",
                    min: 1,
                  }}
                />
                <Field
                  margin="dense"
                  variant="outlined"
                  disabled={false}
                  className={classes.maxImages}
                  component={TextField}
                  label={_("max_image_size")}
                  name="maxImageSize"
                  inputProps={{
                    type: "number",
                    min: 80,
                    max: 4096,
                  }}
                />

                <FormControlLabel
                  label={_("testing_only")}
                  control={
                    <Field
                      disabled={false}
                      component={Checkbox}
                      name="testingOnly"
                      type="checkbox"
                    />
                  }
                />
                {evaluationType && (
                  <>
                    <FormControlLabel
                      label={_("show_recall_precision")}
                      control={
                        <Field
                          disabled={false}
                          component={Checkbox}
                          name="showRecallPrecision"
                          type="checkbox"
                        />
                      }
                    />
                    <FormControlLabel
                      label={_("show_confusion_matrix")}
                      control={
                        <Field
                          disabled={false}
                          component={Checkbox}
                          name="showConfusionMatrix"
                          type="checkbox"
                        />
                      }
                    />
                  </>
                )}
                <FormControlLabel
                  label={_("show_processing_time")}
                  control={
                    <Field
                      disabled={false}
                      component={Checkbox}
                      name="showProcessingTime"
                      type="checkbox"
                    />
                  }
                />
                <FormControlLabel
                  label={_("show_modules")}
                  control={
                    <Field
                      disabled={false}
                      component={Checkbox}
                      name="showModules"
                      type="checkbox"
                    />
                  }
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                {_("cancel")}
              </Button>
              <Button type="submit" onClick={submitForm} color="primary">
                {_("download")}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default DownloadReportDialog;
