import React, { FC } from "react";
import ClassifierIcon from "@mui/icons-material/LocalOffer";
import RotateIcon from "@mui/icons-material/ScreenRotation";
import { detectorDeleteModel } from "../../../actions/actions";
import ModelLearningList from "../../../components/templates/ModelLearningList";
import { PropsFromRedux } from "../ModelListContainer";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";

const styles = {
  iconParams: {
    width: 15,
    height: 15,
    position: "relative",
    top: 2,
    marginLeft: 7,
  },
} as const;

const ModelListView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const history = useHistory();
  return (
    <ModelLearningList
      t={_}
      enableGpuSettings
      onlyOneGpu
      gpuDevices={props.gpuDevices}
      maxHeight={props.maxHeight}
      onChangeGpuValue={(values) =>
        props.setGpuSettings(props.module.id, values)
      }
      gpuValue={props.module.gpuSettings || [0]}
      activeModelId={props.activeModelId}
      models={props.models}
      onActive={(model) => {
        props.detectorSetActiveModelId({
          moduleId: props.moduleId,
          modelId: model ? model.modelId : null,
        });
      }}
      onEditName={props.detectorSetName}
      onDelete={(model) => detectorDeleteModel(model.modelId)}
      onOpen={() => {
        history.push(`/modules/detector/${props.module.id}/validate/detail/`);
      }}
      getParams={({ trainingParams }) => {
        return [
          trainingParams.config.CLASSIFICATION && (
            <ClassifierIcon style={styles.iconParams} />
          ),
          trainingParams.config.ROTATE && (
            <RotateIcon style={styles.iconParams} />
          ),
        ];
      }}
    />
  );
};

export default ModelListView;
