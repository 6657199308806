import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import Input from "../../../../components/OperatorView/Input";
import Label from "../../../../components/OperatorView/Label";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import CameraFake from "../../../../components/OperatorView/Camera";
import Camera from "../../../../components/Camera";
import {
  AnyElement,
  ElementCamera,
  ElementCounter,
  ElementImage,
  ElementInput,
  ElementLabel,
  ElementSelect,
  ElementSlider,
  MovableElement,
} from "./types";
import Select from "../../../../components/OperatorView/Select";
import Slider from "../../../../components/OperatorView/Slider";
import Image from "../../../../components/OperatorView/Image";
import Counter from "../../../../components/OperatorView/Counter";
import { DatabaseState } from "../../../../store/database/reducer";

const ChildrenWrap: FC<any> = ({ children }) => children;

const activeStyle = {
  outline: "2px dashed pink",
  outlineOffset: 10,
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  draggableItem: {
    cursor: "move",
  },
  item: {
    position: "absolute",
    display: "inline-block",
    verticalAlign: "top",
  },
  inputWrap: {
    pointerEvents: "none",
  },
}));

type Props = {
  items: AnyElement[];
  onChange?: (elements: AnyElement[]) => void;
  onSelect?: (elementId: number) => void;
  inputs?: { [key in string]?: string | number };
  counters?: DatabaseState["operatorViewCounter"];
  onChangeInputs?: (values: { [key in string]?: string | number }) => void;
  getLabelValue?: (item: ElementLabel) => string;
  activeItem?: number;
};

const ElementsPreview: FC<Props> = (props) => {
  const {
    getLabelValue,
    inputs,
    onChangeInputs,
    items,
    onChange,
    onSelect,
    counters,
    activeItem,
  } = props;
  const { classes, cx } = useStyles();

  const elementLabels: ElementLabel[] = items.filter(
    (i) => i.type === "label"
  ) as ElementLabel[];
  const elementInputs: ElementInput[] = items.filter(
    (i) => i.type === "input"
  ) as ElementInput[];
  const elementSelects: ElementSelect[] = items.filter(
    (i) => i.type === "select"
  ) as ElementSelect[];
  const elementSliders: ElementSlider[] = items.filter(
    (i) => i.type === "slider"
  ) as ElementSlider[];
  const elementImages: ElementImage[] = items.filter(
    (i) => i.type === "image"
  ) as ElementImage[];
  const camera: ElementCamera = items.find(
    (i) => i.type === "camera"
  ) as ElementCamera;
  const elementCounters: ElementCounter[] = items.filter(
    (i) => i.type === "counter"
  ) as ElementCounter[];

  const handleStopMove = (
    element: MovableElement,
    e: DraggableData,
    k: any
  ) => {
    const nextElement = {
      ...element,
      x: Math.max(e.x, 0),
      y: Math.max(e.y, 0),
    };
    onChange?.(items.map((i) => (i.id === element.id ? nextElement : i)));
  };

  const editable = !!onChange;

  const getDraggableProps = (element: MovableElement) => ({
    key: element.id,
    position: { x: element.x, y: element.y },
    onStop: (e: DraggableEvent, data: DraggableData) =>
      handleStopMove(element, data, e),
    onMouseDown: () => onSelect?.(element.id),
  });

  const getWrapProps = (element: MovableElement) => ({
    onClick: (e: any) => e.stopPropagation(),
    style: {
      zIndex: items.findIndex((i) => i.id === element.id),
      transform: editable
        ? undefined
        : `translate(${element.x}px, ${element.y}px)`,
    },
    className: cx(classes.item, editable && classes.draggableItem),
  });

  const Wrap = editable ? Draggable : ChildrenWrap;

  const getInputValue = (
    element: ElementInput | ElementSelect | ElementSlider
  ) => {
    if (editable) {
      if (element.type === "input") {
        return !element.enableMaxLength
          ? "Text"
          : "Text".substr(0, element.maxLength);
      } else {
        return "";
      }
    } else {
      return inputs?.[element.targetKey] || "";
    }
  };
  const getCounterValue = (counterId: number) => {
    if (editable) {
      return 0;
    } else {
      return counters?.[counterId] ?? 0;
    }
  };
  return (
    <div className={classes.root} onClick={() => onSelect?.(0)}>
      {elementInputs.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <div className={cx(editable && classes.inputWrap)}>
              <Input
                style={activeItem === element.id ? activeStyle : undefined}
                position={{ x: 0, y: 0 }}
                value={getInputValue(element) as string}
                onChange={(value) => {
                  onChangeInputs?.({ ...inputs, [element.targetKey]: value });
                }}
                fontSize={element.fontSize}
                backgroundColor={element.backgroundColor}
                textColor={element.textColor}
                label={element.label}
                width={element.width}
                maxLength={
                  element.enableMaxLength ? element.maxLength : undefined
                }
              />
            </div>
          </div>
        </Wrap>
      ))}
      {elementSliders.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <div className={cx(editable && classes.inputWrap)}>
              <Slider
                style={activeItem === element.id ? activeStyle : undefined}
                position={{ x: 0, y: 0 }}
                value={getInputValue(element) as number}
                onChange={(value) => {
                  onChangeInputs?.({ ...inputs, [element.targetKey]: value });
                }}
                width={element.width}
                max={element.max}
                min={element.min}
                label={element.label}
                showTextField={element.showTextField}
              />
            </div>
          </div>
        </Wrap>
      ))}
      {elementCounters.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <Counter
              id={element.id}
              readOnly={editable}
              style={activeItem === element.id ? activeStyle : undefined}
              position={{ x: 0, y: 0 }}
              resetButton={element.showResetButton}
              textColor={element.textColor}
              backgroundColor={element.backgroundColor}
              fontSize={element.fontSize}
              number={getCounterValue(element.id)}
              label={element.label}
            />
          </div>
        </Wrap>
      ))}
      {elementImages.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <div className={cx(editable && classes.inputWrap)}>
              <Image
                style={activeItem === element.id ? activeStyle : undefined}
                position={{ x: 0, y: 0 }}
                width={element.width}
                src={element.src}
              />
            </div>
          </div>
        </Wrap>
      ))}
      {elementSelects.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <div className={cx(editable && classes.inputWrap)}>
              <Select
                style={activeItem === element.id ? activeStyle : undefined}
                position={{ x: 0, y: 0 }}
                value={getInputValue(element) as string}
                onChange={(value) => {
                  onChangeInputs?.({ ...inputs, [element.targetKey]: value });
                }}
                items={element.items.split(";")}
                fontSize={element.fontSize}
                backgroundColor={element.backgroundColor}
                textColor={element.textColor}
                label={element.label}
                width={element.width}
              />
            </div>
          </div>
        </Wrap>
      ))}

      {elementLabels.map((element, index) => (
        <Wrap {...getDraggableProps(element)}>
          <div {...getWrapProps(element)}>
            <Label
              style={activeItem === element.id ? activeStyle : undefined}
              position={{ x: 0, y: 0 }}
              content={
                editable ? element.sourceValue : getLabelValue?.(element) ?? ""
              }
              textColor={element.textColor}
              backgroundColor={element.backgroundColor}
              fontSize={element.fontSize}
              padding={element.padding}
            />
          </div>
        </Wrap>
      ))}
      <Wrap {...getDraggableProps(camera)}>
        <div {...getWrapProps(camera)}>
          {editable ? (
            <CameraFake
              style={activeItem === camera.id ? activeStyle : undefined}
              width={camera.width}
              height={camera.height}
              position={{ x: 0, y: 0 }}
            />
          ) : (
            <div
              style={{
                width: camera.width,
                height: camera.height,
                position: "relative",
              }}
            >
              <Camera id="main" showRectangles={camera.showRectangles} />
            </div>
          )}
        </div>
      </Wrap>
    </div>
  );
};

export default ElementsPreview;
