import { connect, ConnectedProps } from "react-redux";
import ModelListView from "./components/ModelListView";
import {
  unsupervisedSetActiveModelId,
  setGpuSettings,
  unsupervisedSetName,
} from "../../actions/actions";
import { getModule, modelIsUsed } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { Modules } from "../../modules";
import { UnsupervisedModelUsed, UnsupervisedModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: { moduleId: number; maxHeight?: boolean }
) => {
  const moduleItems = state.database.modules.items.filter(
    (m) => m.type === Modules.UNSUPERVISED
  ) as UnsupervisedModule[];
  const moduleId = ownProps.moduleId;
  const module = getModule(state, moduleId) as UnsupervisedModule;
  const models: UnsupervisedModelUsed[] = state.database.unsupervisedModels.map(
    (i) => ({
      ...i,
      isUsed: modelIsUsed(moduleItems, i),
    })
  );
  return {
    module,
    models: models,
    activeModelId: module.modelId,
    gpuDevices: state.system.gpuDevices,
    maxHeight: ownProps.maxHeight,
  };
};

const connector = connect(mapStateToProps, {
  unsupervisedSetActiveModelId,
  setGpuSettings,
  unsupervisedSetName,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelListView);
