import { connect, ConnectedProps } from "react-redux";
import DetailView from "./components/DetailView";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { CodeItem, CodeModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string; itemId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as CodeModule;
  const codeItem = state.database.codeItems.find(
    (c) => c.itemId === +ownProps.match.params.itemId
  ) as CodeItem;

  return {
    moduleId,
    values: module.values,
    module,
    sourceCode: codeItem ? codeItem.source : null,
    name: codeItem ? codeItem.name : null,
    itemId: codeItem ? codeItem.itemId : null,
    fileId: codeItem ? codeItem.fileId : null,
    images: state.database.images,
    history: ownProps.history,
  };
};

const connector = connect(mapStateToProps, {});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DetailView);
