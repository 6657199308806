import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import {
  setModuleValue,
  ocrStartTraining,
  ocrSetImageRectangles,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { AnyAppDispatch, GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { Rectangle } from "../../types/common";
import { OcrModule } from "../../types/modules";
import { Flow } from "../../types/flow";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as OcrModule;
  return {
    moduleId,
    module,
    models: state.database.ocrModels,
    noImage: state.database.images.length === 0,
    imageRectangles: module.imageRectangles || {},
    images: state.database.images,
    trainingParams: module.trainingParams || {
      part1: 100,
      part2: 100,
      part3: 100,
    },
    modelsCounter: state.database.modelsCounter.OCR,
  };
};

const mapDispatchToProps = (
  dispatch: AnyAppDispatch,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  return {
    startTraining: (name: string, prevModel: number | null, flow: Flow) => {
      dispatch(ocrStartTraining(name, prevModel, moduleId, flow));
    },
    setModuleValue: (rectangles: Rectangle[]) =>
      dispatch(
        setModuleValue({
          withEditDate: false,
          followReset: false,
          moduleId,
          name: "rectangles",
          value: rectangles,
        })
      ),
    resetImageRectangles: () =>
      dispatch(
        setModuleValue({
          withEditDate: false,
          followReset: false,
          moduleId,
          name: "imageRectangles",
          value: {},
        })
      ),
    setTrainingParams: (values: any) =>
      dispatch(
        setModuleValue({
          withEditDate: false,
          followReset: false,
          moduleId,
          name: "trainingParams",
          value: values,
        })
      ),
    setOcv: (active: boolean) =>
      dispatch(
        setModuleValue({
          withEditDate: false,
          followReset: false,
          moduleId,
          name: "ocv",
          value: active,
        })
      ),
    setImageRectangles: (imageId: number, rectangles: Rectangle[]) => {
      dispatch(ocrSetImageRectangles({ moduleId, imageId, rectangles }));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrainingView);
