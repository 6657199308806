import React, { Component } from "react";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";
import NameTextField from "../NameTextField";
import {
  PageToolbar,
  PageToolbarButton,
  PageToolbarSpace,
} from "../PageToolbar";
import withI18n from "../../utils/withI18n";

const styles = {
  wrap: {
    position: "relative",
  },
  textUnderline: {
    borderColor: "rgb(171, 171, 171)",
  },
} as const;

type Props = {
  onSave: () => void;
  onChangeName: (name: string) => void;
  onClickBack: () => void;
  readOnly?: boolean;
  name: string;
  menuItems: React.ReactNode;
  isChange?: boolean;
  t: (key: string) => string;
};

class EditorHeader extends Component<Props> {
  handleTouchTapTraining = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onSave();
  };

  handleTouchTapBack = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClickBack();
  };

  render() {
    const _ = this.props.t;
    return (
      <div style={styles.wrap}>
        <PageToolbar>
          <PageToolbarButton
            color="secondary"
            icon={<LeftIcon />}
            onClick={this.handleTouchTapBack}
            label={_("back")}
          />
          {!this.props.readOnly && (
            <PageToolbarButton
              icon={<SaveIcon />}
              color="primary"
              disabled={!this.props.isChange}
              onClick={this.handleTouchTapTraining}
              label={_("save")}
            />
          )}
          <PageToolbarSpace />
          <NameTextField
            value={this.props.name}
            onChange={this.props.onChangeName}
          />
          {this.props.menuItems}
        </PageToolbar>
      </div>
    );
  }
}

export default withI18n(EditorHeader);
