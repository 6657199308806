import React, { Component } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { formatDateTime, formatDuration } from "../utils/format";
import ModelChart from "./ModelChart";
import withI18n from "../utils/withI18n";
import { AnyModel, DetectorModel } from "../types/modules";

const styles = {
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "32px",
  },
  infoItem: {
    marginTop: 5,
  },
  stage: {
    marginBottom: 10,
  },
  progress: {
    marginBottom: 20,
  },
};

type Props = {
  model: DetectorModel | AnyModel;
  diffTime: number;
  indeterminateLoader?: boolean;
  t: (key: string) => string;
};

class ModelInfo extends Component<Props> {
  interval: number | undefined;

  componentDidMount() {
    this.interval = window.setInterval(() => {
      const { model } = this.props;
      if (model && !model.completed && !model.failed) {
        this.forceUpdate();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { diffTime } = this.props;
    const _ = this.props.t;
    const model = this.props.model as any;
    const isTraining = model && !model.completed && !model.failed;
    const config = model.trainingParams.config || model.trainingParams;
    const { classNames } = (model as any).trainingParams;
    const extented = (model as any).trainingParams.modelId;
    const minutesDiff =
      (isTraining ? (Date.now() + diffTime) / 1000 : model.finishedTime) -
      model.creationDate;
    return (
      <div>
        <div style={styles.text}>
          {!isTraining && (
            <p>
              {_("creation_date")}: <b>{formatDateTime(model.creationDate)}</b>
              {model.finishedTime && !model.failed && (
                <>
                  <br />
                  {_("training_time")}:{" "}
                  <b>
                    {formatDuration(model.finishedTime - model.creationDate)}
                  </b>
                </>
              )}
            </p>
          )}
          {isTraining && minutesDiff > 0 && (
            <p>
              {_("training_time")}: {formatDuration(minutesDiff)}
              {/* {model.estTime !== undefined && (
                <>
                  <br></br>
                  {_("ETA")}: ~{formatDuration(model.estTime)}
                </>
              )} */}
            </p>
          )}
        </div>
        {model.progress !== undefined && isTraining && (
          <LinearProgress
            style={styles.progress}
            variant={"determinate"}
            color="primary"
            value={model.progress}
          />
        )}
        {model.progress === undefined &&
          model.firstStagePercent === undefined && <ModelChart model={model} />}
        {isTraining &&
          model.firstStagePercent !== undefined &&
          (model.saving ? (
            <p>
              <b>{_("saving")} ...</b>
            </p>
          ) : (
            <p>
              <div style={styles.stage}>
                {_("stage")} {model.secondStagePercent === null ? 1 : 2}
              </div>
              <LinearProgress
                key={model.secondStagePercent === null ? 1 : 2}
                variant={
                  this.props.indeterminateLoader
                    ? "indeterminate"
                    : "determinate"
                }
                value={
                  model.secondStagePercent === null
                    ? model.firstStagePercent
                    : model.secondStagePercent
                }
              />
            </p>
          ))}
        {config.SOURCE && (
          <div style={styles.infoItem}>
            <b>
              {_(
                config.SOURCE === "static"
                  ? "static_position"
                  : config.SOURCE === "full"
                  ? "classification_on_all_image"
                  : "detected_in_previous_modules"
              )}
            </b>
          </div>
        )}
        {config.MAX_SIZE !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("max_size")}</b>: {_(config.MAX_SIZE)}
          </div>
        )}
        {config.COLOR_JITTERING !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("color_jittering")}</b>: {_(config.COLOR_JITTERING)}
          </div>
        )}
        {/* config.VALIDATING_IMAGES_PERCENT !== undefined && (
          <div style={styles.infoItem}>
            <b>{_('validating_images_percent')}</b>:{' '}
            {_(config.VALIDATING_IMAGES_PERCENT)}
          </div>
        ) */}
        {config.HORIZONTAL_FLIP !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("horizontal_flip")}</b>:{" "}
            {_(config.HORIZONTAL_FLIP ? "yes" : "no")}
          </div>
        )}
        {config.VERTICAL_FLIP !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("vertical_flip")}</b>:{" "}
            {_(config.VERTICAL_FLIP ? "yes" : "no")}
          </div>
        )}
        {config.SHEAR !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("shear")}</b>: {_(config.SHEAR)}
          </div>
        )}
        {config.WIDTH_SHIFT !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("width_shift")}</b>: {_(config.WIDTH_SHIFT)}
          </div>
        )}
        {config.HEIGHT_SHIFT !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("height_shift")}</b>: {_(config.HEIGHT_SHIFT)}
          </div>
        )}
        {/* type === 'DETECTOR' && (
          <div className={styles.infoItem}>
            <b>{_('enable_detect_rotate')}</b>: {_(config.DETECT_ROTATE ? 'yes' : 'no')}
          </div>
        ) */}
        {config.ROTATE !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("rotate")}</b>: {_(config.ROTATE ? "yes" : "no")}
          </div>
        )}
        {config.PATCH_SIZE !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("size_of_view_finder")}</b>: {config.PATCH_SIZE}
          </div>
        )}
        {config.EPOCH !== undefined && config.EPOCH > 0 && (
          <div style={styles.infoItem}>
            <b>{_("epoch")}</b>: {config.EPOCH}
          </div>
        )}
        {config.EPOCH !== undefined && config.EPOCH === 0 && (
          <div style={styles.infoItem}>
            <b>{_("fast_training")}</b>
          </div>
        )}
        {config.NOISE !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("noise")}</b>: {config.NOISE}
          </div>
        )}
        {config.BRIGHTNESS !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("brightness")}</b>: {config.BRIGHTNESS}
          </div>
        )}
        {config.CONTRAST !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("contrast_resistance")}</b>: {config.CONTRAST}
          </div>
        )}
        {config.SATURATION !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("saturation_resistance")}</b>: {config.SATURATION}
          </div>
        )}
        {config.part1 !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("detection")}</b>: {config.part1}
          </div>
        )}

        {config.part3 !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("classification")}</b>: {config.part3}
          </div>
        )}
        {extented !== undefined && (
          <div style={styles.infoItem}>
            <b>{_("training_dialog_extend")}</b>: {_(extented ? "yes" : "no")}
          </div>
        )}
        {classNames && classNames.length > 0 && (
          <div style={styles.infoItem}>
            <b>{_("classnames")}</b>:{" "}
            {classNames.map(({ label }: { label: string }) => label).join(", ")}
          </div>
        )}
      </div>
    );
  }
}

export default withI18n(ModelInfo);
