import React, { Component } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import withI18n from "../../utils/withI18n";

const LABELS: { [key in SettingItem]?: string } = {
  ROTATE: "rotate",
  HORIZONTAL_FLIP: "horizontal_flip",
  VERTICAL_FLIP: "vertical_flip",
  COLOR_JITTERING: "color_jittering",
  // 'NOISE_LEVEL': 'noise',
  // 'BLUR_LEVEL': 'blur',
  ZOOM: "zoom",
  VALIDATING_IMAGES_PERCENT: "validating_images_percent",
  WIDTH_SHIFT: "width_shift",
  HEIGHT_SHIFT: "height_shift",
  SCALE: "scale",
  SHEAR: "shear",
  EPOCH: "epoch",
  NOISE: "noise",
  BRIGHTNESS: "brightness",
  SATURATION: "saturation_resistance",
  CONTRAST: "contrast_resistance",
  FAST_TRAINING: "Fast",
  MAX_SIZE: "max_size",
  STEPS: "Steps",
  DEEP_SEARCHING: "Deep searching",
} as const;

type NetworkType = "balance" | "performance" | "high_performance";

enum SettingItem {
  ROTATE = "ROTATE",
  HORIZONTAL_FLIP = "HORIZONTAL_FLIP",
  VERTICAL_FLIP = "VERTICAL_FLIP",
  COLOR_JITTERING = "COLOR_JITTERING",
  ZOOM = "ZOOM",
  VALIDATING_IMAGES_PERCENT = "VALIDATING_IMAGES_PERCENT",
  WIDTH_SHIFT = "WIDTH_SHIFT",
  HEIGHT_SHIFT = "HEIGHT_SHIFT",
  SCALE = "SCALE",
  SHEAR = "SHEAR",
  EPOCH = "EPOCH",
  NOISE = "NOISE",
  BRIGHTNESS = "BRIGHTNESS",
  SATURATION = "SATURATION",
  CONTRAST = "CONTRAST",
  FAST_TRAINING = "FAST_TRAINING",
  MAX_SIZE = "MAX_SIZE",
  STEPS = "STEPS",
  DEEP_SEARCHING = "DEEP_SEARCHING",
  NETWORK_SIZE = "NETWORK_SIZE",
  PATCH_SIZE = "PATCH_SIZE",
}

export type Settings = Partial<{
  ROTATE: boolean;
  HORIZONTAL_FLIP: boolean;
  VERTICAL_FLIP: boolean;
  COLOR_JITTERING: number;
  ZOOM: number;
  VALIDATING_IMAGES_PERCENT: number;
  WIDTH_SHIFT: boolean;
  HEIGHT_SHIFT: boolean;
  SCALE: boolean;
  SHEAR: number;
  EPOCH: number;
  NOISE: number;
  BRIGHTNESS: number;
  SATURATION: number;
  CONTRAST: number;
  FAST_TRAINING: boolean;
  MAX_SIZE: number;
  STEPS: number;
  DEEP_SEARCHING: number;
  NETWORK_SIZE: NetworkType;
  PATCH_SIZE: number;
  SOURCE: "static" | "full" | "context";
}>;

const styles = {
  radioGroup: {
    marginTop: 3,
  },
  radioItem: {
    marginTop: -7,
  },
} as const;

type Props = {
  values: Settings;
  onChangeValues: (values: Settings) => void;
  allowShift?: boolean;
  allowRotate?: boolean;
  allowFlip?: boolean;
  allowShear?: boolean;
  allowMobileNet?: boolean;
  readOnly?: boolean;
  disableNetworkSize?: boolean;
  disableMaxSize?: boolean;
  allowColorJittering?: boolean;
  allowValidatingPercent?: boolean;
  hiddenNetworkSize?: boolean;
  allowEpochSize?: boolean;
  allowNoiseResistance?: boolean;
  allowBrightnessResistance?: boolean;
  allowContrastResistance?: boolean;
  allowSaturationResistance?: boolean;
  allowFaster?: boolean;
  allowSteps?: boolean;
  allowMaxSize?: boolean;
  allowDeepSearching?: boolean;
  t: (key: string) => string;
  children?: JSX.Element;
};

class SettingForm extends Component<Props> {
  state = {
    currentNumberValue: null,
    currentNumberType: null,
  };

  renderCheckbox(type: SettingItem) {
    return (
      <FormControlLabel
        style={{ width: "100%" }}
        key={type}
        label={this.props.t(LABELS[type] as string)}
        control={
          <Checkbox
            disabled={this.props.readOnly}
            checked={!!this.props.values[type]}
            onChange={(e) =>
              this.props.onChangeValues({
                ...this.props.values,
                [type]: e.target.checked,
              })
            }
            color="primary"
          />
        }
      />
    );
  }

  renderNumber(
    type: SettingItem,
    min: number,
    max: number | undefined = undefined,
    disabled = false
  ) {
    let label = this.props.t(LABELS[type] as string);
    if (max) {
      label += ` (min ${min}, max ${max})`;
    } else {
      label += ` (min ${min})`;
    }

    return (
      <TextField
        variant="outlined"
        margin="dense"
        size="small"
        InputLabelProps={{
          style: {
            whiteSpace: "nowrap",
          },
        }}
        style={{ width: "100%" }}
        disabled={this.props.readOnly || disabled}
        value={
          this.state.currentNumberType === type
            ? this.state.currentNumberValue
            : this.props.values[type]
        }
        onChange={(e) => {
          this.setState({
            currentNumberValue: e.target.value,
          });
        }}
        onBlur={(e) => {
          const number = parseFloat(e.target.value);
          if (number >= min && (max === undefined || number <= max)) {
            this.props.onChangeValues({ ...this.props.values, [type]: number });
          }
          this.setState({
            currentNumberType: null,
          });
        }}
        onFocus={() => {
          this.setState({
            currentNumberValue: this.props.values[type],
            currentNumberType: type,
          });
        }}
        fullWidth
        label={label}
        inputProps={{
          min,
          max,
          type: "number",
        }}
      />
    );
  }

  renderNumberPicker(type: SettingItem) {
    return (
      <FormControl
        style={{ width: "100%" }}
        variant="outlined"
        margin="dense"
        size="small"
      >
        <InputLabel>{this.props.t(LABELS[type] as string)}</InputLabel>
        <Select
          label={this.props.t(LABELS[type] as string)}
          disabled={this.props.readOnly || this.props.disableNetworkSize}
          value={this.props.values[type]}
          onChange={(e) =>
            this.props.onChangeValues({
              ...this.props.values,
              [type]: e.target.value,
            })
          }
        >
          {Array.from({ length: 11 }, (v, k) => k * 10).map((i) => (
            <MenuItem key={i} value={i}>
              {`${i}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderCNNParams() {
    const {
      allowShift,
      allowRotate,
      allowFlip,
      allowShear,
      allowMobileNet,
      hiddenNetworkSize,
      allowColorJittering,
      allowValidatingPercent,
      allowBrightnessResistance,
      allowContrastResistance,
      allowSaturationResistance,
      allowEpochSize,
      allowNoiseResistance,
      allowFaster,
      allowMaxSize,
      allowDeepSearching,
      allowSteps,
    } = this.props;

    if (
      !(
        allowShift ||
        allowRotate ||
        allowFlip ||
        allowShear ||
        allowMobileNet ||
        !hiddenNetworkSize ||
        allowColorJittering ||
        allowValidatingPercent ||
        allowBrightnessResistance ||
        allowContrastResistance ||
        allowSaturationResistance ||
        allowEpochSize ||
        allowNoiseResistance ||
        allowFaster ||
        allowMaxSize ||
        allowDeepSearching ||
        allowSteps
      )
    ) {
      return null;
    }

    const _ = this.props.t;
    const netTypes = [
      //"high_accuracy",
      //"accuracy",
      "balance",
      "performance",
      "high_performance",
    ];
    if (allowMobileNet) {
      netTypes.push("super_performance");
    }

    return (
      <>
        {allowFlip && this.renderCheckbox(SettingItem.HORIZONTAL_FLIP)}
        {allowFlip && this.renderCheckbox(SettingItem.VERTICAL_FLIP)}
        {allowRotate && this.renderCheckbox(SettingItem.ROTATE)}
        {allowShift && this.renderNumber(SettingItem.WIDTH_SHIFT, 0, 100)}
        {allowValidatingPercent &&
          this.renderNumber(SettingItem.VALIDATING_IMAGES_PERCENT, 0, 99)}
        {!hiddenNetworkSize && (
          <FormControl
            style={{ width: "100%" }}
            variant="outlined"
            margin="dense"
            size="small"
          >
            <InputLabel>{_("network_size")}</InputLabel>
            <Select
              disabled={this.props.readOnly || this.props.disableNetworkSize}
              value={this.props.values.NETWORK_SIZE}
              onChange={(e) =>
                this.props.onChangeValues({
                  ...this.props.values,
                  NETWORK_SIZE: e.target.value as NetworkType,
                })
              }
            >
              {netTypes.map((name) => (
                <MenuItem value={name}>{_(name)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {allowFaster && (
          <>
            <RadioGroup
              style={styles.radioGroup}
              value={this.props.values.FAST_TRAINING ? "fast" : "deep"}
              onChange={(e) => {
                this.props.onChangeValues({
                  ...this.props.values,
                  FAST_TRAINING: e.target.value === "fast",
                });
              }}
            >
              <FormControlLabel
                style={styles.radioItem}
                value="fast"
                control={<Radio />}
                label={_("fast_training")}
              />
              <FormControlLabel
                style={styles.radioItem}
                value="deep"
                control={<Radio />}
                label={_("deep_training")}
              />
            </RadioGroup>
          </>
        )}
        {allowEpochSize &&
          this.renderNumber(
            SettingItem.EPOCH,
            1,
            undefined,
            !!(this.props.allowFaster && this.props.values.FAST_TRAINING)
          )}
        {allowMaxSize &&
          this.renderNumber(
            SettingItem.MAX_SIZE,
            50,
            800,
            this.props.disableMaxSize
          )}
        {allowSteps && this.renderNumber(SettingItem.STEPS, 1)}

        {allowContrastResistance &&
          this.renderNumberPicker(SettingItem.CONTRAST)}
        {allowShift && this.renderNumber(SettingItem.HEIGHT_SHIFT, 0, 100)}
        {allowShear && this.renderNumber(SettingItem.SHEAR, 0, 360)}
        {allowColorJittering &&
          this.renderNumber(SettingItem.COLOR_JITTERING, 0, 50)}
        {allowBrightnessResistance &&
          this.renderNumberPicker(SettingItem.BRIGHTNESS)}
        {allowNoiseResistance && this.renderNumberPicker(SettingItem.NOISE)}
        {allowSaturationResistance &&
          this.renderNumberPicker(SettingItem.SATURATION)}
        {allowDeepSearching && this.renderNumber(SettingItem.DEEP_SEARCHING, 1)}
      </>
    );
  }

  render() {
    return (
      <div>
        {this.props.children}
        {this.renderCNNParams()}
      </div>
    );
  }
}

export default withI18n(SettingForm);
