import { DependencyList, useEffect, useState } from "react";

// https://www.caktusgroup.com/blog/2020/07/01/usekeypress-hook-react/
function useKeyPress(
  key: string,
  action: () => void,
  depedency: DependencyList,
  ctrl = false
) {
  const isMeta = useIsKeyPressed("Meta") || !ctrl;
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key && (ctrl || isMeta)) action();
    }
    window.addEventListener("keydown", onKeyup);
    return () => window.removeEventListener("keydown", onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depedency, isMeta, action]);
}

function useIsKeyPressed(targetKey: string): boolean {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }: any): void {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: any): void => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}

const _defaults = { cb: useKeyPress, is: useIsKeyPressed };

export default _defaults;
