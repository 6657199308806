import React, { FC } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import { TOOLPANEL_HEIGHT } from "../../theme";

const useStyles = makeStyles()(() => ({
  root: {
    height: TOOLPANEL_HEIGHT,
    paddingLeft: 16,
    paddingRight: 16,
    lineHeight: `${TOOLPANEL_HEIGHT}px`,
  },
}));

type Props = {
  rightAllign?: boolean;
  label?: string;
  icon?: any;
} & ButtonProps;

const PageToolbarButton: FC<Props> = (props) => {
  const { classes } = useStyles();
  const { rightAllign, label, icon, ...otherProps } = props;
  return (
    <Button className={classes.root} startIcon={icon} {...otherProps}>
      {label}
    </Button>
  );
};

export default PageToolbarButton;
