import React, { useCallback } from "react";
import { Layer } from "react-konva";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import useKeyPress from "../useKeyPress";
import BackgroundImage from "../BackgroundImage";
import Rectangle from "./Rectangle";
import Wrap from "../Wrap";
import Polygon from "./Polygon";
import { KonvaEventObject } from "konva/lib/Node";
import Line from "./Line";
import { distanceBetweenPoints } from "../utils";

export type PolygonT = {
  points: [number, number][];
  id: number;
  text?: string;
  selectable?: boolean;
  moveable?: boolean;
  disabled?: boolean;
  disableDelete?: boolean;
};

export type RectangleT = {
  x: number;
  y: number;
  rotate?: number;
  width: number;
  height: number;
  id: number;
  text?: string;
  selectable?: boolean;
  moveable?: boolean;
  disabled?: boolean;
  color?: [number, number, number];
  transparent?: boolean;
  disableDelete?: boolean;
};

export type LineT = {
  id: number;
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
  selectable?: boolean;
  moveable?: boolean;
  disabled?: boolean;
  text?: string;
  disableDelete?: boolean;
};

export enum Tool {
  Rectangle = "Rectangle",
  Polygon = "Polygon",
  Line = "Line",
}

type Props = {
  tool?: Tool;
  setTool?: (tool: Tool) => void;
  items: (PolygonT | RectangleT | LineT)[];
  onChangeItems?: (items: (PolygonT | RectangleT | LineT)[]) => void;
  onEdit: (index: number, values: PolygonT | RectangleT | LineT) => void;
  onCreate: (values: PolygonT | RectangleT | LineT) => void;
  onDelete: (index: number) => void;
  onChangeScale?: (value: number) => void;
  imageSize: { width: number; height: number };
  imageUrl: string;
  width: number;
  height: number;
  onSelect?: (id: number | null) => void;
  selectedId?: number;
  rectangleMinSize?: number;
  circle?: boolean;
  rotate?: boolean;
  getResetFunction: (func: () => void) => void;
  onDbClick?: (pos: { x: number; y: number }) => void;
};

const Dashbord = (props: Props) => {
  const minSize = props.rectangleMinSize || 5;
  const [key, setKey] = React.useState(0);
  const { tool, items, onChangeItems, onEdit, onCreate } = props;
  const [scale, setScale] = React.useState(1);
  const [localSelectedId, selectShape] = React.useState<number | null>(null);
  const [newItem, setNewItem] = React.useState<{
    x0: number;
    y0: number;
    x1: number;
    y1: number;
  } | null>(null);
  const [newPolygon, setNewPolygon] = React.useState<[number, number][]>([]);
  const [start, setStart] = React.useState<null | { x: number; y: number }>(
    null
  );

  const selectedId = props.selectedId ?? localSelectedId;

  const handleChangeScahel = useCallback(
    (value: number) => {
      setScale(value);
      props.onChangeScale?.(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setScale, props.onChangeScale]
  );

  const handleDelete = () => {
    const currentItem = items.find((i) => i.id === selectedId);
    if (currentItem) {
      if (!currentItem.disableDelete) {
        props.onDelete(items.findIndex((i) => i.id === selectedId));
        onChangeItems?.(items.filter((i) => i.id !== selectedId));
      }
    }
  };

  const handleSelect = (id: number | null) => {
    selectShape(id);
    props.onSelect?.(id);
  };

  useKeyPress.cb("Backspace", handleDelete, [selectedId]);
  useKeyPress.cb("Delete", handleDelete, [selectedId]);

  const stageRef = React.useRef<Konva.Stage>(null);
  const backgroundRef = React.useRef<Konva.Image>(null);

  const checkDeselect = (e: KonvaEventObject<TouchEvent>) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      handleSelect(null);
    }
  };

  const handleDbClick = (e: KonvaEventObject<MouseEvent>) => {
    const stage = stageRef.current;
    if (backgroundRef.current === e.target && stage) {
      const pointer = stage.getRelativePointerPosition() as Vector2d;
      props.onDbClick?.(pointer);
    }
  };

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    e.evt.stopPropagation();
    e.evt.preventDefault();

    if (e.evt.which === 3) {
      handleEndPolygon();
      return;
    }

    const stage = stageRef.current;
    if (!stage) {
      return;
    }
    if (backgroundRef.current === e.target) {
      const pointer = stage.getRelativePointerPosition() as Vector2d;
      if (tool === Tool.Polygon) {
        handleSelect(null);
        setNewPolygon((prevP) => [...prevP, [pointer.x, pointer.y]]);
      } else if (tool === Tool.Rectangle || tool === Tool.Line) {
        setStart({ x: pointer.x, y: pointer.y });
      }
    }
  };

  const handleMouseUp = () => {
    if (start && !newItem) {
      setStart(null);
      handleSelect(null);
    } else if (start && newItem) {
      const id = Date.now();
      if (props.tool === Tool.Rectangle) {
        const width = Math.abs(newItem.x0 - newItem.x1);
        const height = Math.abs(newItem.y0 - newItem.y1);
        if (width > minSize && height > minSize) {
          const rectangle = {
            id,
            rotate: 0,
            x: Math.min(newItem.x0, newItem.x1),
            y: Math.min(newItem.y0, newItem.y1),
            width,
            height,
          };
          onChangeItems?.([...items, rectangle]);
          onCreate(rectangle);
          handleSelect(id);
        }
      } else if (props.tool === Tool.Line) {
        const line = {
          id,
          start: { x: newItem.x0, y: newItem.y0 },
          end: { x: newItem.x1, y: newItem.y1 },
        };
        const distance = distanceBetweenPoints(
          newItem.x0,
          newItem.y0,
          newItem.x1,
          newItem.y1
        );
        if (distance > 8) {
          onCreate(line);
          onChangeItems?.([...items, line]);
          handleSelect(id);
        }
      }
      setStart(null);
      setNewItem(null);
    }
  };

  const handleEndPolygon = () => {
    const id = Date.now();
    if (newPolygon.length > 2) {
      const newItem = { id: id, points: newPolygon };
      onCreate(newItem);
      onChangeItems?.([...items, newItem]);
    }
    setNewPolygon([]);
    handleSelect(id);
  };

  const handleMouseMove = () => {
    const stage = stageRef.current;
    if (!stage) {
      return;
    }
    if (!start) {
      return;
    }
    const pointer = stage.getRelativePointerPosition() as Vector2d;

    if (props.circle) {
      const width = Math.abs(start.x - pointer.x);
      const height = Math.abs(start.y - pointer.y);
      const maxSize = (width + height) / 2;
      setNewItem({
        x0: start.x,
        y0: start.y,
        x1: start.x + maxSize * (start.x < pointer.x ? 1 : -1),
        y1: start.y + maxSize * (start.y < pointer.y ? 1 : -1),
      });
    } else {
      setNewItem({
        x0: start.x,
        y0: start.y,
        x1: pointer.x,
        y1: pointer.y,
      });
    }
  };
  return (
    <>
      <Wrap
        ref={stageRef}
        imageSize={props.imageSize}
        wrapSize={{
          width: props.width,
          height: props.height,
        }}
        getResetFunction={props.getResetFunction}
        onChangeScale={handleChangeScahel}
        StageProps={{
          onTouchStart: checkDeselect,
          onMouseMove: handleMouseMove,
          onMouseUp: handleMouseUp,
          onMouseDown: handleMouseDown,
          onDblClick: handleDbClick,
        }}
      >
        <Layer>
          <BackgroundImage src={props.imageUrl} ref={backgroundRef} />
          {items.map((item, i) =>
            "points" in item ? (
              <Polygon
                scale={scale}
                moveable={item.moveable}
                selectable={item.selectable}
                disabled={item.disabled}
                key={item.id}
                listening={newPolygon.length === 0}
                onSelect={() => handleSelect(item.id)}
                onChange={(points) => {
                  const item = items.find(
                    (p, index) => i === index
                  ) as PolygonT;
                  const nextItem = { ...item, points };
                  const nextItems = items.map((p, index) =>
                    i === index ? nextItem : p
                  );
                  onChangeItems?.(nextItems);
                  onEdit(i, nextItem);
                }}
                isSelected={item.id === selectedId}
                points={item.points}
                text={item.text}
              />
            ) : "x" in item ? (
              <Rectangle
                onReset={() => setKey(key + 1)}
                rotate={props.rotate}
                showCircle={props.circle}
                keepRatio={props.circle}
                minSize={props.rectangleMinSize}
                moveable={item.moveable}
                selectable={item.selectable}
                disabled={item.disabled}
                scale={scale}
                key={item.id + "-" + key}
                transparent={
                  item.transparent ||
                  (props.imageSize.width === item.width &&
                    props.imageSize.height === item.height)
                }
                color={item.color}
                listening={newPolygon.length === 0}
                shapeProps={item}
                isSelected={item.id === selectedId}
                onSelect={() => {
                  handleSelect(item.id);
                }}
                onChange={(newAttrs: any) => {
                  const rects = items.slice();
                  rects[i] = newAttrs;
                  onChangeItems?.(rects);
                  onEdit(i, newAttrs);
                }}
                text={item.text}
              />
            ) : (
              <Line
                moveable={item.moveable}
                selectable={item.selectable}
                disabled={item.disabled}
                scale={scale}
                isSelected={item.id === selectedId}
                onSelect={() => {
                  handleSelect(item.id);
                }}
                color={[10, 244, 100]}
                start={item.start}
                end={item.end}
                listening={newPolygon.length === 0}
                onChange={(newAttrs: any) => {
                  const rects = items.slice();
                  rects[i] = newAttrs;
                  onEdit(i, newAttrs);
                  onChangeItems?.(rects);
                }}
                text={item.text}
              />
            )
          )}
          {newItem && props.tool === Tool.Rectangle && (
            <Rectangle
              showCircle={props.circle}
              shapeProps={{
                id: 0,
                rotate: 0,
                x: newItem.x0,
                y: newItem.y0,
                width: newItem.x1 - newItem.x0,
                height: newItem.y1 - newItem.y0,
              }}
              color={
                Math.abs(newItem.x1 - newItem.x0) < minSize ||
                Math.abs(newItem.y1 - newItem.y0) < minSize
                  ? [255, 0, 0]
                  : undefined
              }
              isSelected={false}
              onSelect={() => {}}
              onChange={() => {}}
              scale={scale}
            />
          )}
          {newItem && props.tool === Tool.Line && (
            <Line
              stroke={1}
              color={[200, 200, 200]}
              start={{ x: newItem.x0, y: newItem.y0 }}
              end={{ x: newItem.x1, y: newItem.y1 }}
              scale={scale}
            />
          )}
          {newPolygon.length > 0 && (
            <Polygon
              scale={scale}
              listening={false}
              onClickFirst={handleEndPolygon}
              isSelected
              points={newPolygon}
              onChange={setNewPolygon}
              stroke={[97, 190, 255]}
              fill={[255, 255, 255]}
            />
          )}
        </Layer>
      </Wrap>
    </>
  );
};

export default Dashbord;
