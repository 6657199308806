import React, { FC } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import Popover from "@mui/material/Popover";
import { CompactPicker } from "react-color";
import { TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
  },
  typography: {
    padding: theme.spacing(2),
  },
  color: {
    width: 50,
    height: 32,
    border: `solid 1px ${grey[200]}`,
    position: "absolute",
    marginTop: -42,
    marginLeft: 13,
    pointerEvents: "none",
  },
}));

type Props = {
  value: string;
  label: string;
  onChange: (color: string) => void;
};

const ColorPicker: FC<Props> = ({ value, onChange, label }) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        label={label}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        onMouseUp={handleClick}
        // onBlur={handleClose}
        inputProps={{
          style: {
            visibility: "hidden",
          },
        }}
      />
      <div className={classes.color} style={{ backgroundColor: value }} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <CompactPicker
          color={value}
          onChange={(value) => onChange(value.hex)}
        />
      </Popover>
    </div>
  );
};

export default ColorPicker;
