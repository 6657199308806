import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import {
  editConfig,
  getRectangles,
  setModuleValue,
  classifierEditAllImageRectangles,
  classifierResetImageRectangles,
  classifierSetImageRectangles,
  classifierSetClassNames,
  classifierEditImageRectangles,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { addErrorMessage } from "../../store/system/reducer";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { ClassifierModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as ClassifierModule;

  return {
    history: ownProps.history,
    moduleId: module.id,
    values: module.values,
    module,
    models: state.database.classifierModels,
    rectangleClassNames: module.values.classNames,
    cache: state.database.modules.items,
    detectedRectangles: state.system.detectedRectangles,
    runningAutoClassification:
      state.system.autClassifier.indexOf(module.id) !== -1,
    dangerSettings:
      module.dangerSettings && module.values.imageRectangles.length > 0,
    images: state.database.images,
    trainingDisabled: state.system.trainingDisabled,
    modelsCounter: state.database.modelsCounter.CLASSIFIER,
  };
};

const mapDispatchToProps = {
  getRectangles,
  editConfig,
  setModuleValue,
  addErrorMessage,
  classifierEditAllImageRectangles,
  classifierResetImageRectangles,
  classifierSetImageRectangles,
  classifierSetClassNames,
  classifierEditImageRectangles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrainingView);
