import React, { Component } from "react";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Editcon from "@mui/icons-material/Edit";
import OpenIcon from "@mui/icons-material/Pageview";
import { captureCamera } from "../../../actions/actions";
import { CON_MIN_HEIGHT_HEADER } from "../../../theme";
import CameraSettings from "./CameraSettings";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import withI18n from "../../../utils/withI18n";
import Camera from "../../../components/Camera";
import { PropsFromRedux } from "../InputContainer";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";

const styles = {
  wrap: {
    overflow: "hidden",
    flex: 1,
  },
  canvas: {
    position: "relative",
  },
  checkbox: {
    whiteSpace: "nowrap",
    marginLeft: 20,
    marginRight: 10,
  },
  cameraLogo: {
    display: "none",
    backgroundColor: "#263238",
    position: "relative",
    height: 48,
  },
  logo: {
    position: "absolute",
    top: 14,
    left: 17,
    zIndex: 1100,
    height: 21,
  },
  toggle: {
    whiteSpace: "nowrap",
    marginLeft: 20,
    marginRight: 10,
  },
  settings: {
    width: 300,
    padding: 10,
  },
  wrapAll: {
    flexFlow: "row",
    justifyContent: "space-around",
    display: "flex",
    alignItems: "stretch",
    height: CON_MIN_HEIGHT_HEADER,
  },
  buttonMargin: {
    marginBottom: 8,
  },
  captureButton: {},
} as const;

type State = {
  operatorMenuEl: null | Element;
};

class InputView extends Component<
  PropsFromRedux & { t: (key: string) => string },
  State
> {
  state: State = {
    operatorMenuEl: null,
  };

  handleChangeRunningConfig = (toggled: boolean, name: string) => {
    this.props.setRunning({ ...this.props.runningConfig, [name]: toggled });
  };

  handleOpenOperator = () => {
    this.handleCloseOperatorMenu();
    if (sessionStorage.getItem("adminUrl")) {
      this.props.history.push("/operator");
    } else {
      window.open("/operator", "_blank");
    }
  };

  handleOpenEditor = () => {
    this.handleCloseOperatorMenu();
    this.props.history.push("/camera/editor");
  };

  handleCloseOperatorMenu = () => this.setState({ operatorMenuEl: null });

  render() {
    const { cameraRunning } = this.props;
    const _ = this.props.t;
    return (
      <>
        <div style={styles.wrapAll}>
          <div style={styles.wrap} id="camera-wrap">
            <Camera id="main" showRectangles />
          </div>
          <div id="camera_settings">
            <ModuleRightToolPanel width={300}>
              <ModuleSettingsWrap>
                <Button
                  style={styles.captureButton}
                  fullWidth
                  variant="contained"
                  disabled={!cameraRunning}
                  color="primary"
                  onClick={captureCamera}
                  startIcon={<CameraIcon />}
                >
                  {_("do_capture")}
                </Button>
              </ModuleSettingsWrap>
              <ModuleSettingsWrap title={this.props.t("settings")}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.props.runningConfig.processing &&
                          !this.props.disableProcess
                        }
                        onChange={(e, checked) =>
                          this.handleChangeRunningConfig(checked, "processing")
                        }
                      />
                    }
                    label={_("do_process")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.runningConfig.save}
                        onChange={(e, checked) =>
                          this.handleChangeRunningConfig(checked, "save")
                        }
                      />
                    }
                    label={_("do_save")}
                  />
                </FormGroup>
              </ModuleSettingsWrap>
              <ModuleSettingsWrap title={this.props.t("operator_view")}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {" "}
                    <Button
                      style={styles.buttonMargin}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      startIcon={<OpenIcon />}
                      onClick={this.handleOpenOperator}
                    >
                      {_("open")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="secondary"
                      startIcon={<Editcon />}
                      onClick={this.handleOpenEditor}
                    >
                      {_("edit")}
                    </Button>
                  </Grid>
                </Grid>
              </ModuleSettingsWrap>
              {this.props.enableGenicam && !this.props.restriected && (
                <CameraSettings
                  cameraIsRunning={this.props.cameraIsRunning}
                  turnOnCamera={this.props.turnOnCamera}
                  turnOffCamera={this.props.turnOffCamera}
                  cameraSetConfigFile={this.props.cameraSetConfigFile}
                  cameraStatus={this.props.cameraStatus}
                  setCurrentCamera={this.props.setCurrentCamera}
                  currentCamera={this.props.currentCamera}
                  cameraAutoAdjust={this.props.cameraAutoAdjust}
                  provider={this.props.provider}
                  setProvider={this.props.setProvider}
                />
              )}
            </ModuleRightToolPanel>
          </div>
        </div>
      </>
    );
  }
}

export default withI18n(InputView);
