import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DialogTitle } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import withI18n from "../utils/withI18n";

type Props = {
  invalidPassword: boolean;
  onLogin: (password: string) => void;
  t: (key: string) => string;
};

type State = {
  password: string;
  showInvalidPassword: boolean;
};

class LoginDialog extends Component<Props, State> {
  state: State = {
    password: "",
    showInvalidPassword: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.invalidPassword &&
      (!prevProps.invalidPassword || !this.state.showInvalidPassword)
    ) {
      this.setState({
        showInvalidPassword: true,
      });
    }
  }

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.onLogin(this.state.password);
  };

  render() {
    return (
      <Dialog open>
        <form onSubmit={this.handleSubmit}>
          <DialogTitle>{this.props.t("login_title")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              fullWidth
              style={{ width: 350 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.password}
              onChange={(e) =>
                this.setState({
                  password: e.target.value,
                  showInvalidPassword: false,
                })
              }
              type="password"
              placeholder={this.props.t("password_placeholder")}
              label={this.props.t("password")}
              error={this.state.showInvalidPassword}
              helperText={
                this.state.showInvalidPassword &&
                this.props.t("invalid_password")
              }
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              {this.props.t("login")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withI18n(LoginDialog);
