import React, { Component } from "react";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import Settings from "./Settings";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import { Modules, typeToIcon, typeToLabel } from "../../../modules";
import { getImageData } from "../../../actions/ImageService";
import { deepCompare } from "../../../utils/common";
import EvaluationDialog from "../../../components/Evaluation/EvaluationDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import EvaluationButton from "../../../components/EvaluationButton";
import { MEASURE_DOC } from "../../../docLinks";
import { Tool } from "../../../components/ImageListWithMarkers/Dashboard/Objects/Dashboard";
import { Line } from "../../../types/common";
import { Image } from "../../../types/common";
import { Props } from "../WrapContainer";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";

type State = {
  lines: Line[];
  showEvaluationDialog: boolean;
  currentImage: null | Image;
  showLoader: boolean;
};

class MainView extends Component<
  Props & { t: (key: string) => string },
  State
> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    showEvaluationDialog: false,
    lines: [],
    currentImage: null,
    showLoader: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (
      (!deepCompare(prevProps.module.values, this.props.module.values) ||
        prevProps.module.isActive !== this.props.module.isActive) &&
      this.state.currentImage
    ) {
      this.setState({ showLoader: true });
      setTimeout(() => {
        if (this.state.currentImage)
          getImageData(
            this.state.currentImage.id,
            this.context.helper.getSettings({
              moduleId: this.props.module.id,
              otherModule: {
                id: this.props.module.id,
                type: this.props.module.type,
                editDate: Date.now(),
                isActive: this.props.module.isActive,
              },
            }),
            this.context.helper.token
          )
            .then((response) => {
              this.processResponse(response.data.lines);
            })
            .finally(() => {
              this.setState({ showLoader: false });
            });
      }, 300);
    }
  }

  processResponse = (lines: Line[]) => {
    this.setState({
      lines: lines
        .filter((i) => i.length && i.length > 0)
        .map((i) => ({
          ...i,
          id: Date.now(),
          disabled: true,
          text: `${
            this.props.module.values.lines.find((k) => k.id === i.id)?.label
          } - ${i.length}`,
        })),
    });
  };

  setLines(lines: any) {
    this.props.setModuleValue({
      moduleId: this.props.module.id,
      name: "values",
      value: {
        ...this.props.module.values,
        lines,
      },
    });
  }

  handleSelectImage = (i: Image) => {
    this.setState({
      currentImage: i,
      lines: [],
    });
    this.setState({ showLoader: true });
    getImageData(
      i.id,
      this.context.helper.getSettings({
        moduleId: this.props.module.id,
        includeLast: true,
      }),
      this.context.helper.token
    )
      .then((response) => {
        this.processResponse(response.data.lines);
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  handleChangeLines = (lines: any) => {
    this.setLines(lines);
    const filterLines = lines;
    this.props.setModuleValue({
      moduleId: this.props.module.id,
      name: "evaluation",
      value: {
        ...this.props.module.evaluation,
        rules: this.props.module.evaluation.rules.filter(
          (i) =>
            filterLines.find((k: any) => k.id === i.classname) ||
            i.classname === 0
        ),
      },
    });
  };

  render() {
    const _ = this.props.t;
    const { module } = this.props;
    const moduleId = module.id;
    const { lines } = module.values;
    const evaluationRules = module.evaluation.rules.filter(
      (i) =>
        lines.find((k) => k.id === i.classname) ||
        (i.classname !== undefined && i.classname <= 0)
    );
    return (
      <ModuleWrap
        docId={MEASURE_DOC}
        icon={typeToIcon(Modules.MEASURE)}
        title={module.label || _(typeToLabel(Modules.MEASURE))}
        noImage={this.props.noImage}
        active={module.isActive}
        onChangeActive={(isActive) =>
          this.props.setModuleValue({
            moduleId,
            name: "isActive",
            value: isActive,
          })
        }
      >
        <ImageListWithMarkers
          ImageDetailProps={{
            allowOverflow: true,
            tool: Tool.Line,
            lineMode: true,
          }}
          // key={module.showOriginal + '-' + module.isActive}
          getImageSrc={(imageId) => {
            return this.context.helper.getImagePath({
              imageId,
              moduleId,
              otherModule: module.showOriginal
                ? undefined
                : {
                    id: -1,
                    type: "CANNY",
                    threshold: module.values.threshold,
                  },
            });
          }}
          showLoader={this.state.showLoader}
          includeLast
          onSelectImage={this.handleSelectImage}
          lines={[...(module.isActive ? this.state.lines : []), ...lines]}
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          onChangeLines={(lines) => {
            this.setLines(lines.filter((i) => !i.disabled));
          }}
          moduleId={moduleId}
        >
          <ModuleRightToolPanel>
            {this.state.currentImage && (
              <Settings
                onChange={(values: any) =>
                  this.props.setModuleValue({
                    moduleId,
                    name: "values",
                    value: {
                      ...module.values,
                      threshold: values.threshold,
                    },
                  })
                }
                values={module.values}
                lines={lines}
                onChangeLines={this.handleChangeLines}
                showOriginal={module.showOriginal}
                onChangeShowOriginal={(isActive: boolean) =>
                  this.props.setModuleValue({
                    moduleId,
                    name: "showOriginal",
                    value: isActive,
                  })
                }
              />
            )}
            <ModuleSettingsWrap>
              <EvaluationButton
                onClick={() => this.setState({ showEvaluationDialog: true })}
              />
            </ModuleSettingsWrap>
          </ModuleRightToolPanel>
        </ImageListWithMarkers>
        <EvaluationDialog
          open={this.state.showEvaluationDialog}
          onClose={() => this.setState({ showEvaluationDialog: false })}
          classNameList={lines}
          values={{
            ...module.evaluation,
            rules: evaluationRules,
          }}
          onSave={(values) =>
            this.props.setModuleValue({
              moduleId,
              name: "evaluation",
              value: values,
            })
          }
        />
      </ModuleWrap>
    );
  }
}

export default withI18n(MainView);
