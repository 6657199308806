import React from "react";
import TrainingDialogView from "./components/TrainingDialogView";
import {
  classifierStopTraining,
  detectorStopTraining,
  ocrStopTraining,
  supervisedStopTraining,
  unsupervisedStopTraining,
} from "../../actions/actions";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";

const TrainingDialogContainer = () => {
  const state = useAppSelector((state) => {
    const detectorTrainingModel =
      state.database.detectorModels &&
      state.database.detectorModels.find((i) => !i.completed && !i.failed);
    const classifierTrainingModel =
      state.database.classifierModels &&
      state.database.classifierModels.find((i) => !i.completed && !i.failed);
    const supervisedTrainingModels =
      state.database.supervisedModels &&
      state.database.supervisedModels.find((i) => !i.completed && !i.failed);
    const unsupervisedTrainingModels =
      state.database.unsupervisedModels &&
      state.database.unsupervisedModels.find((i) => !i.completed && !i.failed);
    const ocrTrainingModels =
      state.database.ocrModels &&
      state.database.ocrModels.find((i) => !i.completed && !i.failed);
    return {
      detectorTrainingModel,
      classifierTrainingModel,
      supervisedTrainingModels,
      unsupervisedTrainingModels,
      ocrTrainingModels,
      diffTime: state.system.diffTime,
    }
  })

  const dispatch = useAnyAppDispatch()


  return (
    <TrainingDialogView
      {...state}
      detectorStopTraining={(modelId: number) => dispatch(detectorStopTraining(modelId))}
      classifierStopTraining={(modelId: number) => dispatch(classifierStopTraining(modelId))}
      unsupervisedStopTraining={(modelId: number) => dispatch(unsupervisedStopTraining(modelId))}
      supervisedStopTraining={(modelId: number) => dispatch(supervisedStopTraining(modelId))}
      ocrStopTraining={(modelId: number) => dispatch(ocrStopTraining(modelId))}
      />
  )
}

export default TrainingDialogContainer