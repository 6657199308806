import AnomalyTutorialView from "./components/AnomalyTutorialView";
import { Modules } from "../../modules";
import { closeTutorial } from "../../actions/actions";
import { useAppSelector, useAnyAppDispatch } from "../../hooks/store";
import React from "react";
import { UnsupervisedModule } from "../../types/modules";


const AnomalyTutorialContainer = () => {
  const state = useAppSelector(state => ({
    isSelectedImages: !!state.system.uploadImages,
    imagesUploaded: state.database.images.length > 0,
    anomalyModule: state.database.modules.items.find(
      (i) => i.type === Modules.UNSUPERVISED
    ) as (UnsupervisedModule | undefined),
    anomalyTrainingFinished: state.database.unsupervisedModels.length > 0,
    trainingLoading: state.system.loadingItems.indexOf("Training") !== -1,
    clickStartAnomalyTraining: state.system.clickStartAnomalyTraining,
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  }))
  const dispatch = useAnyAppDispatch()

  return (
    <AnomalyTutorialView
      closeTutorial={() => dispatch(closeTutorial())}
      {...state}
    />
  )
}

export default AnomalyTutorialContainer