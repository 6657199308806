import { connect, ConnectedProps } from "react-redux";
import {
  unsupervisedStartSensitivityAnalyze,
  unsupervisedSetEvaluation,
  unsupervisedSeSensitivityClassification,
  changeParam,
  moduleChangeShowHeatmap,
  moduleChangeShowRectangles,
  unsupervisedSetThreshold,
} from "../../actions/actions";
import ModelDetailView from "./components/ModelDetailView";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { UnsupervisedModel, UnsupervisedModule } from "../../types/modules";
import { withRequiredModel } from "../../utils/withRequiredModel";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{
    moduleId: string;
    showAutoSensitivity: string;
  }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as UnsupervisedModule;
  const { modelId } = module;
  return {
    history: ownProps.history,
    moduleId,
    module,
    images: state.database.images,
    model: state.database.unsupervisedModels.find(
      (m) => m.modelId === modelId
    ) as UnsupervisedModel,
    params: ownProps.match.params,
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  };
};

const connector = connect(mapStateToProps, {
  changeParam,
  startSensitivityAnalyze: unsupervisedStartSensitivityAnalyze,
  setEvaluation: unsupervisedSetEvaluation,
  setThreshold: unsupervisedSetThreshold,
  seSensitivityClassification: unsupervisedSeSensitivityClassification,
  moduleChangeShowHeatmap,
  moduleChangeShowRectangles,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRequiredModel(ModelDetailView));
