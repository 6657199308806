import React, { useCallback, useContext, useMemo, useState } from "react";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import TrainingButton from "../../../components/TrainingButton";
import { Button, TextField } from "@mui/material";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import ButtonWithConfirm from "../../../components/ButtonWithConfirm";
import RefreshIcon from "@mui/icons-material/Delete";
import { arraySum, setCharAt } from "../../../utils/common";
import TrainingDialog from "../../../components/Augmentation/TrainingDialog";
import { PropsFromRedux } from "../TrainingContainer";
import useI18n from "../../../hooks/useI18n";
import { Image, ImageSize, Rectangle } from "../../../types/common";
import { invalidAnnotations, splitText } from "./utils";
import { cornerToMidddle, middleToCorner } from "../../../utils/geometry";
import { percentToPixels, pixelsToPercent } from "../../../utils/format";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const styles = {
  annotationActionsFirst: {
    marginTop: 10,
  },
  annotationActions: {
    marginTop: 8,
  },
};

const TrainingView = (props: PropsFromRedux) => {
  const context = useContext(HelperContext);
  const [_] = useI18n();
  const [items, setItems] = useState<Rectangle[]>([]);
  const [showTrainingDialog, setShowTrainingDialog] = useState(false);
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [currentItemId, setCurrentItemId] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const currentItem = items.find((i) => i.id === currentItemId);
  const saveRectangles = (nextItems: Rectangle[]) => {
    setItems(nextItems);
    if (selectedImage) {
      props.setImageRectangles(selectedImage.id, nextItems);
    }
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!currentItem) {
      return;
    }
    const nextItems = items.map((i) =>
      i.id === currentItem?.id ? { ...i, text: e.target.value } : i
    );
    saveRectangles(nextItems);
  };

  const handleChangeCharacter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!currentItem) {
      return;
    }
    const character = e.target.value[e.target.value.length - 1]; // last character
    const parentRectangleIndex = items.findIndex(
      (i) => i.id === currentItem.parentId
    );
    const characterIndex = items.findIndex((i) => i.id === currentItem.id);
    const indexInText = characterIndex - parentRectangleIndex - 1;
    const nextItems = items.map((i) => {
      if (i.id === currentItem?.id) {
        return { ...i, text: character };
      } else if (i.id === currentItem.parentId && character !== undefined) {
        return { ...i, text: setCharAt(i?.text ?? "", indexInText, character) };
      }
      return i;
    });
    saveRectangles(nextItems);
  };

  const handleSelectImage = (image: Image) => {
    setSelectedImage(image);
    setCurrentItemId(null);
    setItems(props.imageRectangles[image.id] ?? []);
  };

  const images = useMemo(
    () =>
      props.images.map((i) => {
        const rectangles = props.imageRectangles[i.id];
        return {
          ...i,
          warning:
            (rectangles?.filter((i) => !i.text).length > 0 ?? false) ||
            (rectangles && invalidAnnotations(rectangles)),
          notSplited: props.imageRectangles[i.id],
          isMarked: props.imageRectangles[i.id]?.length > 0 ?? false,
        };
      }),
    [props.images, props.imageRectangles]
  );

  const rectanglesCount = useMemo(() => {
    return arraySum(
      Object.keys(props.imageRectangles).map((imageId) => {
        return props.imageRectangles[imageId as any].length;
      })
    );
  }, [props.imageRectangles]);

  const imagesMarkedCount = useMemo(() => {
    return Object.keys(props.imageRectangles).filter(
      (i) => props.imageRectangles[i as any].length > 0
    ).length;
  }, [props.imageRectangles]);

  const startTraining = useCallback(
    (name: string, prevModel: number | null) => {
      setShowTrainingDialog(false);
      props.startTraining(
        name,
        prevModel,
        context.helper.getSettings({ moduleId: props.module.id })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isCurrentSplitted =
    currentItem && items.find((i) => i.parentId === currentItem.id);

  const handleClickSplit = () => {
    if (currentItem && imageSize) {
      if ((currentItem.text?.length ?? 0) === 1) {
        saveRectangles([...items.filter((i) => i.parentId !== currentItem.id)]);
      } else {
        const nextRectangles = splitText(
          middleToCorner(percentToPixels(currentItem, imageSize)),
          currentItem.text || ""
        )
          .map(cornerToMidddle)
          .map((i) => pixelsToPercent(i, imageSize));
        setCurrentItemId(null);
        saveRectangles([
          ...items.filter((i) => i.parentId !== currentItem.id),
          ...nextRectangles,
        ]);
      }
    }
  };

  const noAnnotations = !images.some((i) => i.isMarked);
  const errorMessage = noAnnotations
    ? _("no_annotations")
    : images.find((i) => i.warning)
    ? _("ocr_none_marked_error")
    : undefined;

  const currentRectangles = useMemo<Rectangle[]>(() => {
    const invalidParentIds = items
      .filter((i) => {
        if (i.parentId) {
          return false;
        }
        const childs = items.filter(
          (k) => k.parentId === i.id || i.id === k.id
        );
        return !i.text || invalidAnnotations(childs);
      })
      .map((i) => i.id);
    return [
      ...items.map((i) => ({
        ...i,
        disableDelete: !!i.parentId,
        text: i.parentId || i.text?.length === 1 ? i.text : "",
        color: i.parentId
          ? invalidParentIds.includes(i.parentId)
            ? "ff0000"
            : "ffff00"
          : invalidParentIds.includes(i.id)
          ? "ff0000"
          : "ffff66",
      })),
    ];
  }, [items]);

  const enableResplit = useMemo(() => {
    if (currentItem && !currentItem.parentId) {
      const childs = items.filter((i) => i.parentId === currentItem.id);
      if ((currentItem.text?.length ?? 0) === 1 && childs.length > 1) {
        return true;
      }
      return false;
    }
  }, [items, currentItem]);

  const handleChangeRectangles = (imageId: number, nextItems: Rectangle[]) => {
    if (currentItem && !nextItems.some((i) => i.id === currentItem.id)) {
      setCurrentItemId(null);
    }
    if (nextItems.length < items.length) {
      // DELETE parent rectangle -> remove all childs
      saveRectangles(
        nextItems
          .filter(
            (i) => !i.parentId || nextItems.find((k) => k.id === i.parentId)
          )
          .map((i) => ({
            ...i,
            text: i.parentId ? i.text : items.find((k) => i.id === k.id)?.text,
          }))
      );
    } else {
      saveRectangles(
        nextItems
          .map((i) => (i.rotate === undefined ? { ...i, rotate: 0 } : i))
          .map((i) => ({
            ...i,
            text: i.parentId ? i.text : items.find((k) => i.id === k.id)?.text,
          }))
      );
    }
  };

  return (
    <>
      <ImageListWithMarkers
        ImageDetailProps={{
          enableRotate: true,
          selectedRectangle: currentItem,
        }}
        ImageListProps={{
          showSelectedFilter: true,
          images: images,
        }}
        onReceiveImgSize={setImageSize}
        disabledKeyListener={showTrainingDialog}
        detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
        listHeight={CON_MIN_HEIGHT_HEADER}
        onSelectImage={handleSelectImage}
        moduleId={props.moduleId}
        getInfo={({ filtered, all }) => ({
          title: _("image_list_info_help_rectangles"),
          text: `${rectanglesCount} (${imagesMarkedCount}) / ${filtered} / ${all}`,
        })}
        getCurrentRectangles={() => currentRectangles}
        onChangeRectangles={handleChangeRectangles}
        onSelectRectangle={(item) => setCurrentItemId(item?.id ?? null)}
      >
        <ModuleRightToolPanel>
          <ModuleSettingsWrap title={_("annotations")}>
            <ButtonWithConfirm
              ButtonProps={{
                disabled: items.length === 0,
                color: "secondary",
                fullWidth: true,
                size: "small",
                variant: "outlined",
                style: styles.annotationActionsFirst,
                startIcon: <RefreshIcon />,
              }}
              onClickConfirm={() => saveRectangles([])}
              confirmMessage={_("reset_image_annotations_confirm")}
              buttonLabel={_("reset_image")}
            />
            <ButtonWithConfirm
              ButtonProps={{
                disabled: Object.keys(props.imageRectangles).length === 0,
                fullWidth: true,
                color: "secondary",
                size: "small",
                variant: "outlined",
                style: styles.annotationActions,
                startIcon: <RefreshIcon />,
              }}
              onClickConfirm={() => {
                props.resetImageRectangles();
                setItems([]);
              }}
              confirmMessage={_("remove_selected_confirm")}
              buttonLabel={_("remove_annotations")}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={props.module.ocv}
                  onChange={(e) => props.setOcv(e.target.checked)}
                  color="primary"
                />
              }
              label="OCV"
            /> */}
            {currentItem && !currentItem.parentId && (
              <>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder={_("enter_text")}
                  label={_("text")}
                  variant="outlined"
                  margin="normal"
                  value={currentItem.text || ""}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  onKeyUp={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={handleChangeText}
                />
                {((currentItem?.text?.length ?? 0) > 1 || enableResplit) && (
                  <Button onClick={handleClickSplit} variant="outlined">
                    {_(isCurrentSplitted ? "re-split" : "split")}
                  </Button>
                )}
              </>
            )}
            {currentItem && currentItem.parentId && (
              <>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={_("character")}
                  variant="outlined"
                  margin="normal"
                  value={currentItem.text || ""}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  onKeyUp={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={handleChangeCharacter}
                />
              </>
            )}
          </ModuleSettingsWrap>
          <ModuleSettingsWrap>
            <TrainingButton onClick={() => setShowTrainingDialog(true)} />
          </ModuleSettingsWrap>
        </ModuleRightToolPanel>
      </ImageListWithMarkers>
      <TrainingDialog
        modelsCounter={props.modelsCounter}
        open={showTrainingDialog}
        onClose={() => setShowTrainingDialog(false)}
        onStartTraining={startTraining}
        models={props.models}
        onChangeConfig={() => {}}
        hiddenNetworkSize
        errorMessage={!props.module.ocv ? errorMessage : null}
        config={{}}
      >
        <>
          {" "}
          <TextField
            margin="dense"
            size="small"
            variant="outlined"
            fullWidth
            label={_("detection")}
            inputProps={{ type: "number", min: 0 }}
            defaultValue={props.trainingParams.part1}
            onChange={(e) =>
              props.setTrainingParams({
                ...props.trainingParams,
                part1: parseInt(e.target.value),
              })
            }
          />
          <TextField
            margin="dense"
            size="small"
            variant="outlined"
            fullWidth
            label={_("classification")}
            inputProps={{ type: "number", min: 0 }}
            defaultValue={props.trainingParams.part3}
            onChange={(e) =>
              props.setTrainingParams({
                ...props.trainingParams,
                part3: parseInt(e.target.value),
              })
            }
          />
        </>
      </TrainingDialog>
    </>
  );
};

export default TrainingView;
