import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "../../components/SimpleDialog";
import MiniLoader from "../../components/Loader/MiniLoader";
import useI18n from "../../hooks/useI18n";
import { useAppSelector } from "../../hooks/store";

const styles = {
  dialog: {
    minWidth: 300,
  },
  item: {
    position: "relative",
    lineHeight: "40px",
  },
  loader: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  label: {
    whiteSpace: "nowrap",
    paddingRight: 50,
  },
} as const;

const LoadingItems = () => {
  const { items } = useAppSelector((state) => ({
    items: state.system.loadingItems,
  }));

  const [_] = useI18n();
  return (
    <Dialog
      open={items.length > 0}
      title={_("wait_to_finish_process")}
      contentStyle={styles.dialog}
      style={{
        zIndex: 2000,
      }}
    >
      {items.map((i, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div style={styles.item} key={index}>
          {typeof i === "string" ? (
            <>
              <span style={styles.label}>{_(i)}</span>
              <MiniLoader style={styles.loader} />
            </>
          ) : (
            <>
              <span style={styles.label}>{_(i.name)}</span>
              <LinearProgress
                variant="determinate"
                color="primary"
                value={i.progress * 100}
              />
            </>
          )}
        </div>
      ))}
    </Dialog>
  );
};

export default LoadingItems;
