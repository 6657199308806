import React, { FC } from "react";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import utilsTypes from "./utilsTypes";
import useI18n from "../../../../hooks/useI18n";
import { DialogContent } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (id: string) => void;
};

const useStyles = makeStyles()({
  content: { padding: 0 },
});

const AddDialog: FC<Props> = (props) => {
  const { classes } = useStyles();
  const { onClose, onSelect, open } = props;
  const [_] = useI18n();
  function handleClose() {
    onClose();
  }

  function handleListItemClick(value: string) {
    onSelect(value);
    onClose();
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{_("select_function")}</DialogTitle>
      <DialogContent className={classes.content}>
        <List>
          {utilsTypes.map((item, index) => (
            <React.Fragment key={item.id}>
              {index !== 0 && <Divider />}
              <ListItem
                button
                onClick={() => handleListItemClick(item.id)}
                key={item.id}
              >
                <ListItemText primary={_(item.label)} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default AddDialog;
