import SettingDialogView from "./components/SettingDialogView";
import { setLanguage, setOfferTutorial } from "../../actions/actions";
import React from "react";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { closeSettingDialog } from "../../store/system/reducer";

const SettingDialogContainer = () => {
  const state = useAppSelector((state) => ({
    open: state.system.showSettingDialog,
    language: state.system.lang,
    version: state.system.version,
    buildDate: state.system.buildDate,
    offerTutorial: state.system.offerTutorial,
    license: state.system.license,
  }));
  const dispatch = useAnyAppDispatch();

  return (
    <SettingDialogView
      onClose={() => dispatch(closeSettingDialog())}
      changeLanguage={(lang: string) => dispatch(setLanguage(lang))}
      setOfferTutorial={(offer: boolean) => dispatch(setOfferTutorial(offer))}
      {...state}
    />
  );
};

export default SettingDialogContainer;
