import React, {FC} from "react";
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {grey} from "@mui/material/colors";


const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        position: 'absolute',
        backgroundColor: grey[800],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    }
}));


type Props = {
    width: number,
    height: number,
    position: {
        x: number,
        y: number,
    },
    style?: any,
}

const Camera: FC<Props> = ({ position, width, height, style }) => {
    const { classes } = useStyles()

    const elementStyle = {
        left: position.x, top: position.y, width, height, ...style
    }

    return (
        <div className={classes.root} style={elementStyle}>
            <span>Camera</span>
        </div>
    );
};

export default Camera;
