import React, { Component } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EvalutionDialog from "../../../components/Evaluation/EvaluationDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import EvaluationButton from "../../../components/EvaluationButton";
import { getNumberDictionary } from "../../../store/utils";
import withI18n from "../../../utils/withI18n";
import SliderWithField from "../../../components/SliderWithField";
import ChangeModelPanel from "../../../components/ChangeModelPanel";
import ModelListContainerSupervised from "../../../routes/Supervised/ListContainer";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import { PropsFromRedux } from "../ModelDetailContainer";

type State = {
  showEvaluationDialog: boolean;
};

type Props = PropsFromRedux & { t: (key: string) => string };

class HeatmapModelDetail extends Component<Props, State> {
  state: State = {
    showEvaluationDialog: false,
  };

  getImages = (props: Props) => {
    const trainingImagesDict = getNumberDictionary(props.model.trainingImages);
    const validateImagesDict = getNumberDictionary(props.model.validateImages);

    return props.images.map((i) => {
      if (trainingImagesDict[i.id]) {
        return {
          ...i,
          isValidate: false,
          isTraining: true,
        };
      }
      if (validateImagesDict[i.id]) {
        return {
          ...i,
          isValidate: true,
          isTraining: false,
        };
      }
      return i;
    });
  };

  render() {
    const _ = this.props.t;
    if (!this.props.model) {
      return null;
    }

    const evaluateValueTypes = [
      { id: "COUNT", label: _("count") },
      { id: "EDGE_LENGTH", label: _("edge_length") },
      { id: "AREA", label: _("area") },
    ];

    const images = this.getImages(this.props);

    return (
      <>
        <ImageListWithMarkers
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          withMask
          moduleId={this.props.module.id}
          readOnly
          includeLast
          ImageListProps={{
            showSelectedFilter: true,
            images: images,
          }}
        >
          <ModuleRightToolPanel>
            <ChangeModelPanel model={this.props.model}>
              <ModelListContainerSupervised moduleId={this.props.module.id} />
            </ChangeModelPanel>
            <ModuleSettingsWrap title={_("settings")}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.module.showHeatmap}
                      onChange={(e) => {
                        this.props.moduleChangeShowHeatmap({
                          moduleId: this.props.module.id,
                          show: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={_("show_heatmap")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.module.showRectangles}
                      onChange={(e) => {
                        this.props.moduleChangeShowRectangles({
                          moduleId: this.props.module.id,
                          show: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={_("show_rectangles")}
                />
              </FormGroup>
              {(this.props.model.trainingParams as any).config.TYPE !==
                "universal" && (
                <>
                  <SliderWithField
                    update
                    marginTop
                    value={this.props.model.threshold || 50}
                    label={_("confidence_threshold")}
                    onChange={(value) => {
                      this.props.setThreshold?.({
                        value,
                        modelId: this.props.model.modelId,
                      });
                    }}
                    min={1}
                    step={0.1}
                    max={100}
                  />
                </>
              )}
            </ModuleSettingsWrap>
            <ModuleSettingsWrap>
              <EvaluationButton
                onClick={() => this.setState({ showEvaluationDialog: true })}
              />
            </ModuleSettingsWrap>
          </ModuleRightToolPanel>
        </ImageListWithMarkers>

        <EvalutionDialog
          classNameList={this.props.model.trainingParams.classNames}
          valueTypes={evaluateValueTypes}
          open={this.state.showEvaluationDialog}
          onClose={() => this.setState({ showEvaluationDialog: false })}
          onSave={(evaluation) => {
            this.props.updateEvaluation?.({
              modelId: this.props.model.modelId,
              evaluation,
            });
          }}
          values={this.props.model.evaluation || {}}
        />
      </>
    );
  }
}

export default withI18n(HeatmapModelDetail);
