import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import {
  detectorEditConfig,
  detectorEditImageRectangles,
  detectorEditImageFixedRectangle,
  getRectangles,
  detectorSetLastRectangle,
  detectorEditAllImageRectangles,
  detectorSetRectangleClassName,
  detectorSetClassNames,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { DetectorModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as DetectorModule;
  return {
    moduleId,
    history: ownProps.history,
    values: module.values,
    module,
    models: state.database.detectorModels,
    detectedRectangles: state.system.detectedRectangles,
    dangerSettings:
      module.dangerSettings && module.values.imageRectangles.length > 0,
    images: state.database.images,
    trainingDisabled: state.system.trainingDisabled,
    modelsCounter: state.database.modelsCounter.DETECTOR,
  };
};

const mapDispatchToProps = {
  editConfig: detectorEditConfig,
  editImageRectangles: detectorEditImageRectangles,
  editImageFixRectangle: detectorEditImageFixedRectangle,
  getRectangles,
  detectorSetLastRectangle,
  detectorEditAllImageRectangles,
  detectorSetRectangleClassName,
  detectorSetClassNames,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrainingView);
