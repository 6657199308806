import RuntimeStatisticsView from "./components/RuntimeStatisticsView";
import React from "react";
import { useAppSelector } from "../../hooks/store";



const RuntimeStatisticsContainer = () => {
  const state = useAppSelector(state => ({
    token: state.system.token,
  }))

  return (
    <RuntimeStatisticsView
      {...state}
    />
  )
}

export default RuntimeStatisticsContainer

