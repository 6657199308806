import { Image } from "../types/common";
import { SortModules } from "../types/modules";

export const sortImagesByDate = (items: Image[]) =>
  [...items].sort((a, b) => a.creationDate - b.creationDate);

const getAllIds = (items: SortModules): number[] =>
  items.reduce<number[]>((sum, item) => {
    if (Array.isArray(item)) {
      const subIds = item
        .map((k) => getAllIds(k))
        .reduce((s, i) => [...s, ...i], []);
      return [...sum, ...subIds];
    }
    return [...sum, item];
  }, []);

const findInDeep = (items: SortModules, id: number): number => {
  return items.findIndex((item) => {
    if (Array.isArray(item)) {
      return item.map((i) => findInDeep(i, id)).find((i) => i !== -1);
    }
    return item === id;
  });
};

export const getFollowIds = (
  items: SortModules,
  moduleId: number
): number[] => {
  const ids: number[] = [];
  const indexItem = findInDeep(items, moduleId);
  if (indexItem === -1) {
    return [];
  }
  // find in current item
  if (Array.isArray(items[indexItem])) {
    const firstItem = (items?.[indexItem] as SortModules[])[0];
    if (firstItem && Array.isArray(firstItem)) {
      (items[indexItem] as SortModules[]).forEach((item) => {
        if (findInDeep(item, moduleId) !== -1) {
          const outputs = getFollowIds(item, moduleId);
          if (outputs) {
            ids.push(...outputs);
          }
        }
      });
    }
  }
  // add from following
  ids.push(...getAllIds(items.slice(indexItem + 1)));
  return ids;
};

export const getDictionaryById = <T extends { id: number }>(array: T[]) => {
  const r: { [n: number]: T } = {};
  array.forEach((i) => {
    r[i.id] = i;
  });
  return r;
};

export const getNumberDictionary = (array: number[]) => {
  const r: { [n: number]: true } = {};
  array.forEach((i) => {
    r[i] = true;
  });
  return r;
};

export function arrayBufferToBase64(buffer: Iterable<number>): string {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
