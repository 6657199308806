import { Socket } from "socket.io-client";
import { Line, Rectangle } from "../types/common";

type ListenerCb = (
  lastFrame: any,
  rectangles: Rectangle[],
  lines: any[],
  imageSize: { width: number; height: number },
  status: string | null,
  scale: number
) => void;
type StreamData = {
  rectangles: Rectangle[];
  lines: Line[];
  imageSize: { width: number; height: number };
  status: string;
  scale: number;
};

export default class Camera {
  lastFrameId: number = -2;
  isCameraRunning: boolean = false;
  resetCamera: boolean = false;
  lastFrame: HTMLImageElement | null = null;
  socket: Socket;
  listener: null | ListenerCb = null;
  streamData: StreamData | null = null;

  constructor(socket: Socket) {
    this.socket = socket;
    this.initConnection(socket);
  }

  byteArrayToLong = (byteArray: Uint8Array) => {
    let value = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = byteArray.length - 1; i >= 0; i--) {
      value = value * 256 + byteArray[i];
    }
    return value;
  };

  registerListener = (listener: ListenerCb) => {
    this.listener = listener;
  };

  unregisterListener = () => {
    this.listener = null;
  };

  runningCam = () => {
    if (!this.isCameraRunning) {
      this.isCameraRunning = true;
      this.socket.emit("stream", { frame_id: -1 });
    } else if (this.listener && this.lastFrame && this.streamData) {
      this.listener(
        this.lastFrame,
        this.streamData.rectangles,
        this.streamData.lines,
        this.streamData.imageSize,
        this.streamData.status,
        this.streamData.scale
      );
    }
  };

  initConnection = (socket: Socket) => {
    socket.on("dataCamera", (data: Uint8Array) => {
      const canvasMain = document.getElementById("canvas_camera_main");
      if (!canvasMain) {
        this.isCameraRunning = false;
        return;
      }

      const arrayBuffer = data;

      // check is new image
      if (arrayBuffer.byteLength === 0) {
        setTimeout(() => {
          if (this.isCameraRunning) {
            if (this.resetCamera) {
              this.resetCamera = false;
              this.lastFrameId = -1;
            }
            socket.emit("stream", { frame_id: this.lastFrameId });
          }
        }, 15);
        return;
      }
      // get frame id from bytes
      this.lastFrameId = this.byteArrayToLong(
        new Uint8Array(arrayBuffer.slice(0, 8))
      );

      // get image
      const bytes = new Uint8Array(arrayBuffer.slice(8));
      const blob = new Blob([bytes.buffer]);

      const image = new Image();
      image.onerror = (e) => console.log(e);
      image.onload = (e) => {
        this.lastFrame = image;

        if (canvasMain && this.listener && this.streamData) {
          this.listener(
            image,
            this.streamData.rectangles,
            this.streamData.lines,
            this.streamData.imageSize,
            this.streamData.status,
            this.streamData.scale
          );
        }

        if (this.isCameraRunning) {
          if (this.resetCamera) {
            this.resetCamera = false;
            this.lastFrameId = -1;
          }
          setTimeout(() => {
            socket.emit("stream", { frame_id: this.lastFrameId });
          }, 5);
        }
        URL.revokeObjectURL(image.src);
      };
      image.src = URL.createObjectURL(blob);
    });

    socket.on("rectangles", (data: StreamData) => {
      this.streamData = data;
    });
  };
}
