import { AverageTimes, InitData } from "./../store/system/reducer";
import { ClassifierModule } from "./../types/modules";
import { io } from "socket.io-client";
import {
  actions as systemActions,
  DebugerInfo,
  LoadingItem,
  ReceivedRectangle,
  Statistics,
} from "../store/system/reducer";
import * as databaseActions from "../store/database/reducer";
import * as imagesActions from "../store/images/reducer";
import { GetState, GlobalState } from "../store/createStore";
import { SERVER_URL } from "../config";
import { findDetectedRectangles } from "../utils/common";
import { getFollowIds } from "../store/utils";
import * as ImageService from "./ImageService";
import {
  FileWithPath,
  getImageData,
  selectedFiles,
  setSelectedFiles,
} from "./ImageService";
import { HELP_PANEL_WIDTH_MIN } from "../theme";
import { history } from "../components/App";
import Camera from "./camera";
import { FilterSettings } from "../utils/sortFilterImages";
import { Flow } from "../types/flow";
import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { AnyEntity, DatabaseState } from "../store/database/reducer";
import { AnyModule, SortModules } from "../types/modules";
import { Rectangle, Tag, Image } from "../types/common";
import { Camera as CameraT } from "../routes/Camera/types";
import { AutoThreholdAnnotations } from "../components/AutoThresholdDialog";

type AppDispatch = ThunkDispatch<GlobalState, undefined, AnyAction>;

export const socket = io(SERVER_URL);
export const camera = new Camera(socket);

const push = (path: string) => {
  history.push(path);
};

let initConnectionCalled = false;

export const initConnection =
  (
    cb: () => void,
    notification: (message: string | [string, ...string[]]) => void
  ) =>
  (dispatch: AppDispatch, getState: GetState) => {
    if (initConnectionCalled) {
      return;
    }

    initConnectionCalled = true;

    socket.on("connect", function () {
      cb();
    });

    socket.on("error", function () {
      console.log("SOCKET ERROR");
      dispatch(systemActions.disconnect());
      (socket as any).destroy();
    });

    socket.on("fatal_error", function () {
      dispatch(systemActions.receiveFatalError());
    });

    socket.on("disconnect", function () {
      console.log("SOCKET DISCONNECT");
      setTimeout(
        () => {
          dispatch(systemActions.disconnect());
        },
        getState().system.willBeReset ? 60000 * 8 : 100
      );
    });

    socket.io.on("reconnect", function () {
      console.log("SOCKET RECONNECT");
      window.location.reload();
    });

    socket.on("data", (data: InitData) => {
      if (
        data.simpleTutorialOnly &&
        window.location.pathname !== "/images/" &&
        window.location.pathname !== "/"
      ) {
        window.location.href = "/images/";
      }

      dispatch(databaseActions.init((data as any).data));
      dispatch(systemActions.receiveInitData(data));

      if (data.simpleTutorialOnly) {
        // set localiation by search params or session
        const searchParams = new URLSearchParams(window.location.search);
        const langSearch = searchParams.get("lang");
        const langSession = sessionStorage.getItem("lang");
        if (langSearch) {
          // save to the session for reload without search params
          window.sessionStorage.setItem("lang", langSearch);
          dispatch(systemActions.changeLanguage(langSearch));
        } else if (langSession) {
          dispatch(systemActions.changeLanguage(langSession));
        }
      }
    });

    socket.on("connect_error", () => {
      console.log("CONNECT_ERROR");
      dispatch(systemActions.connectError());
    });

    socket.on("update_by_path", (data: { path: any; value: any }) => {
      dispatch(databaseActions.update({ path: data.path, value: data.value }));
    });

    socket.on("remove_by_path", (data: { path: any; id: string | number }) => {
      dispatch(databaseActions.remove({ path: data.path, id: data.id }));
    });

    socket.on("set_camera_running", ({ running }: { running: boolean }) => {
      dispatch(systemActions.setCameraRunning(running));
    });

    socket.on(
      "on_create_code",
      ({ itemId, moduleId }: { itemId: number; moduleId: number }) => {
        push(`/modules/code/${moduleId}/${itemId}/`);
      }
    );

    socket.on(
      "on_create_mask",
      ({ itemId, moduleId }: { itemId: number; moduleId: number }) => {
        push(`/modules/mask/${moduleId}/${itemId}/`);
      }
    );

    socket.on(
      "on_create_unifier",
      ({ itemId, moduleId }: { itemId: number; moduleId: number }) => {
        push(`/modules/unifier/${moduleId}/${itemId}/`);
      }
    );

    socket.on("language", (language: string) => {
      dispatch(systemActions.changeLanguage(language));
    });

    socket.on(
      "update_gpu_info",
      (data: { memoryTotal: number; memoryUsed: number }) => {
        const wrapElement = document.getElementById("gpu_info_wrap");
        const childrenElement = document.getElementById("gpu_info");
        if (wrapElement && childrenElement) {
          const _ = window._;
          const title = `${_("gpu_memory")} - ${_("used")}: ${
            data.memoryUsed
          }, ${_("total")}: ${data.memoryTotal}`;
          const content = `${Math.round(
            (data.memoryUsed / data.memoryTotal) * 100
          )}%`;
          wrapElement.style.display = "contents";
          childrenElement.innerText = content;
          childrenElement.setAttribute("title", title);
        }
      }
    );

    socket.on(
      "detected_rectangles",
      ({ settings, rectangles, imageId, error }: ReceivedRectangle) => {
        dispatch(
          systemActions.receiveDetectedRectangles({
            settings,
            rectangles,
            imageId,
            error,
          })
        );
      }
    );

    socket.on(
      "receive_debugger_image",
      ({ settings, imageId, context, error }: DebugerInfo) => {
        dispatch(
          systemActions.receiveDebuggerInfo({
            settings,
            imageId,
            context,
            error,
          })
        );
      }
    );

    socket.on(
      "receive_average_times",
      ({ settings, imagesLastId, times, error }: AverageTimes) => {
        dispatch(
          systemActions.receiveAverageTimes({
            settings,
            imagesLastId,
            times,
            error,
          })
        );
      }
    );

    socket.on(
      "receive_notification",
      ({ msg }: { msg: string | [string, ...string[]] }) => {
        console.log("RECEIVE NOTIFICATION", msg);
        notification(msg);
      }
    );

    socket.on(
      "start_auto_classification",
      ({ moduleId }: { moduleId: number }) => {
        dispatch(systemActions.startAutoClassification(moduleId));
      }
    );

    socket.on(
      "end_auto_classification",
      ({ moduleId }: { moduleId: number }) => {
        dispatch(systemActions.endAutoClassification(moduleId));
      }
    );

    socket.on(
      "receive_token",
      ({ token, withPassword }: { token: string; withPassword: boolean }) => {
        localStorage.setItem("token", token);
        dispatch(systemActions.receiveToken({ token, withPassword }));
      }
    );

    socket.on("invalid_token", () => {
      localStorage.removeItem("token");
      dispatch(systemActions.invalidToken());
    });

    socket.on("invalid_password", () => {
      dispatch(systemActions.invalidPassword(true));
    });

    socket.on("set_flow_light_only", (isLight: boolean) => {
      dispatch(systemActions.setFlowLightOnly(isLight));
    });

    socket.on("set_loading", ({ items }: { items: LoadingItem[] }) => {
      dispatch(systemActions.setLoading(items));
    });

    socket.on("will_be_reset", () => {
      dispatch(systemActions.willBeReset());
    });

    socket.on("add_error_messages", ({ text }: { text: string }) => {
      dispatch(systemActions.addErrorMessage(text));
    });

    socket.on(
      "extra_model_data",
      ({
        modelId,
        data,
        type,
      }: {
        modelId: number;
        data: any;
        type: "detector";
      }) => {
        dispatch(systemActions.receiveExtraModelData({ modelId, data, type }));
      }
    );

    socket.on("set_show_tutorial", ({ isRunning }: { isRunning: boolean }) => {
      dispatch(systemActions.setShowTutorial(isRunning));
    });

    socket.on(
      "gpu_info",
      (data: { memoryUsed: number; memoryTotal: number }) => {
        if ((window as any).setGpuInfo) {
          (window as any).setGpuInfo(data);
        }
      }
    );

    socket.on(
      "training_done",
      (data: { module: string; modelId: number; moduleId: number }) => {
        history.push(
          `/modules/${data.module.toLowerCase()}/${
            data.moduleId
          }/validate/detail`
        );
      }
    );
  };

export const getRectangles =
  ({ settings, imageId }: { settings: Flow; imageId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const currentImage = findDetectedRectangles({
      detectedRectangles: getState().system.detectedRectangles,
      settings,
      imageId,
    });

    if (!currentImage) {
      getImageData(imageId, settings, getState().system.token)
        .then((response) => {
          dispatch(
            systemActions.receiveDetectedRectangles({
              settings,
              rectangles: response.data.rectangles,
              imageId,
              error: false,
            })
          );
        })
        .catch(() => {
          dispatch(
            systemActions.receiveDetectedRectangles({
              settings,
              rectangles: [],
              imageId,
              error: true,
            })
          );
        });
    }
  };

export const resetModuleValues = (moduleId: number) => {
  socket.emit("module_reset_values", { moduleId });
};

const setStore =
  (path: databaseActions.Path, value: any, localUpdate = true) =>
  (dispatch: AppDispatch, getState: GetState) => {
    if (localUpdate) {
      dispatch(databaseActions.update({ path: path, value: value }));
    }
    socket.emit("set_store", {
      path,
      value,
      updated: localUpdate,
    });
  };

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  GlobalState,
  unknown,
  AnyAction
>;

export const setActiveModelId =
  ({ modelId, moduleId }: { modelId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "modelId"],
        modelId,
        false
      )
    );

    setFollowsToReset(moduleId);

    dispatch(setFollowsToReset(moduleId));
  };

export const setModelName =
  (entity: AnyEntity, modelId: number, name: string) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue(entity, modelId, "name", name));
  };

export const setModelValue =
  (entity: AnyEntity, modelId: number, name: string, value: any) =>
  (dispatch: AppDispatch, getState: GetState) => {
    let entityId = "modelId";
    if (entity === "unifierItems") {
      entityId = "itemId";
    } else if (entity === "maskItems") {
      entityId = "itemId";
    } else if (entity === "codeItems") {
      entityId = "itemId";
    }
    dispatch(setStore([entity, { [entityId]: modelId }, name], value));
  };

export const debuggerImage = ({
  imageId,
  settings,
}: {
  imageId: number;
  settings: Flow;
}) => {
  socket.emit("debugger_image", { imageId, settings });
};

export const averageTimes = ({
  settings,
  imagesLastId,
}: {
  settings: Flow;
  imagesLastId: number;
}) => {
  socket.emit("average_times", { settings, imagesLastId });
};

export const setRunning =
  (running: DatabaseState["running"]) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["running"], running));
  };

export const setLanguage =
  (key: string) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("set_language", key);
    dispatch(systemActions.changeLanguage(key));
  };

export const setShowDoc =
  (show: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(systemActions.setShowDoc(show));
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      document.dispatchEvent(new Event("resize"));
    }, 300);
  };

export const setHelpPanelWidth =
  (width: number) => (dispatch: AppDispatch, getState: GetState) => {
    const value = Math.max(HELP_PANEL_WIDTH_MIN, width);
    dispatch(systemActions.setHelpPanelWidth(value));

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
      document.dispatchEvent(new Event("resize"));
    }, 300);
  };

export const setFollowsToReset =
  (moduleId: number) => (dispatch: AppDispatch, getState: GetState) => {
    const { sort } = getState().database.modules;
    const followIds = getFollowIds(sort, moduleId);
    followIds.forEach((id) => {
      const module = getState().database.modules.items.find((i) => i.id === id);
      let setDanger = false;

      if (!module) {
        return;
      }

      if (module.type === "DETECTOR" || module.type === "CLASSIFIER") {
        if (module.values.imageRectangles.length > 0) {
          setDanger = true;
        }
      } else if (module.type === "SUPERVISED") {
        if (module.values.masks.length > 0) {
          setDanger = true;
        }
      } else if (module.type === "UNSUPERVISED") {
        if (module.values.classification.ok.length > 0) {
          setDanger = true;
        }
      }

      if (setDanger) {
        dispatch(
          setStore(["modules", "items", { id }, "dangerSettings"], true)
        );
      }
    });
  };

export const setFilter =
  (filterId: string, settings: FilterSettings) => (dispatch: AppDispatch) => {
    const path = ["modules", "filter", { id: filterId }];
    const value = { ...settings, id: filterId };
    socket.emit("push_update_store", { path, value, updated: true });
    dispatch(databaseActions.update({ path, value }));
  };

export const confirmDangerSettings = (moduleId: number) => {
  socket.emit("set_store", {
    path: ["modules", "items", { id: moduleId }, "dangerSettings"],
    value: false,
  });
};

export const setVisitedModule = (moduleId: number) => {
  socket.emit("set_store", {
    path: ["modules", "items", { id: moduleId }, "visited"],
    value: true,
  });
};

export const moduleChangeShowHeatmap =
  ({ show, moduleId }: { show: boolean; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "showHeatmap"], show)
    );
  };

export const moduleChangeShowRectangles =
  ({ show, moduleId }: { show: boolean; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "showRectangles"], show)
    );
  };

export const login =
  (password: string) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(systemActions.invalidPassword(false));
    socket.emit("login", { password });
  };

export const checkToken = (token: string) => {
  socket.emit("check_token", { token });
};

export const changePaintRadius =
  ({ moduleId, paintRadius }: { moduleId: number; paintRadius: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "paintRadius"],
        paintRadius
      )
    );
  };

export const setModuleValue =
  ({
    moduleId,
    name,
    value,
    withEditDate = true,
    followReset = true,
  }: {
    moduleId: number;
    name: string;
    value: any;
    withEditDate?: boolean;
    followReset?: boolean;
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["modules", "items", { id: moduleId }, name], value));

    if (followReset) {
      dispatch(setFollowsToReset(moduleId));
    }
    if (withEditDate) {
      dispatch(
        setStore(["modules", "items", { id: moduleId }, "editDate"], Date.now())
      );
    }
  };

// Tutorial
// ------------------------------------

export const startTutorial =
  (notShowAgain: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    if (notShowAgain) {
      socket.emit("not_show_tutorial_again", {});
    }
    dispatch(systemActions.startTutorial());
    socket.emit("start_tutorial", {});
  };

export const closeTutorial =
  () => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("stop_tutorial", {});
    dispatch(systemActions.closeTutorial());
  };

export const closeTutorialDialog =
  (notShowAgain: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    if (notShowAgain) {
      socket.emit("set_offer_tutorial", { show: false });
    }
    dispatch(systemActions.closeTutorialDialog());
  };

export const setOfferTutorial =
  (show: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("set_offer_tutorial", { show });
    dispatch(systemActions.setOfferTutorial(show));
  };

export const changeParam =
  (name: string, modelId: number, value: any) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["unsupervisedModels", { modelId }, name], value));
  };

// Detector
// ------------------------------------

export const detectorUpdateEvaluation =
  ({ modelId, evaluation }: { modelId: number; evaluation: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setModelValue("detectorModels", modelId, "evaluation", evaluation)
    );
  };

export const detectorSetName =
  ({ modelId, value }: { modelId: number; value: string }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("detectorModels", modelId, "name", value));
  };

export const detectorEditConfig =
  (moduleId: number, config: any) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "config"],
        config
      )
    );
  };

export const detectorDeleteModel = (modelId: number) => {
  socket.emit("detector_delete_model", { modelId });
};

export const detectorSetLastRectangle =
  (moduleId: number, size: { width: number; height: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "lastRectangle"],
        size
      )
    );
  };

export const detectorEditAllImageRectangles =
  ({
    moduleId,
    rectangles,
  }: {
    moduleId: number;
    rectangles: {
      id: number;
      isEmpty: boolean;
      rectangles: Rectangle[];
    }[];
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "imageRectangles"],
        rectangles
      )
    );
  };

export const detectorSetRectangleClassName =
  ({
    moduleId,
    imageId,
    rectangleId,
    className,
  }: {
    moduleId: number;
    imageId: number;
    rectangleId: number;
    className: number | null;
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        [
          "modules",
          "items",
          { id: moduleId },
          "values",
          "imageRectangles",
          { id: imageId },
          "rectangles",
          { id: rectangleId },
          "className",
        ],
        className
      )
    );
  };

export const detectorEditImageRectangles =
  (
    moduleId: number,
    imageId: number,
    rectangles: Rectangle[],
    isEmpty = false
  ) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const path = [
      "modules",
      "items",
      { id: moduleId },
      "values",
      "imageRectangles",
      { id: imageId },
    ];
    const value = { rectangles, id: imageId, isEmpty };
    socket.emit("push_update_store", { path, value, updated: true });
    dispatch(databaseActions.update({ path, value }));
  };

export const detectorEditImageFixedRectangle =
  (moduleId: number, rectangle: Rectangle) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "fixRectangle"],
        rectangle
      )
    );
  };

export const detectorStartTraining = ({
  name,
  trainingParams,
  moduleId,
}: {
  name: string;
  trainingParams: any;
  moduleId: number;
}) => {
  socket.emit("detector_start_training", {
    name,
    trainingParams,
    moduleId,
  });
};

export const setModulesPreview =
  (active: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["modules", "showPreview"], active));
  };

export const detectorStopTraining =
  (modelId: number) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("detector_stop_training", {});
  };

export const detectorSetActiveModelId =
  ({ modelId, moduleId }: { modelId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "modelId"], modelId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const detectorSetClassNames =
  ({
    moduleId,
    classNames,
  }: {
    moduleId: number;
    classNames: { label: string; id: number }[];
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "classNames"],
        classNames
      )
    );
  };

export const getExtraModelData =
  ({ type, modelId }: { type: "detector"; modelId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const typeData = getState().system.extraModelData[type];
    if (!typeData || !typeData[modelId]) {
      socket.emit("get_extra_model_data", { type, modelId });
    }
  };

export const detectorSetThreshold =
  ({ modelId, value }: { modelId: number; value: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("detectorModels", modelId, "threshold", value));
  };

// Code
// ------------------------------------

export const codeSetSourceCode =
  ({ moduleId, fileId }: { moduleId: number; fileId: number | null }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "fileId"], fileId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const codeSaveSourceCode = ({
  moduleId,
  itemId,
  sourceCode,
}: {
  moduleId: number;
  itemId: number | null;
  sourceCode: string;
}) => {
  socket.emit("code_save_source_code", { moduleId, itemId, sourceCode });
};

export const codeCreate = ({
  moduleId,
  name,
}: {
  moduleId: number;
  name: string;
}) => {
  socket.emit("code_create", { moduleId, name });
};

export const codeUpdateName = ({
  itemId,
  name,
}: {
  itemId: number;
  name: string;
}) => {
  socket.emit("code_update_name", { itemId, name });
};

export const codeDelete = (itemId: number) => {
  socket.emit("code_delete", { itemId });
};

// Classifier
// ------------------------------------

export const classifierUpdateEvaluation =
  ({ modelId, evaluation }: { modelId: number; evaluation: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setModelValue("classifierModels", modelId, "evaluation", evaluation)
    );
  };

export const classifierSetName =
  ({ modelId, value }: { modelId: number; value: string }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("classifierModels", modelId, "name", value));
  };

export const editConfig =
  (moduleId: number, config: any) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "config"],
        config
      )
    );
  };

export const classifierEditAllImageRectangles =
  (
    moduleId: number,
    rectangles: ClassifierModule["values"]["imageRectangles"]
  ) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "imageRectangles"],
        rectangles
      )
    );
  };

export const classifierEditImageRectangles =
  (
    moduleId: number,
    imageId: number,
    rectangles: {
      width?: number;
      height?: number;
      y?: number;
      x?: number;
      classNames: [] | [number];
    }[]
  ) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const path = [
      "modules",
      "items",
      { id: moduleId },
      "values",
      "imageRectangles",
      { id: imageId },
    ];
    const value = { rectangles, id: imageId };
    socket.emit("push_update_store", { path, value, updated: true });
    dispatch(databaseActions.update({ path, value }));
  };

export const classifierResetImageRectangles =
  ({ moduleId }: { moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "imageRectangles"],
        []
      )
    );
  };

export const classifierSetImageRectangles =
  ({
    moduleId,
    imageRectangles,
  }: {
    moduleId: number;
    imageRectangles: ClassifierModule["values"]["imageRectangles"];
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "imageRectangles"],
        imageRectangles
      )
    );
  };

export const classifierDeleteModel = (modelId: number) => {
  socket.emit("classifier_delete_model", { modelId });
};

export const classifierStartTraining = ({
  name,
  trainingParams,
}: {
  name: string;
  trainingParams: any;
}) => {
  socket.emit("classifier_start_training", { name, trainingParams });
};

export const classifierStopTraining =
  (modelId: number) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("classifier_stop_training", {});
  };

export const classifierSetActiveModelId =
  ({ modelId, moduleId }: { modelId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "modelId"], modelId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const classifierSetClassNames =
  ({
    moduleId,
    classNames,
  }: {
    moduleId: number;
    classNames: ClassifierModule["values"]["classNames"];
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "classNames"],
        classNames
      )
    );
  };

export const updateFlow = ({
  items,
  sort,
}: {
  items: AnyModule[];
  sort: SortModules;
}) => {
  socket.emit("update_flow", { items, sort });
};

export const classifierAuto = ({
  moduleId,
  flow,
  patternItems,
  isRegex,
}: {
  moduleId: number;
  flow: Flow;
  patternItems: any;
  isRegex: boolean;
}) => {
  socket.emit("classifier_auto", { moduleId, flow, patternItems, isRegex });
};

// Supervised
// ------------------------------------

export const supervisedUpdateEvaluation =
  ({ modelId, evaluation }: { modelId: number; evaluation: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setModelValue("supervisedModels", modelId, "evaluation", evaluation)
    );
  };

export const supervisedSetImage = ({
  moduleId,
  imageId,
  base64image,
}: {
  moduleId: number;
  imageId: number;
  base64image: string;
}) => {
  socket.emit("supervised_set_image", { imageId, base64image, moduleId });
};

export const supervisedSetThreshold =
  ({ modelId, value }: { modelId: number; value: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("supervisedModels", modelId, "threshold", value));
  };

export const supervisedSetName =
  ({ modelId, value }: { modelId: number; value: string }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("supervised_set_name", { modelId, value });
    dispatch(setModelValue("supervisedModels", modelId, "name", value));
  };

export const supervisedClearImage = ({
  moduleId,
  imageId,
}: {
  moduleId: number;
  imageId: number;
}) => {
  socket.emit("supervised_clear_image", { moduleId, imageId });
};

export const supervisedEditConfig =
  ({ moduleId, config }: { moduleId: number; config: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "config"],
        config
      )
    );
  };

export const supervisedEditEmptyImages =
  ({ moduleId, emptyImages }: { moduleId: number; emptyImages: number[] }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "emptyImages"],
        emptyImages
      )
    );
  };

export const supervisedClearAnnotation = (moduleId: number) => {
  socket.emit("supervised_clear_annotation", { moduleId });
};

export const supervisedStartTraining = ({
  moduleId,
  flow,
  name,
  modelId,
  type,
}: {
  moduleId: number;
  flow: Flow;
  name: string;
  modelId: number | null;
  type: "universal" | "objects" | "scratches";
}) => {
  socket.emit("supervised_start_training", {
    moduleId,
    flow,
    name,
    modelId,
    type,
  });
};

export const supervisedStopTraining =
  (modelId: number) => (dispatch: AppDispatch, getState: GetState) => {
    // dispatch(systemActions.classifierStopTraining(modelId))
    socket.emit("supervised_stop_training", {});
  };

export const supervisedSetActiveModelId =
  ({ modelId, moduleId }: { modelId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "modelId"], modelId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const supervisedDeleteModel = (modelId: number) => {
  socket.emit("supervised_delete_model", { modelId });
};

export const supervisedSetClassNameItems = ({
  moduleId,
  classNames,
}: {
  moduleId: number;
  classNames: { id: number; label: string }[];
}) => {
  socket.emit("supervised_set_class_names_items", { moduleId, classNames });
};

// Unsupervised
// ------------------------------------

export const unsupervisedSetName =
  ({ modelId, value }: { modelId: number; value: string }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("unsupervisedModels", modelId, "name", value));
  };

export const anomalySetImages =
  ({ moduleId, ok, nok }: { moduleId: number; ok: number[]; nok: number[] }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "classification"],
        { ok, nok }
      )
    );
  };
export const unsupervisedSetEvaluation =
  ({ modelId, enable }: { modelId: number; enable: boolean }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setModelValue("unsupervisedModels", modelId, "evaluation", enable)
    );
  };

export const anomalyClearAnnotations =
  (moduleId: number) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "classification"],
        { ok: [], nok: [] }
      )
    );
  };

export const anomalyEditConfig =
  ({ moduleId, config }: { moduleId: number; config: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "config"],
        config
      )
    );
  };

export const unsupervisedStartTraining = ({
  moduleId,
  flow,
  name,
  faster,
  okIds,
}: {
  moduleId: number;
  flow: Flow;
  name: string;
  faster: boolean;
  okIds: number[];
}) => {
  socket.emit("unsupervised_start_training", {
    moduleId,
    flow,
    name,
    faster,
    okIds,
  });
};

export const unsupervisedStartSensitivityAnalyze =
  ({
    moduleId,
    flow,
    classification,
  }: {
    moduleId: number;
    flow: Flow;
    classification: { ok: number[]; nok: number[] };
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("unsupervised_start_sensitivity_analyze", {
      moduleId,
      flow,
      classification,
    });
  };

export const unsupervisedStopTraining =
  (modelId: number) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("unsupervised_stop_training", { modelId });
  };

export const unsupervisedDeleteModel = (modelId: number) => {
  socket.emit("unsupervised_delete_model", { modelId });
};

export const unsupervisedSetActiveModelId =
  ({ modelId, moduleId }: { modelId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "modelId"], modelId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const unsupervisedSeSensitivityClassification =
  ({ modelId, classification }: { modelId: number; classification: any }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setModelValue(
        "unsupervisedModels",
        modelId,
        "sensitivityClassification",
        classification
      )
    );
  };

export const unsupervisedAuto =
  ({ moduleId, ok, nok }: { moduleId: number; ok: number[]; nok: number[] }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "values", "classification"],
        { ok, nok }
      )
    );
  };

export const unsupervisedSetThreshold =
  ({ modelId, value }: { modelId: number; value: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("unsupervisedModels", modelId, "threshold", value));
  };

// Mask
// ------------------------------------

export const maskSetImage = (
  itemId: number,
  moduleId: number,
  base64image: string
) => {
  socket.emit("mask_set_image", { itemId, moduleId, base64image });
};

export const maskCreate = ({
  moduleId,
  name,
}: {
  moduleId: number;
  name: string;
}) => {
  socket.emit("mask_create", { moduleId, name });
};

export const maskUpdateName = ({
  name,
  itemId,
}: {
  name: string;
  itemId: number;
}) => {
  socket.emit("mask_update_name", { name, itemId });
};

export const maskSetToHeatmap = ({
  heatmap,
  itemId,
}: {
  heatmap: boolean;
  itemId: number;
}) => {
  socket.emit("mask_set_to_heatmap", { heatmap, itemId });
};

export const maskSetImageId =
  ({ itemId, moduleId }: { itemId: number | null; moduleId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "itemId"], itemId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const maskDelete = (itemId: number) => {
  socket.emit("mask_delete", { itemId });
};

// UNIFIER
// ------------------------------------

export const unifierCreate = ({
  moduleId,
  name,
  imageId,
  flow,
}: {
  moduleId: number;
  name: string;
  imageId: number;
  flow: Flow;
}) => {
  socket.emit("unifier_create", { moduleId, name, imageId, flow });
};

export const unifierUpdateValues = ({
  itemId,
  values,
}: {
  itemId: number;
  values: any;
}) => {
  socket.emit("unifier_update_values", { itemId, values });
};

export const unifierSetActiveUnifierId =
  ({ moduleId, itemId }: { moduleId: number; itemId: number | null }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "itemId"], itemId)
    );
    dispatch(setFollowsToReset(moduleId));
  };

export const unifierUpdateName =
  ({ name, itemId }: { name: string; itemId: number }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setModelValue("unifierItems", itemId, "name", name));
  };

export const unifierDelete = (itemId: number) => {
  socket.emit("unifier_delete", { itemId });
};

// OUTPUT
// ------------------------------------

export const setOutputItems =
  (outputItems: any[]) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["outputItems"], outputItems));
  };

export const setMXIAOutputItems = (id: number, active: boolean) => {
  socket.emit("set_mxai_output", { id, active });
};

// IMAGES
// ------------------------------------

export const imagesUpload =
  (images: File[] | FileList) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const currentImages = getState().database.images;
    const imageArray = Array.from(images).map((file) => {
      // @ts-ignore:next-line
      file.fullName = file.webkitRelativePath
        ? file.webkitRelativePath.substr(
            file.webkitRelativePath.indexOf("/") + 1
          )
        : file.name;
      return file;
    });
    const imageNameArray = imageArray.map((i, index) => ({
      fullName: (i as any).fullName as string,
      index,
    }));
    const nameCollisionImages = imageNameArray.filter((i) =>
      // @ts-ignore:next-line
      currentImages.find((k) => k.label === i.fullName)
    );
    setSelectedFiles(imageArray as FileWithPath[]);
    dispatch(systemActions.selectUploadImages(imageNameArray));
    if (nameCollisionImages.length > 0) {
      dispatch(systemActions.setCollisionImagesName(nameCollisionImages));
    }
  };

export const imagesUploadKeepBoth =
  () => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(systemActions.setCollisionImagesName([]));
  };

export const imagesUploadKeepOld =
  () => (dispatch: AppDispatch, getState: GetState) => {
    const { uploadImagesCollisions, uploadImages } = getState().system;

    if (!uploadImages) {
      return;
    }

    const filteredSelectedFiles = selectedFiles.filter(
      (i) => !uploadImagesCollisions.find((k) => k.fullName === i.fullName)
    );

    setSelectedFiles(filteredSelectedFiles);
    if (filteredSelectedFiles.length === 0) {
      dispatch(systemActions.selectUploadImages(null));
    }
    dispatch(systemActions.setCollisionImagesName([]));
  };

export const imagesUploadConfirm =
  (
    prefix: string,
    tagsIds: number[],
    resize: { width: number; height: number } | null
  ) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(systemActions.loadingStart(true));
    const uploadImages = getState().system.uploadImages;

    if (!uploadImages || uploadImages.length === 0) {
      dispatch(systemActions.selectUploadImages(null));
      dispatch(systemActions.loadingStop());
      dispatch(systemActions.setLoadingProgress(null));
      return;
    }

    ImageService.upload(
      socket.id,
      prefix,
      tagsIds,
      resize,
      getState().system.token,
      (value) => dispatch(systemActions.setLoadingProgress(value))
    )
      .then(() => {
        dispatch(systemActions.loadingStop());
        dispatch(systemActions.setLoadingProgress(null));
      })
      .catch((e) => {
        console.log(e);
        dispatch(systemActions.loadingStop());
        dispatch(systemActions.setLoadingProgress(null));
        // eslint-disable-next-line no-alert
        alert("Error");
      });
    dispatch(systemActions.selectUploadImages(null));
  };

export const setTags =
  (tags: Tag[]) => (dispatch: AppDispatch, getState: GetState) => {
    const removedIDs = (getState().database.tags || [])
      .filter((i) => !tags.find((k) => k.id === i.id))
      .map((i) => i.id);
    if (removedIDs.length > 0) {
      // remove tags from images
      const nextImages = getState().database.images.map((i) => ({
        ...i,
        tags: (i.tags || []).filter((k) => !removedIDs.includes(k)),
      }));
      dispatch(setStore(["images"], nextImages));
      // remove tags from filter
      const nextFilters = getState().database.modules.filter.map((filter) => ({
        ...filter,
        tags: (filter.tags || []).filter((i) => !removedIDs.includes(i)) || [],
      }));
      dispatch(setStore(["modules", "filter"], nextFilters));
    }
    dispatch(setStore(["tags"], tags));
  };

export const updateTags =
  (addTagIds: number[], removeTagIds: number[]) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const images = getState().database.images;
    const imageIds = getState().images.tagsImages || [];

    const getNextTags = (image: Image) => {
      if (!image.tags) {
        return addTagIds;
      } else {
        return Array.from(
          new Set([
            ...image.tags.filter((t) => !removeTagIds.includes(t)),
            ...addTagIds,
          ])
        );
      }
    };

    dispatch(imagesActions.setTagsImages({ images: [] }));
    if (imageIds.length === 1) {
      const image = images.find((i) => i.id === imageIds[0]);
      if (image) {
        dispatch(
          setStore(["images", { id: imageIds[0] }], {
            ...image,
            tags: getNextTags(image),
          })
        );
      }
    } else {
      const nextImages = images.map((i) =>
        imageIds.includes(i.id) ? { ...i, tags: getNextTags(i) } : i
      );
      dispatch(setStore(["images"], nextImages));
    }
  };

export const imageUploadCancel =
  () => (dispatch: AppDispatch, getState: GetState) => {
    setSelectedFiles([]);
    dispatch(systemActions.selectUploadImages(null));
  };

export const imagesDelete =
  (imagesIds: number[]) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("images_delete", { images: imagesIds });
  };

export const imageUpdateName =
  ({ imageId, name }: { imageId: number; name: string }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["images", { id: imageId }, "label"], name));
  };

export const captureCamera = () => {
  socket.emit("capture_camera", {});
};

export const turnOnCamera =
  () => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "cameraIsRunning"], true));
  };

export const setProvider =
  (provider: string) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "provider"], provider));
  };

export const enableCustomOperatorView =
  (enable: boolean) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "customOperator", "enable"], enable));
  };

export const setCustomOperatorViewItems =
  (items: any[]) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "customOperator", "items"], items));
  };

export const changeOperatorInput =
  (values: any) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["operatorInput"], values));
  };

export const resetCounter =
  (counterId: number) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("reset_counter", { counterId });
  };

export const turnOffCamera =
  () => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "cameraIsRunning"], false));
  };

export const cameraSetConfigFile =
  (content: string, fileName: string) =>
  (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("camera_set_config_file", { content, fileName });
  };

export const addTutorialImages =
  () => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("image_upload_tutorial", {});
  };

export const cameraAutoAdjust =
  () => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("camera_auto_adjust", {});
  };

export const cameraSetInputType =
  (type: string) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "imageInput"], type));
  };

export const setCurrentCamera =
  (id: CameraT | null) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["camera", "currentCamera"], id));
  };

// OCR
// ------------------------------------
export const ocrStartTraining =
  (name: string, prevModel: number | null, moduleId: number, flow: Flow) =>
  (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("ocr_start_training", { name, moduleId, prevModel, flow });
  };

export const ocrStopTraining =
  (modelId: number) => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("ocr_stop_training", {});
  };

export const ocrDeleteModel = (modelId: number) => {
  socket.emit("ocr_delete_model", { modelId });
};

export const ocrSetImageRectangles =
  ({
    moduleId,
    imageId,
    rectangles,
  }: {
    moduleId: number;
    imageId: number;
    rectangles: Rectangle[];
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(
        ["modules", "items", { id: moduleId }, "imageRectangles", imageId],
        rectangles
      )
    );
  };

export const ocrStartThresholdCalc =
  ({
    moduleId,
    modelId,
    flow,
    classification,
  }: {
    moduleId: number;
    modelId: number;
    flow: Flow;
    classification: AutoThreholdAnnotations;
  }) =>
  (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("ocr_start_threhsold_calc", {
      moduleId,
      modelId,
      flow,
      classification,
    });
  };

// Statistics
// ------------------------------------
export const setStatisticsAnnotations =
  (values: any) => (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["statistics", "annotations"], values));
  };

export const setStatisticsType =
  (type: "evaluation" | "simple") =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setStore(["statistics", "type"], type));
  };

export const startStatistics =
  () => (dispatch: AppDispatch, getState: GetState) => {
    socket.emit("start_statistics", {}, (statistics: Statistics) => {
      dispatch(systemActions.receiveStatistics(statistics));
    });
  };

export const downloadFile =
  (url: string, name: string) =>
  (dispatch: AppDispatch, getState: GetState) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

// GPU settings
// ------------------------------------
export const setGpuSettings =
  (moduleId: number, value: any) =>
  (dispatch: AppDispatch, getState: GetState) => {
    dispatch(
      setStore(["modules", "items", { id: moduleId }, "gpuSettings"], value)
    );
  };
