import React, { useLayoutEffect, useRef } from "react";
import CameraIcon from "@mui/icons-material/CameraAlt";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Module from "./Module";
import EmptyModule from "./EmtyModule";
import type * as Flow from "../../types/flow";
import { Modules } from "../../modules";

// Sorry :/
const getColl = (items: (Flow.Any | Flow.AnyDebugger)[]): number =>
  Math.max(
    ...[
      ...items.map((i) =>
        i.type === "PARALLELISM"
          ? i.branches.map(getColl).reduce((a, b) => a + b, 0)
          : 1
      ),
      1,
    ]
  );

const Arrow = ({
  items,
  down,
}: {
  items: Flow.Parallelism;
  down?: boolean;
}) => {
  let colls = getColl([items]);
  colls = colls === -Infinity ? 2 : colls;
  let prevColls = 0;
  return (
    <svg
      height="20"
      width="100%"
      style={{
        overflow: "visible",
        width: 240 * colls,
        ...(down
          ? { position: "absolute", bottom: 0 }
          : { position: "absolute", top: 0 }),
      }}
    >
      {items.branches.map((i, index) => {
        let currentColls = getColl(i);
        currentColls = currentColls === -Infinity ? 1 : currentColls;
        const x2 = ((currentColls / 2 + prevColls) / colls) * 100;
        prevColls += currentColls;
        return (
          <line
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            x1="50%"
            y1={down ? 20 : "0"}
            x2={`${x2}%`}
            y2={down ? 0 : "20"}
            style={{ stroke: "#999", strokeWidth: 2 }}
          />
        );
      })}
    </svg>
  );
};

const builderStyles = {
  cameraWrap: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
    marginTop: -4,
    position: "relative",
    marginBottom: 18,
  },
  cameraBg: {
    background: "white",
    width: 60,
    height: 44,
    position: "absolute",
    top: 0,
  },
  exitBg: {
    background: "white",
    width: 60,
    height: 57,
    position: "absolute",
    top: 3,
  },
  icon: {
    fill: "currentcolor",
    width: 50,
    height: 50,
    position: "relative",
    color: "rgb(153, 153, 153)",
  },
  arrowDownWrap: {
    position: "absolute",
    top: 7,
  },
} as const;

type Props<T> = {
  items: T[];
  onSelect: (item: T) => void;
  isModuleActive: (itemId: number, moduleType: Modules) => boolean;
  path: any[];
  isFirst?: boolean;
  hideImages?: boolean;
};

function Builder<T extends Flow.Any | Flow.AnyDebugger>(props: Props<T>) {
  const wrapRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (props.isFirst && wrapRef.current?.parentElement?.parentElement) {
      wrapRef.current.parentElement.parentElement.scrollTo(
        (wrapRef.current.offsetWidth -
          wrapRef.current.parentElement.parentElement.offsetWidth) /
          2,
        0
      );
    }
  }, [props.isFirst]);

  const { items, path, onSelect, isFirst, isModuleActive } = props;
  return (
    <div
      ref={wrapRef}
      style={{
        flexDirection: "column",
        display: "inline-flex",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#999",
          border: "1px solid white",
          borderWidth: "0 1px",
          width: 4,
          height: "calc(100% - 5px)",
          left: "calc(50% - 2px)",
        }}
      />
      {isFirst && (
        <div style={builderStyles.cameraWrap}>
          <div style={builderStyles.cameraBg} />
          <CameraIcon style={builderStyles.icon} />
        </div>
      )}
      {items.map((item, index) => {
        if (item.type === "PARALLELISM") {
          return (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 33,
                marginBottom: 20,
                position: "relative",
                padding: "20px 0px",
                backgroundColor: "white",
              }}
            >
              <div style={{ display: "flex" }}>
                <Arrow items={item} />

                {item.branches.map((i, indexBranch) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={indexBranch} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "#999",
                        border: "1px solid white",
                        borderWidth: "0 1px",
                        width: 4,
                        height: "calc(100%)",
                        left: "calc(50% - 2px)",
                      }}
                    />
                    <Builder
                      onSelect={onSelect}
                      path={[...path, index, indexBranch]}
                      items={i as T[]}
                      hideImages={props.hideImages}
                      isModuleActive={isModuleActive}
                    />
                  </div>
                ))}
                <Arrow items={item} down />
              </div>
            </div>
          );
        }
        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 5,
              position: "relative",
            }}
          >
            <div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#999",
                  border: "1px solid white",
                  borderWidth: "0 1px",
                  width: 4,
                  height: "calc(100% - 5px)",
                  left: "calc(50% - 2px)",
                }}
              />
              <Module
                hideImages={props.hideImages}
                active={isModuleActive(item.id, item.type)}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onSelect(item);
                }}
                config={item}
                label={item.label}
                note={item.note}
                type={item.type}
              />
            </div>
          </div>
        );
      })}
      {items.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 5,
            position: "relative",
          }}
        >
          <div>
            <div
              style={{
                position: "absolute",
                backgroundColor: "#999",
                border: "1px solid white",
                borderWidth: "0 1px",
                width: 4,
                height: "calc(100% - 5px)",
                left: "calc(50% - 2px)",
              }}
            />
            <EmptyModule />
          </div>
        </div>
      )}
      {isFirst && (
        <div
          style={{
            ...builderStyles.cameraWrap,
            marginTop: 13,
            marginBottom: 0,
          }}
        >
          <div style={builderStyles.exitBg} />
          <div style={{ ...builderStyles.arrowDownWrap, top: -14 }}>
            <ArrowDownIcon style={{ color: "rgb(153, 153, 153)" }} />
          </div>
          <svg
            className="icon icon-exit"
            style={{ left: 7, ...builderStyles.icon }}
            viewBox="0 0 32 32"
          >
            <path d="M24 20v-4h-10v-4h10v-4l6 6zM22 18v8h-10v6l-12-6v-26h22v10h-2v-8h-16l8 4v18h8v-6z" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default Builder;
