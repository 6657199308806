import React, { Component } from "react";
import RefreshIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { SERVER_IMAGE_URL } from "../../../config";
import {
  confirmDangerSettings,
  setVisitedModule,
  supervisedClearAnnotation,
  supervisedClearImage,
  supervisedSetClassNameItems,
  supervisedSetImage,
  supervisedStartTraining,
} from "../../../actions/actions";
import ImageListContainer from "../../../components/ImageList/ImageListContainer";
import PaintCanvas from "../../../Containers/PaintCanvas/PaintCanvasContainer";
import TrainingDialog from "../../../components/Augmentation/TrainingDialog";
import PatchSize from "../../../components/PatchSize";
import {
  CON_MIN_HEIGHT_HEADER,
  PANEL_WIDTH,
  PRIMARY_COLOR,
} from "../../../theme";
import DangerSettingsDialog from "../../../components/DangerSettingsDialog";
import ClassNameList from "../../../components/ClassNameList";
import ClassNamesManagerDialog from "../../../components/ClassNamesManagerDialog";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import TrainingButton from "../../../components/TrainingButton";

import { PropsFromRedux } from "../TrainingContainer";
import { Image } from "../../../types/common";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import ButtonWithConfirm from "../../../components/ButtonWithConfirm";

const objectsDefaults = {
  PATCH_SIZE: 64,
  COLOR_JITTERING: 0,
  NETWORK_SIZE: "balance",
  TYPE: "objects",
  ROTATE: true,
};

const universalDefaults = {
  BRIGHTNESS: 20,
  CONTRAST: 20,
  SATURATION: 20,
  TYPE: "universal",
  EPOCH: 50,
};

const styles = {
  patchExample: {
    position: "absolute",
    right: 20,
    top: 20,
    border: `solid 2px ${PRIMARY_COLOR}`,
    borderRadius: 4,
    pointerEvents: "none",
  },
  okCheckbox: {
    marginBottom: 8,
  },
  content: {
    width: `calc(100% - ${PANEL_WIDTH}px)`,
    height: "100%",
  },
  contentWrap: {
    flexFlow: "row",
    justifyContent: "space-around",
    display: "flex",
    alignItems: "stretch",
    height: "100%",
  },
  annotaionActions: {
    marginTop: 8,
  },
} as const;

type Props = PropsFromRedux & { t: (key: string) => string };

type State = {
  showTrainingDialog: boolean;
  currentImage: null | Image;
  patchSize: number;
  currentClassificationItemId: null | number;
  cache: number;
  showClassNamesDialog: boolean;
  mask: null | string;
};

class TrainingView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    showTrainingDialog: false,
    currentImage: null,
    patchSize: 0,
    cache: Date.now(),
    showClassNamesDialog: false,
    mask: null,
    currentClassificationItemId: null,
  };

  clearCanvas: (() => void) | null = null;

  componentDidMount() {
    this.setState({
      patchSize: this.props.module.values.config.PATCH_SIZE,
      currentClassificationItemId: this.props.module.values.classNames[0].id,
    });
    if (!this.props.module.visited) {
      setVisitedModule(this.props.module.id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const currentClassificationItemId =
      this.state.currentClassificationItemId ||
      this.props.module.values.classNames[0].id;

    if (this.props.module.values.config.TYPE === "universal") {
      if (currentClassificationItemId === 10000) {
        // unselect mask for universal
        this.handleChangeClassName(this.props.module.values.classNames[0].id);
      }
    }

    const type = this.props.module.values.config.TYPE;

    if (
      !this.props.module.values.classNames.find(
        (i) => i.id === currentClassificationItemId
      ) &&
      !(currentClassificationItemId === 10000 && type === "objects")
    ) {
      // unselect not exist class
      this.handleChangeClassName(this.props.module.values.classNames[0].id);
    }
  }

  handleTouchTapSetting = () => {};

  handleChangeClassName = (id: number | null) => {
    if (this.state.showTrainingDialog || id === null) {
      return;
    }

    const item = this.getAllClassNames().find((i) => i.id === id);
    if (item) {
      this.setState({
        currentClassificationItemId: item.id,
      });
    }
  };

  getCurrentClassificationItem = () => {
    return this.getAllClassNames().find(
      (i) => this.state.currentClassificationItemId === i.id
    );
  };

  getImages = () => {
    const { masks, emptyImages } = this.props.module.values;
    const markedItems = [...masks, ...emptyImages];
    return this.props.images.map((item) => {
      return {
        ...item,
        isMarked: markedItems.indexOf(item.id) !== -1,
      };
    });
  };

  getAllClassNames = () =>
    this.props.module.values.config.TYPE === "universal"
      ? this.props.module.values.classNames
      : [
          {
            color: "#0000ff",
            color_bgr: [0, 0, 255] as [number, number, number],
            id: 10000,
            label: this.props.t("ignore"),
          },
          ...this.props.module.values.classNames,
        ];

  getImageSrc = (imageId: number) =>
    this.context.helper.getImagePath({
      imageId,
      moduleId: this.props.module.id,
    });

  render() {
    const currentClassificationItem = this.getCurrentClassificationItem();
    const _ = this.props.t;
    const imageId = this.state.currentImage && this.state.currentImage.id;
    const moduleId = this.props.module.id;
    const type = this.props.module.values.config.TYPE;
    const universalType = type === "universal";
    const {
      masks,
      config,
      emptyImages,
      paintRadius,
      classNames = [],
    } = this.props.module.values;
    const hasMask = masks.indexOf(imageId) !== -1;

    const maskSrc = hasMask
      ? `${SERVER_IMAGE_URL}/supervised_mask/${moduleId}/${imageId}.png?token=${this.props.token}&c=${this.state.cache}`
      : undefined;

    return (
      <div>
        <ImageListContainer
          showSelectedFilter
          disabledKeyListener={this.state.showTrainingDialog}
          height={CON_MIN_HEIGHT_HEADER}
          activeId={imageId || undefined}
          images={this.getImages()}
          onSelect={(currentImage) =>
            this.setState({ currentImage, mask: null, cache: Date.now() })
          }
          getImageSrc={(image) => ({ imageId: image.id, moduleId })}
          getInfo={({ items, filteredItems }) => ({
            title: _("image_list_info_help_supervised"),
            text: `${masks.length + emptyImages.length} / ${
              filteredItems.length
            } / ${items.length}`,
          })}
        >
          <div style={styles.contentWrap}>
            <div style={styles.content}>
              {this.state.currentImage && (
                <PaintCanvas
                  disabledKeyListener={this.state.showTrainingDialog}
                  label={this.state.currentImage.label}
                  onGetClear={(clearCanvas) => {
                    this.clearCanvas = clearCanvas;
                  }}
                  showClassificationHelp
                  key={
                    this.getImageSrc(this.state.currentImage.id) +
                    classNames.map((i) => i.id).join(",")
                  }
                  imgSrc={this.getImageSrc(this.state.currentImage.id)}
                  maskSrc={this.state.mask || maskSrc}
                  color={
                    currentClassificationItem
                      ? currentClassificationItem.color
                      : "red"
                  }
                  radius={paintRadius || 10}
                  onChangeRadius={(paintRadius) =>
                    this.props.changePaintRadius({ moduleId, paintRadius })
                  }
                  onChange={(canvas) => {
                    if (!imageId) {
                      return;
                    }
                    this.setState({ mask: canvas });
                    supervisedSetImage({
                      moduleId,
                      imageId,
                      base64image: canvas,
                    });
                  }}
                  onClear={() =>
                    imageId && supervisedClearImage({ imageId, moduleId })
                  }
                >
                  {({ scale }) =>
                    !universalType ? (
                      <div
                        style={{
                          width: this.state.patchSize * scale,
                          height: this.state.patchSize * scale,
                          ...styles.patchExample,
                        }}
                      />
                    ) : null
                  }
                </PaintCanvas>
              )}
            </div>
            <ModuleRightToolPanel>
              <ModuleSettingsWrap title={_("settings")}>
                {!universalType && (
                  <PatchSize
                    onlySlider
                    min={32}
                    step={32}
                    max={96}
                    value={this.state.patchSize}
                    onConfirm={() =>
                      this.props.supervisedEditConfig({
                        moduleId,
                        config: {
                          ...config,
                          PATCH_SIZE: Math.round(this.state.patchSize),
                        },
                      })
                    }
                    onChange={(patchSize) => this.setState({ patchSize })}
                  />
                )}
                <RadioGroup
                  value={universalType ? "universal" : "objects"}
                  onChange={(e) => {
                    if (e.target.value === "objects") {
                      this.props.editConfig(moduleId, objectsDefaults);
                      this.setState({
                        patchSize: objectsDefaults.PATCH_SIZE,
                      });
                    } else {
                      if (this.state.currentClassificationItemId === 1000) {
                        this.setState({
                          currentClassificationItemId:
                            this.props.module.values.classNames[0].id,
                        });
                      }
                      this.props.editConfig(moduleId, universalDefaults);
                    }
                  }}
                >
                  <FormControlLabel
                    value="universal"
                    control={<Radio />}
                    label={_("standart")}
                  />
                  <FormControlLabel
                    value="objects"
                    control={<Radio />}
                    label={_("lightweight")}
                  />
                </RadioGroup>
              </ModuleSettingsWrap>
              <ModuleSettingsWrap title={_("annotations")}>
                {imageId && (
                  <FormControlLabel
                    style={styles.okCheckbox}
                    control={
                      <Checkbox
                        disabled={hasMask}
                        checked={emptyImages.indexOf(imageId) !== -1 || hasMask}
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.props.supervisedEditEmptyImages({
                              moduleId,
                              emptyImages: [...emptyImages, imageId],
                            });
                          } else {
                            this.props.supervisedEditEmptyImages({
                              moduleId,
                              emptyImages: emptyImages.filter(
                                (id) => id !== imageId
                              ),
                            });
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={_("supervised_is_empty")}
                  />
                )}
                <ClassNameList
                  t={_}
                  disabledEmpty
                  items={this.getAllClassNames()}
                  value={this.state.currentClassificationItemId ?? undefined}
                  isActive
                  onChange={this.handleChangeClassName}
                  onOpenEditClassNames={() =>
                    this.setState({ showClassNamesDialog: true })
                  }
                />
                <ClassNamesManagerDialog
                  items={classNames}
                  min={1}
                  max={5}
                  onClose={() => this.setState({ showClassNamesDialog: false })}
                  onUpdate={(classNames) => {
                    const activeClassNameId =
                      this.state.currentClassificationItemId;
                    if (activeClassNameId) {
                      const activeItem = classNames.some(
                        (i) => i.id === activeClassNameId
                      );
                      if (!activeItem) {
                        this.setState({
                          currentClassificationItemId: classNames[0].id,
                        });
                      }
                    }
                    supervisedSetClassNameItems({
                      moduleId,
                      classNames,
                    });
                  }}
                  open={this.state.showClassNamesDialog}
                />
                <ButtonWithConfirm
                  ButtonProps={{
                    fullWidth: true,
                    color: "secondary",
                    startIcon: <RefreshIcon />,
                    variant: "outlined",
                    style: styles.annotaionActions,
                    size: "small",
                  }}
                  confirmMessage={_("remove_selected_confirm")}
                  onClickConfirm={() => {
                    supervisedClearAnnotation(this.props.module.id);
                    this.clearCanvas?.();
                  }}
                  buttonLabel={_("remove_annotations")}
                />
              </ModuleSettingsWrap>
              {!this.props.trainingDisabled && (
                <ModuleSettingsWrap>
                  <TrainingButton
                    onClick={() => {
                      this.setState({ showTrainingDialog: true });
                    }}
                  />
                </ModuleSettingsWrap>
              )}
            </ModuleRightToolPanel>
          </div>
        </ImageListContainer>
        <TrainingDialog
          modelsCounter={this.props.modelsCounter}
          open={this.state.showTrainingDialog}
          onClose={() => this.setState({ showTrainingDialog: false })}
          models={this.props.models}
          config={config}
          allowColorJittering={!universalType}
          hiddenNetworkSize
          allowBrightnessResistance={universalType}
          allowSaturationResistance={universalType}
          allowContrastResistance={universalType}
          //allowEpochSize={universalType}
          allowRotate={!universalType}
          hiddenExtendModel
          onChangeConfig={(config) =>
            this.props.supervisedEditConfig({ moduleId, config })
          }
          onStartTraining={(name, modelId) => {
            supervisedStartTraining({
              moduleId,
              flow: this.context.helper.getSettings({ moduleId }),
              name,
              modelId,
              type,
            });
          }}
          errorMessage={masks.length === 0 ? _("training_error_1") : undefined}
        />
        <DangerSettingsDialog
          onClose={() => {
            confirmDangerSettings(this.props.module.id);
          }}
          onRestart={() => {
            supervisedClearAnnotation(this.props.module.id);
          }}
          open={!!this.props.dangerSettings}
        />
      </div>
    );
  }
}

export default withI18n(TrainingView);
