import PaintCanvasView, { Props } from "./components/PaintCanvasView";
import { set as setSettings } from "../../store/paint/reducer";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import React from "react";
import { Values } from "./components/ToolPanel";



const PaintCanvasContainer = (props: Omit<Props, 'settings' | 'setSettings'>) => {
  const state = useAppSelector(state => ({
    settings: state.paint.settings[window.location.pathname],
  }))
  const dispatch = useAnyAppDispatch()

  return (
    <PaintCanvasView
      {...props}
      settings={state.settings}
      setSettings={(config: Values) => dispatch(setSettings({key: window.location.pathname, settings: config}))}
    />
  )
}

export default PaintCanvasContainer