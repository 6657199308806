import React, { FC, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useI18n from "../hooks/useI18n";
import { Grid, Typography } from "@mui/material";
import TagsPicker from "./TagsPicker";
import styled from "styled-components";

const Content = styled(DialogContent)`
  max-width: 400px;
`;

type Classname = { id: number | string; label: string };

type Props = {
  classNames: Classname[];
  open: boolean;
  onClose: () => void;
  onSet: (
    values: { className: Classname; pattern: string | null; tags: number[] }[],
    isRegex: boolean
  ) => void;
};

const AutoClassifierDialog: FC<Props> = (props) => {
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const [tags, setTags] = useState<{ [key: string]: number[] }>({});
  const [isRegex, setIsRegex] = useState(false);
  const [_] = useI18n();

  const handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onClose();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const regex = props.classNames.map((item) => {
      const value = values[item.id];
      const r = value && value.length > 0 ? value : null;
      return {
        className: item,
        pattern: r,
        tags: tags[item.id] || [],
      };
    });
    props.onSet(regex, isRegex);
  };

  return (
    <Dialog open={props.open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{_("smart_sorting")}</DialogTitle>
        <Content>
          <Grid container spacing={1}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRegex}
                    onChange={(e) => setIsRegex(e.target.checked)}
                  />
                }
                label="Regex"
              />
            </Grid>
            {props.classNames.map((item) => (
              <Grid item xs={12} key={item.id}>
                <Typography>{item.label}</Typography>
                <TextField
                  fullWidth
                  key={item.id}
                  size="small"
                  variant="outlined"
                  margin="dense"
                  placeholder={isRegex ? "Regex" : "Prefix"}
                  value={values[item.id] || ""}
                  onChange={(e) => {
                    e.stopPropagation();
                    const { value } = e.target;
                    setValues(() => ({ ...values, [item.id]: value }));
                  }}
                  onKeyPress={(e) => e.stopPropagation()}
                />
                <TagsPicker
                  disabledIds={[]}
                  values={tags[item.id] || []}
                  onChange={(ids) =>
                    setTags((tags) => ({ ...tags, [item.id]: ids }))
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Content>
        <DialogActions>
          <Button color="primary" onClick={handleClickClose}>
            {_("close")}
          </Button>
          <Button color="primary" type="submit">
            {_("set")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AutoClassifierDialog;
