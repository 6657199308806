import React, { FC, useEffect, useState } from "react";
import ClassNameList from "./ClassNameList";
import AutoClassifierDialog from "./AutoClassifierDialog";
import { imagesAutoClassification } from "../utils/common";
import { Image } from "../types/common";
import { useAnyAppDispatch } from "../hooks/store";
import { addErrorMessage } from "../store/system/reducer";
import useI18n from "../hooks/useI18n";

type Values = { [key: string]: number[] };

const getCurrentClassEvaluation = ({
  annotations,
  currentImage,
  selectedIds,
  items,
}: {
  annotations: Values;
  currentImage?: Image;
  selectedIds: number[];
  items: { id: string; label: string }[];
}) => {
  const imageId = currentImage && currentImage.id;

  if (!imageId) {
    return undefined;
  }

  const ids = [...selectedIds, imageId];

  const oneClass = items.find(
    (k) =>
      ids.filter((i) => (annotations[k.id] || []).indexOf(i) !== -1).length ===
      ids.length
  );

  if (oneClass) {
    return oneClass.id;
  }

  if (
    items.filter((i) =>
      (annotations[i.id] || []).find((k) => ids.indexOf(k) !== -1)
    ).length === 0
  ) {
    // all is None
    return null;
  }
  // is different
  return undefined;
};

type Props = {
  onChange: (values: Values) => void;
  items: { id: string; label: string }[];
  disabledKeyListener: boolean;
  currentImage?: Image;
  selectedIds: number[];
  images: Image[];
  values: Values;
  onChangeShowAutoClassifier?: (show: boolean) => void;
};

const ClassNameListAll: FC<Props> = (props) => {
  const {
    disabledKeyListener,
    items,
    currentImage,
    selectedIds,
    onChange,
    values,
    images,
    onChangeShowAutoClassifier,
  } = props;
  const [_] = useI18n();
  const dispatch = useAnyAppDispatch();
  const [showAutoClassifier, setShowAutoClassifier] = useState(false);

  useEffect(() => {
    onChangeShowAutoClassifier?.(showAutoClassifier);
    // eslint-disable-next-line
  }, [showAutoClassifier]);

  const handleChange = (id: string | null) => {
    const result = { ...values };
    const imageId = currentImage && currentImage.id;
    if (!imageId) {
      return;
    }
    const ids = [...selectedIds, imageId];

    items.forEach((classItem) => {
      if (classItem.id === id) {
        // add to class
        result[classItem.id] = [
          ...Array.from(new Set([...(values[classItem.id] || []), ...ids])),
        ];
      } else {
        result[classItem.id] = (values[classItem.id] || []).filter(
          (i: number) => ids.indexOf(i) === -1
        );
      }
    });
    onChange(result);
  };

  const handleRemoveAll = () => {
    const result = { ...values };
    items.forEach((item) => {
      result[item.id] = [];
    });
    onChange(result);
  };

  const handleClickSetAll = () => {
    const key = items[0].id;
    onChange({ ...values, [key]: images.map((i) => i.id) });
  };

  const emptyAnnotations =
    items.filter((item) => (values[item.id] || []).length !== 0).length === 0;

  return (
    <>
      <ClassNameList
        t={_}
        disabledKeyListener={disabledKeyListener || showAutoClassifier}
        emptyAnnotations={emptyAnnotations}
        onClickSetAll={items.length === 1 ? handleClickSetAll : undefined}
        onClickAutoClassifier={() => setShowAutoClassifier(true)}
        onClickRemove={handleRemoveAll}
        isActive={!!currentImage}
        items={items.map((i) => ({ ...i, count: (values[i.id] || []).length }))}
        value={getCurrentClassEvaluation({
          annotations: values,
          currentImage,
          selectedIds,
          items,
        })}
        onChange={handleChange}
      />
      <AutoClassifierDialog
        open={showAutoClassifier}
        onClose={() => setShowAutoClassifier(false)}
        classNames={items}
        onSet={(patternItems, isRegex) => {
          const { results, error } = imagesAutoClassification(
            images,
            patternItems,
            isRegex
          );
          if (error) {
            dispatch(addErrorMessage(error));
          }
          const result: { [name: string]: number[] } = {};
          items.forEach((item, index) => {
            result[item.id] = results[index];
          });
          onChange(result);
          setShowAutoClassifier(false);
        }}
      />
    </>
  );
};

export default ClassNameListAll;
