import React, { FC } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { OutlinedInput, useTheme, Theme } from "@mui/material";
import useI18n from "../../hooks/useI18n";
import { useAppSelector } from "../../hooks/store";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: number, personName: number[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export interface Props {
  value: number[];
  onChange: (value: number[]) => void;
}

const FilterForm: FC<Props> = (props) => {
  const [_] = useI18n();
  const theme = useTheme();
  const { tags } = useAppSelector((state) => ({
    tags: state.database.tags || [],
  }));

  return (
    <>
      <FormControl margin="dense" size="small" fullWidth variant="outlined">
        <InputLabel>{_("tags")}</InputLabel>
        <Select
          multiple
          size="small"
          value={props.value || []}
          onChange={(e) => props.onChange(e.target.value as number[])}
          input={<OutlinedInput label={_("tags")} />}
          MenuProps={MenuProps}
        >
          {tags.map((tag) => (
            <MenuItem
              key={tag.id}
              value={tag.id}
              style={getStyles(tag.id, props.value || [], theme)}
            >
              {tag.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default FilterForm;
