import { ImageSize } from "../../../../types/common";

export type FormItem = {
  id: string;
  type:
    | "number"
    | "select"
    | "selectImages"
    | "color"
    | "checkbox"
    | "histogram";
  label: string;
  min?: number | ((values: Values, imageSize: ImageSize) => number);
  max?: number | ((values: Values, imageSize: ImageSize) => number);
  maxSlider?: number;
  minSlider?: number;
  step?: number;
  show?: (values: Values) => boolean;
  options?: { label: string; value: string }[];
  multiselect?: boolean;
};

export type Util = {
  id: string;
  label: string;
  formItems: FormItem[];
  defaultValues: Values;
  layers?: string[];
};

export type Values = {
  [key: string]: string | number | {};
};

const utilsTypes: Util[] = [
  {
    id: "CUT",
    label: "cut",
    formItems: [
      {
        id: "offsetTop",
        type: "number",
        min: 0,
        max: (values: any, imageSize: ImageSize) => {
          return Math.max(
            imageSize.height - 1 - values.offsetBottom,
            imageSize.height - values.offsetBottom - 1,
            0
          );
        },
        label: "offsetTop",
      },
      {
        id: "offsetBottom",
        type: "number",
        min: 0,
        max: (values, imageSize) =>
          Math.max(
            imageSize.height - 1 - (values.offsetTop as number),
            imageSize.height - (values.offsetTop as number) - 1,
            0
          ),
        label: "offsetBottom",
      },
      {
        id: "offsetLeft",
        type: "number",
        min: 0,
        max: (values, imageSize) =>
          Math.max(
            imageSize.width - 1 - (values.offsetRight as number),
            imageSize.width - (values.offsetRight as number) - 1,
            0
          ),
        label: "offsetLeft",
      },
      {
        id: "offsetRight",
        type: "number",
        min: 0,
        label: "offsetRight",
        max: (values, imageSize) =>
          Math.max(
            imageSize.width - 1 - (values.offsetLeft as number),
            imageSize.width - (values.offsetLeft as number) - 1,
            0
          ),
      },
    ],
    defaultValues: {
      offsetTop: 0,
      offsetLeft: 0,
      offsetBottom: 0,
      offsetRight: 0,
    },
  },
  {
    id: "SCALE",
    label: "scale",
    formItems: [
      {
        id: "value",
        type: "number",
        min: 0.01,
        maxSlider: 2,
        max: 5,
        label: "value",
        step: 0.05,
      },
    ],
    defaultValues: {
      value: 1,
    },
  },
  {
    id: "ROTATION",
    label: "rotation",
    formItems: [
      {
        id: "angle",
        type: "number",
        label: "angle",
        minSlider: 0,
        maxSlider: 360,
      },
    ],
    defaultValues: {
      angle: 0,
    },
  },
  {
    id: "BG_NORMALIZATION",
    label: "bg_normalization",
    formItems: [
      {
        id: "value",
        type: "number",
        maxSlider: 100,
        min: 1,
        max: 200,
        label: "value",
      },
    ],
    defaultValues: {
      value: 1,
    },
  },
  {
    id: "BLUR",
    label: "blur",
    formItems: [
      {
        id: "value",
        type: "number",
        maxSlider: 100,
        label: "value",
        min: 1,
        max: 200,
      },
    ],
    defaultValues: {
      value: 1,
    },
  },
  {
    id: "CANNY_EDGE_DETECTOR",
    label: "canny_edge_detector",
    formItems: [
      {
        id: "threshold",
        type: "number",
        maxSlider: 255,
        max: 255,
        label: "threshold",
      },
    ],
    defaultValues: {
      threshold: 100,
    },
  },
  {
    id: "REMOVE_BACKGROUND",
    label: "remove_background",
    formItems: [
      {
        id: "type",
        type: "select",
        label: "type",
        options: [
          { label: "Histogram", value: "histogram" },
          { label: "Canny", value: "canny" },
        ],
      },
      {
        id: "histogram",
        type: "histogram",
        label: "histogram",
        show: ({ type }) => type !== "canny",
      },
      {
        id: "blur",
        type: "number",
        maxSlider: 100,
        label: "blur",
        min: 1,
        max: 200,
        show: ({ type }) => type === "canny",
      },
      {
        id: "threshold",
        type: "number",
        maxSlider: 170,
        label: "threshold",
        show: ({ type }) => type === "canny",
      },

      {
        id: "canny_dilate_kernel",
        type: "number",
        max: 10,
        label: "canny_dilation_kernel",
        show: ({ type }) => type === "canny",
      },
      {
        id: "canny_dilate_iter",
        type: "number",
        max: 10,
        label: "canny_dilation_iteration",
        show: ({ type }) => type === "canny",
      },
      {
        id: "canny_erode_kernel",
        type: "number",
        max: 10,
        label: "canny_erosion_kernel",
        show: ({ type }) => type === "canny",
      },
      {
        id: "canny_erode_iter",
        type: "number",
        max: 10,
        label: "canny_erosion_iteration",
        show: ({ type }) => type === "canny",
      },
      {
        id: "darkerBg",
        type: "checkbox",
        label: "dark_background",
        show: ({ type }) => type !== "canny",
      },
      {
        id: "extreme1",
        type: "number",
        max: 255,
        label: "extreme_1",
        show: ({ type }) => type !== "canny",
      },
      {
        id: "extreme2",
        type: "number",
        max: 255,
        label: "extreme_2",
        show: ({ type }) => type !== "canny",
      },
      {
        id: "color",
        type: "color",
        label: "background_color",
      },
    ],
    defaultValues: {
      type: "histogram",
      blur: 10,
      threshold: 10,
      canny_dilate_iter: 3,
      canny_erode_iter: 3,
      canny_dilate_kernel: 3,
      canny_erode_kernel: 3,
      extreme1: 50,
      extreme2: 150,
      darkerBg: false,
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },
    layers: ["stage_1"],
  },
  {
    id: "UNIFIER",
    label: "unifier",
    formItems: [
      {
        id: "onlyAngle",
        type: "checkbox",
        label: "only_angle_in_context",
      },
      {
        id: "color",
        type: "color",
        label: "background_color",
      },
    ],
    defaultValues: {
      onlyAngle: false,
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },
  },
  {
    id: "CONTRAST_EQUALISATION",
    label: "contrast_equalisation",
    formItems: [
      {
        id: "kernelSize",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "kernel_size",
        step: 1,
      },
      {
        id: "clipLimit",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "clip_limit",
        step: 1,
      },
    ],
    defaultValues: {
      kernelSize: 1,
      clipLimit: 1,
    },
  },
  {
    id: "DILATION",
    label: "dilation",
    formItems: [
      {
        id: "kernelSize",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "kernel_size",
        step: 1,
      },
      {
        id: "iterations",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "iterations",
        step: 1,
      },
    ],
    defaultValues: {
      kernelSize: 3,
      iterations: 1,
    },
  },
  {
    id: "EROSION",
    label: "erosion",
    formItems: [
      {
        id: "kernelSize",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "kernel_size",
        step: 1,
      },
      {
        id: "iterations",
        type: "number",
        maxSlider: 10,
        min: 1,
        label: "iterations",
        step: 1,
      },
    ],
    defaultValues: {
      kernelSize: 3,
      iterations: 1,
    },
  },
  {
    id: "UNWRAP_CIRCLE",
    label: "polar_transform",
    formItems: [
      {
        id: "dp_value",
        type: "number",
        maxSlider: 2,
        min: 0.01,
        label: "inverse_ratio",
        step: 0.05,
      },
      {
        id: "minDist_value",
        type: "number",
        maxSlider: 1000,
        min: 1,
        label: "minimal_distance_between_circles",
        step: 1,
      },
    ],
    defaultValues: {
      dp_value: 1.5,
      minDist_value: 100,
    },
    layers: ["stage_1"],
  },
  {
    id: "COLOR_TRANSFORM",
    label: "color_transforms",
    formItems: [
      {
        id: "colorTransformType",
        type: "select",
        label: "type",
        options: [
          { label: "HLS", value: "hls" },
          { label: "HSV", value: "hsv" },
          { label: "YUV", value: "yuv" },
          { label: "LAB", value: "lab" },
          { label: "GRAY", value: "gray" },
        ],
      },
    ],
    defaultValues: {
      colorTransformType: "hls",
    },
  },
  {
    id: "ADJUST_GAMMA",
    label: "adjust_gamma",
    formItems: [
      {
        id: "adjustGamma",
        type: "number",
        maxSlider: 3,
        min: 0.01,
        label: "gamma_value",
        step: 0.01,
      },
    ],
    defaultValues: {
      adjustGamma: 1,
    },
  },
  {
    id: "CAMERA_CALIBRATION",
    label: "camera_calibration",
    formItems: [
      {
        id: "calibration",
        type: "selectImages",
        label: "calibration",
        multiselect: true,
      },
      {
        id: "perspectiveTransform",
        type: "selectImages",
        label: "perspective_transform",
        multiselect: false,
      },
    ],
    defaultValues: {
      calibration: {},
      perspectiveTransform: {},
    },
  },
];

export default utilsTypes;
