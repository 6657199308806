import React from "react";
import PhotoIcon from "@mui/icons-material/PhotoLibrary";
import CameraIcon from "@mui/icons-material/Videocam";
import { useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import useI18n from "../hooks/useI18n";
import { useAppSelector } from "../hooks/store";

const styles = {
  wrap: {
    padding: 10,
    paddingTop: 50,
    textAlign: "center",
    borderBottom: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: 10,
  },
  icon: {
    position: "relative",
    top: 7,
  },
} as const;

const NoImageInfo = () => {
  const history = useHistory();
  const [_] = useI18n();
  const { simpleTutorialOnly } = useAppSelector((state) => ({
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  }));
  return (
    <div style={styles.wrap}>
      <Paper style={styles.content} elevation={1}>
        <h2>{_("you_must_add_images_first")}</h2>
        <p>
          {_("please_add_images_in")}{" "}
          <Button
            startIcon={<PhotoIcon />}
            onClick={() => history.push("/images/")}
          >
            {_("images")}
          </Button>{" "}
          {!simpleTutorialOnly && (
            <>
              {_("or_capture_image_using")}{" "}
              <Button
                startIcon={<CameraIcon />}
                onClick={() => history.push("/camera/")}
              >
                {_("camera")}
              </Button>
            </>
          )}
        </p>
        <Button
          variant="outlined"
          onClick={() => history.goBack()}
          color="primary"
        >
          {_("go_back")}
        </Button>
      </Paper>
    </div>
  );
};

export default NoImageInfo;
