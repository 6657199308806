import axios from "axios";
import { useCallback } from "react";
import { SERVER_IMAGE_URL } from "../config";
import { useAppSelector } from "./store";

const useUploadAssets = () => {
  const { token } = useAppSelector((state) => ({
    token: state.system.token,
  }));
  const handleUpload = useCallback(
    async (file: File) => {
      const data = new FormData();
      data.append("file", file);
      const response = await axios.post(
        `${SERVER_IMAGE_URL}/upload_assets?token=${token}`,
        data
      );
      return response.data as { id: string };
    },
    [token]
  );
  return [handleUpload];
};

export default useUploadAssets;
