import axios from "axios";
import { SERVER_IMAGE_URL } from "../config";
import { Line, Rectangle } from "../types/common";
import * as Flow from "../types/flow";
import { jsonToURI } from "../utils/common";
import { socket } from "./actions";
import { arrayBufferToBase64 } from "../store/utils";

// Uploading
// ------------------------------------

export type FileWithPath = File & {
  path?: string;
  fullName: string;
  webkitRelativePath?: string;
};

export let selectedFiles: FileWithPath[] = [];

export const setSelectedFiles = (items: FileWithPath[]) =>
  (selectedFiles = items);

export const uploadImage = (
  file: FileWithPath,
  socketId: string,
  prefix: string,
  tagsIds: number[],
  resize: { width: number; height: number } | null,
  token: string
) =>
  new Promise((resolve) => {
    const data = new FormData();
    data.append("socketId", socketId);
    data.append("prefix", prefix);
    data.append("tagsIds", tagsIds.join(","));
    data.append("maxWidth", resize ? resize.width.toString() : "");
    data.append("maxHeight", resize ? resize.height.toString() : "");
    data.append(
      "name",
      file.webkitRelativePath
        ? file.webkitRelativePath.substr(
            file.webkitRelativePath.indexOf("/") + 1
          )
        : file.name
    );
    if (file.path) {
      data.append("filePath", file.path);
    } else {
      data.append("file", file);
    }

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    resolve(
      axios.post(
        `${SERVER_IMAGE_URL}/upload_images?token=${token}`,
        data,
        config
      )
    );
  });

export const upload = (
  socketId: string,
  prefix: string,
  tagsIds: number[],
  resize: { width: number; height: number } | null,
  token: string,
  onProgress: (value: number) => void
) =>
  new Promise<void>((resolve, reject) => {
    const num = selectedFiles.length;
    let i = 0;
    onProgress(0);
    const up = () => {
      const file = selectedFiles[i];
      i++;
      uploadImage(file, socketId, prefix, tagsIds, resize, token)
        .then(() => {
          onProgress(Math.round((i / num) * 100));
          if (selectedFiles.length === i) {
            setSelectedFiles([]);
            resolve();
          } else {
            up();
          }
        })
        .catch(reject);
    };
    up();
  });

// IMAGE ANALYZE
// ------------------------------------

export type ImageData = {
  rectangles: Rectangle[];
  ocr: any;
  lines: Line[];
  result: boolean | null;
  stdout: string;
  stderr: string;
  imageSize: { width: number; height: number };
  error: boolean;
  errors: string[];
};

const parseImageSrc = (imgSrc: string) => {
  const url = new URL(imgSrc);
  const array = url.pathname.split("/");
  const flow = JSON.parse(
    url.searchParams.get("config") as string
  ) as Flow.Flow;
  const imageId = +array[array.length - 1];

  return {
    flow,
    imageId,
    min: url.searchParams.get("min") === "y",
    withMask: url.searchParams.get("with_mask") === "y",
  };
};

export const getImageSizeFromSrc = (imgSrc: string) => {
  const params = parseImageSrc(imgSrc);
  return getImageData(params.imageId, params.flow, "").then(
    (data) => data.data.imageSize
  );
};

export const getImageWithDataFromSrc = (imgSrc: string) => {
  const params = parseImageSrc(imgSrc);
  return getImageWithData(params, true);
};

export const getImageObjects = async (
  imageId: number,
  flow: Flow.Flow,
  token: string
): Promise<{ rectangles: Rectangle[]; lines: Line[]; error: boolean }> => {
  if (flow.length === 0) {
    return {
      rectangles: [],
      lines: [],
      error: false,
    };
  } else {
    return getImageData(imageId, flow, token).then((data) => ({
      error: data.data.error,
      rectangles: data.data.rectangles,
      lines: data.data.lines,
    }));
  }
};

export const getImageData = (imageId: number, flow: Flow.Flow, token: string) =>
  new Promise<{ data: ImageData }>((resolve) => {
    socket.emit(
      "get_image_data",
      {
        flow,
        imageId,
      },
      (data: ImageData) => {
        resolve({ data });
      }
    );
  });

export const getImageWithData = (
  params: {
    withMask: boolean;
    flow: Flow.Flow;
    imageId: number;
    min: boolean;
  },
  inBlob: boolean
) => {
  return new Promise<{ imageUrl: string; data: ImageData }>((resolve) => {
    socket.emit(
      "get_image_data",
      { ...params, withImage: true },
      (image: Iterable<number>, data: ImageData) => {
        if (data.error) {
          resolve({
            imageUrl: "/error_image.svg",
            data: {
              ...data,
              // error image size
              imageSize: {
                width: 200,
                height: 200,
              },
            },
          });
          return;
        }
        if (inBlob) {
          const blob = new Blob([new Uint8Array(image)], { type: "image/jpg" });
          const imageUrl = URL.createObjectURL(blob);
          resolve({
            imageUrl,
            data,
          });
        } else {
          const imageUrl =
            "data:image/jpg;base64," + arrayBufferToBase64(image);
          resolve({
            imageUrl,
            data,
          });
        }
      }
    );
  });
};

export const getImage = async (imgSrc: string, inBlob?: boolean) => {
  const pararms = parseImageSrc(imgSrc);
  return getImageWithData(pararms, inBlob ?? false).then((data) => {
    return data.imageUrl;
  });
};

export type Histrogram = number[][];

export const getImageHistogram = (
  imageId: number,
  flow: Flow.Flow,
  token: string
) =>
  new Promise<{ data: Histrogram }>((resolve) => {
    resolve(
      axios.get(
        `${SERVER_IMAGE_URL}/get_image_histogram?token=${token}&imageId=${imageId}&flow=${jsonToURI(
          flow
        )}`
      )
    );
  });
