export function rotate(
  cx: number,
  cy: number,
  x: number,
  y: number,
  angle: number
): [number, number] {
  if (angle === 0) {
    return [x, y];
  }
  const radians = (Math.PI / -180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}

export const middleToCorner = (data: any): any => {
  const p1 = rotate(
    data.x + data.width / 2,
    data.y + data.height / 2,
    data.x,
    data.y,
    data.rotate || 0
  );
  const poss = [data.x - p1[0], data.y - p1[1]];
  return {
    ...data,
    x: data.x - poss[0],
    y: data.y - poss[1],
  };
};

export const cornerToMidddle = (data: any): any => {
  const p1 = rotate(
    data.x + data.width / 2,
    data.y + data.height / 2,
    data.x,
    data.y,
    data.rotate || 0
  );
  const poss = [data.x - p1[0], data.y - p1[1]];
  return {
    ...data,
    x: data.x + poss[0],
    y: data.y + poss[1],
  };
};
