import React from "react"
import { useEffect, useState } from "react";
import { Line as LineKonva, Circle } from "react-konva";
import Label from "./Label";


type Position = {
  start: { x: number, y: number },
  end: { x: number, y: number },
}

const lineToPoints = (props: Position): [number, number, number, number] => {
  return [props.start.x, props.start.y, props.end.x, props.end.y]
}

type Props = Position & {
  scale: number,
  listening?: boolean,
  onChange?: (pos: Position) => void,
  color?: [number, number, number],
  isSelected?: boolean,
  onSelect?: () => void,
  stroke?: number,
  selectable?: boolean,
  moveable?: boolean,
  disabled?: boolean,
  text?: string,
}

const Line = (props: Props) => {
  const [position, setPosition] = useState<Position>({ start: props.start, end: props.end })

  useEffect(() => {
    setPosition({ start: props.start, end: props.end })
  }, [props.start, props.end])

  const handleChange = () => {
    props.onChange?.(position)
  }

  const onSelect = (!props.disabled && props.selectable !== false) ? props.onSelect : undefined
  const draggable = !props.disabled && props.moveable !== false

  return (
    <>
      <LineKonva
        onClick={onSelect}
        listening={props.listening}
        stroke={props.isSelected ? '#5ebeff' : props.color ? `rgb(${props.color.join(',')})` : "#5ebeff"}
        points={lineToPoints(position)}
        strokeWidth={(props.stroke || (draggable ? 1 : 3)) / props.scale}
      />

      {(!props.disabled && props.selectable !== false) && <>
        <Circle
          onClick={onSelect}
          onDragMove={(e) => {
            const x = e.target.x()
            const y = e.target.y()
            setPosition((pos) => ({ ...pos, start: { x, y } }))
          }}
          onDragEnd={() => {
            handleChange()
          }}
          draggable={draggable}
          x={position.start.x}
          y={position.start.y}
          radius={6 / props.scale}
          fill={"white"}
          stroke={props.isSelected ? "#5ebeff" : "#0cf463"}
          strokeWidth={1 / props.scale}
        />
        <Circle
          onClick={onSelect}
          onDragMove={(e) => {
            const x = e.target.x()
            const y = e.target.y()
            setPosition((pos) => ({ ...pos, end: { x, y } }))
          }}
          onDragEnd={() => {
            handleChange()
          }}
          draggable={draggable}
          x={position.end.x}
          y={position.end.y}
          radius={6 / props.scale}
          fill={"white"}
          stroke={props.isSelected ? "#5ebeff" : "#0cf463"}
          strokeWidth={1 / props.scale}
        />
      </>}
      <Label
        x={(position.start.x + position.end.x) / 2}
        y={(position.start.y + position.end.y) / 2}
        text={props.text}
        scale={props.scale}
      />
    </>
  )
}

export default Line