import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import TokenContext from "../../../contexts/TokenContext";
import { Camera } from "../types";

const useCamerasList = () => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const { token } = useContext(TokenContext);

  const refresh = useCallback(() => {
    axios
      .get(`${SERVER_URL}/get_cameras?token=${token}`)
      .then((res) => {
        setCameras(res.data as Camera[]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { cameras, refresh };
};

export default useCamerasList;
