import React, { FC, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import usePromise from "react-use-promise";
import { CompactPicker, Color } from "react-color";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Histogram from "./Histogram";
import SliderWithField from "../../../../components/SliderWithField";
import { Button } from "@mui/material";
import SelectImageDialog from "../../../Unifier/components/SelectImageDialog";
import { SERVER_IMAGE_URL } from "../../../../config";
import axios from "axios";
import { FormItem, Values } from "./utilsTypes";
import useI18n from "../../../../hooks/useI18n";
import { Histrogram, ImageData } from "../../../../actions/ImageService";
import { useAppSelector } from "../../../../hooks/store";
import * as Flow from "../../../../types/flow";
import { ImageSize } from "../../../../types/common";

const useStyles = makeStyles<void, 'item'>()((theme, _params, classes) => ({
  root: {
    width: "100%",
  },
  label: {
    color: "rgb(187, 187, 187)!important",
    fontSize: 12,
    display: "block",
    marginBottom: 7,
    marginTop: 10,
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  item: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [`&+.${classes.item}`]: {
      marginTop: 0,
    },
  },
}));

const HistogramWrap: FC<{
  getImageHistogram: (numberOfItems: number) => Promise<{ data: Histrogram }>;
  itemIndex: number;
  imageKey: string;
}> = (props) => {
  const { classes } = useStyles();

  const [result, error, state] = usePromise(
    () => props.getImageHistogram(props.itemIndex),
    [props.imageKey]
  );

  return (
    <div className={classes.item}>
      {/* eslint-disable-next-line  */}
      <label className={classes.label}>Histogram</label>
      {/* eslint-disable-next-line no-nested-ternary */}
      {state === "pending" ? (
        "Pending"
      ) : error ? (
        "Error"
      ) : result ? (
        <Histogram data={result.data} />
      ) : (
        <span />
      )}
    </div>
  );
};

type Props = {
  type: string;
  onChange: (values: Values) => void;
  moduleId: number;
  getImageFlow: () => Flow.Flow;
  multiselect?: boolean;
  label: string;
};

const SelectImages: FC<Props> = ({
  type,
  onChange,
  moduleId,
  getImageFlow,
  multiselect,
  label,
}) => {
  const { classes } = useStyles();
  const [_] = useI18n();
  const { images, token } = useAppSelector((state) => ({
    images: state.database.images,
    token: state.system.token,
  }));
  const [showDialog, setShowDialog] = useState(false);

  const handleSelect = (result: number[]) => {
    setShowDialog(false);
    axios
      .post(`${SERVER_IMAGE_URL}/preprocess_select_images?token=${token}`, {
        imagesIds: result,
        flow: getImageFlow(),
        type,
      })
      .then((res) => {
        onChange(res.data);
      });
  };

  return (
    <>
      <SelectImageDialog
        multiselect={multiselect}
        moduleId={moduleId}
        onSelect={handleSelect}
        open={showDialog}
        images={images}
        onClose={() => setShowDialog(false)}
      />
      <label className={classes.label}>{_(label)}</label>
      <Button variant="outlined" onClick={() => setShowDialog(true)}>
        {_(multiselect ? "select_images" : "select_image")}
      </Button>
    </>
  );
};

const Form: FC<{
  onChange: (values: Values) => void;
  values: Values;
  items: FormItem[];
  type: string;
  getImageData: (numberOfItems: number) => Promise<{ data: ImageData }>;
  getImageFlow: (index: number) => Flow.Flow;
  getImageHistogram: (numberOfItems: number) => Promise<{ data: Histrogram }>;
  moduleId: number;
  imageKey: string;
  itemIndex: number;
}> = (props) => {
  const { classes } = useStyles();
  const [_] = useI18n();
  let result: { data: ImageData } | null = null;
  let error: Error | undefined;
  let state: string | null = null;

  if (props.type === "CUT") {
    // ANTI-PATTERN
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [result, error, state] = usePromise<any>(
      () => props.getImageData(props.itemIndex),
      [props.imageKey]
    );
  }

  if (state === "pending") {
    return <span>Pending ...</span>;
  }

  if (error) {
    return <span>Error</span>;
  }

  const renderNumber = ({
    id,
    label,
    max,
    step = 1,
    min,
    minSlider,
    maxSlider,
  }: {
    id: number;
    label: string;
    max: number | ((values: Values, imageSize: ImageSize) => number);
    step: number;
    min: number;
    minSlider: number;
    maxSlider: number;
  }) => {
    return (
      <SliderWithField
        value={props.values[id] as number}
        label={_(label)}
        onChange={(value) => props.onChange({ ...props.values, [id]: value })}
        min={min}
        step={step}
        minSlider={minSlider}
        maxSlider={maxSlider}
        max={
          max instanceof Function
            ? max(
                props.values,
                result?.data.imageSize ?? { width: 0, height: 0 }
              )
            : max
        }
      />
    );
  };

  const renderCheckbox = ({ id, label }: { id: number; label: string }) => {
    return (
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={!!props.values[id]}
            onChange={(e) =>
              props.onChange({ ...props.values, [id]: e.target.checked })
            }
          />
        }
        label={_(label)}
      />
    );
  };

  const renderColor = ({ id, label }: { id: string; label: string }) => {
    return (
      <div>
        {/* eslint-disable-next-line  */}
        <label className={classes.label}>{_(label)}</label>
        <CompactPicker
          color={props.values[id] as Color}
          onChange={(value) =>
            props.onChange({ ...props.values, [id]: value.rgb })
          }
        />
      </div>
    );
  };

  const renderSelect = ({
    id,
    label,
    options,
  }: {
    id: string;
    label: string;
    options: { label: string; value: string }[];
  }) => {
    return (
      <FormControl component="fieldset">
        <FormLabel className={classes.label} component="legend">
          {_(label)}
        </FormLabel>
        <RadioGroup
          value={props.values[id]}
          onChange={(e) => {
            props.onChange({ ...props.values, [id]: e.target.value });
          }}
        >
          {options.map(({ label, value }) => (
            <FormControlLabel
              value={value}
              control={<Radio color="primary" />}
              label={label}
              labelPlacement="start"
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const filterItems = (i: FormItem) => {
    return i.show?.(props.values) ?? true;
  };

  return (
    <div
      className={classes.root}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
    >
      {props.items.filter(filterItems).map((i) => {
        if (i.type === "number") {
          return (
            <div className={classes.item} key={i.id}>
              {renderNumber(i as any)}
            </div>
          );
        }
        if (i.type === "checkbox") {
          return (
            <div className={classes.item} key={i.id}>
              {renderCheckbox(i as any)}
            </div>
          );
        }
        if (i.type === "color") {
          return (
            <div className={classes.item} key={i.id}>
              {renderColor(i)}
            </div>
          );
        }
        if (i.type === "select") {
          return (
            <div className={classes.item} key={i.id}>
              {renderSelect(i as any)}
            </div>
          );
        }
        if (i.type === "histogram") {
          return (
            <HistogramWrap
              key={i.id}
              getImageHistogram={props.getImageHistogram}
              itemIndex={props.itemIndex}
              imageKey={props.imageKey}
            />
          );
        }
        if (i.type === "selectImages") {
          return (
            <div className={classes.item} key={i.id}>
              <SelectImages
                type={i.id}
                moduleId={props.moduleId}
                label={i.label}
                multiselect={i.multiselect}
                onChange={(values) =>
                  props.onChange({ ...props.values, [i.id]: values })
                }
                getImageFlow={() => props.getImageFlow(props.itemIndex)}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Form;
