import { SERVER_URL } from "./../../config";
import axios from "axios";
import { Buffer } from "buffer";

const getFormData = (file: File) => {
  const data = new FormData();
  data.append("image", file, file.name);
  return data;
};

const parseImage = (data: any) =>
  Buffer.from(data, "binary").toString("base64");

const parseBody = (data: any, imageLength: number | null) => {
  // context
  const text = new TextDecoder().decode(data.slice(imageLength || data.length));
  const context = JSON.parse(text);
  // image
  if (!imageLength) {
    return { context };
  }
  const image = parseImage(data.slice(-(data.length - imageLength)));
  return { context, image };
};

export const getLastContext = async (apiKey: string) => {
  const response = await axios.get(
    `${SERVER_URL}/last_image?api_key=${apiKey}`
  );
  return response.data;
};

export const analyzeImageContext = async (file: File, apiKey: string) => {
  const response = await axios.post(
    `${SERVER_URL}/analyze_image?api_key=${apiKey}`,
    getFormData(file)
  );
  return { context: response.data, image: undefined };
};

export const analyzeImage = async (
  file: File,
  contextInBody: boolean,
  responseType: "image" | "heatmap" | "annotated_image",
  apiKey: string
) => {
  const response = await axios.post(
    `${SERVER_URL}/analyze_image?api_key=${apiKey}&response_type=${responseType}${
      contextInBody ? "&context_in_body=t" : ""
    }`,
    getFormData(file),
    {
      responseType: "arraybuffer",
    }
  );
  if (contextInBody) {
    return parseBody(
      response.data,
      parseInt(response.headers["imagelen"] || null)
    );
  } else {
    const image = parseImage(response.data);
    const context = JSON.parse(atob(response.headers["contextbase64utf"]));
    return { image, context };
  }
};

export const getLastImageContext = async (
  contextInBody: boolean,
  responseType: "image" | "heatmap" | "annotated_image",
  apiKey: string
) => {
  const url = `${SERVER_URL}/last_image?api_key=${apiKey}&response_type=${responseType}${
    contextInBody ? "&context_in_body=t" : ""
  }`;
  const response = await axios.get(url, {
    responseType: "arraybuffer",
  });
  if (contextInBody) {
    return parseBody(
      response.data,
      parseInt(response.headers["imagelen"] || null)
    );
  } else {
    const image = parseImage(response.data);
    const context = JSON.parse(atob(response.headers["contextbase64utf"]));
    return { image, context };
  }
};
