import React, { FC, useContext } from "react";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import { Modules, typeToIcon, typeToLabel } from "../../modules";
import ImageWithRectanglesStatic from "../ImageWithRectanglesStatic";
import type * as Flow from "../../types/flow";
import { roundTo } from "../../utils/common";
import { LinearProgress } from "@mui/material";
import { HelperContext } from "../../layouts/PageLayout/PageLayout";

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: "left",
    minHeight: 60,
    width: 190,
    margin: "20px 10px",
    whiteSpace: "nowrap",
    position: "relative",
    padding: 10,
    cursor: "pointer",
    backgroundColor: "#fbfbfb",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  note: {
    whiteSpace: "pre",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  time: {
    marginTop: 2,
    fontSize: 14,
  },
  inactive: {
    opacity: 0.8,
  },
  progress: {
    width: 80,
    marginTop: 10,
  },
  icon: {
    position: "absolute",
    top: 10,
    right: 8,
  },
  arrowDownWrap: {
    textAlign: "center",
    zIndex: 3,
    position: "relative",
    marginBottom: -33,
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "calc(100% - 21px)",
    display: "block",
  },
  imgWrap: {
    marginLeft: -10,
    marginRight: -10,
    marginBottom: -10,
    marginTop: 8,
  },
}));

type Props = {
  label?: string;
  note?: string;
  active: boolean;
  type: Modules;
  onClick: (e: React.MouseEvent) => void;
  config: Flow.Any | Flow.AnyDebugger;
  hideImages?: boolean;
};

const Module: FC<Props> = ({
  label,
  note,
  type,
  onClick,
  active,
  config,
  hideImages,
}) => {
  const context = useContext(HelperContext);
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handlePopoverOpen(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl) && !!note;
  const isInactive = "inactive" in config && config["inactive"];
  return (
    <>
      <div className={classes.arrowDownWrap}>
        <ArrowDownIcon style={{ color: "rgb(153, 153, 153)" }} />
      </div>
      <Paper
        square
        elevation={anchorEl ? 5 : 3}
        className={cx(classes.root, active && classes.active)}
        onClick={onClick}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className={classes.icon}>{typeToIcon(type)}</span>
        <div className={classes.label}>{label || typeToLabel(type)}</div>

        <div className={classes.time}>
          {isInactive ? (
            <span className={classes.inactive}>Inactive</span>
          ) : (
            "time" in config &&
            (config["time"] !== undefined ? (
              <>{roundTo(config.time * 1000, 2)} ms</>
            ) : (
              <LinearProgress className={classes.progress} />
            ))
          )}
        </div>
        {!hideImages && "_detectedRectangles" in config && (
          <div className={classes.imgWrap}>
            <ImageWithRectanglesStatic
              lines={config.hasOwnProperty("_lines") ? config._lines : []}
              rectangles={config._detectedRectangles}
              ocr={config._ocr}
              width={190}
              imageSrc={context.helper.getStaticFilePath(config._imagePath)}
              key={context.helper.getStaticFilePath(config._imagePath)}
            />
          </div>
        )}
      </Paper>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.note}>{note}</Typography>
      </Popover>
    </>
  );
};

export default Module;
