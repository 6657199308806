import React, { Component } from "react";
import Rectangles from "./Rectangles";
import { ImageSize, Line, Rectangle } from "../types/common";

const styles = {
  imageWrap: {
    position: "relative",
    overflow: "hidden",
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
} as const;

type Props = {
  imageSrc: string;
  width: number;
  rectangles: Rectangle[];
  lines: Line[];
  ocr: any[];
  allowOverflow?: boolean;
};

type State = {
  size: ImageSize | null;
};

export default class ImageWithRectanglesStatic extends Component<Props, State> {
  state: State = {
    size: null,
  };

  componentDidMount() {
    // Get image size
    const newImg = new Image();
    newImg.onload = () => {
      this.setState({
        size: {
          height: newImg.height,
          width: newImg.width,
        },
      });
    };
    newImg.src = this.props.imageSrc;
  }

  getScale(width: number, size: ImageSize) {
    return width / size.width;
  }

  getImageStyle(width: number, size: ImageSize) {
    const scale = this.getScale(width, size);
    return {
      width: size.width * scale,
      height: size.height * scale,
      display: "block",
    };
  }

  render() {
    if (!this.state.size) {
      return null;
    }
    const height =
      (this.state.size.height / this.state.size.width) * this.props.width;

    return (
      <div style={{ width: this.props.width, height }}>
        <div
          style={{
            ...styles.imageWrap,
            overflow: this.props.allowOverflow ? "initial" : "hidden",
          }}
        >
          <img
            alt=""
            style={this.getImageStyle(this.props.width, this.state.size)}
            src={this.props.imageSrc}
          />
          <Rectangles
            imageSize={this.state.size}
            items={[...this.props.rectangles, ...this.props.ocr]}
            lines={this.props.lines}
            wrapSize={{ width: this.props.width, height }}
          />
        </div>
      </div>
    );
  }
}
