import React from "react";
import { Field } from "formik";
import { Checkbox, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import useI18n from "../../../../hooks/useI18n";

const CmdForm = () => {
  const [_] = useI18n();
  return (
    <>
      <Grid item>
        <Field
          disabled={false}
          name="cmd"
          label={_("path_wit_arguments")}
          fullWidth
          variant="outlined"
          component={TextField}
          margin="dense"
          validate={(value: string) => !value && _("required")}
        />
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="cwd"
          label={_("working_directory")}
          fullWidth
          variant="outlined"
          component={TextField}
          margin="dense"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Field component={Checkbox} name="contextToStdin" type="checkbox" />
          }
          label={_("context_to_stdin")}
        />
      </Grid>
    </>
  );
};

export default CmdForm;
