import React, { FC } from "react";
import ModelLearningList from "../../../components/templates/ModelLearningList";
import { useHistory } from "react-router-dom";
import { PropsFromRedux } from "../ModelListContainer";
import useI18n from "../../../hooks/useI18n";

const ModelListView: FC<PropsFromRedux> = (props) => {
  const history = useHistory();
  const [_] = useI18n();
  return (
    <ModelLearningList
      t={_}
      enableGpuSettings
      onlyOneGpu
      gpuDevices={props.gpuDevices}
      maxHeight={props.maxHeight}
      onChangeGpuValue={(values: any) =>
        props.setGpuSettings(props.module.id, values)
      }
      gpuValue={props.module.gpuSettings || [0]}
      activeModelId={props.activeModelId}
      models={props.models}
      onActive={(model) => {
        props.setActiveModuleId({
          moduleId: props.moduleId,
          modelId: model ? model.modelId : null,
        });
      }}
      onEditName={props.setModelName}
      onDelete={(model) => props.deleteModel(model.modelId)}
      onOpen={() => {
        history.push(`/modules/ocr/${props.module.id}/validate/detail/`);
      }}
    />
  );
};

export default ModelListView;
