import React, { Component } from "react";
import * as objectPath from "object-path-immutable";
import Button from "@mui/material/Button";
import { deepCompare, sortModules } from "../../../utils/common";
import { updateFlow } from "../../../actions/actions";
import Builder from "./Builder";
import { CON_MIN_HEIGHT_HEADER, CONTENT_INNER_PADDING } from "../../../theme";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import NavigationPrompt from "../../../components/NavigationPromp";
import { AI_MODULES, Modules } from "../../../modules";
import withI18n from "../../../utils/withI18n";
import { AnyModule, SortModules } from "./../../../types/modules";
import { History } from "history";

type Path = number[];

const styles = {
  wrap: {
    padding: CONTENT_INNER_PADDING,
    height: CON_MIN_HEIGHT_HEADER,
    overflow: "auto",
  },
  builder: {
    display: "flex",
    justifyContent: "center",
  },
  saveButtonWrap: {
    textAlign: "center",
    marginBottom: 20,
  },
} as const;

const insert = (arr: any[], index: number, newItem: any) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

type Props = {
  t: (key: string) => string;
  history: History;
  addErrorMessage: (msg: string) => void;
  moduleItems: AnyModule[];
  sortItems: SortModules;
  disableCodeModule: boolean;
  allModules: boolean;
};

type State = {
  sortItems: SortModules;
  moduleItems: AnyModule[];
  prevPropsSortItems: SortModules;
};

class FlowView extends Component<Props, State> {
  state: State = {
    sortItems: this.props.sortItems,
    moduleItems: this.props.moduleItems,
    prevPropsSortItems: this.props.sortItems,
  };

  isUpdated = false;

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!deepCompare(props.sortItems, state.prevPropsSortItems)) {
      return {
        prevPropsSortItems: props.sortItems,
        sortItems: props.sortItems,
        moduleItems: props.moduleItems,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !deepCompare(prevProps.sortItems, this.props.sortItems) ||
      !deepCompare(prevProps.moduleItems, this.props.moduleItems)
    ) {
      this.props.history.push("/modules/list/");
    }
  }

  handleMoveUp = (path: Path, index: number) => {
    let nextSortItems = [...this.state.sortItems];
    const value = objectPath.get(nextSortItems, [...path, index]);
    // remove current
    nextSortItems = objectPath.del(nextSortItems, [...path, index]);
    const prevItem = objectPath.get(nextSortItems, path)[
      index - 1
    ] as SortModules;
    const isPrevParallelism = this.isArrayOfArray(prevItem);
    if (isPrevParallelism) {
      // insert to parallelism - first branch, last item
      nextSortItems = objectPath.insert(
        nextSortItems,
        [...path, index - 1, 0],
        value,
        // @ts-ignore
        prevItem[0].length
      );
    } else if (index !== 0) {
      // just move down
      nextSortItems = objectPath.insert(
        nextSortItems,
        [...path],
        value,
        index - 1
      );
    } else {
      // remove from parallelism
      nextSortItems = objectPath.insert(
        nextSortItems,
        path.slice(0, -2),
        value,
        path[path.length - 2]
      );
    }
    this.setState({
      sortItems: nextSortItems,
    });
  };

  handleMoveDown = (path: Path, index: number) => {
    let nextSortItems = [...this.state.sortItems];
    const currentArray = objectPath.get(nextSortItems, path);
    const value = objectPath.get(nextSortItems, [...path, index]);
    const nextItem = objectPath.get(nextSortItems, path)[
      index + 1
    ] as SortModules;
    // remove current
    nextSortItems = objectPath.del(nextSortItems, [...path, index]);
    const isNextParallelism = this.isArrayOfArray(nextItem);
    if (isNextParallelism) {
      // insert to parallelism - first branch, first item
      nextSortItems = objectPath.insert(
        nextSortItems,
        [...path, index, 0],
        value,
        0
      );
    } else if (currentArray.length - 1 === index) {
      // remove from parallelism
      nextSortItems = objectPath.insert(
        nextSortItems,
        path.slice(0, -2),
        value,
        path[path.length - 2] + 1
      );
    } else {
      // just move up
      nextSortItems = objectPath.insert(
        nextSortItems,
        [...path],
        value,
        index + 1
      );
    }

    this.setState({
      sortItems: nextSortItems,
    });
  };

  handleMoveLeft = (path: Path, index: number) => {
    let nextSortItems = [...this.state.sortItems];
    const value = objectPath.get(nextSortItems, [...path, index]);
    // remove current
    nextSortItems = objectPath.del(nextSortItems, [...path, index]);
    nextSortItems = objectPath.insert(
      nextSortItems,
      [...path.slice(0, -1), path[path.length - 1] - 1],
      value,
      0
    );

    this.setState({
      sortItems: nextSortItems,
    });
  };

  handleMoveRight = (path: Path, index: number) => {
    let nextSortItems = [...this.state.sortItems];
    const value = objectPath.get(nextSortItems, [...path, index]);
    // remove current
    nextSortItems = objectPath.del(nextSortItems, [...path, index]);
    nextSortItems = objectPath.insert(
      nextSortItems,
      [...path.slice(0, -1), path[path.length - 1] + 1],
      value,
      0
    );

    this.setState({
      sortItems: nextSortItems,
    });
  };

  handleDelete = (path: Path, index: number) => {
    if (path.length === 0) {
      const nextSortItems = [...this.state.sortItems];
      nextSortItems.splice(index, 1);
      const oldIds = this.findIds(this.state.sortItems[index]);
      this.setState({
        sortItems: nextSortItems,
        moduleItems: this.state.moduleItems.filter(
          (i) => oldIds.indexOf(i.id) === -1
        ),
      });
    } else {
      let oldIds: number[] = [];
      const nextSortItems: SortModules = objectPath.update(
        JSON.parse(JSON.stringify(this.state.sortItems)), // deepcopy
        path,
        (item) => {
          oldIds = this.findIds(item[index]);
          item.splice(index, 1);
          return item;
        }
      ) as any;
      this.setState({
        sortItems: nextSortItems,
        moduleItems: this.state.moduleItems.filter(
          (i) => oldIds.indexOf(i.id) === -1
        ),
      });
    }
  };

  handleAdd = (path: Path, type: Modules, index: number) => {
    const isParallelism = type === "PARALLELISM";
    const newModule = {
      id: Date.now(),
      type,
      label: "",
      note: "",
    } as AnyModule;

    if (path.length === 0) {
      this.setState({
        sortItems: insert(
          this.state.sortItems,
          index,
          isParallelism ? [[], []] : newModule.id
        ),
        moduleItems: isParallelism
          ? this.state.moduleItems
          : [...this.state.moduleItems, newModule],
      });
    } else {
      const nextSortItems: SortModules = objectPath.update(
        this.state.sortItems,
        path,
        (item) => {
          return insert(item, index, isParallelism ? [[], []] : newModule.id);
        }
      ) as any;

      this.setState({
        sortItems: nextSortItems,
        moduleItems: isParallelism
          ? this.state.moduleItems
          : [...this.state.moduleItems, newModule],
      });
    }
  };

  handleAddBranch = (path: Path, index: number) => {
    const nextSortItems: SortModules = objectPath.update(
      this.state.sortItems,
      [...path, index],
      (item) => {
        return [...item, []];
      }
    ) as any;
    this.setState({
      sortItems: nextSortItems,
    });
  };

  handleDeleteBranch = (path: Path, index: number, branchIndex: number) => {
    const deleteIds = objectPath.get(this.state.sortItems, [
      ...path,
      index,
      branchIndex,
    ]);
    const nextSortItems: SortModules = objectPath.update(
      this.state.sortItems,
      [...path, index],
      (item) => {
        return item.filter((_: any, index: number) => index !== branchIndex);
      }
    ) as any;
    this.setState({
      sortItems: nextSortItems,
      moduleItems: this.state.moduleItems.filter(
        (i) => deleteIds.indexOf(i.id) === -1
      ),
    });
  };

  handleChangeLabel = (id: number, label: string) => {
    this.setState({
      moduleItems: this.state.moduleItems.map((i) =>
        i.id === id ? { ...i, label } : i
      ),
    });
  };

  handleChangeNote = (id: number, note: string) => {
    this.setState({
      moduleItems: this.state.moduleItems.map((i) =>
        i.id === id ? { ...i, note } : i
      ),
    });
  };

  handleSave = (e: React.MouseEvent) => {
    e.stopPropagation();
    updateFlow({
      /* @ts-ignore */
      items: this.state.moduleItems.map((i) => ({
        id: i.id,
        type: i.type,
        label: i.label,
        note: i.note,
      })),
      sort: this.state.sortItems,
    });
    if (
      !this.props.allModules &&
      this.state.moduleItems.filter((i) => AI_MODULES.includes(i.type)).length >
        1
    ) {
      this.props.addErrorMessage("license_without_more_ai_modules");
    }
  };

  handleClickCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.history.push("/modules/list/");
  };

  isChanged() {
    return (
      !deepCompare(this.state.sortItems, this.props.sortItems) ||
      !deepCompare(this.state.moduleItems, this.props.moduleItems)
    );
  }

  findIds(obj: any) {
    if (Array.isArray(obj)) {
      return obj.reduce((sum, branch) => {
        return [
          ...sum,
          ...branch.reduce(
            /* @ts-ignore */
            (sum2, item) => [...sum2, ...this.findIds(item)],
            []
          ),
        ];
      }, []);
    }
    return [obj];
  }

  // eslint-disable-next-line class-methods-use-this
  isArrayOfArray(array: any[]) {
    return Array.isArray(array) && Array.isArray(array[0]);
  }

  render() {
    const _ = this.props.t;
    return (
      <>
        <NavigationPrompt
          message={_("changes_not_save")}
          when={this.isChanged()}
        />
        <ModuleWrap title={_("drawer_modules")}>
          <div style={styles.wrap}>
            <div style={styles.saveButtonWrap}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleClickCancel}
              >
                {_("cancel")}
              </Button>{" "}
              {this.isChanged() && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSave}
                >
                  {_("save_changes")}
                </Button>
              )}
            </div>
            <div style={styles.builder}>
              <div>
                <Builder
                  disableCodeModule={this.props.disableCodeModule}
                  path={[]}
                  isFirst
                  onChangeLabel={this.handleChangeLabel}
                  onChangeNote={this.handleChangeNote}
                  onAdd={this.handleAdd}
                  onAddBranch={this.handleAddBranch}
                  onDeleteBranch={this.handleDeleteBranch}
                  onDelete={this.handleDelete}
                  onMoveUp={this.handleMoveUp}
                  onMoveDown={this.handleMoveDown}
                  onMoveLeft={this.handleMoveLeft}
                  onMoveRight={this.handleMoveRight}
                  isMostLeft
                  isMostRight
                  items={sortModules(
                    this.state.sortItems,
                    this.state.moduleItems,
                    null,
                    true
                  )}
                />
              </div>
            </div>
          </div>
        </ModuleWrap>
      </>
    );
  }
}

export default withI18n(FlowView);
