import React, { FC } from "react";
import TableMaterial from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import useKeyPress from "./ImageListWithMarkers/Dashboard/useKeyPress";
import { makeStyles } from "tss-react/mui";

const styles = {
  wrap: {
    overflowX: "auto",
  },
} as const;

type Props = {
  headerItems: (string | null | JSX.Element)[];
  onClickItem?: (item: number) => void;
  bodyItems: (string | JSX.Element | JSX.Element[] | null)[][];
  onSelect?: (indexes: number[]) => void;
  selected?: number[];
  disabledShortcuts?: boolean;
};

const useStyles = makeStyles()({
  selected: {
    backgroundColor: "#039be536!important",
  },
});

const Table: FC<Props> = ({
  headerItems,
  bodyItems,
  onClickItem,
  onSelect,
  selected,
  disabledShortcuts,
}) => {
  const { classes } = useStyles();
  useKeyPress.cb(
    "a",
    () => {
      if (onSelect && !disabledShortcuts) {
        const items = [...Array(bodyItems.length)].map((u, i) => i);
        onSelect(items);
      }
    },
    [],
    true
  );

  return (
    <div style={styles.wrap}>
      <TableContainer>
        <TableMaterial>
          <TableHead>
            <TableRow>
              {headerItems.map((item, i) => (
                <TableCell
                  align={i === headerItems.length - 1 ? "right" : "left"}
                  key={i}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyItems.map((lineItem, lineItemIndex) => (
              <TableRow
                hover
                classes={{ selected: classes.selected }}
                selected={selected?.includes(lineItemIndex)}
                key={lineItemIndex}
              >
                {lineItem.map((rowItem, rowItemIndex) => (
                  <TableCell
                    style={{ whiteSpace: "nowrap" }}
                    onClick={(e) => {
                      if (onSelect && (e.ctrlKey || e.metaKey)) {
                        if (selected?.includes(lineItemIndex)) {
                          onSelect(
                            (selected || []).filter((i) => i !== lineItemIndex)
                          );
                        } else {
                          onSelect([...(selected || []), lineItemIndex]);
                        }
                      } else if (onClickItem) {
                        onClickItem(lineItemIndex);
                      }
                    }}
                    key={rowItemIndex}
                    align={
                      rowItemIndex === lineItem.length - 1 ? "right" : "left"
                    }
                  >
                    {rowItem}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableMaterial>
      </TableContainer>
    </div>
  );
};

export default Table;
