import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import store from "./store/createStore";
import i18n from "./i18n/i18n";
import routes from "./routes";

(window as any)._ = i18n;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App store={store} routes={routes} />
  </React.StrictMode>
);
