import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import DetailDialog from "./DetailDialog";
import Confirm from "../../../components/Confirm";
import { PropsFromRedux } from "../ListContainer";
import { OutputItem } from "../../../store/database/reducer";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import DiagnosticsDialog from "./DiagnosticsDialog";

const useStyles = makeStyles()({
  table: {
    minWidth: 650,
    padding: 10,
  },
  actionButton: {
    marginTop: -19,
    marginBottom: -19,
  },
});

const ListView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const history = useHistory();
  const { classes } = useStyles();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDiagnostics, setShowDiagnostics] = useState(false);
  const [showDetailDialog, setShowDetailDialog] = useState<OutputItem | null>(
    null
  );
  const [deleteConfirm, setShowDeleteConfirm] = useState<null | number>(null);

  useEffect(() => {
    if (props.disabled) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowDialog = () => setShowAddDialog(true);

  const handleClickDetail = (item: OutputItem) => setShowDetailDialog(item);

  const confirmHandleClickNo = () => setShowDeleteConfirm(null);

  const confirmHandleClickYes = () => {
    props.setOutputItems(props.items.filter((i) => i.id !== deleteConfirm));
    setShowDeleteConfirm(null);
  };

  const handleCloseDetail = () => {
    if (showDetailDialog) {
      setShowDetailDialog(null);
    } else {
      setShowAddDialog(false);
    }
  };

  const handleClickDelete = (id: number) => setShowDeleteConfirm(id);

  const handleSave = (values: OutputItem) => {
    if (showDetailDialog) {
      // update detail
      setShowDetailDialog(null);
      props.setOutputItems(
        props.items.map((i) => (i.id === values.id ? values : i))
      );
    } else {
      // create detail
      const newItem = {
        ...values,
      };
      props.setOutputItems([...props.items, newItem]);
      setShowAddDialog(false);
    }
  };

  return (
    <>
      <TableContainer style={{ padding: 10 }}>
        {props.items.length === 0 && (
          <Alert severity="info" sx={{ mb: 1 }}>
            {_("no_items_info")}
          </Alert>
        )}
        <Button
          onClick={handleClickShowDialog}
          color="primary"
          variant="contained"
        >
          {_("add")}
        </Button>
        {props.enableMXIO && (
          <Button
            sx={{ marginLeft: 1 }}
            onClick={() => setShowDiagnostics(true)}
            variant="contained"
            color="secondary"
          >
            {_("diagnostics")}
          </Button>
        )}
        {props.items.length > 0 && (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">{_("name")}</TableCell>
                <TableCell align="right">{_("active")}</TableCell>
                <TableCell align="right">{_("action_type")}</TableCell>
                <TableCell align="right">{_("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.items
                .filter((i) => i.action !== "gpio" || props.enableGPIO)
                .filter((i) => i.action !== "mxio" || props.enableMXIO)
                .map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.active ? "yes" : "no"}
                    </TableCell>
                    <TableCell align="right">{row.action || ""}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleClickDetail(row)}
                        className={classes.actionButton}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDelete(row.id)}
                        className={classes.actionButton}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        <DetailDialog
          open={showAddDialog || !!showDetailDialog}
          defaultValues={showDetailDialog}
          onSave={handleSave}
          onClose={handleCloseDetail}
          cloudSave={props.cloudSave}
          enableGPIO={props.enableGPIO}
          enableMXIO={props.enableMXIO}
        />
        <DiagnosticsDialog
          open={showDiagnostics}
          onClose={() => setShowDiagnostics(false)}
        />
      </TableContainer>
      <Confirm
        message={_("remove_item_confirm")}
        open={!!deleteConfirm}
        onClickNo={confirmHandleClickNo}
        onClickYes={confirmHandleClickYes}
      />
    </>
  );
};

export default ListView;
