import React, { FC } from "react";
import rollingImg from "./Rolling.svg";
import rollingWhiteImg from "./RollingWhite.svg";

const styles = {
  img: {
    width: 40,
    height: 40,
  },
} as const;

type Props = {
  style?: any,
  className?: string,
  white?: boolean
}

const MiniLoader:FC<Props> = ({ style, className, white }) => (
  <img
    src={white ? rollingWhiteImg : rollingImg}
    style={{ ...styles.img, ...(style || {}) }}
    alt="loader"
    className={className}
  />
);

export default MiniLoader;
