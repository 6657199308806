import React, { useState } from "react";
import { Rect, Transformer, Group } from "react-konva";
import Label from "./Label";
import Konva from "konva";
import { isRightClick } from "../utils";

type ShapeProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  id: number;
  rotate?: number;
};

type Props = {
  shapeProps: ShapeProps;
  isSelected: boolean;
  onSelect: () => void;
  onChange: (values: ShapeProps) => void;
  listening?: boolean;
  color?: [number, number, number];
  scale: number;
  selectable?: boolean;
  moveable?: boolean;
  disabled?: boolean;
  text?: string;
  minSize?: number;
  keepRatio?: boolean;
  showCircle?: boolean;
  rotate?: boolean;
  transparent?: boolean;
  onReset?: () => void;
};

const Rectangle = ({
  shapeProps,
  isSelected,
  onChange,
  color,
  listening,
  scale,
  ...props
}: Props) => {
  const shapeRef = React.useRef<Konva.Rect>(null);
  const trRef = React.useRef<Konva.Transformer>(null);
  const [localShapeProps, setLocalShareProps] =
    useState<ShapeProps>(shapeProps);
  const minSize = props.minSize || 5;

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      if (trRef.current && shapeRef.current) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [isSelected]);

  React.useEffect(() => {
    setLocalShareProps(shapeProps);
  }, [shapeProps]);

  const onSelect =
    !props.disabled && props.selectable !== false ? props.onSelect : undefined;
  const draggable = !props.disabled && props.moveable !== false;
  const stroke =
    isSelected && !draggable
      ? "#5ebeff"
      : color
      ? `rgb(${color[0]},${color[1]},${color[2]},0.8)`
      : `rgba(120,255,0,0.8)`;
  const fill = color
    ? `rgb(${color[0]},${color[1]},${color[2]},0.1)`
    : `rgba(120,255,0,0.05)`;
  const invalidSize =
    Math.abs(localShapeProps.width) < minSize ||
    Math.abs(localShapeProps.height) < minSize;
  if (shapeProps.width === 0 || shapeProps.height === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Rect
        onClick={(e) => !isRightClick(e) && onSelect?.()}
        listening={listening}
        onTap={onSelect}
        ref={shapeRef}
        x={shapeProps.x}
        y={shapeProps.y}
        rotation={shapeProps.rotate}
        width={shapeProps.width}
        height={shapeProps.height}
        draggable={draggable}
        strokeWidth={(isSelected ? (draggable ? 0 : 2) : 1) / scale}
        stroke={stroke}
        fill={
          props.transparent
            ? undefined
            : invalidSize
            ? "rgba(255,0,0,0.2)"
            : fill
        }
        onDragMove={(e: any) => {
          setLocalShareProps({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onDragEnd={(e: any) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransform={(e) => {
          const node = shapeRef.current;
          if (!node) {
            return;
          }

          const scaleX = node?.scaleX();
          const scaleY = node?.scaleY();
          const rotate = node?.rotation();

          setLocalShareProps({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotate: rotate,
            // set minimal value
            width: node.width() * scaleX,
            height: node.height() * scaleY,
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          if (!node) {
            return;
          }

          const scaleX = node?.scaleX();
          const scaleY = node?.scaleY();
          const rotate = node?.rotation();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          node.rotate(0);
          if (
            Math.abs(node.width() * scaleX) < minSize ||
            Math.abs(node.height() * scaleY) < minSize
          ) {
            props.onReset?.();
          } else {
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              rotate: rotate,
              // set minimal value
              width: node.width() * scaleX,
              height: node.height() * scaleY,
            });
          }
        }}
      />

      <Group
        listening={false}
        x={localShapeProps.x + 2 / scale}
        y={localShapeProps.y + 2 / scale}
        rotation={shapeProps.rotate}
      >
        <Label y={0} x={0} text={props.text} scale={scale} />
      </Group>
      {isSelected && draggable && (
        <Transformer
          ref={trRef}
          keepRatio={props.keepRatio || false}
          ignoreStroke={true}
          preventDefault
          rotateEnabled={props.rotate || false}
          onMouseDown={(e) => e.evt.stopPropagation()}
          onMouseUp={(e) => e.evt.stopPropagation()}
        />
      )}
    </React.Fragment>
  );
};

export default Rectangle;
