import { connect, ConnectedProps } from "react-redux";
import UnifierListView from "./components/UnifierListView";
import { unifierSetActiveUnifierId } from "../../actions/actions";
import { getModule } from "../../utils/common";
import { UnifierModule } from "../../types/modules";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as UnifierModule;

  return {
    history: ownProps.history,
    moduleId,
    module,
    items: state.database.unifierItems,
    activeUnifierId: module.itemId,
    images: state.database.images,
    token: state.system.token,
  };
};

const connector = connect(mapStateToProps, { unifierSetActiveUnifierId });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UnifierListView);
