import React, { Component } from "react";
import deepEqual from "fast-deep-equal";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import StatisticsDialog from "./StatisticsDialog";
import { SERVER_URL } from "../../../config";
import DownloadReportDialog from "./DownloadReportDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import { defaultValues, FilterImage } from "../../../utils/sortFilterImages";
import TypeSettings from "./TypeSettings";
import ClassNameListAll from "../../../components/ClassNameListAll";
import { getNumberDictionary } from "../../../store/utils";
import { STATISTICS_DOC } from "../../../docLinks";
import { nameToValidPath } from "../../../utils/common";
import { PropsFromRedux } from "../StatisticsContainer";
import withI18n from "../../../utils/withI18n";
import { Image } from "../../../types/common";
import { getImageData } from "../../../actions/ImageService";
import { Button } from "@mui/material";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";

type State = {
  currentImage: Image | null;
  showReportDialog: boolean;
  showStatsDialog: boolean;
  showAutoClassifier: boolean;
  selectedIds: number[];
  images: any[];
};

type Props = PropsFromRedux & { t: (key: string) => string };

class StatisticsView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  constructor(props: Props) {
    super(props);
    const annotations = this.getAnnotations();
    this.state = {
      images: this.props.images.map(this.getImageItem(annotations)),
      currentImage: null,
      showReportDialog: false,
      showStatsDialog: false,
      showAutoClassifier: false,
      selectedIds: [],
    };
  }

  componentDidUpdate(prevProps: PropsFromRedux) {
    if (
      prevProps.images !== this.props.images ||
      this.props.values.annotations !== prevProps.values.annotations ||
      this.props.values.type !== prevProps.values.type
    ) {
      const annotations = this.getAnnotations();
      this.setState({
        images: this.props.images.map(this.getImageItem(annotations)),
      });
    }
  }

  checkEvaluation = async () => {
    if (this.props.values.type !== "evaluation") {
      return true;
    }
    const imageId = this.props.images[0]?.id;
    const flow = this.context.helper.getSettings({});
    const data = await getImageData(imageId, flow, this.context.helper.token);
    return data.data.result !== null;
  };

  handleClickStart = async (makeRequest: boolean) => {
    if (!(await this.checkEvaluation())) {
      this.props.addErrorMessage("statistics_without_evaluation_error");
    } else if (this.isEmpty()) {
      this.props.addErrorMessage("annotate_the_images_first");
    } else {
      if (makeRequest) {
        this.props.startStatistics();
      }
      this.setState({
        showStatsDialog: true,
      });
    }
  };

  handleClickReport = async () => {
    if (!(await this.checkEvaluation())) {
      this.props.addErrorMessage("statistics_without_evaluation_error");
    } else if (this.isEmpty()) {
      this.props.addErrorMessage("annotate_the_images_first");
    } else {
      this.setState({ showReportDialog: true });
    }
  };

  handleClickDownloadReport = ({
    name,
    testingOnly,
    maxImages,
    maxImageSize,
    showRecallPrecision,
    showProcessingTime,
    showConfusionMatrix,
    showModules,
    language,
  }: {
    name: string;
    testingOnly: boolean;
    maxImages: number;
    maxImageSize: number;
    showRecallPrecision: boolean;
    showProcessingTime: boolean;
    showConfusionMatrix: boolean;
    showModules: boolean;
    language: string;
  }) => {
    const filename = nameToValidPath(name) + ".html";

    this.props.downloadFile(
      `${SERVER_URL}/get_report_data_html?title=${encodeURIComponent(
        name
      )}&token=${this.context.helper.token}` +
        `&testingOnly=${testingOnly}&maxImages=${maxImages}&maxImageSize=${maxImageSize}` +
        `&showRecallPrecision=${showRecallPrecision}` +
        `&showProcessingTime=${showProcessingTime}` +
        `&showModules=${showModules}` +
        `&showConfusionMatrix=${showConfusionMatrix}` +
        `&language=${language}` +
        `&filename=${encodeURIComponent(filename)}`,
      ""
    );
    this.setState({
      showReportDialog: false,
    });
  };

  handleClickResetModuleValues = () => {
    this.props.setStatisticsAnnotations({ include: [], ok: [], nok: [] });
  };

  getAnnotations = () => {
    return {
      ok: getNumberDictionary(this.props.values.annotations.ok || []),
      nok: getNumberDictionary(this.props.values.annotations.nok || []),
      include: getNumberDictionary(this.props.values.annotations.include || []),
    };
  };

  getImageItem =
    (annotations: {
      ok: {
        [n: number]: true;
      };
      nok: {
        [n: number]: true;
      };
      include: {
        [n: number]: true;
      };
    }) =>
    (i: Image): FilterImage => {
      if (this.props.values.type !== "evaluation") {
        const include = !!annotations.include[i.id];
        return {
          ...i,
          isMarked: include,
          label2: `${include ? this.props.t("statistics_include") : ""}`,
        };
      }
      const ok = !!annotations.ok[i.id];
      const nok = !!annotations.nok[i.id];
      return {
        ...i,
        isOk: ok,
        isNok: nok,
        isMarked: ok || nok,
        label2: `${ok ? this.props.t("ok") : ""}${
          nok ? this.props.t("ng") : ""
        } `,
      };
    };

  isEmpty() {
    const { ok, nok, include } = this.props.values.annotations;
    if (this.props.values.type !== "evaluation") {
      return include.length === 0;
    }
    return ok.length === 0 && nok.length === 0;
  }

  render() {
    const { noImage } = this.props;
    const evaluationItems = [
      { id: "ok", label: this.props.t("ok") },
      { id: "nok", label: this.props.t("ng") },
    ];

    const { annotations } = this.props.values;

    const simpleItems = [
      { id: "include", label: this.props.t("statistics_include") },
    ];

    const evaluationType = this.props.values.type === "evaluation";

    const settings = this.context.helper.getSettings({});
    const currentStats = this.props.statisticsResult.find(
      (i) =>
        deepEqual(settings, i.flow) &&
        deepEqual(i.annotations, annotations) &&
        i.type === this.props.values.type
    );

    // For debug
    // if (this.props.statisticsResult.length > 0) {
    //   settings.map((item: any, index: number) => {
    //     if (!deepEqual(item, this.props.statisticsResult[0].flow[index])) {
    //       console.log(
    //         "Invalid",
    //         item,
    //         this.props.statisticsResult[0].flow[index]
    //       );
    //     }
    //   });
    // }

    const disabledKeyListener =
      this.state.showReportDialog ||
      this.state.showStatsDialog ||
      this.state.showAutoClassifier;

    return (
      <ModuleWrap
        title={this.props.t("report")}
        noImage={noImage}
        docId={STATISTICS_DOC}
      >
        <ImageListWithMarkers
          ImageListProps={{
            showSelectedFilter: true,
            selectMore: true,
            onSelectMore: (ids) =>
              this.setState({
                selectedIds: ids,
              }),
            images: this.state.images,
          }}
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          disabledKeyListener={disabledKeyListener}
          moduleId={null}
          onSelectImage={(currentImage) => {
            this.setState({ currentImage });
          }}
          readOnly
          withMask
        >
          <ModuleRightToolPanel>
            <ModuleSettingsWrap title={this.props.t("type")}>
              <TypeSettings
                value={this.props.values.type}
                onChange={this.props.setStatisticsType}
              />
            </ModuleSettingsWrap>
            <ModuleSettingsWrap title={this.props.t("image_annotation")}>
              <ClassNameListAll
                onChangeShowAutoClassifier={(showAutoClassifier) =>
                  this.setState({ showAutoClassifier })
                }
                disabledKeyListener={disabledKeyListener}
                items={evaluationType ? evaluationItems : simpleItems}
                values={this.props.values.annotations}
                selectedIds={this.state.selectedIds}
                currentImage={this.state.currentImage || undefined}
                onChange={(data) => {
                  this.props.setStatisticsAnnotations({
                    ...this.props.values.annotations,
                    ...data,
                  });
                }}
                images={this.props.images}
              />
            </ModuleSettingsWrap>
            <ModuleSettingsWrap>
              <Button
                style={{ marginTop: 0 }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.handleClickStart(!currentStats)}
              >
                {this.props.t("show_result")}
              </Button>
              <Button
                style={{ marginTop: 8 }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleClickReport}
              >
                {this.props.t("download_report")}
              </Button>
            </ModuleSettingsWrap>
          </ModuleRightToolPanel>
        </ImageListWithMarkers>
        <StatisticsDialog
          evaluationType={evaluationType}
          open={this.state.showStatsDialog}
          onClose={() => this.setState({ showStatsDialog: false })}
          stats={currentStats?.summary}
          images={
            currentStats
              ? currentStats.images.filter(
                  (i) =>
                    annotations.ok.indexOf(i.id) !== -1 ||
                    annotations.nok.indexOf(i.id) !== -1
                )
              : []
          }
          onFilter={(imageIds) => {
            this.props.setFilter("/report/", { ...defaultValues, imageIds });
            this.setState({
              showStatsDialog: false,
            });
          }}
        />
        <DownloadReportDialog
          evaluationType={evaluationType}
          open={this.state.showReportDialog}
          onClose={() => this.setState({ showReportDialog: false })}
          projectName={this.props.projectName}
          lang={this.props.lang}
          onSubmit={this.handleClickDownloadReport}
        />
      </ModuleWrap>
    );
  }
}

export default withI18n(StatisticsView);
