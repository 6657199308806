import React, { FC, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import { Modules, typeToLabel } from "../../../modules";
import useI18n from "../../../hooks/useI18n";

const styles = {
  wrap: {
    // height: 150,
    width: 190,
    margin: "20px 10px",
    whiteSpace: "nowrap",
    position: "relative",
    padding: 13,
  },
  iconDelete: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  text: {
    display: "block",
    width: 140,
  },
  textArea: {
    display: "block",
  },
  arrowDownWrap: {
    zIndex: 3,
    position: "relative",
  },
} as const;

const useStyles = makeStyles()({
  moveVerticalActions: {
    position: "absolute",
    right: -48,
    top: 2,
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },
  moveHorizontalActions: {
    position: "absolute",
    left: -48,
    top: 2,
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },
});

type Props = {
  label?: string;
  note?: string;
  type: Modules;
  isFirst: boolean;
  isLast: boolean;
  isMostLeft: boolean;
  isMostRight: boolean;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  onChangeLabel: (text: string) => void;
  onChangeNote: (text: string) => void;
};

const Module: FC<Props> = (props) => {
  const { classes } = useStyles();
  const [_] = useI18n();
  const [hover, setHover] = useState(false);

  const handleClickRemove = () => {
    props.onRemove();
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    props.onChangeLabel(e.target.value);
  };

  const handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    props.onChangeNote(e.target.value);
  };

  const { label, type, note } = props;
  return (
    <>
      <div style={styles.arrowDownWrap}>
        <ArrowDownIcon
          style={{
            color: "rgb(153, 153, 153)",
            position: "absolute",
            left: "calc(50% - 12px)",
            bottom: -28,
          }}
        />
      </div>
      <Paper
        elevation={hover ? 5 : 3}
        style={styles.wrap}
        square
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <TextField
          style={styles.text}
          fullWidth
          variant="standard"
          placeholder={typeToLabel(type)}
          value={label}
          onChange={handleChangeLabel}
        />
        <TextField
          style={styles.textArea}
          variant="standard"
          placeholder={_("note")}
          value={note}
          fullWidth
          margin="dense"
          multiline
          onChange={handleChangeNote}
        />
        <IconButton
          style={styles.iconDelete}
          onClick={handleClickRemove}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        {hover && (
          <>
            <div className={classes.moveVerticalActions}>
              {!props.isFirst && (
                <IconButton onClick={props.onMoveUp} size="large">
                  <ArrowUpIcon />
                </IconButton>
              )}
              {!props.isLast && (
                <IconButton onClick={props.onMoveDown} size="large">
                  <ArrowDownIcon />
                </IconButton>
              )}
            </div>
            <div className={classes.moveHorizontalActions}>
              {!props.isMostLeft && (
                <IconButton onClick={props.onMoveLeft} size="large">
                  <ArrowLeftIcon />
                </IconButton>
              )}
              {!props.isMostRight && (
                <IconButton onClick={props.onMoveRight} size="large">
                  <ArrowRightIcon />
                </IconButton>
              )}
            </div>
          </>
        )}
      </Paper>
    </>
  );
};

export default Module;
