import React, { FC, useCallback, useRef } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import useI18n from "../../hooks/useI18n";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
  },
  typography: {
    padding: theme.spacing(2),
  },
  color: {
    width: 50,
    height: 32,
    border: `solid 1px ${grey[200]}`,
    position: "absolute",
    marginTop: -42,
    marginLeft: 13,
    pointerEvents: "none",
  },
}));

type Props = {
  onChange: (file: File) => void;
  label: string;
  selected?: boolean;
  accept?: string;
};

const SelectFile: FC<Props> = ({ onChange, label, accept }) => {
  const [t] = useI18n();
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickSelect = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        onChange(file);
      }
    },
    [onChange]
  );

  return (
    <div className={classes.root}>
      <Typography variant="body2" gutterBottom>
        {label}
      </Typography>
      <Button onClick={handleClickSelect} variant="outlined">
        {t("select_file")}
      </Button>
      <input
        accept={accept}
        ref={inputRef}
        onChange={handleSelectFile}
        type="file"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default SelectFile;
