import React, { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "tss-react/mui";
import NoImageInfo from "../NoImageInfo";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import getDoc from "../../docLinks";
import useI18n from "../../hooks/useI18n";
import { Modules } from "../../modules";
import { CONTENT_HEADER_COLOR } from "../../theme";
import { Fade } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  wrap: {
    position: "relative",
    minHeight: "100vh",
    height: "100vh",
    overflow: "auto",
    zIndex: 1,
  },
  wrapLeft: {
    //   width: 270,
    float: "left",
    // marginLeft: -10,
    //marginRight: -10,
  },
  title: {
    height: 48,
    lineHeight: "48px",
    display: "inline-block",
    color: "white",
    padding: "0 20px",
    fontWeight: 600,
    overflow: "hidden",
  },
  icon: {
    position: "relative",
    top: 2,
    paddingLeft: 20,
    marginRight: -10,
    color: theme.palette.primary.light,
  },
  tabs: {
    width: 300,
    display: "inline-block",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  spacing: {
    flex: 1,
  },
  tab: {
    color: "white",
    backgroundColor: CONTENT_HEADER_COLOR,
  },
  activeLabel: {
    color: "white",
  },
  activeIcon: {
    color: `${theme.palette.primary.main}!important`,
  },
  tabIndicator: {
    height: 3,
  },
}));

export type Props = {
  renderTabs?: () => any;
  title?: string;
  noImage?: boolean;
  menuItems?: { path: string; label: string }[];
  onChangeActive?: (active: boolean) => void;
  active?: boolean;
  docId?: string;
  left?: boolean;
  icon?: any;
  className?: string;
  children: React.ReactNode;
  type?: Modules;
  extraCheckbox?: {
    label: string;
    active: boolean;
    onChange: (active: boolean) => void;
    disabled?: boolean;
  };
  front?: boolean;
  onClose?: () => void;
};

const ModuleWrap: FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { classes, cx } = useStyles();
  const [_] = useI18n();
  const [hover, setHover] = useState(false);
  const handleChange = (value: string) => {
    history.push(value);
  };

  const { icon, menuItems, left } = props;
  return (
    <Paper
      elevation={3}
      square
      style={{ zIndex: props.front ? 2 : 1 }}
      className={cx(left && classes.wrapLeft, classes.wrap)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={classes.header}
        style={{ backgroundColor: CONTENT_HEADER_COLOR }}
      >
        {icon && <span className={classes.icon}>{icon}</span>}
        <span className={classes.title}>
          {props.title || (props.type ? _(props.type.toLowerCase()) : "")}
        </span>
        {menuItems && (
          <Tabs
            indicatorColor="primary"
            onChange={(e, value) => handleChange(value)}
            value={location.pathname}
            classes={{
              indicator: classes.tabIndicator,
            }}
          >
            {menuItems.map((i) => (
              <Tab className={classes.tab} label={i.label} value={i.path} />
            ))}
          </Tabs>
        )}
        {props.renderTabs && props.renderTabs()}
        <div className={classes.spacing} />
        {props.onChangeActive && (
          <FormControlLabel
            className={classes.activeLabel}
            control={
              <Checkbox
                checked={props.active}
                onChange={(e) => props.onChangeActive?.(e.target.checked)}
                classes={{ root: classes.activeIcon }}
              />
            }
            label={_("active")}
          />
        )}
        {props.extraCheckbox && (
          <FormControlLabel
            className={classes.activeLabel}
            control={
              <Checkbox
                checked={props.extraCheckbox.active}
                disabled={props.extraCheckbox.disabled}
                onChange={(e) =>
                  props.extraCheckbox?.onChange(e.target.checked)
                }
                classes={{ root: classes.activeIcon }}
              />
            }
            label={props.extraCheckbox.label}
          />
        )}
        {!props.left && props.docId && (
          <IconButton
            target="_blank"
            href={getDoc(props.docId)}
            className={props.className}
            style={{
              color: "white",
            }}
            size="large"
          >
            <HelpIcon />
          </IconButton>
        )}
        {props.onClose && (
          <Fade in={hover}>
            <IconButton
              className={props.className}
              style={{
                color: "#aaa",
              }}
              size="large"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Fade>
        )}
      </div>
      {props.noImage ? <NoImageInfo /> : props.children}
    </Paper>
  );
};

export default React.memo(ModuleWrap);
