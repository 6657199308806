import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import useI18n from "../hooks/useI18n";
import ModuleSettingsWrap from "./ModuleSettingsWrap";
import { AnyModel } from "../types/modules";
import ModelInfoDialog from "./ModelInfoDialog";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  content: {
    padding: 0,
  },
  modelName: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

type Props = {
  model: AnyModel;
  children?: React.ReactElement;
};

const ChangeModelPanel: FC<Props> = (props) => {
  const [_] = useI18n();
  const { classes } = useStyles();
  const [showModelsList, setShowModelsList] = useState(false);
  const [showModelInfo, setShowModelInfo] = useState(false);

  useEffect(() => {
    setShowModelsList(false);
  }, [props.model.modelId]);

  return (
    <>
      <ModuleSettingsWrap title={_("model")}>
        <Grid
          wrap="nowrap"
          container
          direction="column"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item>
            <Typography
              className={classes.modelName}
              onClick={() => setShowModelInfo(true)}
            >
              {props.model.name}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setShowModelsList(true)}
            >
              {_("change")}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={showModelsList}
          maxWidth="xl"
          onClose={() => setShowModelsList(false)}
          scroll="paper"
        >
          <DialogTitle>Select model</DialogTitle>
          <DialogContent className={classes.content}>
            {props.children}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModelsList(false)}>
              {_("close")}
            </Button>
          </DialogActions>
        </Dialog>
      </ModuleSettingsWrap>
      <ModelInfoDialog
        model={props.model}
        open={showModelInfo}
        onClose={() => setShowModelInfo(false)}
      />
    </>
  );
};

export default ChangeModelPanel;
