import { CircularProgress } from "@mui/material";
import logo from "../pekat_vision_eye_rgb_color.svg";
import { FC } from "react";

const styles = {
  wrap: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    padding: 20,
    background: "rgba(255,255,255, 0.5)",
  },
  wrapRelative: {
    position: "relative",
    background: "rgba(255,255,255, 0.5)",
  },
  img: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -75,
    marginTop: -75,
    color: "rgb(65, 71, 74)",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 48,
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
} as const;

type Props = {
  progress?: number | null;
  relative?: boolean;
  fadeIn?: boolean;
  withLogo?: boolean;
};

const Loader: FC<Props> = ({ progress, relative, fadeIn, withLogo }) => (
  <div
    className={fadeIn ? "fade-in" : ""}
    style={{
      ...styles.wrap,
      ...(relative ? styles.wrapRelative : {}),
    }}
  >
    <CircularProgress thickness={3} size={150} style={styles.img} />
    {progress !== null && progress !== undefined && (
      <span style={styles.progress}>{progress}%</span>
    )}
    {withLogo && (
      <div className={fadeIn ? "fade-in" : ""}>
        <img style={styles.logo} alt="loader" src={logo} />
      </div>
    )}
  </div>
);

export default Loader;
