import React from "react"
import { Line, Circle } from "react-konva";
import { Item } from "./Dashboard";

type Props = {
  scale: number,
  item: Item,
  mousePos: { x: number, y: number } | null,
  onFinish: () => void,
  color: string,
}

const Polygon = ({ item, scale, mousePos, onFinish, color }: Props) => {
  return (
    <>
      <Line
        points={item.points}
        stroke={item.eraser ? 'black' : color}
        strokeWidth={2 / scale}
        listening={false}
        globalCompositeOperation={'source-over'}
      />

      {mousePos && <>
        <Line
          stroke={'white'}
          strokeWidth={1 / scale}
          points={[
            item.points[item.points.length - 2],
            item.points[item.points.length - 1],
            mousePos.x,
            mousePos.y
          ]}
        />
        <Line
          stroke={'black'}
          dash={[4 / scale, 4 / scale]}
          strokeWidth={1 / scale}
          points={[
            item.points[item.points.length - 2],
            item.points[item.points.length - 1],
            mousePos.x,
            mousePos.y
          ]}
        />
      </>}
      <Circle
        width={7 / scale}
        height={7 / scale}
        fill={item.eraser ? 'black' : color}
        stroke={"white"}
        strokeWidth={2 / scale}
        x={item.points[0]}
        y={item.points[1]}
        onClick={() => onFinish()}
        onMouseEnter={e => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = "pointer";
          }
        }}
        onMouseLeave={e => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = "default";
          }
        }}
        onMouseUp={() => item.points.length > 4 && onFinish()}
      />
    </>
  );
};

export default Polygon