import React, { FC } from "react";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import { OUTPUT_DOC } from "../../../docLinks";
import useI18n from "../../../hooks/useI18n";

const WrapView: FC<{ children: React.ReactNode }> = (props) => {
  const [_] = useI18n();
  return (
    <ModuleWrap title={_("output")} docId={OUTPUT_DOC}>
      {props.children}
    </ModuleWrap>
  );
};

export default WrapView;
