import React from "react";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { makeStyles } from "tss-react/mui";
import useI18n from "../../../../hooks/useI18n";

const PORTS = [
  7, 11, 12, 13, 15, 16, 18, 19, 21, 22, 23, 24, 26, 29, 31, 32, 33, 35, 36, 37,
  38, 40,
];

const useStyles = makeStyles()({
  port: {
    width: 80,
  },
});

const GpioForm = () => {
  const [_] = useI18n();
  const { classes } = useStyles();
  return (
    <>
      <Grid item>
        <FormLabel component="legend">{_("method")}</FormLabel>
        <Field name="signalEdge" component={RadioGroup} row>
          <FormControlLabel
            value="rising"
            control={<Radio color="primary" />}
            label="Rising edge (positive)"
          />
          <FormControlLabel
            value="failling"
            control={<Radio color="primary" />}
            label="Falling edge (negative)"
          />
        </Field>
      </Grid>
      <Grid item>
        <Field
          select
          className={classes.port}
          disabled={false}
          name="portNumber"
          label={_("port")}
          margin="dense"
          type="number"
          variant="outlined"
          component={TextField}
        >
          {PORTS.map((i) => (
            // @ts-ignore
            <MenuItem value={i} key={i}>
              {i}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </>
  );
};

export default GpioForm;
