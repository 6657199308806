import React, { FC, useContext } from "react";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import {
  PageToolbar,
  PageToolbarButton,
} from "../../../components/PageToolbar";
import { PropsFromRedux } from "../DetailContainer";
import useI18n from "../../../hooks/useI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import { Modules } from "../../../modules";

const ModelDetailView: FC<PropsFromRedux> = (props) => {
  const context = useContext(HelperContext);
  const [_] = useI18n();
  const getImageSrc = (imageId: number) =>
    context.helper.getImagePath({
      imageId,
      moduleId: props.moduleId,
      includeLast: false,
      otherModule: {
        id: -1,
        type: Modules.UNIFIER,
        itemId: props.template.itemId,
        editDate: props.template.editDate,
      },
    });

  const handleClickRedirect = (path: string) => {
    props.history.push(path);
  };

  if (!props.template) {
    return null;
  }

  return (
    <div>
      <PageToolbar>
        <PageToolbarButton
          onClick={(e) =>
            handleClickRedirect(`/modules/unifier/${props.moduleId}/`)
          }
          label={_("back")}
          icon={<LeftIcon />}
        />
        <PageToolbarButton
          label={_("unifier_edit")}
          onClick={(e) =>
            handleClickRedirect(
              `/modules/unifier/${props.moduleId}/${props.template.itemId}/`
            )
          }
          icon={<EditIcon />}
        />
      </PageToolbar>
      <ImageListWithMarkers
        moduleId={props.moduleId}
        readOnly
        getCurrentRectangles={() => []}
        getImageSrc={getImageSrc}
      />
    </div>
  );
};

export default ModelDetailView;
