import React, { FC, useContext, useState } from "react";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
  CONTENT_INNER_PADDING,
} from "../../../theme";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import { Modules, typeToIcon, typeToLabel } from "../../../modules";
import UtilsItems from "./UtilsItems/UtilsItems";
import { getImageData, getImageHistogram } from "../../../actions/ImageService";
import { PREPROCESS_DOC } from "../../../docLinks";
import { PropsFromRedux } from "../WrapContainer";
import useI18n from "../../../hooks/useI18n";
import { Image } from "../../../types/common";
import { Layer } from "./UtilsItems/types";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import { Paper } from "@mui/material";

const styles = {
  wrap: {
    padding: CONTENT_INNER_PADDING,
  },
  settingWrap: {
    zIndex: 1,
    width: 340,
    paddingLeft: CONTENT_INNER_PADDING,
    paddingRight: CONTENT_INNER_PADDING,
    paddingTop: CONTENT_INNER_PADDING,
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
} as const;

const MainView: FC<PropsFromRedux> = (props) => {
  const context = useContext(HelperContext);
  const moduleId = props.module.id;
  const [currentImage, setCurrentImage] = useState<Image | null>(null);
  const [currentLayer, setCurrentLayer] = useState<null | Layer>(null);
  const [_] = useI18n();

  const rectanglesFlow = context.helper.getSettings({
    moduleId,
    otherModule: {
      id: moduleId,
      type: Modules.PREPROCESS,
      isActive: true,
      editDate: props.module.editDate,
      subLayer: currentLayer ? currentLayer.layer : -1,
      numberOfItems: currentLayer ? currentLayer.index + 1 : null,
    },
  });

  return (
    <ModuleWrap
      key={props.module.id}
      docId={PREPROCESS_DOC}
      icon={typeToIcon(Modules.PREPROCESS)}
      title={props.module.label || _(typeToLabel(Modules.PREPROCESS))}
      noImage={props.noImage}
      active={props.module.isActive}
      onChangeActive={(isActive) =>
        props.setModuleValue({
          moduleId,
          name: "isActive",
          value: isActive,
        })
      }
    >
      <ImageListWithMarkers
        childrenOffset={340}
        key={props.module.isActive ? "1" : "0"} // TODO hot fix - https://pekatvision.atlassian.net/browse/DV-442
        getImageSrc={() => {
          if (!currentImage) {
            return "";
          }
          if (!props.module.isActive) {
            return context.helper.getImagePath({
              imageId: currentImage.id,
              includeLast: false,
              withMask: true,
              moduleId,
            });
          }

          return context.helper.getImagePath({
            imageId: currentImage.id,
            moduleId,
            withMask: true,
            otherModule: {
              id: moduleId,
              type: Modules.PREPROCESS,
              isActive: true,
              editDate: props.module.editDate,
              subLayer: (currentLayer ? currentLayer.layer : -1) as any,
              numberOfItems: currentLayer ? currentLayer.index + 1 : null,
            },
          });
        }}
        rectanglesFlow={rectanglesFlow}
        withMask
        detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
        listHeight={CON_MIN_HEIGHT_HEADER}
        showSecondImage
        moduleId={moduleId}
        readOnly
        onSelectImage={setCurrentImage}
      >
        <Paper square style={styles.settingWrap}>
          {currentImage && (
            <UtilsItems
              layers={props.module.items}
              onChangeLayer={setCurrentLayer}
              currentLayer={currentLayer}
              active={props.module.isActive}
              addErrorMessage={props.addErrorMessage}
              imageId={currentImage.id}
              getImageData={(numberOfItems) => {
                const flow = context.helper.getSettings({
                  moduleId,
                  otherModule: {
                    id: moduleId,
                    type: Modules.PREPROCESS,
                    isActive: true,
                    editDate: Date.now(),
                    numberOfItems,
                    processing: 0,
                  },
                });
                return getImageData(
                  currentImage.id,
                  flow,
                  context.helper.token
                );
              }}
              moduleId={moduleId}
              getImageFlow={(numberOfItems) => {
                return context.helper.getSettings({
                  moduleId,
                  otherModule: {
                    id: moduleId,
                    type: Modules.PREPROCESS,
                    isActive: true,
                    editDate: Date.now(),
                    numberOfItems,
                    processing: 0,
                  },
                });
              }}
              getImageHistogram={(numberOfItems) => {
                const flow = context.helper.getSettings({
                  moduleId,
                  otherModule: {
                    id: moduleId,
                    type: Modules.PREPROCESS,
                    isActive: true,
                    editDate: Date.now(),
                    numberOfItems,
                  },
                });
                return getImageHistogram(
                  currentImage.id,
                  flow,
                  context.helper.token
                );
              }}
              items={props.module.items || []}
              onChange={(items) => {
                props.setModuleValue({
                  moduleId,
                  name: "items",
                  value: items,
                });

                if (
                  currentLayer &&
                  items
                    .filter((i) => i.active)
                    .findIndex((i) => i.id === currentLayer.id) === -1
                ) {
                  setCurrentLayer(null);
                }
              }}
            />
          )}
        </Paper>
      </ImageListWithMarkers>
    </ModuleWrap>
  );
};

export default MainView;
