import React, { FC, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import ConnectErrorDialog from "../../components/ConnectErrorDialog";
import { checkToken, initConnection, login } from "../../actions/actions";
import Loader from "../../components/Loader/Loader";
import LoginDialog from "../../components/LoginDialog";
import DisconnectDialog from "../../components/DisconnectDialog";
import { themeV4 } from "../../theme";
import TokenContext from "../../contexts/TokenContext";
import QuestionProvider from "../../providers/Question";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { useSnackbar } from "notistack";
import useI18n from "../../hooks/useI18n";

const CoreLayout: FC<{ children: React.ReactNode }> = (props) => {
  const state = useAppSelector((state) => ({
    isInit: state.system.isInit,
    token: state.system.token,
    tryToken: state.system.tryToken,
    disconnect: state.system.disconnect,
    connectError: state.system.connectError,
    fatalError: state.system.fatalError,
    invalidPassword: state.system.invalidPassword,
    operatorOnly: state.system.operatorOnly,
    projectName: state.system.projectName,
  }));

  const dispatch = useAnyAppDispatch();
  const snackbar = useSnackbar();
  const [_] = useI18n();

  const sendSnackbar = (message: string | [string, ...string[]]) => {
    snackbar.enqueueSnackbar(
      Array.isArray(message) ? _(...message) : _(message)
    );
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const kioskUrl = search.get("adminUrl");
    if (kioskUrl) {
      sessionStorage.setItem("adminUrl", kioskUrl);
    }

    dispatch(
      initConnection(
        () =>
          checkToken(sessionStorage.getItem("token") || search.get("t") || ""),
        sendSnackbar
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isInit && state.projectName) {
      window.document.title = state.projectName;
    }
  }, [state.isInit, state.projectName]);

  useEffect(() => {
    const operatorPath = "/operator";
    if (state.operatorOnly && window.location.pathname !== operatorPath) {
      (window as any).location = operatorPath;
    }
  });

  if (!state.token && !state.tryToken) {
    return (
      <LoginDialog
        onLogin={(password: string) => dispatch(login(password))}
        invalidPassword={state.invalidPassword}
      />
    );
  }

  if (!state.isInit) {
    if (state.connectError || state.fatalError) {
      return (
        <ConnectErrorDialog open onRestart={() => window.location.reload()} />
      );
    }
    return <Loader withLogo fadeIn />;
  }

  return (
    <>
      <ThemeProvider theme={themeV4}>
        <QuestionProvider>
          <TokenContext.Provider value={{ token: state.token }}>
            {!state.disconnect && props.children}
            <DisconnectDialog open={state.disconnect} />
          </TokenContext.Provider>
        </QuestionProvider>
      </ThemeProvider>
    </>
  );
};

export default CoreLayout;
