import React from "react";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { deleteImage } from "../../store/images/reducer";
import { imagesDelete } from "../../actions/actions";
import Confirm from "../../components/Confirm";
import useI18n from "../../hooks/useI18n";

const EditNameDialogContainer = () => {

  const { deleteImages } = useAppSelector(state => ({
    deleteImages: state.images.deleteImages,
  }))

  const [_] = useI18n()
  const dispatch = useAnyAppDispatch()


  return (
    <Confirm
      title={_("delete_image")}
      message={_("are_you_sure")}
      open={!!deleteImages}
      onClickNo={() => dispatch(deleteImage(null))}
      onClickYes={() => {
        if (deleteImages) {
          dispatch(imagesDelete(deleteImages));
        }
        dispatch(deleteImage(null));
      }}
    />
  );
}

export default EditNameDialogContainer