import React from "react";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { setRename } from "../../store/images/reducer";
import { imageUpdateName } from "../../actions/actions";
import EditNameDialog from "../../components/EditNameDialog";

const EditNameDialogContainer = () => {

  const { renameImage } = useAppSelector(state => ({
    renameImage:
      state.images.renameImage !== null &&
      state.database.images.find((i) => i.id === state.images.renameImage),
  }))

  const dispatch = useAnyAppDispatch()

  return (
    <EditNameDialog
      label={renameImage ? renameImage.label : ""}
      open={!!renameImage}
      onClose={() => dispatch(setRename(null))}
      onUpdate={(name: string) => {
        if (renameImage) {
          dispatch(imageUpdateName({
            name,
            imageId: renameImage.id,
          }));
        }
        dispatch(setRename(null));
      }}
    />
  );
}

export default EditNameDialogContainer