import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { formatDateTime } from "../../utils/format";
import CreateDialog from "./CreateDialog";
import Table from "../Table";
import ImageList from "../ImageList/ImageListContainer";
import { CON_MIN_HEIGHT_HEADER, CONTENT_INNER_PADDING } from "../../theme";
import Confirm from "../Confirm";
import { AnyModuleItemUsed } from "../../types/modules";
import { HelperContext } from "../../layouts/PageLayout/PageLayout";
import { Alert } from "@mui/material";

const styles = {
  nameWrap: {
    marginLeft: 30,
  },
  createBtn: {
    marginBottom: 10,
  },
  button: {
    marginTop: -19,
    marginBottom: -19,
  },
  radio: {
    marginTop: -20,
    marginBottom: -20,
  },
  deleteSelected: {
    margin: "-4px 0px",
  },
  content: {
    paddingLeft: CONTENT_INNER_PADDING,
    paddingTop: CONTENT_INNER_PADDING,
    paddingRight: CONTENT_INNER_PADDING,
  },
};

type Props<T> = {
  items: T[];
  moduleId: number;
  onCreate: (values: { moduleId: number; name: string }) => void;
  onSetItem: (item: T | null) => void;
  onClickItem: (item: T) => void;
  isItemActive: (item: T | null) => boolean;
  isItemDisabled?: (item: T) => boolean;
  createTitle: string;
  renderItem?: (item: T) => any;
  renderButtons?: (item: T) => any;
  onClickDelete?: (item: T) => void;
  t: (key: string) => string;
};

type State = {
  showCreateDialog: boolean;
  showDeleteConfirm: boolean;
  deleteItem: null | any;
  selectedLines: number[];
};

class ModelList<T extends AnyModuleItemUsed> extends Component<
  Props<T>,
  State
> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    showCreateDialog: false,
    showDeleteConfirm: false,
    deleteItem: null,
    selectedLines: [],
  };

  handleClick = (e: React.MouseEvent, item: T) => {
    e.stopPropagation();
    this.props.onClickItem(item);
  };

  handleClickDelete = (item: T) => {
    this.props.onClickDelete?.(item);
  };

  handleClickOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      showCreateDialog: true,
    });
  };

  renderItem = (item: T) => {
    return [
      <>
        <Radio
          style={styles.radio}
          checked={this.props.isItemActive(item)}
          onChange={() => this.props.onSetItem(item)}
          disabled={
            this.props.isItemDisabled && this.props.isItemDisabled(item)
          }
        />
        <span style={styles.nameWrap}>
          <b>{item.name}</b>
        </span>
      </>,
      formatDateTime(item.creationDate),
      <>
        <Button
          onClick={(e) => this.handleClick(e, item)}
          style={styles.button}
          color="secondary"
        >
          {this.props.t("edit")}
        </Button>
        {this.props.renderButtons && this.props.renderButtons(item)}
        {this.props.onClickDelete && (
          <IconButton
            style={styles.button}
            disabled={this.props.isItemActive(item) || item.isUsed}
            onClick={(e) => {
              e.stopPropagation();
              if (!this.props.isItemActive(item)) {
                this.setState({ showDeleteConfirm: true, deleteItem: item });
              }
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </>,
    ];
  };

  modelsIndexes = (models: T[]) => {
    return this.state.selectedLines
      .filter((i) =>
        models.some(
          (m) => m.itemId === i && !(m.isUsed || this.props.isItemActive(m))
        )
      )
      .map(
        (itemId: number) => models.findIndex((i) => i.itemId === itemId) + 1
      );
  };

  handleClickDeleteSelected = (items: T[]) => {
    this.state.selectedLines.forEach((modelId) => {
      const item = items.find((m) => m.itemId === modelId);
      if (item && !(item.isUsed || this.props.isItemActive(item))) {
        this.props.onClickDelete?.(item);
      }
    });
  };

  render() {
    const _ = this.props.t;
    const items = ([...this.props.items] || []).reverse();
    const selectedIndexes = this.modelsIndexes(items);
    const bodyItems = items.map(
      this.props.renderItem ? this.props.renderItem : this.renderItem
    );
    const emptyLine = [
      <>
        <Radio style={styles.radio} checked={this.props.isItemActive(null)} />
        <span style={styles.nameWrap}>{_("none_active")}</span>
      </>,
      null,
      null,
    ];

    return (
      <div style={{ height: CON_MIN_HEIGHT_HEADER }}>
        <Confirm
          open={this.state.showDeleteConfirm}
          message={_("remove_item_confirm")}
          onClickNo={() =>
            this.setState({ showDeleteConfirm: false, deleteItem: null })
          }
          onClickYes={() => {
            this.setState({ showDeleteConfirm: false, deleteItem: null });
            if (this.state.deleteItem) {
              this.handleClickDelete(this.state.deleteItem);
            } else {
              this.handleClickDeleteSelected(items);
            }
          }}
        />
        <ImageList
          height={CON_MIN_HEIGHT_HEADER}
          getImageSrc={(image) => ({
            imageId: image.id,
            moduleId: this.props.moduleId,
            withMask: true,
          })}
          getImageSrc2={(image) => ({
            imageId: image.id,
            moduleId: this.props.moduleId,
            withMask: true,
            includeLast: true,
          })}
        >
          <div style={styles.content}>
            {this.props.items.length === 0 && (
              <Alert severity="info" sx={{ mb: 1 }}>
                {_("no_items_info")}
              </Alert>
            )}
            <Button
              color="primary"
              variant="contained"
              style={styles.createBtn}
              onClick={(e) => this.handleClickOpen(e)}
            >
              {_("create")}
            </Button>
            {this.props.items.length > 0 && (
              <Table
                headerItems={[
                  _("name"),
                  _("creation_date"),
                  selectedIndexes.length > 0 ? (
                    <Button
                      style={styles.deleteSelected}
                      onClick={() => this.setState({ showDeleteConfirm: true })}
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      {_("delete_selected")}
                    </Button>
                  ) : null,
                ]}
                bodyItems={[emptyLine, ...bodyItems]}
                onClickItem={(index) => {
                  const item = [...this.props.items].reverse()[index - 1];
                  if (index === 0) {
                    this.props.onSetItem(null);
                  } else if (
                    !this.props.isItemDisabled ||
                    !this.props.isItemDisabled(item)
                  ) {
                    this.props.onSetItem(item);
                  }
                }}
                selected={selectedIndexes}
                disabledShortcuts={this.state.showCreateDialog}
                onSelect={(indexes) =>
                  this.setState({
                    selectedLines: indexes
                      .filter((i) => i !== 0)
                      .map((i) => items[i - 1].itemId),
                  })
                }
              />
            )}
          </div>
        </ImageList>
        <CreateDialog
          open={this.state.showCreateDialog}
          title={this.props.createTitle}
          onClose={() => this.setState({ showCreateDialog: false })}
          onCreate={(name) => {
            this.setState({ showCreateDialog: false });
            this.props.onCreate({ moduleId: this.props.moduleId, name });
          }}
        />
      </div>
    );
  }
}

export default ModelList;
