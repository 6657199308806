import React from "react";
import IconResetZoom from "@mui/icons-material/ZoomOutMap";
import IconHelp from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import PatchSizeSlider, { WrapProps } from "../../PatchSize";
import { FC } from "react";
import { Tooltip } from "@mui/material";
import useI18n from "../../../hooks/useI18n";

const styles = {
  small: {
    width: 36,
    height: 36,
    padding: 0,
  },
  wrap: {
    display: "flex",
    flexDirection: "row-reverse",
    flexWrap: "nowrap",
    justifyContent: "flexStart",
    alignItems: "center",
    borderBottom: "1px solid #e0e0e0",
    height: 51,
    paddingRight: 12,
  },
} as const;

export type PatchSizeProps = Omit<WrapProps, "value" | "onChange">;

type Props = {
  onReset: () => void;
  onOpenHelper: () => void;
  onChangePatchSize?: (value: number) => void;
  patchSize: number;
  patchSizeProps?: PatchSizeProps;
};

const Utils: FC<Props> = (props) => {
  const {
    onReset,
    onOpenHelper,
    onChangePatchSize,
    patchSize,
    patchSizeProps,
  } = props;

  const [_] = useI18n();

  const handleReset = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onReset();
  };

  const handleOpenHelper = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onOpenHelper();
  };

  return (
    <div style={styles.wrap}>
      {onChangePatchSize && patchSizeProps && (
        <PatchSizeSlider
          value={patchSize}
          onChange={onChangePatchSize}
          {...patchSizeProps}
        />
      )}
      <Tooltip title={_("reset_zoom")}>
        <IconButton style={styles.small} onClick={handleReset} size="large">
          <IconResetZoom />
        </IconButton>
      </Tooltip>
      <Tooltip title={_("help")}>
        <IconButton
          style={styles.small}
          onClick={handleOpenHelper}
          size="large"
        >
          <IconHelp />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Utils;
