import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import logo from "./logoFull.svg";
import useI18n from "../hooks/useI18n";

const Logo = styled("img")({
  height: 50,
  marginBottom: 18,
  marginTop: 6,
  display: "block",
});

const TutorialWelcomeDialog = () => {
  const [open, setOpen] = useState(true);
  const [_] = useI18n();
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <DialogContentText>
          <Logo src={logo} />
          <Typography variant="h4" gutterBottom>
            {_("tutorial_welcome_title")}
          </Typography>
          <Typography>{_("tutorial_welcome_content")}</Typography>
          <Button
            sx={{ mt: 2 }}
            onClick={() => setOpen(false)}
            variant="outlined"
          >
            {_("start")}
          </Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TutorialWelcomeDialog;
