import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "tss-react/mui";
import ModuleWrap from "./ModuleWrap";
import ChevronRight from "@mui/icons-material/ChevronRight";
import useI18n from "../../hooks/useI18n";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { getModule } from "../../utils/common";
import ModuleNotFound from "../ModuleNotFound";
import { typeToIcon } from "../../modules";
import NoImageInfo from "../NoImageInfo";
import { CONTENT_HEADER_COLOR } from "../../theme";
import { moduleTypeToDoc } from "../../docLinks";

const useStyles = makeStyles()((theme) => ({
  tabIndicator: {
    height: 3,
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    color: "white",
    position: "relative",
    top: 12,
  },
}));

const WrapView: FC<{ children: React.ReactNode }> = (props) => {
  const { classes } = useStyles();
  const { moduleId } = useParams<{ moduleId: string }>();
  const { module, noImage } = useAppSelector((state) => ({
    module: getModule(state, parseInt(moduleId)),
    noImage: state.database.images.length === 0,
  }));
  const history = useHistory();
  const [_] = useI18n();

  const getLocation = () => {
    const { pathname } = window.location;
    if (pathname.indexOf("/validate/") !== -1) {
      return pathname.substr(
        0,
        pathname.indexOf("/validate/") + "/validate/".length
      );
    }
    return pathname;
  };

  const handleChange = (value: string) => {
    history.push(value);
  };

  if (!module) {
    return <ModuleNotFound />;
  }

  const modulePath = module.type.toLowerCase();

  return (
    <ModuleWrap
      type={module.type}
      icon={typeToIcon(module.type)}
      docId={moduleTypeToDoc(module.type)}
      renderTabs={() => (
        <Tabs
          onChange={(e, value) => handleChange(value)}
          value={getLocation()}
          classes={{
            indicator: classes.tabIndicator,
          }}
        >
          <Tab
            label={_("header_training")}
            style={{ backgroundColor: CONTENT_HEADER_COLOR, color: "white" }}
            value={`/modules/${modulePath}/${moduleId}/training/`}
          />
          <ChevronRight className={classes.icon} />
          <Tab
            label={_("header_inspect")}
            style={{ backgroundColor: CONTENT_HEADER_COLOR, color: "white" }}
            value={`/modules/${modulePath}/${moduleId}/validate/`}
          />
        </Tabs>
      )}
    >
      {noImage ? <NoImageInfo /> : props.children}
    </ModuleWrap>
  );
};

export default WrapView;
