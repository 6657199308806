import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import noImageWrap from "../../utils/noImageWrap";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { UnifierItem, UnifierModule } from "../../types/modules";
import { unifierUpdateName } from "../../actions/actions";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string; itemId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as UnifierModule;
  const template = state.database.unifierItems.find(
    (i) => i.itemId === +ownProps.match.params.itemId
  ) as UnifierItem;

  return {
    history: ownProps.history,
    images: state.database.images,
    module,
    template,
    noImage: state.database.images.length === 0,
    token: state.system.token,
  };
};

const connector = connect(mapStateToProps, { unifierUpdateName });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(noImageWrap(TrainingView));
