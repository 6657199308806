import React, { FC } from "react";
import { Theme } from '@mui/material/styles';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        position: 'absolute',
        whiteSpace: 'nowrap'
    }
}));

type LabelProps = {
    content: string,
    position: {
        x: number,
        y: number,
    },
    padding: number,
    backgroundColor: string,
    textColor: string,
    fontSize: number,
    style?: any,
}

const Label: FC<LabelProps> = ({ content, position, style, backgroundColor, textColor, fontSize, padding}) => {
    const { classes } = useStyles()

    const elementStyle = {
        left: position.x, top: position.y, padding, backgroundColor, color: textColor, fontSize, ...style
    }

    return (
       <div className={classes.root} style={elementStyle}>{content}</div>
    );
};

export default Label;
