import React, { FC, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import { useDebouncedCallback } from "use-debounce";
import Grid from "@mui/material/Grid";
import utilsTypes from "./utilsTypes";
import AddDialog from "./AddDialog";
import Card from "./Card";
import { PreproprecessModule } from "../../../../types/modules";
import useI18n from "../../../../hooks/useI18n";
import { AllTypes, Layer } from "./types";
import * as Flow from "../../../../types/flow";
import { Histrogram, ImageData } from "../../../../actions/ImageService";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import arrayMove from "array-move";
import SelectPreview from "../SelectPreview";
import { StrictModeDroppable } from "../../../../components/StrictModeDroppable";

const useStyles = makeStyles()((theme) => ({
  add: {
    marginBottom: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  itemsWrap: {
    overflowY: "auto",
    overflowX: "hidden",
    padding: "3px 3px 10px",
    height: "calc(100% - 78px)",
  },
  inactive: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

type Props = {
  items: PreproprecessModule["items"];
  onChange: (items: PreproprecessModule["items"]) => void;
  active: boolean;
  getImageData: (numberOfItems: number) => Promise<{ data: ImageData }>;
  getImageHistogram: (numberOfItems: number) => Promise<{ data: Histrogram }>;
  getImageFlow: (numberOfItems: number) => Flow.Flow;
  imageId: number;
  addErrorMessage: (text: string) => void;
  moduleId: number;
  layers: AllTypes[];
  onChangeLayer: (value: Layer | null) => void;
  currentLayer: Layer | null;
};

const Container: FC<Props> = ({
  items,
  onChange,
  active,
  getImageData,
  getImageHistogram,
  getImageFlow,
  imageId,
  addErrorMessage,
  moduleId,
  layers,
  onChangeLayer,
  currentLayer,
}) => {
  const [_] = useI18n();
  const [localItems, _changeItems] =
    useState<PreproprecessModule["items"]>(items);
  const { classes, cx } = useStyles();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeItems = (items: PreproprecessModule["items"]) => {
    _changeItems(items);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    debouncedCallback(items);
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    // function
    (items: PreproprecessModule["items"]) => {
      onChange(items);
    },
    // delay in ms
    1000
  );

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            onClick={() => setShowAddDialog(true)}
            color="primary"
            variant="contained"
            className={classes.add}
          >
            <AddIcon className={classes.leftIcon} />
            {_("add")}
          </Button>
        </Grid>
        <Grid item>
          <SelectPreview
            items={layers}
            onChange={onChangeLayer}
            value={currentLayer}
          />
        </Grid>
      </Grid>

      <div className={cx(!active && classes.inactive, classes.itemsWrap)}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            changeItems([
              ...arrayMove(
                localItems,
                result.source.index,
                result.destination.index
              ),
            ]);
          }}
        >
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {localItems.map((card, index) => (
                  <Draggable
                    key={card.id}
                    draggableId={card.id + ""}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Card
                        draggableProps={provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        ref={provided.innerRef}
                        index={index}
                        imageKey={`${imageId}` + JSON.stringify(items)}
                        onChange={(values) =>
                          changeItems(
                            localItems.map((k) =>
                              k.id === card.id
                                ? ({ ...k, active: true, values } as AllTypes)
                                : k
                            )
                          )
                        }
                        values={card.values}
                        type={card.type}
                        active={card.active}
                        onChangeActive={(active: boolean) => {
                          changeItems(
                            localItems.map((k) =>
                              k.id === card.id ? { ...k, active } : k
                            )
                          );
                          callPending();
                        }}
                        onDelete={() => {
                          changeItems(
                            localItems.filter((k) => k.id !== card.id)
                          );
                          callPending();
                        }}
                        getImageData={getImageData}
                        getImageFlow={getImageFlow}
                        getImageHistogram={getImageHistogram}
                        moduleId={moduleId}
                        expanded={expandedItem === card.id}
                        onChangeExpanded={(expanded) =>
                          setExpandedItem(expanded ? card.id : null)
                        }
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
      <AddDialog
        onClose={() => setShowAddDialog(false)}
        open={showAddDialog}
        onSelect={(type) => {
          const currentItem = utilsTypes.find((i) => i.id === type);
          const nextId = Date.now();
          if (currentItem) {
            changeItems([
              ...localItems,
              {
                id: nextId,
                type,
                values: currentItem.defaultValues,
                active: true,
              } as AllTypes,
            ]);
            callPending();
            setExpandedItem(nextId);
          }
        }}
      />
    </>
  );
};

export default Container;
