import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "tss-react/mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import useI18n from "../../hooks/useI18n";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { imagesUploadConfirm, imageUploadCancel } from "../../actions/actions";
import { ImageSize } from "../../types/common";
import TagsPicker from "../../components/TagsPicker";
import { Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 330,
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  hint: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

const ImageUploadDialog = () => {
  const { open } = useAppSelector((state) => {
    const uploadImages = state.system.uploadImages;
    const uploadImagesCollisions = state.system.uploadImagesCollisions;
    return {
      open: uploadImages !== null && uploadImagesCollisions.length === 0,
    };
  });

  const dispatch = useAnyAppDispatch();

  const { classes } = useStyles();
  const [_] = useI18n();
  const [resizeImage, setResizeImage] = useState(false);
  const [tagsIds, setTagsIds] = useState<number[]>([]);
  const prefixEl = React.createRef<HTMLInputElement>();
  const widthEl = React.createRef<HTMLInputElement>();
  const heightEl = React.createRef<HTMLInputElement>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (prefixEl.current) {
      onConfirm(
        prefixEl.current.value,
        tagsIds,
        resizeImage && widthEl.current && heightEl.current
          ? { width: +widthEl.current.value, height: +heightEl.current.value }
          : null
      );
    }
  };

  const onConfirm = (
    prefix: string,
    tagsIds: number[],
    imageSize: ImageSize | null
  ) => {
    dispatch(imagesUploadConfirm(prefix, tagsIds, imageSize));
  };

  const onCancel = () => {
    dispatch(imageUploadCancel());
  };

  useEffect(() => {
    setTagsIds([]);
  }, [open]);

  return (
    <Dialog
      disablePortal
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{_("upload_image_title")}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">Tags</Typography>
          <TagsPicker values={tagsIds} onChange={setTagsIds} />
          <TextField
            label={_("file_prefix")}
            variant="outlined"
            inputRef={prefixEl}
            defaultValue=""
            fullWidth
          />

          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={resizeImage}
                onChange={(e) => setResizeImage(e.target.checked)}
              />
            }
            label={_("resize_image")}
          />
          {resizeImage && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  defaultValue={1920}
                  inputRef={widthEl}
                  inputProps={{ min: 1 }}
                  type="number"
                  label={_("max_width")}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  inputRef={heightEl}
                  defaultValue={1080}
                  inputProps={{ min: 1 }}
                  type="number"
                  label={_("max_height")}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
          <Typography
            className={classes.hint}
            variant="caption"
            display="block"
          >
            {_("image_size_tip")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{_("cancel")}</Button>
          <Button
            id="add_images_submit_btn"
            autoFocus
            color="primary"
            type="submit"
          >
            {_("submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ImageUploadDialog;
