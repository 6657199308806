import React, { FC } from "react";
import { ImageData } from "../actions/ImageService";

const styles = {
  wrap: {
    color: "white",
  },
  label: {
    color: "#24e0df",
  },
  messages: {
    overflowY: "auto",
    backgroundColor: "rgb(43, 43, 43)",
    padding: 5,
    height: "calc(100% - 30px)",
    userSelect: "text",
  },
  title: {
    lineHeight: "30px",
    padding: "0 5px",
    backgroundColor: "rgb(29, 29, 29)",
  },
  errorText: {
    color: "#ff4c4c",
  },
  code: {
    whiteSpace: "pre",
    fontSize: 16,
  },
} as const;

type Props = {
  data: ImageData | null;
  style: any;
};

const Console: FC<Props> = ({ data, style }) => {
  return (
    <div style={{ ...styles.wrap, ...style }}>
      <div style={styles.title}>Console</div>
      <div style={styles.messages}>
        {data && (
          <>
            <div>
              <span style={styles.label}>stdout: </span>{" "}
              <code style={styles.code}>{data.stdout || "\u00A0"}</code>
            </div>
            <div>
              <span style={styles.label}>stderr: </span>{" "}
              <code style={{ ...styles.code, ...styles.errorText }}>
                {data.stderr || "\u00A0"}
              </code>
            </div>
            <span style={styles.label}>image size: </span>
            {data.imageSize.width}x{data.imageSize.height}
            <br />
            <span style={styles.label}>detected rectangles: </span>
            <code style={styles.code}>
              {JSON.stringify(data.rectangles, null, 4)}
            </code>
            <br />
            <span style={styles.label}>errors: </span>
            <code style={{ ...styles.code, ...styles.errorText }}>
              {data.errors.join(", ")}
            </code>
            <br />
            <span style={styles.label}>result: </span>
            {data.result} <br />
          </>
        )}
      </div>
    </div>
  );
};

export default Console;
