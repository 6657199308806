import React, { FC, useCallback } from "react";
import inputSettings from "./inputSettings";
import { AnyElement } from "./types";
import Form from "../../../../components/Form/Form";
import useUploadAssets from "../../../../hooks/useUploadAssets";
import { useAnyAppDispatch } from "../../../../hooks/store";
import { resetCounter } from "../../../../actions/actions";

type Props = {
  element: AnyElement;
  onChange: (values: AnyElement) => void;
};

const Settings: FC<Props> = ({ element, onChange }) => {
  const [uploadAsset] = useUploadAssets();
  const dispatch = useAnyAppDispatch();

  const handleUploadImage = useCallback(
    async (file: File) => {
      const { id } = await uploadAsset(file);
      return id;
    },
    [uploadAsset]
  );

  const handleClickButton = useCallback(
    (id: string, values: any) => {
      dispatch(resetCounter(values.id));
    },
    [dispatch]
  );

  return (
    <div>
      <Form
        onChange={onChange}
        values={element}
        items={inputSettings[element.type]}
        onUploadImage={handleUploadImage}
        onClickButton={handleClickButton}
      />
    </div>
  );
};

export default Settings;
