import React, { Component } from "react";
import FileUploadIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import StyleIcon from "@mui/icons-material/Style";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import {
  PageToolbar,
  PageToolbarButton,
} from "../../../components/PageToolbar";
import { ACCEPTED_IMAGE } from "../../../utils/common";
import withI18n from "../../../utils/withI18n";

type Props = {
  onSelectFiles: (images: FileList | File[]) => void;
  onDelete: () => void;
  onOpenTags: () => void;
  onCancelSelect: () => void;
  selectedCount: number;
  onSelectAll: () => void;
  onClickAddDemo: () => void;
  demoImages: boolean;
  disableAddImage: boolean;
  t: (key: string, ...params: string[]) => string;
};

type State = {
  fileNumber: number;
};

class Header extends Component<Props, State> {
  state: State = {
    fileNumber: 1,
  };

  fileFolder = React.createRef<HTMLInputElement>();
  file = React.createRef<HTMLInputElement>();

  handleClickUpload = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.file.current?.click();
  };

  handleClickUploadFolder = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.fileFolder.current?.click();
  };

  handleClickSelectAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onSelectAll();
  };

  render() {
    const { onSelectFiles, selectedCount, onDelete, onCancelSelect, t } =
      this.props;

    const _ = t;

    return (
      <PageToolbar>
        {!this.props.disableAddImage && (
          <>
            <PageToolbarButton
              color="secondary"
              label={_("upload_files")}
              id="add_images_btn"
              icon={<FileUploadIcon />}
              onClick={
                this.props.demoImages
                  ? this.props.onClickAddDemo
                  : this.handleClickUpload
              }
            />
            {!this.props.demoImages && (
              <PageToolbarButton
                color="secondary"
                label={_("upload_files_folder")}
                id="add_images_folder_btn"
                icon={<FileUploadIcon />}
                onClick={
                  this.props.demoImages
                    ? this.props.onClickAddDemo
                    : this.handleClickUploadFolder
                }
              />
            )}
          </>
        )}
        <PageToolbarButton
          color="secondary"
          label={_("select_all")}
          icon={<SelectAllIcon />}
          onClick={this.handleClickSelectAll}
        />
        {selectedCount > 0 && (
          <PageToolbarButton
            color="secondary"
            label={_("cancel_select")}
            icon={<CancelIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onCancelSelect();
            }}
          />
        )}
        {selectedCount > 0 && !this.props.demoImages && (
          <PageToolbarButton
            color="secondary"
            label={`${_("delete_selected_files")} (${selectedCount})`}
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        )}
        <PageToolbarButton
          color="secondary"
          label={_("open_tags")}
          icon={<StyleIcon />}
          onClick={this.props.onOpenTags}
        />
        <input
          style={{ display: "none" }}
          type="file"
          id="image_gallery_input"
          /* eslint-disable-next-line react/no-string-refs */
          ref={this.file}
          key={this.state.fileNumber}
          accept={ACCEPTED_IMAGE.join(", ")}
          multiple
          onClick={(event) => {
            const element = event.target as any;
            element.value = "";
          }}
          onChange={(e) => {
            if (e.target.files !== null) {
              onSelectFiles(e.target.files);
            }
            this.setState(({ fileNumber }) => ({ fileNumber: fileNumber + 1 }));
          }}
        />
        <input
          style={{ display: "none" }}
          type="file"
          /* eslint-disable-next-line react/no-string-refs */
          ref={this.fileFolder}
          key={this.state.fileNumber}
          multiple
          // @ts-ignore:next-line
          directory=""
          webkitdirectory=""
          onClick={(event) => {
            const element = event.target as any;
            element.value = "";
          }}
          onChange={(e) => {
            const files: File[] = [];
            if (!e.target.files) {
              return;
            }
            const filesArray = Array.from(e.target.files);
            filesArray.forEach((i) => {
              if (ACCEPTED_IMAGE.includes(i.type)) {
                files.push(i);
              }
            });
            if (files.length > 0) {
              onSelectFiles(files);
              this.setState(({ fileNumber }) => ({
                fileNumber: fileNumber + 1,
              }));
            }
          }}
        />
      </PageToolbar>
    );
  }
}

export default withI18n(Header);
