import React from "react";
import { Subtract } from "utility-types";
import queryString from "query-string";
import deepEqual from "fast-deep-equal";
import { getImageWithDataFromSrc } from "../actions/ImageService";
import Loader from "../components/Loader/Loader";

type ImageSize = { width: number; height: number };

interface InjectedProps {
  imageSize?: { width: number; height: number };
  imgWidth?: number;
  imgHeight?: number;
}

interface MakeProps {
  imgSrc?: string;
  onReceiveImgSize?: (size: ImageSize) => void;
}

interface State {
  size: null | ImageSize;
  imageUrl: null | string;
}

const getImageSize = <P extends InjectedProps>(
  Component: React.ComponentType<P>
) => {
  return class MakeImageSize extends React.Component<
    Subtract<P, InjectedProps> & MakeProps,
    State
  > {
    state: State = {
      size: null,
      imageUrl: null,
    };

    isComponentMounted: boolean = false;

    componentDidMount() {
      this.isComponentMounted = true;
      if (this.props.imgSrc) {
        getImageWithDataFromSrc(this.props.imgSrc).then(
          ({ imageUrl, data }) => {
            if (this.isComponentMounted) {
              this.setState({
                size: data.imageSize,
                imageUrl,
              });
              if (this.props.onReceiveImgSize) {
                this.props.onReceiveImgSize(data.imageSize);
              }
            }
          }
        );
      }
    }

    componentDidUpdate(prevProps: MakeProps) {
      if (
        this.props.imgSrc &&
        !this.isSameImageSize(prevProps.imgSrc, this.props.imgSrc)
      ) {
        this.setState({
          size: null,
          imageUrl: null,
        });
        getImageWithDataFromSrc(this.props.imgSrc).then(
          ({ imageUrl, data }) => {
            if (this.isComponentMounted) {
              this.setState({
                size: data.imageSize,
                imageUrl,
              });
              if (this.props.onReceiveImgSize) {
                this.props.onReceiveImgSize(data.imageSize);
              }
            }
          }
        );
      }
    }

    componentWillUnmount = () => {
      this.isComponentMounted = false;
    };

    // check flow - only code can change size
    // eslint-disable-next-line class-methods-use-this
    isSameImageSize(imgSrc1: string | undefined, imgSrc2: string | undefined) {
      if (imgSrc1 === imgSrc2) {
        return true;
      }

      if (!imgSrc1 || !imgSrc2) {
        return false;
      }

      const parsedUrl1 = queryString.parseUrl(imgSrc1);
      const parsedUrl2 = queryString.parseUrl(imgSrc2);

      // remove mask - no effect
      delete parsedUrl1.query.with_mask;
      delete parsedUrl2.query.with_mask;

      if (deepEqual(parsedUrl1, parsedUrl2)) {
        return true;
      }

      const queryParsed1 = JSON.parse(
        (parsedUrl1.query?.config ?? "{}") as string
      );
      const queryParsed2 = JSON.parse(
        (parsedUrl2.query?.config ?? "{}") as string
      );

      const biggerQuery =
        queryParsed1.length > queryParsed2.length ? queryParsed1 : queryParsed2;
      const smallerQuery =
        queryParsed1.length > queryParsed2.length ? queryParsed2 : queryParsed1;

      if (biggerQuery.length === 0) {
        return false;
      }

      const lastModule = biggerQuery.pop();
      return (
        parsedUrl1.url === parsedUrl2.url &&
        JSON.stringify(biggerQuery) === JSON.stringify(smallerQuery) &&
        lastModule.type !== "CODE"
      );
    }

    render() {
      const { onReceiveImgSize, ...props } = this.props;

      if (!this.state.size) {
        return <Loader fadeIn relative />;
      }

      const ComponentHack = Component as any;

      return (
        <ComponentHack
          {...(props as any)}
          imgSrc={this.state.imageUrl || this.props.imgSrc}
          imageSize={{
            width: this.state.size.width,
            height: this.state.size.height,
          }}
          imgWidth={this.state.size.width}
          imgHeight={this.state.size.height}
        />
      );
    }
  };
};

export default getImageSize;
