import {
  UnsupervisedModule,
  DetectorModule,
  DetectorModel,
  SupervisedModel,
  SupervisedModule,
  UnifierModule,
  UnifierItem,
  ClassifierModel,
  ClassifierModule,
  MaskModule,
  MaskItem,
  OcrModule,
  SortModules,
  OcrModel,
  ParralelismModule,
} from "./../types/modules";
import deepEqual from "fast-deep-equal";
import { AnyModule, UnsupervisedModel } from "../types/modules";
import { Image } from "../types/common";
import type * as Flow from "../types/flow";
import { GlobalState } from "../store/createStore";
import { Modules as ModuleType } from "../modules";
import { ReceivedRectangle } from "../store/system/reducer";
import { SERVER_IMAGE_URL } from "../config";

export const deepCompare = (obj1: any, obj2: any) => deepEqual(obj1, obj2);

export const ACCEPTED_IMAGE = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/bmp",
  "image/tiff",
];

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const getTreshold = (
  module: DetectorModule | UnsupervisedModule | OcrModule | SupervisedModule,
  models: (UnsupervisedModel | DetectorModel | OcrModel | SupervisedModel)[]
) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return currentModel ? currentModel.threshold ?? 50 : 50;
};

export const getModel = (module: OcrModule, models: OcrModel[]) => {
  const currentModel = module.modelId
    ? models.find((m) => m.modelId === module.modelId)
    : null;
  return currentModel ?? null;
};

export const getIou = (module: DetectorModule, models: DetectorModel[]) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return currentModel && currentModel.iou !== undefined ? currentModel.iou : 50;
};

export const getTensorrt = (
  module: ClassifierModule | SupervisedModule,
  models: (ClassifierModel | SupervisedModel)[]
) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return currentModel ? currentModel.tensorrt_auto_optimise ?? false : false;
};

export const getEvaluationRules = (
  module: SupervisedModule | DetectorModule | ClassifierModule | OcrModule,
  models: (SupervisedModel | DetectorModel | ClassifierModel | OcrModel)[]
) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return currentModel &&
    currentModel.evaluation &&
    currentModel.evaluation.enable
    ? currentModel.evaluation.rules
    : [];
};
export const getHeatmapInfo = (module: MaskModule, models: MaskItem[]) => {
  const currentModel =
    module.itemId && models.find((m) => m.itemId === module.itemId);
  return (currentModel && currentModel.heatmap) || false;
};

export const getEvaluation = (
  module: UnsupervisedModule,
  models: UnsupervisedModel[]
) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return currentModel ? currentModel.evaluation : false;
};

export const hasAutosensitivity = (
  module: UnsupervisedModule,
  models: UnsupervisedModel[]
) => {
  const currentModel =
    module.modelId && models.find((m) => m.modelId === module.modelId);
  return !!(currentModel && currentModel.sensitivityAnalyze);
};

export const getEditDate = (module: UnifierModule, models: UnifierItem[]) => {
  const currentModel =
    module.itemId && models.find((m) => m.itemId === module.itemId);
  return currentModel ? currentModel.editDate : 0;
};

export const jsonToURI = (json: any) =>
  encodeURIComponent(JSON.stringify(json));

export const uriToJSON = (uriJson: string) =>
  JSON.parse(decodeURIComponent(uriJson));

export const getModulePath = (items: any, id: number) => {
  let currentItemIndex = items.indexOf(id);
  if (currentItemIndex >= 0) {
    return items.slice(0, currentItemIndex + 1);
  }

  let currentSubtree = null;
  items.forEach((item: any, index: number) => {
    if (!Array.isArray(item)) {
      return;
    }
    item.forEach((item2) => {
      const r = getModulePath(item2, id);
      if (r) {
        currentItemIndex = index;
        currentSubtree = [r];
      }
    });
  });
  return currentSubtree
    ? [...items.slice(0, currentItemIndex), currentSubtree]
    : null;
};

export const removeLastItem = (items: SortModules): SortModules => {
  const last = items[items.length - 1];
  const withoutLast = [...items];
  withoutLast.pop();
  if (!Array.isArray(last)) {
    return withoutLast;
  }
  if (last[0].length === 1) {
    return withoutLast;
  }
  return [...withoutLast, removeLastItem(last as SortModules)] as SortModules;
};

type AllModels = {
  unsupervisedModels: UnsupervisedModel[];
  supervisedModels: SupervisedModel[];
  detectorModels: DetectorModel[];
  classifierModels: ClassifierModel[];
  ocrModels: OcrModel[];
};

type AllModulesItems = {
  unifierItems: UnifierItem[];
  maskItems: MaskItem[];
};

export const addLabelsToFlow = (
  flow: Flow.Flow,
  models: AllModels & AllModulesItems,
  items: AnyModule[],
  withTimes: boolean,
  times?: { [key in number]: number }
): Flow.Flow => {
  return flow.map((flowItem) => {
    if (flowItem.type === "PARALLELISM") {
      return {
        ...flowItem,
        branches: flowItem.branches.map((branch) =>
          addLabelsToFlow(branch, models, items, withTimes, times)
        ),
      };
    }
    const module = items.find((i) => i.id === flowItem.id);
    if (!module) {
      return flowItem;
    }

    const data = {
      ...flowItem,
      label: module?.label,
      note: module?.note,
      inactive: !(
        ("itemId" in module && module["itemId"] !== null) ||
        ("modelId" in module && module["modelId"] !== null) ||
        ("isActive" in module && module["isActive"] === true) ||
        ("fileId" in module && module["fileId"] !== null)
      ),
      time: times?.[module.id] ?? undefined,
    };
    if (!withTimes) {
      delete data.time;
    }
    return data;
  });
};

export const hasParallelism = (items: SortModules): boolean => {
  return items.some((item) => Array.isArray(item));
};

export const sortModules = (
  sort: SortModules,
  items: AnyModule[],
  models: (AllModels & AllModulesItems) | null,
  labels?: boolean
): Flow.Flow => {
  return sort
    .map((item) => {
      const unsupervisedModels = models ? models.unsupervisedModels : [];
      const supervisedModels = models ? models.supervisedModels : [];
      const detectorModels = models ? models.detectorModels : [];
      const classifierModels = models ? models.classifierModels : [];
      const unifierItems = models ? models.unifierItems : [];
      const maskItems = models ? models.maskItems : [];
      const ocrModels = models ? models.ocrModels : [];

      if (Array.isArray(item)) {
        const result: Flow.Parallelism = {
          type: ModuleType.PARALLELISM,
          id: -1,
          branches: item.map((i) => sortModules(i, items, models, labels)),
        };
        return result;
      }

      const current = items.find(({ id }) => item === id);

      if (!current) {
        return undefined;
      }

      if (current.type === "UNSUPERVISED") {
        const result: Flow.Unsupervised = {
          id: current.id,
          type: current.type,
          modelId: current.modelId,
          threshold: getTreshold(current, unsupervisedModels),
          evaluation: current.modelId
            ? getEvaluation(current, unsupervisedModels)
            : false,
          showHeatmap: current.showHeatmap,
          showRectangles: current.showRectangles,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.modelId === null : undefined,
        };
        return result;
      }
      if (current.type === "SUPERVISED") {
        const result: Flow.Supervised = {
          id: current.id,
          type: current.type,
          modelId: current.modelId,
          showHeatmap: current.showHeatmap,
          showRectangles: current.showRectangles,
          rules: getEvaluationRules(current, supervisedModels),
          tensorrt: getTensorrt(current, supervisedModels),
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          threshold: getTreshold(current, supervisedModels),
          inactive: labels ? current.modelId === null : undefined,
        };
        return result;
      }

      if (current.type === "UNIFIER") {
        const result: Flow.Unifier = {
          id: current.id,
          type: current.type,
          itemId: current.itemId,
          editDate: getEditDate(current, unifierItems) || 0,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.itemId === null : undefined,
        };
        return result;
      }
      if (current.type === "DETECTOR") {
        const result: Flow.Detector = {
          id: current.id,
          type: current.type,
          modelId: current.modelId,
          threshold: getTreshold(current, detectorModels),
          iou: getIou(current, detectorModels),
          rules: getEvaluationRules(current, detectorModels),
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.modelId === null : undefined,
        };
        return result;
      }
      if (current.type === "CLASSIFIER") {
        const result: Flow.Classifier = {
          id: current.id,
          type: current.type,
          modelId: current.modelId,
          rules: getEvaluationRules(current, classifierModels),
          tensorrt: getTensorrt(current, classifierModels),
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.modelId === null : undefined,
        };
        return result;
      }
      if (current.type === "OCR") {
        const model = getModel(current, ocrModels);
        const result: Flow.Ocr = {
          id: current.id,
          type: current.type,
          modelId: current.modelId,
          threshold: getTreshold(current, ocrModels),
          rules: getEvaluationRules(current, ocrModels),
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          ocv_max_threshold: model?.ocv_max_threshold ?? 0,
          ocv_area_threshold: model?.ocv_area_threshold ?? 0,
          inactive: labels ? current.modelId === null : undefined,
        };
        return result;
      }
      if (current.type === "MASK") {
        const result: Flow.Mask = {
          id: current.id,
          type: current.type,
          imageId:
            maskItems.find((i) => i.itemId === current.itemId)?.imageId ?? null,
          heatmap: getHeatmapInfo(current, maskItems) || false,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.itemId === null : undefined,
        };
        return result;
      }
      if (current.type === "PREPROCESS") {
        const result: Flow.Preprocess = {
          id: current.id,
          type: current.type,
          editDate: current.editDate || 0,
          isActive: current.isActive,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? !current.isActive : undefined,
        };
        return result;
      }
      if (current.type === "CODE") {
        const result: Flow.Code = {
          id: current.id,
          type: current.type,
          fileId: current.fileId,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? current.fileId !== null : undefined,
        };
        return result;
      }
      if (current.type === "MEASURE") {
        const result: Flow.Measure = {
          id: current.id,
          type: current.type,
          editDate: current.editDate || 0,
          isActive: current.isActive,
          label: labels ? current.label : undefined,
          note: labels ? current.note : undefined,
          inactive: labels ? !current.isActive !== null : undefined,
        };
        return result;
      }
      return undefined;
    })
    .map((i) => {
      if (!labels) {
        delete i?.label;
        delete i?.note;
        delete i?.inactive;
      }
      return i;
    })
    .filter(notEmpty);
};

export const isModuleActive = (module: Flow.Any) => {
  const { type } = module;
  if (type === "PARALLELISM") {
    return true;
  } else if (
    type === "DETECTOR" ||
    type === "CLASSIFIER" ||
    type === "SUPERVISED" ||
    type === "UNSUPERVISED" ||
    type === "OCR"
  ) {
    return !!module.modelId;
  } else if (type === "CODE") {
    return !!module.fileId;
  } else if (type === "MASK") {
    return !!module.imageId;
  } else if (type === "UNIFIER") {
    return !!module.itemId;
  } else if (type === "PREPROCESS" || type === "MEASURE") {
    return module.isActive;
  } else {
    return true;
  }
};

export const removeInactiveModels = (items: Flow.Flow) => {
  const nextItems: Flow.Flow = [];
  items.forEach((item) => {
    if (item["type"] === "PARALLELISM") {
      const nextBranches: Flow.Any[][] = [];
      item.branches.forEach((branch) => {
        const b = removeInactiveModels(branch);
        nextBranches.push(b);
      });
      nextItems.push({ ...item, branches: nextBranches });
    } else {
      if (isModuleActive(item)) {
        nextItems.push(item);
      }
    }
  });
  return nextItems;
};

export const findDetectedRectangles = ({
  detectedRectangles,
  imageId,
  settings,
}: {
  detectedRectangles: ReceivedRectangle[];
  imageId: number;
  settings: Flow.Flow;
}) => {
  return detectedRectangles.find(
    (i) => deepCompare(i.settings, settings) && i.imageId === imageId
  );
};

export const findDebuggerInfo = ({
  items,
  imageId,
  settings,
}: {
  items: any[];
  imageId: number;
  settings: Flow.Flow;
}) => {
  return items.find(
    (i) => i.imageId === imageId && deepCompare(i.settings, settings)
  );
};

export const findAverageTimes = ({
  items,
  imagesLastId,
  settings,
}: {
  items: any[];
  imagesLastId: number;
  settings: Flow.Flow;
}) => {
  return items.find(
    (i) => i.imagesLastId === imagesLastId && deepCompare(i.settings, settings)
  );
};

export const isSourceCodeEmpty = (sourceCode: string) =>
  sourceCode.replace(/(\s|\n|\r|\r\n)+/g, "") === "";

export const alphaNumericSort = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const removeFileSuffix = (str: string) => str.replace(/\.[^/.]+$/, "");

export const fileNameSortAsc = (a: string, b: string) =>
  alphaNumericSort(removeFileSuffix(a), removeFileSuffix(b));
export const fileNameSortDesc = (a: string, b: string) =>
  alphaNumericSort(removeFileSuffix(b), removeFileSuffix(a));

export const imagesAutoClassification = (
  images: Image[],
  patternItems: { pattern: string | null; tags: number[] }[],
  isRegex: boolean
) => {
  const results: number[][] = [...Array(patternItems.length)].map(() => []);
  let error: string | undefined = undefined;
  images.forEach((image) => {
    let found = false;
    patternItems.forEach((prefixItem, indexPrefix) => {
      if (found) {
        return;
      }

      const { pattern } = prefixItem;
      const { label } = image;
      const tags = image.tags ?? [];
      const tagsSuccess =
        prefixItem.tags.length === 0 ||
        prefixItem.tags.every((i) => tags.includes(i));

      if (!tagsSuccess) {
        return;
      }

      if (pattern === "" || pattern === null) {
        if (prefixItem.tags.length > 0) {
          results[indexPrefix].push(image.id);
        }
        return;
      }

      if (isRegex) {
        try {
          if (label.search(pattern) !== -1) {
            found = true;
            results[indexPrefix].push(image.id);
          }
        } catch (e) {
          if (e instanceof Error) {
            error = e.message;
          }
        }
      } else if (label.indexOf(pattern) !== -1) {
        found = true;
        results[indexPrefix].push(image.id);
      }
    });
  });
  return { results, error };
};

export const calcBorder = (
  viewFinder: number,
  width: number,
  height: number
): [number, number, number, number] => {
  const FIX_BORDER = 9;
  const input_image_shape = [width, height];
  const rows = Math.floor((input_image_shape[0] - 22) / 8) + 1;
  const cols = Math.floor((input_image_shape[1] - 22) / 8) + 1;
  const conv_move = Math.floor((viewFinder - 22) / 8);
  const reduced_rows = rows - conv_move;
  const reduced_cols = cols - conv_move;
  const size = {
    y:
      // eslint-disable-next-line no-bitwise
      (Math.round((input_image_shape[0] / rows) * (rows - reduced_rows)) >>
        1) <<
      1,
    x:
      // eslint-disable-next-line no-bitwise
      (Math.round((input_image_shape[1] / cols) * (cols - reduced_cols)) >>
        1) <<
      1,
  };

  const result: [number, number, number, number] = [
    size.y / 2,
    size.x / 2,
    -(-size.y / 2 - FIX_BORDER),
    -(-size.x / 2 - FIX_BORDER),
  ];

  if (result[0] + result[2] > width || result[1] + result[3] > height) {
    return [0, 0, 0, 0];
  }
  return result;
};

export const getModule = (
  state: GlobalState,
  moduleId: number
): Exclude<AnyModule, ParralelismModule> | undefined => {
  return (
    state.database.modules.items as Exclude<AnyModule, ParralelismModule>[]
  ).find((item) => item.id === +moduleId);
};

export const isTraining = (state: GlobalState) => {
  const detectorTrainingModel =
    state.database.detectorModels &&
    state.database.detectorModels.find((i) => !i.completed && !i.failed);
  const classifierTrainingModel =
    state.database.classifierModels &&
    state.database.classifierModels.find((i) => !i.completed && !i.failed);
  const supervisedTrainingModels =
    state.database.supervisedModels &&
    state.database.supervisedModels.find((i) => !i.completed && !i.failed);
  const unsupervisedTrainingModels =
    state.database.unsupervisedModels &&
    state.database.unsupervisedModels.find((i) => !i.completed && !i.failed);
  const ocrTrainingModels =
    state.database.ocrModels &&
    state.database.ocrModels.find((i) => !i.completed && !i.failed);

  return !!(
    detectorTrainingModel ||
    classifierTrainingModel ||
    supervisedTrainingModels ||
    unsupervisedTrainingModels ||
    ocrTrainingModels
  );
};

export const isInitModel = (state: GlobalState) => {
  return state.system.loadingItems.some(i => typeof i === "string" && (i === "init_model" || i === "optimizing_loader") )
}

export const arraySum = (array: number[]) =>
  array.reduce((sum, val) => sum + val, 0);

export const getStaticFilePath = (
  path: string,
  token: string,
  projectId: string
) =>
  `${SERVER_IMAGE_URL}/get_static_file?path=${path}&token=${token}&pid=${projectId}`;

export type GetConfigParams = {
  imageId: number;
  moduleId?: number | null;
  includeLast?: boolean;
  otherModule?: Flow.Any | Flow.Canny;
  withMask?: boolean;
  min?: boolean;
};

export const getImagePath = (
  moduleSort: SortModules,
  moduleItems: AnyModule[],
  models: AllModels & AllModulesItems,
  params: GetConfigParams,
  token: string,
  projectId: string
) => {
  const {
    imageId,
    moduleId = null,
    includeLast = false,
    withMask = false,
    min = false,
    otherModule = null,
  } = params;
  const simpleConfig = getSettings(moduleSort, moduleItems, models, {
    moduleId,
    includeLast,
    otherModule,
  });
  const urlQuery = `config=${jsonToURI(simpleConfig)}&with_mask=${
    withMask ? "y" : "n"
  }&min=${min ? "y" : "n"}&token=${token}&pid=${projectId}`;
  return `${SERVER_IMAGE_URL}/get_image/${imageId}?${urlQuery}`;
};

export const getRawImagePath = (
  imageId: number,
  min: boolean,
  projectId?: string,
  token?: string
) => {
  const urlQuery = `config=${jsonToURI([])}&min=${
    min ? "y" : "n"
  }&token=${token}&pid=${projectId}`;
  return `${SERVER_IMAGE_URL}/get_image/${imageId}?${urlQuery}`;
};

export const getMaskPath = (
  itemId: number,
  token: string,
  projectId: string
) => {
  const urlQuery = `token=${token}&pid=${projectId}`;
  return `${SERVER_IMAGE_URL}/masks/${itemId}.png?${urlQuery}`;
};

export const getSettings = (
  moduleSort: SortModules,
  moduleItems: AnyModule[],
  models: AllModels & AllModulesItems,
  {
    moduleId = undefined,
    includeLast = false,
    otherModule = null,
  }: {
    moduleId?: number | null;
    includeLast?: boolean;
    otherModule?: Flow.Any | Flow.Canny | null;
  }
) => {
  let modulePath = moduleId ? getModulePath(moduleSort, moduleId) : moduleSort;

  if (!includeLast && moduleId) {
    modulePath = removeLastItem(modulePath);
  }

  let simpleConfig = sortModules(modulePath, moduleItems, models);

  if (otherModule) {
    simpleConfig = [...simpleConfig, otherModule] as Flow.Flow;
  }
  return simpleConfig;
};

export const modelIsUsed = <
  T extends { modelId: number | null },
  Y extends { modelId: number }
>(
  moduleItems: T[],
  model: Y
) => !!moduleItems.find((m) => m.modelId === model.modelId);

export const itemIsUsed = <
  T extends { itemId: number | null },
  Y extends { itemId: number }
>(
  moduleItems: T[],
  model: Y
) => !!moduleItems.find((m) => m.itemId === model.itemId);

export const sortAlphabets = (text: string) => text.split("").sort().join("");

export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};

// remove invalid character from path
export const nameToValidPath = (name: string) => {
  return name.replace(/[/|\\|"|<|>|:|!|*|?||]/g, "");
};

export const roundTo = (value: number, decimals: number) =>
  Number(`${Math.round(`${value}e${decimals}` as any)}e-${decimals}`);
