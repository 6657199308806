import React, { Component } from "react";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import ModelInfoDialog from "../ModelInfoDialog";
import { formatDateTime } from "../../utils/format";
import Table from "../Table";
import EditNameDialog from "../EditNameDialog";
import GpuSettings from "../GpuSettings";
import { CON_MIN_HEIGHT_HEADER } from "../../theme";
import Confirm from "../Confirm";
import { AnyModelUsed } from "../../types/modules";
import { Alert, AlertTitle, FormControlLabel, Switch } from "@mui/material";

const styles = {
  wrap: {
    overflowY: "auto",
  },
  nameWrap: {
    marginLeft: 30,
  },
  previewButton: {
    top: -8,
    height: 34,
  },
  button: {
    marginTop: -19,
    marginBottom: -19,
  },
  radio: {
    marginTop: -20,
    marginBottom: -20,
  },
  buttonIcon: {
    marginRight: 8,
  },
  trtWrap: {
    margin: "-10px 0 -10px 8px",
  },
  deleteSelected: {
    margin: "-4px 0px",
  },
  trtSwitch: {},
} as const;

type Props<T> = {
  activeModelId: number | null;
  onOpen: () => void;
  onDelete: (model: T) => void;
  onActive: (model: T | null) => void;
  getParams?: (model: T) => any;
  onEditName: (value: {
    errorId?: number;
    modelId: number;
    value: string;
  }) => void;
  enableGpuSettings?: boolean;
  onlyOneGpu?: boolean;
  gpuDevices: any[];
  gpuValue: any;
  onChangeGpuValue: (values: any) => void;
  t: (key: string) => string;
  showTrt?: ((model: T) => boolean) | boolean;
  onChangeTrt?: (model: T, active: boolean) => void;
  models: T[];
  redirectToTraining?: () => void;
  maxHeight?: boolean;
};

type State<T> = {
  currentDetailModel?: T;
  currentEditNameModel: null | T;
  showDeleteConfirm: boolean;
  deleteModel?: T;
  selectedLines: number[];
};

class TemplateListView<T extends AnyModelUsed> extends Component<
  Props<T>,
  State<T>
> {
  state: State<T> = {
    currentDetailModel: undefined,
    currentEditNameModel: null,
    showDeleteConfirm: false,
    selectedLines: [],
  };

  componentDidMount() {
    if (this.props.models.length === 0) {
      this.props.redirectToTraining?.();
    }
  }

  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onOpen();
  };

  handleClickDelete = (e: React.MouseEvent, model: T) => {
    e.stopPropagation();
    if (!model.isUsed) {
      this.setState({
        deleteModel: model,
        showDeleteConfirm: true,
      });
    }
  };

  handleClickInfo = (e: React.MouseEvent, model: T) => {
    e.stopPropagation();
    this.setState({
      currentDetailModel: model,
    });
  };

  handleClickEdit = (e: React.MouseEvent, model: T) => {
    e.stopPropagation();
    this.setState({
      currentEditNameModel: model,
    });
  };

  handleClickDeleteSelected = (models: T[]) => {
    this.state.selectedLines.forEach((modelId) => {
      const model = models.find((m) => m.modelId === modelId);
      if (model && !model.isUsed) {
        this.props.onDelete(model);
      }
    });
  };

  modelsIndexes = (models: T[]) => {
    return this.state.selectedLines
      .filter((i) => models.some((m) => m.modelId === i && !m.isUsed))
      .map(
        (modelId: number) => models.findIndex((i) => i.modelId === modelId) + 1
      );
  };

  renderParams(model: T) {
    const _ = this.props.t;
    const networkSize = (
      (model as any).trainingParams.config || (model as any).trainingParams
    ).NETWORK_SIZE as string;
    const networkSizeSymbols = (key: string) =>
      ((
        {
          high_accuracy: "XL",
          accuracy: "L",
          balance: "M",
          performance: "S",
        } as any
      )[key]);
    return (
      <>
        <span title={`${_("network_size")}: ${_(networkSize)}`}>
          {networkSizeSymbols(networkSize)}
        </span>
        {this.props.getParams && this.props.getParams(model)}
      </>
    );
  }

  render() {
    const _ = this.props.t;
    const models = this.props.models.concat().reverse();
    const bodyItems = models.map((model) => [
      <>
        <Radio
          style={styles.radio}
          checked={this.props.activeModelId === model.modelId}
          onChange={() =>
            this.props.activeModelId !== model.modelId &&
            this.props.onActive(model)
          }
          disabled={model.failed || !model.completed}
        />
        <span style={styles.nameWrap}>
          <b>{model.name}</b>{" "}
          {!model.completed && <span>({_("model_not_completed")})</span>}
          {model.failed && (
            <span>
              ({_("model_failed")} - {_(`training_error_${model.errorId || 0}`)}
              )
            </span>
          )}
          {model.failed && <span>{}</span>}
        </span>
      </>,
      formatDateTime(model.creationDate),
      this.renderParams(model),
      <>
        {(this.props.showTrt === true ||
          (this.props.showTrt !== false && this.props.showTrt?.(model))) && (
          <FormControlLabel
            onClick={(e) => e.stopPropagation()}
            style={styles.trtWrap}
            control={
              <Switch
                checked={
                  "tensorrt_auto_optimise" in model &&
                  model.tensorrt_auto_optimise
                }
                style={styles.trtSwitch}
                onChange={(e) =>
                  this.props.onChangeTrt?.(model, e.target.checked)
                }
              />
            }
            label="TRT"
          />
        )}
        <IconButton
          style={styles.button}
          onClick={(e) => this.handleClickInfo(e, model)}
          size="large"
        >
          <InfoIcon />
        </IconButton>
        <IconButton
          style={styles.button}
          onClick={(e) => this.handleClickEdit(e, model)}
          size="large"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          style={styles.button}
          disabled={model.isUsed}
          onClick={(e) => this.handleClickDelete(e, model)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </>,
    ]);

    const emptyLine = [
      <>
        <Radio
          style={styles.radio}
          checked={this.props.activeModelId === null}
          onChange={() => this.props.onActive(null)}
        />
        <span style={styles.nameWrap}>{_("none_active")}</span>
      </>,
      null,
      null,
      null,
    ];

    const selectedIndexes = this.modelsIndexes(models);

    if (models.length === 0) {
      return (
        <Alert severity="info" sx={{ m: "10px" }}>
          <AlertTitle>{_("model_not_trained")}</AlertTitle>
          {_("train_model_first_info")}
        </Alert>
      );
    }

    return (
      <>
        {this.props.activeModelId === null && (
          <Alert severity={"info"} sx={{ m: "10px" }}>
            <AlertTitle>{_("model_not_selected")}</AlertTitle>
            {_("select_trained_model_info")}
          </Alert>
        )}

        <Confirm
          open={this.state.showDeleteConfirm}
          message={_("remove_model_confirm")}
          onClickNo={() => {
            this.setState({ showDeleteConfirm: false });
          }}
          onClickYes={() => {
            this.setState({ showDeleteConfirm: false });
            if (this.state.deleteModel) {
              this.props.onDelete(this.state.deleteModel);
            } else {
              this.handleClickDeleteSelected(models);
            }
          }}
        />
        {this.props.enableGpuSettings && (
          <GpuSettings
            gpuDevices={this.props.gpuDevices}
            value={this.props.gpuValue}
            onlyOneGpu={this.props.onlyOneGpu}
            onChange={this.props.onChangeGpuValue}
          />
        )}
        <div
          style={{
            ...styles.wrap,
            padding: this.props.maxHeight ? "0px 10px" : 0,
            maxHeight: this.props.maxHeight ? CON_MIN_HEIGHT_HEADER : "auto",
          }}
        >
          <Table
            onClickItem={(i) => {
              if (i === 0) {
                this.props.onActive(null);
              } else if (models?.[i - 1] !== undefined) {
                this.props.onActive(models[i - 1]);
              }
            }}
            headerItems={[
              _("name"),
              _("creation_date"),
              _("params"),
              selectedIndexes.length > 0 ? (
                <Button
                  style={styles.deleteSelected}
                  onClick={() => this.setState({ showDeleteConfirm: true })}
                  size="small"
                  variant="outlined"
                  color="primary"
                >
                  {_("delete_selected")}
                </Button>
              ) : null,
            ]}
            disabledShortcuts={this.state.currentEditNameModel !== null}
            bodyItems={[emptyLine, ...bodyItems]}
            selected={selectedIndexes}
            onSelect={(indexes) =>
              this.setState({
                selectedLines: indexes
                  .filter((i) => i !== 0)
                  .map((i) => models[i - 1].modelId),
              })
            }
          />
          <ModelInfoDialog
            open={!!this.state.currentDetailModel}
            onClose={() => this.setState({ currentDetailModel: undefined })}
            model={this.state.currentDetailModel}
          />
          <EditNameDialog
            label={this.state.currentEditNameModel?.name ?? ""}
            open={this.state.currentEditNameModel !== null}
            onClose={() => this.setState({ currentEditNameModel: null })}
            onUpdate={(name) => {
              if (this.state.currentEditNameModel) {
                this.props.onEditName({
                  modelId: this.state.currentEditNameModel.modelId,
                  value: name,
                });
              }
            }}
          />
        </div>
      </>
    );
  }
}

export default TemplateListView;
