import React, { useCallback, useEffect } from "react";
import { Layer, Group, Stage, Circle } from "react-konva";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import BackgroundImage from "../BackgroundImage";
import Wrap from "../Wrap";
import Polygon from "./Polygon";
import { KonvaEventObject } from "konva/lib/Node";
import Lines from "./Lines";
import { useDebouncedCallback } from "use-debounce/lib";
import useKeyPress from "../useKeyPress";
import { clipFunc } from "../utils";

export type Item = {
  radius?: number;
  tool: string;
  points: number[];
  finished: boolean;
  eraser: boolean;
  color: string;
};

export enum Tool {
  Polygon = "Polygon",
  Line = "Line",
}

type Props = {
  tool?: Tool;
  eraser: boolean;
  radius: number;
  imageSize: { width: number; height: number };
  imageUrl: string;
  width: number;
  height: number;
  rotate?: boolean;
  getResetFunction: (func: () => void) => void;
  getExportFunc: (func: () => void) => void;
  getClearFunc: (func: () => void) => void;
  getRefereshFunc: (func: (maskUrl: string) => void) => void;
  color: string;
  onChangeCanvas: () => void;
  onChangeScale: (value: number) => void;
  maskUrl?: string;
  onMaskInit: () => void;
  disableScrollListener: boolean;
};

const Dashbord = (props: Props) => {
  const { tool } = props;
  const [maskUrl, setMaskUrl] = React.useState(props.maskUrl);
  const [lines, setLines] = React.useState<Item[]>([]);
  const [scale, setScale] = React.useState<number>(1);
  const isDrawing = React.useRef(false);
  const [mousePos, setMousePos] = React.useState<any>(null);

  const stageRef = React.useRef<Konva.Stage>(null);
  const stageExportRef = React.useRef<Konva.Stage>(null);
  const stageExportEmptyRef = React.useRef<Konva.Stage>(null);
  const backgroundRef = React.useRef<Konva.Image>(null);
  const groupRef = React.useRef<Konva.Group>(null);
  const layerRef = React.useRef<Konva.Layer>(null);
  const [mouseInDashboard, setMouseInDashboard] = React.useState(false);

  const hiddenIsPressed = useKeyPress.is("h");

  const stopPolygon = useCallback(() => {
    if (lines.length > 0 && !lines[lines.length - 1].finished) {
      setLines([...lines.slice(0, lines.length - 1)]);
    }
  }, [lines, setLines]);

  const handleChangeScale = useCallback(
    (value: number) => {
      props.onChangeScale(value);
      setScale(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChangeScale]
  );

  useKeyPress.cb(
    "Escape",
    () => {
      if (tool === Tool.Polygon) {
        // remove last element
        stopPolygon();
      }
    },
    [lines]
  );

  useEffect(() => {
    stopPolygon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool, props.eraser]);

  useEffect(() => {
    props.getExportFunc(handleExport);
    props.getClearFunc(handleClear);
    props.getRefereshFunc(handleRefreshImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    groupRef.current?.cache();
  }, [lines]);

  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    // function
    () => {
      props.onChangeCanvas();
    },
    // delay in ms
    100
  );

  const handleExport = () => {
    const image = stageExportRef.current?.toDataURL({
      mimeType: "image/png",
      x: 0,
      y: 0,
      width: props.imageSize.width,
      height: props.imageSize.height,
    });
    const imageEmpty = stageExportEmptyRef.current?.toDataURL({
      mimeType: "image/png",
      x: 0,
      y: 0,
      width: props.imageSize.width,
      height: props.imageSize.height,
    });
    return { image, isEmpty: image === imageEmpty };
  };

  const handleRefreshImage = (maskUrl: string) => {
    setMaskUrl(maskUrl);
    setLines([]);
  };

  const handleClear = () => {
    setLines([
      {
        eraser: true,
        tool: Tool.Polygon,
        radius: undefined,
        points: [
          0,
          0,
          props.imageSize.width,
          0,
          props.imageSize.width,
          props.imageSize.height,
          0,
          props.imageSize.width,
        ],
        finished: true,
        color: props.color,
      },
    ]);
  };

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      e.evt.stopPropagation();
      e.evt.preventDefault();
      cancel();

      const stage = stageRef.current;
      if (!stage) {
        return;
      }
      if (backgroundRef.current === e.target) {
        isDrawing.current = true;
        const pos = stage.getRelativePointerPosition() as Vector2d;
        if (pos) {
          if (lines.length === 0 || lines[lines.length - 1].finished)
            setLines([
              ...lines,
              {
                eraser: props.eraser,
                tool: tool || "",
                radius:
                  tool === Tool.Line ? (props.radius || 1) / scale : undefined,
                points: [pos.x, pos.y, pos.x, pos.y],
                finished: tool === Tool.Line,
                color: props.color,
              },
            ]);
        }
      }
    },
    [cancel, lines, props.color, props.eraser, props.radius, scale, tool]
  );

  const handleAddPoint = useCallback(() => {
    const stage = stageRef.current;
    if (!stage) {
      return;
    }
    const point = stage.getRelativePointerPosition() as Vector2d;
    let lastLine = lines[lines.length - 1];
    if (!lastLine) {
      return;
    }
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  }, [lines]);

  const handleEndPolygon = useCallback(() => {
    isDrawing.current = false;
    setLines(lines.map((i, index) => (true ? { ...i, finished: true } : i)));
    debouncedCallback();
  }, [debouncedCallback, lines]);

  const handleMouseUp = useCallback(
    (e?: Konva.KonvaEventObject<MouseEvent>) => {
      isDrawing.current = false;
      if (tool === Tool.Line) {
        debouncedCallback();
      } else if (e?.evt.which === 3) {
        // right click
        handleEndPolygon();
      } else {
        handleAddPoint();
      }
    },
    [debouncedCallback, handleAddPoint, handleEndPolygon, tool]
  );

  const handleMouseMove = () => {
    const stage = stageRef.current;
    if (!stage) {
      return;
    }
    const point = stage.getRelativePointerPosition() as Vector2d;
    setMousePos(point);
    if (!isDrawing.current) {
      return;
    }
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseLeave = useCallback(
    (e?: Konva.KonvaEventObject<MouseEvent>) => {
      if (lines.length > 0) {
        callPending();
      }
      if (isDrawing.current) {
        handleMouseUp(e);
      }
    },
    [callPending, handleMouseUp, lines.length]
  );

  useEffect(() => {
    if (!hiddenIsPressed) {
      groupRef.current?.cache();
    } else {
      handleMouseLeave();
    }
  }, [hiddenIsPressed, handleMouseLeave]);

  return (
    <>
      <Wrap
        ref={stageRef}
        imageSize={props.imageSize}
        wrapSize={{
          width: props.width,
          height: props.height,
        }}
        disableScrollListener={props.disableScrollListener}
        onChangeScale={handleChangeScale}
        getResetFunction={props.getResetFunction}
        StageProps={{
          onMouseMove: handleMouseMove,
          onMouseUp: handleMouseUp,
          onMouseDown: handleMouseDown,
          onMouseLeave: handleMouseLeave,
        }}
      >
        <Layer
          onMouseEnter={(e) => {
            if (tool === Tool.Line) {
              const container = e.target.getStage()?.container();
              if (container) {
                container.style.cursor = "none";
              }
            }
            setMouseInDashboard(true);
          }}
          onMouseLeave={(e) => {
            if (tool === Tool.Line) {
              const container = e.target.getStage()?.container();
              if (container) {
                container.style.cursor = "default";
              }
            }
            setMouseInDashboard(false);
          }}
          width={props.imageSize.width}
          height={props.imageSize.height}
          ref={layerRef}
        >
          <BackgroundImage src={props.imageUrl} ref={backgroundRef} />
          {!hiddenIsPressed && (
            <>
              <Group
                clipFunc={clipFunc(props.imageSize)}
                opacity={0.4}
                ref={groupRef}
                x={0}
                y={0}
                width={props.imageSize.width}
                height={props.imageSize.height}
              >
                {maskUrl && (
                  <BackgroundImage
                    onInit={() => {
                      groupRef.current?.cache();
                      props.onMaskInit();
                    }}
                    src={maskUrl}
                    listening={false}
                    imageSize={props.imageSize}
                  />
                )}
                <Lines scale={scale} items={lines.filter((i) => i.finished)} />
              </Group>
              {tool === Tool.Polygon &&
                lines.length > 0 &&
                !lines[lines.length - 1].finished && (
                  <>
                    <Polygon
                      color={props.color}
                      scale={scale}
                      item={lines[lines.length - 1]}
                      mousePos={mousePos}
                      onFinish={handleEndPolygon}
                    />
                  </>
                )}
              {tool === Tool.Line && mousePos && mouseInDashboard && (
                <>
                  <Circle
                    x={mousePos.x}
                    y={mousePos.y}
                    radius={(props.radius - 1) / scale / 2}
                    stroke="black"
                    opacity={0.5}
                    strokeWidth={1 / scale}
                    fill={props.eraser ? "white" : props.color}
                    listening={false}
                  />
                </>
              )}
            </>
          )}
        </Layer>
      </Wrap>
      <div style={{ display: "none" }}>
        <Stage
          width={props.imageSize.width}
          height={props.imageSize.height}
          ref={stageExportRef}
        >
          <Layer>
            {maskUrl && (
              <BackgroundImage
                src={maskUrl}
                listening={false}
                imageSize={props.imageSize}
              />
            )}
            <Lines scale={scale} items={lines.filter((i) => i.finished)} />
          </Layer>
        </Stage>
        <Stage
          width={props.imageSize.width}
          height={props.imageSize.height}
          ref={stageExportEmptyRef}
        >
          <Layer />
        </Stage>
      </div>
    </>
  );
};

export default Dashbord;
