import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import {
  startAnomalyTraining,
  addErrorMessage,
} from "../../store/system/reducer";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { UnsupervisedModule } from "../../types/modules";
import {
  anomalySetImages,
  anomalyEditConfig,
  unsupervisedAuto,
  anomalyClearAnnotations,
} from "../../actions/actions";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const module = getModule(
    state,
    +ownProps.match.params.moduleId
  ) as UnsupervisedModule;
  return {
    module,
    images: state.database.images,
    models: state.database.unsupervisedModels,
    showTrainingDialog:
      ownProps.location.search.indexOf("showTrainingDialog") !== -1,
    trainingDisabled: state.system.trainingDisabled,
    modelsCounter: state.database.modelsCounter.UNSUPERVISED,
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  };
};

const connector = connect(mapStateToProps, {
  startAnomalyTraining,
  addErrorMessage,
  anomalySetImages,
  anomalyEditConfig,
  unsupervisedAuto,
  anomalyClearAnnotations,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrainingView);
