import { connect, ConnectedProps } from "react-redux";
import {
  supervisedSetThreshold,
  supervisedUpdateEvaluation,
  moduleChangeShowHeatmap,
  moduleChangeShowRectangles,
} from "../../actions/actions";
import ModelDetailView from "./components/ModelDetailView";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { SupervisedModel, SupervisedModule } from "../../types/modules";
import { withRequiredModel } from "../../utils/withRequiredModel";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{
    moduleId: string;
    showAutoSensitivity: string;
  }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as SupervisedModule;
  const { modelId } = module;
  return {
    history: ownProps.history,
    moduleId,
    module,
    model: state.database.supervisedModels.find(
      (m) => m.modelId === modelId
    ) as SupervisedModel,
    params: ownProps.match.params,
    images: state.database.images,
  };
};

const connector = connect(mapStateToProps, {
  moduleChangeShowHeatmap,
  moduleChangeShowRectangles,
  setThreshold: supervisedSetThreshold,
  updateEvaluation: supervisedUpdateEvaluation,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRequiredModel(ModelDetailView));
