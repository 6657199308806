import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SliderWithField from "../SliderWithField";
import {
  InputAny,
  InputCheckbox,
  InputColor,
  InputFile,
  InputNumber,
  InputSelect,
  InputText,
} from "./types";
import useI18n from "../../hooks/useI18n";
import { Button, MenuItem, TextField } from "@mui/material";
import ColorPicker from "./ColorPicker";
import SelectFile from "./SelectFile";

const useStyles = makeStyles<void, "item">()((theme, _params, classes) => ({
  root: {
    width: "100%",
  },
  label: {
    color: "rgb(187, 187, 187)!important",
    fontSize: 12,
    display: "block",
    marginBottom: 7,
    marginTop: 10,
  },
  checkbox: {},
  item: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [`&+.${classes.item}`]: {
      marginTop: 0,
    },
  },
}));

type Props = {
  items: InputAny[];
  values: any;
  onChange: (values: any) => void;
  onUploadImage?: (file: File) => Promise<string>;
  onClickButton?: (id: string, values: any) => void;
};

const Form: FC<Props> = (props) => {
  const { classes } = useStyles();
  const [_] = useI18n();

  const renderNumber = (settings: InputNumber) => {
    const { id, label, min, max, maxSlider, minSlider, step } = settings;
    return (
      <SliderWithField
        // disableSliderDelay
        value={props.values[id]}
        label={_(label)}
        onChange={(value) => props.onChange({ ...props.values, [id]: value })}
        min={min}
        step={step}
        minSlider={minSlider}
        maxSlider={maxSlider}
        max={max instanceof Function ? max(props.values) : max}
      />
    );
  };

  const renderCheckbox = (settings: InputCheckbox) => {
    const { id, label } = settings;
    return (
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={props.values[id]}
            onChange={(e) =>
              props.onChange({ ...props.values, [id]: e.target.checked })
            }
          />
        }
        label={_(label)}
      />
    );
  };

  const renderText = (settings: InputText) => {
    const { id, label } = settings;
    return (
      <TextField
        variant="outlined"
        label={_(label)}
        fullWidth
        value={props.values[id]}
        onChange={(e) =>
          props.onChange({ ...props.values, [id]: e.target.value })
        }
      />
    );
  };

  const renderFile = (settings: InputFile) => {
    const { id, label, accept } = settings;
    return (
      <SelectFile
        label={label}
        accept={accept}
        selected={!!props.values[id]}
        onChange={async (file) => {
          const key = await props.onUploadImage?.(file);
          props.onChange({ ...props.values, [id]: key });
        }}
      />
    );
  };

  const renderColor = (settings: InputColor) => {
    const { id, label } = settings;
    return (
      <ColorPicker
        label={_(label)}
        value={props.values[id]}
        onChange={(value) => props.onChange({ ...props.values, [id]: value })}
      />
    );
  };

  const renderSelect = (settings: InputSelect) => {
    const { id, label, options } = settings;
    return (
      <TextField
        select
        fullWidth
        label={_(label)}
        variant="outlined"
        value={props.values[id]}
        onChange={(e) => {
          props.onChange({ ...props.values, [id]: e.target.value });
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {_(label)}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const filterItems = (i: InputAny) => {
    if (!i.show) {
      return true;
    }
    return i.show(props.values);
  };

  return (
    // eslint-disable-next-line
    <div
      className={classes.root}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
    >
      {props.items.filter(filterItems).map((i) => {
        if (i.type === "number") {
          return (
            <div className={classes.item} key={i.id}>
              {renderNumber(i)}
            </div>
          );
        }
        if (i.type === "checkbox") {
          return (
            <div className={classes.item} key={i.id}>
              {renderCheckbox(i)}
            </div>
          );
        }
        if (i.type === "color") {
          return (
            <div className={classes.item} key={i.id}>
              {renderColor(i)}
            </div>
          );
        }
        if (i.type === "select") {
          return (
            <div className={classes.item} key={i.id}>
              {renderSelect(i)}
            </div>
          );
        }
        if (i.type === "text") {
          return (
            <div className={classes.item} key={i.id}>
              {renderText(i)}
            </div>
          );
        }
        if (i.type === "file") {
          return (
            <div className={classes.item} key={i.id}>
              {renderFile(i)}
            </div>
          );
        }
        if (i.type === "button") {
          return (
            <div className={classes.item} key={i.id}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={() => props.onClickButton?.(i.id, props.values)}
              >
                {_(i.label)}
              </Button>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Form;
