import React, { Component } from "react";
import intersection from "lodash/intersection";
import Button from "@mui/material/Button";
import SimpleDialog from "./SimpleDialog";
import ImageListWithMarkers from "./ImageListWithMarkers/ImageListWithMarkers";
import ModuleRightToolPanel from "./ModuleRightToolPanel";
import ModuleSettingsWrap from "./ModuleSettingsWrap";
import { Image } from "../types/common";
import { UnsupervisedModel, UnsupervisedModule } from "../types/modules";
import withI18n from "../utils/withI18n";
import { connect, ConnectedProps } from "react-redux";
import { addErrorMessage } from "../store/system/reducer";
import ClassNameListAll from "./ClassNameListAll";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";

const mapDispatchToProps = {
  addErrorMessage,
};

const connector = connect(null, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  open: boolean;
  onClose: () => void;
  onStart: (data: { ok: number[]; nok: number[] }) => void;
  onChangeSensitivityClassification: (data: {
    ok: number[];
    nok: number[];
  }) => void;
  images: Image[];
  model: UnsupervisedModel;
  module: UnsupervisedModule;
  t: (key: string) => string;
  getImageItem: (image: Image) => any;
};

type State = {
  annotationOk: number[];
  annotationNok: number[];
  showErrorDialog: boolean;
  showAutoClassifierDialog: boolean;
  selectedIds: number[];
  currentImage: null | Image;
};

class AutoSensitivityDialog extends Component<Props, State> {
  state: State = {
    currentImage: null,
    annotationOk: [],
    annotationNok: [],
    showErrorDialog: false,
    showAutoClassifierDialog: false,
    selectedIds: [],
  };

  componentDidMount() {
    if (this.props.open) {
      this.initData(this.props);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      this.initData(this.props);
    }
  }

  initData = (props: Props) => {
    const { images } = props;
    const classification = props.model.sensitivityClassification || {
      ok: [],
      nok: [],
    };
    const imagesIds = images.map((i) => i.id);
    this.setState({
      annotationNok: intersection(classification.nok, imagesIds),
      annotationOk: intersection(classification.ok, imagesIds),
    });
  };

  handleClickStart = () => {
    if (
      this.state.annotationOk.length > 0 //&&
      // this.state.annotationNok.length > 0
    ) {
      this.props.onStart({
        ok: this.state.annotationOk,
        nok: this.state.annotationNok,
      });
    } else {
      this.setState({
        showErrorDialog: true,
      });
    }
  };

  handleClickClose = () => {
    this.props.onClose();
  };

  getImageLabel = (i: Image, isValidate: boolean) => {
    const _ = this.props.t;
    const ok = this.state.annotationOk.indexOf(i.id) !== -1;
    const nok = this.state.annotationNok.indexOf(i.id) !== -1;
    // eslint-disable-next-line no-nested-ternary
    const label = `${isValidate ? "V " : isValidate === false ? "T " : ""}${
      ok ? _("ok") : ""
    }${nok ? _("ng") : ""}`;
    return { label, isOk: ok, isNok: nok };
  };

  handleClickAutoClassification = () => {
    this.setState({
      showAutoClassifierDialog: true,
    });
  };

  handleClickResetModuleValues = () => {
    this.setState({
      annotationNok: [],
      annotationOk: [],
    });

    this.props.onChangeSensitivityClassification({
      ok: [],
      nok: [],
    });
  };

  render() {
    const _ = this.props.t;

    const actionsError = [
      <Button
        color="primary"
        onClick={() => this.setState({ showErrorDialog: false })}
      >
        {_("close")}
      </Button>,
    ];

    const trainingOk = intersection(
      this.props.model.classification ? this.props.model.classification.ok : [],
      this.props.model.trainingImages
    );

    const ok = this.state.annotationOk;
    const nok = this.state.annotationNok;

    const classNames = [
      { id: "ok", label: _("ok"), count: ok.length },
      { id: "nok", label: _("ng"), count: nok.length },
    ];

    return (
      <>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <DialogTitle sx={{ mb: 0 }}>{_("auto_sensitivity")}</DialogTitle>
          <ImageListWithMarkers
            ImageListProps={{
              showSelectedFilter: true,
              showOkNokFilter: true,
              selectMore: true,
              onSelectMore: (ids) =>
                this.setState({
                  selectedIds: ids,
                }),
              getImageItem: (i: Image) => {
                const item = this.props.getImageItem(i);
                const disabled = trainingOk.indexOf(i.id) !== -1;
                const { label, isOk, isNok } = this.getImageLabel(
                  i,
                  item.isValidate
                );
                return {
                  ...item,
                  isOk,
                  isNok,
                  disabled,
                  label2: disabled ? item.label2 : label,
                };
              },
            }}
            withMask
            moduleId={this.props.module.id}
            readOnly
            includeLast
            detailHeight="calc(100vh - 266px)"
            listHeight="calc(100vh - 266px)"
            disabledKeyListener={this.state.showAutoClassifierDialog}
            onSelectImage={(image) => {
              this.setState({
                currentImage: image,
              });
            }}
          >
            <ModuleRightToolPanel>
              <ModuleSettingsWrap title={_("annotations")}>
                <ClassNameListAll
                  items={classNames}
                  values={{
                    ok: this.state.annotationOk,
                    nok: this.state.annotationNok,
                  }}
                  selectedIds={this.state.selectedIds}
                  currentImage={this.state.currentImage || undefined}
                  onChange={(data) => {
                    this.setState({
                      annotationOk: data.ok,
                      annotationNok: data.nok,
                    });
                    this.props.onChangeSensitivityClassification({
                      ok: data.ok,
                      nok: data.nok,
                    });
                  }}
                  images={this.props.images}
                  disabledKeyListener={this.state.showAutoClassifierDialog}
                  onChangeShowAutoClassifier={(show) =>
                    this.setState({ showAutoClassifierDialog: show })
                  }
                />
              </ModuleSettingsWrap>
            </ModuleRightToolPanel>
          </ImageListWithMarkers>
          <DialogActions>
            <Button color="primary" onClick={this.handleClickClose}>
              {_("close")}
            </Button>
            <Button color="primary" onClick={this.handleClickStart}>
              {_("submit")}
            </Button>
          </DialogActions>
        </Dialog>
        <SimpleDialog
          actions={actionsError}
          open={this.state.showErrorDialog}
          onRequestClose={() =>
            this.setState({
              showErrorDialog: false,
            })
          }
        >
          {_("unsupervised_calc_error")}
        </SimpleDialog>
      </>
    );
  }
}

export default connector(withI18n(AutoSensitivityDialog));
