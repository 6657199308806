import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import useWindowSize from "../../../windowsSize";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TutorialAnomaly from "../../AnomalyTutorial/AnomalyTutorialContainer";
import { HELP_PANEL_WIDTH_MIN, MIN_CONTENT_WIDTH } from "../../../theme";

const useStyles = makeStyles()({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    height: `calc(100vh)`,
    padding: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px",
    overflowY: "auto",
    background: "white",
    zIndex: 4,
  },
  activePanel: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 5,
    height: `calc(100vh)`,
    // backgroundColor: 'red',
    cursor: "ew-resize",
  },
  movePanel: {
    position: "absolute",
    // right: HELP_PANEL_WIDTH,
    top: 0,
    width: 2,
    height: `calc(100vh)`,
    backgroundColor: "#0fbaeb",
  },
  bg: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 999999,
    cursor: "ew-resize",
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
});

type Props = {
  helpPanelWidth: number;
  setHelpPanelWidth: (width: number) => void;
  showTutorial: boolean;
  closeTutorial: () => void;
  setShowDoc: (show: boolean) => void;
};

const HelpPanelView = (props: Props) => {
  const { classes } = useStyles();
  const windowSize = useWindowSize();
  const [active, changeActive] = useState(false);
  const [startPos, changeStartPos] = useState(null);
  const [lastPos, changeLastPos] = useState(null);
  const offsetX =
    lastPos !== null && startPos !== null ? startPos - lastPos : 0;
  const offsetXCut = Math.min(
    windowSize.innerWidth - MIN_CONTENT_WIDTH - props.helpPanelWidth,
    offsetX
  );
  const panelWidth = Math.max(
    HELP_PANEL_WIDTH_MIN,
    props.helpPanelWidth + (active ? offsetXCut : 0)
  );
  useEffect(() => {
    if (!active && offsetXCut < 0) {
      props.setHelpPanelWidth(
        Math.max(HELP_PANEL_WIDTH_MIN, props.helpPanelWidth + offsetXCut)
      );
    }
    // eslint-disable-next-line
  }, [offsetXCut, active, props.helpPanelWidth]);

  const handleEndPos = () => {
    props.setHelpPanelWidth(
      Math.max(HELP_PANEL_WIDTH_MIN, props.helpPanelWidth + offsetXCut)
    );
    changeActive(false);
    changeStartPos(null);
    changeLastPos(null);
  };

  return (
    <div className={classes.root} style={{ width: panelWidth }}>
      {props.showTutorial && <TutorialAnomaly />}
      <IconButton
        className={classes.closeBtn}
        onClick={() =>
          props.showTutorial ? props.closeTutorial() : props.setShowDoc(false)
        }
        size="large"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      {!offsetXCut && (
        // eslint-disable-next-line
        <div
          className={classes.activePanel}
          onMouseDown={() => {
            changeActive(true);
          }}
        />
      )}
      {active && (
        // eslint-disable-next-line
        <div
          className={classes.bg}
          onMouseMove={(e: React.MouseEvent) => {
            if (startPos === null) {
              changeStartPos((e as any).clientX);
            }
            changeLastPos((e as any).clientX);
          }}
          onMouseUp={handleEndPos}
          onMouseLeave={handleEndPos}
        >
          {/* eslint-disable-next-line  */}
          <div
            style={{ right: panelWidth }}
            className={classes.movePanel}
            onMouseDown={() => {
              changeActive(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HelpPanelView;
