import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import { Checkbox, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "tss-react/mui";
import useI18n from "../../../../hooks/useI18n";
import { OutputItem } from "../../../../store/database/reducer";

const useStyles = makeStyles()({
  timeout: {
    textAlign: "right",
    width: 100,
  },
});

type Props = {
  values: OutputItem;
};

const TcpForm: FC<Props> = (props) => {
  const [_] = useI18n();
  const { classes } = useStyles();
  return (
    <>
      <Grid item>
        <Grid item>
          <FormControlLabel
            control={
              <Field
                component={Checkbox}
                type="checkbox"
                name="contextToMessage"
              />
            }
            label={_("context_to_message")}
          />
        </Grid>
        {!props.values.contextToMessage && (
          <Field
            disabled={false}
            name="message"
            label={_("message_in_utf8")}
            margin="dense"
            fullWidth
            variant="outlined"
            component={TextField}
            validate={(value: string) => !value && _("required")}
            url
          />
        )}
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="ip"
          label="IP"
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
          validate={(value: string) => !value && _("required")}
          url
        />
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="port"
          label="port"
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
          inputProps={{
            type: "number",
            max: 65535,
            min: 0,
          }}
          /* eslint-disable-next-line consistent-return */
          validate={(value: string) => {
            if (!value) return _("required");
            if (!RegExp("^d+$")) return _("number_error_not_number");
            if (parseInt(value, 10) > 65535)
              return `${_("error")} (${_("must_be")} <= 65535)`;
            if (parseInt(value, 10) < 0) return _("number_error_negative");
          }}
          url
        />
      </Grid>
      {/* <Grid item>
        <FormControlLabel
          control={<Field component={Checkbox} name="waitToResponse" />}
          label={_('wait_to_response')}
        />
      </Grid> */}
      <Grid item>
        <FormControlLabel
          control={
            <Field component={Checkbox} type="checkbox" name="enableTimeout" />
          }
          label={_("enable_timeout")}
        />
      </Grid>
      {props.values.enableTimeout && (
        <Grid item>
          <Field
            className={classes.timeout}
            disabled={false}
            name="timeout"
            label={_("timeout")}
            margin="dense"
            type="number"
            variant="outlined"
            component={TextField}
            validate={(value: string) => !value && _("required")}
            InputProps={{
              endAdornment: <InputAdornment position="start">s</InputAdornment>,
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default TcpForm;
