import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import withI18n from "../utils/withI18n";

type Props = {
  onClose: () => void;
  onUpdate: (name: string) => void;
  open?: boolean;
  label?: string;
  t: (key: string) => string;
};

type State = {
  label: string;
};

class EditNameDialog extends Component<Props, State> {
  state: State = {
    label: "",
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      this.setState({
        label: this.props.label || "",
      });
    }
  }

  handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClose();
  };

  handleClickUpdate = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onUpdate(this.state.label);
    this.props.onClose();
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.onUpdate(this.state.label);
    this.props.onClose();
  };

  render() {
    const { onClose, open } = this.props;
    const _ = this.props.t;

    return (
      <Dialog open={!!open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <TextField
              fullWidth
              autoFocus
              label={_("name")}
              value={this.state.label}
              onChange={(e) => this.setState({ label: e.target.value })}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClickClose}>
            {_("close")}
          </Button>
          <Button
            color="primary"
            disabled={this.props.label === this.state.label}
            onClick={this.handleClickUpdate}
          >
            {_("save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withI18n(EditNameDialog);
