import React, { Component } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { codeSaveSourceCode, codeUpdateName } from "../../../actions/actions";
import Console from "../../../components/Console";
import Editor from "../../../components/templates/Editor";
import {
  PageToolbarButton,
  PageToolbarSpace,
} from "../../../components/PageToolbar";
import { CON_MIN_HEIGHT_HEADER_TOOLPANEL } from "../../../theme";
import "brace/mode/python";
import "brace/theme/github";
import { getImageData, ImageData } from "../../../actions/ImageService";
import NavigationPrompt from "../../../components/NavigationPromp";
import { PropsFromRedux } from "../DetailContainer";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import { Modules } from "../../../modules";

type State = {
  value: string;
  showConsole: boolean;
  activeImageId?: number;
  log: null | ImageData;
  itemId: null | number;
  prevPropsSourceCode: string;
  prevPropsModuleId: number;
};

class CodeView extends Component<
  PropsFromRedux & { t: (key: string) => string },
  State
> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    value: this.props.sourceCode || "",
    showConsole: false,
    log: null,
    itemId: null,
    prevPropsSourceCode: this.props.sourceCode || "",
    prevPropsModuleId: this.props.moduleId,
  };

  static getDerivedStateFromProps(props: PropsFromRedux, state: State) {
    if (
      state.prevPropsSourceCode !== props.sourceCode ||
      state.prevPropsModuleId !== props.moduleId
    ) {
      return {
        value: props.sourceCode || "",
        prevPropsSourceCode: props.sourceCode || "",
        prevPropsModuleId: props.moduleId,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: PropsFromRedux) {
    if (prevProps.fileId !== this.props.fileId) {
      if (this.state.showConsole) {
        this.getCurrentImageLogs();
      }
    }
  }

  getCurrentImageLogs = (activeImageId = this.state.activeImageId) => {
    if (!activeImageId) {
      return;
    }
    this.setState({
      log: null,
    });
    const flow = this.context.helper.getSettings({
      moduleId: this.props.moduleId,
      includeLast: false,
      otherModule: {
        id: this.props.moduleId,
        type: Modules.CODE,
        fileId: this.props.fileId,
      },
    });
    getImageData(activeImageId, flow, this.context.helper.token).then(
      (response) => {
        this.setState({ log: response.data });
      }
    );
  };

  handleUpdate = () => {
    if (this.props.sourceCode === this.state.value) {
      return;
    }
    codeSaveSourceCode({
      moduleId: this.props.moduleId,
      sourceCode: this.state.value,
      itemId: this.props.itemId,
    });
  };

  render() {
    const _ = this.props.t;
    return (
      <>
        <NavigationPrompt
          when={this.props.sourceCode !== this.state.value}
          message={_("changes_not_save")}
        />
        <Editor
          onClickBack={() =>
            this.props.history.push(`/modules/code/${this.props.moduleId}/`)
          }
          onChangeName={(name) => {
            if (this.props.itemId) {
              codeUpdateName({ itemId: this.props.itemId, name });
            }
          }}
          onSelectImage={(activeImage) => {
            this.setState({ activeImageId: activeImage.id });
            if (this.state.showConsole) {
              this.getCurrentImageLogs(activeImage.id);
            }
          }}
          onSave={this.handleUpdate}
          activeId={this.state.activeImageId}
          moduleId={this.props.moduleId}
          name={this.props.name || ""}
          isChange={this.props.sourceCode !== this.state.value}
          menuItems={[
            <PageToolbarSpace />,
            <PageToolbarButton
              icon={
                this.state.showConsole ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )
              }
              onClick={() => {
                if (!this.state.showConsole) {
                  this.getCurrentImageLogs();
                }
                this.setState({ showConsole: !this.state.showConsole });
              }}
              label={_(
                this.state.showConsole ? "hide_console" : "show_console"
              )}
            />,
            <PageToolbarButton
              rightAllign
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(
                  `/modules/code/${this.props.moduleId}/${this.props.itemId}/images/`
                );
              }}
              label={_("code_preview_detection")}
            />,
          ]}
          getImageSrc2={(image) => ({
            imageId: image.id,
            moduleId: this.props.moduleId,
            includeLast: false,
            withMask: true,
            otherModule: {
              id: this.props.moduleId,
              type: "CODE",
              fileId: this.props.fileId,
            },
          })}
        >
          <AceEditor
            key={this.props.moduleId}
            mode="python"
            width="100%"
            height={
              this.state.showConsole
                ? `calc(${CON_MIN_HEIGHT_HEADER_TOOLPANEL} - 300px)`
                : `calc(${CON_MIN_HEIGHT_HEADER_TOOLPANEL})`
            }
            theme="github"
            value={this.state.value}
            onChange={(value) => this.setState({ value })}
            name={`UNIQUE_ID_OF_DIV_${this.props.moduleId}`}
            editorProps={{ $blockScrolling: true }}
            enableLiveAutocompletion
            enableBasicAutocompletion
            enableSnippets
            commands={[
              {
                name: "save",
                bindKey: { win: "Ctrl-S", mac: "Cmd-S" },
                exec: this.handleUpdate,
              },
            ]}
          />
          {this.state.showConsole && (
            <Console style={{ height: 300 }} data={this.state.log} />
          )}
        </Editor>
      </>
    );
  }
}

export default withI18n(CodeView);
