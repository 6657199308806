/* max-classes-per-file: 0 */
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import lodash from "lodash";
import { getImageWithData } from "../actions/ImageService";
import Rectangles from "./Rectangles";
import { mount, unmount, loaded } from "../store/images/reducer";
import { GlobalState } from "../store/createStore";
import { GetImageParams, ImageSize, Line, Rectangle } from "../types/common";
import { HelperContext } from "../layouts/PageLayout/PageLayout";
import { useResizeDetector } from "react-resize-detector";

const styles = {
  imgWrap: {
    display: "inline-block",
    position: "relative",
  },
  imageWrap: {
    position: "relative",
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    overflow: "hidden",
    height: "100%",
    position: "relative",
    boxSizing: "content-box",
  },
  result: {
    position: "absolute",
    top: 2,
    right: -57,
    width: 160,
    height: 20,
    transform: "rotate(45deg)",
  },
  resultOk: {
    backgroundColor: "green",
  },
  resultNok: {
    backgroundColor: "red",
  },
} as const;

type ImageWithRectanglesProps = {
  min: boolean;
  onLoad?: () => void;
  image: GetImageParams;
  withoutProcessing?: boolean;
};

type State = {
  size: null | ImageSize;
  rectangles: Rectangle[];
  lines: Line[];
  ocr: any[];
  result: null | boolean;
  imageUrl: string | null;
};

const ImageWithRectangles: FC<ImageWithRectanglesProps> = (props) => {
  const context = useContext(HelperContext);
  const [state, setState] = useState<State>({
    size: null,
    rectangles: [],
    lines: [],
    result: null,
    ocr: [],
    imageUrl: null,
  });

  useEffect(() => {
    let localImageUrl: string;
    const flow = props.withoutProcessing
      ? []
      : context.helper.getSettings(props.image);

    getImageWithData(
      {
        flow,
        imageId: props.image.imageId,
        min: props.image.min || true,
        withMask: true,
      },
      true
    ).then(({ imageUrl, data }) => {
      localImageUrl = imageUrl;
      setState({
        rectangles: data.rectangles,
        ocr: data.ocr,
        lines: data.lines,
        result: data.result,
        size: data.imageSize,
        imageUrl,
      });

      if (props.onLoad) {
        props.onLoad();
      }
    });
    return () => {
      if (localImageUrl?.startsWith("blob:")) {
        URL.revokeObjectURL(localImageUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ref, width, height } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 10,
  });

  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `url(${
          !props.withoutProcessing ? state.imageUrl : state.imageUrl
        })`,
        ...styles.image,
      }}
    >
      {width && height && state.size && (
        <Rectangles
          imageSize={state.size as ImageSize}
          items={[...state.rectangles, ...state.ocr]}
          lines={state.lines}
          wrapSize={{
            width,
            height,
          }}
        />
      )}
      {state.result !== null && (
        <div
          style={{
            ...styles.result,
            ...(state.result ? styles.resultOk : styles.resultNok),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const { canLoad } = state.images;
  return {
    canLoad,
  };
};

const connector = connect(mapStateToProps, {
  imageMount: mount,
  imageUnmount: unmount,
  imageLoaded: loaded,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type WrapProps = PropsFromRedux & ImageWithRectanglesProps;

const Wrap: FC<WrapProps> = (props) => {
  const imageKeyRef = useRef<string | null>(null);
  const context = useContext(HelperContext);
  const flow = context.helper.getSettings(props.image);
  const needMoreTime = !props.withoutProcessing && flow.length > 0;

  useEffect(() => {
    if (needMoreTime) {
      imageKeyRef.current = lodash.uniqueId();
      props.imageMount(imageKeyRef.current);
      return () => {
        if (imageKeyRef.current) {
          props.imageUnmount(imageKeyRef.current);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    needMoreTime &&
    (imageKeyRef.current === null ||
      props.canLoad.indexOf(imageKeyRef.current) === -1)
  ) {
    return null;
  }

  return (
    <ImageWithRectangles
      {...props}
      onLoad={() => {
        props.imageLoaded();
      }}
    />
  );
};

export default connector(Wrap);
