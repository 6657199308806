import React, { FC } from "react";
import Button from "@mui/material/Button";
import ModelInfo from "./ModelInfo";
import Dialog from "./SimpleDialog";
import { AnyModel } from "../types/modules";
import useI18n from "../hooks/useI18n";

const styles = {
  dialog: {
    width: 700,
  },
};

type Props = {
  open?: boolean;
  onClose: () => void;
  model?: AnyModel;
};

const ModelInfoDialog: FC<Props> = ({ open, onClose, model }) => {
  const [_] = useI18n();

  const handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const actions = [
    <Button color="primary" onClick={handleClickClose}>
      {_("close")}
    </Button>,
  ];

  return (
    <Dialog
      actions={actions}
      open={!!open}
      onRequestClose={onClose}
      contentStyle={styles.dialog}
      title={model && model.name}
    >
      {model && <ModelInfo model={model} diffTime={0} />}
    </Dialog>
  );
};

export default ModelInfoDialog;
