import { createContext } from "react";

type TokenCtx = {
  token: string;
};

const defaultTokenCtx = {
  token: "",
};

export default createContext<TokenCtx>(defaultTokenCtx);
