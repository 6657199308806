import { connect, ConnectedProps } from "react-redux";
import UnifierDetailView from "./components/UnifierDetailView";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { UnifierItem } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string; itemId: string }>
) => {
  const template = state.database.unifierItems.find(
    (i) => i.itemId === +ownProps.match.params.itemId
  ) as UnifierItem;
  const moduleId = Number(ownProps.match.params.moduleId);
  return {
    history: ownProps.history,
    moduleId,
    template,
    module,
    noImage: state.database.images.length === 0,
  };
};

const connector = connect(mapStateToProps, {});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UnifierDetailView);
