import browserImageSize from "browser-image-size";
import { useEffect, useState } from "react";

const useImageSize = (src: string) => {
  const [imageSize, setImageSize] =
    useState<{ width: number; height: number } | null>(null);
  useEffect(() => {
    browserImageSize(src).then(setImageSize);
  }, [src]);
  return imageSize;
};

export default useImageSize;
