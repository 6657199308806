import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "../SimpleDialog";
import withI18n from "../../utils/withI18n";
import { EvaluationRules, Rule } from "../../types/modules";

const compareTypes = [
  {
    id: "MORE",
    label: ">",
  },
  {
    id: "LESS",
    label: "<",
  },
  {
    id: "EQUAL",
    label: "=",
  },
  {
    id: "NOT_EQUAL",
    label: "≠",
  },
];

const styles = {
  line: {
    display: "flex",
  },
  classname: {
    marginRight: 10,
    width: 160,
  },
  type: {
    marginRight: 10,
    width: 160,
  },
  compare: {
    marginRight: 10,
    width: 80,
  },
  value: {
    width: 60,
  },
  regex: {
    width: 160,
  },
  addRuleButton: {
    margin: "10px 0",
  },
  removeRuleButton: {
    alignSelf: "flex-end",
  },
  checkbox: {
    marginBottom: 10,
    display: "block",
  },
} as const;

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (values: EvaluationRules) => void;
  values: EvaluationRules;
  compareTypes?: { id: string; label: string }[];
  valueTypes?: { id: string; label: string }[];
  classNameList?: { id: string | number; label: string }[];
  t: (key: string) => string;
  valueType?: "string" | "number";
};

type State = {
  rules: Rule[];
  enable: boolean;
  prevOpen: boolean;
};

class EvaluationDialog extends React.Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  state: State = {
    rules: [],
    enable: false,
    prevOpen: this.props.open,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.open && !state.prevOpen) {
      return {
        rules: props.values.rules || [],
        enable: props.values.enable || false,
        prevOpen: true,
      };
    } else if (!props.open && state.prevOpen) {
      return {
        prevOpen: false,
      };
    }
    return null;
  }

  handleChangeLine = (
    type: "classname" | "type" | "compare" | "value",
    index: number,
    value: any
  ) => {
    this.setState({
      rules: this.state.rules.map((l, i) =>
        i === index ? { ...l, [type]: value } : l
      ),
    });
  };

  getCompareTypes = () => this.props.compareTypes || compareTypes;

  handleClickAddRule = () => {
    const compareTypes = this.getCompareTypes();
    const defaultLine: Rule = {
      classname: 0,
      value: this.props.valueType === "string" ? "" : 0,
    };

    if (compareTypes.length > 0) {
      defaultLine.compare = compareTypes[0].id;
    }

    if (this.props.valueTypes) {
      defaultLine.type = this.props.valueTypes[0].id;
    }

    this.setState({
      rules: [...this.state.rules, defaultLine],
      enable: true,
    });
  };

  handleRemoveRule = (index: number) => {
    this.setState({
      rules: this.state.rules.filter((_, i) => i !== index),
    });
  };

  render() {
    const { valueTypes } = this.props;
    const _ = this.props.t;
    const actions = [
      <Button
        color="primary"
        onClick={() => {
          this.props.onClose();
        }}
      >
        {_("close")}
      </Button>,
      <Button
        color="primary"
        onClick={() => {
          this.props.onClose();
          this.props.onSave({
            rules: this.state.rules,
            enable: this.state.enable,
          });
        }}
      >
        {_("save_and_close")}
      </Button>,
    ];

    return (
      <Dialog
        title={_("evaluation")}
        actions={actions}
        open={this.props.open}
        contentStyle={{ minWidth: 380 }}
        onRequestClose={this.props.onClose}
      >
        <FormControlLabel
          style={styles.checkbox}
          control={
            <Checkbox
              disabled={this.state.rules.length === 0}
              checked={this.state.enable && this.state.rules.length > 0}
              onChange={(e) => this.setState({ enable: e.target.checked })}
            />
          }
          label={_("enable")}
        />

        {this.state.rules.map((line, index) => (
          <div style={styles.line}>
            {this.props.classNameList &&
              this.props.classNameList.length > 0 && (
                <TextField
                  style={styles.classname}
                  margin="dense"
                  variant="outlined"
                  value={line.classname}
                  label={_("classname")}
                  onChange={(e) =>
                    this.handleChangeLine("classname", index, e.target.value)
                  }
                  select
                >
                  <MenuItem value={-2}>{_("any")}</MenuItem>
                  <MenuItem value={-1}>{_("every")}</MenuItem>
                  <MenuItem value={-3}>{_("together")}</MenuItem>
                  {this.props.classNameList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            {valueTypes && (
              <TextField
                style={styles.type}
                label={_("type")}
                margin="dense"
                variant="outlined"
                value={line.type}
                onChange={(e) =>
                  this.handleChangeLine("type", index, e.target.value)
                }
                select
              >
                {valueTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {this.getCompareTypes().length > 0 && (
              <TextField
                style={styles.compare}
                margin="dense"
                variant="outlined"
                value={line.compare}
                onChange={(e) =>
                  this.handleChangeLine("compare", index, e.target.value)
                }
                label={_("compare")}
                select
              >
                {this.getCompareTypes().map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              margin="dense"
              variant="outlined"
              style={
                this.props.valueType === "string" ? styles.regex : styles.value
              }
              onBlur={(e) => {
                if (
                  e.target.value === "" &&
                  this.props.valueType === "number"
                ) {
                  this.handleChangeLine("value", index, 0);
                }
              }}
              value={line.value}
              onChange={(e) => {
                let value: string | number = "";
                if (this.props.valueType === "string") {
                  value = e.target.value;
                } else {
                  value = e.target.value !== "" ? parseInt(e.target.value) : "";
                }

                this.handleChangeLine("value", index, value);
              }}
              label={_(this.props.valueType === "string" ? "regex" : "value")}
              InputLabelProps={{
                shrink: true,
              }}
              type={this.props.valueType || "number"}
            />
            <IconButton
              style={styles.removeRuleButton}
              onClick={() => this.handleRemoveRule(index)}
              size="large"
            >
              <RemoveIcon />
            </IconButton>
          </div>
        ))}
        <Button
          variant="contained"
          onClick={this.handleClickAddRule}
          color="primary"
          style={styles.addRuleButton}
          startIcon={<AddIcon />}
        >
          {_("add_rule")}
        </Button>
      </Dialog>
    );
  }
}

export default withI18n(EvaluationDialog);
