import React, { FC, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { useDebouncedCallback } from "use-debounce";

const useStyles = makeStyles<void, 'wrap'>()((theme: Theme, _params, classes) => ({
  wrap: {
    position: "relative",
    [`&+.${classes.wrap}`]: {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    width: 90,
  },
  slider: {
    position: "absolute",
    right: 10,
    width: "calc(100% - 80px)",
  },
  sliderInner: {
    margin: 0,
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  value: number | string;
  onChange: (value: number) => void;
  label: string;
  min?: number;
  step?: number;
  max?: number;
  maxSlider?: number;
  minSlider?: number;
  update?: boolean;
  debounce?: boolean;
  disableSliderDelay?: boolean;
  marginTop?: boolean;
  size?: "small" | "medium";
};

const SliderWithField: FC<Props> = ({
  marginTop,
  disableSliderDelay,
  value,
  onChange,
  label,
  min = 0,
  step,
  max = Infinity,
  maxSlider,
  minSlider,
  update,
  size,
}) => {
  const { classes, cx } = useStyles();
  const { _ } = window as any;
  const [localValue, setLocalValue] = useState<number | string>(value);
  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    (e: number) => {
      onChange(e);
    },
    // delay in ms
    500
  );

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const parseNumber = (value: number | string) => {
    const number = typeof value === "number" ? value : parseFloat(value);
    if (isNaN(value as any) || value === "") {
      return _("number_error_not_number");
    }
    if (number > max) {
      return `${_("error")} (${_("must_be")} <= ${max})`;
    }
    if (number < min) {
      return min === 0
        ? _("number_error_negative")
        : `${_("error")} (${_("must_be")} >= ${min})`;
    }

    if (
      step !== undefined &&
      (number - min) % step !== 0 &&
      Number.isInteger(step)
    ) {
      return step === 1 ? _("must_be_integer") : _("error");
    }
    return null;
  };

  const errorMessage = parseNumber(localValue);

  return (
    <div className={cx(classes.wrap, marginTop && classes.marginTop)}>
      <TextField
        InputProps={{
          style: {
            whiteSpace: "nowrap",
          },
        }}
        fullWidth
        variant="outlined"
        size={size}
        value={localValue}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        inputProps={{
          step: step,
          className: classes.input,
          onBlur: (e) => {
            if (isNaN(e.target.value as any) || e.target.value === "") {
              callPending();
              setLocalValue(value);
            }
          },
        }}
        onChange={(e) => {
          cancel();
          const { value } = e.target;
          const intValue = parseFloat(value);
          setLocalValue(
            isNaN(value as any) || intValue.toString() !== value
              ? value
              : intValue
          );
          if (
            !isNaN(value as any) &&
            intValue !== localValue &&
            parseNumber(intValue) === null
          ) {
            debouncedCallback(intValue);
          }
        }}
        label={label}
        InputLabelProps={{
          shrink: true,
          style: {
            whiteSpace: "nowrap",
          },
        }}
        FormHelperTextProps={{
          style: {
            whiteSpace: "nowrap",
          },
        }}
        error={!!errorMessage}
        helperText={errorMessage}
        onDragStart={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        draggable
      />
      <Slider
        /*sliderStyle={classes.sliderInner}*/
        className={classes.slider}
        style={{ top: size === "small" ? 7 : 14 }}
        min={minSlider || min}
        max={maxSlider || max}
        step={step}
        value={typeof localValue === "number" ? localValue : min}
        onChangeCommitted={(e_, v) => {
          if (value !== v) {
            cancel();
            onChange(v as number);
          }
        }}
        onChange={(_, v) => {
          setLocalValue(v as number);
          if (disableSliderDelay && value !== v) {
            onChange(v as number);
          }
        }}
      />
    </div>
  );
};

export default SliderWithField;
