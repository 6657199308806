import { GlobalState } from './../../store/createStore';
import { connect, ConnectedProps } from "react-redux";
import EditorView from "./components/EditorView";
import {
  enableCustomOperatorView,
  setCustomOperatorViewItems
} from "../../actions/actions";


const mapStateToProps = (state: GlobalState) => {
  return {
    customOperatorView: state.database.camera.customOperator.enable,
    customOperatorItems: state.database.camera.customOperator.items,
  };
};

const mapDispatchToProps = {
  enableCustomOperatorView,
  setCustomOperatorViewItems
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditorView)