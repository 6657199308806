import React from "react";
// import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import { CONTENT_PADDING } from '../theme'
// import { deepCompare } from '../utils/common'

/* const styles = {
  root: {
    backgroundColor: 'rgb(232, 232, 232)',
    padding: CONTENT_PADDING
  },
  checkbox: {
    display: 'inline-block',
    width: 'auto',
    whiteSpace: 'nowrap',
    marginRight: 35
  },
  gpuList: {
    marginTop: 10
  },
  button: {
    marginTop: 10
  }
} */

const NoGPUSettings: React.FC<any> = () => null;

export default NoGPUSettings;

/*
class GpuSettings extends Component {
  static propTypes = {
    gpuDevices: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onlyOneGpu: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      value: props.value
    }
  }

  handleChange(value) {
    this.setState({
      value
    })
  }

  handleSave = () => {
    this.props.onChange(this.state.value)
  }

  render() {
    const { onlyOneGpu } = this.props
    const { value } = this.state
    return (
      <div style={styles.root}>
        {!onlyOneGpu && (
          <div>
            <Checkbox
              label="All gpu"
              checked={value.length === 0}
              onCheck={e => {
                if (e.target.checked) {
                  this.handleChange([])
                } else {
                  this.handleChange([this.props.gpuDevices[0].id])
                }
              }}
            />
          </div>
        )}
        <div style={styles.gpuList}>
          {this.props.gpuDevices.map((i, index) =>
            onlyOneGpu ? (
              <RadioButton
                checked={value.indexOf(i.id) !== -1}
                onCheck={e => {
                  if (e.target.checked) {
                    this.handleChange([i.id])
                  }
                }}
                style={styles.checkbox}
                label={i.name}
                key={index}
              />
            ) : (
              <Checkbox
                checked={value.indexOf(i.id) !== -1}
                onCheck={e => {
                  if (e.target.checked) {
                    this.handleChange([...value, i.id])
                  } else {
                    this.handleChange(value.filter(k => k !== i.id))
                  }
                }}
                style={styles.checkbox}
                label={i.name}
                key={index}
              />
            )
          )}
        </div>
        {!deepCompare(this.props.value, value) && (
          <RaisedButton
            onClick={this.handleSave}
            label={_('save changes')}
            style={styles.button}
          />
        )}
      </div>
    )
  }
}
*/
