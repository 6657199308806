import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import useI18n from "../hooks/useI18n";

type Props = {
  open: boolean;
  onRestart: () => void;
  onClose: () => void;
};

const DangerSettingsDialog: FC<Props> = ({ open, onRestart, onClose }) => {
  const [_] = useI18n();

  const handleRestart = () => {
    onRestart();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const actions = [
    <Button color="primary" onClick={handleClose}>
      {_("danger_setting_ok")}
    </Button>,
    <Button color="primary" onClick={handleRestart}>
      {_("danger_setting_delete")}
    </Button>,
  ];

  return (
    <Dialog title={_("danger_setting_title")} actions={actions} open={open}>
      {_("danger_setting_message")}
    </Dialog>
  );
};

export default DangerSettingsDialog;
