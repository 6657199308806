import React from "react"
import { useEffect, useRef, useState } from "react";
import { Line, Circle } from "react-konva";
import Label from "./Label"
import Konva from "konva"
import { isRightClick } from "../utils";



type Props = {
  isSelected: boolean,
  points: [number, number][],
  onChange: (points: [number, number][]) => void,
  onSelect?: () => void,
  fill?: [number, number, number],
  stroke?: [number, number, number],
  onClickFirst?: () => void,
  listening?: boolean,
  scale: number,
  selectable?: boolean,
  moveable?: boolean,
  disabled?: boolean,
  text?: string
}

const Polygon = (props: Props) => {
  useEffect(() => {
    setMovePoints(props.points)
    setPoints(props.points)
  }, [props.points])
  const lineRef = useRef<Konva.Line>(null)
  const [points, setPoints] = useState<[number, number][]>(props.points)
  const [movePoints, setMovePoints] = useState<[number, number][]>(props.points)
  const allInOne: number[] = points.reduce(((a: number[], b) => a.concat(b)), [])
  const handleFinishMove = () => props.onChange(points)
  const { fill, stroke } = props
  const maxWidthPoint: number = movePoints.reduce((all, item) => all > item[0] ? item[0] : all, Infinity)
  const maxHeightPoint: number = movePoints.reduce((all, item) => all < item[1] ? item[1] : all, 0)

  const onSelect = (!props.disabled && props.selectable !== false) ? props.onSelect : undefined
  const draggable = !props.disabled && props.moveable !== false

  return (
    <>
      <Line
        onClick={(e) => !isRightClick(e) && onSelect?.()}
        onTab={props.onSelect}

        onDragEnd={() => {
          setPoints(movePoints)
          lineRef.current?.setPosition({ x: 0, y: 0 })
          props.onChange(movePoints)
        }}
        listening={props.listening}
        stroke={props.isSelected ? "#5ebeff" : stroke ? `rgb(${stroke[0]},${stroke[1]},${stroke[2]},0.8)` : `rgba(120,255,0,0.8)`}
        fill={fill ? `rgb(${fill[0]},${fill[1]},${fill[2]},0.2)` : `rgba(120,255,0,0.3)`}
        ref={lineRef}
        draggable={draggable}
        onDragMove={(e) => {
          const offsetX = e.target.x()
          const offsetY = e.target.y()
          setMovePoints(points.map(i => [i[0] + offsetX, i[1] + offsetY]))
        }}
        closed
        points={allInOne}
        strokeWidth={((props.isSelected && draggable === false) ? 2 : 1) / props.scale}
      />
      <Label text={props.text} scale={props.scale} y={maxHeightPoint + 5 / props.scale} x={maxWidthPoint} />
      {props.isSelected && draggable && movePoints.map((point, index) => (
        <Circle
          key={index}
          onClick={index === 0 ? props.onClickFirst : undefined}
          onDragMove={(e) => {
            setPoints((prevPoints) => prevPoints.map((p, i) => i === index ? [e.target.x(), e.target.y()] : p))
          }}
          onDragEnd={() => {
            handleFinishMove()
            setMovePoints(points)
          }}
          draggable
          x={point[0]}
          y={point[1]}
          radius={6 / props.scale}
          fill={index === 0 ? "#5ebeff" : "white"}
          stroke="#5d9fc1"
          strokeWidth={1 / props.scale}
        />
      ))}
    </>
  )
}

export default Polygon