import React, { FC, useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ImageWithRectangleDinamic from "../../../components/ImageWithRectanglesDinamic";
import { DialogActions, DialogTitle } from "@mui/material";
import useI18n from "../../../hooks/useI18n";
import { typeToLabel } from "../../../modules";
import * as Flow from "../../../types/flow";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

type Props = {
  module?: Flow.AnyDebugger | null;
  onHide: () => void;
};

const DetailDialog: FC<Props> = (props) => {
  const [_] = useI18n();
  const { helper } = useContext(HelperContext);
  const [size, setSize] = useState<null | { width: number; height: number }>(
    null
  );
  return (
    <Dialog open={!!props.module} onClose={props.onHide} maxWidth={false}>
      {size && props.module && (
        <DialogTitle>
          {typeToLabel(props.module?.type)} - {size.width}x{size.height}
        </DialogTitle>
      )}
      {props.module?._imagePath && (
        <div
          style={{ height: `calc(100vh - 200px)`, width: `calc(100vw - 70px)` }}
        >
          <ImageWithRectangleDinamic
            onReciveSize={setSize}
            imageSrc={helper.getStaticFilePath(props.module._imagePath)}
            rectangles={props.module._detectedRectangles}
            lines={props.module._lines}
            ocr={props.module._ocr}
          />
        </div>
      )}
      <DialogActions>
        <Button color="primary" onClick={props.onHide}>
          {_("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailDialog;
