import React, { Component } from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import InfiniteScroll from "react-infinite-scroller";
import MarkedIcon from "@mui/icons-material/CropDin";
import FilterIcon from "@mui/icons-material/FilterList";
import WarningIcon from "@mui/icons-material/Warning";
import queryString from "query-string";
import debounce from "debounce";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GetImageParams, Image } from "../../types/common";
import { IMAGE_LIST_LEFT_PADDING, PRIMARY_COLOR } from "../../theme";
import * as sortFilterImages from "../../utils/sortFilterImages";
import ImageWithRectangles from "../ImageWithRectangles";
import FilterDialog from "./FilterDialog";
import { FilterImage, FilterSettings } from "../../utils/sortFilterImages";
import withI18n from "../../utils/withI18n";
import Tags from "../Tags";
import { Menu, MenuItem } from "@mui/material";
import { HelperContext } from "../../layouts/PageLayout/PageLayout";

const INIT_COUNT = 20;
const ADD_COUNT = 20;
const SCROLLBAR_HEIGHT = 11; // main.css
export const WRAP_WIDTH = 220;
export const WRAP_WIDTH_DOUBLE = 405;
const BORDER_WIDTH = 4;
const MARGIN_LEFT = 2;
const MIN_HEIGHT = 55;
const MAX_HEIGHT = 300;
const ITEM_BOTTOM_PADDING = 8;
const LABEL_HEIGHT = 18;
const CONTENT_INNER_PADDING = 10;

// calculate
const MAX_WIDTH = WRAP_WIDTH - SCROLLBAR_HEIGHT - MARGIN_LEFT;
const MAX_WIDTH_BORDER = MAX_WIDTH - BORDER_WIDTH;

const MAX_WIDTH_DOUBLE =
  (WRAP_WIDTH_DOUBLE - SCROLLBAR_HEIGHT - MARGIN_LEFT) / 2;
const MAX_WIDTH_DOUBLE_BORDER = MAX_WIDTH_DOUBLE - BORDER_WIDTH;

const styles = {
  rightPanel: {
    paddingLeft: IMAGE_LIST_LEFT_PADDING,
    paddingTop: CONTENT_INNER_PADDING,
    position: "relative",
  },
  itemWrap: {
    marginBottom: ITEM_BOTTOM_PADDING,
    position: "relative",
    whiteSpace: "nowrap",
    background: "rgb(38, 50, 56)",
    overflow: "hidden",
  },
  imageWrapSelectable: {
    border: `${BORDER_WIDTH}px solid #273339`,
  },
  imageWrapSelectableActive: {
    border: `${BORDER_WIDTH}px solid #5ba6ec`,
  },
  imageWrap: {
    float: "left",
    overflow: "hidden",
  },
  imageWrap2: {
    float: "left",
    borderLeft: `${MARGIN_LEFT}px solid white`,
    boxSizing: "content-box",
    overflow: "hidden",
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginLeft: -1,
    marginTop: -1,
    overflow: "hidden",
  },
  wrap: {
    flexFlow: "row",
    justifyContent: "space-around",
    display: "flex",
    alignItems: "stretch",
    position: "relative",
    padding: 0,
  },
  imagesWrap: {
    overflowY: "scroll",
    height: "calc(100% - 72px)",
  },
  imagesWrapWithoutProcessing: {
    overflowY: "scroll",
    height: "calc(100% - 33px)",
    marginTop: 3,
  },
  stats: {
    fontSize: 14,
    lineHeight: "30px",
    height: 30,
    fontWeight: 500,
  },
  iconMarked: {
    position: "absolute",
    top: 5,
    right: 5,
    color: PRIMARY_COLOR,
  },
  iconWarning: {
    position: "absolute",
    top: 5,
    right: 35,
    color: PRIMARY_COLOR,
  },
  imageLabel: {
    position: "absolute",
    bottom: -1,
    left: 0,
    padding: "0 5px 0 0px",
    color: "white",
    zIndex: 2,
    width: "100%",
  },
  imageLabel2: {
    position: "absolute",
    top: 3,
    left: 2,
    padding: "0 5px",
    backgroundColor: "rgba(0,0,0,0.3)",
    color: "white",
  },
  activeImageLabel: {
    backgroundColor: "#5ba6ec",
  },
  selectedImageLabel: {
    backgroundColor: "#5ba6ec",
  },
  filterIcon: {
    position: "absolute",
    top: 0,
    right: 5,
  },
  filterCount: {
    position: "absolute",
    right: 54,
    color: PRIMARY_COLOR,
  },
  loader: {
    display: "inline-block",
    position: "relative",
    marginLeft: 5,
    backgroundColor: "rgb(232, 232, 232)",
  },
  loaderText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  checkboxInput: {
    marginLeft: -10,
    whiteSpace: "nowrap",
    marginRight: 0,
    marginBottom: 4,
    marginTop: -4,
  },
  tagsWrap: { position: "absolute", bottom: 21, right: 2 },
} as const;

export type Props = {
  ref: any;
  images: Image[];
  items: FilterImage[];
  height: number | string;
  onSelect?: (image: Image, one: boolean) => void;
  activeId?: number;
  imgSize: {
    width: number;
    height: number;
  };
  getImageSrc: (image: Image) => GetImageParams;
  getImageSrc2?: (image: Image) => GetImageParams;
  showSelectedFilter?: boolean;
  showTrainingTypeFilter?: boolean;
  showOkNokFilter?: boolean;
  showStatsFilter?: boolean;
  getInfo?: ({
    filteredItems,
    items,
  }: {
    filteredItems: Image[];
    items: Image[];
  }) => { text: string; title: string };
  setFilter: (values: FilterSettings) => void;
  filter: FilterSettings;
  disabledProcessed?: boolean;
  disabledKeyListener?: boolean;
  selectMore?: boolean;
  onSelectMore?: (images: number[]) => void;
  setDeleteImages: (ids: number[]) => void;
  setRenameImage: (id: number) => void;
  setTagsImages: (params: { images: number[]; type: "UPDATE" }) => void;
  t: (key: string) => string;
  children: any;
};

type State = {
  multiSelectIndexes: number[];
  loaded: number;
  showFilterDialog: boolean;
  anchorEl: any;
  anchorPos: any;
  contextMenu: {
    pos: { x: number; y: number };
    imageId: number | null;
  };
};

class ImageList extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  private wrapRef = React.createRef<HTMLDivElement>();

  state: State = {
    multiSelectIndexes: [],
    loaded: INIT_COUNT,
    showFilterDialog: false,
    anchorEl: null,
    anchorPos: {
      x: 0,
      y: 0,
    },
    contextMenu: {
      imageId: null,
      pos: { x: 0, y: 0 },
    },
  };

  id: number = 0;

  componentDidMount() {
    this.id = Date.now();

    if (this.props.onSelect) {
      const filteredItems = this.getFilterImages();
      if (filteredItems.length > 0) {
        this.props.onSelect(filteredItems[0], filteredItems.length === 1);
      }
    }
    if (this.props.onSelect) {
      window.addEventListener("keydown", this.handleKeyPress);
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.imgSize.height !== this.props.imgSize.height &&
      this.wrapRef.current
    ) {
      const index =
        this.wrapRef.current.scrollTop /
        (this.getImageSize(prevProps.imgSize).height +
          BORDER_WIDTH * 2 +
          ITEM_BOTTOM_PADDING);
      this.wrapRef.current.scrollTop =
        (this.getImageSize(this.props.imgSize).height +
          BORDER_WIDTH * 2 +
          ITEM_BOTTOM_PADDING) *
        index;
    }

    if (
      this.props.activeId !== prevProps.activeId &&
      this.props.activeId !== undefined &&
      this.state.multiSelectIndexes.length === 0
    ) {
      this.setState({
        multiSelectIndexes: [this.props.activeId],
      });
    }

    if (
      this.props.items.length !== prevProps.items.length &&
      this.state.multiSelectIndexes.length > 0 &&
      this.props.selectMore
    ) {
      const multiSelectIndexes = this.state.multiSelectIndexes.filter((i) =>
        this.props.items.find((k) => k.id === i)
      );

      this.setState({
        multiSelectIndexes,
      });
      this.props.onSelectMore?.(multiSelectIndexes);
    }

    if (this.props.filter.processed !== prevProps.filter.processed) {
      setTimeout(() => {
        forceCheck();
      }, 600);
    }

    if (
      this.props.activeId &&
      this.props.images.length !== 0 &&
      !this.props.images.find((i) => i.id === this.props.activeId)
    ) {
      this.setNextImage();
    }
    const aspectRatioPrev = prevProps.imgSize.width / prevProps.imgSize.height;
    const aspectRatio = this.props.imgSize.width / this.props.imgSize.height;

    if (aspectRatioPrev !== aspectRatio) {
      const filteredItems = this.getFilterImages();
      const index = filteredItems.findIndex(
        (item) => item.id === this.props.activeId
      );
      if (index !== -1) {
        this.setScroll(index);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onSelect) {
      window.removeEventListener("keydown", this.handleKeyPress);
    }
    window.removeEventListener("resize", this.updateDimensions);
  }

  getUrl(img: GetImageParams) {
    const url = this.context.helper.getImagePath(img) as string;

    if (this.props.filter.processed !== false) {
      return url;
    }

    // remove config from url
    const parsedUrl = queryString.parseUrl(url);
    delete parsedUrl.query.config;
    return `${parsedUrl.url}?config=%5B%5D&${queryString.stringify(
      parsedUrl.query
    )}`;
  }

  setNextImage = () => {
    const images = this.getFilterImages();
    const index = images.findIndex((item) => item.id === this.props.activeId);
    if (images.length - 1 > index) {
      this.props.onSelect?.(images[index + 1], index + 2 === images.length);
      this.setScroll(index + 1);
    } else {
      this.props.onSelect?.(images[0], true);
      this.setScroll(0);
    }
  };

  setPrevImage = () => {
    const images = this.getFilterImages();
    const index = images.findIndex((item) => item.id === this.props.activeId);
    if (index > 0) {
      this.props.onSelect?.(images[index - 1], index === images.length);
      this.setScroll(index - 1);
    } else {
      this.setState({
        loaded: images.length,
      });
      this.props.onSelect?.(images[images.length - 1], true);
      this.setScroll(images.length - 1);
    }
  };

  setScroll(activeIndex: number) {
    if (this.wrapRef.current) {
      this.wrapRef.current.scrollTop =
        (LABEL_HEIGHT +
          this.getImageSize().height +
          BORDER_WIDTH * 2 +
          ITEM_BOTTOM_PADDING) *
        activeIndex;
    }
  }

  getFilterImages(filterSort = this.props.filter) {
    const { sort } = filterSort;
    const result = sortFilterImages.filter(this.props.items, filterSort);
    return sortFilterImages.sort(result, sort);
  }

  // eslint-disable-next-line class-methods-use-this
  getInfo({
    filteredItems,
    items,
  }: {
    filteredItems: Image[];
    items: Image[];
  }) {
    return {
      title: this.props.t("image_list_info_help"),
      text: `${filteredItems.length} / ${items.length}`,
    };
  }

  getImageSize(imageSize = this.props.imgSize) {
    const { width, height } = imageSize;
    let maxWidth = 0;
    if (this.isDouble()) {
      maxWidth = MAX_WIDTH_DOUBLE_BORDER;
    } else {
      maxWidth = MAX_WIDTH_BORDER;
    }

    const imageHeight = maxWidth / (width / height);
    const imageWidth = maxWidth;

    return {
      height: Math.min(Math.max(imageHeight, MIN_HEIGHT), MAX_HEIGHT),
      width: imageWidth,
    };
  }

  handleTouchTapFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    this.setState({
      showFilterDialog: true,
    });
  };

  handleClick = (
    e: React.MouseEvent<HTMLDivElement>,
    item: Image,
    isLast: boolean,
    filteredItems: Image[]
  ) => {
    const { multiSelectIndexes } = this.state;
    if (e.metaKey || e.ctrlKey) {
      const selectedIndexes =
        multiSelectIndexes.indexOf(item.id) === -1
          ? [...multiSelectIndexes, item.id]
          : multiSelectIndexes.filter((i) => i !== item.id);

      this.setState({
        multiSelectIndexes: selectedIndexes,
      });
      this.props.onSelect?.(item, isLast);
      this.props.onSelectMore?.(selectedIndexes);
    } else if (e.shiftKey && multiSelectIndexes.length > 0) {
      const activeIndex = filteredItems.findIndex(
        (i) => i.id === multiSelectIndexes[multiSelectIndexes.length - 1]
      );
      const currentIndex = filteredItems.findIndex((i) => i.id === item.id);
      const [firstIndex, lastIndex] =
        activeIndex > currentIndex
          ? [currentIndex, activeIndex]
          : [activeIndex, currentIndex];
      const selectedIndexes = filteredItems
        .slice(firstIndex, lastIndex + 1)
        .map((i) => i.id);
      this.setState({
        multiSelectIndexes: selectedIndexes,
      });
      this.props.onSelectMore?.(selectedIndexes);
    } else {
      if (this.props.onSelect) {
        this.props.onSelect(item, isLast);
      }
      this.setState({
        multiSelectIndexes: [item.id],
      });
      this.props.onSelect?.(item, isLast);
      this.props.onSelectMore?.([item.id]);
    }
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 32 && e.target === document.body) {
      e.preventDefault();
    }

    if (this.props.disabledKeyListener) {
      return;
    }

    const keyCode = e.which || e.keyCode;
    if (keyCode === 40) {
      e.preventDefault();
      e.stopPropagation();
      this.setNextImage();
    }
    if (keyCode === 38) {
      e.preventDefault();
      e.stopPropagation();
      this.setPrevImage();
    }
  };

  handleClickRemove = (e: React.MouseEvent<HTMLElement>) => {
    this.props.setDeleteImages(this.state.multiSelectIndexes);
    this.closeContextMenu();
  };

  handleClickRename = (e: React.MouseEvent<HTMLElement>) => {
    if (this.state.contextMenu.imageId !== null) {
      this.props.setRenameImage(this.state.contextMenu.imageId);
    }
    this.closeContextMenu();
  };

  handleClickUpdateTags = (e: React.MouseEvent<HTMLElement>) => {
    this.props.setTagsImages({
      images: this.state.multiSelectIndexes,
      type: "UPDATE",
    });
    this.closeContextMenu();
  };

  isDouble() {
    return (
      this.props.getImageSrc2 !== undefined &&
      this.props.filter.processed !== false
    );
  }

  // eslint-disable-next-line class-methods-use-this
  updateDimensions() {
    setTimeout(() => {
      forceCheck();
    }, 600);
  }

  handleOnShowContext = (
    images: sortFilterImages.FilterImage[],
    imageId: number
  ) => {
    if (!this.state.multiSelectIndexes.includes(imageId)) {
      this.setState({
        multiSelectIndexes: [imageId],
      });
      const itemIndex = images.findIndex((i) => i.id === imageId);
      if (itemIndex !== -1) {
        this.props.onSelect?.(
          images[itemIndex],
          itemIndex + 1 === images.length
        );
      }
    }
  };

  closeContextMenu = () => {
    this.setState({
      contextMenu: { ...this.state.contextMenu, imageId: null },
    });
  };

  isFilterActive() {
    const {
      from,
      to,
      mark,
      trainingType,
      idFrom,
      idTo,
      text,
      okNokType,
      statsType,
      imageIds,
    } = this.props.filter;
    const {
      showSelectedFilter,
      showTrainingTypeFilter,
      showOkNokFilter,
      showStatsFilter,
    } = this.props;
    return (
      !(
        from === null &&
        to === null &&
        idFrom === null &&
        idTo === null &&
        text === ""
      ) ||
      (showTrainingTypeFilter &&
        trainingType !== sortFilterImages.FILTER_ALL) ||
      (showSelectedFilter && mark !== sortFilterImages.FILTER_ALL) ||
      (showStatsFilter && statsType !== sortFilterImages.FILTER_ALL) ||
      (showOkNokFilter && okNokType !== sortFilterImages.FILTER_ALL) ||
      imageIds
    );
  }

  getInfoFontSize = (length: number) => {
    if (length <= 22) {
      return 14;
    } else if (length <= 26) {
      return 12;
    } else {
      return 10;
    }
  };

  render() {
    const filteredItems = this.getFilterImages();
    const { t } = this.props;

    const info = this.props.getInfo
      ? this.props.getInfo({
          items: this.props.items,
          filteredItems,
        })
      : this.getInfo({
          items: this.props.items,
          filteredItems,
        });
    const imageSize = this.getImageSize();
    const isFilterActive = this.isFilterActive();

    return (
      <div style={styles.wrap}>
        <div
          style={{
            width: `calc(100% - ${
              (this.isDouble() ? WRAP_WIDTH_DOUBLE : WRAP_WIDTH) +
              IMAGE_LIST_LEFT_PADDING
            }px)`,
          }}
        >
          {this.props.children}
        </div>
        <div
          style={{
            height: this.props.height,
            zIndex: 2,
            background: "white",
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            width:
              (this.isDouble() ? WRAP_WIDTH_DOUBLE : WRAP_WIDTH) +
              IMAGE_LIST_LEFT_PADDING,
            ...styles.rightPanel,
          }}
        >
          <div style={styles.stats}>
            <span
              title={info.title}
              style={{ fontSize: this.getInfoFontSize(info.text.length) }}
            >
              {info.text}
            </span>
          </div>
          <IconButton
            style={styles.filterIcon}
            onClick={this.handleTouchTapFilter}
            size="large"
          >
            <FilterIcon color={isFilterActive ? "primary" : undefined} />
          </IconButton>
          {!this.props.disabledProcessed && (
            <div>
              <FormControlLabel
                style={styles.checkboxInput}
                control={
                  <Checkbox
                    color="primary"
                    checked={this.props.filter.processed !== false}
                    onChange={(e) =>
                      this.props.setFilter({
                        ...this.props.filter,
                        processed: e.target.checked,
                      })
                    }
                  />
                }
                label={t("show_processed_images")}
              />
            </div>
          )}
          <div
            style={
              this.props.disabledProcessed
                ? styles.imagesWrapWithoutProcessing
                : styles.imagesWrap
            }
            ref={this.wrapRef}
            onScroll={debounce(() => {
              forceCheck();
            }, 100)}
            className="frame"
          >
            {this.props.imgSize.width !== 0 && (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  this.setState({
                    loaded: this.state.loaded + ADD_COUNT,
                  });
                }}
                useWindow={false}
                hasMore={filteredItems.length > this.state.loaded}
                loader={
                  <div key={0} className="loader">
                    Loading ...
                  </div>
                }
              >
                {filteredItems
                  .slice(0, this.state.loaded)
                  .map((item, index) => (
                    <LazyLoad
                      debounce={0}
                      throttle={0}
                      height={
                        imageSize.height +
                        ITEM_BOTTOM_PADDING +
                        BORDER_WIDTH * 2 +
                        LABEL_HEIGHT
                      }
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`${index}-${item.id}-${imageSize.height}-${imageSize.width}`}
                      unmountIfInvisible
                      placeholder={
                        <div
                          style={{
                            height:
                              imageSize.height +
                              BORDER_WIDTH * 2 +
                              LABEL_HEIGHT,
                            ...styles.itemWrap,
                          }}
                        />
                      }
                    >
                      <div
                        onContextMenu={(e) => {
                          e.preventDefault();
                          this.handleOnShowContext(filteredItems, item.id);
                          this.setState({
                            contextMenu: {
                              pos: {
                                x: e.clientX,
                                y: e.clientY,
                              },
                              imageId: item.id,
                            },
                          });
                        }}
                        style={{
                          height:
                            imageSize.height + BORDER_WIDTH * 2 + LABEL_HEIGHT,
                          cursor: this.props.onSelect ? "pointer" : "auto",
                          ...styles.itemWrap,
                          ...(this.props.activeId === item.id ||
                          this.state.multiSelectIndexes.indexOf(item.id) !== -1
                            ? styles.imageWrapSelectableActive
                            : styles.imageWrapSelectable),
                        }}
                        onClick={(e) =>
                          this.handleClick(
                            e,
                            item,
                            index + 1 === filteredItems.length,
                            filteredItems
                          )
                        }
                      >
                        <div style={{ ...styles.imageWrap, ...imageSize }}>
                          <ImageWithRectangles
                            min
                            key={this.getUrl(this.props.getImageSrc(item))}
                            withoutProcessing={
                              this.props.filter.processed === false
                            }
                            image={
                              this.props.filter.processed === false
                                ? { imageId: item.id }
                                : this.props.getImageSrc(item)
                            }
                          />
                        </div>
                        {this.isDouble() && this.props.getImageSrc2 && (
                          <div
                            style={{
                              ...styles.imageWrap2,
                              ...imageSize,
                            }}
                          >
                            <ImageWithRectangles
                              min
                              key={this.getUrl(this.props.getImageSrc2(item))}
                              image={
                                this.props.filter.processed === false
                                  ? { imageId: item.id }
                                  : this.props.getImageSrc2(item)
                              }
                            />
                          </div>
                        )}
                        <span
                          title={item.label}
                          style={{
                            ...styles.imageLabel,
                            ...((
                              this.props.activeId === item.id
                                ? styles.activeImageLabel
                                : this.state.multiSelectIndexes.indexOf(
                                    item.id
                                  ) !== -1
                            )
                              ? styles.selectedImageLabel
                              : {}),
                          }}
                        >
                          {item.label}
                        </span>
                        {item.label2 && (
                          <span
                            style={{
                              opacity: item.disabled ? 0.5 : 1,
                              ...styles.imageLabel2,
                              ...(this.props.activeId === item.id
                                ? styles.activeImageLabel
                                : {}),
                            }}
                          >
                            {item.label2}
                          </span>
                        )}
                        {item.isMarked && (
                          <MarkedIcon style={styles.iconMarked} />
                        )}
                        {item.warning && (
                          <WarningIcon style={styles.iconWarning} />
                        )}
                        <div style={styles.tagsWrap}>
                          <Tags tagsIds={item.tags ?? []} />
                        </div>
                      </div>
                    </LazyLoad>
                  ))}
              </InfiniteScroll>
            )}
          </div>
          <FilterDialog
            images={this.props.images}
            open={this.state.showFilterDialog}
            onClose={() => {
              this.setState({ showFilterDialog: false });
              setTimeout(() => {
                forceCheck();
              }, 1000);
            }}
            values={this.props.filter}
            onChangeValues={(filterSort) => {
              this.props.setFilter(filterSort);
              if (this.wrapRef.current) {
                this.wrapRef.current.scrollTop = 0;
              }
              const filtersItems = this.getFilterImages(filterSort);
              if (this.props.onSelect && filtersItems.length > 0) {
                this.props.onSelect(filtersItems[0], filtersItems.length === 1);
              }
            }}
            showOkNokFilter={this.props.showOkNokFilter}
            showTrainingTypeFilter={this.props.showTrainingTypeFilter}
            showSelectedFilter={this.props.showSelectedFilter}
            showStatsFilter={this.props.showStatsFilter}
          />
        </div>
        <Menu
          open={this.state.contextMenu.imageId !== null}
          onClose={this.closeContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: this.state.contextMenu.pos.y,
            left: this.state.contextMenu.pos.x,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <MenuItem onClick={this.handleClickRemove}>{t("delete")}</MenuItem>
          {this.state.multiSelectIndexes.length === 1 && (
            <MenuItem onClick={this.handleClickRename}>{t("rename")}</MenuItem>
          )}
          <MenuItem onClick={this.handleClickUpdateTags}>
            {t("update_tags")}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withI18n(ImageList);
