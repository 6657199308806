import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDebouncedCallback } from "use-debounce";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import * as sortFilterImages from "../../utils/sortFilterImages";
import {
  FilterImage,
  FilterSettings,
  Sort,
  TrainingType,
} from "../../utils/sortFilterImages";
import useI18n from "../../hooks/useI18n";
import { Tag } from "../../types/common";
import TagsInputContainer from "../../Containers/TagsInput/TagsInputContainer";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles()((theme) => ({
  imageName: {
    backgroundColor: grey[200],
    padding: 3,
    margin: 2,
    display: "inline-block",
  },
  selectedImages: {
    marginTop: theme.spacing(1),
    marginLeft: -2,
    marginRight: -2,
  },
  deselectButton: {
    marginTop: theme.spacing(1),
  },
  selectedImagesWrap: {
    marginTop: 4,
  },
  showAllButton: {
    padding: "0 8px",
  },
}));

type DatePickerProps = {
  value: string | null;
  label: string;
  onChange: (value: string | null) => void;
};

const Datepicker: FC<DatePickerProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { onChange, value, label } = props;

  useEffect(() => {
    if (inputRef.current) {
      if (typeof value === "string") {
        inputRef.current.value = value;
      }
    }
  }, [value, inputRef]);

  const handleBlur = useCallback(() => {
    if (inputRef.current) {
      onChange(inputRef.current.value);
    }
  }, [inputRef, onChange]);

  return (
    <TextField
      label={label}
      type="date"
      size="small"
      variant="outlined"
      defaultValue={
        typeof value === "string"
          ? new Date(value).toISOString().substring(0, 10)
          : ""
      }
      inputRef={inputRef}
      onBlur={handleBlur}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          handleBlur();
        }
      }}
    />
  );
};

export interface Props {
  showSelectedFilter?: boolean;
  values: FilterSettings;
  images: FilterImage[];
  onChangeValues: (values: FilterSettings) => void;
  showTrainingTypeFilter?: boolean;
  showOkNokFilter?: boolean;
  showStatsFilter?: boolean;
  tags: Tag[];
}

const MAX_SELECTED_IMAGES = 10;

const FilterForm: FC<Props> = (props) => {
  const { classes } = useStyles();

  const [_] = useI18n();
  const [showAllImageIds, setShowAllImageIds] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    // function
    (value: string) => {
      props.onChangeValues({ ...props.values, text: value });
    },
    // delay in ms
    2000
  );

  useEffect(
    () => () => {
      callPending();
    },
    [callPending]
  );

  const renderDateTimePicker = (label: string, name: "from" | "to") => {
    const defaultValue = props.values[name];
    return (
      <Datepicker
        label={label}
        value={defaultValue}
        onChange={(value) => {
          if (value) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleChangeDate(name, new Date(value));
          } else {
            props.onChangeValues({ ...props.values, [name]: null });
          }
        }}
      />
    );
  };

  const handleChangeDate = (name: "from" | "to", value: Date) => {
    if (name === "from") {
      const to = props.values.to ? new Date(props.values.to) : null;
      props.onChangeValues({
        ...props.values,
        from: value.toISOString().substring(0, 10),
        to:
          to !== null && value.getTime() > to.getTime()
            ? value.toISOString().substring(0, 10)
            : props.values.to,
      });
    } else {
      const from = props.values.from ? new Date(props.values.from) : null;
      props.onChangeValues({
        ...props.values,
        from:
          from !== null && value.getTime() < from.getTime()
            ? value.toISOString().substring(0, 10)
            : props.values.from,
        to: value.toISOString().substring(0, 10),
      });
    }
  };

  return (
    // eslint-disable-next-line
    <div
      onKeyDown={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
    >
      <TextField
        defaultValue={props.values.text}
        label={_("search")}
        onKeyPress={(e) => e.stopPropagation()}
        onChange={(e) => debouncedCallback(e.target.value)}
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
        InputLabelProps={
          {
            // shrink: true
          }
        }
      />
      <TagsInputContainer
        value={props.values.tags || []}
        onChange={(tags) => {
          props.onChangeValues({
            ...props.values,
            tags,
          });
        }}
      />
      <TextField
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        label={_("image_filter_show")}
        select
        value={props.values.sort}
        onChange={(e) => {
          e.stopPropagation();
          props.onChangeValues({
            ...props.values,
            sort: e.target.value as Sort,
          });
        }}
        inputProps={{
          id: "filter-sort",
        }}
      >
        <MenuItem value={sortFilterImages.SORT_NEW}>
          {_("image_filter_sort_new")}
        </MenuItem>
        <MenuItem value={sortFilterImages.SORT_OLD}>
          {_("image_filter_sort_old")}
        </MenuItem>
        <MenuItem value={sortFilterImages.SORT_AZ}>
          {_("image_filter_sort_az")}
        </MenuItem>
        <MenuItem value={sortFilterImages.SORT_ZA}>
          {_("image_filter_sort_za")}
        </MenuItem>
      </TextField>

      {props.showSelectedFilter && (
        <TextField
          fullWidth
          size="small"
          margin="dense"
          variant="outlined"
          label={_("image_filter_show")}
          select
          value={props.values.mark}
          onChange={(e) => {
            e.stopPropagation();
            props.onChangeValues({
              ...props.values,
              mark: e.target.value as FilterSettings["mark"],
            });
          }}
          inputProps={{
            id: "filter-show",
          }}
        >
          <MenuItem value={sortFilterImages.FILTER_ALL}>
            {_("image_filter_all")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_NONE_MARKED}>
            {_("image_filter_none_marked")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_MARKED}>
            {_("image_filter_marked")}
          </MenuItem>
        </TextField>
      )}

      {props.showOkNokFilter && (
        <TextField
          select
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          label={_("image_filter_show")}
          value={props.values.okNokType}
          onChange={(e) => {
            e.stopPropagation();
            props.onChangeValues({
              ...props.values,
              okNokType: e.target.value as FilterSettings["okNokType"],
            });
          }}
          inputProps={{
            id: "filter-show-2",
          }}
        >
          <MenuItem value={sortFilterImages.FILTER_ALL}>
            {_("image_filter_all")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_OK}>
            {_("image_filter_ok")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_NOK}>
            {_("image_filter_nok")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_OK_NOK}>
            {_("image_filter_ok_nok")}
          </MenuItem>
        </TextField>
      )}

      {props.showTrainingTypeFilter && (
        <TextField
          select
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          label={_("image_filter_show")}
          value={props.values.trainingType}
          onChange={(e) => {
            e.stopPropagation();
            props.onChangeValues({
              ...props.values,
              trainingType: e.target.value as TrainingType,
            });
          }}
          inputProps={{
            id: "filter-show-3",
          }}
        >
          <MenuItem value={sortFilterImages.FILTER_ALL}>
            {_("image_filter_all")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_TRAINING}>
            {_("image_filter_training")}
          </MenuItem>
          <MenuItem value={sortFilterImages.FILTER_NONE_MARKED}>
            {_("image_filter_none_marked")}
          </MenuItem>
        </TextField>
      )}

      {renderDateTimePicker(_("date_from"), "from")}
      {renderDateTimePicker(_("date_to"), "to")}
      {props.values.imageIds && (
        <div className={classes.selectedImages}>
          <Typography variant="body1">{_("selected_images")}</Typography>
          <div className={classes.selectedImagesWrap}>
            {props.values.imageIds
              .slice(
                0,
                showAllImageIds
                  ? props.values.imageIds.length
                  : MAX_SELECTED_IMAGES
              )
              .map((id) => ({
                id,
                item: props.images.find((i) => i.id === id),
              }))
              .map((i) => (
                <>
                  <Typography
                    className={classes.imageName}
                    variant="body2"
                    display="inline"
                  >
                    {i.item ? i.item.label : _("not_found")}
                  </Typography>{" "}
                </>
              ))}
            {!showAllImageIds &&
              props.values.imageIds.length > MAX_SELECTED_IMAGES && (
                <>
                  ...{" "}
                  <Button
                    className={classes.showAllButton}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setShowAllImageIds(true);
                    }}
                  >
                    {_("show_all")}
                  </Button>
                </>
              )}
          </div>
          <Button
            className={classes.deselectButton}
            variant="outlined"
            size="small"
            onClick={() => {
              props.onChangeValues({
                ...props.values,
                imageIds: null,
              });
            }}
          >
            {_("deselect_images")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FilterForm;
