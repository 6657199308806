import React, { FC } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useI18n from "../../../hooks/useI18n";

type Props = {
  value: string, 
  onChange: (value: "evaluation" | "simple") => void
}

const TypeSettings:FC<Props> = (props) => {
  const [_] = useI18n()
  return (
    <FormControlLabel
      onChange={(_, checked) => props.onChange(checked ? "evaluation" : "simple")}
      checked={props.value === "evaluation"}
      control={<Checkbox />}
      label={_("evaluation")}
    />
  );
};

export default TypeSettings;
