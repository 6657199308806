import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from 'tss-react/mui';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import useI18n from "../../hooks/useI18n";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import {
  imagesUploadKeepBoth,
  imagesUploadKeepOld,
} from "../../actions/actions";
import { grey } from '@mui/material/colors';

const useStyles = makeStyles<void, 'listItem'>()((theme, _params, classes) => ({
  paper: {
    width: 400,
  },
  listItem: {
    backgroundColor: grey[200],
    [`&+.${classes.listItem}`]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const ImageCollisionNameDialog = () => {
  const { open, images } = useAppSelector((state) => ({
    open: state.system.uploadImagesCollisions.length > 0,
    images: state.system.uploadImagesCollisions,
  }));

  const dispatch = useAnyAppDispatch();

  const { classes } = useStyles();
  const [_] = useI18n();
  return (
    <Dialog
      disablePortal
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <DialogTitle>{_("image_name_collision")}</DialogTitle>
      <DialogContent>
        <List dense>
          {images.map((image) => (
            <ListItem className={classes.listItem} key={image.index}>
              <ListItemText primary={image.fullName} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(imagesUploadKeepOld())}>
          {_("keep_old")}
        </Button>
        <Button
          onClick={() => dispatch(imagesUploadKeepBoth())}
          id="add_images_submit_btn"
          autoFocus
          type="submit"
          color="primary"
        >
          {_("keep_both")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCollisionNameDialog;
