import React, { useEffect, useState } from "react";
import { Area, XAxis, YAxis, AreaChart, ReferenceLine } from "recharts";
import { useDebouncedCallback } from "use-debounce";

const MIN_VALUE = 0.001;
const MAX_VALUE = 1e8;

const toFixed = (value: number) => Math.floor(value * 10000) / 10000;

type Props = {
  data: {
    ok: number[];
    nok: number[];
    threshold: number;
  };
  onChangeThreshold: (threshold: number) => void;
};

const PeakChart = (props: Props) => {
  const [div, setDiv] = useState(toFixed(props.data.threshold));
  // eslint-disable-next-line
  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    // function
    (e: number) => {
      props.onChangeThreshold(e);
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    setDiv(toFixed(props.data.threshold));
  }, [props.data.threshold]);

  const min = 0;
  const max = div * 3;
  const step = (max - min) / 300;
  const data: { ok?: number; nok?: number; peak: number }[] = [];
  let lastOk = undefined;
  let lastNok = undefined;
  for (let i = min; i < max; i += step) {
    let currentOk = props.data.ok.filter((value) => value > i).length;
    let currentNok = props.data.nok.filter((value) => value <= i).length;
    //if (currentOk != lastOk || currentNok != lastNok || i > max) {
    data.push({
      peak: toFixed(i),
      ok: currentOk !== lastOk ? currentOk : undefined, // monotone
      nok: currentNok !== lastNok ? currentNok : undefined, // monotone
    });

    if (i > min) {
      const currentItem = data[data.length - 1];
      const prevItem = data[data.length - 2];
      if (currentItem.nok === undefined && prevItem.nok !== undefined) {
        currentItem.nok = prevItem.nok;
        prevItem.nok = undefined;
      }
    }
    lastOk = currentOk;
    lastNok = currentNok;
  }

  if (lastOk !== 0 && data.length > 0) {
    data[data.length - 1].ok = lastOk; // monotone
  }

  return (
    <div>
      <AreaChart
        onClick={(e: any) => {
          if (data?.[e.activeTooltipIndex]) {
            const nextDiv: number = data[e.activeTooltipIndex].peak;
            if (nextDiv < MIN_VALUE || nextDiv > MAX_VALUE) {
              // out of range
              return;
            }
            setDiv(nextDiv);
            debouncedCallback(nextDiv);
          }
        }}
        width={208}
        height={200}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <XAxis
          domain={[toFixed(min), toFixed(max)]}
          type="number"
          dataKey="peak"
        />
        <YAxis hide />

        <ReferenceLine x={div} stroke="black" />
        <Area
          connectNulls
          isAnimationActive={false}
          dataKey="ok"
          fill="#06960e"
          stroke="#06960e"
        />
        <Area
          connectNulls
          isAnimationActive={false}
          dataKey="nok"
          fill="#ff0000"
          stroke="#ff0000"
        />
      </AreaChart>
    </div>
  );
};

export default PeakChart;
