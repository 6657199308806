import React, { FC } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Alert from '@mui/material/Alert';
import Summary from "./Summary";
import useI18n from "../../../hooks/useI18n";
import { Statistics } from "../../../store/system/reducer";
import { FilterTypes } from "./types";

const useStyles = makeStyles()((theme) => ({
  loader: {
    textAlign: "center",
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  open: boolean;
  evaluationType: boolean;
  images: { annotation: boolean; result?: boolean; id: number }[];
  stats?: Statistics["summary"];
  onClose: () => void;
  onFilter: (ids: number[]) => void;
};

const StatisticsDialog: FC<Props> = ({
  open,
  onClose,
  stats,
  images,
  onFilter,
  evaluationType,
}) => {
  const [_] = useI18n();
  const { classes } = useStyles();

  const handleClose = () => {
    onClose();
  };

  const withoutValidation = !stats?.evaluationActive;

  const withoutTestingImages =
    stats &&
    stats.truePositive === 0 &&
    stats.trueNegative === 0 &&
    stats.falsePositive === 0 &&
    stats.falseNegative === 0;

  // eslint-disable-next-line consistent-return
  const filterItems = (type: FilterTypes) => {
    if (type === "truPositive") {
      return images.filter((i) => i.annotation === true && i.result === true);
    }
    if (type === "trueNegative") {
      return images.filter((i) => i.annotation === false && i.result === false);
    }
    if (type === "falseNegative") {
      return images.filter((i) => i.annotation === true && i.result === false);
    }
    if (type === "falsePositive") {
      return images.filter((i) => i.annotation === false && i.result === true);
    }
    return null;
  };

  const handleSelectFilter = (type: FilterTypes) => {
    const result = filterItems(type);
    if (result) {
      onFilter(result.map((i) => i.id));
    }
  };

  return (
    <Dialog open={open && !!stats} onClose={onClose}>
      <DialogTitle>{_("statistics")}</DialogTitle>
      <DialogContent>
        {withoutTestingImages && !withoutValidation && evaluationType && (
          <Alert className={classes.alert} severity="error">
            {_("statistics_without_testing_error")}
          </Alert>
        )}
        {stats && (
          <>
            <Summary
              evaluationType={evaluationType}
              data={stats}
              onSelect={handleSelectFilter}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {_("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatisticsDialog;
