import React from "react";
import { makeStyles } from "tss-react/mui";
import { FC } from "react";
import Paper from "@mui/material/Paper";
import { PANEL_WIDTH } from "../theme";

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles()({
  root: {
    padding: "0 16px",
    overflow: "auto",
    height: "100%",
    background: "white",
    zIndex: 2,
    position: "relative",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    width: PANEL_WIDTH,
  },
});

type Props = {
  height?: number | string;
  width?: number | string;
  children: React.ReactNode;
};

const ModuleRightToolPanel: FC<Props> = (props) => {
  const { classes } = useStyles();
  return (
    <Paper
      square
      style={{
        height: props.height ? `calc(${props.height} - 50px)` : undefined,
        width: props.width ?? PANEL_WIDTH,
      }}
      className={classes.root}
    >
      <div>{props.children}</div>
    </Paper>
  );
};

export default ModuleRightToolPanel;
