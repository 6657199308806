import React from "react";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import RuntimeStatistics from "../../../Containers/RuntimeStatistics/RuntimeStatisticsContainer";
import useI18n from "../../../hooks/useI18n";

const styles = {
  wrap: {
    overflowY: "scroll",
    padding: 10,
  },
} as const;

const RuntimeView = () => {
  const [_] = useI18n();
  return (
    <ModuleWrap title={_("runtime")}>
      <div style={styles.wrap}>
        <RuntimeStatistics />
      </div>
    </ModuleWrap>
  );
};

export default RuntimeView;
