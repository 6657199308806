import { connect, ConnectedProps } from "react-redux";
import TrainingView from "./components/TrainingView";
import {
  editConfig,
  changePaintRadius,
  supervisedEditEmptyImages,
  supervisedEditConfig,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { SupervisedModule } from "../../types/modules";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const module = getModule(
    state,
    +ownProps.match.params.moduleId
  ) as SupervisedModule;
  return {
    history: ownProps.history,
    module,
    models: state.database.supervisedModels,
    token: state.system.token,
    dangerSettings:
      module.dangerSettings &&
      (module.values.masks.length > 0 || module.values.emptyImages.length),
    images: state.database.images,
    trainingDisabled: state.system.trainingDisabled,
    modelsCounter: state.database.modelsCounter.SUPERVISED,
  };
};

const connector = connect(mapStateToProps, {
  editConfig,
  changePaintRadius,
  supervisedEditEmptyImages,
  supervisedEditConfig,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TrainingView);
