import React, { FC } from "react";
import ReactNavigationPrompt from "react-router-navigation-prompt";
import Confirm from "./Confirm";

type Props = {
  message: string;
  when: boolean;
};

const NavigationPrompt: FC<Props> = ({ message, when }) => (
  <ReactNavigationPrompt when={when} disableNative>
    {({ onConfirm, onCancel, isActive }) => (
      <Confirm
        open={isActive}
        onClickNo={onCancel}
        onClickYes={onConfirm}
        message={message}
      />
    )}
  </ReactNavigationPrompt>
);

export default NavigationPrompt;
