import React, { Component } from "react";
import LazyLoad from "react-lazyload";

import ImagesGridFilter from "../../../components/ImagesGridFilter";
import * as sortFilterImages from "../../../utils/sortFilterImages";
import { CONTENT_INNER_PADDING, PRIMARY_COLOR } from "../../../theme";
import ImageWithRectangles from "../../../components/ImageWithRectangles";
import { GetImageParams, Image } from "../../../types/common";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const IMAGE_HEIGHT = 300;

const styles = {
  wrap: {
    padding: CONTENT_INNER_PADDING,
    marginLeft: -5,
    marginRight: -5,
    userSelect: "none",
  },
  itemWrap: {
    display: "inline-block",
    height: IMAGE_HEIGHT,
    border: "1px solid rgba(39, 50, 56, 0.18)",
    background: "rgb(238, 239, 239)",
    padding: 5,
    position: "relative",
    margin: 5,
    marginTop: 0,
    cursor: "pointer",
  },
  imageWrap: {
    height: IMAGE_HEIGHT - 30,
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "calc(100% - 35px)",
  },
  imageLabel: {
    position: "absolute",
    bottom: 5,
    left: 5,
    width: "calc(100% - 10px)",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
} as const;

type Props = {
  images: Image[];
  onSelect: (imageId: number) => void;
  multiselect?: boolean;
  selected: number[];
  getImageSrc: (image: Image) => GetImageParams;
  onChangeSelected: (imagesIds: number[]) => void;
};

export default class ImageList extends Component<Props> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state = {
    filter: sortFilterImages.defaultValues,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  // eslint-disable-next-line class-methods-use-this
  getItemStyle(imageId: number) {
    const wrapWidth = window.innerWidth - 355 + 80;
    const cols = Math.floor(wrapWidth / 350);
    const selected = this.props.selected.includes(imageId);
    return {
      width: `calc(100% / ${cols} - 10px)`,
      ...styles.itemWrap,
      borderWidth: selected ? 2 : 1,
      borderColor: selected ? PRIMARY_COLOR : "rgba(39, 50, 56, 0.18)",
    };
  }

  handleResize = () => this.forceUpdate();

  render() {
    let images = [...this.props.images];

    images = sortFilterImages.filter(images, { text: this.state.filter.text });
    images = sortFilterImages.sort(images, this.state.filter.sort);
    return (
      <div>
        <ImagesGridFilter
          values={this.state.filter}
          onChange={(filter) => {
            this.setState({ filter });
          }}
        />
        <div style={styles.wrap}>
          {images.map((image) => (
            // eslint-disable-next-line
            <div
              style={this.getItemStyle(image.id)}
              key={image.id}
              onClick={(e) => {
                e.stopPropagation();
                if (this.props.multiselect) {
                  this.props.onChangeSelected(
                    !this.props.selected.includes(image.id)
                      ? [...this.props.selected, image.id]
                      : this.props.selected.filter((i) => i !== image.id)
                  );
                } else {
                  this.props.onSelect(image.id);
                }
              }}
            >
              <LazyLoad height={IMAGE_HEIGHT} overflow>
                <div style={styles.imageWrap}>
                  <ImageWithRectangles
                    min
                    image={this.props.getImageSrc(image)}
                  />
                </div>
                <div style={styles.imageLabel} title={image.label}>
                  {image.label}
                </div>
              </LazyLoad>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
