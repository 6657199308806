import React from "react";
import CodeIcon from "@mui/icons-material/Code";
import ClassifierIcon from "@mui/icons-material/LocalOffer";
import DetectorIcon from "@mui/icons-material/FindInPage";
import ParallelismIcon from "@mui/icons-material/DeviceHub";
import MaskIcon from "@mui/icons-material/FormatPaint";
import SuperVisedIcon from "@mui/icons-material/Layers";
import UnifierIcon from "@mui/icons-material/BorderOuter";
import PreprocessIcon from "@mui/icons-material/Image";
import MeasureIcon from "@mui/icons-material/Straighten";
import OcrIcon from "@mui/icons-material/FormatShapes";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const UnsupervisedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M0.374,13.154l7.931,6.375c0.369,0.297,0.894,0.295,1.26-0.006l7.931-6.5c0.427-0.351,0.49-0.98,0.14-1.407
		s-0.98-0.49-1.407-0.14l-7.303,5.985l-7.298-5.866c-0.431-0.346-1.06-0.278-1.406,0.153C-0.125,12.178-0.057,12.808,0.374,13.154z"
    />
    <path
      d="M17.48,6.464L9.55,0.214C9.185-0.074,8.667-0.071,8.304,0.22L4.17,3.543l6.364,1.342
		c0.159,0.033,0.292,0.143,0.356,0.292s0.051,0.321-0.034,0.459l-0.892,1.45l2.695,1.385c0.246,0.126,0.342,0.428,0.216,0.673
		c-0.126,0.246-0.428,0.342-0.673,0.216l-2.764-1.42l-1.582,2.571c-0.145,0.235-0.453,0.308-0.688,0.164
		c-0.235-0.145-0.309-0.453-0.164-0.688l2.628-4.27l-6.47-1.364L0.374,6.595C0.137,6.785,0,7.071,0,7.375s0.137,0.589,0.374,0.779
		l7.931,6.375c0.369,0.297,0.894,0.295,1.26-0.006l7.931-6.5c0.235-0.192,0.369-0.479,0.366-0.783S17.719,6.652,17.48,6.464z"
    />
  </SvgIcon>
);

export const CODE = "CODE";
export const DETECTOR = "DETECTOR";
export const CLASSIFIER = "CLASSIFIER";
export const PARALLELISM = "PARALLELISM";
export const MASK = "MASK";
export const SUPERVISED = "SUPERVISED";
export const UNSUPERVISED = "UNSUPERVISED";
export const UNIFIER = "UNIFIER";
export const PREPROCESS = "PREPROCESS";
export const MEASURE = "MEASURE";
export const OCR = "OCR";

export enum Modules {
  CODE = "CODE",
  DETECTOR = "DETECTOR",
  CLASSIFIER = "CLASSIFIER",
  PARALLELISM = "PARALLELISM",
  MASK = "MASK",
  SUPERVISED = "SUPERVISED",
  UNSUPERVISED = "UNSUPERVISED",
  UNIFIER = "UNIFIER",
  PREPROCESS = "PREPROCESS",
  MEASURE = "MEASURE",
  OCR = "OCR",
}

export const AI_MODULES = [DETECTOR, CLASSIFIER, SUPERVISED, UNSUPERVISED, OCR];

export const typeToLabel = (type: Modules): string => {
  const _ = (window as any)._;
  return {
    [Modules.CODE]: _("code"),
    [Modules.DETECTOR]: _("detector"),
    [Modules.CLASSIFIER]: _("classifier"),
    [Modules.PARALLELISM]: _("parallelism"),
    [Modules.MASK]: _("mask"),
    [Modules.SUPERVISED]: _("supervised"),
    [Modules.UNSUPERVISED]: _("anomaly"),
    [Modules.UNIFIER]: _("unifier"),
    [Modules.PREPROCESS]: _("preprocess"),
    [Modules.MEASURE]: _("measure"),
    [Modules.OCR]: _("ocr"),
  }[type];
};

export const typeToIcon = (type: Modules) =>
  ({
    [Modules.CODE]: <CodeIcon />,
    [Modules.CLASSIFIER]: <ClassifierIcon />,
    [Modules.DETECTOR]: <DetectorIcon />,
    [Modules.PARALLELISM]: <ParallelismIcon />,
    [Modules.MASK]: <MaskIcon />,
    [Modules.SUPERVISED]: <SuperVisedIcon />,
    [Modules.UNSUPERVISED]: <UnsupervisedIcon />,
    [Modules.UNIFIER]: <UnifierIcon />,
    [Modules.PREPROCESS]: <PreprocessIcon />,
    [Modules.MEASURE]: <MeasureIcon />,
    [Modules.OCR]: <OcrIcon />,
  }[type]);
