import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import PatchSizeIcon from "./square_resize_24px.svg";
import ModuleSettingsWrap from "./ModuleSettingsWrap";
import useI18n from "../hooks/useI18n";

type ContentProps = {
  step: number;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  onConfirm: (value: number) => void;
};

const Content: FC<ContentProps> = (props) => {
  const [_] = useI18n();
  return (
    <>
      <Typography id="patch-size-slider" gutterBottom>
        {_("size_of_view_finder")} - {props.value}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <Slider
            marks={props.step > 2}
            min={props.min}
            step={props.step}
            max={props.max}
            value={props.value}
            onChange={(_, value) => props.onChange(value as number)}
            onChangeCommitted={(e, value) => props.onConfirm(value as number)}
          />
        </Grid>
        <Grid item>
          <img src={PatchSizeIcon} alt="view finder size" />
        </Grid>
      </Grid>
    </>
  );
};

export type WrapProps = {
  onlySlider?: boolean;
} & ContentProps;

const PatchSize: FC<WrapProps> = (props) => {
  const [_] = useI18n();
  if (!props.onlySlider) {
    return (
      <ModuleSettingsWrap title={_("settings")}>
        <Content {...props} />
      </ModuleSettingsWrap>
    );
  }
  return <Content {...props} />;
};

export default PatchSize;
