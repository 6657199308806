import React, { useContext, useState } from "react";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import EvaluationButton from "../../../components/EvaluationButton";
import EvaluationDialog from "../../../components/Evaluation/EvaluationDialog";
import { PropsFromRedux } from "../ModelDetailContainer";
import { OcrModel } from "../../../types/modules";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import useI18n from "../../../hooks/useI18n";
import SliderWithField from "../../../components/SliderWithField";
import AutoThresholdDialog from "../../../components/AutoThresholdDialog";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import ChangeModelPanel from "../../../components/ChangeModelPanel";
import ModelListContainer from "../ModelListContainer";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";

const DEFAULT_EVALUATION = { rules: [], enable: true };

const ModelDetailView = (props: PropsFromRedux & { model: OcrModel }) => {
  const context = useContext(HelperContext);
  const [showAutoThreshold, setAutoThreshold] = useState(false);
  const moduleId = props.module.id;
  const modelId = props.model.modelId;
  const [t] = useI18n();

  const [showEvalutionDialog, setShowEvaluationDialog] = useState(false);

  const getImages = () => {
    return props.images.map((i) => {
      if (props.model.trainingImages.includes(i.id)) {
        return { ...i, label2: "T", isTraining: true };
      }
      return i;
    });
  };

  const images = getImages();

  return (
    <>
      <ImageListWithMarkers
        detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
        listHeight={CON_MIN_HEIGHT_HEADER}
        ImageDetailProps={{ defaultScale: 0.9 }}
        moduleId={moduleId}
        readOnly
        ImageListProps={{
          showSelectedFilter: true,
          images: images,
        }}
        includeLast
      >
        <ModuleRightToolPanel>
          <ChangeModelPanel model={props.model}>
            <ModelListContainer moduleId={props.module.id} />
          </ChangeModelPanel>
          <ModuleSettingsWrap title={t("settings")} extraTopMargin>
            <SliderWithField
              update
              marginTop
              value={props.model.threshold}
              label={t("confidence_threshold")}
              onChange={(value) => {
                props.setThreshold({
                  value,
                  modelId: props.model.modelId,
                });
              }}
              size="small"
              min={1}
              step={0.1}
              max={100}
            />
            {/* <Button variant="outlined" onClick={() => setAutoThreshold(true)}>
              Auth threshold
            </Button> */}
          </ModuleSettingsWrap>
          <ModuleSettingsWrap>
            <EvaluationButton onClick={() => setShowEvaluationDialog(true)} />
          </ModuleSettingsWrap>
        </ModuleRightToolPanel>
      </ImageListWithMarkers>
      <EvaluationDialog
        open={showEvalutionDialog}
        onClose={() => setShowEvaluationDialog(false)}
        classNameList={[]}
        values={props.model.evaluation || DEFAULT_EVALUATION}
        valueType="string"
        compareTypes={[]}
        onSave={(evaluation) =>
          props.setModelEvaluation({ modelId, evaluation })
        }
      />
      <AutoThresholdDialog
        open={showAutoThreshold}
        onClose={() => setAutoThreshold(false)}
        onStart={(classification) => {
          const flow = context.helper.getSettings({
            moduleId: props.module.id,
            includeLast: true,
          });
          props.ocrStartThresholdCalc({
            modelId: props.model.modelId,
            moduleId: props.module.id,
            flow,
            classification,
          });
        }}
        images={props.images}
        values={{
          ok: [],
          nok: [],
        }}
        onChangeSensitivityClassification={() => {}}
        moduleId={props.module.id}
        getImageItem={(item) => images.find((i) => i.id === item.id)}
      />
    </>
  );
};

export default ModelDetailView;
