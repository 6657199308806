/* eslint react/sort-comp: 0, consistent-return: 0 */
import React, { Component } from "react";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import {
  classifierAuto,
  classifierStartTraining,
  confirmDangerSettings,
  resetModuleValues,
  setVisitedModule,
} from "../../../actions/actions";
import ClassNameList from "../../../components/ClassNameList";
import {
  findDetectedRectangles,
  imagesAutoClassification,
  notEmpty,
} from "../../../utils/common";
import ClassNamesManagerDialog from "../../../components/ClassNamesManagerDialog";
import AutoClassifierDialog from "../../../components/AutoClassifierDialog";
import TrainingDialog from "../../../components/Augmentation/TrainingDialog";
import Loader from "../../../components/Loader/Loader";
import DangerSettingsDialog from "../../../components/DangerSettingsDialog";
import ClassifierSettingDialog from "./ClassifierSettingDialog";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import TrainingButton from "../../../components/TrainingButton";
import { getDictionaryById } from "../../../store/utils";
import { PropsFromRedux } from "../TrainingContainer";
import withI18n from "../../../utils/withI18n";
import { Image, Rectangle } from "../../../types/common";
import { ClassifierModule } from "../../../types/modules";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import { Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Settings";

const typeLabel = {
  context: "previous_modules",
  full: "whole_image",
  static: "static_position",
};

type Props = PropsFromRedux & { t: (key: string) => string };

type State = {
  showTrainingDialog: boolean;
  selectedRectangle: null | Rectangle;
  selectedImage: null | Image;
  showClassNamesDialog: boolean;
  showAutoClassifierDialog: boolean;
  showSettingsDialog: boolean;
  selectedIds: number[];
  firstVisit: boolean;
};

class TrainingView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  state: State = {
    showTrainingDialog: false,
    selectedRectangle: null,
    selectedImage: null,
    showClassNamesDialog: false,
    showSettingsDialog: false,
    showAutoClassifierDialog: false,
    selectedIds: [],
    firstVisit: false,
  };

  componentDidMount() {
    if (!this.props.module.visited) {
      this.setState({
        showSettingsDialog: true,
        firstVisit: true,
      });
      setVisitedModule(this.props.moduleId);
    }
  }

  getStaticRectangles() {
    return this.props.module.values.staticRectangles || [];
  }

  getCurrentRectangles = (currentImage: Image): Rectangle[] => {
    if (this.props.module.values.config.SOURCE === "full") {
      return [];
    }
    if (currentImage) {
      const currentRectangles = this.findCurrentRectangles(currentImage);

      const getClassNames = (index: number) => {
        const currentItem = this.props.values.imageRectangles.find(
          (i) => i.id === currentImage.id
        );
        const currentClassNameIds =
          currentItem && currentItem.rectangles[index]
            ? currentItem.rectangles[index].classNames
            : [];
        const classItem = currentClassNameIds
          .map((id) => this.props.rectangleClassNames.find((i) => i.id === id))
          .filter(notEmpty);
        return classItem ?? [];
      };

      return currentRectangles
        ? currentRectangles.rectangles.map((i, index) => {
            const classNames = getClassNames(index);
            return {
              ...i,
              detected: this.props.module.values.config.SOURCE === "context",
              classNames: classNames,
            };
          })
        : [];
    }
    return [];
  };

  findCurrentRectangles(currentImage: Image) {
    const settings = this.context.helper.getSettings({
      moduleId: this.props.moduleId,
    });
    return this.props.module.values.config.SOURCE === "static"
      ? { rectangles: this.getStaticRectangles(), error: false }
      : findDetectedRectangles({
          detectedRectangles: this.props.detectedRectangles,
          settings,
          imageId: currentImage.id,
        });
  }

  isError(currentImage: Image | null) {
    if (!currentImage) return false;
    const detectedRectangles = this.findCurrentRectangles(currentImage);
    return detectedRectangles ? detectedRectangles.error : false;
  }

  getImages = () => {
    const imageRectanglesDict = getDictionaryById(
      this.props.values.imageRectangles
    );
    return this.props.images.map((image) => {
      const selected = imageRectanglesDict[image.id];

      if (!selected) {
        return image;
      }

      const isMarked =
        selected.rectangles?.some((i) => i && i.classNames.length > 0) ?? false;

      return { ...image, isMarked };
    });
  };

  getRectangleIndex() {
    const { selectedImage, selectedRectangle } = this.state;
    if (!selectedImage) {
      return null;
    }

    const settings = this.context.helper.getSettings({
      moduleId: this.props.moduleId,
    });
    const detected =
      this.props.module.values.config.SOURCE === "static"
        ? { rectangles: this.getStaticRectangles() }
        : findDetectedRectangles({
            detectedRectangles: this.props.detectedRectangles,
            settings,
            imageId: selectedImage.id,
          });
    if (!detected || !selectedRectangle) {
      return null;
    }
    return detected.rectangles.findIndex((i) => i.id === selectedRectangle.id);
  }

  getRectangleClassName(): [] | [number] | null {
    const index = this.getRectangleIndex();
    if (index === null || !this.state.selectedImage) {
      return null;
    }

    const selected = this.props.values.imageRectangles.find(
      (i) => i.id === this.state.selectedImage?.id
    );
    return selected && selected.rectangles[index]
      ? selected.rectangles[index].classNames
      : [];
  }

  handleChangeClassName = (type: [number] | []) => {
    if (this.state.showTrainingDialog || !this.state.selectedImage) {
      return;
    }

    const { selectedImage } = this.state;

    if (this.props.module.values.config.SOURCE === "full") {
      const ids = [...this.state.selectedIds, this.state.selectedImage.id];
      if (this.state.selectedIds.length === 0) {
        this.props.classifierEditImageRectangles(
          this.props.moduleId,
          this.state.selectedImage.id,
          [{ width: 1, height: 1, y: 0, x: 0, classNames: type }]
        );
        return;
      }
      const nextImageRectangles = this.props.module.values.imageRectangles.map(
        (i) => {
          if (ids.indexOf(i.id) !== -1) {
            return {
              ...i,
              rectangles: [
                { width: 1, height: 1, y: 0, x: 0, classNames: type },
              ],
            };
          }
          return i;
        }
      );
      ids.forEach((id) => {
        const isInArray = nextImageRectangles.find((i) => i.id === id);
        if (!isInArray) {
          nextImageRectangles.push({
            id,
            rectangles: [{ width: 1, height: 1, y: 0, x: 0, classNames: type }],
          });
        }
      });
      this.props.classifierSetImageRectangles({
        moduleId: this.props.moduleId,
        imageRectangles: nextImageRectangles,
      });
    } else {
      const selected = this.props.values.imageRectangles.find(
        (i) => i.id === selectedImage.id
      );
      const index = this.getRectangleIndex();

      if (index === null) {
        return;
      }

      let rectangles = selected
        ? [...selected.rectangles]
        : this.getCurrentRectangles(this.state.selectedImage).map(() => ({
            classNames: [] as [] | [number],
          }));

      if (rectangles.length < index) {
        const emptyArray = new Array(index + 1).fill({
          classNames: [],
        });
        rectangles.forEach((value, i) => {
          emptyArray[i] = value;
        });
        rectangles = emptyArray;
      }

      rectangles[index] =
        type.length > 0
          ? {
              classNames: type,
            }
          : {
              classNames: [],
            };
      this.props.classifierEditImageRectangles(
        this.props.moduleId,
        selectedImage.id,
        rectangles
      );
    }
  };

  handleSelectImage = (image: Image) => {
    if (image) {
      const settings = this.context.helper.getSettings({
        moduleId: this.props.moduleId,
      });
      this.props.getRectangles({ settings, imageId: image.id });
    }

    this.setState({
      selectedImage: image,
      selectedRectangle: null,
    });
  };

  handleStartTraining = (name: string, modelId: number | null) => {
    const { imageRectangles, classNames, config } = this.props.values;
    classifierStartTraining({
      name,
      trainingParams: {
        moduleId: this.props.moduleId,
        modelId: modelId !== -1 ? modelId : null,
        imageRectangles,
        classNames,
        config,
        flow: this.context.helper.getSettings({
          moduleId: this.props.moduleId,
        }),
        staticRectangles: this.getStaticRectangles(),
      },
    });
  };

  handleClickResetModuleValues = () => {
    resetModuleValues(this.props.moduleId);
  };

  handleClickAutoClassification = () => {
    this.setState({
      showAutoClassifierDialog: true,
    });
  };

  getInfo = ({ filtered, all }: { filtered: number; all: number }) => {
    const selected = this.props.values.imageRectangles.filter(
      (i) => i.rectangles.filter((r) => r && r.classNames.length > 0).length > 0
    ).length;
    const countRectanglesList = this.props.values.imageRectangles.map(
      (i) => i.rectangles.filter((r) => r && r.classNames.length > 0).length
    );
    const reducer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;
    const countRectangles = countRectanglesList.reduce(reducer, 0);
    return {
      title: this.props.t("image_list_info_help_rectangles"),
      text: `${countRectangles} (${selected}) / ${filtered} / ${all}`,
    };
  };

  getTrainingErrorMessage(classNameList: { count: number }[]) {
    const classNames = classNameList.filter((i) => i.count > 0);
    if (classNames.length < 2) {
      return this.props.t("classifier_error_min_classnames");
    }
  }

  getClassNameList() {
    const allClassNames = this.props.values.imageRectangles
      .map((i) =>
        (i.rectangles || []).reduce(
          (allRectangles: number[], rectangle) => [
            ...allRectangles,
            ...(rectangle.classNames || []),
          ],
          []
        )
      )
      .flat();
    return this.props.rectangleClassNames.map((item) => {
      const count = allClassNames.filter((i) => i === item.id).length;
      return { ...item, count };
    });
  }

  getActiveClassName = (rectangleClassName: [] | [number] | null) => {
    if (!this.state.selectedImage) {
      return undefined;
    }
    if (this.props.module.values.config.SOURCE === "full") {
      const ids = [...this.state.selectedIds, this.state.selectedImage.id];
      const classes = ids.map((imageId) => {
        const item = this.props.module.values.imageRectangles.find(
          (i) => i.id === imageId
        );
        return item &&
          item.rectangles &&
          item.rectangles.length > 0 &&
          item.rectangles[0].classNames &&
          item.rectangles[0].classNames.length > 0
          ? item.rectangles[0].classNames[0]
          : null;
      });

      if (classes.filter((i) => i === classes[0]).length === classes.length) {
        return classes[0];
      }
      return null;
    }
    return Array.isArray(rectangleClassName) && rectangleClassName.length === 0
      ? null
      : rectangleClassName && rectangleClassName[0];
  };

  handleChangeRectangles = (_: any, rectangles: Rectangle[]) => {
    let nextImageRectangles = this.props.module.values.imageRectangles;
    if (rectangles.length < this.getStaticRectangles().length) {
      const deletedIndex = this.getStaticRectangles().findIndex(
        (i) => !rectangles.find((k) => k.id === i.id)
      );
      nextImageRectangles = this.props.module.values.imageRectangles.map(
        (i) => ({
          ...i,
          rectangles: i.rectangles.filter((_, index) => index !== deletedIndex),
        })
      );
    }
    this.props.setModuleValue({
      moduleId: this.props.moduleId,
      name: "values",
      value: {
        ...this.props.module.values,
        staticRectangles: rectangles,
        imageRectangles: nextImageRectangles,
      },
    });
  };

  render() {
    const _ = this.props.t;
    const { values } = this.props.module;
    const classNameList = this.getClassNameList();
    const rectangleClassName = this.getRectangleClassName();
    const showLoader =
      this.state.selectedImage &&
      this.findCurrentRectangles(this.state.selectedImage) === undefined;

    const emptyAnnotations =
      values.imageRectangles.length + values.staticRectangles.length === 0;

    return (
      <div>
        <ImageListWithMarkers
          ImageDetailProps={{
            selectedRectangle: this.state.selectedRectangle,
            onSelectRectangle: (rectangle) =>
              this.setState({ selectedRectangle: rectangle }),
            classifierMode: this.props.module.values.config.SOURCE !== "static",
            defaultScale: 0.9,
          }}
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          ImageListProps={{
            showSelectedFilter: true,
            selectMore: true,
            onSelectMore: (ids) =>
              this.setState({
                selectedIds: ids,
              }),
            images: this.getImages(),
          }}
          onChangeRectangles={this.handleChangeRectangles}
          disabledKeyListener={
            this.state.showTrainingDialog || this.state.showAutoClassifierDialog
          }
          moduleId={this.props.moduleId}
          getCurrentRectangles={this.getCurrentRectangles}
          onSelectImage={this.handleSelectImage}
          showClassification
          showLoader={!!showLoader}
          showError={this.isError(this.state.selectedImage)}
          getInfo={this.getInfo}
        >
          <ModuleRightToolPanel>
            <ModuleSettingsWrap title={_("type")}>
              <Grid
                wrap="nowrap"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item zeroMinWidth>
                  <Typography>
                    {_(typeLabel[this.props.module.values.config.SOURCE])}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    color="secondary"
                    onClick={() => this.setState({ showSettingsDialog: true })}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ModuleSettingsWrap>
            <ModuleSettingsWrap title={_("annotations")}>
              <ClassNameList
                t={_}
                emptyAnnotations={emptyAnnotations}
                onClickAutoClassifier={this.handleClickAutoClassification}
                onClickRemove={this.handleClickResetModuleValues}
                key={
                  this.state.selectedRectangle &&
                  this.state.selectedRectangle.id
                }
                items={classNameList}
                value={this.getActiveClassName(rectangleClassName)}
                isActive={
                  rectangleClassName !== null ||
                  this.props.module.values.config.SOURCE === "full"
                }
                onChange={(value: number | null) =>
                  this.handleChangeClassName(value ? [value] : [])
                }
                onOpenEditClassNames={() =>
                  this.setState({ showClassNamesDialog: true })
                }
              />
            </ModuleSettingsWrap>

            {!this.props.trainingDisabled && (
              <ModuleSettingsWrap>
                <TrainingButton
                  onClick={() => {
                    this.setState({ showTrainingDialog: true });
                  }}
                />
              </ModuleSettingsWrap>
            )}
          </ModuleRightToolPanel>
        </ImageListWithMarkers>
        <TrainingDialog
          modelsCounter={this.props.modelsCounter}
          hiddenNetworkSize
          allowShift
          allowFlip
          allowShear
          allowRotate
          allowMaxSize
          allowColorJittering
          open={this.state.showTrainingDialog}
          onClose={() => this.setState({ showTrainingDialog: false })}
          onStartTraining={this.handleStartTraining}
          models={this.props.models}
          config={this.props.values.config}
          onChangeConfig={(values) => {
            this.props.editConfig(this.props.moduleId, values);
          }}
          errorMessage={
            this.getTrainingErrorMessage(classNameList) || undefined
          }
        />
        <ClassNamesManagerDialog
          items={this.props.rectangleClassNames}
          onClose={() => this.setState({ showClassNamesDialog: false })}
          onUpdate={(classNames) => {
            const classNamesId = classNames.map(({ id }) => id);
            // remove deleted classNames from rectangles
            const rectangles: ClassifierModule["values"]["imageRectangles"] =
              this.props.values.imageRectangles.map((i) => ({
                ...i,
                rectangles: i.rectangles.map((r) => ({
                  ...r,
                  classNames:
                    r.classNames[0] &&
                    classNamesId.indexOf(r.classNames[0]) !== -1
                      ? r.classNames
                      : [],
                })),
              }));

            this.props.classifierEditAllImageRectangles(
              this.props.moduleId,
              rectangles
            );
            this.props.classifierSetClassNames({
              moduleId: this.props.moduleId,
              classNames,
            });
          }}
          open={this.state.showClassNamesDialog}
        />
        <AutoClassifierDialog
          open={this.state.showAutoClassifierDialog}
          onClose={() => this.setState({ showAutoClassifierDialog: false })}
          classNames={this.props.rectangleClassNames}
          onSet={(patternItems, isRegex) => {
            if (this.props.module.values.config.SOURCE !== "context") {
              const { results, error } = imagesAutoClassification(
                this.props.images,
                patternItems,
                isRegex
              );
              if (error) {
                this.props.addErrorMessage(error);
              }
              const imageRectangles: ClassifierModule["values"]["imageRectangles"] =
                [];
              if (this.props.module.values.config.SOURCE === "static") {
                results.forEach((imagesIds, index) => {
                  imagesIds.forEach((imageId) => {
                    imageRectangles.push({
                      rectangles: this.props.module.values.staticRectangles.map(
                        () => ({
                          width: 1,
                          height: 1,
                          y: 0,
                          x: 0,
                          classNames: [
                            this.props.rectangleClassNames[index].id,
                          ],
                        })
                      ),
                      id: imageId,
                    });
                  });
                });
              } else {
                results.forEach((imagesIds, index) => {
                  imagesIds.forEach((imageId) => {
                    imageRectangles.push({
                      rectangles: [
                        {
                          width: 1,
                          height: 1,
                          y: 0,
                          x: 0,
                          classNames: [
                            this.props.rectangleClassNames[index].id,
                          ],
                        },
                      ],
                      id: imageId,
                    });
                  });
                });
              }
              this.props.classifierSetImageRectangles({
                moduleId: this.props.moduleId,
                imageRectangles,
              });
            } else {
              classifierAuto({
                moduleId: this.props.moduleId,
                flow: this.context.helper.getSettings({
                  moduleId: this.props.moduleId,
                }),
                patternItems,
                isRegex,
              });
            }
            this.setState({
              showAutoClassifierDialog: false,
            });
          }}
        />
        <ClassifierSettingDialog
          withoutSubmit={this.state.firstVisit}
          open={this.state.showSettingsDialog}
          onClose={() =>
            this.setState({ showSettingsDialog: false, firstVisit: false })
          }
          values={this.props.values.config}
          onChange={(values) => {
            this.props.editConfig(this.props.moduleId, values);
            this.props.classifierResetImageRectangles({
              moduleId: this.props.moduleId,
            });
          }}
        />
        {this.props.runningAutoClassification && <Loader />}
        <DangerSettingsDialog
          onClose={() => {
            confirmDangerSettings(this.props.moduleId);
          }}
          onRestart={() => {
            this.props.classifierResetImageRectangles({
              moduleId: this.props.moduleId,
            });
          }}
          open={this.props.dangerSettings}
        />
      </div>
    );
  }
}

export default withI18n(TrainingView);
