import React, { Component } from "react";
import ReactResizeDetector from "react-resize-detector";
import Utils, { PatchSizeProps } from "./Utils";
import { PRIMARY_COLOR } from "../../../theme";
import { Line, Rectangle } from "../../../types/common";
import Dashboard, {
  LineT,
  PolygonT,
  RectangleT,
  Tool,
} from "../Dashboard/Objects/Dashboard";
import { getRectangleText } from "../../../utils/format";

const styles = {
  patchExample: {
    position: "absolute",
    right: 20,
    top: 61,
    border: `solid 2px ${PRIMARY_COLOR}`,
    borderRadius: 4,
    pointerEvents: "none",
  },
  distance: {
    position: "absolute",
    whiteSpace: "nowrap",
    color: "yellow",
    background: "rgba(0,0,0,0.3)",
  },
} as const;

function rotate(
  cx: number,
  cy: number,
  x: number,
  y: number,
  angle: number
): [number, number] {
  if (angle === 0) {
    return [x, y];
  }
  const radians = (Math.PI / -180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}

const middleToCorner = (data: any): any => {
  const p1 = rotate(
    data.x + data.width / 2,
    data.y + data.height / 2,
    data.x,
    data.y,
    data.rotate || 0
  );
  const poss = [data.x - p1[0], data.y - p1[1]];
  return {
    ...data,
    x: data.x - poss[0],
    y: data.y - poss[1],
  };
};

const cornerToMidddle = (data: any): any => {
  const p1 = rotate(
    data.x + data.width / 2,
    data.y + data.height / 2,
    data.x,
    data.y,
    data.rotate || 0
  );
  const poss = [data.x - p1[0], data.y - p1[1]];
  return {
    ...data,
    x: data.x + poss[0],
    y: data.y + poss[1],
  };
};

function hexToRgb(c: string): [number, number, number] {
  const aRgbHex = c.substring(1).match(/.{1,2}/g) as [string, string, string];
  const aRgb: [number, number, number] = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
  return aRgb;
}

const pixelsToPercent = <
  T extends { x: number; y: number; width: number; height: number }
>(
  item: T,
  imageSize: { width: number; height: number }
): T => ({
  ...item,
  percent: true,
  x: item.x / imageSize.width,
  y: item.y / imageSize.height,
  width: item.width / imageSize.width,
  height: item.height / imageSize.height,
});

const pointsPixelsToPercent = (
  points: [number, number][],
  imageSize: { width: number; height: number }
): [number, number][] => {
  return points.map(([a, b]) => [a / imageSize.width, b / imageSize.height]);
};

const pointsPercentToPixels = (
  points: [number, number][],
  imageSize: { width: number; height: number }
): [number, number][] => {
  return points.map(([a, b]) => [a * imageSize.width, b * imageSize.height]);
};

const posPixelsToPercent = (
  pos: { x: number; y: number },
  imageSize: { width: number; height: number }
): { x: number; y: number } => {
  return { x: pos.x / imageSize.width, y: pos.y / imageSize.height };
};

export type Props = {
  rectangles: Rectangle[];
  imgWidth: number;
  imgHeight: number;
  imgSrc: string;
  onChangeRectangles: (
    rectangle: Rectangle[],
    { width, height }: { width: number; height: number }
  ) => void;
  square?: boolean;
  readOnly?: boolean;
  classifierMode?: boolean;
  onSelectRectangle: (rectangle: Rectangle | null) => void;
  onOpenHelper: () => void;
  selectedRectangle?: Rectangle | null;
  showCircle?: boolean;
  defaultClassName?: any;
  enableRotate?: boolean;
  patchSize?: number;
  onChangePatchSize?: (value: number) => void;
  disabledKeyListener?: boolean;
  onChangeLastRectangle?: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
  lastRectangleSize?: {
    width: number;
    height: number;
    x: number;
    y: number;
    className?: number;
  };
  patchSizeProps?: PatchSizeProps;
  lines?: Line[];
  maxRatio?: boolean;
  allowOverflow?: boolean;
  paddingBorder?: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => [number, number, number, number];
  lineMode?: boolean;
  fixSize?: { width: number; height: number };
  minRectangleSize?: boolean;
  tool?: Tool;
  onChangeLines?: (lines: Line[]) => void;
} & {};

type State = {
  patchSize: number;
  scale: number;
};

class ObjectMarker extends Component<Props, State> {
  history: Rectangle[][] = [];

  resetFunc: (() => void) | null = null;

  ref = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      scale: 1,
      patchSize: 8,
    };
  }

  componentDidMount() {
    this.history.push(this.props.rectangles);
    if (this.props.onChangePatchSize && this.props.patchSize) {
      this.setState({
        patchSize: this.props.patchSize,
      });
    }
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  renderClassNames(item: Rectangle) {
    const className =
      item.classNames && item.classNames.length > 0 ? item.classNames[0] : null;
    if (className) {
      return className.accuracy
        ? `${className.label} ${Math.round(className.accuracy * 100)}%`
        : className.label;
    }
    return "";
  }

  getText = (item: Rectangle) => {
    if (item.text) {
      return item.text;
    }
    let output = item.label || "";
    output += `${this.renderClassNames(item)} `;

    if (item.confidence) {
      output += `${Math.round(item.confidence * 100)}% `;
    }
    return output.trim();
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;
    if (e.repeat) {
      return;
    }

    // r - restart view
    if (keyCode === 82) {
      e.stopPropagation();
      // this.handleResize();
    }

    if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
      if (this.history.length > 1) {
        this.history.pop();
        this.props.onSelectRectangle(null);
        const historyItem = this.history[this.history.length - 1];
        this.props.onChangeRectangles(historyItem, {
          width: this.props.imgWidth,
          height: this.props.imgHeight,
        });
      }
    }
  };

  handleResize = () => {
    this.resetFunc?.();
  };

  getLines = (readOnly: boolean = false): LineT[] => {
    return (
      this.props.lines?.map((line) => {
        const l: LineT = {
          id: line.id,
          start: line.percent
            ? {
                x: line.start.x * this.props.imgWidth,
                y: line.start.y * this.props.imgHeight,
              }
            : line.start,
          end: line.percent
            ? {
                x: line.end.x * this.props.imgWidth,
                y: line.end.y * this.props.imgHeight,
              }
            : line.end,
          disabled: line.disabled || readOnly,
          text: readOnly ? line.length + "" : line.text,
        };
        return l;
      }) ?? []
    );
  };

  render() {
    const { imgHeight, imgWidth, paddingBorder } = this.props;

    const imageSize = { width: imgWidth, height: imgHeight };

    const percentToPixels = (values: Rectangle) => {
      if (values.fixSize) {
        return {
          ...values,
          x: values.x * imgWidth,
          y: values.y * imgHeight,
        };
      }
      return !values.percent
        ? values
        : {
            ...values,
            x: values.x * imgWidth,
            y: values.y * imgHeight,
            width: values.width * imgWidth,
            height: values.height * imgHeight,
          };
    };

    let originalRectangles = this.props.rectangles;

    // recalculate normal size by scale and position
    const rectangles = originalRectangles;

    const { onOpenHelper } = this.props;

    const items: (LineT | RectangleT | PolygonT)[] = this.props.lineMode
      ? this.getLines()
      : rectangles.map((i) => {
          if (i.points) {
            const t: PolygonT = {
              id: i.id as number,
              text: getRectangleText(i),
              points: pointsPercentToPixels(i.points, imageSize),
              disabled: i.disabled,
            };
            return t;
          } else {
            const k = middleToCorner(percentToPixels(i));
            const t: RectangleT = {
              x: k.x,
              y: k.y,
              moveable: !this.props.classifierMode,
              width: k.width,
              height: k.height,
              id: k.id as number,
              disabled: k.disabled,
              color: k.color ? hexToRgb(k.color) : undefined,
              text: getRectangleText(k),
              rotate: k.rotate || 0,
              disableDelete: k.disableDelete,
            };
            return t;
          }
        });

    if (!this.props.lineMode) {
      items.push(...this.getLines(true));
    }

    if (paddingBorder) {
      const border = Array.isArray(paddingBorder)
        ? paddingBorder
        : paddingBorder({ width: imgWidth, height: imgHeight });

      if (border) {
        const item: RectangleT = {
          id: -1,
          x: border[0],
          y: border[1],
          width: imgWidth - border[0] - border[2],
          height: imgHeight - border[1] - border[3],
          transparent: true,
          disabled: true,
        };

        items.push(item);
      }
    }

    return (
      <ReactResizeDetector targetRef={this.ref} handleWidth handleHeight>
        {({ width, height }) => (
          <div ref={this.ref} style={{ height: "100%" }}>
            {width !== undefined &&
              height !== undefined &&
              width !== 0 &&
              height !== 0 && (
                <>
                  <Utils
                    onReset={this.handleResize}
                    onOpenHelper={onOpenHelper}
                    onChangePatchSize={
                      this.props.onChangePatchSize &&
                      ((patchSize) => this.setState({ patchSize }))
                    }
                    patchSize={this.state.patchSize}
                    patchSizeProps={this.props.patchSizeProps}
                  />
                  <Dashboard
                    rectangleMinSize={
                      this.props.minRectangleSize ? 32 : undefined
                    }
                    selectedId={this.props.selectedRectangle?.id}
                    items={items}
                    rotate={this.props.enableRotate}
                    getResetFunction={(reset) => {
                      this.resetFunc = reset;
                    }}
                    width={width}
                    height={height - 41}
                    onChangeScale={(scale) => this.setState({ scale })}
                    //circle
                    onDbClick={(pos) => {
                      if (
                        !this.props.lastRectangleSize ||
                        this.props.readOnly
                      ) {
                        return;
                      }

                      const rePos = pixelsToPercent(
                        { width: 1, height: 1, ...pos },
                        imageSize
                      );
                      const newRectangle = {
                        ...this.props.lastRectangleSize,
                        x: rePos.x - this.props.lastRectangleSize.width / 2,
                        y: rePos.y - this.props.lastRectangleSize.height / 2,
                        id: Date.now(),
                        percent: true,
                      };

                      if (this.props.defaultClassName) {
                        newRectangle.className = this.props.defaultClassName;
                      }
                      const rectangles = [
                        ...this.props.rectangles,
                        newRectangle,
                      ];
                      this.history.push(rectangles);
                      this.props.onChangeRectangles(rectangles, imageSize);
                      this.props.onSelectRectangle(newRectangle);
                    }}
                    //rotate
                    onSelect={(id) => {
                      const item = this.props.rectangles.find(
                        (i) => i.id === id
                      );
                      if (!item) {
                        return;
                      }
                      //const nextRectangles = this.props.rectangles.filter(i => i.id !== id)
                      //nextRectangles.push(item)
                      const nextRectangles = this.props.rectangles.map((i) =>
                        i.id === id ? item : i
                      );
                      this.props.onChangeRectangles(nextRectangles, imageSize);
                      this.props.onSelectRectangle(item);
                    }}
                    imageUrl={this.props.imgSrc}
                    imageSize={{
                      width: this.props.imgWidth,
                      height: this.props.imgHeight,
                    }}
                    onChangeItems={() => {}}
                    tool={
                      this.props.readOnly || this.props.classifierMode
                        ? undefined
                        : this.props.tool || Tool.Rectangle
                    }
                    onEdit={(index, values) => {
                      if (this.props.readOnly) {
                        return;
                      }
                      const rectangles = [...this.props.rectangles];
                      if (
                        "start" in values &&
                        this.props.lines &&
                        this.props.onChangeLines
                      ) {
                        const lines = [...this.props.lines];
                        lines[index] = {
                          ...lines[index],
                          start: posPixelsToPercent(values.start, imageSize),
                          end: posPixelsToPercent(values.end, imageSize),
                        };
                        this.props.onChangeLines?.(lines);
                        return;
                      } else if ("points" in values) {
                        rectangles[index] = {
                          ...rectangles[index],
                          points: pointsPixelsToPercent(
                            values.points,
                            imageSize
                          ),
                        };
                      } else {
                        const newPos = pixelsToPercent(
                          cornerToMidddle(values) as RectangleT,
                          imageSize
                        );
                        rectangles[index] = {
                          ...rectangles[index],
                          x: newPos.x,
                          y: newPos.y,
                          width: newPos.width,
                          height: newPos.height,
                          rotate: newPos.rotate,
                        };
                      }

                      this.history.push(rectangles);
                      this.props.onChangeRectangles(rectangles, imageSize);
                    }}
                    onDelete={(index) => {
                      if (this.props.readOnly) {
                        return;
                      }
                      if (this.props.tool === Tool.Line) {
                        const lines = [...(this.props.lines || [])];
                        this.props.onChangeLines?.(
                          lines.filter((_, i) => i !== index)
                        );
                      } else {
                        const rectangles = this.props.rectangles.filter(
                          (_, i) => i !== index
                        );
                        this.history.push(rectangles);
                        this.props.onChangeRectangles(rectangles, imageSize);
                      }
                    }}
                    onCreate={(values) => {
                      if (this.props.readOnly) {
                        return;
                      }
                      if ("start" in values && this.props.onChangeLines) {
                        const lines = [...(this.props.lines || [])];
                        lines.push({
                          id: Date.now(),
                          start: posPixelsToPercent(values.start, imageSize),
                          end: posPixelsToPercent(values.end, imageSize),
                          percent: true,
                          label: "Line",
                          width: 3,
                          method: "max",
                        });
                        this.props.onChangeLines(lines);
                      } else if ("points" in values) {
                        const newRectangle: Rectangle = {
                          x: 0,
                          y: 0,
                          width: 0,
                          height: 0,
                          percent: true,
                          id: values.id,
                          points: pointsPixelsToPercent(
                            values.points,
                            imageSize
                          ),
                        };
                        const rectangles = [
                          ...this.props.rectangles,
                          newRectangle,
                        ];
                        this.history.push(rectangles);
                        this.props.onChangeRectangles(rectangles, imageSize);
                        this.props.onSelectRectangle(newRectangle);
                      } else {
                        const pos = pixelsToPercent(
                          values as RectangleT,
                          imageSize
                        );
                        const newRectangle = {
                          x: pos.x,
                          y: pos.y,
                          width: pos.width,
                          height: pos.height,
                          percent: true,
                          id: values.id,
                        };

                        if (
                          this.props.onChangeLastRectangle &&
                          (!this.props.lastRectangleSize ||
                            this.props.lastRectangleSize.width !==
                              newRectangle.width ||
                            this.props.lastRectangleSize.height !==
                              newRectangle.height)
                        ) {
                          this.props.onChangeLastRectangle({
                            width: newRectangle.width,
                            height: newRectangle.height,
                          });
                        }

                        if (this.props.defaultClassName) {
                          //newRectangle.className = this.props.defaultClassName;
                        }
                        const rectangles = [
                          ...this.props.rectangles,
                          newRectangle,
                        ];
                        this.history.push(rectangles);
                        this.props.onChangeRectangles(rectangles, imageSize);
                        this.props.onSelectRectangle(newRectangle);
                      }
                    }}
                  />
                  {this.props.patchSize && (
                    <div
                      style={{
                        width: this.props.patchSize * this.state.scale,
                        height: this.props.patchSize * this.state.scale,
                        ...styles.patchExample,
                      }}
                    />
                  )}
                </>
              )}
          </div>
        )}
      </ReactResizeDetector>
    );
  }
}

export default ObjectMarker;
