import React, { Component } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SliderWithField from "../../../components/SliderWithField";
import Lines from "./Lines";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import { Line } from "../../../types/common";
import { MeasureModule } from "../../../types/modules";
import withI18n from "../../../utils/withI18n";

type Props = {
  values: MeasureModule["values"];
  onChange: (values: MeasureModule["values"]) => void;
  showOriginal: boolean;
  onChangeShowOriginal: (show: boolean) => void;
  onChangeLines: (values: Line[]) => void;
  lines: Line[];
  t: (key: string) => string;
};

type State = {
  values: MeasureModule["values"];
};

class Settings extends Component<Props, State> {
  timer?: number;

  state: State = {
    values: this.props.values,
  };

  onChange(values: MeasureModule["values"]) {
    this.setState({
      values,
    });

    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.props.onChange(values);
    }, 1000);
  }

  render() {
    const _ = this.props.t;
    return (
      <div>
        <ModuleSettingsWrap title={this.props.t("view")}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.showOriginal}
                onChange={(e) =>
                  this.props.onChangeShowOriginal(e.target.checked)
                }
              />
            }
            label={this.props.t("original_image")}
          />
        </ModuleSettingsWrap>

        <ModuleSettingsWrap
          title={this.props.t("canny settings")}
          extraTopMargin
        >
          <SliderWithField
            marginTop
            size="small"
            value={this.state.values.threshold}
            label={this.props.t("threshold")}
            onChange={(value) =>
              this.onChange({ ...this.state.values, threshold: value })
            }
            min={0}
            step={1}
            max={170}
          />
        </ModuleSettingsWrap>

        <ModuleSettingsWrap title={_("lines")}>
          <Lines
            items={this.props.lines}
            onChangeItems={this.props.onChangeLines}
          />
        </ModuleSettingsWrap>
      </div>
    );
  }
}

export default withI18n(Settings);
