import React, { useMemo, useState } from "react";
import ImageList from "../../../components/ImageList/ImageListContainer";
import DebuggerTree from "./DebuggerTree";
import { CON_MIN_HEIGHT_HEADER } from "../../../theme";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import { PropsFromRedux } from "../DebuggerContainer";
import { INSPECTION_DOC } from "../../../docLinks";
import useI18n from "../../../hooks/useI18n";
import { Image } from "../../../types/common";
import { findDebuggerInfo } from "../../../utils/common";
import { debuggerImage } from "../../../actions/actions";
import Loader from "../../../components/Loader/Loader";

const styles = {
  wrap: {
    minHeight: CON_MIN_HEIGHT_HEADER,
  },
} as const;

const DebuggerView = (props: PropsFromRedux) => {
  const [_] = useI18n();
  const [currentImageId, setCurrentImageId] = useState<number | undefined>();

  const handleClickImage = (currentImage: Image) => {
    setCurrentImageId(currentImage.id);
    const item = findDebuggerInfo({
      items: props.debuggerInfoItems,
      settings: props.settings,
      imageId: currentImage.id,
    });
    if (!item) {
      debuggerImage({ imageId: currentImage.id, settings: props.settings });
    }
  };

  const debuggerItem = useMemo(() => {
    if (!currentImageId) {
      return null;
    }

    return findDebuggerInfo({
      items: props.debuggerInfoItems,
      settings: props.settings,
      imageId: currentImageId,
    });
  }, [currentImageId, props.settings, props.debuggerInfoItems]);

  return (
    <ModuleWrap
      noImage={props.noImage}
      title={_("inspection")}
      docId={INSPECTION_DOC}
    >
      <div style={styles.wrap}>
        <ImageList
          height={CON_MIN_HEIGHT_HEADER}
          activeId={currentImageId}
          onSelect={handleClickImage}
          getImageSrc={(image) => ({ imageId: image.id, withMask: true })}
        >
          {debuggerItem ? (
            debuggerItem.error ? (
              <h3>{_("error_during_processing")}</h3>
            ) : (
              <DebuggerTree items={props.items} debuggerItem={debuggerItem} />
            )
          ) : (
            <Loader relative />
          )}
        </ImageList>
      </div>
    </ModuleWrap>
  );
};

export default DebuggerView;
