import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import TextField from "@mui/material/TextField";
import JSONPretty from "react-json-pretty";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  analyzeImage,
  analyzeImageContext,
  getLastContext,
  getLastImageContext,
} from "./apiUtils";
import {
  AppBar,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { Alert } from '@mui/material';

type ApiType = "last_image" | "analyze_image";

type ResponseType = "context" | "image" | "annotated_image" | "heatmap";

const responseList: { id: ResponseType; label: string }[] = [
  { id: "context", label: "Context" },
  { id: "image", label: "Image" },
  { id: "annotated_image", label: "Annotated Image" },
  { id: "heatmap", label: "Heatmap" },
];

const apiTypeList: { id: ApiType; label: string }[] = [
  { id: "last_image", label: "Get Last Image" },
  { id: "analyze_image", label: "Analyze Image" },
];

// TODO jss-to-tss-react codemod: '@global' is not supported by tss-react.
// See https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override for alternatives.
const useStyles = makeStyles()((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: grey[100],
    },
  },
  root: {
    padding: theme.spacing(2),
  },
  imageWrap: {
    marginTop: theme.spacing(1),
    marginBottom: -12,
    "& img": {
      maxWidth: "100%",
    },
  },
  form: {
    maxWidth: 600,
    padding: theme.spacing(1),
  },
  fileWrap: {
    margin: `${theme.spacing(1)} 0`,
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    padding: 7,
    paddingLeft: 10.5,
  },
  submitBtn: {
    marginBottom: theme.spacing(1),
  },
  requestDetail: {
    backgroundColor: grey[200],
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  responseInfo: {
    margin: `${theme.spacing(1)} 0`,
    maxWidth: 600,
    border: `1px solid ${blue[300]}`,
  },
  progress: {
    position: "absolute",
    width: "100%",
    top: 44,
    left: 0,
  },
}));

const ApiView = () => {
  const { classes } = useStyles();
  const [responseType, setResponseType] = useState<ResponseType>("context");
  const [loading, setLoading] = useState(false);
  const [contextInBody, setContextInBody] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [apiType, setApiType] = useState<ApiType>("last_image");
  const [response, setResponse] =
    useState<null | { image?: string; context?: any }>(null);

  useEffect(() => {
    // reset
    setResponse(null);
  }, [responseType, apiKey, contextInBody, apiType]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      setResponse(null);
      if (apiType === "last_image") {
        if (responseType === "context") {
          const context = await getLastContext(apiKey);
          setResponse({ context });
        } else {
          const { context, image } = await getLastImageContext(
            contextInBody,
            responseType,
            apiKey
          );
          setResponse({ context, image });
        }
      } else {
        const file = fileRef.current?.files?.[0];
        if (!file) {
          alert("Select image");
          setLoading(false);
          return;
        }
        if (responseType === "context") {
          const { context, image } = await analyzeImageContext(file, apiKey);
          setResponse({ context, image });
        } else {
          const { context, image } = await analyzeImage(
            file,
            contextInBody,
            responseType,
            apiKey
          );
          setResponse({ context, image });
        }
      }
    } catch (e) {
      alert("Error");
    } finally {
      setLoading(false);
    }
  }, [apiType, contextInBody, responseType, apiKey]);

  const url = `${
    apiType === "last_image" ? "/last_image" : "/analyze_image"
  }?reponse_type=${responseType}${
    contextInBody && responseType !== "context" ? "&context_in_body=t" : ""
  }${apiKey ? `&api_key=${apiKey}` : ""}`;

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6">Test API</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Paper className={classes.form}>
          <TextField
            select
            variant="outlined"
            fullWidth
            size="small"
            label="API type"
            margin="dense"
            value={apiType}
            onChange={(e) => setApiType(e.target.value as ApiType)}
          >
            {apiTypeList.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            margin="dense"
            fullWidth
            size="small"
            label="Response type"
            value={responseType}
            onChange={(e) => setResponseType(e.target.value as ResponseType)}
          >
            {responseList.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            size="small"
            label="Api key (optional)"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
          {responseType !== "context" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={contextInBody}
                  onChange={(e) => setContextInBody(e.target.checked)}
                />
              }
              label="Context in body"
            />
          )}
          {apiType === "analyze_image" && (
            <div className={classes.fileWrap}>
              <Typography display="inline">Image: </Typography>{" "}
              <input type="file" ref={fileRef} />
            </div>
          )}
          <Paper className={classes.requestDetail}>
            <Typography color="textSecondary" gutterBottom>
              Request detail
            </Typography>
            <Typography noWrap>
              <i>method:</i> {apiType === "last_image" ? "GET" : "POST"}
            </Typography>
            <Typography noWrap>
              <i>url:</i> {url}
            </Typography>
            {apiType !== "last_image" && (
              <Typography noWrap>
                <i>data:</i> {`<image>`}
              </Typography>
            )}
          </Paper>
          <Button
            variant="outlined"
            color="primary"
            className={classes.submitBtn}
            onClick={handleSubmit}
            disabled={loading}
          >
            Send
          </Button>
        </Paper>

        {loading && <LinearProgress className={classes.progress} />}
        {response && (
          <Alert className={classes.responseInfo} severity="info">
            {responseType === "context" && (
              <>JSON is in the body (application/json)</>
            )}
            {responseType !== "context" && contextInBody && (
              <>
                Image and JSON are in the request body
                (application/octet-stream). First part of the body is an image
                (png), second part is a JSON data. JSON is encoded in base64.{" "}
                <i>ImageLen</i> in request header defines length of the first
                part.
              </>
            )}
            {responseType !== "context" && !contextInBody && (
              <>
                Image is in the request body. JSON data is in the request
                header, JSON is encoded (Base64) in <i>ContextBase64utf</i>{" "}
                item.
              </>
            )}
          </Alert>
        )}
        {response?.image && (
          <div className={classes.imageWrap}>
            <img
              alt="preview"
              src={"data:image/png;base64," + response.image}
            />
          </div>
        )}
        {response?.context && (
          <>
            <JSONPretty json={response.context} />
          </>
        )}
      </div>
    </>
  );
};

export default ApiView;
