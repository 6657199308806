import { connect, ConnectedProps } from "react-redux";
import DebuggerView from "./components/DebuggerView";
import { sortModules, removeInactiveModels } from "../../utils/common";
import { GlobalState } from "../../store/createStore";

// TODO fix immutable

const mapStateToProps = (state: GlobalState) => {
  return {
    settings: removeInactiveModels(sortModules(
      state.database.modules.sort,
      state.database.modules.items,
      {
        supervisedModels: state.database.supervisedModels,
        unsupervisedModels: state.database.unsupervisedModels,
        unifierItems: state.database.unifierItems,
        detectorModels: state.database.detectorModels,
        classifierModels: state.database.classifierModels,
        maskItems: state.database.masksItems,
        ocrModels: state.database.ocrModels,
      }
    )),
    items: state.database.modules.items,
    debuggerInfoItems: state.system.debuggerInfo,
    noImage: state.database.images.length === 0,
  };
};

const connector = connect(mapStateToProps, {})

export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DebuggerView);
