import React, { FC } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextIcon from "@mui/icons-material/TextFormat";
import PanoramaIcon from "@mui/icons-material/Panorama";
import RootIcon from "@mui/icons-material/ViewCompact";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import TuneIcon from "@mui/icons-material/Tune";
import ImageIcon from "@mui/icons-material/Image";
import arrayMove from "array-move";
import CounterIcon from "@mui/icons-material/Exposure";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import {
  AnyElement,
  ElementCounter,
  ElementImage,
  ElementInput,
  ElementLabel,
  ElementSelect,
  ElementSlider,
} from "./types";
import useI18n from "../../../../hooks/useI18n";
import { StrictModeDroppable } from "../../../../components/StrictModeDroppable";

type Props = {
  items: AnyElement[];
  onChange: (items: AnyElement[]) => void;
  activeId: number | null;
  onSelect: (itemId: number) => void;
};

const getIcon = (element: AnyElement) => {
  if (element.type === "input") {
    return <EditIcon />;
  } else if (element.type === "label") {
    return <TextIcon />;
  } else if (element.type === "camera") {
    return <PanoramaIcon />;
  } else if (element.type === "root") {
    return <RootIcon />;
  } else if (element.type === "select") {
    return <ListIcon />;
  } else if (element.type === "slider") {
    return <TuneIcon />;
  } else if (element.type === "image") {
    return <ImageIcon />;
  } else if (element.type === "counter") {
    return <CounterIcon />;
  }
};

const defaultValues = {
  input: {
    type: "input",
    textColor: "black",
    backgroundColor: "#ffffff",
    fontSize: 16,
    label: "Label",
    width: 300,
    targetKey: "input1",
    visibilityByKey: "",
    enableMaxLength: false,
    maxLength: 10,
  },
  label: {
    type: "label",
    sourceValue: "Example Value",
    sourceKey: "Key",
    sourceType: "static",
    textColor: "black",
    backgroundColor: "#ffffff",
    padding: 5,
    fontSize: 16,
    visibilityByKey: "",
  },
  select: {
    type: "select",
    textColor: "black",
    backgroundColor: "#ffffff",
    fontSize: 16,
    label: "Label",
    width: 300,
    targetKey: "input1",
    visibilityByKey: "",
    items: "",
  },
  general: {
    style: "",
    x: 100,
    y: 100,
  },
  slider: {
    type: "slider",
    width: 300,
    targetKey: "input1",
    visibilityByKey: "",
    min: 0,
    max: 100,
    defaultValue: 50,
    showTextField: true,
    label: "Label",
  },
  image: {
    type: "image",
    width: 300,
    visibilityByKey: "",
    src: "",
  },
  counter: {
    type: "counter",
    visibilityByKey: "",
    showResetButton: true,
    sourceType: "all",
    textColor: "black",
    backgroundColor: "#ffffff",
    fontSize: 16,
    padding: 4,
    autoRestart: "none",
    incrementByContext: "increment",
    label: "",
  },
} as const;

const ElementsList: FC<Props> = ({ items, onChange, activeId, onSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [_] = useI18n();

  const handleAddInput = () => {
    const newModules: ElementInput = {
      ...defaultValues.input,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleAddLabel = () => {
    const newModules: ElementLabel = {
      ...defaultValues.label,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleAddSelect = () => {
    const newModules: ElementSelect = {
      ...defaultValues.select,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleAddSlider = () => {
    const newModules: ElementSlider = {
      ...defaultValues.slider,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleAddImage = () => {
    const newModules: ElementImage = {
      ...defaultValues.image,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleAddCounter = () => {
    const newModules: ElementCounter = {
      ...defaultValues.counter,
      ...defaultValues.general,
      id: Date.now(),
    };
    setAnchorEl(null);
    onChange([...items, newModules]);
  };

  const handleDelete = (itemId: number) => {
    onChange(items.filter((i) => i.id !== itemId));
    if (itemId === activeId) {
      onSelect(items[0]?.id);
    }
  };

  const rootElement = items[0];
  const itemsWithoutRoot = items.slice(1);

  const canDelete = (item: AnyElement) =>
    !["camera", "root"].includes(item.type);

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleAddLabel}>{_("label")}</MenuItem>
        <MenuItem onClick={handleAddInput}>{_("input")}</MenuItem>
        <MenuItem onClick={handleAddSelect}>{_("select")}</MenuItem>
        <MenuItem onClick={handleAddSlider}>{_("slider")}</MenuItem>
        <MenuItem onClick={handleAddImage}>{_("image")}</MenuItem>
        <MenuItem onClick={handleAddCounter}>{_("counter")}</MenuItem>
      </Menu>
      <List>
        <ListItem button dense onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={_("add_element")} />
        </ListItem>
        <ListItem
          dense
          button
          selected={activeId === rootElement.id}
          onClick={() => onSelect(rootElement.id)}
        >
          <ListItemIcon>{getIcon(rootElement)}</ListItemIcon>
          <ListItemText primary={_(rootElement.type)} />
        </ListItem>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            onChange([
              rootElement,
              ...arrayMove(
                itemsWithoutRoot,
                result.source.index,
                result.destination.index
              ),
            ]);
          }}
        >
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshotRoot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {itemsWithoutRoot.map((i, index) => (
                  <Draggable key={i.id} draggableId={i.id + ""} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={
                          snapshotRoot.isDraggingOver ? "classes" : undefined
                        }
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: snapshot.isDragging
                            ? "grey"
                            : "white",
                          opacity: snapshot.isDragging ? 0.8 : 1,
                        }}
                      >
                        <ListItem
                          ContainerProps={{}}
                          dense
                          button
                          selected={activeId === i.id}
                          onClick={() => onSelect(i.id)}
                        >
                          <ListItemIcon>{getIcon(i)}</ListItemIcon>
                          <ListItemText primary={_(i.type)} />
                          {canDelete(i) && (
                            <ListItemSecondaryAction>
                              <IconButton
                                onClick={() => handleDelete(i.id)}
                                edge="end"
                                aria-label="delete"
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </List>
    </div>
  );
};

export default React.memo(ElementsList);
