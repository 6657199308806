import React, { useLayoutEffect } from "react";
import { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Markdown from "markdown-to-jsx";
import { useLocation } from "react-router-dom";
import useI18n from "../../../hooks/useI18n";
import { UnsupervisedModule } from "../../../types/modules";
import getDoc from "../../../docLinks";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
}));

const download = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  fetch("/images.zip")
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "images.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};

const steps = (simpleTutorial: boolean) => [
  {
    label: "tutorial_step_1_label",
    desc: simpleTutorial
      ? "tutorial_step_1_desc_simple_tutorial"
      : "tutorial_step_1_desc",
  },
  {
    label: "tutorial_step_3_label",
    desc: "tutorial_step_3_desc",
  },
  { label: "tutorial_step_4_label" },
  {
    label: "tutorial_step_5_label",
    desc: "tutorial_step_5_desc",
  },
  { label: "tutorial_step_6_label", desc: "tutorial_step_6_desc" },
  {
    label: "tutorial_step_8_label",
    desc: "tutorial_step_8_desc",
  },
  { label: "tutorial_step_9_label", desc: "tutorial_step_9_desc" },
  { label: "tutorial_step_10_label", desc: "tutorial_step_10_desc" },
];

type Props = {
  imagesUploaded: boolean;
  anomalyModule?: UnsupervisedModule;
  anomalyTrainingFinished: boolean;
  trainingLoading: boolean;
  clickStartAnomalyTraining: boolean;
  simpleTutorialOnly: boolean;
  isSelectedImages: boolean;
  closeTutorial: () => void;
};

export default function AnomalyTutorialView(props: Props) {
  const location = useLocation();
  const { pathname, search } = location;
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [_] = useI18n();

  useEffect(() => {
    if (activeStep === 9) {
      // done
      return;
    }
    if (
      pathname.startsWith("/modules/unsupervised") &&
      (pathname.endsWith("/validate/detail") ||
        pathname.endsWith("/validate/detail/evaluation"))
    ) {
      setActiveStep(8);
    } else if (
      pathname.startsWith("/modules/unsupervised") &&
      props.imagesUploaded &&
      ((pathname.endsWith("/training/") && search === "?showTrainingDialog") ||
        props.trainingLoading ||
        props.clickStartAnomalyTraining)
    ) {
      setActiveStep(7);
    } else if (
      props.anomalyModule &&
      props.imagesUploaded &&
      props.anomalyModule.values.classification.ok.length > 8 &&
      !props.anomalyTrainingFinished
    ) {
      setActiveStep(6);
    } else if (
      pathname.startsWith("/modules/unsupervised") &&
      props.imagesUploaded
    ) {
      setActiveStep(5);
    } else if (props.anomalyModule && props.imagesUploaded) {
      setActiveStep(4);
    } else if (
      pathname.startsWith("/modules") &&
      pathname.startsWith("/modules/edit") &&
      props.imagesUploaded
    ) {
      setActiveStep(3);
    } else if (
      pathname.startsWith("/modules") &&
      pathname.startsWith("/modules/list") &&
      props.imagesUploaded
    ) {
      setActiveStep(2);
    } else if (props.imagesUploaded) {
      setActiveStep(1);
    }
  }, [
    props.isSelectedImages,
    pathname,
    search,
    props.anomalyModule,
    activeStep,
    props.imagesUploaded,
    props.anomalyTrainingFinished,
    props.trainingLoading,
    props.clickStartAnomalyTraining,
  ]);

  const tutorialSteps = steps(props.simpleTutorialOnly);

  useLayoutEffect(() => {
    const el = document.getElementById("download_images");
    el?.addEventListener("click", download);
    return () => el?.removeEventListener("click", download);
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {tutorialSteps.map(({ label, desc }) => (
          <Step key={label}>
            <StepLabel>{_(label)}</StepLabel>
            {desc && (
              <StepContent>
                <Typography>
                  <Markdown>{_(desc)}</Markdown>
                </Typography>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
      {activeStep === tutorialSteps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            <Markdown>
              {_(
                props.simpleTutorialOnly
                  ? "tutorial_final_simple_tutorial"
                  : "tutorial_final",
                getDoc()
              )}
            </Markdown>
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.closeTutorial}
            className={classes.button}
          >
            {_("close")}
          </Button>
        </Paper>
      )}
    </div>
  );
}
