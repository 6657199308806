import React, { FC, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import EvaluationDialog from "../../../components/Evaluation/EvaluationDialog";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import SliderWithField from "../../../components/SliderWithField";
import EvaluationButton from "../../../components/EvaluationButton";
import { getNumberDictionary } from "../../../store/utils";
import { PropsFromRedux } from "../ModelDetailContainer";
import { Image, Rectangle } from "../../../types/common";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";
import ChangeModelPanel from "../../../components/ChangeModelPanel";
import ModelListContainer from "../ModelListContainer";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";

const ModelDetailView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const { model } = props;
  const history = useHistory();
  const [showAnnotated, setShowAnnotated] = useState(false);
  const [showEvaluationDialog, setShowEvaluationDialog] = useState(false);

  useEffect(() => {
    checkModel();
    props.getExtraModelData(props.modelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkModel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]); // TODO check model is replace everytime

  const getTrainingRectangles = (currentImage: Image) => {
    const { classNames } = props.model.trainingParams;
    const getLabel = (i: Rectangle) => {
      const currentClassName =
        classNames && classNames.find((k) => k.id === i.className);
      return currentClassName ? currentClassName.label : "";
    };
    const trainingRectangles = props.extraData.imageRectangles.find(
      (i) => i.id === currentImage.id
    );
    return trainingRectangles
      ? trainingRectangles.rectangles.map((i) => ({
          ...i,
          color: "#dddddd",
          label: getLabel(i),
          disabled: true,
        }))
      : [];
  };

  const getInfo = ({ filtered, all }: { filtered: number; all: number }) => {
    const { trainingImages, validateImages } = props.model;
    return {
      title: _("image_list_info_help_rectangles"),
      text: `(${
        trainingImages.length + validateImages.length
      }) / ${filtered} / ${all}`,
    };
  };

  const checkModel = () => {
    if (!props.model) {
      history.replace(`/modules/detector/${props.moduleId}/validate`);
    }
  };

  if (!props.model || !props.extraData) {
    return null;
  }

  const { trainingImages, validateImages } = props.model;

  const evaluateValueTypes = [
    { id: "COUNT", label: _("count") },
    { id: "EDGE_LENGTH", label: _("edge_length") },
  ];

  const trainingImagesDict = getNumberDictionary(trainingImages);
  const validateImagesDict = getNumberDictionary(validateImages);
  return (
    <div>
      <ImageListWithMarkers
        detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
        listHeight={CON_MIN_HEIGHT_HEADER}
        ImageListProps={{
          showSelectedFilter: true,
          getImageItem: (i) => {
            if (trainingImagesDict[i.id]) {
              return { ...i, label2: "T", isTraining: true };
            }
            if (validateImagesDict[i.id]) {
              return { ...i, label2: "V", isValidate: true };
            }
            return i;
          },
        }}
        moduleId={props.moduleId}
        readOnly
        includeLast
        getAddedCurrentRectangles={(i) =>
          showAnnotated ? getTrainingRectangles(i) : []
        }
        getInfo={getInfo}
      >
        <ModuleRightToolPanel>
          <ChangeModelPanel model={props.model}>
            <ModelListContainer moduleId={props.module.id} />
          </ChangeModelPanel>
          <ModuleSettingsWrap title={_("view")}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAnnotated}
                  onChange={(e) => {
                    setShowAnnotated(e.target.checked);
                  }}
                />
              }
              label={_("show_annotations")}
            />
          </ModuleSettingsWrap>
          <ModuleSettingsWrap title={_("settings")} extraTopMargin>
            <SliderWithField
              update
              marginTop
              size="small"
              value={props.model.threshold}
              label={_("confidence_threshold")}
              onChange={(value) => {
                props.detectorSetThreshold({
                  value,
                  modelId: props.modelId,
                });
              }}
              min={1}
              step={0.1}
              max={100}
            />
            <SliderWithField
              update
              size="small"
              marginTop
              value={props.model.iou || 50}
              label={_("IOU")}
              onChange={(value) => {
                props.detectorSetIOU({
                  value,
                  modelId: props.modelId,
                });
              }}
              min={1}
              step={1}
              max={100}
            />
          </ModuleSettingsWrap>
          <ModuleSettingsWrap>
            <EvaluationButton onClick={() => setShowEvaluationDialog(true)} />
          </ModuleSettingsWrap>
        </ModuleRightToolPanel>
      </ImageListWithMarkers>
      <EvaluationDialog
        classNameList={props.model.trainingParams.classNames}
        valueTypes={evaluateValueTypes}
        open={showEvaluationDialog}
        onClose={() => setShowEvaluationDialog(false)}
        onSave={(evaluation) =>
          props.updateEvaluation({
            modelId: props.model.modelId,
            evaluation,
          })
        }
        values={props.model.evaluation || {}}
      />
    </div>
  );
};

export default ModelDetailView;
