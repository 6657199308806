import React, { Component } from "react";
import RefreshIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import TrainingDialog from "../../../components/Augmentation/TrainingDialog";
import ClassificationErrorDialog from "./ClassificationErrorDialog";
import DetectorSettingDialog from "./DetectorSettingDialog";
import DangerSettingsDialog from "../../../components/DangerSettingsDialog";
import ClassNameList from "../../../components/ClassNameList";
import ClassNamesManagerDialog from "../../../components/ClassNamesManagerDialog";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import {
  confirmDangerSettings,
  detectorStartTraining,
  resetModuleValues,
  setVisitedModule,
} from "../../../actions/actions";
import { findDetectedRectangles } from "../../../utils/common";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import ModuleRightToolPanel from "../../../components/ModuleRightToolPanel";
import ButtonWithConfirm from "../../../components/ButtonWithConfirm";
import TrainingButton from "../../../components/TrainingButton";
import DetectorSetting from "./DetectorSettings";
import {
  CON_MIN_HEIGHT_HEADER,
  CON_MIN_HEIGHT_HEADER_PADDING,
} from "../../../theme";
import Button from "@mui/material/Button";
import { getDictionaryById } from "../../../store/utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import classNamesColor from "../../../utils/classNamesColors";
import { PropsFromRedux } from "../TrainingContainer";
import { Image, Rectangle } from "../../../types/common";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const styles = {
  annotationActions: {
    marginTop: 8,
  },
  setAnnotations: {
    marginTop: 8,
  },
  divider: {
    marginTop: 20,
  },
} as const;

type Props = PropsFromRedux & { t: (key: string) => string };

type ImageRectangleKeys = {
  [n: number]: {
    id: number;
    isEmpty: boolean;
    rectangles: Rectangle[];
  };
};

type State = {
  showTrainingDialog: boolean;
  selectedImage: null | Image;
  showClassNamesDialog: boolean;
  selectedRectangle: null | Rectangle;
  lastClassName: undefined | number;
  showSettingsDialog: boolean;
};

class TrainingView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;
  state: State = {
    showTrainingDialog: false,
    selectedImage: null,
    showClassNamesDialog: false,
    selectedRectangle: null,
    lastClassName: undefined,
    showSettingsDialog: false,
  };

  imageList: any;

  constructor(props: Props) {
    super(props);
    this.imageList = React.createRef();
  }

  componentDidMount() {
    if (!this.props.module.visited) {
      this.setState({
        showSettingsDialog: true,
      });
      setVisitedModule(this.props.moduleId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.values.config.HELPER !== this.props.values.config.HELPER) {
      if (this.state.selectedImage) {
        this.handleSelectImage(this.state.selectedImage);
      }
    }
  }

  getSelectedRectangles(
    currentImage: Image,
    imageRectanglesKeys: ImageRectangleKeys
  ) {
    const imageRectanglesItem = imageRectanglesKeys
      ? imageRectanglesKeys[currentImage.id]
      : this.props.values.imageRectangles.find((i) => i.id === currentImage.id);
    return imageRectanglesItem ? imageRectanglesItem.rectangles : [];
  }

  getDetectedRectangles = (currentImage: Image) => {
    const settings = this.context.helper.getSettings({
      moduleId: this.props.moduleId,
      includeLast: this.props.values.config.HELPER,
    });
    const detectedRectangles = findDetectedRectangles({
      detectedRectangles: this.props.detectedRectangles,
      settings,
      imageId: currentImage.id,
    });
    return detectedRectangles ? detectedRectangles.rectangles : null;
  };

  getCurrentRectangles = (currentImage: Image | null) => {
    if (currentImage) {
      const imageRectanglesKeys = getDictionaryById(
        this.props.values.imageRectangles
      );
      return this.getSelectedRectangles(currentImage, imageRectanglesKeys);
    }
    return [];
  };

  getCountRectangles() {
    return this.props.values.imageRectangles.reduce(
      (sum, val) => sum + val.rectangles.length,
      0
    );
  }

  getMarkedImages() {
    return this.props.values.imageRectangles.filter(
      (i) => i.rectangles.length > 0
    ).length;
  }

  getClassNameLabel(classNameId: number) {
    const item = (this.props.values.classNames || []).find(
      (c) => c.id === classNameId
    );
    return item ? item.label : undefined;
  }

  getClassNameColor(classNamesId: number) {
    const colorIndex = (this.props.values.classNames || []).findIndex(
      (i) => i.id === classNamesId
    );
    return colorIndex !== -1 ? classNamesColor[colorIndex] : undefined;
  }

  handleChangeClassName = (className: number | null) => {
    if (this.state.showTrainingDialog) {
      return;
    }

    if (className) {
      this.setState({
        lastClassName: className,
      });
    }
    if (this.state.selectedImage && this.state.selectedRectangle) {
      this.props.detectorSetRectangleClassName({
        moduleId: this.props.moduleId,
        rectangleId: this.state.selectedRectangle.id as number,
        className: className,
        imageId: this.state.selectedImage.id,
      });
    }
  };

  handleSelectImage = (image: Image) => {
    if (image) {
      const settings = this.context.helper.getSettings({
        moduleId: this.props.moduleId,
        includeLast: this.props.values.config.HELPER,
      });
      this.props.getRectangles({ settings, imageId: image.id });
    }
    this.setState({
      selectedImage: image,
      selectedRectangle: null,
    });
  };

  handleChangeRectangles = (imageId: number, rectangles: Rectangle[]) => {
    const newRectangles = rectangles.filter((i) => !i.detected);
    this.props.editImageRectangles(this.props.moduleId, imageId, newRectangles);
  };

  handleStartTraining = (name: string, modelId: null | number) => {
    const { config, imageRectangles } = this.props.values;
    detectorStartTraining({
      name,
      moduleId: this.props.moduleId,
      trainingParams: {
        modelId: modelId !== -1 ? modelId : null,
        classNames: this.props.values.classNames,
        flow: this.context.helper.getSettings({
          moduleId: this.props.moduleId,
        }),
        config,
        imageRectangles: imageRectangles.filter(
          (item) =>
            (item.rectangles.length > 0 || item.isEmpty) &&
            this.props.images.find((i) => i.id === item.id)
        ),
      },
    });
  };

  isFullClassification() {
    return (
      !this.props.values.config.CLASSIFICATION ||
      this.props.values.imageRectangles
        .reduce((all: Rectangle[], image) => {
          all.push(...image.rectangles);
          return all;
        }, [])
        .filter((item) => !item.className).length === 0
    );
  }

  isImageEmpty(currentImage: Image) {
    const item = this.props.values.imageRectangles.find(
      (i) => i.id === currentImage.id
    );
    return item ? item.isEmpty : false;
  }

  isError(currentImage: Image | null) {
    if (!currentImage) return false;
    const settings = this.context.helper.getSettings({
      moduleId: this.props.moduleId,
    });
    const detectedRectangles = findDetectedRectangles({
      detectedRectangles: this.props.detectedRectangles,
      settings,
      imageId: currentImage.id,
    });
    return detectedRectangles ? detectedRectangles.error : false;
  }

  isImageWarning(image: Image, imageRectanglesKeys: ImageRectangleKeys) {
    if (!this.props.values.config.CLASSIFICATION) {
      return false;
    }
    return (
      imageRectanglesKeys[image.id]?.rectangles?.some((r) => !r.className) ??
      false
    );
  }

  enableClassNamesColor() {
    return (
      (this.props.values.classNames || []).length <= classNamesColor.length
    );
  }

  getRectangles = (i: Image) => {
    const enableClassification = this.props.values.config.CLASSIFICATION;
    if (enableClassification) {
      return this.getCurrentRectangles(i).map((r) =>
        r.className
          ? { ...r, label: this.getClassNameLabel(r.className) }
          : { ...r, label: undefined }
          ? {
              ...r,
              label: this.getClassNameLabel(r.className),
              color: undefined,
            }
          : { ...r, label: undefined, color: undefined }
      );
    }
    return this.getCurrentRectangles(i).map((k) => ({
      ...k,
      label: undefined,
      color: undefined,
    }));
  };

  handleSelectDetected = () => {
    if (!this.state.selectedImage) {
      return;
    }
    const rectangles = this.imageList.current.state.rectangles as Rectangle[];
    const imgSize = this.imageList.current.state.imageData.size as {
      width: number;
      height: number;
    };
    const classNames = this.getClassNames();
    const percentRectangles = rectangles
      .filter((i) => i.detected /*&& i.detectedBy === this.props.module.id*/)
      .map((item, index) => {
        const currentClassName =
          classNames &&
          classNames.find((i) => i.label === item.classNames?.[0]?.label);
        const rec = {
          id: Date.now() + index,
          width: item.width / imgSize.width,
          height: item.height / imgSize.height,
          x: item.x / imgSize.width,
          y: item.y / imgSize.height,
          percent: true,
          className: currentClassName?.id,
        };
        return rec;
      });
    const prevRectangles =
      this.props.values.imageRectangles.find(
        (i) => i.id === this.state.selectedImage?.id
      )?.rectangles || [];
    const uniqPrevRectangles = prevRectangles.filter(
      (i) =>
        !percentRectangles.some(
          (j) =>
            j.x === i.x &&
            j.y === i.y &&
            j.width === i.width &&
            j.height === i.height &&
            j.className === i.className
        )
    );
    this.props.editImageRectangles(
      this.props.moduleId,
      this.state.selectedImage.id,
      [...percentRectangles, ...uniqPrevRectangles]
    );
  };

  handleResetImage = () => {
    if (this.state.selectedImage) {
      this.props.editImageRectangles(
        this.props.moduleId,
        this.state.selectedImage.id,
        []
      );
    }
  };

  getClassNames() {
    const enableClassification = this.props.values.config.CLASSIFICATION;

    if (!enableClassification) {
      return null;
    }

    const allImageRectangles = this.props.values.imageRectangles
      .map((i) => i.rectangles)
      .flat();
    return this.props.values.classNames.map((i, index) => ({
      ...i,
      count: allImageRectangles.filter((item) => item.className === i.id)
        .length,
    }));
  }

  getImages = () => {
    const imageRectanglesKeys = getDictionaryById(
      this.props.values.imageRectangles
    );
    return this.props.images.map((i) => ({
      ...i,
      warning: this.isImageWarning(i, imageRectanglesKeys),
      isMarked: this.getSelectedRectangles(i, imageRectanglesKeys).length > 0,
    }));
  };

  render() {
    const _ = this.props.t;
    const enableClassification = this.props.values.config.CLASSIFICATION;
    const showLoader =
      this.state.selectedImage &&
      this.getDetectedRectangles(this.state.selectedImage) === null;

    const classNames = this.getClassNames();

    const currentRectangle =
      this.state.selectedRectangle &&
      this.getCurrentRectangles(this.state.selectedImage).find(
        (i) => i.id === this.state.selectedRectangle?.id
      );

    const enableHelper =
      this.props.values.config.HELPER && !!this.props.module.modelId;

    return (
      <div>
        <ImageListWithMarkers
          ImageDetailProps={{
            maxRatio: true,
            minRectangleSize: true,
            enableRotate: this.props.values.config.DETECT_ROTATE,
            defaultClassName: this.state.lastClassName,
            selectedRectangle: enableClassification
              ? this.state.selectedRectangle
              : null,
            lastRectangleSize: this.props.values.lastRectangle,
            onChangeLastRectangle: (size) => {
              this.props.detectorSetLastRectangle(this.props.moduleId, size);
            },
          }}
          ImageListProps={{
            showSelectedFilter: true,
            images: this.getImages(),
          }}
          ref={this.imageList}
          includeLast={enableHelper}
          showClassification
          detailHeight={CON_MIN_HEIGHT_HEADER_PADDING}
          listHeight={CON_MIN_HEIGHT_HEADER}
          disabledKeyListener={this.state.showTrainingDialog}
          key={enableClassification ? 1 : 0}
          moduleId={this.props.moduleId}
          formatRectangles={(i) => ({
            ...i,
            disabled: true,
            color: "#0000ff",
            detected: true,
          })}
          getCurrentRectangles={enableHelper ? undefined : this.getRectangles}
          getAddedCurrentRectangles={
            enableHelper ? this.getRectangles : undefined
          }
          onChangeRectangles={this.handleChangeRectangles}
          onSelectImage={this.handleSelectImage}
          getInfo={({ filtered, all }) => ({
            title: _("image_list_info_help_rectangles"),
            text: `${this.getCountRectangles()} (${this.getMarkedImages()}) / ${filtered} / ${all}`,
          })}
          showLoader={!!showLoader}
          showError={this.isError(this.state.selectedImage)}
          onSelectRectangle={(selectedRectangle) => {
            if (enableClassification) {
              this.setState({ selectedRectangle });
            }
          }}
        >
          <ModuleRightToolPanel>
            <ModuleSettingsWrap title={_("settings")}>
              <DetectorSetting
                values={this.props.values.config}
                onChange={(values) =>
                  this.props.editConfig(this.props.moduleId, values)
                }
              />
            </ModuleSettingsWrap>
            <ModuleSettingsWrap title={_("annotations")}>
              {enableClassification && (
                <>
                  <ClassNameList
                    t={this.props.t}
                    key={
                      this.state.selectedRectangle &&
                      this.state.selectedRectangle.id
                    }
                    items={classNames || []}
                    value={currentRectangle?.className ?? null}
                    isActive={!!currentRectangle}
                    onChange={this.handleChangeClassName}
                    onOpenEditClassNames={() =>
                      this.setState({ showClassNamesDialog: true })
                    }
                  />
                </>
              )}
              <ButtonWithConfirm
                ButtonProps={{
                  disabled: this.props.values.imageRectangles.length === 0,
                  fullWidth: true,
                  color: "secondary",
                  size: "small",
                  variant: "outlined",
                  style: styles.annotationActions,
                  startIcon: <RefreshIcon />,
                }}
                onClickConfirm={this.handleResetImage}
                confirmMessage={_("reset_image_annotations_confirm")}
                buttonLabel={_("reset_image")}
              />

              <ButtonWithConfirm
                ButtonProps={{
                  disabled: this.props.values.imageRectangles.length === 0,
                  fullWidth: true,
                  size: "small",
                  color: "secondary",
                  variant: "outlined",
                  style: styles.annotationActions,
                  startIcon: <RefreshIcon />,
                }}
                onClickConfirm={() => resetModuleValues(this.props.moduleId)}
                confirmMessage={_("remove_selected_confirm")}
                buttonLabel={_("remove_annotations")}
              />
              <br />
            </ModuleSettingsWrap>
            <ModuleSettingsWrap title={_("auto_annotations")}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!this.props.module.modelId}
                    checked={this.props.values.config.HELPER && enableHelper}
                    onChange={(e) => {
                      this.props.editConfig(this.props.moduleId, {
                        ...this.props.values.config,
                        HELPER: e.target.checked,
                      });
                    }}
                  />
                }
                label={_("active")}
              />
              {enableHelper && (
                <Button
                  fullWidth
                  onClick={this.handleSelectDetected}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<DoneIcon />}
                >
                  {_("set_annotations")}
                </Button>
              )}
            </ModuleSettingsWrap>
            {!this.props.trainingDisabled && (
              <ModuleSettingsWrap>
                <TrainingButton
                  onClick={() => {
                    this.setState({ showTrainingDialog: true });
                  }}
                />
              </ModuleSettingsWrap>
            )}
          </ModuleRightToolPanel>
        </ImageListWithMarkers>

        <ClassNamesManagerDialog
          enableDeleteAnnotations
          items={this.props.values.classNames}
          onClose={() => this.setState({ showClassNamesDialog: false })}
          onUpdate={(classNames, deleteAnnotations, deleteIds) => {
            const classNamesId = classNames.map(({ id }) => id);
            // remove deleted classNames from rectangles
            const rectangles = this.props.values.imageRectangles.map((i) => ({
              ...i,
              rectangles: i.rectangles
                .map((r) => ({
                  ...r,
                  delete:
                    deleteAnnotations &&
                    r.className &&
                    deleteIds.indexOf(r.className) !== -1
                      ? true
                      : undefined,
                  className:
                    classNamesId.indexOf(r.className) !== -1
                      ? r.className
                      : null,
                }))
                .filter((i) => !i.delete),
            }));
            this.props.detectorEditAllImageRectangles({
              moduleId: this.props.moduleId,
              rectangles,
            });
            this.props.detectorSetClassNames({
              moduleId: this.props.moduleId,
              classNames,
            });
            if (
              this.state.lastClassName &&
              classNamesId.indexOf(this.state.lastClassName) === -1
            ) {
              this.setState({
                lastClassName: undefined,
              });
            }
          }}
          open={this.state.showClassNamesDialog}
        />
        <TrainingDialog
          modelsCounter={this.props.modelsCounter}
          hiddenNetworkSize
          open={this.state.showTrainingDialog && this.isFullClassification()}
          onClose={() => this.setState({ showTrainingDialog: false })}
          onStartTraining={this.handleStartTraining}
          models={this.props.models}
          config={this.props.values.config}
          allowFlip
          allowRotate
          allowBrightnessResistance
          allowContrastResistance
          //allowEpochSize
          allowSaturationResistance
          onChangeConfig={(values) => {
            this.props.editConfig(this.props.moduleId, values);
          }}
          errorMessage={
            this.getCountRectangles() === 0 ? _("training_error_1") : undefined
          }
        />
        <DetectorSettingDialog
          open={this.state.showSettingsDialog}
          onClose={() => this.setState({ showSettingsDialog: false })}
          values={this.props.values.config}
          onChange={(values) =>
            this.props.editConfig(this.props.moduleId, values)
          }
        />
        <ClassificationErrorDialog
          open={this.state.showTrainingDialog && !this.isFullClassification()}
          onClose={() => this.setState({ showTrainingDialog: false })}
        />
        <DangerSettingsDialog
          onClose={() => {
            confirmDangerSettings(this.props.moduleId);
          }}
          onRestart={() => {
            resetModuleValues(this.props.moduleId);
          }}
          open={this.props.dangerSettings}
        />
      </div>
    );
  }
}

export default withI18n(TrainingView);
