import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import useI18n from "../hooks/useI18n";

const styles = {
  dialog: {
    width: 400,
  },
  key: {
    borderRadius: "12px",
    backgroundColor: "#555",
    color: "white",
    padding: "0px 10px",
    display: "inline-block",
  },
  keyInfo: {
    margin: "10px 0",
    lineHeight: "24px",
  },
  icon: {
    padding: "5px",
  },
} as const;

type KeyInfoProps = {
  keys: string[];
  result: string;
};

const KeyInfo: FC<KeyInfoProps> = ({ keys, result }) => (
  <div style={styles.keyInfo}>
    {keys.map((label, index) => [
      index > 0 && (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`${index}p`} style={styles.icon}>
          +
        </span>
      ),
      // eslint-disable-next-line react/no-array-index-key
      <span key={index} style={styles.key}>
        {label}
      </span>,
    ])}
    <span style={styles.icon}>=</span>
    <strong>{result}</strong>
  </div>
);

type Props = {
  open: boolean;
  onHide: () => void;
  showClassification?: boolean;
  showBrush?: boolean;
  showUndo?: boolean;
};

const HelperDialog: FC<Props> = ({
  open,
  onHide,
  showClassification,
  showBrush,
  showUndo,
}) => {
  const [_] = useI18n();
  const actions = [
    <Button
      color="primary"
      onClick={(e) => {
        e.stopPropagation();
        onHide();
      }}
    >
      {_("close")}
    </Button>,
  ];

  return (
    <Dialog
      title={_("help")}
      open={open}
      onRequestClose={onHide}
      contentStyle={styles.dialog}
      actions={actions}
    >
      <KeyInfo keys={[_("h")]} result={_("hide_markers")} />
      <KeyInfo keys={[_("mouse_scroll")]} result={_("zoom")} />
      <KeyInfo keys={[_("mouse_right_button")]} result={_("move")} />
      <KeyInfo keys={["↓"]} result={_("next_image")} />
      <KeyInfo keys={["↑"]} result={_("prev_image")} />
      {showClassification && (
        <KeyInfo keys={[`[${_("number")}]`]} result={_("select_class_name")} />
      )}
      {showBrush && <KeyInfo keys={[`e`]} result={_("erase_point")} />}
      {showBrush && <KeyInfo keys={[`l`]} result={_("size_of_brush") + " +"} />}
      {showBrush && <KeyInfo keys={[`k`]} result={_("size_of_brush") + " -"} />}
      {showUndo && <KeyInfo keys={[`ctrl`, _("z")]} result={_("undo")} />}
      <KeyInfo keys={[`r`]} result={_("reset_zoom")} />
    </Dialog>
  );
};

export default HelperDialog;
