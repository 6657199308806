import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import useI18n from "../../hooks/useI18n";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { clearErrorMessages } from "../../store/system/reducer";
import { Alert } from '@mui/material';

const styles = {
  dialog: {
    width: 500,
  },
  item: {
    position: "relative",
    lineHeight: "40px",
  },
} as const;

const ErrorMessages = () => {
  const { items } = useAppSelector((state) => ({
    items: state.system.errorMessages,
  }));

  const [_] = useI18n();
  const dispatch = useAnyAppDispatch();
  return (
    <Dialog
      maxWidth={false}
      PaperProps={{ style: styles.dialog }}
      open={items.length > 0}
    >
      <DialogContent>
        {items.map((i) => (
          <div style={styles.item} key={i.id}>
            <Alert severity="error">{_(i.text)}</Alert>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => dispatch(clearErrorMessages())}>
          {_("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorMessages;
