import React, { FC, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { TextField } from "@mui/material";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "absolute",
    width: 500,
  },
}));

type Props = {
  value: string;
  onChange: (value: string) => void;
  position: {
    x: number;
    y: number;
  };
  label?: string;
  fontSize: number;
  width: number;
  textColor: string;
  backgroundColor: string;
  style?: any;
  disableAbsolutePosition?: boolean;
  maxLength?: number;
};

const Input: FC<Props> = ({
  value,
  onChange,
  position,
  maxLength,
  label,
  style,
  fontSize,
  textColor,
  backgroundColor,
  width,
  disableAbsolutePosition,
}) => {
  const { classes } = useStyles();
  const [localValue, setLocalValue] = useState<string>(value);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const elementStyle = {
    position: "relative",
    left: position.x,
    top: position.y,
    backgroundColor,
    width,
    ...style,
  };

  return (
    <TextField
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        style: {
          fontSize,
          color: textColor,
        },
        maxlength: maxLength,
      }}
      className={!disableAbsolutePosition ? classes.root : undefined}
      onBlur={() => onChange(localValue)}
      style={elementStyle}
      value={localValue}
      label={label}
      onChange={(e) => setLocalValue(e.target.value)}
    />
  );
};

export default Input;
