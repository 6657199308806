import React, { useEffect } from "react";
import { Image } from "react-konva";
import Konva from "konva";
import useImage from "use-image";

type Props = {
  src: string;
  listening?: boolean;
  imageSize?: { width: number; height: number };
  onInit?: () => void;
};

const BackgroundImage = React.forwardRef<Konva.Image, Props>((props, ref) => {
  const [image] = useImage(props.src, "anonymous");

  useEffect(
    () => {
      if (image) {
        props.onInit?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [image]
  );

  return (
    <Image
      image={image}
      ref={ref}
      listening={props.listening}
      width={props.imageSize?.width}
      height={props.imageSize?.height}
    />
  );
});

export default BackgroundImage;
