import { connect, ConnectedProps } from "react-redux";
import ModelDetailView from "./components/ModelDetailView";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { CodeModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string; itemId: string }>
) => {
  const module = getModule(
    state,
    +ownProps.match.params.moduleId
  ) as CodeModule;
  const codeItem = state.database.codeItems.find(
    (c) => c.itemId === +ownProps.match.params.itemId
  );

  return {
    moduleId: +ownProps.match.params.moduleId,
    values: module.values,
    module,
    itemId: codeItem ? codeItem.itemId : null,
    fileId: codeItem ? codeItem.fileId : null,
    history: ownProps.history,
  };
};

const connector = connect(mapStateToProps, {});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelDetailView);
