import React, { FC } from "react";
import { CircularProgress, Alert, Theme } from "@mui/material";
import useCameraDatalogicPreconfig from "../hooks/useCameraDatalogicPreconfig";
import Features from "./Features";
import useI18n from "../../../hooks/useI18n";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  loader: {
    margin: "0 auto",
    display: "block",
  },
}));

type Props = {};

const FeaturesDialog: FC<Props> = () => {
  const { classes } = useStyles();

  const [_] = useI18n();

  const { features, setFeature, callCommand, error } =
    useCameraDatalogicPreconfig();

  return (
    <>
      {error === null &&
        (features !== null ? (
          <Features
            opened
            callCommand={callCommand}
            setFeature={setFeature}
            structure={[{ items: features, name: "Features" }]}
          />
        ) : (
          <CircularProgress className={classes.loader} />
        ))}
      {error !== null && <Alert severity="error">{_(error)}</Alert>}
    </>
  );
};

export default FeaturesDialog;
