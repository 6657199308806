import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { typeToIcon, typeToLabel } from "../../modules";
import { getModule } from "../../utils/common";
import ModuleNotFound from "../ModuleNotFound";
import ModuleWrap from "./ModuleWrap";

const SimpleModuleWrap: FC<{ children: React.ReactNode }> = (props) => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const { module, noImage } = useAppSelector((state) => ({
    module: getModule(state, parseInt(moduleId)),
    noImage: state.database.images.length === 0,
  }));

  if (!module) {
    return <ModuleNotFound />;
  }

  return (
    <ModuleWrap
      title={module.label || typeToLabel(module.type)}
      icon={typeToIcon(module.type)}
      docId={module.type}
      noImage={noImage}
    >
      {props.children}
    </ModuleWrap>
  );
};

export default SimpleModuleWrap;
