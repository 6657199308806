import React, { FC } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from 'tss-react/mui';
import FilterForm from "./FilterForm";
import useI18n from "../../hooks/useI18n";
import { FilterImage, FilterSettings } from "../../utils/sortFilterImages";
import { useAppSelector } from "../../hooks/store";

const useStyles = makeStyles()({
  paper: {
    width: 350,
  },
});

type Props = {
  open: boolean,
  onClose: () => void,
  values: FilterSettings,
  onChangeValues: (values: FilterSettings) => void,
  showSelectedFilter?: boolean,
  showTrainingTypeFilter?: boolean,
  showOkNokFilter?: boolean,
  showStatsFilter?: boolean,
  images: FilterImage[]
}

const FilterDialog:FC<Props> = ({
  open,
  onClose,
  values,
  onChangeValues,
  showSelectedFilter,
  showTrainingTypeFilter,
  showOkNokFilter,
  showStatsFilter,
  images,
}) => {
  
  const { tags } = useAppSelector(state => ({
    tags: state.database.tags || [],
  }))

  const [_] = useI18n()
  const { classes } = useStyles();
  const handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleClickClose}
      open={open}
    >
      <DialogTitle>{_("filter")}</DialogTitle>
      <DialogContent>
        <FilterForm
          images={images}
          showSelectedFilter={showSelectedFilter}
          values={values}
          onChangeValues={onChangeValues}
          showTrainingTypeFilter={showTrainingTypeFilter}
          showOkNokFilter={showOkNokFilter}
          showStatsFilter={showStatsFilter}
          tags={tags}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClickClose}>
          {_("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
