import { connect, ConnectedProps } from "react-redux";
import ModelDetailView from "./components/ModelDetailView";
import { ocrStartThresholdCalc } from "../../actions/actions";
import { getModule } from "../../utils/common";
import { setModelValue } from "../../actions/actions";
import { AnyAppDispatch, GlobalState } from "../../store/createStore";
import { EvaluationRules, OcrModule } from "../../types/modules";
import { RouteComponentProps } from "react-router-dom";
import { Flow } from "../../types/flow";
import { AutoThreholdAnnotations } from "../../components/AutoThresholdDialog";
import { withRequiredModel } from "../../utils/withRequiredModel";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const module = getModule(state, +ownProps.match.params.moduleId) as OcrModule;
  const { modelId } = module;
  return {
    module,
    model: modelId
      ? state.database.ocrModels.find((m) => m.modelId === modelId)
      : undefined,
    noImage: state.database.images.length === 0,
    images: state.database.images,
  };
};

const mapDispatchToProps = (dispatch: AnyAppDispatch) => ({
  ocrStartThresholdCalc: (params: {
    moduleId: number;
    modelId: number;
    flow: Flow;
    classification: AutoThreholdAnnotations;
  }) => dispatch(ocrStartThresholdCalc(params)),
  setThreshold: (params: { modelId: number; value: number }) =>
    dispatch(
      setModelValue("ocrModels", params.modelId, "threshold", params.value)
    ),
  setModelEvaluation: (params: {
    modelId: number;
    evaluation: EvaluationRules;
  }) =>
    dispatch(
      setModelValue(
        "ocrModels",
        params.modelId,
        "evaluation",
        params.evaluation
      )
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRequiredModel(ModelDetailView));
