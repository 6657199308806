import React, { useEffect, useImperativeHandle } from "react";
import { Stage, StageProps } from "react-konva";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import useKeyPress from "./useKeyPress";

import { isRightClick, onWheel } from "./utils";
import { KonvaEventObject } from "konva/lib/Node";

const getScale = (
  wrapSize: { width: number; height: number },
  imageSize: { width: number; height: number }
) => {
  const scaleWidth = Math.min(wrapSize.width / imageSize.width);
  const scaleHeight = Math.min(wrapSize.height / imageSize.height);
  return Math.min(scaleWidth, scaleHeight);
};

const getOffset = (
  wrapSize: { width: number; height: number },
  imageSize: { width: number; height: number },
  scale: number
) => {
  return {
    x: (wrapSize.width - imageSize.width * scale) / 2,
    y: (wrapSize.height - imageSize.height * scale) / 2,
  };
};

type Props = {
  StageProps?: StageProps;
  onChangeScale: (scale: number) => void;
  wrapSize: {
    width: number;
    height: number;
  };
  imageSize: {
    width: number;
    height: number;
  };
  children: React.ReactNode;
  getResetFunction: (func: () => void) => void;
  disableScrollListener?: boolean;
};

const Wrap = React.forwardRef<Konva.Stage, Props>((props, ref) => {
  const scale = getScale(props.wrapSize, props.imageSize);
  const offset = getOffset(props.wrapSize, props.imageSize, scale);
  const [startMove, setStartMove] = React.useState<null | {
    x: number;
    y: number;
    x1: number;
    y1: number;
  }>(null);

  useKeyPress.cb("r", () => handleReset(), [
    scale,
    props.wrapSize,
    props.imageSize,
  ]);

  const handleReset = () => {
    const scale = getScale(props.wrapSize, props.imageSize);
    const offset = getOffset(props.wrapSize, props.imageSize, scale);

    const stage = stageRef.current;
    if (stage) {
      stage.position(offset);
      stage.scale({ x: scale, y: scale });
    }
    props.onChangeScale(scale);
  };

  useEffect(() => {
    props.onChangeScale(scale);
    props.getResetFunction(handleReset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getResetFunction(handleReset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.wrapSize.width,
    props.wrapSize.height,
    props.imageSize.width,
    props.imageSize.height,
  ]);

  const stageRef = React.useRef<Konva.Stage>(null);

  useImperativeHandle(ref, () => stageRef.current as Konva.Stage);

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const rightClick = isRightClick(e);
    if (rightClick) {
      const stage = stageRef.current;
      if (stage) {
        const pointer = stage.getPointerPosition() as Vector2d;
        setStartMove({
          x: pointer.x,
          y: pointer.y,
          x1: stage.attrs.x || 0,
          y1: stage.attrs.y || 0,
        });
      }
    } else {
      props.StageProps?.onMouseDown?.(e);
    }
  };

  const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
    if (startMove) {
      setStartMove(null);
    } else {
      props.StageProps?.onMouseUp?.(e);
    }
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = stageRef.current;
    const rightClick = isRightClick(e);
    if (rightClick && startMove) {
      if (stage) {
        const stage = stageRef.current;
        const pointer = stage.getPointerPosition() as Vector2d;
        stage.position({
          x: startMove.x1 - startMove.x + pointer.x,
          y: startMove.y1 - startMove.y + pointer.y,
        });
      }
    }
    props.StageProps?.onMouseMove?.(e);
  };

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    if (!isRightClick(e)) {
      props.StageProps?.onClick?.(e);
    } else {
      e.evt.stopPropagation();
    }
  };

  return (
    <Stage
      ref={stageRef}
      width={props.wrapSize.width}
      height={props.wrapSize.height}
      style={{ background: "#EEE" }}
      scaleX={scale}
      scaleY={scale}
      {...props.StageProps}
      onWheel={(e) => {
        if (props.disableScrollListener) {
          return;
        }
        const nextScale = onWheel(e, stageRef.current);
        props.onChangeScale(nextScale);
      }}
      onMouseMove={handleMouseMove}
      onContextMenu={(e) => {
        e.evt.preventDefault();
        return false;
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
      {...offset}
    >
      {props.children}
    </Stage>
  );
});

export default Wrap;
