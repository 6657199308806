import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import useI18n from "../../../hooks/useI18n";
import { ListItem, ListItemText } from "@mui/material";

const useStyles = makeStyles()({
  itemText: {
    display: "inline-flex",
    whiteSpace: "nowrap",
    height: 32,
    color: "#ffffff77",
    fontSize: 14,
  },
  number: {
    display: "inline-block",
    width: 55,
    textAlign: "center",
    marginRight: 17,
  },
});

const GpuInfoView = () => {
  const { classes } = useStyles();
  const [gpuInfo, setGpuInfo] = useState<{
    memoryUsed: number;
    memoryTotal: number;
  } | null>(null);
  const [_] = useI18n();

  useEffect(() => {
    (window as any).setGpuInfo = setGpuInfo;
    return () => {
      (window as any).setGpuInfo = null;
    };
  }, []);

  if (!gpuInfo) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemText>
        <div className={classes.itemText}>
          <div className={classes.number}>
            {Math.round((gpuInfo.memoryUsed / gpuInfo.memoryTotal) * 100)}%
          </div>
          <div>{_("gpu_memory")}</div>
        </div>
      </ListItemText>
    </ListItem>
  );
};

export default GpuInfoView;
