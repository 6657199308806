import { connect, ConnectedProps } from "react-redux";
import ModelListView from "./components/ModelListView";
import {
  classifierSetActiveModelId,
  setGpuSettings,
  setModelValue,
  classifierSetName,
} from "../../actions/actions";
import { getModule, modelIsUsed } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { ClassifierModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: { moduleId: number; maxHeight?: boolean }
) => {
  const moduleItems = state.database.modules.items as ClassifierModule[];
  const module = getModule(state, ownProps.moduleId) as ClassifierModule;
  return {
    moduleId: ownProps.moduleId,
    values: module.values,
    module,
    models: state.database.classifierModels.map((i) => ({
      ...i,
      isUsed: modelIsUsed(moduleItems, i),
    })),
    activeModelId: module.modelId,
    gpuDevices: state.system.gpuDevices,
    maxHeight: ownProps.maxHeight,
  };
};

const mapDispatchToProps = {
  classifierSetActiveModelId,
  setGpuSettings,
  setModelValue,
  classifierSetName,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelListView);
