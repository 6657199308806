import React from "react";
import DragIcon from "@mui/icons-material/PermMedia";

const styles = {
  wrap: {
    backgroundColor: "rgba(255,255,255,0.7)",
    outline: "1px dashed black",
    outlineOffset: -10,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1300,
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 150,
  },
} as const;

const DragUploadFrame = () => (
  <div style={styles.wrap}>
    <DragIcon style={styles.icon} />
  </div>
);
export default DragUploadFrame;
