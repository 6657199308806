import React, { FC, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import useI18n from "../../../hooks/useI18n";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Switch,
  Typography,
} from "@mui/material";
import { setMXIAOutputItems, socket } from "../../../actions/actions";

type Props = {
  open: boolean;
  onClose: () => void;
};

const PORTS = Array.from(Array(16).keys()).map((i) => 16 - i);

const DiagnosticsDialog: FC<Props> = (props) => {
  const [_] = useI18n();
  const [activePorts, setActivePorts] = React.useState<number[] | null>();
  useEffect(() => {
    const listener = (data: number[] | null) => {
      setActivePorts(data);
    };

    socket.emit("enter_mxai_outputs", (data: any) => {
      setActivePorts(data);
    });

    socket.on("mxio_diagnostic", listener);

    return () => {
      socket.off("mxio_diagnostic", listener);
      socket.emit("leave_mxai_outputs");
    };
  }, []);

  const handleChangePort = (id: number, active: boolean) => {
    setMXIAOutputItems(id, active);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{_("diagnostic_dialog_title")}</DialogTitle>
      <DialogContent>
        {activePorts === null && (
          <Alert sx={{ mb: 2 }} severity="error">
            {_("connect_error_message")}
          </Alert>
        )}

        <Typography gutterBottom>{_("diagnostic_dialog_header")}</Typography>
        <FormGroup sx={{ display: "block", columnCount: 2 }}>
          {PORTS.map((port) => (
            <FormControlLabel
              sx={{ display: "block" }}
              control={
                <Switch
                  disabled={activePorts === null || activePorts === undefined}
                  checked={activePorts?.includes(port) || false}
                  onChange={(e) => handleChangePort(port, e.target.checked)}
                />
              }
              label={`${_("port")} ${port}`}
            />
          ))}
        </FormGroup>
        {activePorts === undefined && <LinearProgress sx={{ mt: 2 }} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{_("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiagnosticsDialog;
