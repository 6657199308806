import React, { ChangeEvent, FC, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/GetApp";
import ClearIcon from "@mui/icons-material/Clear";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import useCamerasList from "../hooks/useCamersList";
import deepEqual from "fast-deep-equal";
import FeaturesDialog from "./FeaturesDialog";
import { Camera } from "../types";
import useI18n from "../../../hooks/useI18n";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import { Link, TextField } from "@mui/material";
import { Alert } from "@mui/material";
import ButtonWithConfirm from "../../../components/ButtonWithConfirm";
import useCameraFeatures from "../hooks/useCameraFeatures";
import getDoc, { CAMERA_DOC } from "../../../docLinks";
import { useSnackbar } from "notistack";
import BasicFeatures from "./BasicFeatures";

const useStyles = makeStyles()((theme) => ({
  select: {
    width: 253,
  },
  showHelperButton: {
    display: "block",
    marginTop: theme.spacing(1),
    whiteSpace: "nowrap",
  },
}));

const providerItems = [
  { id: "api", label: "API" },
  { id: "basler", label: "Basler / Datalogic" },
  { id: "teli", label: "Toshiba Teli" },
];

type Props = {
  turnOnCamera: () => void;
  turnOffCamera: () => void;
  cameraIsRunning: boolean;
  cameraSetConfigFile: (content: string, filename: string) => void;
  setCurrentCamera: (cameraId: Camera | null) => void;
  setProvider: (providerId: string) => void;
  cameraStatus: string;
  currentCamera: Camera | null;
  provider: string;
  cameraAutoAdjust: () => void;
};

const CameraSettings: FC<Props> = (props) => {
  const snackbar = useSnackbar();
  const { classes } = useStyles();
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileI, setFileI] = useState(0);
  const [showFeatures, setShowFeatures] = useState(false);
  const { cameras, refresh } = useCamerasList();
  const { resetFeatures, getConfigFile } = useCameraFeatures(false);
  const [_] = useI18n();

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFileI(fileI + 1);

    const { files } = e.target;
    if (!files || files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        props.cameraSetConfigFile(event.target.result as string, files[0].name);
      } else {
        snackbar.enqueueSnackbar(_("config_error"));
      }
    };
    reader.readAsText(files[0]);
  };

  const handleChangeProvider = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setProvider(e.target.value);
  };

  const notFound =
    props.currentCamera &&
    !cameras.find((i) => deepEqual(i, props.currentCamera));

  const cameraDisconnected =
    props.cameraStatus === "camera_status_camera_not_found";

  return (
    <>
      <ModuleSettingsWrap title={_("input")}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              className={classes.select}
              label={_("select_provider")}
              value={props.provider}
              onChange={handleChangeProvider}
            >
              {providerItems.map((provider) => (
                <MenuItem key={provider.id} value={provider.id}>
                  {provider.label}
                </MenuItem>
              ))}
            </TextField>
            {props.provider !== "api" && (
              <>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  className={classes.select}
                  margin="normal"
                  size="small"
                  label={_("select_camera")}
                  value={
                    props.currentCamera
                      ? JSON.stringify(props.currentCamera)
                      : 0
                  }
                  onChange={({ target: { value } }) => {
                    props.turnOffCamera();
                    if (typeof value === "string") {
                      props.setCurrentCamera(JSON.parse(value));
                    } else {
                      props.setCurrentCamera(null);
                    }
                  }}
                >
                  <MenuItem value={0}>Not selected</MenuItem>
                  {notFound && props.currentCamera && (
                    <MenuItem
                      disabled
                      value={JSON.stringify(props.currentCamera)}
                    >
                      {props.currentCamera.label}
                    </MenuItem>
                  )}
                  {cameras.map((item) => (
                    <MenuItem key={item.id} value={JSON.stringify(item)}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  startIcon={<RefreshIcon />}
                  variant="outlined"
                  size="small"
                  onClick={refresh}
                >
                  {_("refresh")}
                </Button>
                {cameras.length === 0 && (
                  <Link
                    className={classes.showHelperButton}
                    target="_href"
                    href={getDoc(CAMERA_DOC)}
                    onClick={refresh}
                  >
                    {_("doesnt_see_cam")}
                  </Link>
                )}
              </>
            )}
          </Grid>
          {props.provider !== "api" && (
            <>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      disabled={!props.currentCamera}
                      checked={props.cameraIsRunning}
                      onChange={
                        props.cameraIsRunning
                          ? props.turnOffCamera
                          : props.turnOnCamera
                      }
                    />
                  }
                  label={_("start_stop")}
                />
              </Grid>
              <Grid item xs={12}>
                <Alert variant="outlined" severity="info">
                  {_(props.cameraStatus)}
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </ModuleSettingsWrap>
      {props.currentCamera && (
        <ModuleSettingsWrap title={_("features")}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                disabled={cameraDisconnected}
                size="small"
                fullWidth
                variant="outlined"
                color="primary"
                startIcon={<SettingsIcon />}
                onClick={() => setShowFeatures(true)}
              >
                {_("set")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ButtonWithConfirm
                ButtonProps={{
                  disabled: !!(props.cameraIsRunning || notFound),
                  size: "small",
                  fullWidth: true,
                  variant: "outlined",
                  color: "primary",
                  startIcon: <ClearIcon />,
                }}
                buttonLabel={_("reset")}
                onClickConfirm={() => resetFeatures()}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={!!(props.cameraIsRunning || notFound)}
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                startIcon={<UploadIcon />}
                onClick={() => fileRef?.current?.click?.()}
              >
                {_("upload")}
              </Button>
              <input
                key={fileI}
                ref={fileRef}
                onChange={handleChangeFile}
                type="file"
                style={{ display: "none" }}
                accept=".txt, .json, .pfs"
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={!!(props.cameraIsRunning || notFound)}
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                startIcon={<DownloadIcon />}
                onClick={() => getConfigFile()}
              >
                {_("download")}
              </Button>
            </Grid>
          </Grid>
        </ModuleSettingsWrap>
      )}
      {props.currentCamera &&
        props.provider === "basler" &&
        !cameraDisconnected && (
          <ModuleSettingsWrap extraTopMargin title={_("basic_settings")}>
            <BasicFeatures key={props.cameraStatus} />
          </ModuleSettingsWrap>
        )}
      {showFeatures && (
        <FeaturesDialog onClose={() => setShowFeatures(false)} />
      )}
    </>
  );
};

export default CameraSettings;
