import { Alert, Box } from "@mui/material";
import React from "react";

const PageNotFound = () => (
  <Box sx={{ p: 1 }}>
    <Alert severity="error">404 page not found</Alert>
  </Box>
);

export default PageNotFound;
