import { connect, ConnectedProps } from "react-redux";
import ListView from "./components/ListView";
import {
  addTutorialImages,
  imagesUpload,
  setFilter,
} from "../../actions/actions";
import {
  deleteImage as setDeleteImages,
  setRename as setRenameImage,
  setShowTagsManager,
  setTagsImages,
} from "../../store/images/reducer";
import { GlobalState } from "../../store/createStore";
import { FilterSettings, getFilter } from "../../utils/sortFilterImages";

const IMAGE_FILTER = "IMAGE_FILTER";

const mapStateToProps = (state: GlobalState) => {
  return {
    images: state.database.images,
    filter: getFilter(state.database.modules.filter, IMAGE_FILTER),
    simpleTutorialOnly: state.system.simpleTutorialOnly,
    language: state.system.lang, // refresh language,
    tags: state.database.tags || [],
  };
};

const mapDispatchToProps = {
  imagesUpload,
  setFilter: (settings: FilterSettings) => setFilter(IMAGE_FILTER, settings),
  setTagsImage: (imageIds: number[]) => setTagsImages({ images: imageIds }),
  addTutorialImages,
  setRenameImage,
  setDeleteImages,
  setShowTagsManager,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ListView);
