import React, { Component } from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import FileUploadIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import TagIcon from "@mui/icons-material/Style";
import IconButton from "@mui/material/IconButton";
import Header from "./Header";
import ImagesGridFilter from "../../../components/ImagesGridFilter";
import ModuleWrap from "../../../components/templates/ModuleWrap";
import uniq from "lodash/uniq";
import * as sortFilterImages from "../../../utils/sortFilterImages";
import { Image } from "../../../types/common";
import {
  CONTENT_INNER_PADDING,
  CON_MIN_HEIGHT_HEADER_TOOLPANEL_PADDING,
  PRIMARY_COLOR,
} from "../../../theme";
import { getDictionaryById } from "../../../store/utils";
import { IMAGES_DOC } from "../../../docLinks";
import { PropsFromRedux } from "../ListContainer";
import withI18n from "../../../utils/withI18n";
import { Backdrop, Fab, Tooltip } from "@mui/material";
import Tags from "../../../components/Tags";
import ImageGallery from "react-image-gallery";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";

const IMAGE_HEIGHT = 200;
const PAGE_IMAGES_COUNT = 500;

const styles = {
  wrap: {
    padding: `0 ${CONTENT_INNER_PADDING}px`,
    marginLeft: -5,
    marginRight: -5,
    userSelect: "none",
    height: `calc(${CON_MIN_HEIGHT_HEADER_TOOLPANEL_PADDING} - 44px)`,
    width: "100%",
    overflow: "auto",
  },
  imageWrap: {
    display: "inline-block",
    height: IMAGE_HEIGHT,
    border: "1px solid",
    borderColor: "rgba(39, 50, 56, 0.18)",
    background: "rgb(238, 239, 239)",
    padding: 2,
    position: "relative",
    margin: 5,
    marginTop: 0,
  },
  selectedImageWrap: {
    boxShadow: `inset 0px 0px 0px 2px ${PRIMARY_COLOR}`,
    borderColor: PRIMARY_COLOR,
  },
  image: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "calc(100% - 35px)",
  },
  imageLabel: {
    position: "absolute",
    bottom: 5,
    left: 5,
    width: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tags: {
    position: "absolute",
    top: 5,
    right: 5,
    width: "calc(100% - 10px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 11,
    lineHeight: "16px",
  },
  deleteButton: {
    position: "absolute",
    right: 2,
    top: 2,
    backgroundColor: "rgb(238, 239, 239)",
    borderRadius: 0,
  },
  editButton: {
    position: "absolute",
    right: 2,
    top: 49,
    backgroundColor: "rgb(238, 239, 239)",
    borderRadius: 0,
  },
  lightBoxButton: {
    position: "absolute",
    right: 2,
    top: 96,
    backgroundColor: "rgb(238, 239, 239)",
    borderRadius: 0,
  },
  tagsButton: {
    position: "absolute",
    right: 2,
    top: 143,
    backgroundColor: "rgb(238, 239, 239)",
    borderRadius: 0,
  },
  noImageWrap: {
    height: "100%",
    display: "grid",
    padding: 10,
    cursor: "pointer",
  },
  noImageContent: {
    margin: "auto",
    textAlign: "center",
  },
  noImageIcon: {
    width: 200,
    height: 200,
  },
  lazyLoad: {
    // position: "absolute",
    width: "calc(100%)",
    height: "calc(100%)",
  },
  closeButton: {
    position: "absolute",
    top: -20,
    right: -20,
  },
} as const;

type State = {
  hoverImage: null | number;
  selectedImages: number[];
  lightboxIsOpen: boolean;
  currentImage: number;
  currentPage: number;
  images: sortFilterImages.FilterImage[];
  showTags: boolean;
};

type Props = PropsFromRedux & { t: (key: string) => string };

class ListView extends Component<Props, State> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  constructor(props: Props) {
    super(props);
    const filterImages = sortFilterImages.filter(props.images, {
      text: this.props.filter.text,
    });
    const sortedImages = sortFilterImages.sort(
      filterImages,
      this.props.filter.sort
    );
    this.state = {
      hoverImage: null,
      selectedImages: [],
      lightboxIsOpen: false,
      currentImage: 0,
      currentPage: 0,
      images: sortedImages,
      showTags: false,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    document.addEventListener("keydown", this.escFunction);
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.filter !== this.props.filter ||
      this.props.images !== prevProps.images
    ) {
      let { images } = this.props;
      if (
        prevProps.filter.text !== this.props.filter.text ||
        this.props.images !== prevProps.images
      ) {
        images = sortFilterImages.filter(images, {
          text: this.props.filter.text,
        });
      }
      if (
        prevProps.filter.sort !== this.props.filter.sort ||
        this.props.images !== prevProps.images
      ) {
        images = sortFilterImages.sort(images, this.props.filter.sort);
      }
      const imageIds = getDictionaryById(this.props.images);
      this.setState((state) => ({
        images,
        selectedImages:
          prevProps.filter.text === this.props.filter.text
            ? state.selectedImages.filter((i) => imageIds[i])
            : [],
        currentPage:
          prevProps.filter !== this.props.filter
            ? 0
            : images.length / PAGE_IMAGES_COUNT < state.currentPage
            ? Math.ceil(state.currentPage / PAGE_IMAGES_COUNT)
            : state.currentPage,
      }));
    }
    if (prevProps.images !== this.props.images) {
    }
  }

  escFunction = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      this.setState({
        lightboxIsOpen: false,
      });
      //Do whatever when esc is pressed
    }
  };

  getItemStyle(image: Image) {
    const isSelected = this.state.selectedImages.indexOf(image.id) !== -1;
    const wrapWidth = window.innerWidth - 255 + 80;
    const cols = Math.floor(wrapWidth / 250);
    return {
      width: `calc(100% / ${cols} - 10px)`,
      ...styles.imageWrap,
      ...(isSelected ? styles.selectedImageWrap : {}),
    };
  }

  handleResize = () => forceCheck();

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  gotoImage = (index: number) => {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  selectFiles = (files: File[] | FileList) => {
    this.props.imagesUpload(files);
  };

  handleDelete = (ids = this.state.selectedImages) => {
    this.props.setDeleteImages(ids);
  };

  handleClickUpload = () => {
    if (!this.props.simpleTutorialOnly) {
      document.getElementById("image_gallery_input")?.click();
    } else {
      this.props.addTutorialImages();
    }
  };

  render() {
    const imagePageItems = [
      ...this.state.images.slice(
        this.state.currentPage * PAGE_IMAGES_COUNT,
        (1 + this.state.currentPage) * PAGE_IMAGES_COUNT
      ),
    ];
    const selectedImages = this.state.selectedImages;
    const _ = this.props.t;

    return (
      <>
        <ModuleWrap title={_("image_library")} docId={IMAGES_DOC}>
          <Header
            onSelectFiles={this.selectFiles}
            onOpenTags={() => this.props.setShowTagsManager(true)}
            onDelete={this.handleDelete}
            onCancelSelect={() => this.setState({ selectedImages: [] })}
            onClickAddDemo={this.props.addTutorialImages}
            demoImages={this.context.helper.simpleTutorialOnly}
            onSelectAll={() => {
              if (selectedImages.length === this.state.images.length) {
                this.setState({ selectedImages: [] });
              } else {
                this.setState({
                  selectedImages: this.state.images.map(({ id }) => id),
                });
              }
            }}
            disableAddImage={
              this.props.simpleTutorialOnly && this.props.images.length > 0
            }
            selectedCount={selectedImages.length}
          />
          {this.props.images.length > 0 && (
            <ImagesGridFilter
              values={this.props.filter}
              onChange={(filter) => {
                this.props.setFilter(filter);
              }}
              pagination={{
                currentPage: this.state.currentPage,
                pages: Math.ceil(this.state.images.length / PAGE_IMAGES_COUNT),
                onChangePage: (currentPage) => this.setState({ currentPage }),
              }}
            />
          )}
          <div style={styles.wrap} onScroll={forceCheck}>
            {this.props.images.length === 0 && (
              // eslint-disable-next-line
              <div style={styles.noImageWrap} onClick={this.handleClickUpload}>
                <div style={styles.noImageContent}>
                  <FileUploadIcon style={styles.noImageIcon} />
                  <br />
                  {_("upload_image_first")}
                </div>
              </div>
            )}
            {imagePageItems.map((image, index) => (
              // eslint-disable-next-line
              <div
                style={this.getItemStyle(image)}
                key={image.id + "-" + index}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  if (this.state.hoverImage !== image.id) {
                    this.setState({
                      hoverImage: image.id,
                    });
                  }
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  this.setState({
                    hoverImage: null,
                  });
                }}
                onDoubleClick={() => {
                  this.gotoImage(index);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (selectedImages.indexOf(image.id) === -1) {
                    const prevId = selectedImages[selectedImages.length - 1];
                    let startIndex = imagePageItems.findIndex(
                      (i) => i.id === prevId
                    );

                    if (
                      !e.shiftKey ||
                      imagePageItems.length === 0 ||
                      startIndex === -1
                    ) {
                      this.setState({
                        selectedImages: [...selectedImages, image.id],
                      });
                    } else {
                      let endIndex = index;

                      if (startIndex > endIndex) {
                        [endIndex, startIndex] = [startIndex, endIndex];
                      }

                      const newSelectedImages = imagePageItems
                        .slice(startIndex, endIndex + 1)
                        .map(({ id }) => id);
                      // .filter((i) => imagePageItems.indexOf(i) === -1);

                      this.setState({
                        selectedImages: uniq([
                          ...selectedImages,
                          ...newSelectedImages,
                        ]),
                      });
                    }
                  } else {
                    this.setState({
                      selectedImages: selectedImages.filter(
                        (i) => i !== image.id
                      ),
                    });
                  }
                }}
              >
                <LazyLoad
                  style={styles.lazyLoad}
                  height={IMAGE_HEIGHT}
                  throttle={500}
                  key={image.id}
                >
                  <div
                    style={{
                      backgroundImage: `url(${this.context.helper.getRawImagePath(
                        image.id,
                        true
                      )})`,
                      ...styles.image,
                    }}
                  />
                </LazyLoad>
                <div style={styles.tags}>
                  <Tags tagsIds={image.tags ?? []} />
                </div>
                <div style={styles.imageLabel} title={image.label}>
                  {image.label}
                </div>
                {this.state.hoverImage === image.id && (
                  <>
                    {!this.context.helper.simpleTutorialOnly && (
                      <Tooltip title={_("delete_image")}>
                        <IconButton
                          style={styles.deleteButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleDelete([image.id]);
                          }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={_("edit_name")}>
                      <IconButton
                        style={styles.editButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.setRenameImage(image.id);
                        }}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={_("tags")}>
                      <IconButton
                        style={styles.tagsButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (this.state.selectedImages.includes(image.id)) {
                            this.props.setTagsImage(this.state.selectedImages);
                          } else {
                            this.setState({
                              selectedImages: [image.id],
                            });
                            this.props.setTagsImage([image.id]);
                          }
                        }}
                        size="large"
                      >
                        <TagIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={_("preview")}>
                      <IconButton
                        style={styles.lightBoxButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.gotoImage(index);
                        }}
                        size="large"
                      >
                        <FullScreenIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            ))}
          </div>
        </ModuleWrap>
        <Backdrop style={{ zIndex: 1300 }} open={this.state.lightboxIsOpen}>
          <div style={{ position: "relative" }}>
            <ImageGallery
              startIndex={this.state.currentImage}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              lazyLoad
              items={imagePageItems.map((i) => ({
                original: this.context.helper.getRawImagePath(i.id),
                thumbnail: this.context.helper.getRawImagePath(i.id),
                description: i.label,
              }))}
            />
            <Fab
              size="small"
              style={styles.closeButton}
              color="primary"
              onClick={() => this.setState({ lightboxIsOpen: false })}
            >
              <CloseIcon />
            </Fab>
          </div>
        </Backdrop>
      </>
    );
  }
}

export default withI18n(ListView);
