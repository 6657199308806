import { FC, useEffect } from "react";
import useImageSize from "../hooks/useImageSize";
import { useResizeDetector } from "react-resize-detector";
import { Line, Rectangle } from "../types/common";
import React from "react";
import Dashbord, {
  LineT,
  RectangleT,
} from "./ImageListWithMarkers/Dashboard/Objects/Dashboard";
import { getRectangleText, percentToPixels } from "../utils/format";
import { middleToCorner } from "../utils/geometry";

type Props = {
  imageSrc: string;
  rectangles: Rectangle[];
  lines: Line[];
  ocr: any[];
  onReciveSize?: (size: { width: number; height: number }) => void;
};

const ImageWithRectangleDinamic: FC<Props> = (props) => {
  const imageSize = useImageSize(props.imageSrc);
  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (imageSize) {
      props.onReciveSize?.(imageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSize]);

  if (!imageSize) {
    return null;
  }

  const getItems = (imageSize: {
    width: number;
    height: number;
  }): (RectangleT | LineT)[] => {
    const scale = 1; //imageSize.width / data.width
    const rectangles = [...props.rectangles, ...props.ocr].map((_item) => {
      const item = middleToCorner(percentToPixels(_item, imageSize));

      return {
        ...item,
        width: scale * item.width,
        height: scale * item.height,
        x: scale * item.x,
        y: scale * item.y,
        id: scale * (item.id ?? 0),
        text: getRectangleText(item),
        disabled: true,
      };
    });
    const lines = props.lines.map((item) => ({
      id: item.id,
      start: {
        x: item.start.x * scale,
        y: item.start.y * scale,
      },
      end: {
        x: item.end.x * scale,
        y: item.end.y * scale,
      },
      text: item.length + "",
      disabled: true,
    }));
    return [...rectangles, ...lines];
  };

  return (
    <div style={{ height: "100%" }} ref={ref}>
      {width && height && (
        <Dashbord
          items={getItems(imageSize)}
          width={width}
          height={height}
          imageUrl={props.imageSrc}
          imageSize={imageSize}
          getResetFunction={() => {}}
          onEdit={() => {}}
          onDelete={() => {}}
          onCreate={() => {}}
        />
      )}
    </div>
  );
};

export default ImageWithRectangleDinamic;
