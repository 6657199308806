import {useEffect, useState} from "react";
import { socket } from "../../../actions/actions";

const useLiveContext = () => {
    const [ context, setContext ] = useState<any>({})


    useEffect(() => {
        const listener = (data: any) => {
            setContext(data)
        }
        // socket.join("liveContext")
        socket.on("liveContext", listener)

        return () => {
            // socket.leave("liveContext")
            socket.off("liveContext", listener)
        }
    }, [])

    return context
}

export default useLiveContext
