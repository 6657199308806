import React, { FC, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import ImageList from "./ImageList";
import { Props as ImageListProps } from "./ImageList";
import {
  FilterImage,
  FilterSettings,
  getFilter,
} from "../../utils/sortFilterImages";
import NoImage from "./NoImage";
import { setFilter } from "../../actions/actions";
import {
  deleteImage as setDeleteImages,
  setRename as setRenameImage,
  setTagsImages,
} from "../../store/images/reducer";
import { GlobalState } from "../../store/createStore";
import { Image, ImageSize } from "../../types/common";
import { getImageSizeFromSrc } from "../../actions/ImageService";
import { HelperContext } from "../../layouts/PageLayout/PageLayout";
import { GetConfigParams } from "../../utils/common";

export type Props = Omit<
  ImageListProps,
  "filter" | "ref" | "t" | "items" | "setFilter" | "imgSize"
> & {
  setFilter: (filterId: string, values: FilterSettings) => void;
  imageListSettings: FilterSettings[];
  getImageItem?: (image: Image) => FilterImage;
  images: Image[];
  enableEmptyImages?: boolean;
  imgSize?: null | ImageSize;
};

const ImageListContainer: FC<Props> = (props) => {
  const context = useContext(HelperContext);
  const [imageSize, setImageSize] = useState<{
    size: ImageSize;
    url: string;
  }>();

  const getFirstImageSrc = () => {
    const { pathname } = document.location;
    const filterId = pathname;
    const { images, imageListSettings } = props;
    const filter = getFilter(imageListSettings, filterId);
    if (images.length > 0) {
      if (filter && filter.processed === false) {
        return context.helper.getRawImagePath(images[0].id) as string;
      }
      return context.helper.getImagePath(
        props.getImageSrc(images[0]) as GetConfigParams
      ) as string;
    }
    return null;
  };

  const firstImageSrc = getFirstImageSrc();

  useEffect(() => {
    if (!firstImageSrc) {
      setImageSize({
        size: {
          width: 500,
          height: 500,
        },
        url: "",
      });
      return;
    }

    if (props.imgSize === undefined) {
      getImageSizeFromSrc(firstImageSrc)
        .then((imageSize) => {
          setImageSize({
            size: imageSize,
            url: firstImageSrc,
          });
        })
        .catch(() => {
          setImageSize({
            size: {
              width: 500,
              height: 500,
            },
            url: firstImageSrc,
          });
        });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.imgSize && (!imageSize || imageSize.url !== firstImageSrc)) {
      setImageSize({ size: props.imgSize, url: firstImageSrc || "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imgSize, firstImageSrc]);

  const {
    setFilter,
    imageListSettings,
    enableEmptyImages,
    imgSize,
    ...otherProps
  } = props;
  const { pathname } = document.location;
  const filterId = pathname;

  if (props.images.length === 0 && !enableEmptyImages) {
    return <NoImage height={props.height}>{props.children}</NoImage>;
  }

  return (
    <ImageList
      filter={getFilter(imageListSettings, filterId)}
      setFilter={(settings: FilterSettings) => setFilter(filterId, settings)}
      {...otherProps}
      imgSize={imageSize?.size || props.imgSize || { width: 0, height: 0 }}
      selectMore
      items={
        // eslint-disable-next-line no-nested-ternary
        props.getImageItem ? props.images.map(props.getImageItem) : props.images
      }
    />
  );
};

type OwnProps = {
  images?: Image[];
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  return {
    images: ownProps.images || state.database.images,
    enableEmptyImages: !!ownProps.images,
    imageListSettings: state.database.modules.filter,
  };
};

export default connect(mapStateToProps, {
  setFilter,
  setDeleteImages,
  setRenameImage,
  setTagsImages,
})(ImageListContainer);
