import React, { PureComponent } from "react";
import ImageList from "../ImageList/ImageListContainer";
import Header from "./EditorHeader";
import { GetImageParams, Image } from "../../types/common";

import "brace/mode/python";
import "brace/theme/github";

import { CON_MIN_HEIGHT_HEADER_TOOLPANEL } from "../../theme";

type Props = {
  moduleId: number;
  name: string;
  onSave: () => void;
  onClickBack: () => void;
  onChangeName: (name: string) => void;
  isChange?: boolean;
  onSelectImage?: (item: Image, one: boolean) => void;
  menuItems: React.ReactNode;
  activeId?: number;
  getImageSrc2?: (image: Image) => GetImageParams;
  children: React.ReactNode;
};

export default class Editor extends PureComponent<Props> {
  render() {
    return (
      <>
        <Header
          onSave={this.props.onSave}
          onChangeName={this.props.onChangeName}
          name={this.props.name}
          isChange={this.props.isChange}
          onClickBack={this.props.onClickBack}
          menuItems={this.props.menuItems}
        />
        <ImageList
          activeId={this.props.activeId}
          onSelect={this.props.onSelectImage}
          height={CON_MIN_HEIGHT_HEADER_TOOLPANEL}
          getImageSrc={(image) => ({
            imageId: image.id,
            moduleId: this.props.moduleId,
          })}
          getImageSrc2={this.props.getImageSrc2}
        >
          {this.props.children}
        </ImageList>
      </>
    );
  }
}
