import { connect, ConnectedProps } from "react-redux";
import ModelListView from "./components/ModelListView";
import {
  detectorSetActiveModelId,
  setGpuSettings,
  detectorSetName,
} from "../../actions/actions";
import { getModule, modelIsUsed } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { DetectorModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: { moduleId: number; maxHeight?: boolean }
) => {
  const moduleItems = state.database.modules.items as DetectorModule[];
  const moduleId = ownProps.moduleId;
  const module = getModule(state, moduleId) as DetectorModule;

  return {
    moduleId,
    values: module.values,
    module,
    models: state.database.detectorModels.map((i) => ({
      ...i,
      isUsed: modelIsUsed(moduleItems, i),
    })),
    activeModelId: module.modelId,
    gpuDevices: state.system.gpuDevices,
    maxHeight: ownProps.maxHeight,
  };
};

const mapDispatchToProps = {
  detectorSetActiveModelId,
  setGpuSettings,
  detectorSetName,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelListView);
