import React, { Component, FC } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { unifierUpdateValues } from "../../../actions/actions";
import Editor from "../../../components/templates/Editor";
import ImagePreview from "./ImagePreview";
import SliderWithField from "../../../components/SliderWithField";
import { SERVER_IMAGE_URL } from "../../../config";
import {
  PageToolbarButton,
  PageToolbarSpace,
} from "../../../components/PageToolbar";
import ModuleSettingsWrap from "../../../components/ModuleSettingsWrap";
import withI18n from "../../../utils/withI18n";
import { PropsFromRedux } from "../TraningContainer";
import { UnifierItem } from "../../../types/modules";
import { ImageSize } from "../../../types/common";
import NavigationPromp from "../../../components/NavigationPromp";

type FormProps = {
  values: UnifierItem["values"];
  onChange: (values: UnifierItem["values"]) => void;
  imageSize: ImageSize;
};

const Form: FC<FormProps> = (props) => {
  const diagonal = Math.floor(
    Math.sqrt(
      // eslint-disable-next-line no-restricted-properties
      Math.pow(props.imageSize.width, 2) + Math.pow(props.imageSize.height, 2)
    )
  );
  const halfDiagonal = Math.floor(diagonal / 2);
  const onChange = (name: string) => (value: number) =>
    props.onChange({ ...props.values, [name]: value });
  return (
    <div>
      <SliderWithField
        marginTop
        min={-180}
        step={1}
        max={180}
        value={props.values.angle}
        onChange={onChange("angle")}
        label="angle"
      />
      <SliderWithField
        marginTop
        min={50}
        step={1}
        max={diagonal}
        value={props.values.width}
        onChange={onChange("width")}
        label="width"
      />
      <SliderWithField
        marginTop
        min={50}
        step={1}
        max={diagonal}
        value={props.values.height}
        onChange={onChange("height")}
        label="height"
      />
      <SliderWithField
        marginTop
        min={-halfDiagonal}
        step={1}
        max={halfDiagonal}
        value={props.values.offsetX}
        onChange={onChange("offsetX")}
        label="offset-x"
      />
      <SliderWithField
        marginTop
        min={-halfDiagonal}
        step={1}
        max={halfDiagonal}
        value={props.values.offsetY}
        onChange={onChange("offsetY")}
        label="offset-y"
      />
    </div>
  );
};

const styles = {
  checkbox: {
    marginTop: 10,
  },
  classNamesLabel: {
    fontSize: 16,
    marginTop: 22,
    display: "block",
  },
};

type Props = PropsFromRedux & {
  t: (key: string) => string;
};

type State = {
  values: UnifierItem["values"] | null;
};

class UnifierView extends Component<Props, State> {
  state = {
    values: this.props.template ? this.props.template.values : null,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.template.values && !state.values) {
      return {
        values: props.template.values,
      };
    }
    return null;
  }

  isChanged = () =>
    JSON.stringify(this.props.template.values) !==
    JSON.stringify(this.state.values);

  handleUpdate = () => {
    unifierUpdateValues({
      itemId: this.props.template.itemId,
      values: this.state.values,
    });
  };

  render() {
    if (!this.props.template || this.state.values === null) {
      return null;
    }
    const _ = this.props.t;
    const imagePath = `${SERVER_IMAGE_URL}/unifier/${this.props.template.itemId}.jpg?token=${this.props.token}`;
    const { selectedRectangles } = this.state.values;
    return (
      <>
        <NavigationPromp
          when={this.isChanged()}
          message={_("changes_not_save")}
        />

        <Editor
          onClickBack={() =>
            this.props.history.push(`/modules/unifier/${this.props.module.id}/`)
          }
          onChangeName={(name) =>
            this.props.unifierUpdateName({
              itemId: this.props.template.itemId,
              name,
            })
          }
          onSave={this.handleUpdate}
          isChange={this.isChanged()}
          moduleId={this.props.module.id}
          name={this.props.template.name}
          getImageSrc2={(image) => ({
            imageId: image.id,
            moduleId: this.props.module.id,
            otherModule: {
              id: this.props.module.id,
              type: "UNIFIER",
              itemId: this.props.template.itemId,
              editDate: this.props.template.editDate,
            },
          })}
          menuItems={[
            <PageToolbarSpace key="1" />,
            <PageToolbarButton
              key="2"
              rightAllign
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(
                  `/modules/unifier/${this.props.module.id}/${this.props.template.itemId}/images/`
                );
              }}
              color="secondary"
              label={_("preview")}
            />,
          ]}
        >
          <div style={{ height: "100%" }}>
            <div
              style={{
                float: "left",
                width: `calc(100% - 300px)`,
                height: "100%",
              }}
            >
              <ImagePreview
                imageSize={this.props.template.imageSize}
                imgSrc={imagePath}
                pointers={this.props.template.rectangles.filter(
                  (i) => selectedRectangles.indexOf(i.id) !== -1
                )}
                values={this.state.values}
              />
            </div>
            <div style={{ width: 300, float: "left", padding: "0 10px" }}>
              <ModuleSettingsWrap title={_("settings")}>
                <Form
                  values={this.state.values}
                  imageSize={this.props.template.imageSize}
                  onChange={(values) => {
                    this.setState({ values });
                  }}
                />
              </ModuleSettingsWrap>
              <ModuleSettingsWrap title={_("active_classnames")}>
                {this.state.values &&
                  this.props.template.rectangles.map((r) => (
                    <FormControlLabel
                      style={styles.checkbox}
                      key={r.id}
                      control={
                        <Checkbox
                          checked={
                            this.state.values?.selectedRectangles.indexOf(
                              r.id
                            ) !== -1
                          }
                          onChange={(e) => {
                            if (!this.state.values) {
                              return;
                            }
                            if (e.target.checked) {
                              this.setState({
                                values: {
                                  ...this.state.values,
                                  selectedRectangles: [
                                    ...selectedRectangles,
                                    r.id,
                                  ],
                                },
                              });
                            } else {
                              this.setState({
                                values: {
                                  ...this.state.values,
                                  selectedRectangles: selectedRectangles.filter(
                                    (i) => i !== r.id
                                  ),
                                },
                              });
                            }
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={r.id}
                    />
                  ))}
              </ModuleSettingsWrap>
            </div>
          </div>
        </Editor>
      </>
    );
  }
}

export default withI18n(UnifierView);
