import React, { FC, MouseEvent, useEffect } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import useI18n from "../hooks/useI18n";

const styles = {
  dialog: {
    width: 400,
  },
};

type Props = {
  open: boolean;
  message?: string;
  onClickYes: () => void;
  onClickNo: () => void;
  title?: string;
};

const Confirm: FC<Props> = ({
  open,
  onClickYes,
  onClickNo,
  message,
  title,
}) => {
  const [_] = useI18n();
  useEffect(() => {
    // stop all keydown events when dialog is open
    const handleKeyUp = (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key === "Escape") onClickNo();
      else if (event.key === "Enter") onClickYes();
    };

    if (open) {
      document.addEventListener("keyup", handleKeyUp);
    } else {
      document.removeEventListener("keyup", handleKeyUp);
    }

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [open, onClickYes, onClickNo]);

  const handleClickYes = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickYes();
  };

  const handleClickNo = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickNo();
  };

  const actions = [
    <Button color="primary" onClick={handleClickNo}>
      {_("cancel")}
    </Button>,
    <Button color="primary" onClick={handleClickYes}>
      {_("confirm")}
    </Button>,
  ];

  return (
    <Dialog
      actions={actions}
      title={title ?? _("are_you_sure")}
      open={open}
      contentStyle={styles.dialog}
    >
      {message && <DialogContentText>{message}</DialogContentText>}
    </Dialog>
  );
};

export default Confirm;
