import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import database from "./database/reducer";
import system from "./system/reducer";
import images from "./images/reducer";
import paint from "./paint/reducer";

const store = configureStore({
  reducer: {
    system,
    images,
    paint,
    database,
  },
});

export type GlobalState = ReturnType<typeof store.getState>;

export type GetState = () => GlobalState;

export type AppDispatch = typeof store.dispatch;

export type AnyAppDispatch = ThunkDispatch<GlobalState, undefined, AnyAction>;

export default store;
