import React, { FC } from "react";
import { useDebouncedCallback } from "use-debounce";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type Props = {
  onChange: (value: string) => void;
  debounce?: number;
};

const TextFieldWithDebounce: FC<Props & Omit<TextFieldProps, "onChange">> = ({
  onChange,
  value,
  debounce = 1000,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [debouncedCallback, cancel, callPending] = useDebouncedCallback(
    // function
    (e: string) => {
      onChange?.(e);
    },
    // delay in ms
    1000
  );
  return (
    <TextField
      onBlur={() => callPending()}
      defaultValue={value}
      onChange={(e) => debouncedCallback(e.target.value)}
      {...(props as TextFieldProps)}
    />
  );
};

export default TextFieldWithDebounce;
