import React, { FC, Fragment, useRef, useState } from "react";
import Context, { Config } from "./Context";
import Confirm from "../../components/Confirm";

const Provider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const cbRef = useRef<undefined | ((answer: boolean) => void)>();
  const [state, setState] = useState({ isOpen: false });
  const [config, setConfig] = useState<Config>({});

  const open = (config: Config, cb: (answer: boolean) => void) => {
    setConfig(config);
    cbRef.current = cb;
    setState((state) => ({ ...state, isOpen: true }));
  };

  const handleClickYes = () => {
    cbRef.current?.(true);
    cbRef.current = undefined;
    setState((state) => ({ ...state, isOpen: false }));
  };

  const handleClickNo = () => {
    cbRef.current?.(false);
    cbRef.current = undefined;
    setState((state) => ({ ...state, isOpen: false }));
  };

  return (
    <Context.Provider value={{ open }}>
      <Fragment>
        {children}
        <Confirm
          message={config.message}
          title={config.title}
          open={state.isOpen}
          onClickYes={handleClickYes}
          onClickNo={handleClickNo}
        />
      </Fragment>
    </Context.Provider>
  );
};

export default Provider;
