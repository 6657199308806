import { connect, ConnectedProps } from "react-redux";
import MainView from "./components/MainView";
import { setModuleValue } from "../../actions/actions";
import { addErrorMessage } from "../../store/system/reducer";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { PreproprecessModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => ({
  module: getModule(
    state,
    +ownProps.match.params.moduleId
  ) as PreproprecessModule,
  noImage: state.database.images.length === 0,
});

const connector = connect(mapStateToProps, { setModuleValue, addErrorMessage });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MainView);
