import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "../../../components/SimpleDialog";
import DetectorSetting from "./DetectorSettings";
import useI18n from "../../../hooks/useI18n";
import { DetectorModule } from "../../../types/modules";

const styles = {
  dialog: {
    width: 400,
  },
} as const;

type Props = {
  values: DetectorModule["values"]["config"];
  onChange: (values: DetectorModule["values"]["config"]) => void;
  open: boolean;
  onClose: () => void;
};

const DetectorSettingDialog: FC<Props> = ({
  values,
  onChange,
  open,
  onClose,
}) => {
  const [_] = useI18n();

  const actions = [
    <Button color="primary" onClick={onClose}>
      {_("close")}
    </Button>,
  ];

  return (
    <Dialog
      actions={actions}
      open={open}
      title={_("settings")}
      onRequestClose={onClose}
      contentStyle={styles.dialog}
    >
      <DetectorSetting onChange={onChange} values={values} />
    </Dialog>
  );
};

export default DetectorSettingDialog;
