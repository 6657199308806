export const colors: [string, string][] = [
  ["#e53935", "white"],
  ["#d81b60", "white"],
  ["#8e24aa", "white"],
  ["#5e35b1", "white"],
  ["#3949ab", "white"],
  ["#1e88e5", "white"],
  ["#039be5", "white"],
  ["#00acc1", "white"],
  ["#00897b", "white"],
  ["#43a047", "white"],
  ["#7cb342", "black"],
  ["#c0ca33", "black"],
  ["#fdd835", "black"],
  ["#ffb300", "black"],
  ["#fb8c00", "black"],
  ["#f4511e", "white"],
];

const createColorsKey = () => {
  const result: { [key: string]: string } = {};
  colors.forEach(([key, background]) => {
    result[key] = background;
  });
  return result;
};

const colorsKeys = createColorsKey();

export const getContrastColor = (color: string) => colorsKeys[color];
