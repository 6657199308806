import React from "react";
import NoImageInfo from "../components/NoImageInfo";

function noImageWrap<T extends { noImage: boolean }>(
  ComposedComponent: React.ComponentType<T>
) {
  return (props: T) => {
    if (!props.noImage) {
      return <ComposedComponent {...props} />;
    }
    return <NoImageInfo />;
  };
}

export default noImageWrap;
