import moment from "moment";
import { ImageSize, Rectangle } from "../types/common";

// const SECONDS_IN_MINUTES = 60
// const SECONDS_IN_HOURS

export const formatDateTime = (unixTime?: number) =>
  moment(unixTime ? unixTime * 1000 : undefined).format("M.D.YYYY H:mm:ss");

export const formatDate = (unixTime: number) =>
  moment(unixTime ? unixTime * 1000 : undefined).format("D.M.YYYY");

export const formatDatepicker = (unixTime: number) =>
  moment(unixTime ? unixTime * 1000 : undefined).format("YYYY-MM-DD");

export const formatDuration = (duration: number) => {
  const d = moment.duration(duration, "seconds");
  const durationDays = d.days();
  const durationHours = d.hours();
  const durationMinutes = d.minutes();
  const durationSeconds = d.seconds();
  const dayText = durationDays ? `${durationDays}d ` : "";
  return `${dayText}${durationHours ? `${durationHours}h ` : ""} ${
    durationMinutes ? `${durationMinutes}m ` : ""
  } ${durationSeconds ? `${durationSeconds}s ` : ""}`;
};

export const pixelsToPercent = (values: Rectangle, imgSize: ImageSize) => ({
  ...values,
  percent: true,
  x: values.x / imgSize.width,
  y: values.y / imgSize.height,
  width: values.width / imgSize.width,
  height: values.height / imgSize.height,
});

export const percentToPixels = (values: Rectangle, imgSize: ImageSize) => {
  if (values.fixSize) {
    return {
      ...values,
      x: values.x * imgSize.width,
      y: values.y * imgSize.height,
    };
  }
  return !values.percent
    ? values
    : {
        ...values,
        x: values.x * imgSize.width,
        y: values.y * imgSize.height,
        width: values.width * imgSize.width,
        height: values.height * imgSize.height,
      };
};

export const formatConfidence = (number: number) => {
  const text = (number * 100).toFixed(2);
  if (text.endsWith(".00")) {
    return text.slice(0, -3);
  } else {
    return text;
  }
};

const renderClassNames = (item: Rectangle) => {
  const className =
    item.classNames && item.classNames.length > 0 ? item.classNames[0] : null;
  if (className) {
    return className.accuracy
      ? `${className.label} ${Math.round(className.accuracy * 100)}%`
      : className.label;
  }
  return "";
};

export const getRectangleText = (item: Rectangle) => {
  if (item.text) {
    return item.text;
  }
  let output = item.label || "";
  output += `${renderClassNames(item)} `;

  if (item.confidence) {
    output += `${Math.round(item.confidence * 100)}% `;
  }
  return output.trim();
};
