import { useCallback } from "react";
import { useAppSelector } from "./store";

const useI18n = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { lang } = useAppSelector((state) => ({ lang: state.system.lang })); // refresh translation
  const _ = useCallback((key: string, ...params: string[]) => {
    const translate = (window as any)._;
    return translate(key, ...params);
  }, []);
  return [_];
};

export default useI18n;
