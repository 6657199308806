import React from "react";
import useI18n from "../hooks/useI18n";

function withI18n<T>(ComposedComponent: React.ComponentType<T>) {
  return React.forwardRef((props: Omit<T, "t">, ref) => {
    const [_] = useI18n();
    return <ComposedComponent ref={ref} {...(props as T)} t={_} />;
  });
}

export default withI18n;
