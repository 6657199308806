import React, { FC } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from 'tss-react/mui';
import { socket } from "../../actions/actions";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "absolute",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: 100,
  },
  textFieldWithReset: {
    width: 160,
  },
}));

type CounterProps = {
  id: number;
  position: {
    x: number;
    y: number;
  };
  backgroundColor: string;
  textColor: string;
  fontSize: number;
  resetButton: boolean;
  style?: any;
  number: number;
  readOnly?: boolean;
  label: string;
};

const Counter: FC<CounterProps> = ({
  id,
  position,
  style,
  resetButton,
  backgroundColor,
  textColor,
  fontSize,
  number,
  readOnly,
  label,
}) => {
  const { classes } = useStyles();

  const elementStyle = {
    left: position.x,
    top: position.y,
    backgroundColor,
    color: textColor,
    fontSize,
    ...style,
  };

  const handleResetCounter = () => {
    if (!readOnly) {
      socket.emit("resetCounter", { counterId: id });
    }
  };

  return (
    <div className={classes.root} style={elementStyle}>
      <TextField
        disabled
        className={resetButton ? classes.textFieldWithReset : classes.textField}
        variant="outlined"
        value={number}
        inputProps={{
          style: {
            fontSize,
            color: textColor,
          },
        }}
        label={label}
        InputProps={
          resetButton
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleResetCounter} size="large">
                      <RefreshIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
    </div>
  );
};

export default Counter;
