import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useI18n from "../../../hooks/useI18n";
import { TextField } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  language: string;
  changeLanguage: (value: string) => void;
  version: string;
  buildDate: string;
  offerTutorial: boolean;
  setOfferTutorial: (offer: boolean) => void;
  license: {
    allModules: boolean;
    withTraining: boolean;
  };
};

const SettingDialogView = (props: Props) => {
  const { version, buildDate } = props;
  const [_] = useI18n();
  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onClose}>
      <DialogTitle>{_("settings")}</DialogTitle>
      <DialogContent>
        <div
          onKeyDown={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
        >
          <TextField
            label={_("language")}
            margin="normal"
            select
            variant="outlined"
            fullWidth
            value={props.language}
            onChange={(e) => {
              e.stopPropagation();
              props.changeLanguage(e.target.value as string);
            }}
          >
            <MenuItem value="en-US">English</MenuItem>
            <MenuItem value="de-DE">Deutsch</MenuItem>
            <MenuItem value="cs-CZ">Čeština</MenuItem>
            <MenuItem value="ja-JP">日本語</MenuItem>
            <MenuItem value="zh-Hans">简体中文</MenuItem>
          </TextField>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.offerTutorial}
                onChange={(e) => props.setOfferTutorial(e.target.checked)}
              />
            }
            label={_("show_tutorial_dialog")}
          />
          <Typography variant="subtitle2" gutterBottom>
            {_("version")} {version} {buildDate}
          </Typography>

          <Typography variant="subtitle2">
            {_("license")}
            {": "}
            {_(
              props.license.withTraining ? "with_training" : "without_training"
            )}
            {", "}
            {_(
              props.license.allModules ? "any_number_of_modules" : "one_module"
            )}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {_("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingDialogView;
