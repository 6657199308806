import { Vector2d } from "konva/lib/types";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { useEffect, useState } from "react";
import { getImage } from "../../../actions/ImageService";

export const distanceBetweenPoints = (
  x0: number,
  y0: number,
  x1: number,
  y1: number
) => {
  return Math.sqrt(Math.pow(x0 - x1, 2) + Math.pow(y0 - y1, 2));
};

export const onWheel = (e: any, stage: Konva.Stage | null) => {
  const scaleBy = 1 / 1.1;
  e.evt.preventDefault();
  if (!stage) {
    return 1;
  }
  var oldScale = stage.scaleX();

  var pointer = stage.getPointerPosition() as Vector2d;

  var mousePointTo = {
    x: (pointer.x - stage.x()) / oldScale,
    y: (pointer.y - stage.y()) / oldScale,
  };

  var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
  stage.scale({ x: newScale, y: newScale });

  var newPos = {
    x: pointer.x - mousePointTo.x * newScale,
    y: pointer.y - mousePointTo.y * newScale,
  };
  stage.position(newPos);
  return newScale;
};

export const isRightClick = (e: KonvaEventObject<MouseEvent>) => {
  if ("which" in e.evt) {
    // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
    return e.evt.which === 3 || e.evt.which === 2;
  } else if ("button" in e) {
    // IE, Opera
    return (e.evt as any).button === 2 || (e.evt as any).button === 3;
  } else {
    return false;
  }
};

export const clipFunc =
  (imageSize: { width: number; height: number }) => (ctx: any) => {
    const x = 0;
    const y = 0;
    const cornerRadius = 0;
    const width = imageSize.width;
    const height = imageSize.height;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width - cornerRadius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y);
    ctx.lineTo(x + width, y + height - cornerRadius);
    ctx.quadraticCurveTo(x + width, y + height, x + width, y + height);
    ctx.lineTo(x, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height);
    ctx.lineTo(x, y);
    ctx.quadraticCurveTo(x, y, x, y);
    ctx.closePath();
  };

export const useImageBase64 = (imageUrl: string) => {
  const [loader, setLoader] = useState(true);
  const [image, setImage] = useState<string>("");
  useEffect(() => {
    setLoader(true);
    getImage(imageUrl, true).then((image) => {
      setLoader(false);
      setImage(image);
    });
    return () => {
      if (image?.startsWith("blob:")) {
        URL.revokeObjectURL(image);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);
  return loader ? null : image;
};
