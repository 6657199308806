import React, { FC } from "react";
import BrushIcon from "@mui/icons-material/Brush";
import DeleteIcon from "@mui/icons-material/Delete";
import PolygonIcon from "@mui/icons-material/Timeline";
import PointIcon from "@mui/icons-material/FiberManualRecord";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import IconHelp from "@mui/icons-material/Help";
import IconUndo from "@mui/icons-material/Undo";
import InserImageIcon from "@mui/icons-material/InsertPhoto";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ImageSize } from "../../../types/common";
import useI18n from "../../../hooks/useI18n";
import { CONTENT_INNER_PADDING } from "../../../theme";

const styles = {
  wrap: {
    height: 41,
    paddingTop: CONTENT_INNER_PADDING,
    paddingLeft: CONTENT_INNER_PADDING,
    paddingRight: CONTENT_INNER_PADDING,
    position: "relative",
  },
  toggle: {
    marginRight: 8,
  },
  slider: {
    display: "inlineBlock",
    width: 100,
    margin: "0 0 -4px 10px",
  },
  brushIcon: {
    marginRight: 8,
    position: "relative",
  },
  eraseIcon: {
    marginRight: 20,
  },
  brushSize: {
    display: "inline-block",
  },
  smallIcon: {
    width: 24,
    height: 24,
  },
  small: {
    width: 36,
    height: 36,
    padding: 0,
  },
  imageLabelSize: {
    color: "rgb(101, 101, 101)",
    marginLeft: 10,
  },
  imageLabel: {
    marginLeft: 10,
  },
  sizeLabel: {
    // marginLeft: 10
  },
  toggleButton: {
    height: 30,
  },
  iconButton: {
    padding: 5,
  },
  labelWrap: {
    flex: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: 4,
    textOverflow: "ellipsis",
  },
} as const;

const EraseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 20">
    <path
      d={`M16.998 4.18l-3.154-2.425c-0.872-0.671-2.135-0.506-2.807 0.365l-8.4 10.897c-0.671 0.871-0.507 2.132 0.365
   2.803l3.153 2.425c0.872 0.671 2.135 0.506 2.807-0.365l8.401-10.897c0.671-0.871 0.507-2.132-0.365-2.803zM8.548
    16.467l-0.537 0.681c-0.152 0.197-0.385 0.31-0.639 0.31-0.124
    0-0.309-0.029-0.485-0.164l-3.153-2.425c-0.168-0.129-0.275-0.317-0.303-0.53s0.028-0.422
     0.157-0.589l0.537-0.681c0.152-0.197 0.385-0.311 0.64-0.311 0.124 0 0.309 0.029 0.485
      0.164l3.154 2.425c0.168 0.129 0.275 0.317 0.303 0.53 0.026 0.213-0.030 0.422-0.159 0.59z`}
    />
  </SvgIcon>
);

export type Values = {
  radius: number;
  rubber: boolean;
  polygon: boolean;
};

type Props = {
  values: Values;
  imageSize: ImageSize;
  onChange: (values: Values) => void;
  onResetScale: () => void;
  onClear: () => void;
  onShowHelper: () => void;
  undoEnabled?: boolean;
  label?: string;
  enableUpload?: boolean;
  onClickUpload?: () => void;
  onClickUndo: () => void;
  children?: React.ReactNode;
};

const ToolPanel: FC<Props> = ({
  values,
  onChange,
  onResetScale,
  onClear,
  onShowHelper,
  children,
  imageSize,
  onClickUndo,
  undoEnabled,
  label,
  enableUpload,
  onClickUpload,
}) => {
  const [_] = useI18n();

  const handleClickResetScale = (e: React.MouseEvent) => {
    e.stopPropagation();
    onResetScale();
  };

  const handleClickClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClear();
  };

  const handleClickShowHelper = (e: React.MouseEvent) => {
    e.stopPropagation();
    onShowHelper();
  };

  const handleClickUndo = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickUndo();
  };

  return (
    <div style={styles.wrap}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={values.rubber ? "rubber" : "paint"}
            onChange={(e, value) =>
              onChange({ ...values, rubber: value === "rubber" })
            }
            size="small"
          >
            <ToggleButton style={styles.toggleButton} value="paint">
              <BrushIcon />
            </ToggleButton>
            <ToggleButton style={styles.toggleButton} value="rubber">
              <EraseIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <ToggleButtonGroup
            exclusive
            value={values.polygon ? "polygon" : "brush"}
            onChange={(e, value) => {
              onChange({ ...values, polygon: value === "polygon" });
            }}
            size="small"
          >
            <ToggleButton style={styles.toggleButton} value="brush">
              <PointIcon />
            </ToggleButton>
            <ToggleButton style={styles.toggleButton} value="polygon">
              <PolygonIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <Grid spacing={1} container alignItems="center">
            <Grid item>
              <span style={styles.sizeLabel}>{_("size_of_brush")}</span>
            </Grid>
            <Grid item>
              <Slider
                min={2}
                max={70}
                style={{ width: 100, marginBottom: -6 }}
                value={values.radius}
                onChange={(e, radius) =>
                  onChange({ ...values, radius: radius as number })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton
            disabled={!undoEnabled}
            style={styles.iconButton}
            onClick={handleClickUndo}
            size="large"
          >
            <IconUndo />
          </IconButton>
          <IconButton
            style={styles.iconButton}
            onClick={handleClickResetScale}
            size="large"
          >
            <FullscreenIcon />
          </IconButton>
          <IconButton
            style={styles.iconButton}
            onClick={handleClickClear}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            style={styles.iconButton}
            onClick={handleClickShowHelper}
            size="large"
          >
            <IconHelp />
          </IconButton>
          {enableUpload && (
            <IconButton
              title={_("upload_image")}
              style={styles.iconButton}
              onClick={onClickUpload}
              size="large"
            >
              <InserImageIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          <div
            style={styles.labelWrap}
            title={`${imageSize.width}x${imageSize.height} ${label || ""}`}
          >
            <span
              style={styles.imageLabelSize}
            >{`${imageSize.width}x${imageSize.height}`}</span>
            <span style={styles.imageLabel}>{label}</span>
          </div>
        </Grid>

        {children}
      </Grid>
    </div>
  );
};

export default ToolPanel;
