import React, { FC } from "react";
import PhotoIcon from "@mui/icons-material/PhotoLibrary";
import CameraIcon from "@mui/icons-material/Videocam";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { CONTENT_INNER_PADDING, IMAGE_LIST_LEFT_PADDING } from "../../theme";
import useI18n from "../../hooks/useI18n";

const styles = {
  wrap: {
    flexFlow: "row",
    justifyContent: "space-around",
    display: "flex",
    alignItems: "stretch",
    position: "relative",
    padding: CONTENT_INNER_PADDING,
  },
  leftPanel: {
    flex: 1,
    width: 1,
  },
  rightPanel: {
    marginLeft: IMAGE_LIST_LEFT_PADDING,
    position: "relative",
    width: 300,
    background: "grey",
  },
  content: {
    margin: 10,
    padding: 10,
    color: "white",
  },
} as const;

type Props = {
  height: number | string;
  children: React.ReactNode;
};

const NoImage: FC<Props> = ({ children, height }) => {
  const history = useHistory();
  const [_] = useI18n();
  return (
    <div style={{ height, ...styles.wrap }}>
      <div style={styles.leftPanel}>{children}</div>
      <div style={styles.rightPanel}>
        <div style={styles.content}>
          <p>
            <h3>{_("no_images")}</h3>
            {_("upload_image")}{" "}
            <Button
              variant="contained"
              startIcon={<PhotoIcon />}
              onClick={() => history.push("/images/")}
            >
              {_("images")}
            </Button>
            <br />
            <br />
            {_("capture_image")}{" "}
            <Button
              variant="contained"
              startIcon={<CameraIcon />}
              onClick={() => history.push("/camera/")}
            >
              {_("camera")}
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoImage;
