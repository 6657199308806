import React, { FC } from "react";
import useI18n from "../hooks/useI18n";
import { Alert, AlertTitle, Box } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  deleted?: boolean;
};

const ModuleNotFound: FC<Props> = ({ deleted }) => {
  const [_] = useI18n();

  return (
    <Box sx={{ p: 1 }}>
      <Alert severity="error">
        <AlertTitle>
          {deleted ? _("component_deleted") : _("component_not_found")}
        </AlertTitle>
        Go back to the <Link to="/modules/list">list of modules</Link>
      </Alert>
    </Box>
  );
};

export default ModuleNotFound;
