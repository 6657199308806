import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import SliderWithField from "../../../components/SliderWithField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Theme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FeatureCategory } from "../types";
import { makeStyles } from "tss-react/mui";
import TextFieldWithDebounce from "../../../components/TextFieldWithDebounce";

type Props = {
  structure: FeatureCategory[];
  setFeature: (id: string, value: number | boolean | string) => void;
  callCommand: (id: string) => void;
  opened?: boolean;
};

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: -10,
  },
}));

const Features: FC<Props> = ({
  structure,
  setFeature,
  callCommand,
  opened,
}) => {
  const { classes } = useStyles();

  const WrapComponent = opened ? React.Fragment : Accordion;
  const DetailComponent = opened ? React.Fragment : AccordionDetails;
  return (
    <div>
      {structure
        .filter((s) => s.items.length > 0)
        .map((category) => (
          <WrapComponent key={category.name}>
            {!opened && (
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" gutterBottom>
                  {category.name}
                </Typography>
              </AccordionSummary>
            )}
            <DetailComponent>
              <Grid container spacing={1}>
                {category.items.map((item) => (
                  <Grid item xs={12} key={item.name}>
                    {item.type === "boolean" && (
                      <FormControlLabel
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            readOnly={item.accessMode === "ReadOnly"}
                            checked={item.value as boolean}
                            onChange={(e) =>
                              setFeature(item.id, e.target.checked)
                            }
                          />
                        }
                        label={item.name}
                      />
                    )}

                    {item.type === "integer" &&
                      (item.accessMode === "ReadOnly" ? (
                        <TextField
                          variant="outlined"
                          disabled
                          size="small"
                          value={item.value}
                          margin="dense"
                          type="number"
                          fullWidth
                          label={item.name}
                        />
                      ) : (
                        <SliderWithField
                          onChange={(value) => {
                            setFeature(item.id, value);
                          }}
                          step={item.step}
                          marginTop
                          label={`${item.name} - min ${item.min} - max ${item.max}`}
                          value={item.value as number}
                          max={item.max as number}
                          min={item.min}
                          size="small"
                        />
                      ))}

                    {item.type === "float" && (
                      <TextFieldWithDebounce
                        variant="outlined"
                        disabled={item.accessMode === "ReadOnly"}
                        size="small"
                        value={item.value}
                        margin="dense"
                        type="number"
                        fullWidth
                        label={item.name}
                        onChange={(value) => {
                          setFeature(item.id, parseFloat(value));
                        }}
                      />
                    )}

                    {item.type === "string" && (
                      <TextField
                        variant="outlined"
                        disabled
                        margin="dense"
                        value={item.value}
                        fullWidth
                        label={item.name}
                        size="small"
                      />
                    )}
                    {item.type === "enumeration" && (
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        size="small"
                        disabled={item.accessMode === "ReadOnly"}
                        value={item.value}
                        label={item.name}
                        onChange={(e) =>
                          setFeature(item.id, e.target.value as string)
                        }
                      >
                        {item.items?.map((subItem) => (
                          <MenuItem key={subItem.id} value={subItem.id}>
                            {subItem.id}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {item.type === "command" && (
                      <Button
                        className={classes.button}
                        variant="outlined"
                        onClick={() => callCommand(item.id)}
                      >
                        {item.name}
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </DetailComponent>
          </WrapComponent>
        ))}
    </div>
  );
};

export default Features;
