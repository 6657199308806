import React, { FC } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup } from "@mui/material";
import useI18n from "../../../hooks/useI18n";
import { DetectorModule } from "../../../types/modules";

type Props = {
  values: DetectorModule["values"]["config"];
  onChange: (values: DetectorModule["values"]["config"]) => void;
};

const DetectorSetting: FC<Props> = ({ values, onChange }) => {
  const [_] = useI18n();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.CLASSIFICATION}
            onChange={(e) => {
              onChange({ ...values, CLASSIFICATION: e.target.checked });
            }}
          />
        }
        label={_("enable_classification")}
      />
    </FormGroup>
  );
};

export default DetectorSetting;
