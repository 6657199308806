import React from "react";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import { Field } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "tss-react/mui";
import validUrl from "valid-url";
import useI18n from "../../../../hooks/useI18n";

const useStyles = makeStyles()({
  timeout: {
    textAlign: "right",
    width: 100,
  },
});

const HttpForm = () => {
  const { classes } = useStyles();
  const [_] = useI18n();
  return (
    <>
      <Grid item>
        <FormLabel component="legend">{_("method")}</FormLabel>
        <Field name="method" component={RadioGroup} row>
          <FormControlLabel
            value="get"
            control={<Radio color="primary" />}
            label="GET"
          />
          <FormControlLabel
            value="post"
            control={<Radio color="primary" />}
            label="POST"
          />
        </Field>
      </Grid>
      <Grid item>
        <Field
          disabled={false}
          name="endPoint"
          label={_("api_endpoint")}
          validate={(value: string) =>
            (!value || !validUrl.isUri(value) || value === "http://") &&
            "Invalid url"
          }
          margin="dense"
          fullWidth
          variant="outlined"
          component={TextField}
        />
      </Grid>
      <Grid item>
        <Field
          className={classes.timeout}
          disabled={false}
          name="timeout"
          label={_("timeout")}
          margin="dense"
          type="number"
          variant="outlined"
          component={TextField}
          InputProps={{
            endAdornment: <InputAdornment position="start">s</InputAdornment>,
          }}
        />
      </Grid>
    </>
  );
};

export default HttpForm;
