import { Rectangle } from "../../types/common";
import * as Flow from "../../types/flow";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LoadingItem = string | { name: string; progress: number };

export type Statistics = {
  annotations: {
    ok: number[];
    nok: number[];
    include: number[];
  };
  flow: any;
  goodNokCount: number;
  goodOkCount: number;
  images: { id: number; result?: boolean; annotation: boolean }[];
  summary: {
    truePositive: number;
    trueNegative: number;
    falsePositive: number;
    falseNegative: number;
    recall: number;
    precision: number;
    time: {
      min: number;
      max: number;
      average: number;
    };
    evaluationActive: boolean;
  };
  trainingImagesIds: number[];
  type: string;
};

export type SystemState = {
  operatorOnly: boolean;
  extraModelData: {
    detector: {
      [key: number]: {
        imageRectangles: {
          id: number;
          isEmpty: boolean;
          rectangles: Rectangle[];
        }[];
      };
    };
  };
  isOpenDrawer: boolean;
  isInit: boolean;
  showNeedRestart: boolean;
  detectedRectangles: ReceivedRectangle[];
  debuggerInfo: DebugerInfo[];
  averageTimes: AverageTimes[];
  disconnect: boolean;
  connectError: boolean;
  lang: string;
  fatalError: boolean;
  isLoading: boolean;
  imageListSettings: any;
  autClassifier: number[];
  uploadImages: { fullName: string; index: number }[] | null;
  showSettingDialog: boolean;
  showCameraFiltered: boolean;
  modelLoading: boolean;
  cameraRunning: boolean;
  token: string;
  tryToken: boolean;
  invalidPassword: boolean;
  withPassword: boolean;
  version: string;
  buildDate: string;
  isFlowLightOnly: boolean;
  loadingItems: LoadingItem[];
  leftDrawerSlim: boolean;
  errorMessages: { id: number; text: string }[];
  statisticsResult: Statistics[];
  gpuDevices: any[];
  loadingProgress: null | number;
  showDoc: boolean;
  helpPanelWidth: number;
  showTutorial: boolean;
  showTutorialDialog: boolean;
  offerTutorial: boolean;
  willBeReset: boolean;
  lightLicense: boolean;
  clickStartAnomalyTraining: boolean;
  disableCodeModule: boolean;
  cameraIsRunning: boolean;
  ctiFilePath: null;
  cameraStatus: string;
  imageInput: "camera" | "api";
  cameraList: any[];
  currentCamera: null;
  projectName: string;
  simpleTutorialOnly: boolean;
  enableGenicam: boolean;
  uploadImagesCollisions: { fullName: string; index: number }[];
  running: any;
  projectId: string;
  hideContent: boolean;
  trainingDisabled: boolean;
  operatorConfig: any;
  diffTime: number;
  providerId: string | null;
  license: {
    withTraining: boolean;
    allModules: boolean;
  };
  enableGPIO: boolean;
  enableMXIO: boolean;
  restricted: boolean;
};

// Define the initial state using that type
const initialState: SystemState = {
  operatorOnly: false,
  extraModelData: { detector: {} },
  isOpenDrawer: false,
  isInit: false,
  showNeedRestart: false,
  detectedRectangles: [],
  debuggerInfo: [],
  averageTimes: [],
  disconnect: false,
  connectError: false,
  lang: "en-US",
  fatalError: false,
  isLoading: false,
  imageListSettings: {},
  autClassifier: [],
  uploadImages: null,
  showSettingDialog: false,
  showCameraFiltered: false,
  modelLoading: false,
  cameraRunning: false,
  token: "",
  tryToken: true,
  invalidPassword: false,
  withPassword: false,
  version: "",
  buildDate: "",
  isFlowLightOnly: true,
  loadingItems: [],
  leftDrawerSlim: true,
  errorMessages: [],
  statisticsResult: [],
  gpuDevices: [],
  loadingProgress: null,
  showDoc: true,
  helpPanelWidth: 400,
  showTutorial: false,
  showTutorialDialog: false,
  offerTutorial: false,
  willBeReset: false,
  lightLicense: false,
  clickStartAnomalyTraining: false,
  disableCodeModule: false,
  cameraIsRunning: false,
  ctiFilePath: null,
  cameraStatus: "",
  imageInput: "camera",
  cameraList: [],
  currentCamera: null,
  projectName: "",
  simpleTutorialOnly: false,
  enableGenicam: false,
  uploadImagesCollisions: [],
  running: {},
  projectId: "",
  hideContent: false,
  trainingDisabled: true,
  operatorConfig: null,
  diffTime: 0,
  providerId: null,
  license: {
    allModules: true,
    withTraining: true,
  },
  enableGPIO: false,
  enableMXIO: false,
  restricted: false,
};

export type InitData = {
  disableCodeModule: boolean;
  showDoc: boolean;
  offerTutorial: boolean;
  showTutorialDialog: boolean;
  helpPanelWidth: number;
  version: string;
  buildDate: string;
  operatorOnly: boolean;
  gpuDevices: any[];
  simpleTutorialOnly: boolean;
  title: string;
  enableGenicam: boolean;
  projectId: string;
  operatorConfig: any;
  trainingDisabled: boolean;
  time: number;
  providerId: string | null;
  license: {
    withTraining: boolean;
    allModules: boolean;
  };
  enableGPIO: boolean;
  enableMXIO: boolean;
  restricted: boolean;
};

export type ReceivedRectangle = {
  settings: any;
  rectangles: Rectangle[];
  imageId: number;
  error: boolean;
};

export type DebugerInfo = {
  error: boolean;
  imageId: number;
  settings: Flow.Any[];
  context: { items: Flow.AnyDebugger[]; completeTime: number };
};

export type AverageTimes = {
  error: boolean;
  settings: Flow.Any[];
  imagesLastId: number;
  times: { [key in number]: number };
};

export const paintSlice = createSlice({
  name: "paint",
  initialState,
  reducers: {
    receiveFatalError: (state) => {
      state.fatalError = true;
    },
    disconnect: (state) => {
      state.disconnect = true;
    },
    connectError: (state) => {
      state.connectError = true;
    },
    receiveInitData: (state, action: PayloadAction<InitData>) => {
      (window as any).version = action.payload.version;
      state.disconnect = false;
      state.connectError = false;
      state.isInit = true;
      state.showDoc = action.payload.showDoc;
      state.offerTutorial = action.payload.offerTutorial;
      state.showTutorialDialog = action.payload.showTutorialDialog;
      state.helpPanelWidth = action.payload.helpPanelWidth;
      state.version = action.payload.version;
      state.buildDate = action.payload.buildDate;
      state.operatorOnly = action.payload.operatorOnly;
      state.gpuDevices = action.payload.gpuDevices;
      state.simpleTutorialOnly = action.payload.simpleTutorialOnly;
      state.projectName = action.payload.title;
      state.enableGenicam = action.payload.enableGenicam;
      state.projectId = action.payload.projectId;
      state.operatorConfig = action.payload.operatorConfig;
      state.trainingDisabled = action.payload.trainingDisabled;
      state.diffTime = action.payload.time - Date.now();
      state.providerId = action.payload.providerId;
      state.license = action.payload.license;
      state.enableGPIO = action.payload.enableGPIO;
      state.enableMXIO = action.payload.enableMXIO;
      state.restricted = action.payload.restricted;
      if (state.simpleTutorialOnly) {
        state.showTutorial = true;
        state.showTutorialDialog = false;
      }
    },
    receiveDetectedRectangles: (
      state,
      action: PayloadAction<ReceivedRectangle>
    ) => {
      state.detectedRectangles.push(action.payload);
    },
    receiveDebuggerInfo: (state, action: PayloadAction<DebugerInfo>) => {
      state.debuggerInfo.push(action.payload);
    },
    receiveAverageTimes: (state, action: PayloadAction<AverageTimes>) => {
      state.averageTimes.push(action.payload);
    },
    showSettingDialog: (state) => {
      state.showSettingDialog = true;
    },
    closeSettingDialog: (state) => {
      state.showSettingDialog = false;
    },
    loadingStart: (state, action: PayloadAction<boolean>) => {
      state.hideContent = action.payload;
      state.isLoading = true;
    },
    loadingStop: (state) => {
      state.hideContent = false;
      state.isLoading = false;
    },
    startAutoClassification: (state, action: PayloadAction<number>) => {
      state.autClassifier.push(action.payload);
    },
    endAutoClassification: (state, action: PayloadAction<number>) => {
      state.autClassifier = state.autClassifier.filter(
        (i) => i !== action.payload
      );
    },
    selectUploadImages: (
      state,
      action: PayloadAction<{ fullName: string; index: number }[] | null>
    ) => {
      state.uploadImages = action.payload;
    },
    setCollisionImagesName: (
      state,
      action: PayloadAction<{ fullName: string; index: number }[]>
    ) => {
      state.uploadImagesCollisions = action.payload;
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
      (window as any).__lang = action.payload;
      state.lang = action.payload;
    },
    setCameraRunning: (state, action: PayloadAction<boolean>) => {
      state.cameraRunning = action.payload;
    },
    receiveToken: (
      state,
      action: PayloadAction<{ token: string; withPassword: boolean }>
    ) => {
      state.token = action.payload.token;
      state.withPassword = action.payload.withPassword;
    },
    invalidToken: (state) => {
      state.tryToken = false;
    },
    setFlowLightOnly: (state, action: PayloadAction<boolean>) => {
      state.isFlowLightOnly = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingItem[]>) => {
      state.loadingItems = action.payload;
    },
    addErrorMessage: {
      reducer(state, action: PayloadAction<{ id: number; text: string }>) {
        state.errorMessages.push(action.payload);
      },
      prepare(text: string) {
        return {
          payload: {
            text,
            id: Date.now(),
          },
        };
      },
    },
    clearErrorMessages: (state) => {
      state.errorMessages = [];
    },
    receiveStatistics: (state, action: PayloadAction<any>) => {
      state.statisticsResult.push(action.payload);
    },
    setLoadingProgress: (state, action: PayloadAction<number | null>) => {
      state.loadingProgress = action.payload;
    },
    setShowDoc: (state, action: PayloadAction<boolean>) => {
      state.showDoc = action.payload;
    },
    setHelpPanelWidth: (state, action: PayloadAction<number>) => {
      state.helpPanelWidth = action.payload;
    },
    receiveExtraModelData: (
      state,
      action: PayloadAction<{ modelId: number; data: any; type: "detector" }>
    ) => {
      state.extraModelData[action.payload.type][action.payload.modelId] =
        action.payload.data;
    },
    startTutorial: (state) => {
      state.showTutorial = true;
      state.showTutorialDialog = false;
    },
    closeTutorialDialog: (state) => {
      state.showTutorialDialog = false;
    },
    closeTutorial: (state) => {
      state.showTutorial = false;
    },
    setOfferTutorial: (state, action: PayloadAction<boolean>) => {
      state.offerTutorial = action.payload;
    },
    willBeReset: (state) => {
      state.willBeReset = true;
    },
    setShowTutorial: (state, action: PayloadAction<boolean>) => {
      state.showTutorial = action.payload;
    },
    startAnomalyTraining: (state) => {
      state.clickStartAnomalyTraining = true;
    },
    invalidPassword: (state, action: PayloadAction<boolean>) => {
      state.invalidPassword = action.payload;
    },
  },
});

export const {
  addErrorMessage,
  clearErrorMessages,
  showSettingDialog,
  closeSettingDialog,
  startAnomalyTraining,
} = paintSlice.actions;

export default paintSlice.reducer;

export const actions = paintSlice.actions;
