import React from "react";
import { useHistory } from "react-router-dom";
import ModelLearningList from "../../../components/templates/ModelLearningList";
import { unsupervisedDeleteModel } from "../../../actions/actions";
import useI18n from "../../../hooks/useI18n";
import { PropsFromRedux } from "../ListContainer";

const ModelListView = (props: PropsFromRedux) => {
  const history = useHistory();
  const [_] = useI18n();
  return (
    <ModelLearningList
      t={_}
      enableGpuSettings
      maxHeight={props.maxHeight}
      gpuValue={props.module.gpuSettings || []}
      onChangeGpuValue={(value) => {
        props.setGpuSettings(props.module.id, value);
      }}
      gpuDevices={[]}
      activeModelId={props.activeModelId}
      models={props.models}
      onActive={(model) => {
        props.unsupervisedSetActiveModelId({
          moduleId: props.module.id,
          modelId: model ? model.modelId : null,
        });
      }}
      getParams={(model) => {
        return (
          <span>
            {" "}
            {_(
              // eslint-disable-next-line no-nested-ternary
              (model.trainingParams.TYPE || "old") === "old"
                ? "lightweight"
                : "standart"
            )}
          </span>
        );
      }}
      onEditName={props.unsupervisedSetName}
      onDelete={(model) => unsupervisedDeleteModel(model.modelId)}
      onOpen={() => {
        history.push(
          `/modules/unsupervised/${props.module.id}/validate/detail/`
        );
      }}
    />
  );
};

export default React.memo(ModelListView);
