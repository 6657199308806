import { InputAny } from "../../../../components/Form/types";
import { ElementTypes } from "./types";

const inputSettings: { [type in ElementTypes]: InputAny[] } = {
  input: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "label", type: "text", label: "label" },
    { id: "backgroundColor", type: "color", label: "background_color" },
    { id: "textColor", type: "color", label: "text_color" },
    { id: "width", type: "number", label: "width", min: 10, max: 1000 },
    { id: "targetKey", type: "text", label: "target_key" },
    {
      id: "fontSize",
      type: "number",
      label: "font_size",
      min: 4,
      max: 300,
      maxSlider: 100,
    },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
    { id: "enableMaxLength", type: "checkbox", label: "enable_max_length" },
    {
      id: "maxLength",
      type: "number",
      label: "max_length",
      min: 0,
      maxSlider: 100,
      max: 1000,
      show: (values) => !!values["enableMaxLength"],
    },
  ],
  slider: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "width", type: "number", label: "width", min: 10, max: 1000 },
    { id: "min", type: "number", label: "min", maxSlider: 1000 },
    { id: "max", type: "number", label: "max", maxSlider: 1000 },
    { id: "label", type: "text", label: "label" },
    { id: "showTextField", type: "checkbox", label: "show_text_field" },
    { id: "targetKey", type: "text", label: "target_key" },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
  ],
  image: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "width", type: "number", label: "width", min: 10, max: 1000 },
    { id: "src", type: "file", label: "Image", accept: "image/*" },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
  ],
  select: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "label", type: "text", label: "label" },
    { id: "backgroundColor", type: "color", label: "background_color" },
    { id: "textColor", type: "color", label: "text_color" },
    { id: "width", type: "number", label: "width", min: 10, max: 1000 },
    {
      id: "fontSize",
      type: "number",
      label: "font_size",
      min: 4,
      max: 300,
      maxSlider: 100,
    },
    { id: "targetKey", type: "text", label: "target_key" },
    { id: "items", type: "text", label: "items_separator" },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
  ],
  root: [{ id: "backgroundColor", type: "color", label: "background_color" }],
  camera: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "width", type: "number", label: "width", min: 0, max: 2000 },
    { id: "height", type: "number", label: "height", min: 0, max: 2000 },
    { id: "showRectangles", type: "checkbox", label: "show_rectangles" },
  ],
  label: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    { id: "backgroundColor", type: "color", label: "background_color" },
    { id: "textColor", type: "color", label: "text_color" },
    {
      id: "padding",
      type: "number",
      label: "offset",
      min: 0,
      max: 100,
      maxSlider: 50,
    },
    {
      id: "fontSize",
      type: "number",
      label: "font_size",
      min: 4,
      max: 300,
      maxSlider: 100,
    },
    {
      id: "sourceType",
      type: "select",
      label: "source_type",
      options: [
        { label: "static", value: "static" },
        { label: "dynamic", value: "dynamic" },
      ],
    },
    {
      id: "sourceValue",
      type: "text",
      label: "text",
      show: (values) => values["sourceType"] === "static",
    },
    {
      id: "sourceKey",
      type: "text",
      label: "context_key",
      show: (values) => values["sourceType"] === "dynamic",
    },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
  ],
  counter: [
    { id: "x", type: "number", label: "position_x", min: 0, max: 2000 },
    { id: "y", type: "number", label: "position_y", min: 0, max: 2000 },
    {
      id: "sourceType",
      type: "select",
      label: "source_type",
      options: [
        { label: "ok", value: "ok" },
        { label: "ng", value: "ng" },
        { label: "all", value: "all" },
        { label: "by_context", value: "by_context" },
      ],
    },
    {
      id: "incrementByContext",
      type: "text",
      label: "key_by_which_to_increment",
      show: (values) => values["sourceType"] === "by_context",
    },
    { id: "backgroundColor", type: "color", label: "background_color" },
    { id: "textColor", type: "color", label: "text_color" },
    {
      id: "fontSize",
      type: "number",
      label: "font_size",
      min: 4,
      max: 300,
      maxSlider: 100,
    },
    { id: "label", type: "text", label: "label" },
    { id: "showResetButton", type: "checkbox", label: "show_reset_button" },
    {
      id: "autoReset",
      type: "select",
      label: "auto_reset",
      options: [
        { label: "startProject", value: "start_project" },
        { label: "none", value: "none" },
      ],
      show: (values) => values["showResetButton"],
    },
    { id: "visibilityByKey", type: "text", label: "visibility_by_key" },
    { id: "resetButton", type: "button", label: "reset_counter" },
  ],
};

export default inputSettings;
