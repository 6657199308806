import { connect, ConnectedProps } from "react-redux";
import ModelDetailView from "./components/ModelDetailView";
import {
  classifierUpdateEvaluation,
  setModuleValue,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { RouteComponentProps } from "react-router-dom";
import { GlobalState } from "../../store/createStore";
import { ClassifierModel, ClassifierModule } from "../../types/modules";
import { withRequiredModel } from "../../utils/withRequiredModel";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const module = getModule(
    state,
    +ownProps.match.params.moduleId
  ) as ClassifierModule;
  const { modelId } = module;
  return {
    module,
    model: (modelId &&
      state.database.classifierModels.find(
        (m) => m.modelId === modelId
      )) as ClassifierModel,
    images: state.database.images,
  };
};

const mapDispatchToProps = {
  updateEvaluation: classifierUpdateEvaluation,
  setModuleValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRequiredModel(ModelDetailView));
