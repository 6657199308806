// @ts-nocheck
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { EnhancedStore } from "@reduxjs/toolkit";
import { SnackbarProvider } from "notistack";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const history = createBrowserHistory();

type Props = {
  store: EnhancedStore;
  routes: JSX.Element;
};

class App extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <SnackbarProvider maxSnack={5}>
          <div style={{ height: "100%" }}>
            <Router history={history}>
              <div>{this.props.routes}</div>
            </Router>
          </div>
        </SnackbarProvider>
      </Provider>
    );
  }
}

export default App;
