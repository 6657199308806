import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import useI18n from "../hooks/useI18n";

type Props = {
  open: boolean;
  onRestart: () => void;
};

const ConnectErrorDialog: FC<Props> = ({ open, onRestart }) => {
  const [_] = useI18n();
  const handleRestart = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRestart();
  };

  const actions = [
    <Button color="primary" onClick={handleRestart}>
      {_("refresh")}
    </Button>,
  ];

  return (
    <Dialog
      title={_("connect_error_title")}
      actions={actions}
      open={open}
    ></Dialog>
  );
};

export default ConnectErrorDialog;
