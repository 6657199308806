import React, { FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import utilsTypes, { Util } from "./UtilsItems/utilsTypes";
import useI18n from "../../../hooks/useI18n";
import { AllTypes, Layer } from "./UtilsItems/types";

const useStyle = makeStyles()({
  root: {
    marginBottom: 6,
  },
  mainLayer: {
    fontWeight: 500,
  },
  button: {
    textTransform: "none",
  },
});

type Props = {
  items: AllTypes[];
  onChange: (value: Layer | null) => void;
  value: Layer | null;
};

const SelectPreview: FC<Props> = (props) => {
  const [_] = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { classes } = useStyle();
  const items: Layer[] = props.items.reduce((all: Layer[], item, index) => {
    if (!item.active) {
      return all;
    }
    const module = utilsTypes.find((m) => m.id === item.type) as Util;
    const layers = module.layers ?? [];
    return [
      ...all,
      { id: item.id, layer: -1, label: _(module.label), index },
      ...(layers || []).map((layer, i) => ({
        id: item.id,
        layer: i,
        label: _(layer),
        parentLabel: _(module.label),
        index,
      })),
      null, // divider
    ];
  }, []);
  items.pop(); // remove last divider

  const selectedItemIndex = items
    .map((i) => JSON.stringify(i))
    .findIndex((i) => i === JSON.stringify(props.value));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (item: Layer) => {
    props.onChange(item);
    setAnchorEl(null);
  };

  const selectedItem =
    selectedItemIndex !== -1 ? items[selectedItemIndex] : null;

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="secondary"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedItem
          ? selectedItem.layer === -1
            ? selectedItem.label
            : `${selectedItem.parentLabel} ${selectedItem.label}`
          : _("show_all_layers")}
      </Button>
      <Menu
        id="layers-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClickItem(null)}>
          <b>{_("show_all_layers")}</b>
        </MenuItem>
        <Divider />
        {items.map((item, index) =>
          !item ? (
            // eslint-disable-next-line react/no-array-index-key
            <Divider key={index} />
          ) : (
            <MenuItem
              onClick={() => handleClickItem(item)}
              key={`${item.id}-${item.layer}`}
              value={JSON.stringify(item)}
            >
              {item.layer === -1 ? (
                <span className={classes.mainLayer}>{item.label}</span>
              ) : (
                <Box ml={1}>{item.label}</Box>
              )}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default SelectPreview;
