import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from "@mui/material/styles";
import Dropzone from "react-dropzone";
import LeftDrawer from "../../components/LeftDrawer";
import FatalErrorDialog from "../../components/FatalErrorDialog";
import StartTutorialDialog from "../../Containers/StartTutorialDialog/StartTutorialDialogContainer";
import UnsupportedBrowserDialog from "../../components/UnsupportedBrowserDialog";
import HelpPanel from "../../Containers/HelpPanel/HelpPanelContainer";
import type * as Flow from "../../types/flow";
import {
  ACCEPTED_IMAGE,
  GetConfigParams,
  getImagePath,
  getMaskPath,
  getRawImagePath,
  getSettings,
  getStaticFilePath,
  hasParallelism,
  isInitModel,
  isTraining,
} from "../../utils/common";
import { imagesUpload } from "../../actions/actions";
import Loader from "../../components/Loader/Loader";
import DragUploadFrame from "../../components/DragUploadFrame";
import TrainingDialogContainer from "../../Containers/TrainingDialog/TrainingDialogContainer";
import ImageUploadDialog from "../../Containers/ImageUploadDialog/ImageUploadDIalogContainer";
import SettingDialogContainer from "../../Containers/SettingDialog/SettingDialogContainer";
import * as theme from "../../theme";
import { themeV4, themeV4rightPanel } from "../../theme";
import LoadingItems from "../../Containers/LoadingItems/LoadingItemsContainer";
import ErrorMessages from "../../Containers/ErrorMessages/ErrorMessagesContainer";
import DeleteImagesDialog from "../../Containers/DeleteImagesDialog/DeleteImagesDialogContainer";
import EditNameDialog from "../../Containers/EditNameDialog/EditNameDialogContainer";
import ImageCollisionNameDialog from "../../Containers/ImageCollisionNameDialog/ImageCollisionNameDialogContainer";
import Modules from "../../routes/Modules/ModulesContainer";
import { GlobalState } from "../../store/createStore";
import { FileWithPath } from "../../actions/ImageService";
import { RouteComponentProps } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import withI18n from "../../utils/withI18n";
import TagsImagesDialog from "../../Containers/TagsImagesDialog/TagsImagesDialog";
import TagsManager from "../../routes/Images/components/TagsManager";
import { setShowTagsManager } from "../../store/images/reducer";
import TutorialWelcomeDialog from "../../components/TutorialWelcomeDialog";

export const HelperContext = React.createContext<{
  helper: {
    getImagePath: (params: GetConfigParams) => string;
    getMaskPath: (itemId: number) => string;
    getSettings: (params: {
      moduleId?: number | null;
      includeLast?: boolean;
      otherModule?: Flow.Any | Flow.Canny | null;
    }) => Flow.Flow;
    getStaticFilePath: (path: string) => string;
    getRawImagePath: (imageId: number, min?: boolean) => string;
    token: string;
    simpleTutorialOnly: boolean;
  };
}>({} as any);

async function myCustomFileGetter(event: any) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }

  return files;
}

const styles = {
  contentWidthDrawerSlim: {
    padding: `0 0 0 ${theme.DRAWER_SLIM_WIDTH}px`,
  },
  dropZone: {
    position: "relative",
    minHeight: "100vh",
  },
} as const;

type Props = RouteComponentProps<{ moduleId: string }> &
  PropsFromRedux & {
    children: React.ReactNode;
    t: (key: string, ...params: string[]) => string;
  };

type State = {
  theme: Theme;
  width?: number;
};

class PageLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      theme: themeV4rightPanel(props.helpPanelWidth),
    };
  }

  getHelperContext() {
    return {
      helper: {
        getImagePath: this.getImagePath,
        getSettings: this.getSettings,
        getMaskPath: this.getMaskPath,
        getStaticFilePath: this.getStaticFilePath,
        getRawImagePath: this.getRawImagePath,
        token: this.props.token,
        simpleTutorialOnly: this.props.simpleTutorialOnly,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
    this.updateSize();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.helpPanelWidth !== this.props.helpPanelWidth) {
      this.setState({
        theme: themeV4rightPanel(this.props.helpPanelWidth),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
  }

  updateSize = () => {
    this.setState({
      width: (window as any).innerWidth,
    });
  };

  getStaticFilePath = (path: string) =>
    getStaticFilePath(path, this.props.token, this.props.projectId);

  getImagePath = (params: GetConfigParams) =>
    getImagePath(
      this.props.moduleSort,
      this.props.moduleItems,
      {
        supervisedModels: this.props.supervisedModels,
        unsupervisedModels: this.props.unsupervisedModels,
        detectorModels: this.props.detectorModels,
        classifierModels: this.props.classifierModels,
        maskItems: this.props.maskItems,
        unifierItems: this.props.unifierItems,
        ocrModels: this.props.ocrModels,
      },
      params,
      this.props.token,
      this.props.projectId
    );

  getRawImagePath = (imageId: number, min?: boolean) =>
    getRawImagePath(
      imageId,
      min || false,
      this.props.projectId,
      this.props.token
    );

  getMaskPath = (itemId: number) =>
    getMaskPath(itemId, this.props.token, this.props.projectId);

  getSettings = (params: {
    moduleId?: number | null;
    includeLast?: boolean;
    otherModule?: Flow.Any | null | Flow.Canny;
  }) =>
    getSettings(
      this.props.moduleSort,
      this.props.moduleItems,
      {
        supervisedModels: this.props.supervisedModels,
        unsupervisedModels: this.props.unsupervisedModels,
        detectorModels: this.props.detectorModels,
        classifierModels: this.props.classifierModels,
        maskItems: this.props.maskItems,
        unifierItems: this.props.unifierItems,
        ocrModels: this.props.ocrModels,
      },
      params
    );

  handleDrop = (accepted: File[]) => {
    if (accepted.length > 0) {
      const acceptedFiles: FileWithPath[] = [];
      accepted.forEach((i) => {
        if (ACCEPTED_IMAGE.includes(i.type)) {
          acceptedFiles.push(i as FileWithPath);
        } else {
          enqueueSnackbar(this.props.t("invalid_file_type", i.name));
        }
      });
      if (acceptedFiles.length > 0) {
        this.props.imagesUpload(acceptedFiles);
      }
    }
  };

  render() {
    const {
      children,
      showChildren,
      location: { pathname },
    } = this.props;

    const showModulesList =
      (!this.state.width || this.state.width > 1367) &&
      this.props.showModulesPreview &&
      pathname.startsWith("/modules") &&
      !pathname.startsWith("/modules/edit") &&
      !pathname.startsWith("/modules/list");

    if (this.props.operatorOnly) {
      return null;
    }

    return (
      <Dropzone
        onDrop={this.handleDrop}
        noClick
        getFilesFromEvent={myCustomFileGetter}
        useFsAccessApi={true}
        noKeyboard
      >
        {({ getRootProps, isDragActive }) => (
          <>
            <div {...getRootProps()}>
              <HelperContext.Provider value={this.getHelperContext()}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider
                    theme={
                      this.props.showHelpPanel ? this.state.theme : themeV4
                    }
                  >
                    <div
                      style={{
                        userSelect: "none",
                        width: this.props.showHelpPanel
                          ? `calc(100vw - ${this.props.helpPanelWidth}px)`
                          : undefined,
                      }}
                    >
                      <div style={styles.contentWidthDrawerSlim}>
                        {showModulesList &&
                          !this.props.hideContent &&
                          !showChildren && <Modules left />}
                        {!this.props.hideContent &&
                          (!showChildren ? (
                            children
                          ) : (
                            <TrainingDialogContainer />
                          ))}
                      </div>
                      <LeftDrawer />
                      {isDragActive && <DragUploadFrame />}
                      <ImageUploadDialog />
                      <ImageCollisionNameDialog />
                      <SettingDialogContainer />
                      <FatalErrorDialog
                        open={this.props.fatalError}
                        onRestart={() => window.location.reload()}
                      />
                      <LoadingItems />
                      <ErrorMessages />
                      {this.props.isLoading && (
                        <Loader progress={this.props.loadingProgress} />
                      )}
                    </div>
                    <StartTutorialDialog />
                    {this.props.showHelpPanel && <HelpPanel />}
                    <UnsupportedBrowserDialog />
                  </ThemeProvider>
                </StyledEngineProvider>
              </HelperContext.Provider>
            </div>
            <EditNameDialog />
            <DeleteImagesDialog />
            {this.props.showTags && <TagsImagesDialog />}
            <TagsManager
              open={this.props.showTagsManager}
              onClose={() => this.props.setShowTagsManager(false)}
            />
            {this.props.simpleTutorialOnly && <TutorialWelcomeDialog />}
          </>
        )}
      </Dropzone>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    fatalError: state.system.fatalError,
    showChildren: isTraining(state) || isInitModel(state),
    hideContent: state.system.hideContent && state.system.isLoading,
    moduleItems: state.database.modules.items,
    supervisedModels: state.database.supervisedModels,
    unsupervisedModels: state.database.unsupervisedModels,
    detectorModels: state.database.detectorModels,
    classifierModels: state.database.classifierModels,
    maskItems: state.database.masksItems,
    unifierItems: state.database.unifierItems,
    ocrModels: state.database.ocrModels,
    moduleSort: state.database.modules.sort,
    isLoading: state.system.isLoading,
    token: state.system.token,
    loadingProgress: state.system.loadingProgress,
    showHelpPanel: state.system.showDoc || state.system.showTutorial,
    helpPanelWidth: state.system.helpPanelWidth,
    simpleTutorialOnly: state.system.simpleTutorialOnly,
    operatorOnly: state.system.operatorOnly,
    projectId: state.system.projectId,
    showTags: state.images.tagsImages.length > 0,
    showTagsManager: state.images.showTagsManager,
    showModulesPreview:
      state.database.modules.showPreview &&
      !hasParallelism(state.database.modules.sort),
  };
};

const mapDispatch = {
  imagesUpload,
  setShowTagsManager,
};

const connector = connect(mapStateToProps, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withI18n(connector(PageLayout));
