import React, { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "../../../components/SimpleDialog";
import useI18n from "../../../hooks/useI18n";

const styles = {
  content: {
    maxWidth: 500,
  },
  checkbox: {
    marginTop: 20,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const ClassificationErrorDialog: FC<Props> = ({ open, onClose }) => {
  const [_] = useI18n();
  const actions = [
    <Button onClick={onClose} color="primary">
      {_("close")}
    </Button>,
  ];

  return (
    <Dialog
      open={open}
      onRequestClose={onClose}
      actions={actions}
      title={_("detector_with_classification_training_error_title")}
      contentStyle={styles.content}
    >
      {_("detector_with_classification_training_error_msg")}
    </Dialog>
  );
};

export default ClassificationErrorDialog;
