import React, { FC } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import useI18n from "../hooks/useI18n";

type Props = {
  open: boolean;
};

const DisconnectDialog: FC<Props> = ({ open }) => {
  const [_] = useI18n();
  return (
    <Dialog maxWidth={false} open={open}>
      <DialogTitle sx={{ mb: 0 }}>{_("disconnect_message")}</DialogTitle>
    </Dialog>
  );
};

export default DisconnectDialog;
