import React, { FC, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import ModuleInfo from "./ModuleInfo";
import ModuleInfoHome from "./ModuleInfoHome";
import {
  CODE,
  typeToIcon,
  typeToLabel,
  UNSUPERVISED,
  Modules,
} from "../../../modules";

import anomaly0 from "./screenshots/unsupervised_eggs_pre.png";
import anomaly1 from "./screenshots/unsupervised_eggs_post.png";
import anomaly2 from "./screenshots/unsupervised_musli_pre.png";
import anomaly3 from "./screenshots/unsupervised_musli_post.png";
import anomaly4 from "./screenshots/unsupervised_pills_pre.png";
import anomaly5 from "./screenshots/unsupervised_pills_post.png";
import anomaly6 from "./screenshots/unsupervised_wheels_pre.png";
import anomaly7 from "./screenshots/unsupervised_wheels_post.png";

import classifier1 from "./screenshots/classifier_car_00.png";
import classifier2 from "./screenshots/classifier_car_01.png";
import classifier3 from "./screenshots/classifier_wood00.png";
import classifier4 from "./screenshots/classifier_wood01.png";
import measure1 from "./screenshots/measure_RJ_a00.png";
import parallelism0 from "./screenshots/parallelism_00.png";
import parallelism1 from "./screenshots/parallelism_01.png";
import preprocess0 from "./screenshots/preprocess_rod_00.png";
import preprocess1 from "./screenshots/preprocess_rod_01.png";
import surface0 from "./screenshots/surface_scratch_00.png";
import surface1 from "./screenshots/surface_scratch_01.png";
import surface2 from "./screenshots/surface_tampon_00.png";
import surface3 from "./screenshots/surface_tampon_01.png";
import unifier0 from "./screenshots/unifier_car_01.png";
import unifier1 from "./screenshots/unifier_car_02.png";
import unifier2 from "./screenshots/unifier_car_11.png";
import unifier3 from "./screenshots/unifier_car_12.png";
import detector0 from "./screenshots/detector_knoty_00.png";
import detector1 from "./screenshots/detector_knoty_01.png";
import detector2 from "./screenshots/detector_jello_00.png";
import detector3 from "./screenshots/detector_jello_01.png";
import ocr0 from "./screenshots/ocr_00.png";
import useI18n from "../../../hooks/useI18n";
import { useAppSelector } from "../../../hooks/store";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    maxWidth: 900,
    width: "100%",
  },
  leftPanel: {
    height: 690,
    width: 300,
  },
  module: {
    paddingLeft: theme.spacing(3),
    height: 647,
    overflowY: "auto",
    overflowX: "hidden",
  },
  secondaryAction: {
    right: 0,
    height: "100%",
  },
  buttonAdd: {
    height: 48,
    borderRadius: 0,
    boxShadow: "none",
  },
  title: {
    "&>h2": {
      marginLeft: theme.spacing(2),
    },
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "white",
  },
}));

const items = [
  {
    title: "recognition_methods",
    items: [
      Modules.UNSUPERVISED,
      Modules.CLASSIFIER,
      Modules.DETECTOR,
      Modules.SUPERVISED,
      Modules.MEASURE,
      Modules.OCR,
    ],
  },
  {
    title: "editing_tools",
    items: [Modules.PREPROCESS, Modules.MASK, Modules.UNIFIER],
  },
  {
    title: "enhancements",
    items: [Modules.CODE, Modules.PARALLELISM],
  },
];

const itemsDetail = {
  [Modules.UNSUPERVISED]: {
    info: [
      "dialog_anomaly_0",
      "dialog_anomaly_1",
      "dialog_anomaly_2",
      "dialog_anomaly_3",
    ],
    images: [
      anomaly2,
      anomaly3,
      anomaly4,
      anomaly5,
      anomaly6,
      anomaly7,
      anomaly0,
      anomaly1,
    ],
  },
  [Modules.SUPERVISED]: {
    info: [
      "dialog_supervised_0",
      "dialog_supervised_1",
      "dialog_supervised_2",
      "dialog_supervised_3",
    ],
    images: [surface0, surface1, surface2, surface3],
  },
  [Modules.DETECTOR]: {
    info: ["dialog_detector_0", "dialog_detector_1", "dialog_detector_2"],
    images: [detector0, detector1, detector2, detector3],
  },
  [Modules.CLASSIFIER]: {
    info: ["dialog_classifier_0", "dialog_classifier_1"],
    images: [classifier1, classifier2, classifier3, classifier4],
  },
  [Modules.CODE]: {
    info: ["dialog_code_0", "dialog_code_1"],
    images: [],
  },
  [Modules.MEASURE]: {
    info: ["dialog_measurement_0", "dialog_measurement_1"],
    images: [measure1],
  },
  [Modules.MASK]: {
    info: ["dialog_mask_0"],
    images: [],
  },
  [Modules.UNIFIER]: {
    info: ["dialog_unifier_0", "dialog_unifier_1"],
    images: [unifier0, unifier1, unifier2, unifier3],
  },
  [Modules.PREPROCESS]: {
    info: ["dialog_preprocess_0", "dialog_preprocess_1"],
    images: [preprocess0, preprocess1],
  },
  [Modules.PARALLELISM]: {
    info: ["dialog_parallelism_0", "dialog_parallelism_1"],
    images: [parallelism0, parallelism1],
  },
  [Modules.OCR]: {
    info: ["dialog_ocr_0"],
    images: [ocr0],
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  disableCodeModule?: boolean;
  onAdd: (moduleType: Modules) => void;
};

const AddModuleDialog: FC<Props> = (props: Props) => {
  const { simpleTutorialOnly } = useAppSelector((state) => ({
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  }));
  const [_] = useI18n();
  const [activeModule, setActiveModule] = useState<Modules | null>(null);
  const [tutorialWarning, setTutorialWarning] = useState<boolean>(false);
  const { classes } = useStyles();

  useEffect(() => {
    if (props.open) {
      setActiveModule(null);
    }
  }, [props.open]);

  return <>
    <Dialog
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle classes={{ root: classes.title }}>
        {_("add_module")}
      </DialogTitle>
      <DialogContent>
        <IconButton className={classes.closeBtn} onClick={() => props.onClose()} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
        <Grid container>
          <Grid item>
            <List component="nav" className={classes.leftPanel}>
              {items.map((category, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <Divider />}
                  <ListSubheader component="div" id="nested-list-subheader">
                    {_(category.title)}
                  </ListSubheader>
                  {category.items
                    .filter(
                      (module) => !props.disableCodeModule || module !== CODE
                    )
                    .map((module) => (
                      <ListItem
                        key={module}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveModule(module);
                        }}
                        button
                        selected={activeModule === module}
                      >
                        <ListItemIcon>{typeToIcon(module)}</ListItemIcon>
                        <ListItemText primary={typeToLabel(module)} />
                        {activeModule === module && (
                          <ListItemSecondaryAction
                            className={classes.secondaryAction}
                          >
                            <Button
                              onClick={() => {
                                if (
                                  simpleTutorialOnly &&
                                  module !== UNSUPERVISED
                                ) {
                                  setTutorialWarning(true);
                                } else if (activeModule) {
                                  props.onAdd(activeModule);
                                }
                              }}
                              color="primary"
                              variant="contained"
                              className={classes.buttonAdd}
                            >
                              {_("add")}
                            </Button>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    ))}
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid item className={classes.module} xs>
            {!(activeModule && itemsDetail[activeModule]) ? (
              <ModuleInfoHome />
            ) : (
              <ModuleInfo
                title={typeToLabel(activeModule)}
                data={itemsDetail[activeModule]}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    <Dialog
      maxWidth="xs"
      open={!!tutorialWarning}
      onClose={() => setTutorialWarning(false)}
    >
      <DialogContent>
        <DialogContentText>
          {_("tutorial_add_module_warning")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTutorialWarning(false)}>{_("ok")}</Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default AddModuleDialog;
