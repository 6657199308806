import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { Field, Formik } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, RadioGroup, TextField } from "formik-mui";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import HttpForm from "./actions/HttpForm";
import CmdForm from "./actions/CmdForm";
import TcpForm from "./actions/TcpForm";
import PlcForm from "./actions/PlcForm";
import GpioForm from "./actions/GpioForm";
import { OutputItem } from "../../../store/database/reducer";
import useI18n from "../../../hooks/useI18n";
import MxioForm from "./actions/MxioForm";

type Props = {
  defaultValues: OutputItem | null;
  onClose: () => void;
  enableGPIO?: boolean;
  enableMXIO?: boolean;
  open: boolean;
  onSave: (values: OutputItem) => void;
  cloudSave: boolean;
};

const DetailDialog: FC<Props> = (props) => {
  const [_] = useI18n();
  const { enableGPIO, enableMXIO } = props;
  const initialValues: OutputItem = props.defaultValues || {
    createdAt: Date.now(),
    updatedAt: Date.now(),
    id: Date.now(),
    trigger: "always",
    timeout: 2,
    name: "Output",
    active: true,
    method: "get",
    endPoint: "http://",
    action: "http",
    cmd: "",
    pwd: "",
    contextToStdin: false,
    enableTimeout: false,
    ip: "127.0.0.1",
    port: "",
    message: "",
    waitToResponse: false,
    contextToMessage: false,
    plcValue: "0",
    plcIp: "127.0.0.1",
    plcMessage: "",
    plcPort: 102,
    signalEdge: "rising",
    portNumber: 7,
    setOn: [],
    setOff: [],
    pulse: 0,
    delay: 0,
    pulseActive: false,
    delayActive: false,
  };
  return (
    <Dialog open={props.open}>
      <Formik initialValues={initialValues} onSubmit={props.onSave}>
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Field
                    disabled={false}
                    name="name"
                    label={_("name")}
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        name="active"
                      />
                    }
                    label={_("active")}
                  />
                </Grid>
                <Grid item>
                  <FormLabel component="legend">{_("trigger")}</FormLabel>
                  <Field name="trigger" component={RadioGroup} row>
                    <FormControlLabel
                      value="always"
                      control={<Radio color="primary" />}
                      label={_("always")}
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio color="primary" />}
                      label={_("good")}
                    />
                    <FormControlLabel
                      value="bad"
                      control={<Radio color="primary" />}
                      label={_("bad")}
                    />
                    <FormControlLabel
                      value="busy"
                      control={<Radio color="primary" />}
                      onChange={() => {
                        setFieldValue("trigger", "busy");
                        setFieldValue("action", "mxio");
                      }}
                      label={_("busy")}
                    />
                    <FormControlLabel
                      value="ready"
                      control={<Radio color="primary" />}
                      onChange={() => {
                        setFieldValue("trigger", "ready");
                        setFieldValue("action", "mxio");
                      }}
                      label={_("ready")}
                    />
                  </Field>
                </Grid>

                <Grid item>
                  <FormLabel component="legend">{_("action")}</FormLabel>
                  <Field name="action" component={RadioGroup} row>
                    <FormControlLabel
                      value="http"
                      disabled={
                        values.trigger === "busy" || values.trigger === "ready"
                      }
                      control={<Radio color="primary" />}
                      label="HTTP"
                    />
                    <FormControlLabel
                      value="cmd"
                      disabled={
                        props.cloudSave ||
                        values.trigger === "busy" ||
                        values.trigger === "ready"
                      }
                      control={<Radio color="primary" />}
                      label="CMD"
                    />
                    <FormControlLabel
                      value="tcp"
                      disabled={
                        values.trigger === "busy" || values.trigger === "ready"
                      }
                      control={<Radio color="primary" />}
                      label="TCP"
                    />
                    <FormControlLabel
                      value="plc"
                      disabled={
                        values.trigger === "busy" || values.trigger === "ready"
                      }
                      control={<Radio color="primary" />}
                      label="Siemens S7 PLC"
                    />
                    {enableGPIO && (
                      <FormControlLabel
                        value="gpio"
                        control={<Radio color="primary" />}
                        label="GPIO"
                      />
                    )}
                    {enableMXIO && (
                      <FormControlLabel
                        value="mxio"
                        control={<Radio color="primary" />}
                        label="GPIO"
                      />
                    )}
                  </Field>
                </Grid>

                {values.action === "http" && <HttpForm />}
                {values.action === "cmd" && <CmdForm />}
                {values.action === "tcp" && <TcpForm values={values} />}
                {values.action === "plc" && <PlcForm />}
                {values.action === "gpio" && enableGPIO && <GpioForm />}
                {values.action === "mxio" && enableMXIO && (
                  <MxioForm
                    values={values}
                    busyOrReady={
                      values.trigger === "busy" || values.trigger === "ready"
                    }
                    setFieldValue={setFieldValue}
                  />
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                {_("close")}
              </Button>
              <Button type="submit" color="primary">
                {_("save")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DetailDialog;
