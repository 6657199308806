import React, { Component } from "react";
import TextField from "@mui/material/TextField";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

type State = {
  value: string;
  prevPropsValue: string;
};

export default class NameTextField extends Component<Props, State> {
  state: State = {
    value: this.props.value,
    prevPropsValue: this.props.value,
  };

  timeout: undefined | number;

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value !== state.prevPropsValue) {
      return {
        value: props.value,
        prevPropsValue: props.value,
      };
    }
    return null;
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    clearTimeout(this.timeout);
    e.stopPropagation();
    this.setState({
      value,
    });

    this.timeout = window.setTimeout(() => {
      this.props.onChange(value);
    }, 1000);
  };

  render() {
    const { value, onChange, ...otherProps } = this.props;
    return (
      <TextField
        value={this.state.value}
        onChange={this.handleChange}
        variant="standard"
        {...otherProps}
      />
    );
  }
}
