import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useI18n from "../../hooks/useI18n";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { closeTutorialDialog, startTutorial } from "../../actions/actions";

const StartTutorialDialog = () => {
  const { open } = useAppSelector((state) => ({
    open: state.system.showTutorialDialog,
  }));

  const dispatch = useAnyAppDispatch();

  const [_] = useI18n();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const handleClickStart = () => {
    dispatch(startTutorial(checkboxRef.current?.checked ?? false));
  };
  const handleClickClose = () => {
    dispatch(closeTutorialDialog(checkboxRef.current?.checked ?? false));
  };

  return (
    <Dialog onClose={handleClickClose} open={open}>
      <DialogTitle>{_("tutorial")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{_("tutorial_dialog_content")}</DialogContentText>
        <FormControlLabel
          control={<Checkbox inputRef={checkboxRef} />}
          label={_("dont_show_this_again")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickClose} color="primary">
          {_("close")}
        </Button>
        <Button onClick={handleClickStart} color="primary">
          {_("start_tutorial")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartTutorialDialog;
