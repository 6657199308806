import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import flowImg from "./flow.png";
import useI18n from "../../../hooks/useI18n";

const useStyles = makeStyles()((theme) => ({
  leftIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {},
  infoWrap: {
    backgroundColor: grey[100],
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  img: {
    marginTop: theme.spacing(2),
    width: 180,
    margin: "0 auto",
    display: "block",
  },
}));

const ModuleInfo = () => {
  const { classes } = useStyles();
  const [_] = useI18n()

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {_("flow_principle")}
      </Typography>
      <Typography gutterBottom>
        {_("flow_desc")}
      </Typography>
      <div>
        <div className={classes.infoWrap}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            alignItems="center"
            className={classes.info}
          >
            <Grid item>
              <Avatar className={classes.leftIcon}>
                <ArrowLeftIcon />
              </Avatar>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography variant="h6" gutterBottom>
                {_("start_choosing")}
              </Typography>
              <Typography>{_("start_choosing_desc")}</Typography>
            </Grid>
          </Grid>
        </div>
        <img src={flowImg} className={classes.img} alt="flow example" />
      </div>
    </>
  );
};

export default ModuleInfo;
