/* eslint-disable react/prop-types */
import React, { Component } from "react";
import ImageDetail, { Props as ImageDetailProps } from "./ImageDetail";
import HelperDialog from "../HelperDialog";
import ImageList, {
  Props as ImageListProps,
} from "../ImageList/ImageListContainer";
import {
  CON_MIN_HEIGHT_HEADER_TOOLPANEL,
  CON_MIN_HEIGHT_HEADER_TOOLPANEL_PADDING,
  PANEL_WIDTH,
} from "../../theme";
import {
  getImageObjects,
  getImageWithDataFromSrc,
} from "../../actions/ImageService";
import withI18n from "../../utils/withI18n";
import {
  GetImageParams,
  Image,
  ImageSize,
  Line,
  Rectangle,
} from "../../types/common";
import { Flow } from "../../types/flow";
import { connect, ConnectedProps } from "react-redux";
import { GlobalState } from "../../store/createStore";
import Loader from "../Loader/Loader";
import { HelperContext } from "../../layouts/PageLayout/PageLayout";

const mapStateToProps = (state: GlobalState) => {
  return {
    renameImage: !!state.images.renameImage,
  };
};

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles = {
  wrap: {
    flexFlow: "row",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "stretch",
    position: "relative",
  },
  imageDetailWrap: {},
} as const;

type Props = PropsFromRedux & {
  getCurrentRectangles?: (image: Image) => Rectangle[];
  getAddedCurrentRectangles?: (image: Image) => Rectangle[];
  getImageSrc?: (imageId: number, hiddenMarkers: boolean) => string;
  getInfo?: ({ filtered, all }: { filtered: number; all: number }) => {
    text: string;
    title: string;
  };
  listHeight?: number | string;
  detailHeight?: number | string;
  moduleId: number | null;
  onChangeRectangles?: (
    imageId: number,
    rectangles: Rectangle[],
    imgSize: { width: number; height: number }
  ) => void;
  onSelectImage?: (image: Image) => void;
  onSelectRectangle?: (rectangle: Rectangle | null) => void;
  readOnly?: boolean;
  showError?: boolean;
  showLoader?: boolean;
  disabledKeyListener?: boolean;
  hideRectangles?: boolean;
  showClassification?: boolean;
  showSecondImage?: boolean;
  lines?: Line[];
  onChangeLines?: (lines: Line[]) => void;
  formatRectangles?: (otem: Rectangle) => Rectangle;
  t: (key: string) => string;
  withMask?: boolean;
  includeLast?: boolean;
  children?: JSX.Element;
  onReceiveImgSize?: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
  rectanglesFlow?: Flow;
  ImageDetailProps?: Partial<ImageDetailProps>;
  ImageListProps?: Partial<ImageListProps>;
  childrenOffset?: number;
};

type State = {
  currentImage: any | null;
  showHelper: boolean;
  hiddenMarkers: boolean;
  rectangles: Rectangle[] | null;
  lines: any[] | undefined;
  showError: boolean;
  imageData: { size: ImageSize; url: string; originalUrl: string } | null;
  loadingImageDataUrl: string | null;
};

class ImageListWithMarkers extends Component<Props, State> {
  state: State = {
    currentImage: null,
    showHelper: false,
    hiddenMarkers: false,
    rectangles: null,
    lines: undefined,
    showError: false,
    imageData: null,
    loadingImageDataUrl: null,
  };

  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  lastFlow?: Flow;

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.getAddedCurrentRectangles !==
      this.props.getAddedCurrentRectangles
    ) {
      this.getRectanglesFromImage(this.state.currentImage);
    }
    if (this.lastFlow) {
      const flow =
        this.props.rectanglesFlow ??
        this.context.helper.getSettings({
          moduleId: prevProps.moduleId,
          includeLast: prevProps.includeLast,
        });
      if (JSON.stringify(this.lastFlow) !== JSON.stringify(flow)) {
        this.getRectanglesFromImage(this.state.currentImage);
      }
    }
    const currentImageUrl = this.getDetailImageSrc(this.state.currentImage?.id);
    if (
      this.state.currentImage &&
      currentImageUrl !== this.state.imageData?.originalUrl &&
      this.state.loadingImageDataUrl !== currentImageUrl
    ) {
      this.getImageData(currentImageUrl);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("resize", this.handleResize);
    if (this.state.imageData?.url.startsWith("blob:")) {
      URL.revokeObjectURL(this.state.imageData.url);
    }
  }

  getRectanglesFromImage(currentImage: Image | null) {
    if (!currentImage) {
      return;
    }
    const flow =
      this.props.rectanglesFlow ??
      this.context.helper.getSettings({
        moduleId: this.props.moduleId,
        includeLast: this.props.includeLast,
      });
    this.lastFlow = flow;

    getImageObjects(currentImage.id, flow, this.context.helper.token).then(
      (response) => {
        this.setState({
          rectangles: (this.props.formatRectangles
            ? response.rectangles.map(this.props.formatRectangles)
            : response.rectangles
          ).map((i) => ({ ...i, disabled: true })),
          lines: response.lines,
          showError: response.error,
        });
      }
    );
  }

  handleResize = () => {
    this.forceUpdate();
  };

  handleKeyPress = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 72 && !this.state.hiddenMarkers) {
      e.stopPropagation();
      this.setState({ hiddenMarkers: true });
    }
  };

  handleKeyUp = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 72 && this.state.hiddenMarkers) {
      e.stopPropagation();
      this.setState({ hiddenMarkers: false });
    }
  };

  handleOpenHelper = () => {
    this.setState({
      showHelper: true,
    });
  };

  getDetailImageSrc = (imageId: number) => {
    if (this.props.getImageSrc) {
      return this.props.getImageSrc(imageId, this.state.hiddenMarkers);
    }
    return this.context.helper.getImagePath({
      imageId: imageId,
      moduleId: this.props.moduleId,
      includeLast: this.props.includeLast,
      withMask: this.props.withMask && !this.state.hiddenMarkers,
    });
  };

  showRectangles = () =>
    !this.props.hideRectangles && !this.state.hiddenMarkers;

  getImageData = (imageSrc: string) => {
    if (this.state.imageData?.url.startsWith("blob:")) {
      URL.revokeObjectURL(this.state.imageData.url);
    }
    this.setState({
      loadingImageDataUrl: imageSrc,
      imageData: null,
    });

    getImageWithDataFromSrc(imageSrc).then((response) => {
      this.props.onReceiveImgSize?.(response.data.imageSize);
      this.setState({
        loadingImageDataUrl: null,
        imageData: {
          size: response.data.imageSize,
          url: response.imageUrl,
          originalUrl: imageSrc,
        },
      });
    });
  };

  render() {
    const _ = this.props.t;
    let currentRectangles =
      this.state.currentImage && this.props.getCurrentRectangles
        ? this.props.getCurrentRectangles(this.state.currentImage)
        : this.props.formatRectangles && this.state.rectangles
        ? this.state.rectangles.map((i) =>
            (this.props.formatRectangles as (r: Rectangle) => Rectangle)(i)
          )
        : this.state.rectangles;

    const lines =
      this.props.lines !== undefined ? this.props.lines : this.state.lines;

    if (this.props.getAddedCurrentRectangles && currentRectangles) {
      currentRectangles = [
        ...currentRectangles,
        ...this.props.getAddedCurrentRectangles(this.state.currentImage),
      ];
    }

    const rectanglesLoading = this.showRectangles() && !currentRectangles;
    return (
      <>
        <ImageList
          disabledKeyListener={this.props.disabledKeyListener}
          activeId={this.state.currentImage?.id}
          onSelect={(currentImage) => {
            this.setState({
              currentImage,
              rectangles: null,
              showError: false,
              //              imageData: null,
            });
            this.getRectanglesFromImage(currentImage);
            if (this.props.onSelectImage) {
              this.props.onSelectImage(currentImage);
            }
          }}
          getInfo={({ items, filteredItems }) => {
            if (this.props.getInfo) {
              return this.props.getInfo({
                filtered: filteredItems.length,
                all: items.length,
              });
            }
            return {
              title: _("image_list_info_help"),
              text: `${filteredItems.length} / ${items.length}`,
            };
          }}
          getImageSrc={(item: Image): GetImageParams => ({
            imageId: item.id,
            moduleId: this.props.moduleId,
            includeLast: this.props.includeLast,
            withMask: this.props.withMask,
          })}
          getImageSrc2={
            this.props.showSecondImage
              ? (item) => {
                  return {
                    imageId: item.id,
                    moduleId: this.props.moduleId,
                    includeLast: true,
                    withMask: this.props.withMask,
                  };
                }
              : undefined
          }
          height={this.props.listHeight || CON_MIN_HEIGHT_HEADER_TOOLPANEL}
          imgSize={this.state.imageData?.size ?? null}
          {...this.props.ImageListProps}
        >
          <div
            style={{
              height:
                this.props.detailHeight ||
                CON_MIN_HEIGHT_HEADER_TOOLPANEL_PADDING,
              ...styles.wrap,
            }}
          >
            {this.state.currentImage && (
              <div
                style={{
                  width: this.props.children
                    ? `calc(100% - ${
                        this.props.childrenOffset || PANEL_WIDTH
                      }px)`
                    : "100%",
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                }}
              >
                {!this.state.imageData?.size && <Loader fadeIn relative />}
                {this.state.imageData?.size && (
                  <ImageDetail
                    imgSize={this.state.imageData?.size}
                    onChangeLines={this.props.onChangeLines}
                    disabledKeyListener={
                      this.props.disabledKeyListener || this.props.renameImage
                    }
                    key={`${this.state.currentImage.id}`}
                    imageId={
                      this.state.currentImage ? this.state.currentImage.id : -1
                    }
                    getImageSrc={() => this.state.imageData?.url ?? ""}
                    imageLabel={this.state.currentImage.label}
                    moduleId={this.props.moduleId}
                    onChangeRectangles={(imageId, rectangles) => {
                      if (
                        this.props.onChangeRectangles &&
                        this.state.imageData
                      ) {
                        this.props.onChangeRectangles(
                          imageId,
                          rectangles,
                          this.state.imageData.size
                        );
                      }
                    }}
                    lines={lines}
                    onOpenHelper={this.handleOpenHelper}
                    onSelectRectangle={(rectangle) => {
                      if (this.props.onSelectRectangle) {
                        this.props.onSelectRectangle(rectangle);
                      }
                    }}
                    readOnly={
                      this.props.readOnly ||
                      this.props.showLoader ||
                      this.props.showError ||
                      this.state.hiddenMarkers
                    }
                    rectangles={
                      !this.showRectangles() ? [] : currentRectangles || []
                    }
                    showError={this.props.showError || this.state.showError}
                    showLoader={this.props.showLoader || rectanglesLoading}
                    {...this.props.ImageDetailProps}
                  />
                )}
              </div>
            )}
            {this.props.children}
          </div>
        </ImageList>
        <HelperDialog
          showUndo={!this.props.readOnly}
          showClassification={this.props.showClassification}
          open={this.state.showHelper}
          onHide={() => this.setState({ showHelper: false })}
        />
      </>
    );
  }
}

export default connector(withI18n(ImageListWithMarkers));
