import React, { FC } from "react";
import { LineChart, Line, XAxis, Tooltip } from "recharts";
import { Histrogram } from "../../../../actions/ImageService";

type Props = {
  data: Histrogram;
};

const Histogram: FC<Props> = ({ data }) => {
  const d = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 256; i++) {
    d.push({
      name: i,
      value: data[i] || 0,
    });
  }
  return (
    <LineChart width={200} height={100} data={d} margin={{}}>
      <XAxis dataKey="name" />
      <Tooltip />
      <Line dot={false} type="monotone" dataKey="value" stroke="#82ca9d" />
    </LineChart>
  );
};

export default Histogram;
