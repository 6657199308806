import React, { FC } from "react";
import CameraIcon from "@mui/icons-material/CameraAlt";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Module from "./Module";
import AddButton from "./AddButton";
import EmptyModule from "./EmtyModule";
import type * as Flow from "../../../types/flow";
import { Modules } from "../../../modules";

type Path = number[];

// Sorry :/
const getColl = (items: (Flow.Any | Flow.AnyDebugger)[]): number =>
  Math.max(
    ...[
      ...items.map((i) =>
        i.type === "PARALLELISM"
          ? i.branches.map(getColl).reduce((a, b) => a + b, 0)
          : 1
      ),
      1,
    ]
  );

const Arrow = ({
  items,
  down,
}: {
  items: Flow.Parallelism;
  down?: boolean;
}) => {
  let colls = getColl([items]);
  colls = colls === -Infinity ? 2 : colls;
  let prevColls = 0;
  return (
    <svg
      height="20"
      width="100%"
      style={{
        overflow: "visible",
        width: 240 * colls,
        ...(down
          ? { position: "absolute", bottom: 0 }
          : { position: "absolute", top: 0 }),
      }}
    >
      {items.branches.map((i, index) => {
        let currentColls = getColl(i);
        currentColls = currentColls === -Infinity ? 1 : currentColls;
        const x2 = ((currentColls / 2 + prevColls) / colls) * 100;
        prevColls += currentColls;
        return (
          <line
            x1="50%"
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            y1={down ? 20 : "0"}
            x2={`${x2}%`}
            y2={down ? 0 : "20"}
            style={{ stroke: "#999", strokeWidth: 2 }}
          />
        );
      })}
    </svg>
  );
};

const builderStyles = {
  cameraWrap: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2,
    marginTop: -4,
    position: "relative",
    marginBottom: 18,
  },
  cameraBg: {
    background: "white",
    width: 60,
    height: 44,
    position: "absolute",
    top: 0,
  },
  exitBg: {
    background: "white",
    width: 60,
    height: 57,
    position: "absolute",
    top: 3,
  },
  icon: {
    fill: "currentcolor",
    width: 50,
    height: 50,
    position: "relative",
    color: "rgb(153, 153, 153)",
  },
  arrowDownWrap: {
    position: "absolute",
    top: 7,
  },
} as const;

type Props = {
  items: Flow.Any[];
  onAdd: (path: Path, type: Modules, index: number) => void;
  onAddBranch: (path: Path, index: number) => void;
  onDelete: (path: Path, index: number) => void;
  onMoveUp: (path: Path, index: number) => void;
  onMoveDown: (path: Path, index: number) => void;
  onMoveLeft: (path: Path, index: number) => void;
  onMoveRight: (path: Path, index: number) => void;
  path: Path;
  onChangeLabel: (id: number, text: string) => void;
  onChangeNote: (id: number, text: string) => void;
  isFirst?: boolean;
  disableCodeModule?: boolean;
  isMostLeft: boolean;
  isMostRight: boolean;
  onDeleteBranch: (path: Path, index: number, branchIndex: number) => void;
};

const Builder: FC<Props> = ({
  items,
  onAdd,
  onAddBranch,
  onDelete,
  onMoveUp,
  onMoveDown,
  onMoveLeft,
  onMoveRight,
  path,
  onChangeLabel,
  onChangeNote,
  isFirst,
  disableCodeModule,
  isMostLeft,
  isMostRight,
  onDeleteBranch,
}) => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "inline-flex",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#999",
          border: "1px solid white",
          borderWidth: "0 1px",
          width: 4,
          height: "calc(100% - 5px)",
          left: "calc(50% - 2px)",
        }}
      />
      {isFirst && (
        <div style={builderStyles.cameraWrap}>
          <div style={builderStyles.cameraBg} />
          <CameraIcon style={builderStyles.icon} />
        </div>
      )}
      {items.map((item, index) => {
        if (item.type === "PARALLELISM") {
          return (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 83,
                marginBottom: 20,
                position: "relative",
                padding: "20px 0px",
                backgroundColor: "white",
              }}
            >
              <div style={{ display: "flex" }}>
                <Arrow items={item} />
                {(index === 0 || items[index - 1].type !== "PARALLELISM") && (
                  <AddButton
                    disableCodeModule={disableCodeModule}
                    onSelect={(type) => onAdd(path, type, index)}
                    style={
                      {
                        position: "absolute",
                        top: -78,
                        left: "calc(50% - 20px)",
                      } as const
                    }
                  />
                )}
                <AddButton
                  branch
                  numberOfBranches={item.branches.length}
                  disableCodeModule={disableCodeModule}
                  onAddBranch={() => onAddBranch(path, index)}
                  onDeleteBranch={(branchIndex) =>
                    onDeleteBranch(path, index, branchIndex)
                  }
                  onDelete={() => onDelete(path, index)}
                  style={{
                    position: "absolute",
                    top: -20,
                    left: "calc(50% - 20px)",
                  }}
                />
                {item.branches.map((i, indexBranch) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div style={{ position: "relative" }} key={indexBranch}>
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "#999",
                        border: "1px solid white",
                        borderWidth: "0 1px",
                        width: 4,
                        height: "calc(100%)",
                        left: "calc(50% - 2px)",
                      }}
                    />
                    <Builder
                      onChangeLabel={onChangeLabel}
                      onChangeNote={onChangeNote}
                      onDelete={onDelete}
                      onAdd={onAdd}
                      onMoveDown={onMoveDown}
                      onMoveUp={onMoveUp}
                      onMoveLeft={onMoveLeft}
                      onMoveRight={onMoveRight}
                      onAddBranch={onAddBranch}
                      path={[...path, index, indexBranch]}
                      isMostLeft={indexBranch === 0}
                      isMostRight={indexBranch === item.branches.length - 1}
                      onDeleteBranch={onDeleteBranch}
                      items={i}
                    />
                  </div>
                ))}
                {(index === items.length - 1 ||
                  items[index + 1].type === "PARALLELISM") && (
                  <AddButton
                    disableCodeModule={disableCodeModule}
                    onSelect={(type) => onAdd(path, type, index + 1)}
                    style={{
                      position: "absolute",
                      bottom: -20,
                      left: "calc(50% - 20px)",
                    }}
                  />
                )}
                <Arrow items={item} down />
              </div>
            </div>
          );
        }
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 5,
              position: "relative",
            }}
            key={index}
          >
            <div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#999",
                  border: "1px solid white",
                  borderWidth: "0 1px",
                  width: 4,
                  height: "calc(100% - 5px)",
                  left: "calc(50% - 2px)",
                }}
              />
              <AddButton
                disableCodeModule={disableCodeModule}
                onSelect={(type) => onAdd(path, type, index)}
              />
              <Module
                isFirst={!!(index === 0 && isFirst)}
                isLast={!!(index === items.length - 1 && isFirst)}
                label={item.label}
                note={item.note}
                onChangeLabel={(label) => onChangeLabel(item.id, label)}
                onChangeNote={(label) => onChangeNote(item.id, label)}
                type={item.type}
                onRemove={() => onDelete(path, index)}
                onMoveUp={() => onMoveUp(path, index)}
                onMoveDown={() => onMoveDown(path, index)}
                onMoveLeft={() => onMoveLeft(path, index)}
                onMoveRight={() => onMoveRight(path, index)}
                isMostLeft={isMostLeft}
                isMostRight={isMostRight}
              />
              {(index === items.length - 1 ||
                items[index + 1].type === "PARALLELISM") &&
                !(
                  items[index + 1] && items[index + 1].type === "PARALLELISM"
                ) && (
                  <AddButton
                    disableCodeModule={disableCodeModule}
                    onSelect={(type) => onAdd(path, type, index + 1)}
                  />
                )}
            </div>
          </div>
        );
      })}
      {items.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 5,
            position: "relative",
          }}
        >
          <div>
            <div
              style={{
                position: "absolute",
                backgroundColor: "#999",
                border: "1px solid white",
                borderWidth: "0 1px",
                width: 4,
                height: "calc(100% - 5px)",
                left: "calc(50% - 2px)",
              }}
            />
            <AddButton
              disableCodeModule={disableCodeModule}
              onSelect={(type) => onAdd(path, type, items.length)}
            />
            <EmptyModule />
          </div>
        </div>
      )}
      {isFirst && (
        <div
          style={{
            ...builderStyles.cameraWrap,
            marginTop: 13,
            marginBottom: 0,
          }}
        >
          <div style={builderStyles.exitBg} />
          <div style={{ ...builderStyles.arrowDownWrap, top: -14 }}>
            <ArrowDownIcon style={{ color: "rgb(153, 153, 153)" }} />
          </div>
          <svg
            className="icon icon-exit"
            style={{ left: 7, ...builderStyles.icon }}
            viewBox="0 0 32 32"
          >
            <path d="M24 20v-4h-10v-4h10v-4l6 6zM22 18v8h-10v6l-12-6v-26h22v10h-2v-8h-16l8 4v18h8v-6z" />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Builder;
