import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from 'tss-react/mui';
import { green, red } from "@mui/material/colors";
import useI18n from "../../../hooks/useI18n";
import { FilterTypes } from "./types";

const formatDuration = (seconds: number) => {
  return Math.round(seconds * 1000);
};

const useStyles = makeStyles()({
  tableMatrix: {
    borderCollapse: "collapse",
    "& td, & th": {
      padding: "0.3rem",
    },
  },
  tableInfo: {
    borderCollapse: "collapse",
    "& td, & th": {
      padding: "0.3rem",
      border: "1px solid #dee2e6",
    },
    "& th": {
      textAlign: "right",
    },
  },
  tdBorder: {
    border: "2px solid #dee2e6",
  },
  filterTd: {
    "&:hover": {
      background: "#dee2e6",
      cursor: "pointer",
    },
  },
  redColor: {
    color: red[500],
  },
  greenColor: {
    color: green[500],
  },
});

type Props = {
  data: {
    truePositive: number,
    trueNegative: number,
    falsePositive: number,
    falseNegative: number,
    recall: number,
    precision: number,
    time: {
      min: number,
      max: number,
      average: number
    },
  },
  evaluationType: boolean
  onSelect: (type: FilterTypes) => void,
}

const Summary: FC<Props> = (props) => {
  const { classes, cx } = useStyles()
  const [_] = useI18n()
  const {
    truePositive,
    trueNegative,
    falsePositive,
    falseNegative,
    recall,
    precision,
    time,
  } = props.data;

  const { evaluationType } = props;

  const handleClick = (type: FilterTypes) => () => {
    props.onSelect(type);
  };

  return (
    <Grid container spacing={2}>
      {evaluationType && (
        <Grid item>
          <table
            className={classes.tableMatrix}
            cellSpacing="0"
            cellPadding="0"
          >
            <thead>
              <tr>
                <th colSpan={2} />
                <th colSpan={2}>{_("predicted")}</th>
              </tr>
              <tr>
                <th />
                <th />
                <th className={classes.greenColor}>{_("good")}</th>
                <th className={classes.redColor}>{_("bad")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={2} style={{ verticalAlign: "middle" }}>
                  {_("actual")}
                </th>
                <th scope="row" className={classes.greenColor}>
                  {_("good")}
                </th>
                <td
                  onClick={handleClick("truPositive")}
                  className={cx(classes.tdBorder, classes.filterTd)}
                >
                  {truePositive}
                </td>
                <td
                  onClick={handleClick("falseNegative")}
                  className={cx(classes.tdBorder, classes.filterTd)}
                >
                  {falseNegative}
                </td>
              </tr>
              <tr>
                <th className={classes.redColor} scope="row">
                  {_("bad")}
                </th>
                <td
                  onClick={handleClick("falsePositive")}
                  className={cx(classes.tdBorder, classes.filterTd)}
                >
                  {falsePositive}
                </td>
                <td
                  onClick={handleClick("trueNegative")}
                  className={cx(classes.tdBorder, classes.filterTd)}
                >
                  {trueNegative}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )}
      <Grid item>
        <table
          className={classes.tableInfo}
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {evaluationType && (
              <>
                <tr>
                  <th scope="row">{_("recall")}</th>
                  <td>{recall.toFixed(2)}</td>
                </tr>
                <tr>
                  <th scope="row">{_("precision")}</th>
                  <td>{precision.toFixed(2)}</td>
                </tr>
              </>
            )}
            <tr>
              <th scope="row">{_("average_processing time")}</th>
              <td>
                {formatDuration(time.average)} {_("ms")}
              </td>
            </tr>
            <tr>
              <th scope="row">{_("min_processing_time")}</th>
              <td>
                {formatDuration(time.min)} {_("ms")}
              </td>
            </tr>
            <tr>
              <th scope="row">{_("max_processing_time")}</th>
              <td>
                {formatDuration(time.max)} {_("ms")}
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default Summary;
