const colors = [
  "#3f51b5",
  "#f44336",
  "#ffc107",
  "#8bc34a",
  "#00bcd4",
  "#9c27b0",
  "#e91e63",
  "#607d8b",
];

export default colors;
