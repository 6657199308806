import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ImageList from "./ImageList";
import Dialog from "../../../components/SimpleDialog";
import useI18n from "../../../hooks/useI18n";
import { Image } from "../../../types/common";

const styles = {
  dialogContent: {
    width: "calc(100% - 200px)",
    maxWidth: "none",
    maxHeight: "none",
    height: "100%",
  },
  imageItem: {
    flex: "0 20%",
    padding: 10,
  },
  imageItemImg: {
    maxWidth: "100%",
    cursor: "pointer",
  },
} as const;

type Props = {
  open: boolean;
  onSelect: (imageIds: number[]) => void;
  onClose: () => void;
  multiselect?: boolean;
  images: Image[];
  moduleId: number;
};

const SelectImageDialog = (props: Props) => {
  const [_] = useI18n();
  const [selected, setSelected] = useState<number[]>([]);
  const actions = [];

  useEffect(() => {
    if (props.open) {
      setSelected([]);
    }
  }, [props.open]);

  if (selected.length > 0) {
    actions.push(
      <Button color="primary" onClick={() => props.onSelect(selected)}>
        {_("submit")}
      </Button>
    );
  }

  actions.push(
    <Button color="primary" onClick={props.onClose}>
      {_("close")}
    </Button>
  );

  return (
    <Dialog
      title={_("select_template_image")}
      actions={actions}
      contentStyle={styles.dialogContent}
      open={props.open}
      onRequestClose={props.onClose}
    >
      <ImageList
        multiselect={props.multiselect}
        selected={selected}
        onChangeSelected={setSelected}
        images={props.images}
        onSelect={(imageId) => props.onSelect([imageId])}
        getImageSrc={(image) => ({
          imageId: image.id,
          moduleId: props.moduleId,
        })}
      />
    </Dialog>
  );
};

export default SelectImageDialog;
