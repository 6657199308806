import { connect, ConnectedProps } from "react-redux";
import CameraView from "./components/CameraView";
import { changeOperatorInput } from "../../actions/actions";
import { GlobalState } from "../../store/createStore";

const mapStateToProps = (state: GlobalState) => {
  return {
    showCameraFiltered: state.system.showCameraFiltered,
    cameraRunning: state.system.cameraRunning,
    runningConfig: state.database.running,
    operatorInput: state.database.operatorInput,
    enableCustomOperator: state.database.camera.customOperator.enable,
    customOperatorItems: state.database.camera.customOperator.items,
    operatorViewCounter: state.database.operatorViewCounter,
  };
};

const connector = connect(mapStateToProps, { changeOperatorInput });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CameraView);
