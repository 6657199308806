import { connect, ConnectedProps } from "react-redux";
import InputView from "./components/InputView";
import {
  cameraAutoAdjust,
  cameraSetConfigFile,
  cameraSetInputType,
  setCurrentCamera,
  turnOffCamera,
  turnOnCamera,
  setProvider,
  setRunning,
} from "../../actions/actions";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{}>
) => {
  return {
    history: ownProps.history,
    showCameraFiltered: state.system.showCameraFiltered,
    cameraRunning: state.system.cameraRunning,
    runningConfig: state.database.running,
    cameraStatus: state.database.camera.cameraStatus,
    provider: state.database.camera.provider,
    currentCamera: state.database.camera.currentCamera,
    enableGenicam: state.system.enableGenicam,
    disableProcess: !state.system.isFlowLightOnly && state.system.lightLicense,
    cameraIsRunning: state.database.camera.cameraIsRunning,
    language: state.system.lang, // refresh language,
    restriected: state.system.restricted,
  };
};

const mapDispatchToProps = {
  turnOnCamera,
  turnOffCamera,
  cameraSetConfigFile,
  cameraSetInputType,
  setCurrentCamera,
  cameraAutoAdjust,
  setProvider,
  setRunning,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(InputView);
