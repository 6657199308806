import React from "react";
import FlowView from "./components/FlowView";
import { addErrorMessage } from "../../store/system/reducer";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";
import { useHistory } from "react-router-dom";

const FlowContainer = () => {
  const state = useAppSelector((state) => ({
    moduleItems: state.database.modules.items,
    sortItems: state.database.modules.sort,
    disableCodeModule: state.system.disableCodeModule,
    allModules: state.system.license.allModules,
  }));
  const dispatch = useAnyAppDispatch();
  const history = useHistory();

  return (
    <FlowView
      history={history}
      addErrorMessage={(message: string) => dispatch(addErrorMessage(message))}
      {...state}
    />
  );
};

export default FlowContainer;
