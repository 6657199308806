import React, { Component } from "react";
import AddIcon from "@mui/icons-material/Add";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fab from "@mui/material/Fab";
import AddModuleDialog from "../../Modules/components/AddModuleDialog";
import withI18n from "../../../utils/withI18n";
import { Modules } from "../../../modules";

const styles = {
  wrap: {
    textAlign: "center",
    zIndex: 2,
    position: "relative",
  },
} as const;

type Props = {
  onSelect?: (moduleType: Modules) => void;
  numberOfBranches?: number;
  onDelete?: () => void;
  onAddBranch?: () => void;
  onDeleteBranch?: (index: number) => void;
  branch?: boolean;
  disableCodeModule?: boolean;
  style?: React.CSSProperties;
  t: (key: string) => string;
};

type State = {
  open: boolean;
  anchorEl: HTMLElement | null;
};

class AddButton extends Component<Props, State> {
  state: State = {
    open: false,
    anchorEl: null,
  };

  handleTouchTap = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectModule = (moduleType: Modules) => {
    this.props.onSelect?.(moduleType);
    this.setState({
      open: false,
    });
  };

  handleDelete = () => {
    this.props.onDelete?.();
    this.setState({
      open: false,
    });
  };

  handleDeleteBranch = (index: number) => {
    this.props.onDeleteBranch?.(index);
    this.setState({
      open: false,
    });
  };

  handleAddBranch = () => {
    this.props.onAddBranch?.();
    this.setState({
      open: false,
    });
  };

  renderBrachMenuItems() {
    if (!this.props.branch || this.props.numberOfBranches === undefined) {
      return null;
    }
    const _ = this.props.t;
    const items = [
      <MenuItem key="delete" onClick={this.handleDelete}>
        {_("delete")}
      </MenuItem>,
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.props.numberOfBranches; i++) {
      items.push(
        <MenuItem
          disabled={this.props.numberOfBranches <= 2}
          key={i}
          onClick={() => this.handleDeleteBranch(i)}
        >
          {`${_("delete_branch")} ${i + 1}`}
        </MenuItem>
      );
    }
    items.push(
      <MenuItem key="add" onClick={this.handleAddBranch}>
        {_("add_branch")}
      </MenuItem>
    );

    return items;
  }

  render() {
    return (
      <div style={{ ...styles.wrap, ...this.props.style }}>
        <Fab
          size="small"
          color={this.props.branch ? "secondary" : "primary"}
          onClick={this.handleTouchTap}
        >
          {this.props.branch ? <MoreIcon /> : <AddIcon />}
        </Fab>
        <Menu
          open={!!(this.state.open && this.props.branch)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleRequestClose}
        >
          {this.renderBrachMenuItems()}
        </Menu>
        <AddModuleDialog
          disableCodeModule={this.props.disableCodeModule}
          open={!this.props.branch && this.state.open}
          onAdd={this.handleSelectModule}
          onClose={() => this.setState({ open: false })}
        />
      </div>
    );
  }
}

export default withI18n(AddButton);
