/* eslint max-classes-per-file: 0 */
import React, { FC } from "react";
import { maskCreate, maskDelete } from "../../../actions/actions";
import ModelList from "../../../components/templates/ModelList";
import { PropsFromRedux } from "../ListContainer";
import useI18n from "../../../hooks/useI18n";

const MaskListView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  return (
    <ModelList
      moduleId={props.moduleId}
      items={props.items}
      t={_}
      onCreate={maskCreate}
      onSetItem={(item) => {
        props.maskSetImageId({
          moduleId: props.moduleId,
          itemId: item ? item.itemId : null,
        });
      }}
      onClickItem={({ itemId }) =>
        props.history.push(`/modules/mask/${props.moduleId}/${itemId}/`)
      }
      isItemActive={(item) => {
        if (item) {
          if (props.activeImageId) {
            return props.activeImageId === item.itemId;
          }
          return false;
        }
        return !props.activeImageId;
      }}
      onClickDelete={({ itemId }) => {
        maskDelete(itemId);
      }}
      isItemDisabled={(item) => item.itemId === null}
      createTitle={_("mask_new_placeholder")}
    />
  );
};

export default MaskListView;
