import React from "react";
import ModelLearningList from "../../../components/templates/ModelLearningList";
import { classifierDeleteModel } from "../../../actions/actions";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import useI18n from "../../../hooks/useI18n";
import { PropsFromRedux } from "../ModelListContainer";

type Props = PropsFromRedux;

const ModelListView: FC<Props> = (props) => {
  const history = useHistory();
  const [_] = useI18n();
  return (
    <ModelLearningList
      t={_}
      enableGpuSettings
      onlyOneGpu
      gpuDevices={props.gpuDevices}
      maxHeight={props.maxHeight}
      onChangeGpuValue={(values) =>
        props.setGpuSettings(props.module.id, values)
      }
      gpuValue={props.module.gpuSettings || [0]}
      activeModelId={props.activeModelId}
      models={props.models}
      onActive={(model) => {
        props.classifierSetActiveModelId({
          moduleId: props.moduleId,
          modelId: model ? model.modelId : null,
        });
      }}
      showTrt
      onChangeTrt={(model, active) => {
        props.setModelValue(
          "classifierModels",
          model.modelId,
          "tensorrt_auto_optimise",
          active
        );
      }}
      onEditName={props.classifierSetName}
      onDelete={(model) => classifierDeleteModel(model.modelId)}
      onOpen={() => {
        history.push(`/modules/classifier/${props.module.id}/validate/detail/`);
      }}
    />
  );
};

export default ModelListView;
