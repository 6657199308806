import { connect, ConnectedProps } from "react-redux";
import { setModulesPreview } from "../../actions/actions";
import { GlobalState } from "../../store/createStore";
import { hasParallelism, sortModules } from "../../utils/common";
import ModulesView from "./components/ModulesView";

const mapStateToProps = (state: GlobalState, otherProps: { left: boolean }) => {
  const allModels = {
    supervisedModels: state.database.supervisedModels,
    unsupervisedModels: state.database.unsupervisedModels,
    unifierItems: state.database.unifierItems,
    detectorModels: state.database.detectorModels,
    classifierModels: state.database.classifierModels,
    maskItems: state.database.masksItems,
    ocrModels: state.database.ocrModels,
  };
  return {
    moduleItems: state.database.modules.items,
    sortItems: state.database.modules.sort,
    lang: state.system.lang,
    images: state.database.images,
    averageTimes: state.system.averageTimes,
    noImagesQue: state.images.que.length === 0,
    settings: sortModules(
      state.database.modules.sort,
      state.database.modules.items,
      allModels
    ),
    allModels,
    imagesLastId: state.database.imagesLastId ?? 0,
    modelsCounter: state.database.modelsCounter,
    showPreview: state.database.modules.showPreview,
    hasParallelism: hasParallelism(state.database.modules.sort),
    disabledTimes: otherProps.left || state.system.simpleTutorialOnly,
  };
};

const connector = connect(mapStateToProps, {
  setModulesPreview,
});

export type PropsFromRedux = ConnectedProps<typeof connector> & {
  left?: boolean;
};

export default connector(ModulesView);
