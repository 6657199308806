import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import { Field } from "formik";
import { Checkbox, TextField } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { makeStyles } from "tss-react/mui";
import useI18n from "../../../../hooks/useI18n";
import { OutputItem } from "../../../../store/database/reducer";
import { grey } from "@mui/material/colors";

const PORTS = Array.from(Array(16).keys()).map((i) => 16 - i);

const useStyles = makeStyles()({
  textField: {
    width: 100,
  },
  table: {
    backgroundColor: grey[100],
    "& tr>td:first-of-type": {
      textAlign: "right",
    },
    "& tr:first-of-type": {
      textAlign: "center",
    },
  },
});

type Props = {
  busyOrReady?: boolean;
  setFieldValue: (
    field: keyof OutputItem,
    value: any,
    shouldValidate?: boolean
  ) => void;
  values: OutputItem;
};

const MxioForm: FC<Props> = (props) => {
  const [_] = useI18n();
  const { classes } = useStyles();
  return (
    <>
      {!props.busyOrReady && (
        <>
          <Grid item>
            <FormLabel component="legend">{_("Delay & Pulse")}</FormLabel>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    name="delayActive"
                  />
                }
                label={_("DELAY: Wait before changing the output")}
              />
            </Grid>
            <Grid
              item
              sx={{
                visibility: props.values.delayActive ? "visibility" : "hidden",
              }}
            >
              <Field
                name="delay"
                label="Length (ms)"
                margin="dense"
                variant="outlined"
                width="100"
                component={TextField}
                inputProps={{
                  type: "number",
                  min: 0,
                  step: 1,
                }}
                size="small"
                className={classes.textField}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Field
                    type="checkbox"
                    component={Checkbox}
                    name="pulseActive"
                  />
                }
                label={_("PULSE: Change the output then change it back")}
              />
            </Grid>
            <Grid
              item
              sx={{
                visibility: props.values.pulseActive ? "visibility" : "hidden",
              }}
            >
              <Field
                name="pulse"
                label="Length (ms)"
                margin="dense"
                variant="outlined"
                component={TextField}
                inputProps={{
                  type: "number",
                  min: 0,
                  step: 1,
                }}
                size="small"
                className={classes.textField}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid item>
        <FormLabel component="legend">{_("Set outputs")}</FormLabel>
      </Grid>
      <Grid item>
        <table className={classes.table}>
          <thead>
            <tr>
              <td />
              {PORTS.map((i) => (
                <td key={i}>{i}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{_("on")}</td>
              {PORTS.map((i) => (
                <td key={i}>
                  <Radio
                    checked={props.values.setOn.includes(i)}
                    onChange={(e) => {
                      if (e.target.checked && !props.values.setOn.includes(i)) {
                        props.setFieldValue(
                          "setOn",
                          props.values.setOn.concat(i)
                        );
                        props.setFieldValue(
                          "setOff",
                          props.values.setOff.filter((j) => j !== i)
                        );
                      }
                    }}
                    name={`${i}`}
                    sx={{ p: 0.1 }}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>{_("off")}</td>
              {PORTS.map((i) => (
                <td key={i}>
                  <Radio
                    checked={props.values.setOff.includes(i)}
                    onChange={(e) => {
                      if (
                        e.target.checked &&
                        !props.values.setOff.includes(i)
                      ) {
                        props.setFieldValue(
                          "setOff",
                          props.values.setOff.concat(i)
                        );
                        props.setFieldValue(
                          "setOn",
                          props.values.setOn.filter((j) => j !== i)
                        );
                      }
                    }}
                    name={`${i}`}
                    sx={{ p: 0.1 }}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>{_("none")}</td>
              {PORTS.map((i) => (
                <td key={i}>
                  <Radio
                    checked={
                      !props.values.setOff.includes(i) &&
                      !props.values.setOn.includes(i)
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.setFieldValue(
                          "setOn",
                          props.values.setOn.filter((j) => j !== i)
                        );
                        props.setFieldValue(
                          "setOff",
                          props.values.setOff.filter((j) => j !== i)
                        );
                      }
                    }}
                    name={`${i}`}
                    sx={{ p: 0.1 }}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Grid>
    </>
  );
};

export default MxioForm;
