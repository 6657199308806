import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "./SimpleDialog";
import useI18n from "../hooks/useI18n";
import { detect as detectBrowser } from "detect-browser";

const UnsupportedBrowserDialog = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem("UnsupportedBrowserShowed")) {
      const browser = detectBrowser();
      if (browser && ["chrome"].indexOf(browser.name) === -1) {
        setShow(true);
      }
    }
  }, []);

  const [_] = useI18n();

  const onClose = () => {
    setShow(false);
    window.localStorage.setItem("UnsupportedBrowserShowed", "true");
  };

  const actions = [
    <Button color="primary" onClick={onClose}>
      {_("ok")}
    </Button>,
  ];

  return (
    <Dialog
      title={_("unsupported_browser_title")}
      open={show}
      actions={actions}
    >
      {_("unsupported_browser_message")}
    </Dialog>
  );
};

export default UnsupportedBrowserDialog;
