import React, { Component } from "react";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Delete";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Confirm from "./Confirm";

const styles = {
  dialog: {
    width: 350,
  },
  key: {
    color: "#bbb",
  },
  editBtn: {
    marginTop: 12,
  },
  annotationBtn: {
    marginTop: 6,
  },
  annotationActions: {
    marginTop: 6,
  },
} as const;

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

type Item<T> = {
  id: T;
  label: string;
  count?: number;
  color?: string;
  color_bgr?: [number, number, number];
};

type Props<T> = {
  onOpenEditClassNames?: () => void;
  disabledEmpty?: boolean;
  isActive?: boolean;
  onClickRemove?: () => void;
  onClickAutoClassifier?: (e: React.MouseEvent) => void;
  onClickSetAll?: () => void;
  emptyAnnotations?: boolean;
  disabledKeyListener?: boolean;
  t: (key: string) => string;
  items: {
    id: T;
    label: string;
    count?: number;
    color?: string;
    color_bgr?: [number, number, number];
  }[];
  value: T | null | undefined;
  onChange: (value: T | null) => void;
};

type State = {
  showDeleteConfirm: boolean;
};

class ClassNameList<T extends number | string> extends Component<
  Props<T>,
  State
> {
  state: State = {
    showDeleteConfirm: false,
  };

  componentDidMount() {
    window.addEventListener("keypress", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.handleKeyPress);
  }

  handleKeyPress = (event: KeyboardEvent) => {
    if (this.props.disabledKeyListener) {
      return;
    }
    const number = parseInt(event.key, 10);
    if (!isNaN(number) && !!this.props.isActive) {
      if (number === 0 && !this.props.disabledEmpty) {
        this.props.onChange(null);
      } else if (
        number !== 0 &&
        this.props.items.length >= number &&
        this.props.items[number - 1].id !== this.props.value
      ) {
        this.props.onChange(this.props.items[number - 1].id);
      }
    }
  };

  handleOpenEditClassNames = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onOpenEditClassNames?.();
  };

  renderLabel(item: Item<T | null>, index: number) {
    const number = index + 1 + (!this.props.disabledEmpty ? -1 : 0);
    const text =
      item.id === 0 || item.count === undefined
        ? item.label
        : `${item.label} (${item.count})`;
    return (
      <>
        <span>
          {text} {number < 10 && <span style={styles.key}> [{number}]</span>}
        </span>
      </>
    );
  }

  render() {
    const { items, value, onOpenEditClassNames } = this.props;
    const _ = this.props.t;
    const allItems = this.props.disabledEmpty
      ? items
      : [{ id: null, label: _("none_classname") }, ...items];
    return (
      <div>
        <RadioGroup value={`${value}`}>
          {allItems.map((item, index) => (
            <FormControlLabel
              control={
                <Radio
                  checked={item.id === value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.props.onChange(item.id);
                    }
                  }}
                  style={{
                    color: hasOwnProperty(item, "color")
                      ? (item.color as string)
                      : undefined,
                  }}
                />
              }
              disabled={!this.props.isActive}
              key={item.id}
              checked
              value={`${item.id}`}
              label={this.renderLabel(item, index)}
            />
          ))}
        </RadioGroup>

        {onOpenEditClassNames && (
          <Button
            fullWidth
            size="small"
            color="secondary"
            style={styles.annotationBtn}
            variant="outlined"
            onClick={this.handleOpenEditClassNames}
          >
            {_("classifier_class_name_manager")}
          </Button>
        )}

        {(this.props.onClickAutoClassifier || this.props.onClickRemove) && (
          <>
            {this.props.onClickSetAll && (
              <Button
                color="secondary"
                fullWidth
                size="small"
                style={styles.annotationBtn}
                variant="outlined"
                onClick={this.props.onClickSetAll}
              >
                {_("include_all")}
              </Button>
            )}

            {this.props.onClickAutoClassifier && (
              <Button
                color="secondary"
                disabled={this.props.items.length < 1}
                style={styles.annotationBtn}
                fullWidth
                size="small"
                variant="outlined"
                onClick={this.props.onClickAutoClassifier}
              >
                {_("smart_sorting")}
              </Button>
            )}
            {this.props.onClickRemove && (
              <Button
                color="secondary"
                disabled={this.props.emptyAnnotations}
                style={styles.annotationBtn}
                fullWidth
                size="small"
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => this.setState({ showDeleteConfirm: true })}
              >
                {_("remove_annotations")}
              </Button>
            )}
          </>
        )}
        <Confirm
          open={this.state.showDeleteConfirm}
          message={_("remove_selected_confirm")}
          onClickNo={() => this.setState({ showDeleteConfirm: false })}
          onClickYes={() => {
            this.setState({ showDeleteConfirm: false });
            this.props.onClickRemove?.();
          }}
        />
      </div>
    );
  }
}

export default ClassNameList;
