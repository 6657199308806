import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateObject, removeObject } from "./utils";
import {
  ClassifierModel,
  CodeItem,
  DetectorModel,
  SupervisedModel,
  UnifierItem,
  UnsupervisedModel,
  AnyModule,
  SortModules,
  MaskItem,
  OcrModel,
} from "./../../types/modules";
import { Image, Tag } from "../../types/common";
import { FilterSettings } from "../../utils/sortFilterImages";
import { AnyElement } from "../../routes/Camera/components/Editor/types";
import { Camera } from "../../routes/Camera/types";

export type PathKeyItem = { [key: string]: number | string };
export type PathItem = string | PathKeyItem | number;
export type Path = Array<PathItem>;

export type OutputItem = {
  trigger: "always" | "good" | "bad" | "busy" | "ready";
  timeout: number;
  name: string;
  active: boolean;
  method: "get" | "post";
  endPoint: string;
  action: "http" | "cmd" | "tcp" | "plc" | "gpio" | "mxio";
  cmd: string;
  pwd: string;
  contextToStdin: boolean;
  enableTimeout: boolean;
  ip: string;
  port: string;
  message: string;
  waitToResponse: boolean;
  contextToMessage: boolean;
  plcValue: "0" | "1";
  plcIp: string;
  plcMessage: string;
  plcPort: number;
  signalEdge: "rising" | "failling";
  portNumber: number;
  createdAt: number;
  updatedAt: number;
  id: number;
  setOn: number[];
  setOff: number[];
  pulse: number;
  delay: number;
  pulseActive: false;
  delayActive: false;
};

export type DatabaseState = {
  classifierModels: ClassifierModel[];
  detectorModels: DetectorModel[];
  supervisedModels: SupervisedModel[];
  unsupervisedModels: UnsupervisedModel[];
  ocrModels: OcrModel[];
  masksItems: MaskItem[];
  unifierItems: UnifierItem[];
  codeItems: CodeItem[];
  images: Image[];
  imagesLastId: number;
  lang: string;
  modules: {
    items: AnyModule[];
    sort: SortModules;
    filter: FilterSettings[];
    showPreview?: boolean;
  };
  camera: {
    provider: "api" | "toshiba" | "basler";
    cameraIsRunning: boolean;
    cameraStatus: string;
    currentCamera: Camera | null;
    customOperator: {
      enable: boolean;
      items: AnyElement[];
    };
  };
  operatorInput: { [key in string]?: string };
  running: {
    save: boolean;
    processing: boolean;
  };
  tags: Tag[];
  statistics: {
    annotations: {
      ok: number[];
      nok: number[];
      include: number[];
    };
    type: "evaluation" | "simple";
  };
  modelsCounter: {
    OCR: number;
    DETECTOR: number;
    CLASSIFIER: number;
    UNSUPERVISED: number;
    SUPERVISED: number;
  };
  outputItems: OutputItem[];
  operatorViewCounter: { [key in number]: number };
};

export type AnyEntity =
  | "classifierModels"
  | "detectorModels"
  | "supervisedModels"
  | "unsupervisedModels"
  | "ocrModels"
  | "unifierItems"
  | "codeItems"
  | "maskItems";

// Define the initial state using that type
const initialState: DatabaseState = {
  classifierModels: [],
  detectorModels: [],
  supervisedModels: [],
  unsupervisedModels: [],
  ocrModels: [],
  masksItems: [],
  unifierItems: [],
  codeItems: [],
  images: [],
  imagesLastId: 0,
  lang: "en",
  modules: {
    items: [],
    sort: [],
    filter: [],
  },
  camera: {
    provider: "api",
    cameraIsRunning: false,
    cameraStatus: "",
    currentCamera: null,
    customOperator: {
      enable: true,
      items: [],
    },
  },
  operatorInput: {},
  running: {
    save: false,
    processing: true,
  },
  tags: [],
  statistics: {
    annotations: {
      ok: [],
      nok: [],
      include: [],
    },
    type: "evaluation",
  },
  modelsCounter: {
    OCR: 0,
    DETECTOR: 0,
    CLASSIFIER: 0,
    UNSUPERVISED: 0,
    SUPERVISED: 0,
  },
  outputItems: [],
  operatorViewCounter: {},
};

export const databaseSlice = createSlice({
  name: "database",
  initialState,
  reducers: {
    init: (state, action: PayloadAction<DatabaseState>) => {
      return { ...initialState, ...action.payload };
    },
    update: (state, action: PayloadAction<{ path: Path; value: any }>) => {
      updateObject(state, action.payload.path, action.payload.value);
    },
    remove: (
      state,
      action: PayloadAction<{ path: Path; id: number | string }>
    ) => {
      removeObject(state, action.payload.path, action.payload.id);
    },
  },
});

export const { init, update, remove } = databaseSlice.actions;

export default databaseSlice.reducer;
