import React, { Component } from "react";
import Toolbar from "@mui/material/Toolbar";
import { TOOLPANEL_HEIGHT } from "../../theme";

const styles = {
  wrap: {
    position: "relative",
    backgroundColor: "rgb(232, 232, 232)",
  },
  toolbar: {
    height: TOOLPANEL_HEIGHT,
    padding: 0,
  },
  firstToolbarGroup: {
    marginLeft: 0,
  },
} as const;

export default class Header extends Component<{ children: React.ReactNode }> {
  getChildInArray() {
    return (
      Array.isArray(this.props.children)
        ? this.props.children
        : [this.props.children]
    ).reduce((sum: any[], item) => {
      if (Array.isArray(item)) {
        return [...sum, ...item];
      }
      return [...sum, item];
    }, []);
  }

  render() {
    return (
      <div style={styles.wrap}>
        <Toolbar style={styles.toolbar} variant="dense">
          {this.getChildInArray()
            .filter((c) => c && c.props && !c.props.rightAllign)
            .map((c, i) => ({ ...c, key: "left" + i }))}
          <div style={{ flexGrow: 1 }} />
          {this.getChildInArray()
            .filter((c) => c && c.props && c.props.rightAllign)
            .map((c, i) => {
              const { rightAllign, ...otherPros } = c.props;
              return { ...c, key: "right" + i, props: otherPros };
            })}
        </Toolbar>
      </div>
    );
  }
}
