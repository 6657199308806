import { connect, ConnectedProps } from "react-redux";
import DetailView from "./components/DetailView";
import { getModule } from "../../utils/common";
import { changePaintRadius } from "../../actions/actions";
import { MaskItem, MaskModule } from "../../types/modules";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string; itemId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as MaskModule;
  const maskItem = state.database.masksItems.find(
    (c) => c.itemId === +ownProps.match.params.itemId
  ) as MaskItem;

  return {
    history: ownProps.history,
    moduleId,
    module,
    maskItem,
  };
};

const connector = connect(mapStateToProps, { changePaintRadius });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DetailView);
