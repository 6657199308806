import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from 'tss-react/mui';
import Grid from "@mui/material/Grid";
import useI18n from "../../../hooks/useI18n";

const useStyles = makeStyles()({
  list: {
    paddingLeft: 25,
    "&>li": {
      listStyleImage:
        'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTBwdCIgaGVpZ2h0PSI5cHQiIHZpZXdCb3g9IjAgMCAxMCA5IiB2ZXJzaW9uPSIxLjEiPgo8ZyBpZD0ic3VyZmFjZTEiPgo8cGF0aCBzdHlsZT0iIHN0cm9rZTpub25lO2ZpbGwtcnVsZTpub256ZXJvO2ZpbGw6cmdiKDAlLDAlLDAlKTtmaWxsLW9wYWNpdHk6MTsiIGQ9Ik0gNS4wODIwMzEgMS40ODQzNzUgTCA0LjQ4ODI4MSAyLjExNzE4OCBMIDYuOTQxNDA2IDQuNzQyMTg4IEwgMS41OTM3NSA0Ljc0MjE4OCBMIDEuNTkzNzUgNS42Nzk2ODggTCA2Ljk0MTQwNiA1LjY3OTY4OCBMIDQuNDg4MjgxIDguMzA0Njg4IEwgNS4xMDkzNzUgOC45Njg3NSBMIDguNjI1IDUuMjEwOTM4IEwgNS4xMDkzNzUgMS40NTcwMzEgWiBNIDUuMDgyMDMxIDEuNDg0Mzc1ICIvPgo8L2c+Cjwvc3ZnPgo=")',
    },
  },
  img: {
    width: "100%",
    display: "block",
  },
});

type Props = {
  title: string;
  data: {
    images: string[];
    info: string[];
  };
};

const ModuleInfo: FC<Props> = ({ data, title }) => {
  const { classes } = useStyles();
  const [_] = useI18n();
  const { info, images = [] } = data;
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <ul className={classes.list}>
        {info.map((text, index) => (
          <li key={index}>{_(text)}</li>
        ))}
      </ul>
      <Grid container spacing={1}>
        {images.map((imageSrc) => (
          <Grid item xs={6}>
            <img
              alt="preview"
              className={classes.img}
              key={imageSrc}
              src={imageSrc}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ModuleInfo;
