import {createContext} from 'react'

export type Config = {
  message?: string,
  title?: string
}

type QuestionCtx = {
  open: (config: Config, cb: (answer: boolean) => void) => void
}

const defaultQuestionCtx = {
  // eslint-disable-next-line no-empty-pattern
  open: ({}, cb: (answer: boolean) => void) => undefined
}

export default createContext<QuestionCtx>(defaultQuestionCtx)
