import React from "react"
import { Line } from "react-konva";
import { Tool } from "./Dashboard";

type Props = {
  scale: number,
  items: { tool: string, points: number[], finished: boolean, eraser: boolean, radius?: number, color: string }[]
}

const Lines = ({ items, scale }: Props) => {
  return (
    <>
      {items.filter(i => i.finished).map((line, i) => (
        <Line
          key={i}
          fill={line.color}
          points={line.points}
          closed={line.tool === Tool.Polygon}
          stroke={line.color}
          strokeWidth={(line.radius || undefined)}
          lineCap={"round"}
          lineJoin={"round"}
          listening={false}
          globalCompositeOperation={
            line.eraser ? 'destination-out' : 'source-over'
          }
        />
      ))}
    </>
  )
}

export default Lines