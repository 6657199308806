import React, { Component } from "react";
import JSONPretty from "react-json-pretty";
import { CON_MIN_HEIGHT_HEADER_PADDING } from "../../../theme";
import DetailDialog from "./DetailDialog";
import Builder from "../../../components/Builder/Builder";
import { AnyModule } from "../../../types/modules";
import { DebugerInfo } from "../../../store/system/reducer";
import * as Flow from "../../../types/flow";
import { roundTo } from "../../../utils/common";
import { grey } from '@mui/material/colors';

const styles = {
  dialog: {
    width: 800,
  },
  wrap: {
    height: CON_MIN_HEIGHT_HEADER_PADDING,
    overflow: "auto",
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  completeTime: {
    textAlign: "center",
    backgroundColor: grey[700],
    color: "white",
    marginTop: 10,
    marginBottom: -13,
    padding: "2px 4px",
  },
} as const;

type Props = {
  debuggerItem: DebugerInfo;
  items: AnyModule[];
};

type State = {
  detailModule: null | Flow.AnyDebugger;
};

class Debugger extends Component<Props, State> {
  state: State = {
    detailModule: null,
  };

  handleClose = () => {
    this.setState({
      detailModule: null,
    });
  };

  render() {
    const { detailModule } = this.state;
    const { debuggerItem } = this.props;
    const { items, ...contextWithoutItems } = debuggerItem.context;
    return (
      <div style={styles.wrap}>
        <Builder
          isModuleActive={(moduleId, type) => false}
          path={[]}
          isFirst
          onSelect={(detailModule: Flow.AnyDebugger) =>
            this.setState({ detailModule })
          }
          items={[...debuggerItem.context.items]}
        />
        <div style={styles.completeTime}>
          {roundTo(debuggerItem.context.completeTime * 1000, 2)} ms
        </div>
        <DetailDialog
          module={detailModule}
          onHide={() => this.setState({ detailModule: null })}
        />
        <JSONPretty json={contextWithoutItems} />
      </div>
    );
  }
}

export default Debugger;
