import React, { FC, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AnyElement, ElementRoot } from "./Editor/types";
import ElementsList from "./Editor/ElementsList";
import { grey } from "@mui/material/colors";
import { Divider } from "@mui/material";
import Settings from "./Editor/Settings";
import BackIcon from "@mui/icons-material/ChevronLeft";
import SaveIcon from "@mui/icons-material/Save";
import PageToolbar from "../../../components/PageToolbar/PageToolbar";
import PageToolbarButton from "../../../components/PageToolbar/PageToolbarButton";
import PageToolbarCheckbox from "../../../components/PageToolbar/PageToolbarCheckbox";
import ElementsPreview from "./Editor/ElementsPreview";
import { CON_MIN_HEIGHT_HEADER_TOOLPANEL } from "../../../theme";
import useI18n from "../../../hooks/useI18n";
import { useHistory } from "react-router-dom";
import NavigationPromp from "../../../components/NavigationPromp";
import { PropsFromRedux } from "../EditorContainer";

const SIDEBAR_WIDTH = 300;
const ELEMENTS_HEIGHT = 250;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    height: CON_MIN_HEIGHT_HEADER_TOOLPANEL,
  },
  container: {
    borderRight: `1px solid ${grey[500]}`,
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    maxWidth: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    overflow: "auto",
  },
  sidebar: {
    width: SIDEBAR_WIDTH,
  },
  elements: {
    height: ELEMENTS_HEIGHT,
    overflowY: "auto",
  },
  form: {
    padding: theme.spacing(1),
    height: `calc(100% - ${ELEMENTS_HEIGHT + 1}px)`,
    overflowY: "auto",
  },
}));

const defaultValues: AnyElement[] = [
  {
    id: 0,
    type: "root",
    backgroundColor: "white",
  },
  {
    type: "camera",
    x: 10,
    y: 10,
    id: 1,
    width: 640,
    height: 480,
    showRectangles: true,
  },
];

const EditorView: FC<PropsFromRedux> = (props) => {
  const [_] = useI18n();
  const [saved, setSaved] = useState(true);
  const [elements, setElements] = useState<AnyElement[]>(
    props.customOperatorItems || defaultValues
  );
  const [activeItem, setActiveItem] = useState<number>(0);
  const currentElement = elements.find((i) => i.id === activeItem);
  const root: ElementRoot = elements.find(
    (i) => i.type === "root"
  ) as ElementRoot;
  const { classes } = useStyles();
  const history = useHistory();

  const handleChangElements = useCallback(
    (items: AnyElement[]) => {
      setSaved(false);
      setElements(items);
    },
    [setSaved, setElements]
  );

  return (
    <>
      <NavigationPromp when={!saved} message={_("changes_not_save")} />
      <PageToolbar>
        <PageToolbarButton
          color="secondary"
          onClick={() => history.push("/camera")}
          icon={<BackIcon />}
          label={_("back")}
        />
        <PageToolbarCheckbox
          label={_("active")}
          active={props.customOperatorView}
          onChangeActive={props.enableCustomOperatorView}
        />
        <PageToolbarButton
          disabled={saved}
          onClick={() => {
            setSaved(true);
            props.setCustomOperatorViewItems(elements);
          }}
          icon={<SaveIcon />}
          label={_("save")}
        />
      </PageToolbar>
      <div className={classes.root}>
        <div
          className={classes.container}
          style={{ backgroundColor: root.backgroundColor }}
        >
          <ElementsPreview
            items={elements}
            onChange={handleChangElements}
            onSelect={setActiveItem}
            activeItem={activeItem}
          />
        </div>
        <div className={classes.sidebar}>
          <div className={classes.elements}>
            <ElementsList
              onSelect={setActiveItem}
              items={elements}
              onChange={handleChangElements}
              activeId={activeItem}
            />
          </div>
          <Divider />
          <div className={classes.form}>
            {currentElement && (
              <Settings
                element={currentElement}
                onChange={(values) => {
                  setElements((elements) =>
                    elements.map((i) => (i.id === activeItem ? values : i))
                  );
                  setSaved(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorView;
