import React, { FC } from "react";
import {
  FormControl,
  InputLabel,
  Select as SelectMui,
} from "@mui/material";

type Props = {
  value: string;
  onChange: (value: string) => void;
  position: {
    x: number;
    y: number;
  };
  label?: string;
  fontSize: number;
  width: number;
  textColor: string;
  backgroundColor: string;
  style?: any;
  maxLength?: number;
  items: string[];
};

const Select: FC<Props> = ({
  items,
  value,
  onChange,
  position,
  maxLength,
  label,
  style,
  fontSize,
  textColor,
  backgroundColor,
  width,
}) => {
  const elementStyle = {
    position: "relative",
    left: position.x,
    top: position.y,
    backgroundColor,
    width,
    ...style,
  };

  return (
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <SelectMui
        native
        label={label}
        style={elementStyle}
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        inputProps={{
          style: {
            fontSize,
            lineHeight: fontSize + "px",
            color: textColor,
          },
          maxlength: maxLength,
        }}
      >
        {items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </SelectMui>
    </FormControl>
  );
};

export default Select;
