import { connect, ConnectedProps } from "react-redux";
import ListView from "./components/ListView";
import { maskSetImageId } from "../../actions/actions";
import { getModule, itemIsUsed } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { MaskModule } from "../../types/modules";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleItems = state.database.modules.items.filter(
    (m) => m.type === "MASK"
  ) as MaskModule[];
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as MaskModule;
  return {
    history: ownProps.history,
    moduleId,
    module,
    items: state.database.masksItems.map((i) => ({
      ...i,
      isUsed: itemIsUsed(moduleItems, i),
    })),
    activeImageId: module.itemId,
  };
};

const connector = connect(mapStateToProps, { maskSetImageId });

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ListView);
