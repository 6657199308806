import React, { useState } from "react";
import CameraIcon from "@mui/icons-material/Videocam";
import ModulesIcon from "@mui/icons-material/Share";
import BugIcon from "@mui/icons-material/BugReport";
import ChartIcon from "@mui/icons-material/ShowChart";
import DocIcon from "@mui/icons-material/DescriptionOutlined";
import SettingIcon from "@mui/icons-material/Settings";
import logoImg from "./pekat_vision_logo_left_inverse_RGB.svg";
import PhotoIcon from "@mui/icons-material/PhotoLibrary";
import OutputIcon from "@mui/icons-material/OpenInNew";
import { useHistory, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "tss-react/mui";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { DRAWER_SLIM_WIDTH, DRAWER_WIDTH } from "../theme";
import useI18n from "../hooks/useI18n";
import { useAppSelector } from "../hooks/store";
import { grey } from "@mui/material/colors";
import getDoc, { MAIN_DOC } from "../docLinks";
import { ListItemButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { showSettingDialog } from "../store/system/reducer";
import GpuInfoContainer from "../Containers/GpuInfo/GpuInfoContainer";
import { addErrorMessage } from "../store/system/reducer";

const useStyles = makeStyles()({
  drawerPaperClose: {
    width: DRAWER_SLIM_WIDTH,
  },
  drawerPaperOpen: {
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    overflowX: "hidden",
    backgroundColor: "#263238",
    color: "white",
    top: 0,
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  activeList: {
    backgroundColor: "rgb(0 0 0 / 35%)!important",
  },
  toggle: {
    marginLeft: 0,
    marginTop: 12,
  },
  toggleLabel: {
    marginLeft: 14,
  },
  listIcon: {
    color: "white",
  },
  hover: {
    "&:hover": {
      backgroundColor: grey[900],
    },
  },
  label: {
    whiteSpace: "nowrap",
  },
  logoWrap: {
    paddingLeft: 10,
    paddingTop: 15,
    marginBottom: -2,
    overflow: "hidden",
  },
  logoWrapCut: {
    width: 50,
    overflow: "hidden",
  },
  logo: {
    width: 158,
    marginBottom: 3,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
});

const LeftDrawer = () => {
  const { restricted, simpleTutorialOnly } = useAppSelector((state) => ({
    restricted: state.system.restricted,
    simpleTutorialOnly: state.system.simpleTutorialOnly,
  }));
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [_] = useI18n();
  const slim = !hover;
  const str = location.pathname;
  const re = /\/[a-zA-Z0-9_-]+\//i;
  const found = str.match(re);

  const handleClickHelp = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(getDoc(MAIN_DOC), "_blank");
  };

  const handleClickSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(showSettingDialog());
  };

  const menuItems = [
    {
      icon: <CameraIcon />,
      text: _("camera"),
      value: "/camera/",
      disabled: restricted,
      tutorialDisabled: simpleTutorialOnly,
    },
    {
      icon: <OutputIcon />,
      text: _("output"),
      value: "/output/",
      disabled: restricted,
      tutorialDisabled: simpleTutorialOnly,
    },
    {
      icon: <PhotoIcon />,
      text: _("images"),
      value: "/images/",
    },
    {
      icon: <ModulesIcon />,
      text: _("drawer_modules"),
      value: "/modules/",
    },
    {
      icon: <BugIcon />,
      text: _("inspection"),
      value: "/debugger/",
      tutorialDisabled: simpleTutorialOnly,
    },
    {
      icon: <ChartIcon />,
      text: _("report"),
      value: "/report/",
      tutorialDisabled: simpleTutorialOnly,
    },
  ];

  return (
    <Drawer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      elevation={3}
      variant="permanent"
      classes={{
        paper: cx(
          slim ? classes.drawerPaperClose : classes.drawerPaperOpen,
          classes.drawerPaper
        ),
      }}
      open
    >
      <div className={cx(classes.logoWrap, { [classes.logoWrapCut]: slim })}>
        <img alt="" className={classes.logo} src={logoImg} />
      </div>
      <List className={classes.list}>
        <div>
          {menuItems
            .filter((item) => !item.disabled)
            .map((item) => (
              <ListItem key={item.value} disablePadding>
                <ListItemButton
                  disabled={item.tutorialDisabled}
                  classes={{
                    selected: classes.activeList,
                  }}
                  className={classes.hover}
                  onClick={() => {
                    if (item.tutorialDisabled) {
                      dispatch(addErrorMessage("not_available_in_tutorial"));
                    } else {
                      history.push(item.value);
                    }
                  }}
                  selected={
                    (found && found[0] ? found[0] : location.pathname) ===
                    item.value
                  }
                >
                  <ListItemIcon className={classes.listIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText className={classes.label} primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
        </div>
        <div>
          <GpuInfoContainer />
          <ListItem disablePadding className={classes.hover}>
            <ListItemButton onClick={handleClickHelp}>
              <ListItemIcon className={classes.listIcon}>
                <DocIcon />
              </ListItemIcon>
              <ListItemText>{_("documentation")}</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding className={classes.hover}>
            <ListItemButton onClick={handleClickSettings}>
              <ListItemIcon className={classes.listIcon}>
                <SettingIcon />
              </ListItemIcon>
              <ListItemText>{_("settings")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
};

export default React.memo(LeftDrawer);
