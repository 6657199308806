import React, { Component } from "react";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import ImageListWithMarkers from "../../../components/ImageListWithMarkers/ImageListWithMarkers";
import {
  PageToolbar,
  PageToolbarButton,
} from "../../../components/PageToolbar";
import { PropsFromRedux } from "../ImageListContainer";
import withI18n from "../../../utils/withI18n";
import { HelperContext } from "../../../layouts/PageLayout/PageLayout";
import { Modules } from "../../../modules";

class ModelDetailView extends Component<
  PropsFromRedux & { t: (key: string) => string }
> {
  static contextType = HelperContext;
  context!: React.ContextType<typeof HelperContext>;

  // eslint-disable-next-line react/sort-comp
  handleClickRedirect(e: React.MouseEvent, path: string) {
    e.stopPropagation();
    this.props.history.push(path);
  }

  getImageSrc = (imageId: number) =>
    this.context.helper.getImagePath({
      imageId: imageId,
      moduleId: this.props.moduleId,
      includeLast: false,
      otherModule: {
        id: -1,
        fileId: this.props.fileId,
        type: Modules.CODE,
      },
      withMask: true,
    });

  render() {
    const _ = this.props.t;
    if (!this.props.fileId) {
      return null;
    }

    return (
      <>
        <PageToolbar>
          <PageToolbarButton
            color="secondary"
            onClick={(e) =>
              this.handleClickRedirect(
                e,
                `/modules/code/${this.props.moduleId}/`
              )
            }
            label={_("back")}
            icon={<LeftIcon />}
          />
          <PageToolbarButton
            label={_("code_edit")}
            color="secondary"
            onClick={(e) =>
              this.handleClickRedirect(
                e,
                `/modules/code/${this.props.moduleId}/${this.props.itemId}/`
              )
            }
            icon={<EditIcon />}
          />
        </PageToolbar>
        <ImageListWithMarkers
          moduleId={this.props.moduleId}
          readOnly
          includeLast
          withMask
        />
      </>
    );
  }
}

export default withI18n(ModelDetailView);
