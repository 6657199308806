import { connect, ConnectedProps } from "react-redux";
import ModelDetailView from "./components/ModelDetailView";
import {
  detectorSetThreshold,
  detectorUpdateEvaluation,
  getExtraModelData,
  setModelValue,
} from "../../actions/actions";
import { getModule } from "../../utils/common";
import { GlobalState } from "../../store/createStore";
import { RouteComponentProps } from "react-router-dom";
import { DetectorModel, DetectorModule } from "../../types/modules";
import { withRequiredModel } from "../../utils/withRequiredModel";

const mapStateToProps = (
  state: GlobalState,
  ownProps: RouteComponentProps<{ moduleId: string }>
) => {
  const moduleId = +ownProps.match.params.moduleId;
  const module = getModule(state, moduleId) as DetectorModule;
  const modelId = module && (module.modelId as number);
  return {
    moduleId,
    values: module.values,
    module,
    model: state.database.detectorModels.find(
      (m) => m.modelId === modelId
    ) as DetectorModel,
    modelId,
    extraData:
      state.system.extraModelData.detector &&
      state.system.extraModelData.detector[modelId as number],
  };
};

const mapDispatchToProps = {
  updateEvaluation: detectorUpdateEvaluation,
  detectorSetThreshold,
  detectorSetIOU: ({ modelId, value }: { modelId: number; value: number }) =>
    setModelValue("detectorModels", modelId, "iou", value),
  getExtraModelData: (modelId: number) =>
    getExtraModelData({ modelId, type: "detector" }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRequiredModel(ModelDetailView));
