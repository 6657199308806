import { useCallback, useContext, useEffect, useState } from "react";
import { socket } from "../../../actions/actions";
import { Feature } from "../types";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import TokenContext from "../../../contexts/TokenContext";
import { downloadText } from "../../../utils/donwloads";
import { useSnackbar } from "notistack";

type Response =
  | {
      status: "success";
      items: Feature[];
    }
  | {
      status: "unknown_error" | "disconnected";
    };

const useCameraFeatures = (
  init = true
): {
  resetFeatures: () => void;
  features: Feature[] | null;
  setFeature: (id: string, value: number | boolean | string) => void;
  getConfigFile: () => Promise<void>;
  callCommand: (id: string) => void;
  error: string | null;
} => {
  const { token } = useContext(TokenContext);
  const [features, setFeatures] = useState<Feature[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const snackbar = useSnackbar();
  useEffect(() => {
    if (!init) {
      return;
    }

    const listener = (data: Response) => {
      if (data.status === "success") {
        setFeatures(data.items);
      } else {
        setError(data.status);
      }
    };

    socket.on("cameraDatalogicPreconfig", listener);
    socket.emit("get_features", {});
    socket.emit("get_datalogic_preconfig", {});

    return () => {
      socket.off("cameraDatalogicPreconfig", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFeature = useCallback(
    (id: string, value: number | boolean | string) => {
      socket.emit("set_feature", { id, value });
    },
    []
  );

  const callCommand = useCallback((id: string) => {
    socket.emit("call_command", { id });
  }, []);

  const resetFeatures = useCallback(() => {
    socket.emit("delete_features", {});
  }, []);

  const getConfigFile = useCallback(() => {
    return axios
      .get(`${SERVER_URL}/get_config_file?token=${token}`)
      .then((res) => {
        if (res.data.status === "empty") {
          snackbar.enqueueSnackbar("Empty");
        } else {
          downloadText(res.data.content, "camera_config.txt");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    features,
    setFeature,
    resetFeatures,
    error,
    getConfigFile,
    callCommand,
  };
};

export default useCameraFeatures;
