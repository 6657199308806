import React, { FC, useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Confirm from "./Confirm";

type Props = {
  onClickConfirm: () => void;
  confirmMessage?: string;
  ButtonProps: ButtonProps;
  buttonLabel: React.ReactNode;
};

const ButtonWithConfirm: FC<Props> = (props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <Button {...props.ButtonProps} onClick={() => setShowConfirm(true)}>
        {props.buttonLabel}
      </Button>
      <Confirm
        open={showConfirm}
        message={props.confirmMessage}
        onClickNo={() => setShowConfirm(false)}
        onClickYes={() => {
          setShowConfirm(false);
          props.onClickConfirm();
        }}
      />
    </>
  );
};

export default ButtonWithConfirm;
