import React, { FC, useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Alert from '@mui/material/Alert';
import useI18n from "../../../hooks/useI18n";
import { ClassifierModule } from "../../../types/modules";

const styles = {
  dialog: {
    width: 400,
  },
  alert: {
    marginTop: 6,
  },
} as const;

type Props = {
  values: ClassifierModule["values"]["config"];
  onChange: (values: ClassifierModule["values"]["config"]) => void;
  open: boolean;
  onClose: () => void;
  withoutSubmit?: boolean;
};

const ClassifierSettingDialog: FC<Props> = ({
  values,
  onChange,
  open,
  onClose,
  withoutSubmit,
}) => {
  const [source, setSource] = useState(values.SOURCE);
  const [_] = useI18n();
  useEffect(() => {
    if (open) {
      setSource(values.SOURCE);
    }
  }, [open, values.SOURCE]);

  const handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (withoutSubmit) {
      onChange({ ...values, SOURCE: source });
    }
    onClose();
  };

  const handleClickSave = () => {
    onChange({ ...values, SOURCE: source });
    onClose();
  };

  const isChanged = values.SOURCE !== source && !withoutSubmit;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: styles.dialog }}>
      <DialogTitle>{_("settings")}</DialogTitle>
      <DialogContent>
        <RadioGroup
          value={source}
          onChange={(e) =>
            setSource(e.target.value as "static" | "full" | "context")
          }
        >
          <FormControlLabel
            value="static"
            control={<Radio />}
            label={_("static_position")}
          />
          <FormControlLabel
            value="full"
            control={<Radio />}
            label={_("classification_on_all_image")}
          />
          <FormControlLabel
            value="context"
            control={<Radio />}
            label={_("detected_in_previous_modules")}
          />
        </RadioGroup>
        {isChanged && (
          <FormHelperText error>
            {_("previous_annotations_will_be_removed")}
          </FormHelperText>
        )}
        {source === "full" && (
          <Alert style={styles.alert} severity="warning">
            {_("classifier_full_image_warning")}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClickClose}>
          {_("close")}
        </Button>
        {isChanged && (
          <Button color="primary" onClick={handleClickSave}>
            {_("set")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ClassifierSettingDialog;
