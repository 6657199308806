import React from "react";
import HelpPanelView from "./components/HelpPanelView";
import {
  closeTutorial,
  setHelpPanelWidth,
  setShowDoc,
} from "../../actions/actions";
import { useAnyAppDispatch, useAppSelector } from "../../hooks/store";

const HelpPanelContainer = () => {
  const state = useAppSelector((state) => ({
    helpPanelWidth: state.system.helpPanelWidth,
    showTutorial: state.system.showTutorial,
  }));
  const dispatch = useAnyAppDispatch();

  return (
    <HelpPanelView
      setShowDoc={(show: boolean) => dispatch(setShowDoc(show))}
      setHelpPanelWidth={(width: number) => dispatch(setHelpPanelWidth(width))}
      closeTutorial={() => dispatch(closeTutorial())}
      {...state}
    />
  );
};

export default HelpPanelContainer;
