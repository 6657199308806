import React, { Component } from "react";
import ToolPanel, { Values as Settings } from "./ToolPanel";
import { withResizeDetector } from "react-resize-detector";
import getImageSize from "../../../utils/getImageSize";
import Loader from "../../../components/Loader/Loader";
import HelperDialog from "../../../components/HelperDialog";
import Confirm from "../../../components/Confirm";
import { ImageSize } from "../../../types/common";
import withI18n from "../../../utils/withI18n";
import Dashboard, {
  Tool,
} from "../../../components/ImageListWithMarkers/Dashboard/Painting/Dashboard";
import { CONTENT_INNER_PADDING } from "../../../theme";

const TOOL_PANEL_HEIGHT = 52;

function toDataURL(url: string, callback: (data: string) => void) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result as string);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export type Props = {
  imgSrc: string;
  maskSrc?: string;
  onChange: (image: string) => void;
  onClear: () => void;
  onGetClear: (func: () => void) => void;
  onChangeRadius: (value: number) => void;
  setSettings: (values: Settings) => void;
  disabledKeyListener?: boolean;
  radius: number;
  color: string;
  enableUploadImage?: boolean;
  showClassificationHelp?: boolean;
  label: string;
  settings: Settings;

  children?: (values: {
    x: number;
    y: number;
    scale: number;
  }) => React.ReactNode;
  paddingBorder?:
    | ((values: {
        width: number;
        height: number;
      }) => [number, number, number, number])
    | [number, number, number, number];
};

export type PropsExtra = {
  t: (key: string) => string;
  imageSize: ImageSize;
  width: number;
  height: number;
};

type State = {
  values: { x: number; y: number; scale: number };
  showClearConfirm: boolean;
  history: string[];
  isMouseDown: boolean;
  moveEnable: boolean;
  tool: Settings;
  rubberKey: boolean;
  isLoading: boolean;
  hiddenCanvas: boolean;
  fileIncrement: number;
  brushSizeScroll: boolean;
  showHelper: boolean;
  brushSizeScale: null | "up" | "down";
  prevProspImgSrc: string;
  prevPropsWidth: number;
  prevPropsHeight: number;
};

class PanAndZomeWrap extends Component<Props & PropsExtra, State> {
  mousePos: { x: number; y: number } | null = null;

  imageWrapRef = React.createRef<HTMLImageElement>();

  canvasRef = React.createRef<HTMLCanvasElement>();

  fileRef = React.createRef<HTMLInputElement>();

  dashboardRef = React.createRef<any>();

  exportFunc = React.createRef<() => { image: string; isEmpty: boolean }>();

  resetFunc = React.createRef<() => void>();

  clearFunc = React.createRef<() => void>();

  refreshFunc = React.createRef<(maskUrl: string) => void>();

  brushSizeTimer = React.createRef<number>();

  constructor(props: Props & PropsExtra) {
    super(props);
    this.state = {
      showClearConfirm: false,
      history: [],
      values: PanAndZomeWrap.getDefaultValues(props),
      isMouseDown: false,
      moveEnable: false,
      tool: props.settings || {
        rubber: false,
        radius: props.radius,
        polygon: false,
      },
      rubberKey: false,
      isLoading: false,
      hiddenCanvas: false,
      fileIncrement: 0,
      brushSizeScroll: false,
      showHelper: false,
      brushSizeScale: null,
      prevProspImgSrc: this.props.imgSrc,
      prevPropsWidth: this.props.width,
      prevPropsHeight: this.props.height,
    };
  }

  componentDidMount() {
    this.props.onGetClear(this.clearAll);
    window.addEventListener("keydown", this.handleKeyPress);
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("resize", this.handleResetScale);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("resize", this.handleResetScale);
  }

  static getDerivedStateFromProps(props: Props & PropsExtra, state: State) {
    if (
      props.imgSrc !== state.prevProspImgSrc ||
      props.width !== state.prevPropsWidth ||
      props.height !== state.prevPropsHeight
    ) {
      return {
        prevProspImgSrc: props.imgSrc,
        prevPropsWidth: props.width,
        prevPropsHeight: props.height,
        values: PanAndZomeWrap.getDefaultValues(props),
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props & PropsExtra) {
    if (!this.props.maskSrc && prevProps.maskSrc) {
      this.clearAll();
    }
  }

  pushToHistory = () => {
    if (this.exportFunc?.current) {
      this.setState({
        history: [...this.state.history, this.exportFunc.current?.().image],
      });
    }
  };

  handleResetScale = () => {
    this.setState({
      values: PanAndZomeWrap.getDefaultValues(this.props),
    });
  };

  static getDefaultValues(props: Props & PropsExtra) {
    // image in top left
    const containerWidth = props.width;
    const height = props.height - TOOL_PANEL_HEIGHT;
    const imgWidth = props.imageSize.width;
    const imgHeight = props.imageSize.height;

    const radius =
      containerWidth / height < imgWidth / imgHeight
        ? containerWidth / imgWidth
        : height / imgHeight;

    return {
      x: 0.5,
      y: 0.5,
      scale: radius,
    };
  }

  handleKeyPress = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;
    if (e.repeat) {
      return;
    }

    // e - switch to rubber
    if (keyCode === 69) {
      this.setState({
        rubberKey: true,
      });
    }

    // b - brush size
    if (keyCode === 66) {
      e.stopPropagation();
      this.setState({
        brushSizeScroll: true,
      });
    }

    // k|l - brush scale down/up
    if (keyCode === 75 || keyCode === 76) {
      e.stopPropagation();
      this.setState({
        brushSizeScale: keyCode === 75 ? "down" : "up",
      });
      if (!this.brushSizeTimer.current) {
        (this.brushSizeTimer as any).current = setInterval(
          this.brushSizeScale,
          40
        );
      }
    }

    if (e.key === "z" && (e.ctrlKey || e.metaKey)) {
      this.undoHistory();
    }
  };

  undoHistory = () => {
    if (this.state.history.length > 1) {
      const history = [...this.state.history];
      history.pop();
      this.props.onChange(history[history.length - 1]);
      this.refreshFunc?.current?.(history[history.length - 1]);
      this.setState({ history });
    }
  };

  brushSizeScale = () => {
    const newRadius =
      this.state.brushSizeScale === "down"
        ? Math.max(2, this.state.tool.radius - 2)
        : Math.min(70, this.state.tool.radius + 2);
    this.props.onChangeRadius(newRadius);
    const nextTool = { ...this.state.tool, radius: newRadius };
    this.setState({
      tool: nextTool,
    });
    this.props.setSettings(nextTool);
  };

  handleKeyUp = (e: KeyboardEvent) => {
    const keyCode = e.which || e.keyCode;

    // e - switch to rubber
    if (keyCode === 69) {
      this.setState({
        rubberKey: false,
      });
    }

    // b - brush size
    if (keyCode === 66) {
      e.stopPropagation();
      this.setState({
        brushSizeScroll: false,
      });
    }

    // l|k
    if ((keyCode === 76 || keyCode === 75) && this.brushSizeTimer.current) {
      e.stopPropagation();
      window.clearInterval(this.brushSizeTimer.current);
      (this.brushSizeTimer as any).current = null;
      this.setState({
        brushSizeScale: null,
      });
    }
  };

  handleClearAll = () => {
    this.setState({
      showClearConfirm: false,
    });

    this.clearAll();
  };

  clearAll = () => {
    this.clearFunc.current?.();
    this.props.onClear();
  };

  isImageEmpty = () => {
    return this.exportFunc.current?.().isEmpty;
  };

  handleChange = () => {
    const isEmpty = this.isImageEmpty();
    if (!this.exportFunc.current) {
      return;
    }

    if (this.props.onChange && !isEmpty) {
      this.pushToHistory();
      this.props.onChange(this.exportFunc.current?.().image);
    }
    if (this.props.onClear && isEmpty) {
      this.props.onClear();
    }
  };

  handleClickUpload = () => {
    this.fileRef.current?.click();
  };

  handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    const canvas = document.createElement("canvas");
    canvas.width = this.props.imageSize.width;
    canvas.height = this.props.imageSize.height;

    if (files && files.length > 0 && canvas) {
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(
            image,
            0,
            0,
            this.props.imageSize.width,
            this.props.imageSize.height
          );

          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

          for (let i = 0; i < imgData.width * imgData.height * 4; i += 4) {
            const r = imgData.data[i + 0];
            const g = imgData.data[i + 1];
            const b = imgData.data[i + 2];
            const a = imgData.data[i + 3];
            const threshold = 105;
            if (
              (r >= threshold && g >= threshold && b >= threshold) ||
              a < threshold
            ) {
              imgData.data[i + 0] = 255;
              imgData.data[i + 1] = 255;
              imgData.data[i + 2] = 255;
              imgData.data[i + 3] = 0;
            } else {
              imgData.data[i + 0] = 0;
              imgData.data[i + 1] = 0;
              imgData.data[i + 2] = 0;
              imgData.data[i + 3] = 255;
            }
          }
          ctx.putImageData(imgData, 0, 0);
          this.props.onChange(canvas.toDataURL());
        };
        image.src = event.target?.result as any;
      };
      reader.readAsDataURL(files[0]);
    }

    this.setState((state) => ({
      fileIncrement: state.fileIncrement + 1,
    }));
  };

  handleOpenHelper = () => {
    this.setState({
      showHelper: true,
    });
  };

  render() {
    if (this.props.width === undefined || this.props.height === undefined) {
      return (
        <div style={{ height: "100%" }}>
          <Loader relative fadeIn />
        </div>
      );
    }
    return (
      <div style={{ height: "100%" }}>
        <ToolPanel
          undoEnabled={this.state.history.length > 1}
          onClickUndo={this.undoHistory}
          key="tool"
          label={this.props.label}
          onShowHelper={this.handleOpenHelper}
          onResetScale={() => this.resetFunc.current?.()}
          imageSize={this.props.imageSize}
          values={this.state.tool}
          onChange={(tool) => {
            if (this.state.tool.radius !== tool.radius) {
              this.props.onChangeRadius(tool.radius);
            }
            this.setState({ tool });
            this.props.setSettings(tool);
          }}
          onClear={() => this.setState({ showClearConfirm: true })}
          enableUpload={this.props.enableUploadImage}
          onClickUpload={this.handleClickUpload}
        />
        <div
          key="wrap"
          ref="wrap"
          style={{
            display: "inline-block",
            verticalAlign: "top",
            position: "relative",
            overflow: "hidden",
            marginTop: CONTENT_INNER_PADDING,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Dashboard
            getExportFunc={(exportFunc) => {
              /* @ts-expect-error */
              this.exportFunc.current = exportFunc;
              if (this.props.maskSrc) {
                toDataURL(this.props.maskSrc as any, (base64) => {
                  this.setState({
                    history: [base64],
                  });
                });
              }
            }}
            disableScrollListener={this.state.brushSizeScroll}
            onMaskInit={() => {}}
            getClearFunc={(clearFunc) => {
              /* @ts-expect-error */
              this.clearFunc.current = clearFunc;
            }}
            onChangeScale={(scale) =>
              this.setState(({ values }) => ({ values: { ...values, scale } }))
            }
            radius={this.state.tool.radius}
            eraser={
              this.state.tool.rubber ||
              (this.state.rubberKey && !this.state.tool.polygon)
            }
            color={this.props.color}
            getResetFunction={(reset) => {
              /* @ts-expect-error */
              this.resetFunc.current = reset;
            }}
            getRefereshFunc={(refresh) => {
              /* @ts-expect-error */
              this.refreshFunc.current = refresh;
            }}
            width={this.props.width}
            height={this.props.height - TOOL_PANEL_HEIGHT}
            imageUrl={this.props.imgSrc}
            imageSize={this.props.imageSize}
            maskUrl={this.props.maskSrc}
            onChangeCanvas={() => this.handleChange()}
            tool={this.state.tool.polygon ? Tool.Polygon : Tool.Line}
          />
          {this.props.children && this.props.children(this.state.values)}
          {this.state.isLoading && <Loader />}
          <HelperDialog
            open={this.state.showHelper}
            showBrush
            showClassification={this.props.showClassificationHelp}
            onHide={() => this.setState({ showHelper: false })}
            showUndo
          />
          {this.props.enableUploadImage && (
            <input
              accept="image/jpeg, image/jpg, image/png, image/bmp, image/tiff"
              type="file"
              style={{ display: "none" }}
              onChange={this.handleChangeFile}
              ref={this.fileRef}
              key={this.state.fileIncrement}
            />
          )}
        </div>
        <Confirm
          open={this.state.showClearConfirm}
          onClickNo={() => this.setState({ showClearConfirm: false })}
          onClickYes={this.handleClearAll}
          message={this.props.t("the_annotations_will_be_removed")}
        />
      </div>
    );
  }
}

export default withI18n(getImageSize(withResizeDetector(PanAndZomeWrap)));
