import React, { FC } from "react";
import { makeStyles } from 'tss-react/mui';

import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useI18n from "../hooks/useI18n";
import {
  ClassifierModel,
  DetectorModel,
  SupervisedModel,
} from "../types/modules";

const AVERAGE_COUNT = 30;

const geNearestValues = (array: number[], index: number, divisor: number) => {
  const halfCount = AVERAGE_COUNT / 2 / divisor;
  return [
    ...array.slice(Math.max(index - halfCount, 0), index),
    array[index],
    ...array.slice(index + 1, 1 + index + halfCount),
  ];
};

const getAverage = (array: number[], index: number, divisor: number) => {
  const items = geNearestValues(array, index, divisor);
  const sum = items.reduce((a, b) => a + b, 0);
  return sum / items.length;
};

const useStylesDialog = makeStyles()((theme) => ({
  root: {
    backgroundColor: "rgba(255,255,255,0.8)",
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles()({
  infoItem: {
    marginTop: 5,
  },
});

const CustomTooltip = ({ active, payload }: any) => {
  const { classes } = useStylesDialog();
  const [_] = useI18n();
  if (active && payload) {
    return (
      <div className={classes.root}>
        {_("valueLoss")}: <b>{parseFloat(payload[0].value).toFixed(5)}</b>
        <br />
        {_("average_loss")}: <b>{parseFloat(payload[1].value).toFixed(5)}</b>
      </div>
    );
  }
  return null;
};

type Props = {
  model: ClassifierModel | DetectorModel | SupervisedModel;
};

const ModelChart: FC<Props> = (props) => {
  const { classes } = useStyles();
  const [_] = useI18n();

  const offset =
    props.model.lossCount > props.model.loss.length
      ? props.model.lossCount - props.model.loss.length
      : 0;
  const chartData = props.model.loss.map((n, index) => ({
    name: index * props.model.divisor,
    [_("valueLoss")]: n,
    [_("average_loss")]: getAverage(
      props.model.loss,
      index,
      props.model.divisor
    ),
  }));
  const lastItem = chartData[chartData.length - 1];
  return (
    <>
      <LineChart
        key={props.model.lossCount}
        width={522}
        height={200}
        data={chartData}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
      >
        <XAxis type="number" dataKey="name" domain={[offset, "dataMax"]} />
        <YAxis
          scale="log"
          tickFormatter={(tick) => {
            return `${Math.round(tick * 100000) / 100000}`;
          }}
          domain={["dataMin", "dataMax"]}
        />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid stroke="#f5f5f5" />
        <Line
          dot={false}
          isAnimationActive={false}
          type="monotone"
          dataKey={_("valueLoss")}
          stroke="#ff7300"
          yAxisId={0}
        />
        <Line
          dot={false}
          isAnimationActive={false}
          type="monotone"
          dataKey={_("average_loss")}
          stroke="#00ff00"
          yAxisId={0}
        />
      </LineChart>
      {lastItem && (
        <>
          <div className={classes.infoItem}>
            <b>{_("valueLoss")}</b>: {lastItem[_("valueLoss")].toFixed(5)}
          </div>
          <div className={classes.infoItem}>
            <b>{_("average_loss")}</b>: {lastItem[_("average_loss")].toFixed(5)}
          </div>
        </>
      )}
    </>
  );
};

export default ModelChart;
