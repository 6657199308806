import React, { Component } from "react";
import debounce from "debounce";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import * as sortFilterImages from "../utils/sortFilterImages";
import Pagination from "@mui/material/Pagination";
import withI18n from "../utils/withI18n";
import { Sort } from "../utils/sortFilterImages";
import { CONTENT_INNER_PADDING } from "../theme";

const styles = {
  wrap: {
    padding: CONTENT_INNER_PADDING,
  },
} as const;

type Props = {
  onChange: (values: sortFilterImages.FilterSettings) => void;
  values: sortFilterImages.FilterSettings;
  t: (key: string) => string;
  pagination?: {
    pages: number;
    currentPage: number;
    onChangePage: (page: number) => void;
  };
};

type State = {
  text: string;
};

class ImagesGridFilter extends Component<Props, State> {
  state: State = {
    text: "",
  };

  debounceSaveText?: () => void;

  componentDidMount() {
    this.setState({
      text: this.props.values.text,
    });
    this.debounceSaveText = debounce(this.saveText, 600);
  }

  saveText = () => {
    this.props.onChange({ ...this.props.values, text: this.state.text });
  };

  render() {
    const { values, onChange, pagination } = this.props;
    const { sort } = values;
    const _ = this.props.t;
    const changeValue = (name: string, value: Sort) =>
      onChange({ ...values, [name]: value });

    return (
      <div style={styles.wrap}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              value={this.state.text}
              placeholder={_("search")}
              onChange={(e) => {
                this.debounceSaveText?.();
                this.setState({ text: e.target.value });
              }}
            />
          </Grid>

          {pagination && pagination.pages > 1 && (
            <Grid item>
              <Pagination
                count={pagination.pages}
                page={pagination.currentPage + 1}
                onChange={(e, page) => pagination.onChangePage(page - 1)}
                color="primary"
              />
            </Grid>
          )}
          <Grid item>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              size="small"
              onChange={(e) => changeValue("sort", e.target.value as Sort)}
            >
              <MenuItem value={sortFilterImages.SORT_NEW}>
                {_("image_filter_sort_new")}
              </MenuItem>
              <MenuItem value={sortFilterImages.SORT_OLD}>
                {_("image_filter_sort_old")}
              </MenuItem>
              <MenuItem value={sortFilterImages.SORT_AZ}>
                {_("image_filter_sort_az")}
              </MenuItem>
              <MenuItem value={sortFilterImages.SORT_ZA}>
                {_("image_filter_sort_za")}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withI18n(ImagesGridFilter);
