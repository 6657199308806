import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

type Props = {
  title?: string;
  onRequestClose?: () => void;
  actions?: any[];
  contentStyle?: any;
  open: boolean;
  style?: any;
  children?: any;
};

const SimpleDialog: FC<Props> = (props) => {
  const {
    onRequestClose,
    title,
    children,
    actions,
    open,
    contentStyle,
    style,
    ...otherProps
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{ style: contentStyle || {} }}
      onClose={() => onRequestClose?.()}
      style={style}
      {...otherProps}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        {actions && actions.map((i, index) => ({ ...i, key: index }))}
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
