import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from '@mui/material/Alert';
import SettingForm, { Settings } from "./SettingForm";
import { Model } from "../../types/modules";
import withI18n from "../../utils/withI18n";

const styles = {
  augumentation: {
    marginTop: 8,
  },
  divider: {
    margin: "20px 0",
  },
  dialog: {
    width: 300,
    // sminHeight: 460
  },
  text: {
    verticalAlign: "top",
    display: "inline-block",
    width: "calc(50% - 10px)",
  },
  selectModel: {
    display: "inline-block",
    width: "calc(50% - 10px)",
    marginLeft: 20,
  },
  patchSizeWarning: {
    color: "red",
  },
  error: {
    marginTop: 12,
  },
  children: {
    marginTop: 12,
  },
} as const;

type State = {
  prevModel: number;
};

type Props = {
  models: Model[];
  hiddenExtendModel?: boolean;
  onClose: () => void;
  config: Settings;
  onChangeConfig: (values: Settings) => void;
  onStartTraining: (name: string, prevModel: number | null) => void;
  open: boolean;
  errorMessage?: string;
  allowShift?: boolean;
  allowRotate?: boolean;
  allowFlip?: boolean;
  allowShear?: boolean;
  allowMobileNet?: boolean;
  readOnly?: boolean;
  disableNetworkSize?: boolean;
  allowColorJittering?: boolean;
  allowValidatingPercent?: boolean;
  hiddenNetworkSize?: boolean;
  allowEpochSize?: boolean;
  allowNoiseResistance?: boolean;
  allowBrightnessResistance?: boolean;
  allowContrastResistance?: boolean;
  allowSaturationResistance?: boolean;
  allowFaster?: boolean;
  allowSteps?: boolean;
  allowMaxSize?: boolean;
  allowDeepSearching?: boolean;
  t: (key: string) => string;
  children?: JSX.Element;
  modelsCounter: number;
};

class TrainingDialog extends Component<Props, State> {
  state: State = {
    prevModel: -1,
  };

  textRef = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: Props) {
    if (this.props.open && !prevProps.open) {
      setTimeout(() => {
        const el = document.getElementById("training-start-btn");
        if (el) {
          el.focus();
        }
      }, 300);
    }
  }

  handleClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.onClose();
  };

  handleClickTraining = () => {
    this.props.onClose();
    if (this.textRef.current) {
      this.props.onStartTraining(
        this.textRef.current.value,
        this.state.prevModel
      );
    }
  };

  showPatchSizeWarning = () => {
    const prevModel = this.props.models.find(
      (m) => m.modelId === this.state.prevModel
    );
    return (
      this.props.config.PATCH_SIZE &&
      prevModel &&
      (prevModel as any).trainingParams.config.PATCH_SIZE !==
        this.props.config.PATCH_SIZE
    );
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.handleClickTraining();
  };

  render() {
    const { open, onClose, models } = this.props;
    const _ = this.props.t;
    const allowAnyAugumentation =
      this.props.allowFaster ||
      !this.props.hiddenNetworkSize ||
      !this.props.hiddenExtendModel ||
      this.props.allowValidatingPercent ||
      this.props.allowColorJittering ||
      this.props.allowMobileNet ||
      this.props.allowShift ||
      this.props.allowRotate ||
      this.props.allowFlip ||
      this.props.allowShear ||
      this.props.allowMaxSize ||
      this.props.allowBrightnessResistance ||
      this.props.allowEpochSize ||
      this.props.allowNoiseResistance ||
      this.props.allowContrastResistance ||
      this.props.allowSaturationResistance ||
      this.props.allowSteps ||
      this.props.allowDeepSearching;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ style: styles.dialog }}
      >
        <DialogTitle>{_("start_train")}</DialogTitle>
        <DialogContent>
          <form
            id="training-form"
            onSubmit={this.handleSubmit}
            onKeyPress={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <TextField
              fullWidth
              inputRef={this.textRef}
              variant="outlined"
              label={_("name")}
              defaultValue={"Model " + this.props.modelsCounter}
              size="small"
              margin="normal"
            />

            {!this.props.hiddenExtendModel && (
              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
              >
                <InputLabel>{_("training_dialog_extend")}</InputLabel>
                <Select
                  label={_("training_dialog_extend")}
                  value={this.state.prevModel}
                  onChange={(e) => {
                    const value = e.target.value as number;
                    this.setState({ prevModel: value });
                    if (value !== -1) {
                      const model = models.find((m) => m.modelId === value);
                      if (model && this.props.allowMaxSize) {
                        this.props.onChangeConfig({
                          ...this.props.config,
                          MAX_SIZE: (model as any).trainingParams.config
                            .MAX_SIZE,
                        });
                      }

                      if (!this.props.hiddenNetworkSize) {
                        this.props.onChangeConfig({
                          ...this.props.config,
                          NETWORK_SIZE: (model as any).trainingParams.config
                            .NETWORK_SIZE,
                        });
                      }
                    }
                  }}
                >
                  <MenuItem value={-1}>{_("training_dialog_none")}</MenuItem>
                  {[...models]
                    .reverse()
                    .filter((i) => i.completed && !i.failed)
                    .map((m) => (
                      <MenuItem key={m.modelId} value={m.modelId}>
                        {m.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {allowAnyAugumentation && (
              <div style={styles.augumentation}>
                <SettingForm
                  allowFaster={this.props.allowFaster}
                  hiddenNetworkSize={this.props.hiddenNetworkSize}
                  allowValidatingPercent={this.props.allowValidatingPercent}
                  allowColorJittering={this.props.allowColorJittering}
                  allowMobileNet={this.props.allowMobileNet}
                  allowShift={this.props.allowShift}
                  allowRotate={this.props.allowRotate}
                  allowFlip={this.props.allowFlip}
                  allowShear={this.props.allowShear}
                  allowMaxSize={this.props.allowMaxSize}
                  allowBrightnessResistance={
                    this.props.allowBrightnessResistance
                  }
                  allowEpochSize={this.props.allowEpochSize}
                  allowNoiseResistance={this.props.allowNoiseResistance}
                  allowContrastResistance={this.props.allowContrastResistance}
                  allowSaturationResistance={
                    this.props.allowSaturationResistance
                  }
                  allowSteps={this.props.allowSteps}
                  allowDeepSearching={this.props.allowDeepSearching}
                  onChangeValues={this.props.onChangeConfig}
                  values={this.props.config}
                  disableNetworkSize={this.state.prevModel !== -1}
                  disableMaxSize={this.state.prevModel !== -1}
                />
              </div>
            )}
            {this.showPatchSizeWarning() && (
              <p style={styles.patchSizeWarning}>
                {_("extent_model_patch_size_warning")}
              </p>
            )}

            {this.props.children && (
              <div style={styles.children}>{this.props.children}</div>
            )}
          </form>
          {this.props.errorMessage && (
            <Alert style={styles.error} severity="error">
              {this.props.errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClickClose}>
            {_("close")}
          </Button>
          <Button
            id="training-start-btn"
            color="primary"
            form="training-form"
            type="submit"
            disabled={!!this.props.errorMessage}
          >
            {_("training_dialog_start")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withI18n(TrainingDialog);
