import { connect, ConnectedProps } from "react-redux";
import StatisticsView from "./components/StatisticsView";
import {
  downloadFile,
  setFilter,
  startStatistics,
  setStatisticsAnnotations,
  setStatisticsType,
} from "../../actions/actions";
import { addErrorMessage } from "../../store/system/reducer";
import { GlobalState } from "../../store/createStore";

const mapStateToProps = (state: GlobalState) => {
  return {
    noImage: state.database.images.length === 0,
    values: state.database.statistics,
    statisticsResult: state.system.statisticsResult,
    images: state.database.images,
    projectName: state.system.projectName,
    lang: state.system.lang,
  };
};

const connector = connect(mapStateToProps, {
  startStatistics,
  downloadFile,
  addErrorMessage,
  setFilter,
  setStatisticsAnnotations,
  setStatisticsType,
});

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StatisticsView);
