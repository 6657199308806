import React, {FC, useMemo} from "react";
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {CircularProgress, Dialog, DialogActions, MenuItem, TextField} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import useCameraFeatures from "../hooks/useCameraFeatures";
import Features from "./Features";
import Button from "@mui/material/Button";
import { Alert } from '@mui/material';
import {Visibility} from "../types";
import {themeV4dark} from "../../../theme";
import {useLocalStorage} from "react-use-storage";
import useI18n from "../../../hooks/useI18n";



const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'absolute',
  },
  loader: {
    margin: '0 auto',
    display: 'block',
  },
  filter: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: 'white'
  }
}));


type Props = {
  onClose: () => void
}

const visibilityToIndex = (name: Visibility) : number => ['Beginner', 'Expert', 'Guru'].indexOf(name)

const FeaturesDialog: FC<Props> = ({onClose}) => {
  const { classes } = useStyles()
  const [_] = useI18n()
  const [filter, setFilter] = useLocalStorage<Visibility>("camera.filter","Beginner")
  const {features, setFeature, callCommand, error} = useCameraFeatures()
  const filteredFeatures = useMemo(() => {
    if (features !== undefined && features !== null) {
      return features.filter(i => i.items.length > 0).map(category => ({
        ...category,
        items: category.items.filter(i => visibilityToIndex(i.visibility) <= visibilityToIndex(filter))
      }))
    } else {
      return features
    }
  }, [filter, features])
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {_('features')}
        {features !== null &&
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themeV4dark}>
            <TextField
              className={classes.filter}
              select
              value={filter}
              onChange={(e) => setFilter(e.target.value as Visibility)}
            >
              {['Beginner', 'Expert', 'Guru'].map(key =>
                <MenuItem key={key} value={key}>
                  {_(key.toLowerCase())}
                </MenuItem>
              )}
            </TextField>
          </ThemeProvider>
        </StyledEngineProvider>
       }
      </DialogTitle>
      <DialogContent>
        {error === null && (filteredFeatures !== null ? <Features callCommand={callCommand} setFeature={setFeature} structure={filteredFeatures}/> :
          <CircularProgress className={classes.loader}/>)}
        {error !== null && <Alert severity="error">{_(error)}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{_('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeaturesDialog;
