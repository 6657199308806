import { Rectangle } from "../../../types/common";
import { sortAlphabets } from "../../../utils/common";

function degrees_to_radians(degrees: number)
{
  var pi = Math.PI;
  return degrees * (pi/180);
}

export const splitText = (rectangle: Rectangle, text: string): Rectangle[] => {
    return text.split("").reverse().map((character, index) => {
        const offsetX = (rectangle.width / text.length) * (index - text.length + 1) 
        const angle = degrees_to_radians((rectangle.rotate || 0))
        const item: Rectangle = {
            id: Date.now() + index,
            width: rectangle.width / text.length,
            height: rectangle.height,
            x: rectangle.x  - Math.cos(angle) * offsetX,
            y: rectangle.y - Math.sin(angle) * offsetX,
            text: character,
            percent: rectangle.percent,
            rotate: (rectangle.rotate || 0),
            parentId: rectangle.id
        }
        return item
    }).reverse()

}

export const invalidAnnotations = (rectangles: Rectangle[]) => {
    if (rectangles.length === 1 && rectangles[0].text?.length === 1) {
        return false
    } 
    return rectangles.filter(i => {
      if (i.parentId) {
        return false
      }
      const childs = rectangles.filter(k => k.parentId === i.id)
      
      if (i.text?.length === 1 && childs.length === 0) {
        return false
      }

      const notSplitted = childs.length !== i.text?.length
      const invalidText = sortAlphabets(childs.map(i => i.text).join("")) !== sortAlphabets(i.text || '')

      return notSplitted || invalidText
    }).length > 0
  }